import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { TButton, TCard, theme, TLoader, TTextArea } from '../../../components/myde-react-components';
import { useTheme } from '../../../providers/custom-theme-provider';
import {
  getApplicationCommunicationByIdDetails,
  messageDetails,
  respondApplicationCommunicationDetails,
} from '../../../redux/feature/message/messageSlice';

import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import { MessageResultType, ResponseType } from '../../../types/messageTypes';
import MessageBox from './MessageBox';
import { formatDate, getTimeStamp, hexToRgbA } from '../../../utils/utils';
import {
  MAX_CHAR_LIMIT,
  MESSAGE_RESPONDER_TYPES,
} from '../../../components/myde-react-components/src/constants/constants';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { setParentRoute, setPreviousStep } from '../../../redux/feature/common/commonSlice';
import { RouteType } from '../../../types/commonTypes';
import * as ROUTES from '../../../constants/routes';
import { Divider } from '@material-ui/core';
import useQuery from '../../../hooks/useQuery';

interface StyleProps {
  currentThemes: Theme;
}

//Styles
const useStyles = makeStyles<Theme, StyleProps>({
  noteCount: {
    position: 'sticky',
    top: '95%',
  },

  replyBox: {
    position: 'sticky',
    top: '90%',
  },

  replyTextArea: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      padding: '10px',
    },
  },

  unreadText: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },

  unreadMessageBox: {
    '& .msgBoxWithUnreadText': {
      borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
      backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
    },
  },

  underMessageDividerColor: {
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const MessageDetails = () => {
  const { currentThemes } = useTheme();
  const { t } = useTranslation('userViewMessage');
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const query = useQuery();
  const messageData = {
    applicationId: query.get('applicationId') || '',
    communicationId: query.get('communicationId') || '',
  };
  const messagesEndRef = useRef<HTMLElement>(null);

  const { loading, selectedMessageDetail } = useSelector(messageDetails);

  const [chatList, setChatList] = useState({} as MessageResultType);
  const [newUserMsg, setNewUserMsg] = useState('');
  const [unreadMsgIdx, setUnreadMsgIndex] = useState(0);
  const [count, setCount] = useState(0);
  const maxLength = MAX_CHAR_LIMIT.CHAR_LENGTH;

  useEffect(() => {
    const route: RouteType = {
      path: ROUTES.APPLICATION_MESSAGES,
      isChild: true,
      stateName: 'Application Messages',
      query: messageData,
      stateObj: undefined,
    };
    dispatch(setParentRoute(route));
    dispatch(setPreviousStep(null));
    if (messageData?.applicationId && messageData?.communicationId) {
      dispatch(getApplicationCommunicationByIdDetails(messageData));
    }
  }, []);

  useEffect(() => {
    if (selectedMessageDetail) {
      checkUnread(selectedMessageDetail?.response);
      setChatList(selectedMessageDetail);
    }
  }, [selectedMessageDetail]);

  useEffect(() => {
    if (chatList?.response?.length) {
      scrollToBottom();
    }
  }, [chatList]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const checkUnread = (responseList: ResponseType[]) => {
    const unread = responseList?.findIndex(
      (item: ResponseType) => !item?.is_read && item?.responder === MESSAGE_RESPONDER_TYPES.MERCHANT,
    );
    setUnreadMsgIndex(unread);
  };

  const handleReplyTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || '';
    setCount(value.length);
    if (value && value.length > maxLength) {
      return;
    }
    setNewUserMsg(value);
  };

  const reply = () => {
    const data = {
      reply: { text: newUserMsg },
      ...messageData,
    };
    dispatch(respondApplicationCommunicationDetails(data));
    setNewUserMsg('');
    setCount(0);
  };

  return (
    <TCard sx={{ pb: 3, mb: 8 }} className="applicationMessageDetailsCard p-relative">
      <Box sx={{ p: 3 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TLoader loading={loading} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box className="messageListBox">
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Box sx={chatList?.response?.length > 5 ? { mr: 1 } : { mr: 0 }}>
                  <MessageBox
                    message={chatList?.request}
                    messageDate={formatDate(chatList?.created_on)}
                    messageTime={getTimeStamp(chatList?.created_on)}
                    responder={`${chatList?.account?.first_name} ${chatList?.account?.last_name}`}
                  />
                </Box>
              </Grid>
              {chatList?.response?.map((item: ResponseType, index: number) => (
                <>
                  <Grid
                    container
                    key={index}
                    direction="row"
                    justifyContent={item?.responder === 'Merchant' ? 'flex-start' : 'flex-end'}
                    alignItems="center"
                  >
                    {unreadMsgIdx > -1 && unreadMsgIdx === index && (
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1, mb: theme.spacing(5) }}>
                        <Divider className={clsx('w-100', classes.underMessageDividerColor)} />
                        <Box
                          sx={{ mt: theme.spacing(5) }}
                          className={clsx('text-medium font-weight-semibold text-center', classes.unreadText)}
                        >
                          {t('unreadText', { ns: 'userViewMessage' })}
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={8} sm={8} md={8} lg={8}>
                      <Box
                        sx={chatList?.response?.length >= 4 ? { mr: 1 } : { mr: 0 }}
                        className={clsx({
                          [classes.unreadMessageBox]:
                            item?.responder === MESSAGE_RESPONDER_TYPES.MERCHANT && !item?.is_read,
                        })}
                      >
                        <MessageBox
                          key={item?.trellis_uid}
                          message={item?.text}
                          messageDate={formatDate(item?.timestamp)}
                          messageTime={getTimeStamp(item?.timestamp)}
                          responder={`${item?.name}`}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ))}
              <Box ref={messagesEndRef}></Box>
            </Box>
          </Grid>
        </Grid>
        <Box className="p-absolute userReplyBox">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.replyBox}
            sx={{ my: 3 }}
          >
            <Grid className="p-relative textReplyArea" item xs={12} sm={12} md={12} lg={12}>
              <TTextArea
                fullWidth={true}
                value={newUserMsg}
                onChange={handleReplyTextChange}
                minRows={1}
                className={classes.replyTextArea}
                inputProps={{ maxLength: maxLength }}
              />
              <TButton
                className="replyButton"
                variant="text"
                icon={<TurnLeftIcon />}
                size="small"
                btnText="Reply"
                onClick={reply}
              />
            </Grid>
          </Grid>
          <Box className={clsx(classes.noteCount, 'flex-basic-end text-small')}>{`${
            maxLength - count
          } character(s) remaining`}</Box>
        </Box>
      </Box>
    </TCard>
  );
};

export default MessageDetails;
