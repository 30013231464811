import { ValidateEmailType } from '../types/commonTypes';
import { apiPost } from './axios';

export const verifyEmail = async () => {
  const res = await apiPost('/verify-email', null, {
    custom: {
      excludeTokenIdFromHeader: true,
      APITokenIdFromHeader: true,
    },
  });
  return res.data;
};

export const verifyProfileEmail = async () => {
  const res = await apiPost('/profile-email/verify', null, {
    custom: {
      excludeTokenIdFromHeader: true,
      APITokenIdFromHeader: true,
    },
  });
  return res.data;
};

export const resendProfileVerifyLink = async (id: string) => {
  const res = await apiPost('/profile-email/resend-email', { profile_id: id });
  return res.data;
};

export const resendEntityEmailVerifyLink = async (data: {entityUid: number, profile_id: string}) => {
  const res = await apiPost(`/entity/${data?.entityUid}/profile-email/resend-email`,{profile_id: data?.profile_id})
  return res.data
}

export const verifyEnteredEmail = async (data: ValidateEmailType) => {
  return await apiPost('validate-email', data);
};

export const resendVerifyLinkWithToken = async () => {
  const res = await apiPost('/resend-verification-email-using-token', null, {
    custom: {
      excludeTokenIdFromHeader: true,
      APITokenIdFromHeader: true,
    },
  });
  return res.data;
};

export const resendVerifyLinkWithoutToken = async () => {
  const res = await apiPost('/resend-verification-email');
  return res.data;
};
