import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, TableFooter, TableRow, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '../../../../../providers/custom-theme-provider';
export interface ClickHandlerType {
  (param?: any): void;
}
interface CustomSelectProps {
  rowsPerPageOptions: any[];
  rowsPerPage: number;
  count: number;
  onRowChange: ClickHandlerType;
  onPageChange: ClickHandlerType;
  primaryColor: string;
  page?: number;
}

export interface StyleProps {
  primaryColor: string;
  currentThemes: Theme;
}
// MuiPagination-outlined
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  pageSelectCount: {
    height: 32,
    minWidth: 50,
    mr: 1,
    '&.MuiOutlinedInput-root': {
      '&.Mui-focused': {
        '& > fieldset': { borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main}` },
      },
    },
  },
  paginationtainer: {
    '& .MuiPagination-root': {
      '& .MuiPagination-ul': {
        '& .MuiButtonBase-root': {
          border: '1px solid',
          color: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
          borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main}`,
        },
        '& .Mui-selected': {
          background: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
          opacity: 1,
        },
        '& .Mui-disabled': {
          borderColor: 'transparent!important',
        },
      },
    },
  },
}));
export const CPagination = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  onRowChange,
  onPageChange,
  primaryColor,
  page,
}: CustomSelectProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    primaryColor,
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);
  const [rowsPerPageOption, setrowsPerPageOption] = React.useState(rowsPerPage.toString());
  const handleChange = (event: SelectChangeEvent<string>) => {
    setrowsPerPageOption(event.target.value);
    onRowChange(event.target.value);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: any) => {
    onPageChange(page);
  };

  return (
    <TableFooter>
      <TableRow>
        <Stack spacing={2} sx={{ pt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Box sx={{ p: '4px' }}>Rows per page</Box>
            <FormControl>
              <Select
                className={classes.pageSelectCount}
                IconComponent={() => <span className="icon-arrow-down-line textColor-200 actionIconFont-24" />}
                value={rowsPerPageOption}
                onChange={handleChange}
                sx={{}}
              >
                {rowsPerPageOptions.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box className={classes.paginationtainer}>
              <Pagination color="primary" count={count} shape="rounded" onChange={handlePageChange} page={page} />
            </Box>
          </Box>
        </Stack>
      </TableRow>
    </TableFooter>
  );
};
export default CPagination;
