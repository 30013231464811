import { Box } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { TTextField } from '../../..';

interface GPlacesProps {
  fieldType: string[];
  fieldId: string;
  isOptional?: boolean;
  onPlaceSelection: (placeData: any) => void;
}

type CombinedProps = GPlacesProps & TextFieldProps;

export const GPlaces = React.forwardRef<any, CombinedProps>(
  ({ fieldId, fieldType, onPlaceSelection, ...props }: CombinedProps, ref: any) => {
    // Constants
    let autoComplete: any;
    const options = {
      fields: ['address_components', 'name'],
    };

    // Use Effects
    useEffect(() => {
      if (fieldId) {
        initGPlacesAutocomplete();
      }
    }, [fieldId]);
    useEffect(() => {
      if (autoComplete && fieldType)
        autoComplete.setOptions({
          types: fieldType,
        });
    }, [fieldType]);

    // Methods
    const initGPlacesAutocomplete = () => {
      const input = document.getElementById(fieldId) as HTMLInputElement;
      if ((window as any).google) {
        autoComplete = new (window as any).google.maps.places.Autocomplete(input, options);
        autoComplete.addListener('place_changed', async () => {
          const place = await autoComplete.getPlace();
          onPlaceSelection(place);
        });
      }
    };

    // HTML
    return (
      <Box className="gplacesTextBox">
        <TTextField className="" id={fieldId} {...props} ref={ref} />
      </Box>
    );
  },
);

export default GPlaces;
