import React, { useEffect, useState } from 'react';
import CustomTabs, { TabListType } from '../../components/myde-react-components/src/components/Tabs/Tabs';
import MerchantDocuments from './components/MerchantDocuments';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import RecentDocuments from './RecentDocuments';
import { theme } from '../../components/myde-react-components';
import { useDispatch } from 'react-redux';
import { setCardState } from '../../redux/feature/common/commonSlice';

const useStyles = makeStyles({
  pageContainer: {
    minHeight: '100vh',
  },
});

const MerchantDocumentsIndex = () => {
  // State Variables
  const [tabs, setTabs] = useState<Array<TabListType>>([]);

  const tabList: TabListType[] = [
    {
      label: 'Merchants',
      component: <MerchantDocuments />,
    },
    {
      label: 'Recent Documents',
      component: <RecentDocuments />,
    },
  ];

  // Constants
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setTabs(tabList);
    dispatch(setCardState(false));
  }, []);

  return (
    <>
      <Box
        sx={{ mt: theme.spacing(19) }}
        className={clsx('textColor-200 text-medium font-weight-regular', classes.pageContainer)}
      >
        <Box className="prent-section">
          <CustomTabs
            customClass={true}
            removePadding={true}
            tabList={tabs}
            borderRequired={false}
            showBottomBorder={false}
          />
        </Box>
      </Box>
    </>
  );
};

export default MerchantDocumentsIndex;
