import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { t } from 'i18next';

import UploadDoc from '../common/UploadDoc';
import {
  clearIdProcessMultipleData,
  selectSelectedIdProcessDetails,
  submitEntityIdProcess,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { APPLICATION_STATUS } from '../../../../constants/constants';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { ENTITY_PROCESS_IDS, ID_PROCESS_STATUS_COMPLETION_STATE } from '../../../myde-react-components';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';
import { FileType } from '../../../../types/documentTypes';

const OwnershipDocConfirmation = () => {
  // Constants
  const dispatch = useDispatch();

  // Redux Values
  const { selectedEntity } = useSelector(selectEntity);
  const { uploadedImageToPreviewMultiple, uploadedDocIdMultiple, selectedEntityIdProcessDetails } =
    useSelector(selectSelectedIdProcessDetails);

  //State variables
  const [preview, setPreview] = useState([] as FileType[]);

  // Use Effects
  useEffect(() => {
    if (
      Object.keys(selectedEntityIdProcessDetails)?.length > 0 &&
      selectedEntityIdProcessDetails?.data?.document_id_list?.length > 0
    ) {
      setPreview(selectedEntityIdProcessDetails?.data?.document_id_list);
    }
  }, [selectedEntityIdProcessDetails]);

  // Methods
  const handleNext = () => {
    const payload: any = {
      process_id: ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT,
      process_data: {
        document_id_list: uploadedDocIdMultiple,
      },
      action: APPLICATION_STATUS.SUBMIT,
    };
    if (uploadedImageToPreviewMultiple?.length > 0) {
      dispatch(
        submitEntityIdProcess({
          entityUid: selectedEntity?.trellis_uid,
          formData: payload,
        }),
      );
      dispatch(clearIdProcessMultipleData());
      dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
      dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
    } else {
      toast.error(t('somethingWentWrongText', { ns: 'notificationMessages' }));
    }
  };

  // HTML
  return (
    <Box>
      <UploadDoc
        buttonText={'Submit'}
        handleNext={handleNext}
        isMultiple={true}
        previewFiles={preview}
        title={t('form-heading', { ns: 'entityOwnership' })}
      />
    </Box>
  );
};

export default OwnershipDocConfirmation;
