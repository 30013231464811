import React from 'react';
import { Box, Icon, Theme } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../providers/custom-theme-provider';
import successImgBg from '../../../components/myde-react-components/src/common/assets/success-placeholder.svg';
import { TButton, theme, TPaper } from '../../myde-react-components';
import { useLocation } from 'react-router';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  iconContainer: {
    backgroundColor: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
}));

const SuccessPage = () => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes: currentThemes,
  };
  const params: any = useLocation();
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();

  const handleContinue = () => {
    if (params?.state?.isEntitySuccess) {
      routeTo(ROUTES.ENTITY_INVITE);
    } else {
      routeTo(ROUTES.TAX_ID_DETAILS, true, 'Entity');
    }
  };

  return (
    <>
      <TPaper>
        <Box>
          <Box className="flex-column-center text-center">
            <Box className="flex-basic-center" sx={{ my: 4 }}>
              <Box className="p-relative">
                <img src={successImgBg} alt="Success" />
                <div className={clsx('circularIcon flex-basic-center', classes.iconContainer)}>
                  <Icon className={classes.icon}>check_icon</Icon>
                </div>
              </Box>
            </Box>
          </Box>
          {params?.state?.isEntitySuccess ? (
            <Box className="text-center textColor-200">
              <Box className="text-h2 font-weight-semibold">{t('congratulations')}</Box>
              <Box sx={{ px: 5 }} className="text-h3 font-weight-semibold">
                {t('requestSent')}
              </Box>
            </Box>
          ) : (
            <>
              <Box className="text-center textColor-200">
                <Box className="text-h2 font-weight-semibold">{t('congratulations')}</Box>
                <Box className="text-h3 font-weight-semibold">{t('entityCreated')}</Box>
              </Box>
              <Box sx={{ p: theme.spacing(3), m: 2 }} className="statusBackground">
                <Box className="flex-basic-space-between">
                  <Box className="font-weight-semibold text-medium textColor-200">{t('taxIdVerified')}</Box>
                  <span className="icon-approved success-color-state icon-size-24"></span>
                </Box>
              </Box>
              <Box sx={{ px: 3 }} className="text-medium text-center">
                {t('detailsWontBeShared')}
              </Box>
            </>
          )}

          <Box sx={{ px: 8, pt: 4, pb: 10 }}>
            <TButton
              btnText={params?.state?.isEntitySuccess ? 'OK, Great!' : 'Continue'}
              variant="contained"
              btnWidthSize="button-w-240"
              onClick={handleContinue}
            />
          </Box>
        </Box>
      </TPaper>
    </>
  );
};

export default SuccessPage;
