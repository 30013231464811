import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { TPaper } from '../../myde-react-components';
import CustomTabs, { TabListType } from '../../myde-react-components/src/components/Tabs/Tabs';
import {
  entityInvitessList,
  selectEntity,
  setSelectedTabName,
  setEntityMembersFormData,
} from '../../../redux/feature/entity/entitySlice';
import { EntityInvitionsType, EntityUserDataListType } from '../../../types/entityTypes';
import MemberDataList from './MemberDataList';
import { ID_CARD_NAV_INDEX, ROLES } from '../../../constants/constants';
import RequestsIndex from './RequestsIndex';
import { RouteType } from '../../../types/commonTypes';
import * as ROUTES from '../../../constants/routes';
import { setActiveCardNav, setParentRoute } from '../../../redux/feature/common/commonSlice';
import { selectNotification, setSpecificNotification } from '../../../redux/feature/notification/notificationSlice';
import { NotificationsResultType } from '../../myde-react-components/src/components/Notifications/notificationsType';

const MemberIndex = () => {
  //Redux
  const { allEntityInviteesList, selectedEntity, selectedTabName } = useSelector(selectEntity);
  const { specificNotification } = useSelector(selectNotification);

  //state var
  const [entityMembersData, setEntityMembersData] = useState([] as EntityInvitionsType[]);
  const [currentTab, setCurrentTab] = useState('');

  //const
  const dispatch = useDispatch();
  const tabList: TabListType[] = [
    {
      id: ROLES.OWNER,
      label: 'Owner(s)',
      component: <MemberDataList tabName={currentTab} data={entityMembersData} />,
    },
    {
      id: ROLES.USER,
      label: 'User(s)',
      component: <MemberDataList tabName={currentTab} data={entityMembersData} />,
    },
    { id: ROLES.REQUEST, label: 'Request(s)', component: <RequestsIndex /> },
  ];

  //useEffects
  useEffect(() => {
    dispatch(setEntityMembersFormData({} as EntityUserDataListType));
    const route: RouteType = {
      path: ROUTES.ENTITY_MY_IDENTITY,
      isChild: false,
      stateName: '',
      query: null,
      stateObj: null,
    };
    dispatch(setParentRoute(route));
    dispatch(setActiveCardNav(ID_CARD_NAV_INDEX.SETTINGS));
    return () => {
      dispatch(setSelectedTabName(''));
      dispatch(setSpecificNotification({} as NotificationsResultType));
    };
  }, []);

  useEffect(() => {
    if (selectedTabName) {
      setCurrentTab(selectedTabName);
    }
  }, [selectedTabName]);

  useEffect(() => {
    if (specificNotification?.id) {
      dispatch(setSelectedTabName(ROLES.REQUEST));
    } else {
      dispatch(setSelectedTabName(ROLES.OWNER));
    }
  }, [specificNotification]);

  useEffect(() => {
    if (currentTab && currentTab !== ROLES.REQUEST) {
      const payload = {
        entityUid: selectedEntity?.trellis_uid,
        userData: {
          user_role: currentTab,
          ordering: '-created_on',
        },
      };
      dispatch(entityInvitessList(payload));
    }
  }, [currentTab, selectedEntity]);

  useEffect(() => {
    setEntityMembersData(allEntityInviteesList);
  }, [allEntityInviteesList]);

  //methods
  const setSelectedTab = (value: string) => {
    dispatch(setSelectedTabName(value));
  };

  return (
    <TPaper className="memberTCardHeight">
      <Box sx={{ px: 3 }}>
        {currentTab && (
          <CustomTabs
            tabSelected={setSelectedTab}
            selectedTab={currentTab}
            tabList={tabList}
            borderRequired={false}
            showBottomBorder={false}
          />
        )}
      </Box>
    </TPaper>
  );
};

export default MemberIndex;
