import React from 'react';
import { Box } from '@mui/material';

import { NotificationsResultType } from './notificationsType';
import NotificationsListing from './NotificationsListing';

interface NotificationsTabProps {
  tab: string;
  data: NotificationsResultType[];
  hasMore?: boolean;
  children?: any;
  heightClass: string;
  fetchMoreData?: (tab: string) => void;
  handleNotificationClick: (notification: NotificationsResultType, tab: string) => void;
  handleMarkAsReadClick: (data: string[], tab: string) => void;
  unreadCount: number;
}

const NotificationsTab = ({
  tab,
  data,
  handleNotificationClick,
  handleMarkAsReadClick,
  fetchMoreData,
  heightClass,
  hasMore,
  children = <></>,
  unreadCount,
}: NotificationsTabProps) => {
  return (
    <Box>
      <NotificationsListing
        handleMarkAsReadClick={handleMarkAsReadClick}
        handleNotificationClick={handleNotificationClick}
        data={data}
        tab={tab}
        fetchMoreData={fetchMoreData}
        hasMore={hasMore}
        heightClass={heightClass}
        unreadCount={unreadCount}
      >
        {children}
      </NotificationsListing>
    </Box>
  );
};

export default NotificationsTab;
