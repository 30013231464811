import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { selectEntity, getEntityList } from '../../redux/feature/entity/entitySlice';
import EntityList from '../../components/id-card/EntityList';
import { TAccordion, TLoader } from '../../components/myde-react-components';
import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../providers/custom-router-provider';
import { setEntityDetailFlag, setEntitySelectionFlag } from '../../redux/feature/user/userSlice';
import { setCardState } from '../../redux/feature/common/commonSlice';
import { useTheme } from '../../providers/custom-theme-provider';
export interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  btnWrapper: {
    '& .MuiButton-root': {
      color: '#fff',
    },
  },
  btnBgColor: {
    background: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const IdCardSelection = () => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { loading } = useSelector(selectEntity);
  const { t } = useTranslation('common');

  useEffect(() => {
    dispatch(getEntityList());
  }, []);

  const addEntity = () => {
    dispatch(setEntitySelectionFlag(false));
    dispatch(setEntityDetailFlag(false));
    dispatch(setCardState(false));
    routeTo(ROUTES.SEARCH_ENTITY, true, 'Add Entity');
  };

  return (
    <>
      <TLoader loading={loading} />
      {!loading && (
        <>
          <Box sx={{ pb: 3 }}>
            <TAccordion
              isExpanded={true}
              accordinHeaderText={t('aboutEntites')}
              accordinBodyText1={t('entityBody1')}
              accordinBodyText2={t('entityBody2')}
              accordinBodyText3={t('entityBody3')}
            ></TAccordion>
          </Box>
          <Box className="p-relative">
            <Box sx={{ pb: 16 }}>
              <EntityList />
            </Box>
            <Box
              className={clsx('floating-btn entityListFloating flex-basic-center cursorPointer', classes.btnBgColor)}
              onClick={addEntity}
            >
              <span className="icon-add"></span>
            </Box>
          </Box>
          <Box
            sx={{ mt: 2 }}
            className={clsx({
              [classes.btnWrapper]: true,
              ['flex-basic-center']: true,
            })}
          ></Box>
        </>
      )}
    </>
  );
};

export default IdCardSelection;
