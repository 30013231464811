import React from 'react';
import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SUPPORT_EMAILS } from '../myde-react-components/src/constants/constants';

const PublicFooter = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <Box className="flex-basic-space-between">
        <Box>
          <Link
            href={process.env.REACT_APP_PRIVACY_POLICY}
            target="_blank"
            className="textDecorationNone text-medium cursorPointer"
            rel="noopener noreferrer"
          >
            {t('privacyPolicy')}
          </Link>
        </Box>
        <Box>
          <Link
            className="text-medium cursorPointer textDecorationNone"
            href={`mailto: ${SUPPORT_EMAILS.HELP_ASSISTANCE}`}
            rel="noopener noreferrer"
          >
            {t('helpandAssistance')}
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default PublicFooter;
