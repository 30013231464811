import { makeStyles } from '@mui/styles';
import React from 'react';
import { PORTAL } from '../../constants/portal';
import { Theme } from '@mui/material';

const useStyles = makeStyles<Theme, StyleProps>({
  container: { height: ({ maxArea }) => (maxArea ? '600px' : '200px'), width: '100%' },
  webviewer: { height: '600px', width: '100%' },
});

interface PdfViewerProps {
  uri: string;
  domain: string;
  maxArea?: boolean;
}

export interface StyleProps {
  maxArea?: boolean;
}

export const PdfViewer = ({ uri, domain, maxArea }: PdfViewerProps) => {
  const styleProps = {
    maxArea,
  };
  const classes = useStyles(styleProps);
  return (
    <div className={domain === PORTAL.MERCHANT ? classes.webviewer : classes.container}>
      <iframe
        loading="lazy"
        id="pdf-js-viewer"
        src={`${process.env.REACT_APP_BASE_URL}/pdfjs-2.14.305-dist/web/viewer.html?file=${encodeURI(uri)}`}
        frameBorder="0"
        width="100%"
        height="100%"
        style={{ flex: 1 }}
      />
    </div>
  );
};

export default PdfViewer;
