import React, { useEffect, useState } from 'react';
import { FormControl, Box, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAddress } from '../../../../../redux/feature/idProcess/documentVerificationSlice';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ProfileDetails } from '../../../../../types/profileTypes';
import { getProfileDetailsList, selectProfile } from '../../../../../redux/feature/profile/profileSlice';
import { removeUnderscore } from '../../../../../utils/utils';
import { PROCESS_IDS } from '../../../../../constants/constants';
import {
  ID_PROCESS_STATUS_COMPLETION_STATE,
  ID_PROCESS_STEP_KEYS,
  TButton,
  TCard,
  theme,
  TSelect,
} from '../../../../myde-react-components';
import { setCurrentStep, setPreviousStep } from '../../../../../redux/feature/common/commonSlice';
import {
  getProcessDetailsById,
  selectSelectedIdProcessDetails,
} from '../../../../../redux/feature/idProcess/mainIdProcessSlice';

const useStyles = makeStyles({
  selectField: {
    width: '100%',
  },
});

const ProfileSelection = () => {
  // constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'idDocumentFlow']);
  const { processId } = useSelector(selectSelectedIdProcessDetails);
  const { profileList } = useSelector(selectProfile);

  // states
  const [value, setValue] = useState('');
  const [profileHolder, setProfileHolder] = useState<ProfileDetails>();
  const [addressType, setAddressType] = useState('');
  const [cardHeading, setCardHeading] = useState('');

  // useEffects
  useEffect(() => {
    dispatch(getProcessDetailsById({ processId: processId }));
    dispatch(getProfileDetailsList({}));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[3].percent));
  }, []);

  useEffect(() => {
    if (profileList?.length === 1) {
      setProfileHolder(profileList[0]);
      setValue(profileList[0]?.profile_name);
    }
  }, [profileList]);

  useEffect(() => {
    if (profileHolder?.id) {
      dispatch(setSelectedAddress(profileHolder));
    }
    profileHolder?.address_type && setAddressType(removeUnderscore(profileHolder?.address_type));
  }, [profileHolder]);

  useEffect(() => {
    if (processId === PROCESS_IDS.DRIVERS_LICENSE) {
      setCardHeading(t('idDocStep1License', { ns: 'idDocumentFlow' }));
    } else if (processId === PROCESS_IDS.PASSPORT) {
      setCardHeading(t('idDocStep1', { ns: 'idDocumentFlow' }));
    }
  }, [processId]);

  // methods
  const handleChange = (event: any) => {
    if (profileList && profileList.length > 0) {
      const filterredAddress: any = profileList.find(
        (profile: ProfileDetails) => profile?.profile_name === event.target.value,
      );
      setProfileHolder(filterredAddress);
      setValue(filterredAddress?.profile_name);
    }
  };

  const handleNext = () => {
    dispatch(setCurrentStep(ID_PROCESS_STEP_KEYS.STEP3));
    dispatch(setPreviousStep(ID_PROCESS_STEP_KEYS.STEP2));
  };

  const renderAddress = () => {
    return (
      <>
        {profileHolder && (
          <Box>
            <Box>{profileHolder?.address?.address1}</Box>
            <Box>{profileHolder?.address?.address2}</Box>
            <Box>{profileHolder?.address?.address3}</Box>
            <Box>
              {profileHolder?.address?.city}, {profileHolder?.address?.state}
            </Box>
            <Box>{profileHolder?.address?.zip}</Box>
          </Box>
        )}
      </>
    );
  };

  const profileData = () => {
    const profileObj: { label: string; id: string }[] = [];
    if (profileList && profileList.length > 0) {
      profileList.forEach((element) => {
        profileObj.push({
          label: element?.profile_name,
          id: element?.profile_name,
        });
      });
    }
    return profileObj;
  };

  return (
    <TCard>
      <Box>
        <Box className="text-center textColor-300 text-large font-weight-semibold" sx={{ p: 3 }}>
          {cardHeading}
        </Box>
        <Divider />
        <Box className="text-center textColor-200 text-h3 font-weight-semibold" sx={{ pt: 3, px: 3 }}>
          {t('addressInstructions')}
        </Box>
        <Box sx={{ p: 3 }}>
          <Box>
            <FormControl className={classes.selectField} sx={{ mb: theme.spacing(5) }}>
              <TSelect
                value={value}
                options={profileData()}
                fullWidth
                label={'Select Profile'}
                itemValue={'label'}
                itemId={'id'}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          {profileHolder?.profile_name && (
            <Box sx={{ mb: 3 }}>
              <Box className="textColor-300 text-small font-weight-regular" sx={{ mb: 1 }}>
                {profileHolder?.profile_name} Profile {`(${addressType})`} :
              </Box>
              <Box className="textColor-200 text-medium font-weight-semibold">{renderAddress()}</Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="flex-column-center" sx={{ mb: 4 }}>
        <TButton
          sx={{ mb: 2 }}
          btnText={t('next')}
          variant="contained"
          btnWidthSize="button-w-240"
          disabled={!profileHolder}
          onClick={handleNext}
        />
      </Box>
    </TCard>
  );
};

export default ProfileSelection;
