import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../myde-react-components';
import useQuery from '../../../../hooks/useQuery';
import signSample from '../../../../common/assets/signSample.svg';
import {
  getVerificationItem,
  selectIdeProcessDetails,
} from '../../../../redux/feature/idProcess/signVerificationSlice';

const GenerateRandomNumber = () => {
  //constants
  const query = useQuery();
  const { t } = useTranslation(['wetSignature', 'common']);
  const processId = query.get('id');
  const dispatch = useDispatch();

  //state variables
  const [generatedNumber, setGeneratedNumber] = useState('');

  // Redux Values
  const { verificationItem, idProcessItem } = useSelector(selectIdeProcessDetails);

  // Use Effects
  useEffect(() => {
    if (!idProcessItem?.data?.verification_string) {
      const id = processId !== null ? processId : '';
      dispatch(getVerificationItem(id));
    }
  }, [idProcessItem]);

  useEffect(() => {
    const number =
      verificationItem?.verification_item?.length > 0
        ? verificationItem?.verification_item
        : idProcessItem?.data?.verification_string;
    setGeneratedNumber(number);
  }, [verificationItem, idProcessItem]);

  return (
    <>
      <Box>
        <Box className={'text-center text-h3 font-weight-semibold textColor-200'} sx={{ mb: 2 }}>
          {t('signDesc', { ns: 'wetSignature' })}
        </Box>
        <Box className="w-100 flex-basic-center">
          <Box sx={{ my: 2, py: theme.spacing(2) }} className="verificationItem text-medium text-center textColor-200">
            {generatedNumber}
          </Box>
        </Box>
        <Box className="text-center">
          <Box className="text-small textColor-200" sx={{ m: 1 }}>
            {t('exampleText', { ns: 'wetSignature' })}
          </Box>
          <img className="imgPreview" src={signSample} alt="sample sign" />
        </Box>
      </Box>
    </>
  );
};

export default GenerateRandomNumber;
