import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import IdProcessListIndex from './IdProcessList/IdProcessListIndex';
import { makeStyles } from '@mui/styles';
import { colorPalette, TLoader } from '../../components/myde-react-components';
import { getAccessToken, setEmailVerificationToken } from '../../utils/storage';
import {
  getIdProcessMasterDetails,
  getIdProcessDetails,
  selectDashboardDetails,
} from '../../redux/feature/dashboard/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/feature/user/userSlice';
import { getProfileDetails } from '../../redux/feature/profile/profileSlice';
import { selectSession, setRefreshPage } from '../../redux/feature/common/commonSlice';

const useStyles = makeStyles({
  loader: {
    color: colorPalette.backgroundFadeColors.secondary,
    zIndex: 10,
  },
  pageContainer: {
    minHeight: '100vh',
  },
});

const MyIdentity = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authToken } = useSelector(selectUser);
  const { loading } = useSelector(selectDashboardDetails);
  const { refreshPage } = useSelector(selectSession);

  useEffect(() => {
    const token = getAccessToken();
    setEmailVerificationToken('');
    if (token !== '') {
      dispatch(getIdProcessMasterDetails());
      dispatch(getIdProcessDetails());
      dispatch(getProfileDetails());
    }
  }, [authToken]);

  useEffect(() => {
    if (refreshPage) {
      dispatch(getIdProcessMasterDetails());
      dispatch(getIdProcessDetails());
      dispatch(getProfileDetails());
      dispatch(setRefreshPage(false));
    }
  }, [refreshPage]);

  return (
    <>
      {loading && <TLoader loading={loading} />}
      {!loading && (
        <Box sx={{ pt: 8 }} className={classes.pageContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <IdProcessListIndex />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default MyIdentity;
