import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import DateAdapter from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { CustomThemeProvider } from './providers/custom-theme-provider';
import { CustomToastProvider } from '../src/components/myde-react-components/src/providers/custom-toast-provider';
import allEn from './i18n/en/index';
import allFr from './i18n/fr/index';

i18n.use(initReactI18next).init({
  resources: {
    en: allEn,
    fr: allFr,
  },
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomThemeProvider>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <Suspense fallback="Loading...">
              <CustomToastProvider />
              <App />
            </Suspense>
          </LocalizationProvider>
        </CustomThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
