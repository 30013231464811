import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectEntity } from '../../../redux/feature/entity/entitySlice';
import { selectEntityProfile, getEntityProfileDetailsList } from '../../../redux/feature/profile/entityProfileSlice';
import { ProfileDetails } from '../../../types/profileTypes';
import { TButton, TCard, theme, TLoader } from '../../myde-react-components';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';

function TaxIdDetailView() {
  const { selectedEntity, loading, currentEntityTaxId } = useSelector(selectEntity);
  const { entityProfileList } = useSelector(selectEntityProfile);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();

  //Redux
  const { addEntityResult } = useSelector(selectEntity);

  //state
  const [profileDetails, setProfileDetails] = useState({} as ProfileDetails);

  //useEffect
  useEffect(() => {
    dispatch(getEntityProfileDetailsList({ entityUId: selectedEntity?.trellis_uid, params: {} }));
  }, [selectedEntity]);

  useEffect(() => {
    setProfileDetails(entityProfileList[0]);
  }, [entityProfileList]);

  const getMaskedTaxId = () => {
    const taxId = currentEntityTaxId;
    const masked = taxId?.slice(-4);
    let maskedNum = '';
    for (let character = taxId?.length - 4; character > 0; character--) {
      maskedNum += '*';
    }
    return maskedNum + masked;
  };

  const handleContinue = () => {
    //linking next sreen
    routeTo(ROUTES.ENTITY_ADD_MEMBERS, true, addEntityResult?.name ? addEntityResult?.name : 'Add Members');
  };

  return (
    <>
      <TLoader loading={loading} />
      <Box className="taxIdDetailCard">
        <TCard>
          <Box sx={{ pt: theme.spacing(5), px: 3 }}>
            <Box className="text-small textColor-300">{t('profileName')}</Box>
            <Box sx={{ pt: theme.spacing(1), pb: 2 }} className="font-weight-semibold text-medium">
              {profileDetails?.profile_name}
            </Box>
          </Box>
          <Box sx={{ pb: theme.spacing(5) }}>
            <Divider />
          </Box>
          <Box sx={{ pb: theme.spacing(5), px: 3 }}>
            <Box className="font-weight-semibold text-h3">{t('address')}</Box>
            <Box sx={{ pt: theme.spacing(5) }} className="text-small">
              {t('addressLine1')}
            </Box>
            <Box sx={{ pt: theme.spacing(1) }} className="font-weight-semibold text-medium">
              {profileDetails?.address?.address1}
            </Box>
            {profileDetails?.address?.address2 && (
              <>
                <Box sx={{ pt: theme.spacing(5) }} className="text-small">
                  {t('addressLine2')}
                </Box>
                <Box sx={{ pt: theme.spacing(1) }} className="font-weight-semibold text-medium">
                  {profileDetails?.address?.address2}
                </Box>
              </>
            )}
            {profileDetails?.address?.address3 && (
              <>
                <Box sx={{ pt: theme.spacing(5) }} className="text-small">
                  {t('addressLine3')}
                </Box>
                <Box sx={{ pt: theme.spacing(1) }} className="font-weight-semibold text-medium">
                  {profileDetails?.address?.address3}
                </Box>
              </>
            )}
            <Box sx={{ pt: theme.spacing(5) }} className="text-small">
              {t('city')}
            </Box>
            <Box sx={{ pt: theme.spacing(1) }} className="font-weight-semibold text-medium">
              {profileDetails?.address?.city}
            </Box>
            <Box className="d-flex">
              <Box sx={{ pt: theme.spacing(5) }} className="text-small">
                {t('state')}
                <Box sx={{ pt: theme.spacing(1) }} className="font-weight-semibold text-medium">
                  {profileDetails?.address?.state}
                </Box>
              </Box>
              <Box sx={{ pt: theme.spacing(5), pl: 4 }} className="text-small">
                {t('zip')}
                <Box sx={{ pt: theme.spacing(1) }} className="font-weight-semibold text-medium">
                  {profileDetails?.address?.zip}
                </Box>
              </Box>
              <Box sx={{ pt: theme.spacing(5), pl: theme.spacing(11) }} className="text-small">
                {t('country')}
                <Box sx={{ pt: theme.spacing(1) }} className="font-weight-semibold text-medium">
                  {profileDetails?.address?.country}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ pb: theme.spacing(5) }}>
            <Divider />
          </Box>
          <Box sx={{ pb: theme.spacing(5), px: 3 }}>
            <Box className="flex-basic-space-between">
              <Box className="font-weight-semibold text-h3">{t('taxId')}</Box>
              <Box className="p-relative">
                <span className="icon-error-warning error-color-state icon-size-24"></span>
              </Box>
            </Box>
            <Box sx={{ pt: theme.spacing(5) }} className="text-small">
              {t('taxId')}
            </Box>
            <Box sx={{ pt: theme.spacing(1) }} className="font-weight-semibold text-medium">
              {getMaskedTaxId()}
            </Box>
          </Box>
        </TCard>
        <Box sx={{ py: 5 }} className="text-center">
          <TButton btnText="Continue" variant="text" onClick={handleContinue} />
        </Box>
      </Box>
    </>
  );
}

export default TaxIdDetailView;
