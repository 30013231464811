export type LocationType = {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: any;
};

export type RouteType = {
  path: string;
  isChild: boolean;
  stateName: string;
  query: any;
  stateObj: any;
};

export type CountryType = {
  id: string;
  name: string;
};

export type EntityType = {
  id: string;
  name: string;
};

export type StateType = {
  id: string;
  name: string;
  country: string;
};

export type CityType = {
  id: string;
  city: string;
  state: string;
  state_code: string;
  country: string;
  country_iso2: string;
};

export type ClickHandlerType = {
  (param?: any): void;
};

export type ValidateEmailType = {
  email: string;
};

export type RouteToType = {
  isChild?: boolean;
  stateName?: string;
  query?: any;
  stateObj?: any;
};

export type ListingType = {
  id: string;
  title: string;
  subtitle: string;
  icon: string;
  hasDocuments?: boolean;
  isRead?: boolean;
};

export type TipsType = {
  tipsListItem: string;
};

export type ImageUrlsType = {
  imgFrontUrl?: string;
  imageBackUrl?: string;
  avatarImageUrl?: string;
  uploadedImagesUrl?: string;
};

export interface PhotoCaptureInstructionProps {
  instructionTitle: string;
  imagesUrl?: ImageUrlsType;
  photoCaptureInstrucationSection?: boolean;
  captureDescriptionNote?: string;
  tipsList?: string[];
  imageCaption?: string;
}

export enum UploadCaptureButtons {
  UPLOAD = 'upload',
  CAPTURE = 'capture',
}

export type WebSocketDetailsType = {
  status: string;
};

export type WebSocketDataType = {
  focus_item: string;
  resource_id: string;
  resource_name: string;
  message: string;
  navigate_to: string;
  details: WebSocketDetailsType;
  category: string;
};

export type ChangePasswordType = {
  email?: string;
  current_password: string;
  new_password: string;
};
