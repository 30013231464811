import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { TCard, theme, TLoader } from '../../../components/myde-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationCommunicationDetails, messageDetails } from '../../../redux/feature/message/messageSlice';
import { MessageResultType } from '../../../types/messageTypes';
import { formatDate, getTimeStamp, titleCase } from '../../../utils/utils';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { RouteType } from '../../../types/commonTypes';
import { setParentRoute, setPreviousStep } from '../../../redux/feature/common/commonSlice';
import useQuery from '../../../hooks/useQuery';

const Messages = () => {
  const { t } = useTranslation('userViewMessage');
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const query = useQuery();
  const applicationId = query.get('applicationId') || '';

  const { applicationCommunicationList, loading } = useSelector(messageDetails);

  const [noResponse, setNoResponse] = useState(false);
  const [communicationList, setCommunicationList] = useState<Array<MessageResultType>>([]);

  useEffect(() => {
    const route: RouteType = {
      path: ROUTES.USER_MESSAGES,
      isChild: false,
      stateName: '',
      query: { applicationId },
      stateObj: undefined,
    };
    dispatch(setParentRoute(route));
    dispatch(setPreviousStep(null));
    if (applicationId) {
      const data = {
        applicationId: applicationId,
        filters: {
          ordering: '-last_replied_on',
        },
      };
      dispatch(getApplicationCommunicationDetails(data));
    }
  }, []);

  useEffect(() => {
    if (applicationCommunicationList?.length > 0) {
      setCommunicationList(applicationCommunicationList);
    } else {
      setCommunicationList([] as MessageResultType[]);
    }
  }, [applicationCommunicationList]);

  useEffect(() => {
    if (communicationList?.length === 0) {
      setNoResponse(true);
    } else {
      setNoResponse(false);
    }
  }, [communicationList]);

  const selectMessage = (item: MessageResultType) => {
    const communicationData = {
      applicationId: applicationId,
      communicationId: item?.id,
    };
    routeTo(ROUTES.APPLICATION_MESSAGE_DETAILS, true, 'Application Messages', communicationData);
  };

  return (
    <TCard sx={{ p: 3 }}>
      <TLoader loading={loading} />
      {noResponse ? (
        <Box sx={{ pt: 5 }} className="text-center">
          <Box sx={{ mb: 3 }} className="icon-chat icon-size-72 textColor-400"></Box>
          <Box className="text-h3 font-weight-semibold textColor-300">
            {t('noMessageText', { ns: 'userViewMessage' })}
          </Box>
        </Box>
      ) : (
        <>
          {communicationList &&
            communicationList?.map((item, index) => (
              <Box key={item?.id} className="flex-column-center align-items-start">
                <Box className="flex-basic-start align-items-start">
                  <Box sx={{ mr: 2, mt: theme.spacing(3) }} className="icon-chat icon-size-20"></Box>
                  <Box
                    className="p-relative"
                    sx={{ mt: 1, pb: index + 1 !== communicationList?.length ? theme.spacing(5) : 0 }}
                    onClick={() => selectMessage(item)}
                  >
                    {item?.unread_response_count > 0 ? (
                      <Box className="flex-basic-space-between cursorPointer">
                        <Box
                          sx={{ mr: 3 }}
                          className="text-medium font-weight-semibold textColor-200 text-break-word text-truncate-lines"
                        >
                          {item?.request}
                        </Box>
                      </Box>
                    ) : (
                      <Box className="flex-basic-space-between cursorPointer mb-4">
                        <Box
                          sx={{ mb: theme.spacing(1) }}
                          className="text-medium textColor-200 text-break-word text-truncate-lines"
                        >
                          {titleCase(item?.request)}
                        </Box>
                        {item?.unread_response_count > 0 && <Box>{item?.unread_response_count}</Box>}
                      </Box>
                    )}

                    {item?.section && <Box className="text-extra-small textColor-300">Section: {item?.section} |</Box>}
                    <Box className={clsx('text-extra-small textColor-300')}>{`Asked on ${formatDate(
                      item?.created_on,
                    )} at ${getTimeStamp(item?.created_on)} by ${item?.account?.first_name} ${
                      item?.account?.last_name
                    }`}</Box>
                  </Box>
                </Box>
                {communicationList?.length > 1 && index + 1 !== communicationList?.length && (
                  <Divider sx={{ mb: theme.spacing(5) }} className="w-100" />
                )}
              </Box>
            ))}
        </>
      )}
    </TCard>
  );
};

export default Messages;
