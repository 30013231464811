import React, { useEffect, useState } from 'react';
import { Box, Theme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { upperCase } from 'lodash';
import clsx from 'clsx';

import { TCard, TCommonAvatar } from '../../components/myde-react-components';
import { fontStyle } from '../../styles/style';
import { CreatedRequestType } from '../../types/entityTypes';
import { formatDate, hexToRgbA } from '../../utils/utils';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import { setChildRoute } from '../../redux/feature/common/commonSlice';
import { ENTITY_INVITE_REQUEST_TYPE, REQUEST_REVIEW_STATUS } from '../../constants/constants';
import { setSelectedRequest } from '../../redux/feature/entity/entitySlice';
import { makeStyles } from '@mui/styles';
import { colorPalette } from '../../components/myde-react-components';
interface EntityInviteListProps {
  tabId: number;
  data: CreatedRequestType[];
}

const useStyles = makeStyles<Theme>({
  boldUnreadCount: {
    borderColor: () => `${colorPalette.error} !important`,
    color: () => `${colorPalette.error} !important`,
    backgroundColor: () => hexToRgbA(`${colorPalette.error}`, 0.08),
  },
});

function EntityInviteList({ tabId, data }: EntityInviteListProps) {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'userViewMessage']);
  const { routeTo } = useRouter();
  const dispatch = useDispatch();

  //state variables
  const [requests, setRequests] = useState([] as CreatedRequestType[]);

  //useEffects
  useEffect(() => {
    if (data?.length > 0) {
      setRequests(data);
    }
  }, [data]);

  const handleCard = (item: CreatedRequestType) => {
    const route = {
      path: ROUTES.ENTITY_INVITE,
    };
    const query = {
      type: tabId === 1 ? ENTITY_INVITE_REQUEST_TYPE.SENT : ENTITY_INVITE_REQUEST_TYPE.RECEIVED,
    };
    dispatch(setChildRoute(route));
    dispatch(setSelectedRequest(item));
    if (item?.review_status === REQUEST_REVIEW_STATUS.REJECTED) {
      routeTo(ROUTES.ENTITY_INVITE_DECLINE, true, `${item?.requested_account?.name}`, query);
    } else {
      routeTo(ROUTES.ENTITY_INVITE_DETAILS, true, `${item?.requested_account?.name}`, query);
    }
  };

  return (
    <>
      {requests?.length === 0 && (
        <Box sx={{ pt: 5 }} className="text-h3 font-weight-semibold textColor-300 text-center">
          {t('nodataAvailable', { ns: 'userViewMessage' })}
        </Box>
      )}
      <Box className="tabListScroll">
        {requests?.map((item: CreatedRequestType) => {
          return (
            <TCard
              key={item?.id}
              sx={{ mb: 3 }}
              className="requestBackground cursorPointer"
              onClick={() => handleCard(item)}
            >
              <Box className="flex-basic-space-between">
                <Box sx={{ p: 2 }} className="flex-basic-start">
                  <TCommonAvatar
                    imageUrl={item?.requested_account?.avatar_url || ''}
                    avatarText={item?.requested_account?.name || ''}
                    iconSize="4.5rem"
                    textSize={fontStyle.fontSize.font20.fontSize}
                  />
                </Box>
                <Box className="w-80">
                  <Box sx={{ pb: 1 }} className="flex-basic-space-between text-small textColor-300">
                    <Box>{t('entityName')}</Box>
                    <Box sx={{ pr: 3 }}>{formatDate(item?.created_on)}</Box>
                  </Box>
                  <Box className="font-weight-semibold text-medium">{upperCase(item?.requested_account?.name)}</Box>
                  {item?.review_status === REQUEST_REVIEW_STATUS.REJECTED && tabId === 1 && (
                    <Box sx={{ mr: 3 }} className="flex-basic-end">
                      <Box className={clsx('unreadCountBox', classes.boldUnreadCount)}>{`Declined`}</Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </TCard>
          );
        })}
      </Box>
    </>
  );
}

export default EntityInviteList;
