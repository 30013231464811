import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { colorPalette, theme, TPaper } from '../../myde-react-components';
import AddMembersForm from './AddMembersForm';
import { selectEntity, sendInviteToMembers, setEntityMembersFormData } from '../../../redux/feature/entity/entitySlice';
import { EntityMembersFormData, EntityMembersPayload } from '../../../types/entityTypes';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { setPreviousStep } from '../../../redux/feature/common/commonSlice';
import { USER_TYPES } from '../../../constants/constants';
import useQuery from '../../../hooks/useQuery';

const useStyles = makeStyles({
  instructionContainer: {
    backgroundColor: colorPalette.containerBack.base,
    borderRadius: '10px',
  },
});

const AddOwnerUser = () => {
  //Constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { routeTo } = useRouter();
  const query = useQuery();
  const isPostOnboardingFlow = query.get('isPostOnboarding') === 'true';

  // Redux Values
  const { entityMembersFormData, selectedEntity, allEntityMembersList } = useSelector(selectEntity);

  // Use Effects
  useEffect(() => {
    dispatch(setPreviousStep(null));
  }, []);

  useEffect(() => {
    if (
      isPostOnboardingFlow &&
      entityMembersFormData?.ownerList &&
      Object.keys(entityMembersFormData?.ownerList).length > 0
    ) {
      const membersDetails = [...entityMembersFormData?.ownerList];
      const payload: EntityMembersPayload = {
        entity_uid: selectedEntity?.trellis_uid || 0,
        membersData: { user_list: membersDetails },
      };
      dispatch(sendInviteToMembers(payload));
    }
  }, [entityMembersFormData]);

  // Methods
  const updateOwnerData = (ownerData: EntityMembersFormData[]) => {
    dispatch(setEntityMembersFormData({ ownerList: ownerData }));
    if (!isPostOnboardingFlow) {
      routeTo(ROUTES.ENTITY_ADD_MEMBER_USERS, true, 'Add Members');
    }
  };

  //HTML
  return (
    <TPaper sx={{ p: 3, mb: 8 }}>
      <Box className="flex-column-center">
        {!isPostOnboardingFlow && (
          <Box
            sx={{ p: theme.spacing(5) }}
            className={clsx('textColor-200 text-medium text-center', classes.instructionContainer)}
          >
            {t('addMembersLater', { ns: 'inviteFlow' })}
          </Box>
        )}
        <Box className="w-100">
          <AddMembersForm
            userType={USER_TYPES.OWNER}
            btnText={isPostOnboardingFlow ? 'Send Invite' : 'Next'}
            memberList={entityMembersFormData?.ownerList || []}
            isPostOnboardingFlow={isPostOnboardingFlow}
            allEntityMembersData={allEntityMembersList}
            onBtnClick={updateOwnerData}
          />
        </Box>
      </Box>
    </TPaper>
  );
};

export default AddOwnerUser;
