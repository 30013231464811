import { RootState } from '../..';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MerchantInviteList, MerchantIdProcessType, StartApplicationResultType } from '../../../types/inviteTypes';
import { getInvites, getStartedApplications } from '../../../api/invite';

type InviteState = {
  inviteList: any[];
  merchantInviteList: MerchantInviteList[];
  loading: boolean;
  error: boolean;
  merchantApplicationMessage: any;
  merchantIdProcessData: MerchantIdProcessType;
  selectedMerchant: MerchantInviteList;
  apiComplete: boolean;
  startedApplications: StartApplicationResultType[];
  isApplicationStartedFlag: boolean;
};

const initialState: InviteState = {
  loading: false,
  error: false,
  apiComplete: false,
  inviteList: [],
  merchantInviteList: [],
  merchantApplicationMessage: <any>{},
  merchantIdProcessData: <MerchantIdProcessType>{},
  selectedMerchant: <MerchantInviteList>{},
  startedApplications: [],
  isApplicationStartedFlag: false,
};

export const inviteDetails = ({ invite }: RootState) => ({
  inviteList: invite.inviteList,
  loading: invite.loading,
  apiComplete: invite.apiComplete,
  merchantInviteList: invite.merchantInviteList,
  merchantIdProcessData: invite.merchantIdProcessData,
  merchantApplicationMessage: invite.merchantApplicationMessage,
  selectedMerchant: invite.selectedMerchant,
  startedApplications: invite.startedApplications,
  isApplicationStartedFlag: invite.isApplicationStartedFlag,
});

export const getInvitesDetails = createAsyncThunk('invite/getInvitesDetails', async (filter: object) => {
  return await getInvites(filter);
});

export const getMerchantInviteDetails = createAsyncThunk('invite/getMerchantInviteDetails', async (filter?: object) => {
  return await getInvites(filter);
});

export const getInProgressApplications = createAsyncThunk('invite/getInProgressApplications', async () => {
  return await getStartedApplications();
});

export const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    resetFlag: (state) => {
      state.apiComplete = false;
    },
    setSelectedMerchant: (state, action) => {
      state.selectedMerchant = action.payload;
    },
    resetSelectedMerchant: (state) => {
      state.selectedMerchant = <MerchantInviteList>{};
    },
    resetMerchantIdProcessDetails: (state) => {
      state.merchantIdProcessData = <MerchantIdProcessType>{};
    },
    resetIsApplicationStartedFlag: (state) => {
      state.isApplicationStartedFlag = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvitesDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInvitesDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.inviteList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getInvitesDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getMerchantInviteDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMerchantInviteDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.merchantInviteList = action.payload.results;
          state.apiComplete = true;
        } else {
          state.error = true;
        }
      })
      .addCase(getMerchantInviteDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getInProgressApplications.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInProgressApplications.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.startedApplications = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getInProgressApplications.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  resetFlag,
  setSelectedMerchant,
  resetSelectedMerchant,
  resetMerchantIdProcessDetails,
  resetIsApplicationStartedFlag,
} = inviteSlice.actions;

export default inviteSlice.reducer;
