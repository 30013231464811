import React, { useEffect, useState } from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

import NotificationsTab from './NotificationsTab';
import CustomTabs, { TabListType } from '../Tabs/Tabs';
import { NotificationsResultType, NotificationTabType } from './notificationsType';
import { hexToRgbA } from '../../utils/commonMethods';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { theme } from '../../Theme';
import { colorPalette } from '../../constants/colors';

interface NotificationsIndexProps {
  notificationData: NotificationTabType[];
  handleNotificationClick: (notification: NotificationsResultType, tab: string) => void;
  handleMarkAsReadClick: (data: string[], tab: string) => void;
  fetchMoreData?: (tab: string) => void;
  hasMore?: boolean;
  children?: any;
  onClose?: () => void;
  defaultTab: string;
}

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  boldNotificationCount: {
    '& .countBox': {
      borderRadius: '50%',
      backgroundColor: colorPalette.boxShadowColors.lighten1,
    },
    '& .Mui-selected': {
      '& .countBox': {
        color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
        backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.3),
      },
    },
  },
});

const NotificationsIndex = ({
  notificationData,
  handleNotificationClick,
  handleMarkAsReadClick,
  fetchMoreData,
  hasMore,
  onClose,
  defaultTab,
}: NotificationsIndexProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation();

  //state variables
  const [tab, setTab] = useState(defaultTab);
  const [notificationTabs, setNotificationTabs] = useState([] as TabListType[]);

  useEffect(() => {
    if (notificationData?.length > 0) {
      const result = notificationData?.map((notification: NotificationTabType) => {
        return {
          id: notification?.id,
          label: notification?.label,
          component: (
            <NotificationsTab
              handleMarkAsReadClick={handleMarkAsReadClick}
              handleNotificationClick={handleNotificationClick}
              tab={tab}
              data={notification?.data}
              fetchMoreData={fetchMoreData}
              hasMore={hasMore}
              heightClass={notification?.heightClass}
              unreadCount={notification?.count}
            >
              <>{notification?.children}</>
            </NotificationsTab>
          ),
          tabChild: (
            <>
              <Box className="flex-basic-center">
                <Box>{notification?.label}</Box>
                {notification?.count > 0 && (
                  <Box className="countBox" sx={{ px: theme.spacing(2), ml: 1 }}>{`${notification?.count}`}</Box>
                )}
              </Box>
            </>
          ),
        };
      });
      setNotificationTabs(result);
    }
  }, [notificationData, tab]);

  return (
    <Box>
      <Box className="flex-basic-space-between">
        <Box sx={{ mb: 1, pt: 2 }} className="text-h2 textColor-200 font-weight-semibold">
          {t('notifications', { ns: 'common' })}
        </Box>
        {onClose && (
          <Box className="flex-basic-end cursorPointer" sx={{ mb: 1 }}>
            <CloseIcon onClick={() => onClose()} />
          </Box>
        )}
      </Box>
      <Divider />
      <Box className={classes.boldNotificationCount} sx={{ pt: 1 }}>
        <CustomTabs
          tabList={notificationTabs}
          borderRequired={false}
          showBottomBorder={false}
          tabSelected={setTab}
          selectedTab={tab}
        />
      </Box>
    </Box>
  );
};

export default NotificationsIndex;
