import React, { useEffect, useState, FC } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { colorPalette, PdfViewer, TCard } from '../../../components/myde-react-components';
import { selectDocuments } from '../../../redux/feature/documents/documentsSlice';
import { MerchantDocumentType } from '../../../types/documentTypes';
import { formatDate, getTimeStamp } from '../../../utils/utils';

interface FileDetailsProps {
  hideHeader?: boolean;
}

const useStyles = makeStyles({
  subHeading: {
    color: `${colorPalette.typoText.darken2} !important`,
  },
  image: {
    width: '100%',
    marginBottom: '9px!important',
  },
  breakWord: {
    wordBreak: 'break-all',
  },
});

const FileDetails: FC<FileDetailsProps> = ({ hideHeader }: FileDetailsProps) => {
  // Constants
  const classes = useStyles();

  // Redux Values
  const { selectedFile, file: document } = useSelector(selectDocuments);

  // State Values
  const [file, setFile] = useState({} as MerchantDocumentType);

  // Use Effects
  useEffect(() => {
    if (selectedFile && Object.keys(selectedFile)?.length > 0) {
      setFile(selectedFile);
    }
  }, [selectedFile]);

  // HTML
  return (
    <>
      <TCard sx={{ p: 3 }} className="scrollBarCard">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item className={classes.breakWord} md={9} xs={9} sm={9}>
            <Box className="flex-basic-start">
              <Box className="text-medium font-weight-semibold textColor-200">{file?.name}</Box>
            </Box>
          </Grid>
          <Grid item xs={1} md={1}>
            <Box className="flex-basic-center">
              <span
                className="icon-download-files textColor-200 cursorPointer text-font-20"
                onClick={() => window.open(document.signed_url)}
              ></span>
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2, mb: 1 }} direction="column">
          <Box className="text-small textColor-300">
            {`${formatDate(selectedFile?.modified_on)} | ${getTimeStamp(selectedFile?.modified_on)} | by ${
              selectedFile?.merchant_communication?.merchant?.name
            }`}
          </Box>
          {!hideHeader && (
            <Box className="text-small textColor-300">
              {[selectedFile?.details?.category, selectedFile?.details?.year_month]
                .filter((item) => !!item)
                .join(' | ')}
            </Box>
          )}
        </Grid>
        <Box sx={{ my: 2 }}>
          <span className="text-medium font-weight-semibold textColor-200">
            {selectedFile?.merchant_communication?.title}
          </span>
        </Box>
        {document?.mime_type?.includes('pdf') ? (
          <Box className={classes.image}>
            <PdfViewer domain={'myde'} uri={document?.signed_url || ''} />
          </Box>
        ) : (
          <img src={document.signed_url} className={classes.image} alt={document.name} />
        )}
        <Box className="text-small textColor-300">
          <div dangerouslySetInnerHTML={{ __html: selectedFile?.merchant_communication?.message || '-' }}></div>
        </Box>
      </TCard>
    </>
  );
};

export default FileDetails;
