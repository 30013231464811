import React from 'react';

const FullStarIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd">
      <path
        d="m10.606.395 2.567 5.45a.679.679 0 0 0 .508.386l5.74.874a.717.717 0 0 1 .374 1.208l-4.153 4.242a.73.73 0 0 0-.194.626l.98 5.99a.683.683 0 0 1-.98.746l-5.134-2.828a.648.648 0 0 0-.628 0l-5.134 2.828a.683.683 0 0 1-.98-.746l.98-5.99a.73.73 0 0 0-.194-.626L.205 8.313a.717.717 0 0 1 .374-1.208l5.74-.874a.679.679 0 0 0 .508-.387L9.394.394a.662.662 0 0 1 1.212 0Z"
        fill="#F6AB27"
      />
      <path
        d="M12.548 4.52a20.901 20.901 0 0 0-4.544 3.283 19.766 19.766 0 0 0-3.649 4.749L.204 8.314a.717.717 0 0 1 .375-1.207l5.738-.876a.689.689 0 0 0 .51-.386L9.394.393a.665.665 0 0 1 1.214 0l1.941 4.128Z"
        fill="#F4CD1E"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default FullStarIcon;
