export const LOGIN = '/login';

export const ONBOARDING = '/onboarding';
export const CREATE_ACCOUNT = '/onboarding/create-account';
export const VERIFY_EMAIL = '/onboarding/verify-email';
export const VERIFY_PROFILE_EMAIL = '/onboarding/verify-profile-email';
export const FORGOT_PASSWORD = '/onboarding/forgot-password';
export const RESET_PASSWORD = '/forget-password';
export const PASSWORD_RESET_SUCCESS = '/reset-password/success';
export const RESET_EXPIRED_PASSWORD = '/reset-password';

export const ID_PROCESS = '/id-process';
export const KYC_AML = '/kyc-aml';
export const BENEFICIAL_OWNER_DOCUMENT = '/beneficial-owner-documents';

export const INVITE = '/invite';
export const VIEW_INVITE = '/view-invite';
export const APPROVE_SUBMIT_INVITE = '/approve-invite';
export const INVITATION_STATUS = '/invite-status';

export const DASHBOARD = '/dashboard';

export const MY_T_APPS = '/my-t-apps';
export const MY_IDENTITY = '/my-identity';
export const USER_PROFILE = '/my-profile';
export const USER_MESSAGES = '/my-merchants';
export const USER_MESSAGES_INDEX = '/messages';
export const APPLICATION_LIST = '/application-list';
export const APPLICATION_MESSAGES = '/application-messages';
export const APPLICATION_MESSAGE_DETAILS = '/application-messages/details';
export const MY_INBOX = '/my-inbox';
export const MY_INBOX_MESSAGE_DETAILS = '/my-inbox/message-details';
export const MY_DOCUMENTS = '/my-documents';
export const MY_TAPPS = '/my-tapps';
export const SHARING = '/sharing';
export const MY_T_APPS_ABOUT = '/about';
export const EDIT_USER_PROFILE_PICTURE = '/edit-profile-picture';

export const MERCHANT_DOCUMENTS = '/merchant-documents';
export const DOCUMENTS_CATEGORY = '/documents-category';
export const CATEGORY_FOLDER = '/category-folder';
export const DOCUMENTS_FILES = '/document-files';
export const FILE_DETAILS = '/file-details';

export const ENTITIES = '/entity';
export const ADD_NEW_ENTITY = '/entity/add-new-entity';
export const ENTITY_ID_PROCESS = '/entity/id-process';
export const SEARCH_ENTITY = 'entity/search';
export const ENTITY_MY_IDENTITY = '/entity/my-identity';
export const ENTITY_VIEW = '/entity/entity-view';
export const ENTITY_ADD_OWNERS_USERS = '/entity/add-owners-users';
export const ENTITY_ADD_MEMBER_USERS = '/entity/add-member-users';
export const ENTITY_ADD_MEMBERS = '/entity/add-members';
export const ENTITY_PROFILE = '/entity/entity-profile';
export const EDIT_ENTITY_PROFILE_PICTURE = '/entity/edit-profile-picture';

export const INDEX = '/custom/index';
export const CUSTOM_DOCUMENTS = '/custom/listing';
export const CUSTOM_DOCUMENTS_CATEGORY = '/custom/category';
export const CUSTOM_CATEGORY_FOLDER = 'custom/folder';
export const CUSTOM_CATEGORY_DOCUMENTS = 'custom/document';

export const CUSTOM_DOCUMENTS_DESCRIPTION = '/custom/category-description';
export const TAX_ID_FAILED = '/taxId-failed';
export const TAX_ID_SUCCESS = '/passed';
export const TAX_ID_DETAILS = '/taxId-details';

export const ENTITY_INVITE = '/entity-invite';
export const ENTITY_INVITE_DETAILS = '/entity-invite-details';
export const ENTITY_INVITE_DECLINE = '/entity-decline';

export const SHARED_ID = '/shared-id';
export const SHARED_ID_DETAILS = '/shared-id-details';

export const NOTIFICATION_ROUTING = '/notification-routing';
export const COMING_SOON = '/coming-soon';

export const ENTITY_MEMBERS = '/entity-members';

export const ENTITY_MEMBERS_DETAILS = '/entity-members-details';
