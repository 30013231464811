import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { theme, TPaper } from '../../components/myde-react-components';
import AuthLayout from '../../layout/AuthLayout';
import clsx from 'clsx';
import { useTheme } from '../../providers/custom-theme-provider';

export interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  imgBg: {
    width: '122px',
    height: '122px',
    borderRadius: '100%',
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const ResetPasswordTokenExpired = () => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');

  return (
    <>
      <AuthLayout>
        <TPaper sx={{ p: 3 }}>
          <>
            <Box className={'flex-column-center'}>
              <Box sx={{ my: 4 }} className={clsx(classes.imgBg, 'flex-basic-center')}>
                <span className="icon-broken-link broken-icon"></span>
              </Box>

              <Box sx={{ mb: theme.spacing(3) }} className="text-center font-weight-semibold textColor-200 text-h2">
                {t('resetPasswordLinkInvalidTitle', { ns: 'forgotPassword' })}
              </Box>

              <Box sx={{ mb: 3 }} className="text-center textColor-200 text-medium">
                {t('resetPasswordLinkInvalidDesc', { ns: 'forgotPassword' })}
              </Box>
            </Box>
          </>
        </TPaper>
      </AuthLayout>
    </>
  );
};

export default ResetPasswordTokenExpired;
