import { createTheme } from '@mui/material/styles';
import { colorPalette } from './constants/colors';

export const theme = createTheme({
  palette: {
    primary: {
      dark: '#009F46',
      main: colorPalette.primary,
      light: '#14A952',
    },
    secondary: {
      light: '#EDF2F3',
      main: '#0044ff',
    },
    error: {
      main: colorPalette.error,
    },
  },
  typography: {
    fontFamily: ['Montserrat'].join(','),
    h2: {
      fontWeight: '600, Semi Bold',
      fontSize: '24px',
      lineHeight: '29px',
      color: colorPalette.typoText.darken1,
    },
    h3: {
      fontWeight: '700, Bold',
      fontSize: '16px',
      lineHeight: '20px',
      color: colorPalette.typoText.lighten1,
    },
    h4: {
      fontWeight: '700, Bold',
      fontSize: '18px',
      lineHeight: '22px',
      color: colorPalette.typoText.darken1,
    },
    body1: {
      fontWeight: '600, Bold',
      fontSize: '18px',
      lineHeight: '21px',
      color: colorPalette.typoText.lighten1,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '29px',
      color: colorPalette.typoText.darken2,
    },
    body2: {
      fontWeight: '400, Regular',
      fontSize: '14px',
      lineHeight: '18px',
      color: colorPalette.typoText.darken2,
    },
    caption: {
      fontWeight: '400, Regular',
      fontSize: '14px',
      lineHeight: '14px',
      color: colorPalette.typoText.lighten1,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: '#029E4A',
          fontWeight: '600, Semi Bold',
          padding: '5.5px 12.5px',
          textTransform: 'capitalize',
        },
        outlined: {
          color: '#029E4A',
          fontWeight: '600, Semi Bold',
          border: '1px solid #D7E0E2',
          borderRadius: '3px',
          background: 'linear-gradient(180deg, #FFFFFF 0%, #EDF2F3 100%)',
        },
        contained: {
          color: '#FFFFFF',
          fontWeight: '700, Bold',
          borderRadius: '3px',
          border: '1px solid #009F46',
          background: 'linear-gradient(180deg, #2EC96E 0%, #14A952 100%)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 600,
          color: colorPalette.typoText.darken2,
          fontFamily: 'Montserrat',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
  },

  //custom theme spacing
  spacing: 4,
});
