import React, { useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ProfileForm from '../../id-process/components/profile/ProfileForm';
import { ProfileFormDataType } from '../../../types/profileTypes';
import { createEntityForAnAccount, selectEntity, setSelectedEntity } from '../../../redux/feature/entity/entitySlice';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { ID_PROCESS_STATUS, TCard } from '../../myde-react-components';

interface AddressFormIndexProps {
  goToPreviousStep: (step: number) => void;
}

const AddressFormIndex = ({ goToPreviousStep }: AddressFormIndexProps) => {
  // Constants
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();
  const dispatch = useDispatch();

  // Use Selectors
  const { entityFormData, addEntityResult } = useSelector(selectEntity);

  // Use Effects
  useEffect(() => {
    if (addEntityResult?.id) {
      dispatch(setSelectedEntity(addEntityResult));
      if (addEntityResult?.details?.entity_info?.verification_status === ID_PROCESS_STATUS.PASSED) {
        routeTo(ROUTES.TAX_ID_SUCCESS, true, 'Tax Id');
      } else {
        routeTo(ROUTES.TAX_ID_FAILED, true, 'Tax Id');
      }
    }
  }, [addEntityResult]);

  // Methods
  const createEntityForAccount = (data: ProfileFormDataType) => {
    const profileInfo = {
      profile_name: data?.address?.type,
      profile_email: data?.profile_email,
      address1: data?.address?.address1,
      address2: data?.address?.address2,
      address3: data?.address?.address3,
      city: data?.address?.city,
      state: data?.address?.state,
      country: data?.address?.country,
      state_name: data?.address?.state_name,
      country_name: data?.address?.country_name,
      zip: data?.address?.zip,
      phones: data?.phones,
    };
    const formData = {
      ...entityFormData,
      profile: profileInfo,
    };
    dispatch(createEntityForAnAccount(formData));
  };

  const renderForm = () => {
    return (
      <TCard>
        <ProfileForm
          entityOnBoarding={true}
          isEntity={true}
          hasStepButton={true}
          saveProfileData={createEntityForAccount}
          goToPreviousStep={goToPreviousStep}
        >
          <Box sx={{ p: 2 }}>
            <Box className="text-large font-weight-semibold textColor-200" sx={{ mb: 1 }}>
              {t('addressFormDescription1')}
            </Box>
            <Box className="text-medium textColor-200" sx={{ mb: 1 }}>
              {`${t('addressFormDescription2')} ${entityFormData?.entity_name} ${t('addressFormDescription3')}`}
            </Box>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box className="text-h3 font-weight-semibold textColor-200" sx={{ pl: 3 }}>
            {t('entityForm')}
          </Box>
        </ProfileForm>
      </TCard>
    );
  };

  return <Box>{renderForm()}</Box>;
};
export default AddressFormIndex;
