import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../providers/custom-router-provider';

import DocumentListing from '../../../components/documents/DocumentListing';
import { TCard, TLoader, TSearchComponent } from '../../../components/myde-react-components';
import { DocumentCategoryType, MerchantDocumentType } from '../../../types/documentTypes';
import * as ROUTES from '../../../constants/routes';
import {
  clearFileList,
  getDocCategories,
  getFolderFiles,
  selectDocuments,
  setIsSearching,
  setMerchantId,
} from '../../../redux/feature/documents/documentsSlice';

import { setPreviousStep } from '../../../redux/feature/common/commonSlice';
import SearchListing from '../search-listing/SearchListing';
import useQuery from '../../../hooks/useQuery';

// Styles
const useStyles = makeStyles({
  pageContainer: {
    minHeight: '100vh',
  },
});

export const DocumentCategory = () => {
  // Constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();
  const query = useQuery();
  const merchantId = query.get('id') || '';
  const merchant = query.get('merchant') || '';
  const applicationId = query.get('applicationId') || '';
  const section = query.get('section') || '';
  const isEntity = query.get('isEntity') === 'true';
  const entity_uid = query.get('entity_uid') || '';

  // Redux Values
  const { categoryList, loading, isSearching, fileList } = useSelector(selectDocuments);

  // State Values
  const [categories, setCategories] = useState([] as DocumentCategoryType[]);
  const [searchValue, setSearchValue] = useState('');
  const [filesList, setFilesList] = useState([] as MerchantDocumentType[]);
  const [resetSearchFilter, setResetSearchFilter] = useState(false);
  const [showViewAllBtn, setShowViewAllBtn] = useState(false);

  // Use Effects
  useEffect(() => {
    const filters = {
      merchant_id: applicationId,
    };
    dispatch(getDocCategories(isEntity ? { ...filters, entity_uid: parseInt(entity_uid) } : filters));

    backSearchHandler();
    dispatch(setPreviousStep(null));
    dispatch(setMerchantId(merchantId));
  }, [merchantId, merchant, section]);

  useEffect(() => {
    setFilesList(fileList);
  }, [fileList]);

  useEffect(() => {
    setCategories(categoryList);
  }, [categoryList]);

  // Methods
  const selectCategory = (category: DocumentCategoryType) => {
    const query = {
      id: merchantId,
      category: category?.category,
      merchant: merchant,
      section: section,
      entity_uid: entity_uid,
      isEntity,
      applicationId: applicationId,
    };
    routeTo(ROUTES.CATEGORY_FOLDER, true, category?.category, query);
  };

  const searchHandler = (value: string) => {
    setSearchValue(value);
    if (value) {
      dispatch(getFolderFiles({ name: value, limit: 4, entity_uid: isEntity ? entity_uid : '' }));
      setShowViewAllBtn(true);
    } else {
      dispatch(clearFileList());
      setShowViewAllBtn(false);
    }
  };

  const searchBoxClickHandler = () => {
    dispatch(setIsSearching(true));
    setResetSearchFilter(false);
    setShowViewAllBtn(false);
  };

  const backSearchHandler = () => {
    setResetSearchFilter(true);
    dispatch(setIsSearching(false));
    dispatch(clearFileList());
  };

  const viewAllBtnClickHandler = () => {
    dispatch(getFolderFiles({ name: searchValue }));
    setShowViewAllBtn(false);
  };

  // HTML
  return (
    <Box className={classes.pageContainer}>
      <TLoader loading={loading} />
      {!loading && categoryList?.length !== 0 && (
        <TCard sx={{ py: 3 }} className="card-height-200">
          <Box className="flex-basic-start" sx={{ px: 3 }}>
            {isSearching && (
              <Box sx={{ pr: 2, pt: 1 }}>
                <span
                  className="icon-arrow-lefts textColor-200 cursorPointer actionIconFont-24"
                  onClick={backSearchHandler}
                ></span>
              </Box>
            )}
            <Box className="w-100" onClick={searchBoxClickHandler}>
              <TSearchComponent
                hideResetButton={true}
                placeholder="Search"
                onSearchUpdate={searchHandler}
                hasExtraFilters={true}
                resetSearchFilter={resetSearchFilter}
              />
            </Box>
          </Box>

          {isSearching ? (
            <Box className="scrollBox">
              <SearchListing
                filesList={filesList}
                showCategory={true}
                showYearMonth={true}
                showViewAllBtn={showViewAllBtn}
                viewAllBtnClickHandler={viewAllBtnClickHandler}
              />
            </Box>
          ) : (
            <Box className="scrollBox">
              {categories?.map((category, index) => {
                return (
                  <Box key={category?.category + index}>
                    <DocumentListing
                      categoryIcon={true}
                      name={category?.category}
                      icon={category?.icon_url}
                      count={category?.no_of_files}
                      onClick={() => selectCategory(category)}
                      showUnreadIcon={category?.unread_count > 0}
                      unreadCount={category?.unread_count}
                    />
                    {index < categories?.length - 1 && <Divider />}
                  </Box>
                );
              })}
            </Box>
          )}
        </TCard>
      )}

      {!loading && categories?.length === 0 && (
        <TCard className="documentCardHeight">
          <Box sx={{ mt: 4 }} className="text-center">
            <Box className="icon-document textColor-400 "></Box>
            <Box sx={{ pt: 3 }} className="text-h3 textColor-300 text-center font-weight-semibold">
              {t('noDocuments')}
            </Box>
          </Box>
        </TCard>
      )}
    </Box>
  );
};

export default DocumentCategory;
