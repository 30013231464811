import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from './axios';

export const getEntityList = async () => {
  const res = await apiGet('/entity');
  return res.data;
};

export const getIdProcessesOfAnEntity = async (entityId: number) => {
  const res = await apiGet(`/entity/${entityId}/id-process`);
  return res.data;
};

export const createNewEntity = async (formData: object) => {
  const res = await apiPost('/entity', formData);
  return res.data;
};

export const searchEntity = async (formData: object) => {
  const res = await apiPost('/entity/search', formData);
  return res.data;
};

export const inviteEntityMembers = async (data: any, entity_uid: number) => {
  const res = await apiPost(`/entity/${entity_uid}/invite`, data);
  return res.data;
};

//creating an entity associaion request
export const createNewRequest = async (data: object) => {
  const res = await apiPost('/entity/association-request', data);
  return res.data;
};
export const getEntityInvite = async () => {
  const res = await apiGet('/entity/invite');
  return res.data;
};

export const inviteAction = async (id: string, formData: object) => {
  const res = await apiPost(`/entity/invite/${id}`, formData);
  return res.data;
};

export const getEntityInviteDetailsByToken = async (token: string) => {
  const res = await apiGet(`/entity/invite/${token}`);
  return res.data;
};

export const startEntityApplication = async (formData: any) => {
  const res = await apiPost('/entity/application', formData);
  return res.data;
};

//get sent association requests
export const getSentRequests = async (params?: object) => {
  const res = await apiGet('/entity/association-request', { params: params });
  return res.data;
};

//get received association requests
export const getReceivedRequests = async (params: object) => {
  const res = await apiGet('/entity/association-request/review', { params: params });
  return res.data;
};

//patch request (review)
export const reviewRequest = async (id: string, data: object) => {
  const res = await apiPatch(`/entity/association-request/${id}`, data);
  return res.data;
};

//adding entity custom id process
export const addEntitiyCustomIdProcess = async (entityUid: number, formData: object) => {
  const res = await apiPost(`/entity/${entityUid}/id-process/custom`, formData);
  return res.data;
};

//editing entity custom id process
export const editEntityCustomIdProcess = async (entityUid: number, id: string, formData: object) => {
  const res = await apiPut(`/entity/${entityUid}/id-process/custom/${id}`, formData);
  return res.data;
};

//list entity's custom id processes
export const getEntityCustomIdProcesses = async (data: { entityUid: number | string; filters: object }) => {
  const res = await apiGet(`/entity/${data?.entityUid}/id-process/custom`, { params: data?.filters });
  return res.data;
};

//list indiviaual entity custom id process details
export const getEntityCustomIdProcessDetails = async (data: { entityUid: number; uuid: string }) => {
  const res = await apiGet(`/entity/${data?.entityUid}/id-process/custom/${data?.uuid}`);
  return res.data;
};

//revoke entity association request
export const revokeEntityRequest = async (requestId: string) => {
  const res = await apiPatch(`/entity/association-request/${requestId}/revoke`);
  return res.data;
};

//Get entity details
export const getEntityProfileDetails = async (entity_uid: number, params: object) => {
  const res = await apiGet(`/entity/${entity_uid}/entity-profile`, { params: params });
  return res.data;
};

//Update entity details
export const updateEntityProfileDetails = async (entity_uid: number, formData: object) => {
  const res = await apiPatch(`/entity/${entity_uid}/entity-profile`, formData);
  return res.data;
};

//entity member list
export const getEntityInvitessList = async (entityUid: number, params?: object) => {
  const res = await apiGet(`/entity/${entityUid}`, { params: params });
  return res.data;
};

export const getFilteredEntityList = async (params: object) => {
  const res = await apiGet('/entity', { params: params });
  return res.data;
};

//entity member details
export const updateEntityMemberDetails = async (entity_uid: number, trellis_uid: number, data: object) => {
  const res = await apiPatch(`/entity/${entity_uid}/member/${trellis_uid}`, data);
  return res.data;
};

//delete entity member
export const deleteEntityMember = async (entity_uid: number, trellis_uid: number) => {
  const res = await apiDelete(`/entity/${entity_uid}/member/${trellis_uid}`);
  return res.data;
};
