import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import {
  draftOrCreateIdProcess,
  postUploadedDoc,
  processDetailsById,
  getCountries,
  getAllStates,
} from '../../../api/idProcesses';
import {
  AddressResultType,
  CountriesDataType,
  StatesDataType,
  UploadedImageToPreviewType,
  IdProcessDocTypeListType,
  DetailResultType,
} from '../../../types/idProcessTypes';
import { ProfileDetails } from '../../../types/profileTypes';
import { IdProcessDetails } from '../../../types/dashboardTypes';
import { FileType } from '../../../types/documentTypes';

type DocumentState = {
  documentType: IdProcessDetails;
  loading: boolean;
  error: boolean;
  errorObj: any;
  uploadedFImageToPreview: UploadedImageToPreviewType;
  uploadedBImageToPreview: UploadedImageToPreviewType;
  uploadedFImage: FileType;
  uploadedBImage: FileType;
  selectedAddress: ProfileDetails;
  idProcessDLItem: IdProcessDocTypeListType;
  idProcessPItem: IdProcessDocTypeListType;
  selectedAddressToPreview: AddressResultType;
  countries: CountriesDataType;
  allStatesList: StatesDataType;
  documentSide: string;
  editDoc: boolean;
  isSubmited: boolean;
  selectedDocumentId: number;
  isDrafted: boolean;
  showDetails: boolean;
  detailsList: DetailResultType;
  dlLoading: boolean;
  passportLoading: boolean;
  uploading: boolean;
};

const initialState: DocumentState = {
  documentType: <IdProcessDetails>{},
  loading: false,
  error: false,
  errorObj: {},
  uploadedFImageToPreview: <UploadedImageToPreviewType>{},
  uploadedBImageToPreview: <UploadedImageToPreviewType>{},
  uploadedFImage: <FileType>{},
  uploadedBImage: <FileType>{},
  selectedAddress: <ProfileDetails>{},
  idProcessDLItem: <IdProcessDocTypeListType>{},
  idProcessPItem: <IdProcessDocTypeListType>{},
  selectedAddressToPreview: <AddressResultType>{},
  countries: <CountriesDataType>{},
  allStatesList: <StatesDataType>{},
  documentSide: '',
  editDoc: false,
  isSubmited: false,
  selectedDocumentId: 0,
  isDrafted: false,
  showDetails: false,
  detailsList: <DetailResultType>{},
  dlLoading: false,
  passportLoading: false,
  uploading: false,
};

export const selectIdeProcessDetails = ({ documentVerification }: RootState) => ({
  // here we will get data for id process item
  idProcessDLItem: documentVerification.idProcessDLItem,
  idProcessPItem: documentVerification.idProcessPItem,
  documentType: documentVerification.documentType,
  uploadedFImageToPreview: documentVerification.uploadedFImageToPreview,
  uploadedBImageToPreview: documentVerification.uploadedBImageToPreview,
  uploadedFImage: documentVerification.uploadedFImage,
  uploadedBImage: documentVerification.uploadedBImage,
  selectedAddress: documentVerification.selectedAddress,
  selectedAddressToPreview: documentVerification.selectedAddressToPreview,
  countries: documentVerification.countries,
  allStatesList: documentVerification.allStatesList,
  documentSide: documentVerification.documentSide,
  editDoc: documentVerification.editDoc,
  loading: documentVerification.loading,
  isSubmited: documentVerification.isSubmited,
  selectedDocumentId: documentVerification.selectedDocumentId,
  isDrafted: documentVerification.isDrafted,
  showDetails: documentVerification.showDetails,
  detailsList: documentVerification.detailsList,
  dlLoading: documentVerification.dlLoading,
  passportLoading: documentVerification.passportLoading,
  uploading: documentVerification.uploading,
});

export const createDocumentVerification = createAsyncThunk(
  'documentVerification/createDocumentVerification',
  async (formdata: object) => {
    return await draftOrCreateIdProcess(formdata);
  },
);

export const setImageTypeToStore = createAsyncThunk('documentVerification/setImageTypeToStore', async (type: any) => {
  return type;
});

export const setFUploadedImageToStore = createAsyncThunk(
  'documentVerification/setFUploadedImageToStore',
  async (image: any) => {
    return image;
  },
);

export const setBUploadedImageToStore = createAsyncThunk(
  'documentVerification/setBUploadedImageToStore',
  async (image: any) => {
    return image;
  },
);

export const setSelectedAddressToStore = createAsyncThunk(
  'documentVerification/setSelectedAddressToStore',
  async (address: any) => {
    return address;
  },
);

export const setFUploadedImage = createAsyncThunk('documentVerification/setFUploadedImage', async (image: any) => {
  return await postUploadedDoc(image);
});

export const setBUploadedImage = createAsyncThunk('documentVerification/setBUploadedImage', async (image: any) => {
  return await postUploadedDoc(image);
});

export const getDLProcessDetailsById = createAsyncThunk(
  'documentVerification/getDLProcessDetailsById',
  async (processId: number) => {
    return await processDetailsById(processId);
  },
);

export const getPProcessDetailsById = createAsyncThunk(
  'documentVerification/getPProcessDetailsById',
  async (processId: number) => {
    return await processDetailsById(processId);
  },
);

export const getCountriesData = createAsyncThunk('documentVerification/getCountriesData', async () => {
  return await getCountries();
});

export const getAllStatesData = createAsyncThunk('documentVerification/getAllStatesData', async () => {
  return await getAllStates();
});

export const documentVerificationSlice = createSlice({
  name: 'documentVerification',
  initialState,
  reducers: {
    setDocumentSide: (state, action) => {
      state.documentSide = action.payload;
    },
    resetDocumentSide: (state) => {
      state.documentSide = '';
    },
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    resetSelectedAddress: (state) => {
      state.selectedAddress = <ProfileDetails>{};
    },
    setSelectedDocumentId: (state, action) => {
      state.selectedDocumentId = action.payload;
    },
    resetSelectedDocumentId: (state) => {
      state.selectedDocumentId = 0;
    },
    navigateToUpload: (state, action) => {
      state.editDoc = action.payload;
    },
    setIsSubmited: (state, action) => {
      state.isSubmited = action.payload;
    },
    resetFUploadedImage: (state) => {
      state.uploadedFImage = <FileType>{};
    },
    resetBUploadedImage: (state) => {
      state.uploadedBImage = <FileType>{};
    },
    setIsDrafted: (state, action) => {
      state.isDrafted = action.payload;
    },
    setShowDetails: (state, action) => {
      state.showDetails = action.payload;
    },
    setDetailsList: (state, action) => {
      state.detailsList = action.payload;
    },
    setUploadedFImageToPreview: (state, action) => {
      state.uploadedFImageToPreview.preview = action.payload;
    },
    setUploadedBImageToPreview: (state, action) => {
      state.uploadedBImageToPreview.preview = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setImageTypeToStore.fulfilled, (state, type) => {
        state.error = false;
        state.loading = false;
        if (type.payload) {
          state.documentType = type.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setFUploadedImageToStore.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(setFUploadedImageToStore.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(setFUploadedImageToStore.fulfilled, (state, image) => {
        state.error = false;
        state.loading = false;
        if (image.payload) {
          state.uploadedFImageToPreview = image.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setBUploadedImageToStore.fulfilled, (state, image) => {
        state.error = false;
        state.loading = false;
        if (image.payload) {
          state.uploadedBImageToPreview = image.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setSelectedAddressToStore.fulfilled, (state, image) => {
        state.error = false;
        state.loading = false;
        if (image.payload) {
          state.selectedAddressToPreview = image.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setFUploadedImage.rejected, (state) => {
        state.error = true;
        state.loading = false;
        state.uploading = false;
      })
      .addCase(setFUploadedImage.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.uploading = true;
      })
      .addCase(setFUploadedImage.fulfilled, (state, image) => {
        state.error = false;
        state.loading = false;
        state.uploading = false;
        if (image.payload) {
          state.uploadedFImage = image.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setBUploadedImage.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.uploading = true;
      })
      .addCase(setBUploadedImage.fulfilled, (state, image) => {
        state.error = false;
        state.loading = false;
        state.uploading = false;
        if (image.payload) {
          state.uploadedBImage = image.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setBUploadedImage.rejected, (state) => {
        state.error = true;
        state.loading = false;
        state.uploading = false;
      })
      .addCase(getDLProcessDetailsById.pending, (state) => {
        state.dlLoading = true;
        state.error = false;
      })
      .addCase(getDLProcessDetailsById.fulfilled, (state, action) => {
        state.error = false;
        state.dlLoading = false;
        if (action.payload) {
          state.idProcessDLItem = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getDLProcessDetailsById.rejected, (state) => {
        state.dlLoading = false;
        state.error = true;
      })
      .addCase(getPProcessDetailsById.pending, (state) => {
        state.passportLoading = true;
        state.error = false;
      })
      .addCase(getPProcessDetailsById.fulfilled, (state, action) => {
        state.error = false;
        state.passportLoading = false;
        if (action.payload) {
          state.idProcessPItem = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getPProcessDetailsById.rejected, (state) => {
        state.passportLoading = false;
        state.error = true;
      })
      .addCase(getCountriesData.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCountriesData.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.countries = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getCountriesData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAllStatesData.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllStatesData.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.allStatesList = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getAllStatesData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(createDocumentVerification.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createDocumentVerification.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          // toast.success(action.payload?.detail);
        } else {
          state.error = true;
        }
      })
      .addCase(createDocumentVerification.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setDocumentSide,
  resetDocumentSide,
  setSelectedAddress,
  resetSelectedAddress,
  navigateToUpload,
  setIsSubmited,
  setSelectedDocumentId,
  resetSelectedDocumentId,
  resetFUploadedImage,
  resetBUploadedImage,
  setIsDrafted,
  setShowDetails,
  setDetailsList,
  setUploadedFImageToPreview,
  setUploadedBImageToPreview,
} = documentVerificationSlice.actions;
export default documentVerificationSlice.reducer;
