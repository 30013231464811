import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from '@mui/material';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  TTextField,
  TAutocomplete,
  TTextArea,
  TPaper,
  TButton,
  ACCEPTED_IMAGE_MIME_TYPES,
  SUPPORTED_IMAGE_TYPES,
  FILE_SIZE,
  FilePreviewType,
  TLoader,
  MAX_CHAR_LIMIT,
} from '../../../components/myde-react-components';
import {
  customIdProcessDetails,
  getEntityCustomIdProcessDetail,
  getEntityCustomIdProcessList,
  getIdProcessCategoriesList,
  getSpecificIdProcessDetails,
  getSupplementalIdProcessDetails,
  resetFilesList,
  setFilesList,
  setSelectedCategory,
} from '../../../redux/feature/idProcess/customIdProcessSlice';
import {
  CategoryType,
  SupplementalIdProcessResults,
  IdProcessResultSet,
  CategoryListType,
} from '../../../types/dashboardTypes';
import { titleCase } from '../../../utils/utils';
import { SHORT_DESCRIPTION_LENGTH } from '../../../constants/constants';
import UploadOrCapturePhoto from '../../../components/common/UploadOrCapturePhoto';
import { postUploadedDoc } from '../../../api/idProcesses';
import CaptureMultiple from '../../../components/common/CaptureMultiple';
import useQuery from '../../../hooks/useQuery';
import { createCustomIdProcess, createIdProcessCategory, editCustomIdProcess } from '../../../api/dashboard';
import { useRouter } from '../../../providers/custom-router-provider';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';
import { addEntitiyCustomIdProcess, editEntityCustomIdProcess } from '../../../api/entity';
import { isMobile } from 'react-device-detect';

const CustomDocumentForm = () => {
  // Constants
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { goBack } = useRouter();
  const queryProcessId = useQuery().get('id') || '';
  const query = useQuery();
  const isEntity = query.get('isEntity') === 'true';

  // Redux Values
  const { loading, filesList, selectedCategory, supplementalIdProcessList, idProcessCategoriesList } =
    useSelector(customIdProcessDetails);
  const { selectedEntity } = useSelector(selectEntity);

  //State values
  const [category, setCategory] = useState({} as CategoryListType);
  const [idProcess, setIdProcess] = useState([] as CategoryType[]);
  const [categoriesList, setCategoriesList] = useState([] as IdProcessResultSet[]);
  const [existingFiles, setExisitingFiles] = useState([] as any[]);
  const [documents, setDocuments] = useState([] as string[]);
  const [noCategories, setNoCategories] = useState(false);
  const [customCategory, setCustomCategory] = useState('');
  const [showMultipleCapture, setShowMultipleCapture] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  //Form Setup
  const CustomFormSchema = !isEditMode
    ? z.object({
        category: z.string().max(50, { message: 'Category can be atmost 50 characters long' }),
        title: z
          .string()
          .min(1, { message: 'Title is required' })
          .max(25, {
            message: 'Title can be atmost 25 characters long',
          })
          .refine(
            (value) =>
              !idProcess.some((el) => titleCase(el.process_name).toUpperCase() === titleCase(value).toUpperCase()),
            {
              message: 'Title must be unique',
            },
          ),
        text: z.string(),
      })
    : z.object({
        category: z.string().max(50, { message: 'Category can be atmost 50 characters long' }),
        title: z.string().min(1, { message: 'Title is required' }).max(25, {
          message: 'Title can be atmost 25 characters long',
        }),
        text: z.string(),
      });

  type CustomFormSchemaPayload = z.infer<typeof CustomFormSchema>;
  const { handleSubmit, control, formState, setValue, watch } = useForm<CustomFormSchemaPayload>({
    resolver: zodResolver(CustomFormSchema),
    mode: 'onChange',
  });
  const { errors } = formState;
  const titleMaxLength = 25;
  // Use Effects
  useEffect(() => {
    const filters = {
      ordering: '-created_on',
    };
    dispatch(getIdProcessCategoriesList({}));
    dispatch(getSupplementalIdProcessDetails(filters));
    dispatch(resetFilesList());
  }, []);

  useEffect(() => {
    if (selectedCategory?.id) {
      const result =
        categoriesList?.find(
          (category) => category?.category_name === selectedCategory?.process_data?.process_category,
        ) || ({} as IdProcessResultSet);
      const previewFiles = selectedCategory?.process_data?.document_list?.map((item) => {
        return {
          name: item.doc_name || '',
          type: item.mime_type || '',
          preview: item.signed_url || '',
          size: item.size_in_kb,
          id: item?.doc_id,
        };
      });
      setIsEditMode(true);
      setPreviewFiles(previewFiles);
      setDocuments(selectedCategory?.process_data?.document_id_list || []);
      setCategory({ id: result?.id, category_name: result?.category_name });
      setValue('title', selectedCategory?.process_name, { shouldValidate: true });
      setValue('text', selectedCategory?.process_data?.process_description, { shouldValidate: true });
      setValue('category', selectedCategory?.process_data?.process_category, { shouldValidate: true });
    }
  }, [selectedCategory, categoriesList]);

  useEffect(() => {
    const list: Array<CategoryType> = supplementalIdProcessList.map((item: SupplementalIdProcessResults) => {
      return {
        id: item?.id,
        process_name: item?.process_name,
        process_description: item?.process_data?.process_description,
      };
    });
    setCategoriesList(idProcessCategoriesList);
    setIdProcess(list);
  }, [supplementalIdProcessList, idProcessCategoriesList]);

  useEffect(() => {
    if (queryProcessId && !isEntity) {
      const data = {
        id: queryProcessId,
      };
      dispatch(getSpecificIdProcessDetails(data));
    }
  }, [queryProcessId, isEntity]);

  useEffect(() => {
    if (isEntity) {
      const data = {
        entityUid: selectedEntity?.trellis_uid,
        filters: { ordering: '-created_on' },
      };
      dispatch(getEntityCustomIdProcessList(data));
    }
  }, [isEntity]);

  useEffect(() => {
    if (idProcessCategoriesList?.length === 0) {
      setNoCategories(true);
    } else {
      setNoCategories(false);
    }
  }, [idProcessCategoriesList]);

  useEffect(() => {
    if (idProcessCategoriesList?.length > 0) {
      setCategoriesList(idProcessCategoriesList);
    }
  }, [idProcessCategoriesList]);

  useEffect(() => {
    setExisitingFiles(filesList);
  }, [filesList]);

  // Methods
  const handleCategoryChange = (_event: React.SyntheticEvent, value: CategoryListType) => {
    if (value?.id) {
      setCategory(value);
      setValue('category', value.category_name, { shouldValidate: true });
    }
  };

  const handleTitleChange = (value: string) => {
    setValue('title', value, { shouldValidate: true });
    return value;
  };

  const handleDescriptionChange = (value: string) => {
    setValue('text', value);
    return value;
  };

  const handleCategoryText = (value: string) => {
    if (!value) {
      setNoCategories(false);
      dispatch(getIdProcessCategoriesList({}));
      return;
    }
    setCustomCategory(value);
    setValue('category', value, { shouldValidate: true });
  };

  const getCategoryOnType = async (value: string) => {
    if ((value && value?.length >= 3) || value.length === 0) {
      setCustomCategory(value);
      const data = {
        category_name: value,
      };
      setValue('category', value, { shouldValidate: true });
      await dispatch(getIdProcessCategoriesList(data));
    }
  };

  const uploadFiles = (files: FilePreviewType[], oldFiles: any[]) => {
    setIsUploading(true);
    let documentIdList: string[] = oldFiles?.map((file) => file?.id && file?.id) || [...documents];
    let documentList: FilePreviewType[] = oldFiles || [];
    if (files && files?.length > 0) {
      files?.forEach(async (file: any) => {
        const result = await postUploadedDoc(file);
        result.preview = file?.preview;
        documentIdList = Object.assign([], documentIdList);
        documentList = Object.assign([], documentList);
        documentIdList.push(result?.doc_id);
        documentList.push(result);
        setDocuments(documentIdList);
        setPreviewFiles(documentList);
      });
    } else {
      setDocuments(documentIdList);
      setPreviewFiles(documentList);
    }
    setIsUploading(false);
  };

  const setPreviewFiles = (files: any[]) => {
    const fileList: FilePreviewType[] = [];
    files?.forEach((file: any) => {
      const data = {
        name: file?.name || file?.doc_name,
        type: file?.type || file?.mime_type,
        preview: file?.preview || file?.signed_url,
        size: file?.size || file?.size_in_kb,
        id: file?.id || file?.doc_id,
      };
      fileList?.push(data);
    });
    dispatch(setFilesList(fileList));
  };

  const setMultipleCaptureData = (files: FilePreviewType[]) => {
    uploadCapturedImages(files);
    setPreviewFiles(files);
  };

  const uploadCapturedImages = (files: FilePreviewType[]) => {
    const documentIdList: string[] = [];
    if (files && files?.length > 0) {
      files?.forEach(async (file: any) => {
        const result = await postUploadedDoc(file?.file);
        if (result?.doc_id && Object.keys(result).length > 0) {
          documentIdList?.push(result?.doc_id);
        }
        if (Object.keys(result)?.length > 0 && documentIdList?.length > 0) {
          setDocuments(documentIdList);
        }
      });
    }
  };

  const handleButtonState = () => {
    const formData = watch();
    let result = false;
    if ((formData?.text || documents?.length > 0) && formData?.title) {
      result = true;
    }
    return result;
  };

  const getIndividualCustomIdProcess = async (payload: object) => {
    let result;
    if (isEditMode && !isEntity) {
      result = await editCustomIdProcess(queryProcessId, payload);
    }
    if (!isEditMode && !isEntity) {
      result = await createCustomIdProcess(payload);
    }
    return result;
  };

  const getSpecificIdProcessDetail = () => {
    dispatch(setSelectedCategory({} as SupplementalIdProcessResults));
    if (isEditMode && !isEntity) {
      dispatch(
        getSpecificIdProcessDetails({
          id: queryProcessId,
        }),
      );
    }
  };

  const getEntitySpecificIdProcessDetail = () => {
    dispatch(setSelectedCategory({} as SupplementalIdProcessResults));
    if (isEditMode && isEntity) {
      const data = {
        entityUid: selectedEntity?.trellis_uid,
        uuid: queryProcessId,
      };
      dispatch(getEntityCustomIdProcessDetail(data));
    }
  };

  const getEntityCustomIdProcess = async (payload: object) => {
    let entityResult;
    if (isEntity && isEditMode) {
      entityResult = await editEntityCustomIdProcess(selectedEntity?.trellis_uid, queryProcessId, payload);
    }
    if (isEntity && !isEditMode) {
      entityResult = await addEntitiyCustomIdProcess(selectedEntity?.trellis_uid, payload);
    }
    return entityResult;
  };

  const getPayload = (formData: CustomFormSchemaPayload) => {
    const payload = {
      process_name: formData?.title,
      process_data: {
        process_category: formData?.category,
        document_id_list: documents,
        process_description: formData?.text,
      },
    };
    return payload;
  };

  const onSubmit = async (formData: CustomFormSchemaPayload) => {
    const payload = getPayload(formData);
    if (noCategories) {
      await createIdProcessCategory({ category_name: formData?.category });
    }
    const result: any = getIndividualCustomIdProcess(payload);
    if (result) {
      getSpecificIdProcessDetail();
    }
    const entityResult: any = getEntityCustomIdProcess(payload);
    if (entityResult) {
      getEntitySpecificIdProcessDetail();
    }
    goBack();
  };

  return (
    <>
      <TPaper sx={{ mb: 8 }}>
        <TLoader loading={loading || isUploading} />
        <Box>
          <Box sx={{ p: 2 }}>
            {!showMultipleCapture ? (
              <Box className="flex-column-center">
                <Box className="text-center textColor-300 text-large font-weight-semibold">
                  {!isEditMode ? t('addDocument') : t('editDocument')}
                </Box>
              </Box>
            ) : (
              <Box className="flex-column-center">
                <Box className="text-center textColor-300 text-large font-weight-semibold">{t('capture')}</Box>
              </Box>
            )}
          </Box>
          <Divider />
          {!showMultipleCapture ? (
            <>
              <Box sx={{ px: 3, pt: 3 }}>
                <form>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Controller
                        name="category"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <>
                            {noCategories ? (
                              <>
                                <TTextField
                                  {...field}
                                  label="Category"
                                  variant="outlined"
                                  fullWidth
                                  isOptional={true}
                                  value={customCategory}
                                  inputProps={{ maxLength: SHORT_DESCRIPTION_LENGTH }}
                                  sx={{ my: 1 }}
                                  error={Boolean(errors.category)}
                                  helperText={errors.category?.message}
                                  onChange={(event) => {
                                    const { value } = event?.target;
                                    handleCategoryText(value);
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <TAutocomplete
                                  {...field}
                                  value={category}
                                  options={categoriesList || []}
                                  disablePortal={true}
                                  onChange={handleCategoryChange}
                                  getOptionLabel={(option) => option.category_name || ''}
                                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                                  freeSolo
                                  renderInput={(params) => (
                                    <TTextField
                                      label="Category"
                                      variant="outlined"
                                      isOptional={true}
                                      onChange={(event) => {
                                        const { value } = event.target;
                                        getCategoryOnType(value);
                                      }}
                                      error={Boolean(errors.category)}
                                      helperText={errors.category?.message}
                                      {...params}
                                    />
                                  )}
                                />
                              </>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Controller
                        name="title"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TTextField
                            {...field}
                            label="Title"
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: titleMaxLength }}
                            error={Boolean(errors.title)}
                            helperText={errors.title?.message}
                            onChange={(event) => {
                              const { value } = event.target;
                              handleTitleChange(value);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Controller
                        name="text"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TTextArea
                            {...field}
                            label="Text (optional)"
                            variant="outlined"
                            fullWidth
                            multiline
                            inputProps={{ maxLength: MAX_CHAR_LIMIT.CHAR_LENGTH }}
                            minRows={3}
                            error={Boolean(errors.text)}
                            helperText={errors.text?.message}
                            onChange={(event) => {
                              const { value } = event.target;
                              handleDescriptionChange(value);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box>
                        <UploadOrCapturePhoto
                          isMultiple={true}
                          acceptedFileFormat={ACCEPTED_IMAGE_MIME_TYPES}
                          supportedFileFormat={SUPPORTED_IMAGE_TYPES}
                          maxFileSize={FILE_SIZE.CUSTOM_DOCUMENT_FILE_SIZE}
                          uploadedFile={existingFiles}
                          setMultipleFileData={uploadFiles}
                          setShowMultipleCapture={setShowMultipleCapture}
                          showTitleText={isMobile ? 'Upload / Capture Image' : ''}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ p: 3, mx: 4 }} className="flex-basix-center">
                    <TButton
                      fullWidth
                      variant={'contained'}
                      btnText={'Save'}
                      disabled={!handleButtonState()}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </Box>
                </form>
              </Box>
            </>
          ) : (
            <Box sx={{ py: 3, px: 2 }}>
              <CaptureMultiple
                setMultipleFileData={setMultipleCaptureData}
                setShowMultipleCapture={setShowMultipleCapture}
              />
            </Box>
          )}
        </Box>
      </TPaper>
    </>
  );
};

export default CustomDocumentForm;
