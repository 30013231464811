import React from 'react';
import { Box, Divider, Grid } from '@mui/material';

import { ClickHandlerType } from '../../types/commonTypes';
import { ACCESS_TYPE_LABEL, ROLE_TEXT } from '../../constants/constants';

interface CommonListProps {
  onClickHandler: ClickHandlerType;
  imageUrl: string;
  avatarText?: string;
  listText: string;
  callBackData?: any;
  role?: string;
  permission?: string;
}

const CommonList = ({ onClickHandler, listText, callBackData, role, permission }: CommonListProps) => {
  return (
    <Box>
      <Grid sx={{ p: 2 }} container onClick={() => onClickHandler(callBackData)}>
        <Grid item xs={2}>
          <Box className="text-center" sx={{ pr: 2 }}>
            <span className="icon-profile-users textColor-200 icon-size-24" />
          </Box>
        </Grid>
        <Grid item xs={8} className="text-large font-weight-medium">
          {listText}
        </Grid>
        {role && permission && (
          <Box sx={{ pt: 2, pl: 5 }} className="flex-basic-center">
            <Box className="flex-basic-start">
              <Box sx={{ pr: 1 }} className={'icon-profile-info icon-size-14 textColor-300'}></Box>
              <Box sx={{ pr: 3 }} className="text-extra-small textColor-200">
                {ROLE_TEXT[role]}
              </Box>
            </Box>
            <Box className="flex-basic-start">
              <Box sx={{ pr: 1 }} className={'icon-lock icon-size-14 textColor-300'}></Box>
              <Box className="text-extra-small textColor-200">{ACCESS_TYPE_LABEL[permission]}</Box>
            </Box>
          </Box>
        )}
      </Grid>
      <Divider sx={{ mx: 2 }} />
    </Box>
  );
};

export default CommonList;
