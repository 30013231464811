import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectSelectedIdProcessDetails } from '../../../redux/feature/idProcess/mainIdProcessSlice';
import {
  ID_PROCESS_KEYS,
  ID_PROCESS_STATUS_COMPLETION_STATE,
} from '../../myde-react-components/src/constants/constants';
import TaxIdForm from '../components/tax-id/SsnForm';
import ProfileFormIndex from '../components/profile/ProfileFormIndex';
import { setPreviousStep } from '../../../redux/feature/common/commonSlice';
import { TLoader } from '../../myde-react-components';
import IncomeConfirmation from '../components/income-verification/IncomeConfirmation';
import W9W8BenConfirmation from '../components/w9-w8-ben/W9W8BenConfirmation';
import AddressForm from '../components/address-verification/AddressForm';
import BioMetricConfrimation from '../components/biometric/BiometricConfirmation';
import AccreditationConfirmation from '../components/accreditation/AccreditationConfirmation';
import DocumentFormationConfirmation from '../components/document-formation/DocumentFormationConfirmation';
import LetterOfGoodStandingConfirmation from '../components/letter-of-good-standing/LetterOfGoodStandingConfirmation';
import IrsLetterEvidencingConfirmation from '../components/irs-letter-evidencing/IrsLetterEvidencingConfirmation';
import OwnershipDocConfirmation from '../components/ownership-documents/OwnershipDocConfirmation';
import WetSignatureConfirmation from '../components/wet-signature/WetSignatureConfirmation';
import DocumentConfirmation from '../components/document-verification/DocumentConfirmation';

interface ConfirmationProps {
  lang: string;
  isEntity: boolean;
}

const IdProcessConfirmation = ({ lang, isEntity }: ConfirmationProps) => {
  const dispatch = useDispatch();

  // Redux Values
  const { selectedProcessKey } = useSelector(selectSelectedIdProcessDetails);

  // State Values
  const [idProcessTransaltion, setIdProcessTranslation] = useState('common');
  const [idProcessKey, setIdProcessKey] = useState('');
  const [loading, setLoading] = useState(true);
  const ID_PROCESS_COMPONENTS_IN_PROGRESS = [
    {
      processKey: ID_PROCESS_KEYS.PROFILE,
      component: <ProfileFormIndex isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.SSN,
      component: <TaxIdForm />,
    },
    {
      processKey: ID_PROCESS_KEYS.W9_W8_BEN,
      component: <W9W8BenConfirmation isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.INCOME,
      component: <IncomeConfirmation />,
    },
    {
      processKey: ID_PROCESS_KEYS.BIOMETRIC,
      component: <BioMetricConfrimation />,
    },
    {
      processKey: ID_PROCESS_KEYS.ACCREDITATION,
      component: <AccreditationConfirmation isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.ADDRESS,
      component: <AddressForm />,
    },
    {
      processKey: ID_PROCESS_KEYS.SIGNATURE,
      component: <WetSignatureConfirmation />,
    },
    {
      processKey: ID_PROCESS_KEYS.FORMATION_DOCUMENT,
      component: <DocumentFormationConfirmation isEntity={isEntity} isMultiple={true} />,
    },
    {
      processKey: ID_PROCESS_KEYS.LETTER_OF_GOOD_STANDING,
      component: <LetterOfGoodStandingConfirmation isEntity={isEntity} isMultiple={false} />,
    },
    {
      processKey: ID_PROCESS_KEYS.IRS_LETTER_EVIDENCING_TAX_ID,
      component: <IrsLetterEvidencingConfirmation isEntity={isEntity} isMultiple={false} />,
    },
    {
      processKey: ID_PROCESS_KEYS.OWNERSHIP_DOCUMENTS,
      component: <OwnershipDocConfirmation />,
    },
    {
      processKey: ID_PROCESS_KEYS.DOCUMENT_VERIFICATION,
      component: <DocumentConfirmation />,
    },
  ];

  // Use Effects
  useEffect(() => {
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[0].percent));
  }, []);
  useEffect(() => {
    setIdProcessTranslation(lang);
  }, [lang]);
  useEffect(() => {
    if (selectedProcessKey) {
      setIdProcessKey(selectedProcessKey);
    }
  }, [selectedProcessKey]);
  useEffect(() => {
    if (idProcessTransaltion !== 'common') {
      setLoading(false);
    }
  }, [idProcessTransaltion]);

  // Methods
  const updateIdProcessComponent = () => {
    const currentComponentItem = ID_PROCESS_COMPONENTS_IN_PROGRESS.find((item) => item?.processKey === idProcessKey);
    return currentComponentItem?.component || <></>;
  };

  // HTML
  return (
    <>
      <TLoader loading={loading} />
      {!loading && <>{updateIdProcessComponent()}</>}
    </>
  );
};

export default IdProcessConfirmation;
