import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { entityProcessDetailsById, newEntityIdProcess } from '../../../api/entityIdprocesses';

import {
  getAddressIdProcessData,
  processDetailsById,
  postUploadedDoc,
  getProcessChildrenById,
} from '../../../api/idProcesses';
import { EntityPayloadType, PROCESS_IDS } from '../../../components/myde-react-components';
import { UploadedFileType, UploadedImageToPreviewType } from '../../../types/idProcessTypes';

const initialState = {
  loading: false,
  error: false,
  processId: 0,
  selectedIdProcessDetails: <any>{},
  selectedIdProcessChildrenList: [],
  selectedProcessKey: '',
  selectedParentIdProcess: <any>{},
  selectedCustomIdProcess: <any>{},
  selectedProcessId: 0,
  selectedEntityIdProcessDetails: <any>{},
  addressDataIndex: null,
  uploadedImage: <UploadedFileType>{},
  uploadedImageToPreview: <UploadedImageToPreviewType>{},
  uploadedDocId: [] as string[],
  uploadedImageMultiple: [],
  uploadedImageToPreviewMultiple: [] as any[],
  uploadedDocIdMultiple: [] as string[],
  uploadedFrontImage: <UploadedFileType>{},
  uploadedBackImage: <UploadedFileType>{},
};

export const selectSelectedIdProcessDetails = ({ idProcessMain }: RootState) => ({
  loading: idProcessMain.loading,
  error: idProcessMain.error,
  processId: idProcessMain.processId,
  selectedIdProcessDetails: idProcessMain.selectedIdProcessDetails,
  selectedIdProcessChildrenList: idProcessMain.selectedIdProcessChildrenList,
  selectedProcessKey: idProcessMain.selectedProcessKey,
  selectedParentIdProcess: idProcessMain.selectedParentIdProcess,
  selectedProcessId: idProcessMain.selectedProcessId,
  selectedCustomIdProcess: idProcessMain.selectedCustomIdProcess,
  selectedEntityIdProcessDetails: idProcessMain.selectedEntityIdProcessDetails,
  addressDataIndex: idProcessMain.addressDataIndex,
  uploadedImage: idProcessMain.uploadedImage,
  uploadedImageToPreview: idProcessMain.uploadedImageToPreview,
  uploadedDocId: idProcessMain.uploadedDocId,
  uploadedImageMultiple: idProcessMain.uploadedImageMultiple,
  uploadedImageToPreviewMultiple: idProcessMain.uploadedImageToPreviewMultiple,
  uploadedDocIdMultiple: idProcessMain.uploadedDocIdMultiple,
  uploadedFrontImage: idProcessMain.uploadedFrontImage,
  uploadedBackImage: idProcessMain.uploadedBackImage,
});

export const getProcessDetailsById = createAsyncThunk(
  'idProcessMain/getProcessDetailsById',
  async (data: { processId: string | number; params?: object }) => {
    if ((data?.processId).toString() === PROCESS_IDS.ADDRESS_VERIFICATION.toString()) {
      return await getAddressIdProcessData();
    } else {
      return await processDetailsById(data.processId, data.params);
    }
  },
);

export const getIdProcessChildren = createAsyncThunk(
  'idProcessMain/getIdProcessChildren',
  async (data: { processId: string | number; params?: object }) => {
    return await getProcessChildrenById(data.processId);
  },
);

export const getEntityProcessDetailsById = createAsyncThunk(
  'idProcessMain/getEntityProcessDetailsById',
  async (data: EntityPayloadType) => {
    return await entityProcessDetailsById(data.processId, data.entityUid);
  },
);

export const setUploadedImage = createAsyncThunk('idProcessMain/setUploadedImage', async (image: any) => {
  return await postUploadedDoc(image);
});

export const submitEntityIdProcess = createAsyncThunk(
  'idProcessMain/submitEntityIdProcess',
  async (data: { entityUid: number; formData: object }) => {
    return await newEntityIdProcess(data.entityUid, data.formData);
  },
);

export const idProcessMainSlice = createSlice({
  name: 'idProcessMain',
  initialState,
  reducers: {
    setProcessId: (state, action) => {
      state.processId = action.payload;
    },
    setIdProcessKey: (state, action) => {
      state.selectedProcessKey = action.payload;
    },
    setParentIdProcess: (state, action) => {
      state.selectedParentIdProcess = action.payload;
    },
    setCustomIdProcess: (state, action) => {
      state.selectedCustomIdProcess = action.payload;
    },
    setSelectedProcessId: (state, action) => {
      state.selectedProcessId = action.payload;
    },
    resetSelectedIdProcessDetails: (state) => {
      state.selectedIdProcessDetails = {};
      state.selectedIdProcessChildrenList = [];
    },
    setUploadedImageToStore: (state, action) => {
      state.uploadedImageToPreview = action.payload;
    },
    clearIdProcessData: (state) => {
      state.uploadedImage = <UploadedFileType>{};
      state.uploadedImageToPreview = <UploadedImageToPreviewType>{};
      state.uploadedDocId = [];
    },
    setUploadedDocId: (state, action) => {
      if (action?.payload) {
        state.uploadedDocId = action?.payload;
      }
    },
    setUploadedDocIdMultiple: (state, action) => {
      if (action?.payload) {
        state.uploadedDocIdMultiple = action?.payload;
      }
    },
    setMultipleUploadedPreviewImage: (state, action) => {
      state.uploadedImageToPreviewMultiple = action.payload;
    },
    setMultipleUploadedImage: (state, action) => {
      state.uploadedImageMultiple = action.payload;
    },
    clearIdProcessMultipleData: (state) => {
      state.uploadedImageMultiple = [];
      state.uploadedImageToPreviewMultiple = [];
      state.uploadedDocIdMultiple = [];
    },
    setAddressDataIndex: (state, action) => {
      state.addressDataIndex = action.payload;
    },
    setUploadedFrontImage: (state, action) => {
      state.uploadedFrontImage = action.payload;
    },
    setUploadedBackImage: (state, action) => {
      state.uploadedBackImage = action.payload;
    },
    clearSelectedIdProcessData: (state) => {
      state.selectedEntityIdProcessDetails = {};
      state.selectedIdProcessDetails = {};
      state.uploadedFrontImage = <UploadedFileType>{};
      state.uploadedBackImage = <UploadedFileType>{};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProcessDetailsById.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.selectedIdProcessDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getProcessDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getIdProcessChildren.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIdProcessChildren.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.selectedIdProcessChildrenList = action.payload?.results || [];
        } else {
          state.error = true;
        }
      })
      .addCase(getIdProcessChildren.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(getEntityProcessDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityProcessDetailsById.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.selectedEntityIdProcessDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityProcessDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(setUploadedImage.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setUploadedImage.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.uploadedImage = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setUploadedImage.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setProcessId,
  setIdProcessKey,
  setParentIdProcess,
  setCustomIdProcess,
  setSelectedProcessId,
  resetSelectedIdProcessDetails,
  setAddressDataIndex,
  setUploadedImageToStore,
  clearIdProcessData,
  setUploadedDocId,
  setUploadedDocIdMultiple,
  clearIdProcessMultipleData,
  setMultipleUploadedPreviewImage,
  setMultipleUploadedImage,
  setUploadedFrontImage,
  setUploadedBackImage,
  clearSelectedIdProcessData,
} = idProcessMainSlice.actions;
export default idProcessMainSlice.reducer;
