import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ENTITY_TYPE } from '../../../../constants/constants';
import {
  TTextField,
  TAutocomplete,
  GPlacesAddressType,
  TGPlaces,
  GPlacesAddressDataType,
  TButton,
  REGEX,
} from '../../../../components/myde-react-components';
import { setEntityFormData, selectEntity, setGPlacesAddress } from '../../../../redux/feature/entity/entitySlice';
import { EntityTypes } from '../../../../types/entityTypes';
import { useTranslation } from 'react-i18next';
import { getAddressData } from '../../../myde-react-components/src/utils/commonMethods';
import { CountryType } from '../../../../types/commonTypes';
import { selectProfile } from '../../../../redux/feature/profile/profileSlice';
import { selectSession, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { selectEntityProfile, setEmailNotVerified } from '../../../../redux/feature/profile/entityProfileSlice';

interface EntityFormProps {
  goToNextStep: (step: number) => void;
}

const EntityForm = ({ goToNextStep }: EntityFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const { entityList, entityFormData, currentEntityTaxId, checkIsEntity, googlePlacesAddress } =
    useSelector(selectEntity);
  const { profileData } = useSelector(selectProfile);
  const { currentStep } = useSelector(selectSession);
  const { entityEmailNotVerified } = useSelector(selectEntityProfile);

  const [gPlacesAddressData, setGPlacesAddressData] = useState({} as GPlacesAddressDataType);

  const [type, setType] = useState({
    id: '',
    name: '',
  });
  const [existingEntity, setExistingEntity] = useState<Array<EntityTypes>>([]);
  const [taxIdValue, setTaxIdValue] = useState('');
  const [emailValue, setEmailValue] = useState('');

  const EntityFormSchema = z.object({
    entity_type: z.string().min(1, { message: 'Entity Type is required' }),
    tax_id: z.string(),
    email: z.string().min(1, { message: 'Email is required' }).email({ message: 'Please enter a valid email' }),
    entity_name: z
      .string()
      .min(1, { message: 'Entity Name is required' })
      .max(100, { message: 'Entity name should be less than 100 characters' }),
    dba_name: z.string(),
    website: z
      .string()
      .min(1, { message: 'Website Link is required' })
      .refine((value) => REGEX.WEBSITE.test(value), { message: 'Please enter a valid url' }),
    formation_country: z.string().min(1, { message: 'Country is required' }),
    formation_state: z.string().min(1, { message: 'State is required' }),
  });

  type EntityFormSchemaPayload = z.infer<typeof EntityFormSchema>;
  const { handleSubmit, control, formState, setValue, setError, getValues } = useForm<EntityFormSchemaPayload>({
    resolver: zodResolver(EntityFormSchema),
    mode: 'onChange',
  });

  const { errors, isValid } = formState;

  const onSubmit = async (formData: EntityFormSchemaPayload) => {
    const payload = {
      entity_type: formData?.entity_type,
      tax_id: formData?.tax_id,
      email: formData?.email,
      entity_name: formData?.entity_name,
      dba_name: formData?.dba_name,
      website: formData?.website,
      formation_country: gPlacesAddressData?.country,
      formation_country_name: gPlacesAddressData?.country_name,
      formation_state: gPlacesAddressData?.state,
      formation_state_name: gPlacesAddressData?.state_name,
    };
    dispatch(setEntityFormData(payload));
    goToNextStep(1);
  };

  useEffect(() => {
    if (entityList) {
      setExistingEntity(entityList);
    }
  }, [entityList]);

  useEffect(() => {
    if (entityEmailNotVerified) {
      dispatch(setEmailNotVerified(false));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(entityFormData)?.length > 0) {
      const entType = ENTITY_TYPE.find((item) => item?.id === entityFormData?.entity_type) || ({} as CountryType);
      setType(entType);
      setValue('entity_type', entType?.id, { shouldValidate: true });
      setValue('tax_id', entityFormData?.tax_id, { shouldValidate: true });
      setValue('email', entityFormData?.email, { shouldValidate: true });
      setValue('entity_name', entityFormData?.entity_name, { shouldValidate: true });
      setValue('dba_name', entityFormData?.dba_name);
      setValue('website', entityFormData?.website, { shouldValidate: true });
      setValue('formation_country', entityFormData?.formation_country_name, { shouldValidate: true });
      setValue('formation_state', entityFormData?.formation_state_name, { shouldValidate: true });
    }
  }, [entityFormData]);

  useEffect(() => {
    if (checkIsEntity && currentEntityTaxId) {
      setTaxIdValue(currentEntityTaxId);
      setValue('tax_id', currentEntityTaxId, { shouldValidate: true });
    }
  }, [currentEntityTaxId, checkIsEntity]);

  useEffect(() => {
    if (checkIsEntity && profileData?.email?.length > 0) {
      setEmailValue(profileData?.email);
      setValue('email', profileData?.email, { shouldValidate: true });
    }
  }, [checkIsEntity, profileData]);

  useEffect(() => {
    dispatch(setPreviousStep(currentStep));
  }, []);

  useEffect(() => {
    if (googlePlacesAddress?.state_name) {
      setGPlacesAddressData(googlePlacesAddress);
    }
  }, [googlePlacesAddress]);

  const handleTypeChange = (_event: React.SyntheticEvent, value: any) => {
    if (value) {
      setType(value);
      setValue('entity_type', value?.id, { shouldValidate: true });
    } else {
      setType({ id: '', name: '' });
      setError('entity_type', { type: 'custom', message: 'Entity Type is required' });
    }
  };

  const inputChange = () => {
    const entityName = getValues('entity_name');
    existingEntity?.map((entity) => {
      if (entity?.name === entityName) {
        setError('entity_name', {
          type: 'custom',
          message: `Entity with this name is already associated with your account`,
        });
      }
    });
  };

  const setPlacesDetails = (data: GPlacesAddressType) => {
    const address = getAddressData(data) || ({} as GPlacesAddressType);
    setGPlacesAddressData(address);
    dispatch(setGPlacesAddress(address));
    setValue('formation_country', address?.country_name, { shouldValidate: true });
    setValue('formation_state', address?.state_name, { shouldValidate: true });
  };

  return (
    <>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="entity_type"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TAutocomplete
                  {...field}
                  value={type}
                  options={ENTITY_TYPE || []}
                  disablePortal={true}
                  getOptionLabel={(option) => option?.name || ''}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  onChange={handleTypeChange}
                  renderInput={(params) => (
                    <TTextField
                      label={t('entityType')}
                      variant="outlined"
                      error={Boolean(errors?.entity_type)}
                      helperText={errors?.entity_type?.message}
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="tax_id"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label={t('taxId')}
                  variant="outlined"
                  fullWidth
                  type={'password'}
                  disabled={checkIsEntity}
                  helperText={errors?.tax_id?.message}
                  {...field}
                  value={taxIdValue}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <>
                  <TTextField
                    label={t('email')}
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.email)}
                    helperText={errors?.email?.message}
                    {...field}
                    defaultValue={emailValue}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="entity_name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <>
                  <TTextField
                    label={t('legalName')}
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.entity_name)}
                    helperText={errors?.entity_name?.message}
                    {...field}
                    onKeyUp={inputChange}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="dba_name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label={t('dbaName')}
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors?.dba_name)}
                  helperText={errors?.dba_name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="website"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label={t('website')}
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors?.website)}
                  helperText={errors?.website?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="formation_state"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TGPlaces
                  fieldId={'state'}
                  placeholder={t('stateregionofFormation')}
                  fieldType={[]}
                  label={t('stateregionofFormation')}
                  variant="outlined"
                  fullWidth
                  sx={{ my: 1 }}
                  error={Boolean(errors.formation_state)}
                  helperText={errors.formation_state?.message}
                  onPlaceSelection={setPlacesDetails}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="formation_country"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label={t('countryOfFormation')}
                  variant="outlined"
                  fullWidth
                  sx={{ my: 1 }}
                  disabled
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box className="flex-column-center" sx={{ mt: 4 }}>
          <TButton
            sx={{ mb: 2 }}
            btnText={'Next'}
            variant="contained"
            btnWidthSize="button-w-240"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      </form>
    </>
  );
};
export default EntityForm;
