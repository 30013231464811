import * as z from 'zod';

const symbol = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
const number = /\d/;
export const ResetPasswordFormSchema = z
  .object({
    password: z.string().min(8, { message: 'Password is required' }),
    confirm_password: z.string().min(8, { message: 'Confirm password is required' }),
  })
  .refine((data) => number.test(data.password), {
    message: 'Password is invalid',
    path: ['password'],
  })
  .refine((data) => symbol.test(data.password), {
    message: 'Password is invalid',
    path: ['password'],
  })
  .refine((data) => data.password.toLowerCase() !== data.password, {
    message: 'Password is invalid',
    path: ['password'],
  })
  .refine((data) => data.password.toUpperCase() !== data.password, {
    message: 'Password is invalid',
    path: ['password'],
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Confirm password does not match',
    path: ['confirm_password'],
  });

export type ResetPasswordFormSchemaPayload = z.infer<typeof ResetPasswordFormSchema>;
