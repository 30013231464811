import React, { useEffect, useState } from 'react';
import { Box, Divider, Pagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';

import {
  entityInviteAction,
  getEntityInvitationList,
  getEntityInviteDetails,
  selectEntity,
  setShowEntityDetailsFlag,
} from '../../../redux/feature/entity/entitySlice';
import { AcceptInviteType, EntityInvitionsType } from '../../../types/entityTypes';
import { colorPalette, TButton, TCommonAvatar, theme, TPaper } from '../../myde-react-components';
import { ENTITY_INVITATION_ACTION, ENTITY_INVITE_STATUS } from '../../../constants/constants';
import { fontStyle } from '../../../styles/style';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiButtonBase-root.MuiPaginationItem-root': {
      height: '15px',
      minWidth: '15px',
      border: `2px solid ${colorPalette.typoText.lighten1}`,
      borderRadius: '50%',
      padding: '5px',
      fontSize: 0,
      cursor: 'initial',
    },
    '& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
      backgroundColor: colorPalette.typoText.lighten1,
    },
  },
}));

const NewEntityInvitation = () => {
  //Constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  //Redux
  const { allEntityInvitations, acceptedRejectedEntityInviteDetails } = useSelector(selectEntity);

  //State
  const [invitationList, setInvitationList] = useState([] as EntityInvitionsType[]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  //useEffects
  useEffect(() => {
    if (allEntityInvitations?.length > 0) {
      const invites = allEntityInvitations?.filter((invite: EntityInvitionsType) => {
        if (invite?.status === ENTITY_INVITE_STATUS.PENDING) {
          return invite;
        }
      });
      setInvitationList(invites);
      setCount(invites?.length / 1);
    }
  }, [allEntityInvitations]);

  useEffect(() => {
    if (
      acceptedRejectedEntityInviteDetails &&
      acceptedRejectedEntityInviteDetails?.status === ENTITY_INVITE_STATUS.DISMISSED
    ) {
      dispatch(getEntityInvitationList());
    }
  }, [acceptedRejectedEntityInviteDetails]);

  //Methods
  const handleStepChange = (step: number) => {
    setPage(step + 1);
  };

  const viewInvitation = (invite: EntityInvitionsType) => {
    dispatch(getEntityInviteDetails(invite?.token));
    dispatch(setShowEntityDetailsFlag(true));
  };

  const dismissApplication = (invite: EntityInvitionsType) => {
    const payload: AcceptInviteType = {
      inviteId: invite?.id,
      payload: {
        action: ENTITY_INVITATION_ACTION.DISMISS,
      },
    };
    dispatch(entityInviteAction(payload));
  };

  //HTML
  return (
    <>
      {invitationList?.length > 0 && (
        <>
          <Divider sx={{ my: theme.spacing(5) }} />
          <Box sx={{ mb: theme.spacing(5) }} className="textColor-200 text-h3 font-weight-semibold">
            {`${t('newInvitations', { ns: 'entityInvite' })}${invitationList?.length > 1 ? 's' : ''}`}
          </Box>
        </>
      )}
      <Box className={classes.root}>
        <SwipeableViews enableMouseEvents onChangeIndex={handleStepChange}>
          {invitationList?.length > 0 &&
            invitationList?.map((invite: EntityInvitionsType) => (
              <TPaper sx={{ p: 3 }} key={invite?.id}>
                <Box sx={{ mt: 2, mb: 5 }} className="flex-basic-center">
                  <TCommonAvatar
                    imageUrl={invite?.entity?.avatar_url || ''}
                    avatarText={invite?.entity?.name || ''}
                    iconSize="100px"
                    textSize={fontStyle.fontSize.font36.fontSize}
                  />
                </Box>
                <Box className="text-center">
                  <Box sx={{ mb: 4 }} className="textColor-200 text-h3 font-weight-semibold">
                    {`${t('inviteTitle', { ns: 'entityInvite' })} ${invite?.entity?.name} from ${
                      invite?.account?.first_name
                    } ${invite?.account?.last_name}`}
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TButton
                      onClick={() => viewInvitation(invite)}
                      variant="contained"
                      btnText="View Details"
                      btnWidthSize="button-w-240"
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <TButton
                      onClick={() => dismissApplication(invite)}
                      btnWidthSize="button-w-240"
                      variant="text"
                      btnText="Dismiss"
                    />
                  </Box>
                </Box>
              </TPaper>
            ))}
        </SwipeableViews>
        {invitationList?.length === 1 || (
          <Box className="flex-basic-center">
            <Pagination
              sx={{ mt: 2 }}
              siblingCount={0}
              boundaryCount={1}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              hideNextButton
              hidePrevButton
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default NewEntityInvitation;
