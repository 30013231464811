import React, { useEffect, useState } from 'react';
import { Box, CardActions, CardHeader, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import {
  getRequiredIdProcessList,
  selectInviteDashboardDetails,
  setSelectedProfiles,
} from '../../../redux/feature/dashboard/invitationSlice';
import { MerchantApplicationType, ProcessType, ProfileRequirementsType } from '../../../types/invitationTypes';
import {
  colorPalette,
  ID_PROCESS_STATUS,
  PROCESS_IDS,
  TButton,
  TCard,
  theme,
  TLoader,
} from '../../myde-react-components';
import { fontStyle } from '../../../styles/style';
import { ProfileDetails } from '../../../types/profileTypes';
import CommonIdProcessList from '../../id-process/CommonIdProcessList';
import {
  BENEFICIAL_OWNER_DOCUMENT,
  BENEFICIAL_OWNER_DOCUMENT_VALUES,
  BENEFICIAL_OWNER_ID_PROCESS_STATUS_TO_CHECK,
  GET_PROCESS_IDS,
} from '../../../constants/constants';
import { getProfileDetailsList, selectProfile } from '../../../redux/feature/profile/profileSlice';
import * as ROUTES from '../../../constants/routes';
import { useRouter } from '../../../providers/custom-router-provider';
import { CustomIdProcessType } from '../../../types/inviteTypes';
import { selectSession } from '../../../redux/feature/common/commonSlice';
import ProfileSelectionList from './ProfileSelectionList';
import { getEntityList, selectEntity, setSelectedEntity } from '../../../redux/feature/entity/entitySlice';
import { EntityTypes } from '../../../types/entityTypes';
import { getEntityProfileDetailsList, selectEntityProfile } from '../../../redux/feature/profile/entityProfileSlice';
import EntityProfileSelectionList from './EntityProfileSelectionList';
import {
  selectBeneficialOwnerDetails,
  setAreAllOwnerIdRequirementsFulfilled,
} from '../../../redux/feature/idProcess/beneficialOwnerDocumentSlice';

// Styles
const useStyles = makeStyles({
  inviteActionBtn: {
    '& .MuiButtonBase-root': {
      color: colorPalette.typoText.inviteAction,
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '14px',
      textTransform: 'capitalize',
    },
  },
  cardContent: {
    '& .MuiCardContent-root': {
      padding: '0px',
    },
  },
  listItem: {
    borderTop: `1px solid ${colorPalette.border.card}`,
    borderBottom: `1px solid ${colorPalette.border.card}`,
    cursor: 'pointer',
  },
  iconContainer: {
    width: '34px',
    height: '34px',
    border: `2px solid ${colorPalette.iconColor.main}`,
    borderRadius: '50%',
    textAlign: 'center',
  },
  icon: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  retry: {
    color: colorPalette.border.warning,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
  },
  badgeIcon: {
    '& .MuiBadge-badge': {
      top: '4px',
      right: '6px',
      minWidth: '10px',
      padding: 0,
      height: '0.6rem',
    },
  },
  caution: {
    '& .MuiSvgIcon-root': {
      width: '0.5em',
      height: 'auto',
    },
  },
  check: {
    pointerEvents: 'none',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  profileImage: {
    width: '50%',
  },
  cardActionContainer: {
    '& .MuiCardActions-root': {
      display: 'block',
    },
  },
  inviteContainer: {
    '& .MuiGrid-root': {
      display: 'block',
    },
  },
  cardContainer: {
    '& .MuiCard-root': {
      boxShadow: 'none',
      border: `1px solid ${colorPalette.containerBack.lighten2}`,
      borderRadius: '10px',
    },
  },
  brandIcon: {
    width: '52px',
    height: 'auto',
    margin: '0px 8px',
    ...fontStyle.fontSize.font10,
  },
});

const ViewInvitationDetails = () => {
  // Constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'inviteFlow']);
  const { routeTo } = useRouter();

  // Redux Values
  const {
    selectedApplication,
    basicIdProcessList,
    ownerIdProcessList,
    customIdProcessList,
    loading,
    isInviteSubmitAllowed,
  } = useSelector(selectInviteDashboardDetails);
  const { profileList } = useSelector(selectProfile);
  const { refreshPage } = useSelector(selectSession);
  const { checkEntityUiId, entityList, checkIsEntity, selectedEntity } = useSelector(selectEntity);
  const { entityProfileList } = useSelector(selectEntityProfile);
  const { areAllOwnerIdRequirementsFulfilled } = useSelector(selectBeneficialOwnerDetails);

  // State Values
  const [applicationDetails, setApplicationDetails] = useState({} as MerchantApplicationType);
  const [idProcessList, setIdProcessList] = useState([] as ProcessType[]);
  const [customProcesses, setCustomProcesses] = useState([] as CustomIdProcessType[]);
  const [allUserProfiles, setAllUserProfiles] = useState([] as ProfileDetails[]);
  const [allEntityProfiles, setAllEntityProfiles] = useState([] as ProfileDetails[]);
  const [selectedProfileNames, setSelectedProfileNames] = useState([] as string[]);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [profileIdProcessRequirement, setProfileIdProcessRequirement] = useState({} as ProfileRequirementsType);
  const [profileIdRequirementsList, setProfileIdRequirementsList] = useState([] as ProfileRequirementsType[]);
  const [isRequirementFulfilled, setIsRequirementFulfilled] = useState(false);
  const [selectEntityProfileData, setSelectedEntityData] = useState({} as string);
  const [isProfileNeeded, setIsProfileNeeded] = useState(false);
  const [isProfileReq, setIsProfileReq] = useState(false);

  // Use Effects
  useEffect(() => {
    dispatch(getEntityList());
  }, []);
  useEffect(() => {
    if (checkIsEntity && entityList?.length > 0) {
      const entityData =
        entityList?.find((entity: EntityTypes) => entity?.trellis_uid === checkEntityUiId) || ({} as EntityTypes);
      dispatch(setSelectedEntity(entityData));
    }
  }, [checkIsEntity, entityList]);
  useEffect(() => {
    if (selectedApplication?.id && selectedApplication?.invitation?.id) {
      dispatch(getRequiredIdProcessList(selectedApplication?.invitation?.id));
    }
    if (checkIsEntity) {
      dispatch(getEntityProfileDetailsList({ entityUId: selectedEntity?.trellis_uid, params: {} }));
    } else {
      dispatch(getProfileDetailsList({}));
    }
  }, [checkIsEntity, selectedEntity]);
  useEffect(() => {
    if (refreshPage) {
      if (selectedApplication?.id && selectedApplication?.invitation?.id) {
        dispatch(getRequiredIdProcessList(selectedApplication?.invitation?.id));
      }
      dispatch(getProfileDetailsList({}));
    }
  }, [refreshPage, selectedApplication]);
  useEffect(() => {
    if (selectedApplication?.id) {
      setApplicationDetails(selectedApplication);
    }
  }, [selectedApplication]);
  useEffect(() => {
    setIdProcessList(basicIdProcessList);

    const profileData =
      basicIdProcessList?.find((item) => [GET_PROCESS_IDS.PROFILE].includes(item?.process_id)) || ({} as ProcessType);
    setIsProfileNeeded(Object.keys(profileData)?.length > 0);

    const profileIdProcess =
      basicIdProcessList?.find((item) => item?.process_id === PROCESS_IDS.PROFILE_VERIFICATION) || ({} as ProcessType);
    if (
      profileIdProcess?.details?.profile_address_requirement_list &&
      profileIdProcess?.details?.profile_address_requirement_list?.length > 0
    ) {
      setIsProfileReq(profileData?.details?.is_mandatory);
      setProfileIdRequirementsList(profileIdProcess?.details?.profile_address_requirement_list);
      if (profileIdProcess?.details?.profile_address_requirement_list?.length === 1) {
        setProfileIdProcessRequirement(profileIdProcess?.details?.profile_address_requirement_list[0]);
      }
    }
  }, [basicIdProcessList]);
  useEffect(() => {
    let idProcesses = basicIdProcessList;
    let beneficialOwnerProcess = {} as ProcessType;
    if (ownerIdProcessList?.length > 0) {
      beneficialOwnerProcess = BENEFICIAL_OWNER_DOCUMENT;
      idProcesses = [...idProcesses, beneficialOwnerProcess];
    }
    if (checkIsEntity) {
      const profileData =
        basicIdProcessList?.find((item) => [GET_PROCESS_IDS.ENTITY_PROFILE].includes(item?.process_id)) ||
        ({} as ProcessType);
      setIsProfileNeeded(Object.keys(profileData)?.length > 0);
      setIsProfileReq(profileData?.details?.is_mandatory);
    }
    if (areAllOwnerIdRequirementsFulfilled && checkIsEntity) {
      const idProcessData = idProcesses;
      idProcesses = idProcessData?.map((item) => {
        if (item?.key === BENEFICIAL_OWNER_DOCUMENT_VALUES.KEY) {
          return {
            ...item,
            status: ID_PROCESS_STATUS.PASSED,
          };
        }
        return {
          ...item,
        };
      });
    }
    setIdProcessList(idProcesses);
  }, [checkIsEntity, basicIdProcessList, ownerIdProcessList, areAllOwnerIdRequirementsFulfilled]);
  useEffect(() => {
    setCustomProcesses(customIdProcessList);
  }, [customIdProcessList]);
  useEffect(() => {
    const filterProfileByType = profileList?.filter(
      (item) => item?.address_type === profileIdProcessRequirement?.address_type,
    );
    if (profileIdProcessRequirement?.address_type) {
      setAllUserProfiles(filterProfileByType);
    } else {
      setAllUserProfiles(profileList);
    }
  }, [profileList, profileIdProcessRequirement]);
  useEffect(() => {
    setAllEntityProfiles(entityProfileList);
  }, [checkIsEntity, entityProfileList]);
  useEffect(() => {
    setIsSubmitEnabled(isInviteSubmitAllowed);
  }, [isInviteSubmitAllowed]);
  useEffect(() => {
    const areAllProcessDone = ownerIdProcessList?.every((item: ProcessType) =>
      BENEFICIAL_OWNER_ID_PROCESS_STATUS_TO_CHECK.includes(item?.status),
    );
    dispatch(setAreAllOwnerIdRequirementsFulfilled(areAllProcessDone));
  }, [ownerIdProcessList]);

  // Methods
  const goForUserApproval = () => {
    dispatch(setSelectedProfiles(selectedProfileNames));
    routeTo(ROUTES.APPROVE_SUBMIT_INVITE);
  };
  const checkIfSubmitAllowed = () => {
    if (
      isSubmitEnabled &&
      ((isProfileReq && isProfileNeeded && Object.keys(selectedProfileNames)?.length > 0 && isRequirementFulfilled) ||
        !isProfileNeeded ||
        !isProfileReq)
    ) {
      return true;
    } else if (
      checkIsEntity &&
      isSubmitEnabled &&
      ((isProfileReq && isProfileNeeded && Object.keys(selectEntityProfileData)?.length > 0) ||
        !isProfileNeeded ||
        !isProfileReq)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const addSelectedProfiles = (data: any) => {
    setSelectedProfileNames(data);
    setSelectedEntityData(data);
  };
  const enableOrDisableForm = (value: boolean) => {
    setIsRequirementFulfilled(value);
  };

  // HTML
  return (
    <>
      <Box sx={{ pt: 10 }} className={classes.cardContainer}>
        <TLoader loading={loading} />
        <TCard sx={{ mt: 2 }}>
          {
            <Box className={classes.inviteContainer}>
              {/* Invitation Card Header */}
              <CardHeader
                title={
                  <Box>
                    <Box className="text-center flex-basic-center">
                      <Box sx={{ mr: 2 }} className="img-radius-box small-box">
                        <img
                          src={applicationDetails?.merchant?.branding?.logo_thumbnail_url}
                          loading="lazy"
                          alt="Branding Logo"
                        />
                      </Box>
                      <Box className="textColor-200 text-large font-weight-semibold">
                        {applicationDetails?.merchant?.name}
                      </Box>
                    </Box>
                  </Box>
                }
              />
              <Divider />
              {/* Invite Content */}
              <Box sx={{ px: 3, pt: 3 }}>
                <Box sx={{ mb: theme.spacing(3) }} className="textColor-200 text-large font-weight-semibold">
                  {t('applicationRequirements', { ns: 'inviteFlow' })}
                </Box>
                <Box className="textColor-200 text-medium font-weight-regular">
                  <Box sx={{ py: 1 }}>
                    {applicationDetails?.merchant?.name} {t('applicationReqText1', { ns: 'inviteFlow' })}
                  </Box>
                  <Box sx={{ py: 1 }}>{t('applicationReqText2', { ns: 'inviteFlow' })}</Box>
                  <Box sx={{ py: 1 }}>{t('applicationReqText3', { ns: 'inviteFlow' })}</Box>
                  <Box sx={{ py: 1 }}>{t('applicationReqText4', { ns: 'inviteFlow' })}</Box>
                </Box>
              </Box>
              {/* Profile Selection Option */}
              {!checkIsEntity && isProfileNeeded && allUserProfiles?.length > 0 && (
                <ProfileSelectionList
                  profiles={allUserProfiles}
                  profileIdRequirementsList={profileIdRequirementsList}
                  addSelectedProfiles={addSelectedProfiles}
                  enableOrDisableForm={enableOrDisableForm}
                />
              )}
              {checkIsEntity && isProfileNeeded && allEntityProfiles?.length > 0 && (
                <EntityProfileSelectionList profiles={allEntityProfiles} addSelectedProfiles={addSelectedProfiles} />
              )}
              {/* ID Process List */}
              {idProcessList?.length > 0 && (
                <CommonIdProcessList
                  list={idProcessList}
                  simpleList={true}
                  titleKey={'title'}
                  subtitleKey={'access_level'}
                />
              )}
              {customProcesses?.length > 0 && (
                <Box>
                  <CommonIdProcessList
                    list={customProcesses}
                    simpleList={true}
                    titleKey={'process_name'}
                    subtitleKey={'process_description'}
                    hasLongSubtitle={true}
                    isCustomProcess={true}
                  />
                </Box>
              )}
              {/* Invitation Card Actions */}
              <Box className={classes.cardActionContainer}>
                <CardActions>
                  {
                    <Box sx={{ my: 3 }} className="flex-basic-center">
                      <TButton
                        variant={'contained'}
                        btnText={'Submit Application'}
                        btnWidthSize="button-w-240"
                        disabled={!checkIfSubmitAllowed()}
                        onClick={goForUserApproval}
                      />
                    </Box>
                  }
                </CardActions>
              </Box>
            </Box>
          }
        </TCard>
      </Box>
      {!checkIsEntity && (
        <Box sx={{ px: 3, pt: theme.spacing(1) }}>
          <Box sx={{ mt: 2, mb: theme.spacing(3) }} className="textColor-200 text-large font-weight-semibold">
            {t('aboutVisibility', { ns: 'inviteFlow' })}
          </Box>
          <Box className="textColor-200 text-medium font-weight-regular">
            <Box>{t('inviteTextDesc', { ns: 'inviteFlow' })}</Box>
            <Box sx={{ my: 3 }}>
              <strong> SafeDoc</strong>: {t('safeDocDescription', { ns: 'inviteFlow' })}
            </Box>
            <Box sx={{ my: 3 }}>
              <strong> Source</strong>: {t('sourceDescription', { ns: 'inviteFlow' })}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ViewInvitationDetails;
