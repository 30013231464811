import React from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps, Theme } from '@mui/material';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { makeStyles } from '@mui/styles';
import { colorPalette } from '../../constants/colors';
export interface StyleProps {
  currentThemes: Theme;
  isDisabled: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>({
  customCheckBox: {
    padding: '7px!important',
    '&.Mui-checked': {
      color: ({ currentThemes, isDisabled }) =>
        isDisabled ? `${colorPalette.typoText.darken3} !important` : `${currentThemes.palette.primary.main} !important`,
    },
  },
});
export const Checkbox = ({ disabled = false, ...props }: CheckboxProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
    isDisabled: disabled,
  };
  const classes = useStyles(styleProps);

  return <MuiCheckbox className={classes.customCheckBox} disabled={disabled} {...props} />;
};
