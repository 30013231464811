import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';

import {
  colorPalette,
  theme,
  TButton,
  TLoader,
  ID_PROCESS_STATUS_COMPLETION_STATE,
  W9_W8_BEN_DOCUMENT_TYPE,
  PROCESS_IDS,
  ENTITY_PROCESS_IDS,
  PandaDocDialog,
} from '../../../myde-react-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  selectSupplementalDetails,
  setSelectedIdProcess,
} from '../../../../redux/feature/idProcess/supplementalVerificationSlice';
import { getEntityEsignUrl, getEsignUrl } from '../../../../api/idProcesses';
import { useTheme } from '../../../../providers/custom-theme-provider';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { selectSelectedIdProcessDetails } from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';

interface SelectTypeProps {
  isEntity: boolean;
}
export interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  listItem: {
    border: `2px solid ${colorPalette.stateDisabled}`,
    borderRadius: '10px',
    borderStyle: 'dashed',
  },
  listItemEnabled: {
    background: colorPalette.containerBack.lighten3,
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: '10px',
  },
  icon: {
    fontSize: '20px !important',
  },
  retry: {
    color: colorPalette.border.warning,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
  },
  loader: {
    color: colorPalette.backgroundFadeColors.secondary,
    zIndex: '9999 !important',
  },
  containerIcon: {
    paddingRight: '10px',
  },
  boxContainer: {
    height: '400px',
  },
}));

const SelectType = ({ isEntity }: SelectTypeProps) => {
  // Constants
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  // Redux Values
  const { selectedProfileDetails } = useSelector(selectSupplementalDetails);
  const { selectedIdProcessDetails, selectedEntityIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);
  const { selectedEntity } = useSelector(selectEntity);

  // State Variables
  const [openDialog, setOpenDialog] = useState(false);
  const [esign, setEsign] = useState('');
  const [selectedProcess, setSelectedProcess] = useState('');
  const [loading, setLoading] = useState(false);
  const [idProcessItem, setIdProcessItem] = useState({} as any);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Use Effects
  useEffect(() => {
    if (isEntity && Object.keys(selectedEntityIdProcessDetails?.data)?.length > 0) {
      setIdProcessItem(selectedEntityIdProcessDetails);
    } else if (!isEntity && Object.keys(selectedIdProcessDetails?.data)?.length > 0) {
      setIdProcessItem(selectedIdProcessDetails);
    }
  }, [isEntity, selectedIdProcessDetails, selectedEntityIdProcessDetails]);

  useEffect(() => {
    if ((selectedProfileDetails && Object.keys(selectedProfileDetails)?.length) || idProcessItem?.data?.profile?.id) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedProfileDetails, idProcessItem]);

  const getESignUrlData = async (element: string) => {
    try {
      const formData = {
        document_type: element,
        profile_id: selectedProfileDetails?.id,
      };
      setLoading(true);
      setSelectedProcess(element);
      dispatch(setSelectedIdProcess(element));
      const url = isEntity
        ? await getEntityEsignUrl(ENTITY_PROCESS_IDS.W8_W9_DOCUMENT, selectedEntity?.trellis_uid, formData)
        : await getEsignUrl(PROCESS_IDS.W9_W8_BEN_DOCUMENT, formData);
      setEsign(url.url);
      setLoading(false);
      setOpenDialog(true);
      setIsButtonDisabled(false);
    } catch (e) {
      setLoading(false);
      toast.error(t('somethingWentWrongText', { ns: 'notificationMessages' }));
    }
  };

  const onComplete = () => {
    setOpenDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleNext = () => {
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
  };

  const renderPreferredDocuments = () => {
    return (
      <Box className={classes.boxContainer}>
        <Box className="text-center" sx={{ pb: 5 }}>
          <Box
            className={clsx('text-h3 font-weight-semibold', {
              ['textColor-disabled']: isDisabled,
              ['textColor-200']: !isDisabled,
            })}
          >
            {t('selectTypeTitle', { ns: 'w8' })}
          </Box>
        </Box>
        <Box>
          <Box>
            <List>
              {W9_W8_BEN_DOCUMENT_TYPE.map((element: string, index: number) => (
                <ListItem
                  sx={{ mb: theme.spacing(5), px: 2, py: 3 }}
                  className={clsx('text-large textColor-200 font-weight-medium', {
                    [classes.listItem]: isDisabled,
                    [classes.listItemEnabled]: !isDisabled,
                  })}
                  key={index}
                  secondaryAction={
                    <>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        disabled={isDisabled}
                        onClick={() => getESignUrlData(element)}
                      >
                        <ArrowForwardIosIcon color={isDisabled ? 'disabled' : 'action'} className={classes.icon} />
                      </IconButton>
                    </>
                  }
                >
                  <Box className={classes.containerIcon}>
                    <Box>
                      <span
                        className={clsx(`icon-w8 icon-size-32`, {
                          ['textColor-disabled']: isDisabled,
                          ['textColor-300']: !isDisabled,
                        })}
                      ></span>
                    </Box>
                  </Box>
                  <Box
                    className={clsx('text-large font-weight-medium', {
                      ['textColor-disabled']: isDisabled,
                      ['textColor-200']: !isDisabled,
                    })}
                  >
                    {'Form' + '\t' + element}
                  </Box>
                </ListItem>
              ))}
              <Box sx={{ mt: theme.spacing(8) }} className="flex-basic-center">
                <TButton
                  btnText="Next"
                  variant={'contained'}
                  btnWidthSize={'button-w-240'}
                  disabled={isButtonDisabled}
                  onClick={() => handleNext()}
                />
              </Box>
            </List>
            <TLoader loading={loading} />
            <PandaDocDialog
              title={'Form' + '\t' + selectedProcess}
              open={openDialog}
              onClose={handleDialogClose}
              onComplete={onComplete}
              esign={esign}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  return <>{renderPreferredDocuments()}</>;
};
export default SelectType;
