import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import UploadDoc from '../../common/UploadDoc';
import { FileType } from '../../../../../types/documentTypes';
import { ID_PROCESS_STEP_KEYS, PROCESS_IDS } from '../../../../myde-react-components';
import { APPLICATION_STATUS, DOCUMENT_TYPE } from '../../../../../constants/constants';
import {
  clearIdProcessData,
  getIdProcessChildren,
  selectSelectedIdProcessDetails,
  setUploadedBackImage,
  setUploadedDocId,
} from '../../../../../redux/feature/idProcess/mainIdProcessSlice';
import {
  createDocumentVerification,
  selectIdeProcessDetails,
} from '../../../../../redux/feature/idProcess/documentVerificationSlice';
import { setPreviousStep } from '../../../../../redux/feature/common/commonSlice';
import licenseBackImage from '../../../../../common/assets/back-license.svg';

const UploadBackImage = () => {
  //constants
  const dispatch = useDispatch();
  const { t } = useTranslation('idDocumentFlow');

  // states
  const [preview, setPreview] = useState([] as FileType[]);
  const [frontImageId, setFrontImageId] = useState('');

  // selectors
  const {
    uploadedImage,
    selectedIdProcessDetails,
    uploadedDocId,
    uploadedFrontImage,
    uploadedImageToPreview,
    uploadedBackImage,
  } = useSelector(selectSelectedIdProcessDetails);
  const { selectedAddress } = useSelector(selectIdeProcessDetails);

  //useEffects
  useEffect(() => {
    dispatch(setPreviousStep(ID_PROCESS_STEP_KEYS.STEP3));
  }, []);

  useEffect(() => {
    if (Object.keys(uploadedImage)?.length > 0 && Object.keys(uploadedImageToPreview)?.length > 0) {
      const previewFile = {
        ...uploadedImage,
        signed_url: uploadedImageToPreview?.preview,
      };
      dispatch(setUploadedBackImage(previewFile));
      dispatch(clearIdProcessData());
    }
  }, [uploadedImage, uploadedImageToPreview]);

  useEffect(() => {
    if (Object.keys(uploadedBackImage)?.length > 0) {
      setPreview([uploadedBackImage]);
      dispatch(setUploadedDocId(uploadedBackImage?.doc_id));
    }
  }, [uploadedBackImage]);

  useEffect(() => {
    if (selectedIdProcessDetails?.data?.source_document?.back) {
      setPreview([selectedIdProcessDetails?.data?.source_document?.back]);
      dispatch(setUploadedDocId(selectedIdProcessDetails?.data?.source_document?.back?.doc_id));
    }

    if (selectedIdProcessDetails?.data?.source_document?.front) {
      setFrontImageId(selectedIdProcessDetails?.data?.source_document?.front?.doc_id);
    }
  }, [selectedIdProcessDetails]);

  useEffect(() => {
    if (uploadedFrontImage?.doc_id) {
      setFrontImageId(uploadedFrontImage?.doc_id);
    }
  }, [uploadedFrontImage]);

  //methods
  const handleNext = async () => {
    if (frontImageId === '') {
      return;
    }

    const payload: any = {
      process_id: PROCESS_IDS.DRIVERS_LICENSE,
      process_data: {
        document_type: DOCUMENT_TYPE.DRIVERS_LICENSE,
        front: frontImageId,
        back: uploadedDocId,
        document_country: selectedAddress?.address?.country,
        profile_id: selectedAddress?.id,
      },
      action: APPLICATION_STATUS.SUBMIT,
    };
    await dispatch(createDocumentVerification(payload));
    dispatch(getIdProcessChildren({ processId: PROCESS_IDS.DOCUMENT_VERIFICATION }));
    dispatch(setPreviousStep(ID_PROCESS_STEP_KEYS.STEP4));
  };

  const getCaptureInstructions = () => {
    return {
      instructionTitle: t('captureInstructionHeading'),
      photoCaptureInstrucationSection: true,
      captureDescriptionNote: t('imagePositionInstruction'),
      imagesUrl: {
        avatarImageUrl: licenseBackImage,
      },
      tipsList: [t('tip1'), t('tip2'), t('tip3'), t('tip4')],
    };
  };

  const getUploadInstructions = () => {
    return {
      instructionTitle: t('uploadInstructionHeading'),
      photoCaptureInstrucationSection: true,
      captureDescriptionNote: t('uploadLicenseImageInstruction'),
      imagesUrl: {
        uploadedImagesUrl: licenseBackImage,
      },
      imageCaption: t('backImage'),
    };
  };

  return (
    <Box>
      <UploadDoc
        title={t('idDocStep3')}
        showTitleText={`Upload ${isMobile ? '/ Capture' : ''} Back Image`}
        isEntity={false}
        handleNext={handleNext}
        isMultiple={false}
        previewFiles={preview}
        buttonText={'Submit'}
        captureInstruction={getCaptureInstructions()}
        uploadInstruction={getUploadInstructions()}
      />
    </Box>
  );
};

export default UploadBackImage;
