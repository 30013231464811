import React from 'react';
import { Box, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ID_PROCESS_STATUS_COMPLETION_STATE, TButton, theme } from '../../../../components/myde-react-components';
import { useTranslation } from 'react-i18next';
import { selectProfile, setIsSkip } from '../../../../redux/feature/profile/profileSlice';
import {
  resendEntityEmailVerification,
  resendProfileVerifyLinkHandler,
  selectDashboardDetails,
} from '../../../../redux/feature/verifyEmail/verifyEmailSlice';
import { useTheme } from '../../../../providers/custom-theme-provider';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import newemailDetectBgImage from '../../../../../src/common/assets/new-email-detect-bg-image.svg';
import { selectEntityProfile } from '../../../../redux/feature/profile/entityProfileSlice';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';
import useQuery from '../../../../hooks/useQuery';
interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  container: { textAlign: 'center' },
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
});

const EmailVerification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('profile');
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const query = useQuery();
  const isEntity = query.get('isEntity') === 'true';

  //redux values
  const { addedProfile } = useSelector(selectProfile);
  const { loading } = useSelector(selectDashboardDetails);
  const { entityAddedProfile } = useSelector(selectEntityProfile);
  const { showResendBtn } = useSelector(selectDashboardDetails);
  const { selectedEntity } = useSelector(selectEntity);

  //Methods
  const handleSkip = () => {
    dispatch(setIsSkip());
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
  };

  const resentProfileVerificationLink = () => {
    if (isEntity) {
      const data = {
        entityUid: selectedEntity?.trellis_uid,
        profile_id: entityAddedProfile?.id,
      };
      dispatch(resendEntityEmailVerification(data));
    } else {
      dispatch(resendProfileVerifyLinkHandler(addedProfile.id));
    }
  };

  //TSX
  return (
    <>
      <Box sx={{ p: 3 }} className={classes.container}>
        <Box>
          <Box sx={{ mb: 3 }} className="flex-basic-center">
            <Box className="p-relative email-detect-section">
              <img src={newemailDetectBgImage} alt="new Email" className={classes.img} />
              <span className={clsx('icon-new-email-detect icon-size-52', classes.icon)}></span>
            </Box>
          </Box>
          <Box sx={{ mb: theme.spacing(3) }} className="text-h2 font-weight-semibold textColor-200">
            {t('emailVerificationTitle')}
          </Box>
          <Box className="text-medium   textColor-200">{t('emailVerificationBody1')}</Box>
          <Box className="text-medium   textColor-200" sx={{ mb: theme.spacing(5) }}>
            {t('emailVerificationBody2')}
          </Box>
          <Box className="text-medium textColor-200" sx={{ mb: theme.spacing(8) }}>
            {t('question')}
          </Box>
          <Box sx={{ mb: 2 }}>
            {showResendBtn ? (
              <>
                <TButton
                  btnWidthSize={'button-w-240'}
                  btnText="Resend Email"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  onClick={resentProfileVerificationLink}
                />
              </>
            ) : (
              <>
                <Box
                  className="text-small font-weight-medium"
                  sx={{ my: 2, px: 3, color: currentThemes.palette.primary.main }}
                >
                  {t('emailVerificationLinkSent')}
                </Box>
              </>
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            <TButton
              btnWidthSize={'button-w-240'}
              btnText="Skip for Now"
              color="primary"
              variant="text"
              disabled={loading}
              onClick={handleSkip}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EmailVerification;
