import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { processDetailsById, draftOrCreateIdProcess } from '../../../api/idProcesses';
import { SsnIdProcessType, AddressType } from '../../../types/idProcessTypes';
import { ProfileDetails } from '../../../types/profileTypes';

type SsnState = {
  idProcessItem: SsnIdProcessType;
  loading: boolean;
  error: boolean;
  isEditMode: boolean;
  errorObj: any;
  address: AddressType;
  selectedAddress: ProfileDetails;
  visibilityLevel: boolean;
  unmaskedId: string;
  isSsnCreated: boolean;
};

const initialState: SsnState = {
  idProcessItem: <SsnIdProcessType>{},
  address: <AddressType>{},
  selectedAddress: <ProfileDetails>{},
  loading: false,
  error: false,
  isEditMode: false,
  errorObj: {},
  visibilityLevel: false,
  unmaskedId: '',
  isSsnCreated: false,
};

export const selectIdProcessDetails = ({ ssnVerification }: RootState) => ({
  idProcessItem: ssnVerification.idProcessItem,
  address: ssnVerification.address,
  selectedAddress: ssnVerification.selectedAddress,
  loading: ssnVerification.loading,
  error: ssnVerification.error,
  isEditMode: ssnVerification.isEditMode,
  visibilityLevel: ssnVerification.visibilityLevel,
  unmaskedId: ssnVerification.unmaskedId,
  isSsnCreated: ssnVerification.isSsnCreated,
});

export const getProcessDetailsById = createAsyncThunk(
  'ssnVerification/getProcessDetailsById',
  async (data: { processId: string | number; params?: object }) => {
    return await processDetailsById(data.processId, data.params);
  },
);

export const getProcessUnmaskedId = createAsyncThunk(
  'ssnVerification/getProcessUnmaskedId',
  async (data: { processId: string | number; params?: object }) => {
    return await processDetailsById(data.processId, data.params);
  },
);

export const setSelectedAddressToStore = createAsyncThunk(
  'ssnVerification/setSelectedAddressToStore',
  async (address: any) => {
    return address;
  },
);

export const createSsnVerification = createAsyncThunk(
  'ssnVerification/createAddressVerification',
  async (formdata: object) => {
    return await draftOrCreateIdProcess(formdata);
  },
);

export const ssnVerificationSlice = createSlice({
  name: 'ssnVerification',
  initialState,
  reducers: {
    setEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    resetEditMode: (state) => {
      state.isEditMode = false;
    },
    setVisibilityLevel: (state, action) => {
      state.visibilityLevel = action.payload;
    },
    setSsnCreatedFlag: (state, action) => {
      state.isSsnCreated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProcessDetailsById.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.idProcessItem = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getProcessDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getProcessUnmaskedId.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProcessUnmaskedId.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.unmaskedId = action?.payload?.data?.ssn;
        } else {
          state.error = true;
        }
      })
      .addCase(getProcessUnmaskedId.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(setSelectedAddressToStore.fulfilled, (state, address: any) => {
        state.error = false;
        state.loading = false;
        if (address.payload) {
          state.selectedAddress = address.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(createSsnVerification.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createSsnVerification.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        state.isSsnCreated = true;
        if (!action.payload) {
          state.error = true;
          state.isSsnCreated = false;
        }
      })
      .addCase(createSsnVerification.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setEditMode, resetEditMode, setVisibilityLevel, setSsnCreatedFlag } = ssnVerificationSlice.actions;

export default ssnVerificationSlice.reducer;
