import React, { useEffect, useState } from 'react';
import { Box, Divider, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { TCard, theme, TLoader, TTextField } from '../../myde-react-components';
import { ListDetailsResultType } from '../../../types/idProcessTypes';
import {
  getIdProcessSharedCountForEntity,
  getSharedIdPorcessDetailsForEntity,
  getSharedIdProcessDetails,
  getSharedMerchantsList,
  idProcessShareDetails,
  setCustomIdProcessId,
} from '../../../redux/feature/idProcess/idProcessShareSlice';
import { selectSelectedIdProcessDetails } from '../../../redux/feature/idProcess/mainIdProcessSlice';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { setSelectedMerchant } from '../../../redux/feature/idProcess/idProcessShareSlice';
import { useTranslation } from 'react-i18next';
import { AUTHORIZATION_STATUS } from '../../../constants/constants';
import useQuery from '../../../hooks/useQuery';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';

const useStyles = makeStyles({
  iconStyle: {
    marginRight: '16px',
    height: '24px',
    width: '24px',
  },
});

const SharedIdProcess = () => {
  const { routeTo } = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'userViewMessage']);
  const query = useQuery();
  const isEntity = query.get('isEntity') === 'true';

  //redux values
  const { sharedMerchantList, entitySharedMerchantList, loading, customIdProcessId } =
    useSelector(idProcessShareDetails);
  const { processId } = useSelector(selectSelectedIdProcessDetails);
  const { selectedEntity } = useSelector(selectEntity);

  //state variables
  const [merchantList, setdMerchantList] = useState([] as ListDetailsResultType[]);
  const [searchMerchant, setSearchMerchant] = useState('');

  //useEffects
  useEffect(() => {
    if (!isEntity && sharedMerchantList?.length > 0) {
      setdMerchantList(sharedMerchantList);
    } else if (isEntity && entitySharedMerchantList?.length > 0) {
      setdMerchantList(entitySharedMerchantList);
    } else {
      setdMerchantList([] as ListDetailsResultType[]);
    }
  }, [sharedMerchantList, entitySharedMerchantList]);

  useEffect(() => {
    dispatch(setSelectedMerchant({}));
  }, []);

  useEffect(() => {
    //the return statement is called before unmounting the component
    return () => {
      dispatch(setCustomIdProcessId(''));
    };
  }, []);

  //methods
  const getData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      authorization_status: AUTHORIZATION_STATUS.SHARED,
      page: 1,
      process_id: processId,
      query: '{id,merchant, created_on}',
      limit: 500,
      merchant_name: event?.target?.value,
    };
    return data;
  };

  const getEntityData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      entity_uid: selectedEntity?.trellis_uid,
      params: {
        process_id: processId,
        merchant_name: event?.target?.value,
      },
    };
    return data;
  };

  const getCustomData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      authorization_status: AUTHORIZATION_STATUS.SHARED,
      page: 1,
      account_id_process_id: customIdProcessId,
      query: '{id,merchant, created_on}',
      limit: 500,
      merchant_name: event?.target?.value,
    };
    return data;
  };

  const getEntityCustomData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      entity_uid: selectedEntity?.trellis_uid,
      params: {
        account_id_process_id: customIdProcessId,
        merchant_name: event?.target?.value,
      },
    };
    return data;
  };

  const handleMerchantChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMerchant(event?.target?.value);
    if (customIdProcessId) {
      searchCustomIdProcess(isEntity, event);
    } else {
      searchMerchantListing(event);
    }
  };

  const searchMerchantListing = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isEntity) {
      const data = getEntityData(event);
      await dispatch(getIdProcessSharedCountForEntity(data));
    } else {
      const data = getData(event);
      await dispatch(getSharedMerchantsList(data));
    }
  };

  const searchCustomIdProcess = async (isEntity: boolean, event: React.ChangeEvent<HTMLInputElement>) => {
    if (isEntity) {
      const data = getEntityCustomData(event);
      await dispatch(getIdProcessSharedCountForEntity(data));
    } else {
      const data = getCustomData(event);
      await dispatch(getSharedMerchantsList(data));
    }
  };

  const handleMerchantClick = async (merchant: ListDetailsResultType) => {
    await dispatch(setSelectedMerchant(merchant));
    if (isEntity) {
      const data = {
        entity_uid: selectedEntity?.trellis_uid,
        params: {
          merchant_id: merchant?.merchant?.id,
        },
      };
      await dispatch(getSharedIdPorcessDetailsForEntity(data));
    } else {
      const data = {
        authorization_status: AUTHORIZATION_STATUS.SHARED,
        page: 1,
        limit: 500,
        merchant_id: merchant.merchant?.id,
        query: '{account_id_process{id_process{title}, process_name}, id_access_level{title}, details}',
      };
      await dispatch(getSharedIdProcessDetails(data));
    }
    routeTo(ROUTES.SHARED_ID_DETAILS, true, `${merchant?.merchant?.name}`, isEntity && { isEntity });
  };

  return (
    <TCard>
      <TLoader loading={loading} />
      <Box>
        <Box sx={{ px: 3, py: 3 }}>
          <TTextField
            variant="outlined"
            placeholder="Search"
            value={searchMerchant}
            onChange={handleMerchantChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className="icon-search textColor-400" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{ pl: 2, pb: 2 }}
          className="font-weight-semibold text-medium"
        >{`${merchantList?.length} merchant(s)`}</Box>
        {merchantList?.length > 0 ? (
          <Box sx={{ pb: merchantList?.length < 5 ? 5 : theme.spacing(2) }} className="cursorPointer">
            {merchantList?.map((merchant: ListDetailsResultType, index: number) => {
              return (
                <Box key={merchant?.id} className="flex-column-start align-items-start">
                  <Box className="flex-basic-start" sx={{ pl: 2 }} onClick={() => handleMerchantClick(merchant)}>
                    <Box sx={{ pt: theme.spacing(1) }}>
                      <img
                        className={classes.iconStyle}
                        src={merchant?.merchant?.branding?.logo_thumbnail_url}
                        alt={'merchant logo'}
                      />
                    </Box>
                    <Box className="text-large font-weight-medium">{merchant?.merchant?.name}</Box>
                    {index < merchantList?.length - 1 && <Divider />}
                  </Box>
                  {index + 1 !== merchantList?.length && <Divider sx={{ my: theme.spacing(5) }} className="w-100" />}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box sx={{ pt: 5, pb: 5 }} className="text-h3 font-weight-semibold textColor-300 text-center">
            {t('nodataAvailable', { ns: 'userViewMessage' })}
          </Box>
        )}
      </Box>
    </TCard>
  );
};

export default SharedIdProcess;
