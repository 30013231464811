import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

import { Button } from '../Button/Button';
import { colorPalette } from '../../constants/colors';
import TextArea from '../Textarea/Textarea';
import ConfirmDialog from '../DeleteDialog/ConfirmDialog';
import { MAX_CHAR_LIMIT } from '../../constants/constants';
import { PORTAL } from '../../constants/portal';

const useStyles = makeStyles({
  listItem: {
    padding: '0 !important',
    fontFamily: 'Montserrat',
  },
  itemHeading: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: colorPalette.typoText.darken2,
  },
  itemDetail: {
    fontSize: '0.75rem',
    fontWeight: 400,
    color: colorPalette.typoText.darken2,
    wordBreak: 'break-word',
    marginTop: 5,
  },
});

export interface CommentListProps {
  maxLength?: number;
  list: ListType[];
  deleteMsg?: string;
  portalType?: string;
  onSave?: ClickHandlerType;
  onDelete?: ClickHandlerType;
}

interface ListType {
  id: string;
  comment: string;
  created_on: string;
  modified_on: string;
  isEditing?: boolean;
}

interface ClickHandlerType {
  (param: any): void;
}

export const CommentList = ({
  list,
  deleteMsg,
  maxLength = MAX_CHAR_LIMIT.CHAR_LENGTH,
  portalType = PORTAL.MYDE,
  onSave,
  onDelete,
}: CommentListProps) => {
  const classes = useStyles();

  const [currentComment, setCurrentComment] = useState({} as ListType);
  const [toDeleteComment, setCommentToDelete] = useState({} as ListType);
  const [commentList, setCommentList] = useState([] as ListType[]);
  const [showDeleteDialog, setDialogFlag] = useState(false);

  useEffect(() => {
    if (list && list.length > 0) {
      setCommentList(list);
    }
  }, [list]);

  const onEdit = (item: ListType) => {
    setCurrentComment(item);
  };
  const onClose = () => {
    setCurrentComment({} as ListType);
  };
  const onSaveComment = () => {
    if (onSave) {
      onSave(currentComment);
      onClose();
    }
  };
  const onDeleteComment = (item: ListType) => {
    setCommentToDelete(item);
    setDialogFlag(true);
  };
  const onCancelDelete = () => {
    setCommentToDelete({} as ListType);
    setDialogFlag(false);
  };
  const onConfirmDelete = () => {
    if (onDelete) {
      onDelete(toDeleteComment);
      onCancelDelete();
    }
  };
  const formatDate = (date: string) => {
    return moment(date).isValid() ? moment(date).format('MM/DD/YYYY hh:mm A') : '';
  };
  const updateComment = (item: any, event: any) => {
    const value = event?.target?.value || '';
    if (value && value.length > maxLength) {
      return;
    }
    const selectedComment = JSON.parse(JSON.stringify(item));
    selectedComment.comment = value;
    setCurrentComment(selectedComment);
  };

  return (
    <>
      <List>
        {commentList &&
          commentList.map((item) => (
            <ListItem sx={{ mb: 2 }} alignItems="flex-start" className={classes.listItem} key={item.id}>
              {currentComment?.id !== item.id && (
                <>
                  <ListItemText
                    sx={{ mr: 1 }}
                    primary={
                      <React.Fragment>
                        <Box sx={{ pb: 1 }} className="text-small textColor-300">
                          Last Edited: {formatDate(item.modified_on)}
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box className="text-medium font-weight-semibold textColor-200">{item.comment}</Box>
                      </React.Fragment>
                    }
                  ></ListItemText>
                  {onSave && portalType === PORTAL.MYDE && (
                    <Button
                      size="small"
                      variant="text"
                      icon={<span className="icon-edit" />}
                      onClick={() => onEdit(item)}
                      disabled={!!currentComment.id}
                      sx={{ mr: 1 }}
                    />
                  )}
                  {onDelete && portalType === PORTAL.MYDE && (
                    <Button
                      size="small"
                      variant="text"
                      icon={<span className="icon-delete" />}
                      onClick={() => onDeleteComment(item)}
                    />
                  )}
                </>
              )}
              {currentComment?.id === item.id && (
                <>
                  <ListItemText sx={{ mb: 2 }}>
                    <TextArea
                      minRows={2}
                      fullWidth={true}
                      label="Edit comment"
                      defaultValue={item.comment}
                      inputProps={{ maxLength: MAX_CHAR_LIMIT.CHAR_LENGTH }}
                      onInput={(event) => {
                        updateComment(item, event);
                      }}
                    />
                  </ListItemText>
                  <Button size="small" variant="text" sx={{ ml: 1 }} icon={<CheckIcon />} onClick={onSaveComment} />
                  <Button size="small" variant="text" icon={<CloseIcon />} onClick={() => onClose()} />
                </>
              )}
            </ListItem>
          ))}
      </List>
      <ConfirmDialog
        title="Confirmation"
        showDialog={showDeleteDialog}
        confirmationMessage={deleteMsg}
        onCancelClick={onCancelDelete}
        onConfirmClick={onConfirmDelete}
      />
    </>
  );
};

export default CommentList;
