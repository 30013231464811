import * as common from './en.json';
import * as inviteFlow from './invite-flow.json';
import * as faceWordSearch from './face-word-search.json';
import * as forgotPassword from './forgot-password.json';
import * as kycAmlReport from './kyc-aml-report.json';
import * as ssn from './id-process/tax-id.json';
import * as w8 from './id-process/w8.json';
import * as incomeVerification from './id-process/income-verification.json';
import * as biometricVerification from './id-process/biometric-verification.json';
import * as profile from './id-process/profile.json';
import * as addressVerification from './id-process/address-verification.json';
import * as accreditation from './id-process/accreditation.json';
import * as userViewMessage from './user-view-message.json';
import * as entityW8 from './id-process/entityW8.json';
import * as wetSignature from './id-process/wet-sign-verification.json';
import * as formationDocument from './id-process/formationDocument.json';
import * as letterOfGoodStanding from './id-process/letter-of-good-standing.json';
import * as irsLetterEvidencing from './id-process/irs-letter-evidecing.json';
import * as entityOwnership from './id-process/entity-ownership.json';
import * as entityInvite from './entity-invite.json';
import * as idDocumentFlow from './id-process/idDocumentFlow.json';
import * as beneficialOwnerDocument from './id-process/beneficial-owner-document.json';
import * as notificationMessages from './notification-messages.json';
import * as entityMembers from './entity-members.json';

const allEn = {
  common,
  inviteFlow,
  faceWordSearch,
  forgotPassword,
  kycAmlReport,
  ssn,
  w8,
  entityW8,
  incomeVerification,
  biometricVerification,
  profile,
  addressVerification,
  accreditation,
  userViewMessage,
  wetSignature,
  formationDocument,
  letterOfGoodStanding,
  entityOwnership,
  irsLetterEvidencing,
  entityInvite,
  idDocumentFlow,
  beneficialOwnerDocument,
  notificationMessages,
  entityMembers,
};

export default allEn;
