import { DocFiltersType } from '../types/documentTypes';
import { apiGet } from './axios';

export const getDocCategoryList = async (filters?: DocFiltersType) => {
  const res = await apiGet('/my-merchant-document/category', { params: filters });
  return res.data;
};
export const getSelectedCategoryFolders = async (data: { category: string; filters: object }) => {
  const res = await apiGet(`/my-merchant-document/category/${data.category}/year-month`, { params: data.filters });
  return res.data;
};
export const getSelectedFolderFiles = async (fileParams: object) => {
  const res = await apiGet('/my-merchant-document', { params: fileParams });
  return res.data;
};

export const getFile = async (docId: string, filters?: object) => {
  const res = await apiGet(`/my-merchant-document/${docId}`, { params: filters });
  return res.data;
};
