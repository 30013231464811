import { ACCESS_BRANDING, ACCESS_TOKEN, RESET_PASSWORD_TOKEN, VERIFICATION_TOKEN } from '../constants/constants';

const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};
const getItem = (key: string) => localStorage.getItem(key) || null;

export const setAccessToken = (token: string) => {
  setItem(ACCESS_TOKEN, token);
};

export const setEmailVerificationToken = (token: string) => {
  setItem(VERIFICATION_TOKEN, token);
};

export const setResetPasswordToken = (token: string) => {
  setItem(RESET_PASSWORD_TOKEN, token);
};

export const getAccessToken = () => getItem(ACCESS_TOKEN) || '';

export const getEmailVerificationToken = () => getItem(VERIFICATION_TOKEN) || '';

export const getResetPasswordToken = () => getItem(RESET_PASSWORD_TOKEN) || '';

export const setBranding = (branding: object) => {
  if (branding) {
    setItem(ACCESS_BRANDING, JSON.stringify(branding));
  }
};

export const getBranding = () => getItem(ACCESS_BRANDING) || '{}';

export const clearStorage = () => {
  localStorage.clear();
};
