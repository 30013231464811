import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import hardSet from 'redux-persist/es/stateReconciler/hardSet';
import reducer from './index';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore(
  {
    reducer: persistedReducer,
    devTools: true,
    middleware: [thunk],
  },
);
