import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, FormControlLabel, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';

import {
  ENTITY_MEMBER_PERMISSION,
  TAlertDialog,
  TButton,
  TCard,
  TCheckbox,
  theme,
  TLoader,
  TSelect,
  TTextField,
} from '../../myde-react-components';
import { useTheme } from '../../../providers/custom-theme-provider';
import { REGEX, REQUEST_REVIEW_STATUS, ROLES } from '../../../constants/constants';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { RouteType } from '../../../types/commonTypes';
import { setParentRoute, setPreviousStep } from '../../../redux/feature/common/commonSlice';
import {
  entityInvitessList,
  deleteMember,
  selectEntity,
  setSelectedTabName,
  updateEntityMemberDetails,
} from '../../../redux/feature/entity/entitySlice';
import { useTranslation } from 'react-i18next';
import useQuery from '../../../hooks/useQuery';
import { selectUser } from '../../../redux/feature/user/userSlice';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  btnColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const MemberDetails = () => {
  //const
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const query = useQuery();
  const selectedTabName = query.get('tab') || '';

  const DetailsMemberFormSchema = z.object({
    ownership_percentage: z
      .string()
      .nullish()
      .optional()
      .refine((value) => REGEX.NUMBER_DECIMAL.test(value || ''), {
        message: 'Please enter a number',
      })
      .or(z.literal('')),
    permissions: z.string().min(1, { message: 'Permissions is required' }),
  });
  type DetailsMemberFormSchemaPayload = z.infer<typeof DetailsMemberFormSchema>;
  const { handleSubmit, control, formState, setValue } = useForm<DetailsMemberFormSchemaPayload>({
    resolver: zodResolver(DetailsMemberFormSchema),
    mode: 'onChange',
  });

  //Constants
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const { t } = useTranslation(['common', 'entityMembers']);
  const classes = useStyles(styleProps);
  const { errors, isValid } = formState;

  //Redux values
  const { selectedMemberDetails, selectedEntity } = useSelector(selectEntity);
  const { userData } = useSelector(selectUser);

  //state variables
  const [loading, setLoading] = useState(true);
  const [permissionVaule, setPermissionValue] = useState('');
  const [isBeneficialOwner, setIsBeneficialOwner] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [hideDelete, setHideDelete] = useState(false);

  //useEffects
  useEffect(() => {
    const route: RouteType = {
      path: ROUTES.ENTITY_MEMBERS,
      isChild: true,
      stateName: '',
      query: null,
      stateObj: null,
    };
    dispatch(setParentRoute(route));
    dispatch(setPreviousStep(null));
    dispatch(setSelectedTabName(selectedTabName));
  }, []);

  useEffect(() => {
    if (selectedMemberDetails) {
      setPermissionValue(selectedMemberDetails?.permission);
      setValue('permissions', selectedMemberDetails?.permission, { shouldValidate: true });
      setValue('ownership_percentage', selectedMemberDetails?.ownership_percentage?.toString(), {
        shouldValidate: true,
      });
      setIsBeneficialOwner(selectedMemberDetails.is_beneficial_owner);
      setLoading(false);
    }
    hideDeleteIcon();
  }, [selectedMemberDetails]);

  //Methods
  const handleAccessChange = (event: any) => {
    setPermissionValue(event?.target?.value);
    setValue('permissions', event.target.value, { shouldValidate: true });
    setIsSaveDisabled(false);
  };

  const onSubmit = (formData: DetailsMemberFormSchemaPayload) => {
    const payload = {
      entity_uid: selectedMemberDetails?.entity_trellis_uid,
      trellis_uid: selectedMemberDetails?.account_trellis_uid,
      userData: {
        permission: formData?.permissions,
        user_role: selectedMemberDetails?.user_role,
        ownership_percentage: Number(formData?.ownership_percentage),
        is_beneficial_owner: isBeneficialOwner,
      },
    };
    dispatch(updateEntityMemberDetails(payload));
    getMemberUpdatedDetails();
  };

  const getMemberUpdatedDetails = async () => {
    const payload = {
      entityUid: selectedEntity?.trellis_uid,
      userData: {
        user_role: selectedTabName,
      },
    };
    await dispatch(entityInvitessList(payload));
    routeTo(ROUTES.ENTITY_MEMBERS, true, 'Members');
  };
  const handleCheckboxChange = () => {
    setIsBeneficialOwner(!isBeneficialOwner);
    setIsSaveDisabled(false);
  };

  const handelChangeTextBox = (event: any) => {
    setValue('ownership_percentage', event?.target?.value, {
      shouldValidate: true,
    });
    setIsSaveDisabled(false);
  };

  const handleNoClick = () => {
    setShowDeleteDialog(false);
  };

  const handleYesClick = async () => {
    await dispatch(
      deleteMember({
        entity_uid: selectedMemberDetails?.entity_trellis_uid,
        trellis_uid: selectedMemberDetails?.account_trellis_uid,
      }),
    );
    setShowDeleteDialog(false);
    await getMemberUpdatedDetails();
  };

  const hideDeleteIcon = () => {
    let result = false;
    if (
      selectedMemberDetails?.status === REQUEST_REVIEW_STATUS?.PENDING ||
      selectedMemberDetails?.account_trellis_uid === userData?.account?.trellis_uid ||
      selectedMemberDetails?.is_beneficial_owner
    ) {
      result = true;
    }
    setHideDelete(result);
  };

  return (
    <>
      <TLoader loading={loading} />
      <TCard sx={{ px: 3, py: theme.spacing(5) }}>
        <Box>
          <Box sx={{ mb: theme.spacing(3) }} className="flex-basic-space-between">
            <Box className="text-mdiumn font-weight-semibold textColor-200">Name</Box>
            {!hideDelete && (
              <Box>
                <Box
                  className={clsx(classes.btnColor, 'icon-delete icon-size-16 cursorPointer')}
                  onClick={() => setShowDeleteDialog(true)}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ mb: theme.spacing(3) }} className="flex-basic-start text-mdiumn">
            <Box className="icon-profile-users icon-size-20 textColor-300" sx={{ mr: 1 }} />
            <Box> {`${selectedMemberDetails?.first_name}  ${selectedMemberDetails?.last_name}`} </Box>
          </Box>
          <Box className="flex-basic-start text-mdiumn">
            <Box className="icon-email-details icon-size-16 textColor-300" sx={{ mr: 1 }} />
            <Box>{selectedMemberDetails?.email} </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Box sx={{ mb: theme.spacing(3) }} className="text-mdiumn font-weight-semibold textColor-200">
            {t('detailsText', { ns: 'entityMembers' })}
          </Box>
          <form>
            {selectedMemberDetails.user_role == ROLES.OWNER && (
              <Box sx={{ mb: theme.spacing(5) }}>
                <Controller
                  name={'ownership_percentage'}
                  defaultValue={null}
                  control={control}
                  render={({ field }) => (
                    <TTextField
                      {...field}
                      label="Ownership Entity Stake % (Optional)"
                      variant="outlined"
                      fullWidth
                      onChange={handelChangeTextBox}
                      error={Boolean(errors.ownership_percentage)}
                      helperText={errors.ownership_percentage?.message}
                      disabled={selectedMemberDetails?.status === REQUEST_REVIEW_STATUS.PENDING}
                    />
                  )}
                />
              </Box>
            )}
            <Box>
              <Box sx={{ mt: theme.spacing(5) }}>
                <Controller
                  name={'permissions'}
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <FormControl className="w-100">
                      <TSelect
                        {...field}
                        name={'permissions'}
                        id="type"
                        value={permissionVaule}
                        fullWidth
                        options={ENTITY_MEMBER_PERMISSION}
                        label="Permission"
                        itemId="value"
                        itemValue="label"
                        onChange={handleAccessChange}
                        disabled={selectedMemberDetails?.status === REQUEST_REVIEW_STATUS.PENDING}
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
            {selectedMemberDetails.user_role == ROLES.OWNER && (
              <Box sx={{ mt: 3 }}>
                <FormControlLabel
                  value="benificialOwner"
                  control={
                    <TCheckbox
                      checked={isBeneficialOwner}
                      value={isBeneficialOwner}
                      onChange={handleCheckboxChange}
                      disabled={selectedMemberDetails?.status === REQUEST_REVIEW_STATUS.PENDING}
                    />
                  }
                  label={t('beneficialOwner')}
                />
                <Box className="text-extra-small">{t('benificaialOwnerNote')}</Box>
              </Box>
            )}
            <Box className="flex-column-center" sx={{ mt: 6 }}>
              <TButton
                sx={{ mb: 2 }}
                btnText={'Save'}
                variant="contained"
                btnWidthSize="button-w-240"
                disabled={isSaveDisabled || !isValid || selectedMemberDetails?.status === REQUEST_REVIEW_STATUS.PENDING}
                onClick={handleSubmit(onSubmit)}
              />
            </Box>
          </form>
        </Box>
        <Box>
          <TAlertDialog
            showDialog={showDeleteDialog}
            title={'Delete'}
            content={`${t('deleteMemberText1')} ${selectedMemberDetails?.first_name} ${
              selectedMemberDetails?.middle_name && selectedMemberDetails?.middle_name
            } ${selectedMemberDetails?.last_name} ${t('deleteMemberText2')}`}
            yesBtnTitle={'Yes'}
            noBtnTitle={'No'}
            onCancel={handleNoClick}
            onConfirm={handleYesClick}
          />
        </Box>
      </TCard>
    </>
  );
};

export default MemberDetails;
