import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../components/myde-react-components';
import { titleCase } from '../../../../utils/utils';
import {
  getVerificationItem,
  resetVerificationItem,
  selectIdeProcessDetails,
  setResetTimeAndWords,
  setResetTimer,
} from '../../../../redux/feature/idProcess/biometricVerificationSlice';
import { PROCESS_IDS } from '../../../../constants/constants';
import clsx from 'clsx';
import { useTheme } from '../../../../providers/custom-theme-provider';

interface GenerateRandomNumberProps {
  title?: string;
  startTimer?: boolean;
}
export interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  iconColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});
const GenerateRandomNumber = ({ title, startTimer = false }: GenerateRandomNumberProps) => {
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { verificationItem, resetTimeAndWords } = useSelector(selectIdeProcessDetails);
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);
  const [resultantVerificationItems, setResultantVerificationItems] = useState<Array<string>>([]);

  const seconds = String(countDown % 60).padStart(2, '0');
  const minutes = String(Math.floor(countDown / 60)).padStart(2, '0');

  useEffect(() => {
    setRunTimer(false);
    if (!verificationItem?.verification_item?.length) {
      dispatch(getVerificationItem(PROCESS_IDS.BIOMETRIC_VERIFICATION));
    }

    return () => {
      resetVerificationItem();
    };
  }, []);

  useEffect(() => {
    if (verificationItem?.verification_item?.length) {
      setResultantVerificationItems(verificationItem?.verification_item);
    }
  }, [verificationItem]);

  useEffect(() => {
    if (resetTimeAndWords) {
      reloadVerificationItems();
      dispatch(setResetTimeAndWords(false));
    }
  }, [resetTimeAndWords]);

  useEffect(() => {
    if (countDown === 0 && !runTimer && startTimer) {
      setRunTimer(true);
    }
  }, [countDown, runTimer, verificationItem]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
      if (title !== 'Preview') {
        dispatch(getVerificationItem(PROCESS_IDS.BIOMETRIC_VERIFICATION));
      }
    }
  }, [countDown, runTimer]);

  useEffect(() => {
    let timerId: any;
    setCountDown(60 * 5);
    if (runTimer) {
      timerId = setInterval(() => {
        setCountDown((count) => count - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (startTimer) {
      setRunTimer(true);
    }
  }, [startTimer]);

  const reloadVerificationItems = () => {
    setCountDown(-1);
    setRunTimer(true);
    dispatch(setResetTimer(false));
  };

  return (
    <>
      <Box>
        <Box sx={{ mb: 1 }}>
          <Box className="flex-column-center" sx={{ mt: 3 }}>
            <Box className="textColor-300 text-medium">{title}</Box>

            {title !== 'Preview' && (
              <Box className="textColor-200 text-h2 font-weight-semibold">
                {minutes}:{seconds}
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          {title !== 'Preview' && (
            //this logic needs to be updated in code optimization
            <>
              <Box sx={{ mb: 2 }} className="text-center textColor-200 text-medium">
                {t('biometricDescription')}
              </Box>
              <Box className="flex-basic-center">
                <Box className="flex-basic-center refresh-icon-bg" sx={{ mt: 1, mr: 2 }}>
                  <span
                    className={clsx('icon-refresh cursorPointer icon-size-20', classes.iconColor)}
                    onClick={reloadVerificationItems}
                  />
                </Box>
                <Box className="text-center textColor-300 text-small custom-width-refresh-text">
                  {t('biometricRefreshText')}
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box sx={{ mt: 1 }}>
          <Box className="flex-column-center">
            {resultantVerificationItems?.map((item: string) => (
              <Box sx={{ mt: theme.spacing(5) }} className="bio-verification-word-text" key={item}>
                <Box>{titleCase(item)}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default GenerateRandomNumber;
