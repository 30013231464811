import React from 'react';
import { Button as MuiButton, ButtonProps, SvgIconProps, Theme } from '@mui/material';
import clsx from 'clsx';
import { FILE_TYPES } from '../../constants/fileTypes';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { makeStyles } from '@mui/styles';
export interface FileButtonProps {
  fileType?: typeof FILE_TYPES;
  acceptedFileTypes?: typeof FILE_TYPES;
  label?: string;
  isMultipleFile: boolean;
  btnWidthSize?: string;
  icon?: React.ReactElement<SvgIconProps>;
  variant: string;
  onClick?: () => void;
  onChange: (val: File[]) => void;
}
interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  fileBtn: {
    '& .MuiButton-root': {
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
  },
});

export type CombinedProps = FileButtonProps & ButtonProps;

export const FileButton = ({
  fileType = [],
  acceptedFileTypes = [],
  variant,
  label,
  isMultipleFile,
  btnWidthSize = '',
  icon,
  onChange,
  ...props
}: CombinedProps) => {
  let acceptedTypes = '';
  if (acceptedFileTypes?.length > 0) {
    acceptedTypes = acceptedFileTypes.toString();
  } else {
    acceptedTypes = FILE_TYPES.toString();
  }

  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);

  const handleChangeFile = (event: any): void => {
    event.persist();
    const filesList = event.target.files;
    let result: typeof filesList;
    if (filesList.length) {
      const arrFiles = Array.from(filesList);

      const fileName: Array<string> = [];

      result = arrFiles.filter((file: any, index: number): any => {
        const currentFileType: string = file.type;
        if (fileType && fileType.includes(currentFileType)) {
          if (arrFiles.length > index) {
            fileName.push(file.name);
            return { file, id: index };
          }
        } else if (!fileType || fileType.length === 0) {
          if (arrFiles.length > index) {
            fileName.push(file.name);
            return { file, id: index };
          }
        }
      });
    }
    onChange(result);
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const ev = event.target as HTMLInputElement;
    ev.value = '';
  };

  return (
    <div>
      <input
        accept={acceptedTypes}
        id="button-file"
        multiple={isMultipleFile}
        style={{ display: 'none' }}
        type="file"
        capture="environment"
        onChange={(e) => handleChangeFile(e)}
        onClick={onInputClick}
      />
      <label className={clsx({ [classes.fileBtn]: variant === 'text' })} htmlFor="button-file">
        <MuiButton className={btnWidthSize} {...props} startIcon={icon} variant={variant} component="span">
          {label}
        </MuiButton>
      </label>
    </div>
  );
};
