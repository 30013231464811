import * as React from 'react';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { typography, fontStyle } from '../../../styles/style';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { selectEntity, createNewAssociationRequest } from '../../../redux/feature/entity/entitySlice';
import { useTheme } from '../../../providers/custom-theme-provider';
import { TButton, TCard, theme } from '../../myde-react-components';
import FormIndex from '../Form/FormIndex';
import * as ROUTES from '../../../constants/routes';
import { useRouter } from '../../../providers/custom-router-provider';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textAlignment: {
    textAlign: 'center',
  },
  btnText: {
    textAlign: 'center',
    cursor: 'pointer',
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
  },
});

export const EntityResult = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { searchEntityType } = useSelector(selectEntity);

  const permissionButtonHandler = () => {
    const data = {
      trellis_uid: searchEntityType?.trellis_uid,
    };
    dispatch(createNewAssociationRequest(data));
    const stateObj = { isEntitySuccess: true };
    routeTo(ROUTES.TAX_ID_SUCCESS, true, 'Entity Created', null, stateObj);
  };

  const renderRequirements = () => {
    return (
      <Box className="h-100">
        {searchEntityType?.trellis_uid || searchEntityType?.tax_id ? (
          <Box>
            <Box sx={{ p: 3 }}>
              <Box className="text-large font-weight-semibold textColor-200 flex-basic-start">
                <Box>{t('findYourEntity')}</Box>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ py: 1, px: 3 }}>
              <Box sx={{ py: theme.spacing(5) }}>
                <Box className="textColor-300 text-small">{t('entityTid')}</Box>
                <Box sx={{ pt: theme.spacing(1) }} style={typography.subTitle}>
                  {searchEntityType?.trellis_uid}
                </Box>
              </Box>
              <Box>
                <Box className="textColor-300 text-small">{t('entityName')}</Box>
                <Box sx={{ pt: theme.spacing(1) }} style={typography.subTitle}>
                  {searchEntityType?.legal_name}
                </Box>
              </Box>
            </Box>
            <Box sx={{ py: 1, px: 3 }}>
              <Box style={typography.descriptionDark} sx={{ mt: 1 }}>
                {t('foundEntityDesc1')}
              </Box>
              <Box style={typography.descriptionDark}>{t('foundEntityDesc2')}</Box>
            </Box>
            <Box sx={{ py: 3, pt: 3, pb: 5, color: currentThemes.palette.primary.main }} className={classes.btnText}>
              <TButton btnText="Request Permission to Use" variant={'contained'} onClick={permissionButtonHandler} />
            </Box>
          </Box>
        ) : (
          <Box>
            <FormIndex />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <TCard>
      <Box>{renderRequirements()}</Box>
    </TCard>
  );
};
export default EntityResult;
