import React from 'react';
import { Box, Theme } from '@mui/material';
import { TCard, theme } from '../myde-react-components';
import clsx from 'clsx';
import { useTheme } from '../../providers/custom-theme-provider';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { PhotoCaptureInstructionProps } from '../../types/commonTypes';

export interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  primaryColor: {
    '& span': {
      color: ({ currentThemes }) => currentThemes.palette.primary.main,
    },
  },
}));

const PhotoCaptureInstruction = ({
  imagesUrl,
  captureDescriptionNote,
  tipsList,
  photoCaptureInstrucationSection = false,
  imageCaption,
}: PhotoCaptureInstructionProps) => {
  const { currentThemes } = useTheme();
  const { t } = useTranslation('common');
  const styleProp = {
    currentThemes,
  };
  const classes = useStyles(styleProp);
  return (
    <Box>
      <Box>
        <TCard sx={{ p: theme.spacing(5) }}>
          {photoCaptureInstrucationSection ? (
            <Box className="photoCaptureInstrucation flex-basic-center">
              {imagesUrl && imagesUrl?.uploadedImagesUrl ? (
                <Box sx={imageCaption ? { mb: 1 } : {}} className="photo-placeholder-box p-relative">
                  <Box className="flex-basic-center">
                    <img className="upload-image" src={imagesUrl?.uploadedImagesUrl} alt="image" />
                  </Box>
                  <Box sx={{ mb: 1 }} className="textColor-200 text-medium flex-basic-center">
                    {imageCaption}
                  </Box>
                </Box>
              ) : (
                <Box className={clsx('photo-placeholder-box flex-basic-center p-relative', classes.primaryColor)}>
                  <span className="icon-curve-placeholder- left-corner"></span>
                  <span className="icon-curve-placeholder- right-corner"></span>
                  <span className="icon-curve-placeholder- bottom-corner"></span>
                  <span className="icon-curve-placeholder- top-corner"></span>
                  <img className="capture-image" src={imagesUrl?.avatarImageUrl} alt="image" />
                </Box>
              )}
            </Box>
          ) : (
            <Box className="frontBackImage flex-column-center">
              <Box sx={{ mb: 4 }} className="flex-column-center">
                <img className="" src={imagesUrl?.imgFrontUrl} alt="image" />
                <Box sx={{ mt: 1 }} className="textColor-200 text-medium">
                  {t('frontImage', { ns: 'common' })}
                </Box>
              </Box>
              <Box sx={{ mb: 4 }} className="flex-column-center">
                <img className="" src={imagesUrl?.imageBackUrl} alt="image" />
                <Box sx={{ mt: 1 }} className="textColor-200 text-medium">
                  {t('backImage', { ns: 'common' })}
                </Box>
              </Box>
            </Box>
          )}
          {captureDescriptionNote && (
            <Box sx={{ mt: 3 }} className="textColor-200 text-medium text-center">
              {captureDescriptionNote}
            </Box>
          )}
          {tipsList && (
            <Box sx={{ mt: 3, px: 1 }} className="tipsBox">
              <Box sx={{ mb: 1 }} className="textColor-200 text-h3 font-weight-semibold">
                {t('tipsTitle', { ns: 'common' })}
              </Box>
              {tipsList?.map((element: string, index: number) => {
                return (
                  <Box key={index} sx={{ mb: theme.spacing(1) }} className="tipsList">
                    {element}
                  </Box>
                );
              })}
            </Box>
          )}
        </TCard>
      </Box>
    </Box>
  );
};

export default PhotoCaptureInstruction;
