import * as z from 'zod';
import { formatDateToUtc } from '../../../../utils/utils';
export const SsnFormSchema = z.object({
  ssn: z.string().nonempty('Tax ID is required'),
  dob: z
    .string()
    .nonempty('Birthdate is required')
    .refine((value) => Date.parse(formatDateToUtc(value)) < Date.parse(formatDateToUtc(new Date().toString())), {
      message: 'Birthdate cannot be a future date',
    }),
});

export type SsnFormSchemaPayload = z.infer<typeof SsnFormSchema>;
