import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RouteType } from '../../../types/commonTypes';
import * as ROUTES from '../../../constants/routes';
import useQuery from '../../../hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { setParentRoute, setPreviousStep } from '../../../redux/feature/common/commonSlice';
import { getSpecificInboxCommunications, messageDetails } from '../../../redux/feature/message/messageSlice';
import { InboxCommunicationType } from '../../../types/messageTypes';
import { FilePreviewType, TCard, TLoader, theme } from '../../../components/myde-react-components';
import { Box } from '@mui/material';
import { formatDate, getTimeStamp } from '../../../utils/utils';
import PreviewFileByType from '../../../components/common/PreviewFileByType';

const MyInboxMessageDetails = () => {
  //Constant
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const query = useQuery();
  const communicationId = query.get('id') || '';
  const merchant_id = query.get('merchant_id') || '';
  const entity_uid = query.get('entity_uid') || '';
  const is_entity = query.get('is_entity') === 'true';
  const invite_type = query.get('invite_type') || '';

  //Redux
  const { inboxCommunicationDetails, selectedMerchantDetails, loading } = useSelector(messageDetails);

  //State
  const [communicationDetails, setCommunicationDetails] = useState({} as InboxCommunicationType);
  const [preview, setPreview] = useState([] as FilePreviewType[]);

  //useEffects
  useEffect(() => {
    const query = { id: communicationId, merchant_id, entity_uid, is_entity, invite_type };
    routeBackToMyInbox(ROUTES.MY_INBOX, true, t('myInbox'), query);
    if (communicationId) {
      const communicationData = {
        communicationId,
        payload: is_entity ? { merchant_id, entity_uid } : { merchant_id },
      };
      dispatch(getSpecificInboxCommunications(communicationData));
    }
  }, []);

  useEffect(() => {
    if (inboxCommunicationDetails?.merchant_communication?.id) {
      setCommunicationDetails(inboxCommunicationDetails);
    }
  }, [inboxCommunicationDetails]);

  useEffect(() => {
    if (
      communicationDetails?.merchant_communication?.id &&
      communicationDetails?.merchant_communication?.document_list?.length
    ) {
      const documentList: FilePreviewType[] = [];
      communicationDetails?.merchant_communication?.document_list?.map((doc) => {
        const document: FilePreviewType = {
          name: doc?.display_name,
          type: doc?.mime_type,
          preview: doc?.signed_url,
          size: doc?.size_in_kb,
        };
        documentList.push(document);
      });
      setPreview(documentList);
    }
  }, [communicationDetails]);

  //Methods
  const routeBackToMyInbox = (path: string, isChild: boolean, stateName: string, query: object, stateObj?: object) => {
    const route: RouteType = {
      path,
      isChild,
      stateName,
      query,
      stateObj,
    };
    dispatch(setParentRoute(route));
    dispatch(setPreviousStep(null));
  };

  //HTML
  return (
    <TCard sx={{ p: 3 }}>
      <TLoader loading={loading} />
      <Box className="textColor-200 text-medium font-weight-semibold">
        {communicationDetails?.merchant_communication?.title}
      </Box>
      <Box sx={{ mb: theme.spacing(5) }} className="textColor-300 text-small">{`${formatDate(
        communicationDetails?.merchant_communication?.sent_on,
      )} | ${getTimeStamp(communicationDetails?.merchant_communication?.sent_on)} | by ${
        selectedMerchantDetails?.merchant_name
      }`}</Box>
      {!!communicationDetails?.merchant_communication?.document_list?.length && (
        <>
          <Box sx={{ mb: 2 }} className="textColor-200 text-medium font-weight-semibold">
            {t('files')}
          </Box>
          <Box sx={{ mb: 3 }}>
            <PreviewFileByType files={preview} isDownloadable={true} />
          </Box>
        </>
      )}
      {communicationDetails?.merchant_communication?.message && (
        <>
          <Box sx={{ mb: 1 }} className="textColor-200 text-medium font-weight-semibold">
            {t('message')}
          </Box>
          <div
            className="textColor-300 text-small"
            dangerouslySetInnerHTML={{ __html: communicationDetails?.merchant_communication?.message || '-' }}
          ></div>
        </>
      )}
    </TCard>
  );
};

export default MyInboxMessageDetails;
