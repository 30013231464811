import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import UserIcon from '../../components/common/UserIcon';
import * as ROUTES from '../../constants/routes';
import IdCardDetails from '../../components/id-card/IdCardDetails';
import { selectUser } from '../../redux/feature/user/userSlice';
import IdCardSelection from './IdCardSelection';
import EntityIdentity from './EntityIdentity';
import { selectProfile } from '../../redux/feature/profile/profileSlice';

export const TAppIndex = () => {
  //redux val
  const { userData } = useSelector(selectUser);
  const userName = `${userData?.account?.first_name} ${userData?.account?.last_name?.charAt(0)}.`;
  const { showEntitySelectionList, showEntityDetails } = useSelector(selectUser);
  const { profileData } = useSelector(selectProfile);
  const listItems = [
    {
      icon: 'icon-dashboard',
      label: 'My Dashboard',
      path: `${ROUTES.DASHBOARD}`,
    },
    {
      icon: 'icon-profile-users',
      label: 'My Identity',
      path: `${ROUTES.MY_IDENTITY}`,
    },
    {
      icon: 'icon-documents-file',
      label: 'Documents',
      path: `${ROUTES.MERCHANT_DOCUMENTS}`,
    },
  ];

  return (
    <Box>
      {showEntitySelectionList ? (
        <IdCardSelection />
      ) : showEntityDetails ? (
        <EntityIdentity />
      ) : (
        <>
          <Box sx={{ mt: 2 }} className="flex-basic-center">
            <UserIcon imgBorderRadius="100%" iconSize="165px" />
          </Box>
          <IdCardDetails
            userName={userName || ''}
            tId={userData?.account?.trellis_uid || 0}
            list={listItems}
            accreditationStatus={profileData?.details?.accreditation_status}
          />
        </>
      )}
    </Box>
  );
};

export default TAppIndex;
