import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  TButton,
  ID_PROCESS_STATUS,
  TLoader,
  ID_PROCESS_STATUS_COMPLETION_STATE,
} from '../../../myde-react-components';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from '../../../../hooks/useQuery';
import { makeStyles } from '@mui/styles';
import { PROCESS_IDS, VERIFICATION_ACTIONS } from '../../../../constants/constants';
import {
  getProcessDetailsById,
  selectIdeProcessDetails,
  createBiometricVerification,
  setUploadedVideoToStore,
  getVerificationItem,
  getIdProcessDetails,
} from '../../../../redux/feature/idProcess/biometricVerificationSlice';
import {
  selectSession,
  setCurrentStep,
  setPreviousStep,
  setRefreshPage,
} from '../../../../redux/feature/common/commonSlice';
import { useRouter } from '../../../../providers/custom-router-provider';
import BiometricVerification from './BiometricSubmission';
import VideoCaptureInstruction from './VideoCaptureInstruction';
import { RecordVideo } from './RecordVideo';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  firstStep: {
    display: 'flex',
    justifyContent: 'center',
  },
  pageContainer: {
    marginBottom: 100,
  },
});

const BiometricVerificationIndex = () => {
  // Redux Values
  const { uploadedVideo, verificationItem, idProcessItem, loading, uploading } = useSelector(selectIdeProcessDetails);
  const { refreshPage } = useSelector(selectSession);

  // Constants
  const classes = useStyles();
  const { goBack } = useRouter();
  const query = useQuery();
  const processId = query.get('id');
  const dispatch = useDispatch();
  const statusArray = [
    ID_PROCESS_STATUS.SUBMITTED,
    ID_PROCESS_STATUS.SYSTEM_APPROVED,
    ID_PROCESS_STATUS.PENDING_VERIFICATION,
    ID_PROCESS_STATUS.PASSED,
    ID_PROCESS_STATUS.FAILED,
    ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
  ];
  const verificationItemsData = idProcessItem?.data?.biometric_verification_words;
  const videoId = idProcessItem?.data?.video?.doc_id;

  // State Variables
  const [activeStep, setActiveStep] = useState(0);
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [showTimerPage, setShowTimerPage] = useState(true);
  const [showRecord, setShowRecord] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  // Use Effects
  useEffect(() => {
    const id = processId !== null ? processId : '';
    dispatch(getProcessDetailsById(id));
    dispatch(getVerificationItem(PROCESS_IDS.BIOMETRIC_VERIFICATION));
    dispatch(getIdProcessDetails());
  }, []);

  useEffect(() => {
    if (refreshPage) {
      const id = processId !== null ? processId : '';
      dispatch(getProcessDetailsById(id));
      dispatch(getVerificationItem(PROCESS_IDS.BIOMETRIC_VERIFICATION));
      dispatch(getIdProcessDetails());
      dispatch(setRefreshPage(false));
    }
  }, [refreshPage]);

  useEffect(() => {
    if (idProcessItem && idProcessItem?.data && Object.keys(idProcessItem?.data).length > 0) {
      dispatch(setUploadedVideoToStore({}));
      if (statusArray?.includes(idProcessItem?.status)) {
        setActiveStep(0);
      } else if (idProcessItem?.status === ID_PROCESS_STATUS.IN_DRAFT) {
        setActiveStep(1);
      } else {
        setActiveStep(0);
      }
    }
  }, [idProcessItem]);

  // Methods
  const getFormData = () => {
    const formData = {
      process_id: PROCESS_IDS.BIOMETRIC_VERIFICATION,
      process_data: {
        video_id: uploadedVideo?.doc_id || videoId,
        biometric_verification_words: verificationItem?.verification_item || verificationItemsData,
      },
      action: activeStep === 0 ? VERIFICATION_ACTIONS.DRAFT : VERIFICATION_ACTIONS.SUBMIT,
    };
    return formData;
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      const formData = getFormData();
      await dispatch(createBiometricVerification(formData));
      await dispatch(getProcessDetailsById(PROCESS_IDS.BIOMETRIC_VERIFICATION));
      setActiveStep(1);
    } else if (activeStep === 1) {
      const formData = getFormData();
      await dispatch(createBiometricVerification(formData));
      await dispatch(getProcessDetailsById(PROCESS_IDS.BIOMETRIC_VERIFICATION));
    } else if (activeStep === 3) {
      goBack();
    } else if (activeStep === 2) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const validateFile = () => {
    let result = false;
    if (
      (uploadedVideo && Object.keys(uploadedVideo)?.length > 0) ||
      (idProcessItem && idProcessItem?.data && Object.keys(idProcessItem.data)?.length > 0)
    ) {
      result = true;
    }
    return result;
  };

  const getBtnState = (step: number) => {
    let result = false;
    if (step === 0) {
      result = !validateFile() || disableConfirmButton;
    }
    return result;
  };

  const getBtnText = () => {
    if (activeStep === 0) {
      return 'Confirm';
    } else if (activeStep === 1) {
      return 'Submit';
    } else if (activeStep === 2) {
      return 'Next';
    }
  };

  const renderActions = () => {
    return (
      <div className={classes.firstStep}>
        <TButton
          variant={'contained'}
          btnWidthSize={'button-w-240'}
          disabled={getBtnState(activeStep)}
          btnText={getBtnText()}
          onClick={handleNext}
        />
      </div>
    );
  };

  const onStartOver = (step: number) => {
    if (step === 0) {
      setShowRecord(false);
      setShowUpload(false);
      setShowTimerPage(true);
    }
    setActiveStep(step);
  };
  const onSubmit = async () => {
    const formData = getFormData();
    await dispatch(createBiometricVerification(formData));
    await dispatch(getProcessDetailsById(PROCESS_IDS.BIOMETRIC_VERIFICATION));
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    dispatch(setPreviousStep(null));
  };

  const onVideoRecord = (step: number) => {
    setShowRecord(true);
    setActiveStep(step);
  };

  const onVideoUpload = (step: number) => {
    setShowUpload(true);
    setActiveStep(step);
  };

  const shouldShowCancelButton = (value: boolean) => {
    if (value) {
      setShowTimerPage(false);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <RecordVideo
            renderActions={renderActions()}
            disableConfirm={(value: boolean) => setDisableConfirmButton(value)}
            showCancelButton={(value: boolean) => shouldShowCancelButton(value)}
            showTimerPage={showTimerPage}
            onVideoRecord={onVideoRecord}
            onVideoUpload={onVideoUpload}
            showRecord={showRecord}
            showUpload={showUpload}
          />
        );
      case 1:
        return <BiometricVerification renderActions={renderActions()} onSubmit={onSubmit} onStartOver={onStartOver} />;
      case 2:
        return <VideoCaptureInstruction showRecordInstrucation={showRecord} renderActions={renderActions()} />;
    }
  };

  return (
    <Box className={classes.pageContainer}>
      <TLoader loading={loading || uploading} />
      <Box className="bottomFooter">{getStepContent(activeStep)}</Box>
    </Box>
  );
};
export default BiometricVerificationIndex;
