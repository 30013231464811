import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import {
  processDetailsById,
  verificationItemById,
  postUploadedDoc,
  draftOrCreateIdProcess,
} from '../../../api/idProcesses';
import { idProcessDetails } from '../../../api/dashboard';
import { BiometricIdProcessType, BiometricVerificationItemType } from '../../../types/idProcessTypes';
import { IdProcessDetails } from '../../../types/dashboardTypes';
import { toast } from 'react-toastify';

type BiometricState = {
  idProcessList: IdProcessDetails[];
  idProcessItem: BiometricIdProcessType;
  verificationItem: BiometricVerificationItemType;
  loading: boolean;
  error: boolean;
  errorObj: any;
  uploadedVideo: any;
  uploadedVideoToPreview: any;
  resetTimer: boolean;
  resetTimeAndWords: boolean;
  uploading: boolean;
};

const initialState: BiometricState = {
  idProcessList: [],
  idProcessItem: <BiometricIdProcessType>{},
  loading: false,
  error: false,
  errorObj: {},
  uploadedVideo: {},
  uploadedVideoToPreview: {},
  verificationItem: <BiometricVerificationItemType>{},
  resetTimer: false,
  resetTimeAndWords: false,
  uploading: false,
};

export const selectIdeProcessDetails = ({ biometricVerification }: RootState) => ({
  idProcessList: biometricVerification.idProcessList,
  idProcessItem: biometricVerification.idProcessItem,
  verificationItem: biometricVerification.verificationItem,
  uploadedVideo: biometricVerification.uploadedVideo,
  uploadedVideoToPreview: biometricVerification.uploadedVideoToPreview,
  resetTimer: biometricVerification.resetTimer,
  loading: biometricVerification.loading,
  resetTimeAndWords: biometricVerification.resetTimeAndWords,
  uploading: biometricVerification.uploading,
});

export const getIdProcessDetails = createAsyncThunk('biometricVerification/getIdProcessDetails', async () => {
  return await idProcessDetails();
});

export const getProcessDetailsById = createAsyncThunk(
  'biometricVerification/getProcessDetailsById',
  async (processId: string | number) => {
    return await processDetailsById(processId);
  },
);

export const getVerificationItem = createAsyncThunk(
  'biometricVerification/getVerificationItem',
  async (processId: string | number) => {
    return await verificationItemById(processId);
  },
);

export const setUploadedVideo = createAsyncThunk('biometricVerification/setUploadedVideo', async (video: File) => {
  return await postUploadedDoc(video);
});

export const setUploadedVideoToStore = createAsyncThunk(
  'biometricVerification/setUploadedVideoToStore',
  async (video: any) => {
    return video;
  },
);

export const createBiometricVerification = createAsyncThunk(
  'biometricVerification/createBiometricVerification',
  async (formdata: object) => {
    return await draftOrCreateIdProcess(formdata);
  },
);

export const biometricVerificationSlice = createSlice({
  name: 'biometricVerification',
  initialState,
  reducers: {
    setResetTimer(state, action) {
      state.resetTimer = action.payload;
    },
    resetUploadedVideoToPreview(state) {
      state.uploadedVideoToPreview = {};
    },
    setResetTimeAndWords(state, action) {
      state.resetTimeAndWords = action.payload;
    },
    resetVerificationItem(state) {
      state.verificationItem = <BiometricVerificationItemType>{};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProcessDetailsById.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.idProcessItem = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getProcessDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getIdProcessDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIdProcessDetails.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.idProcessList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getIdProcessDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getVerificationItem.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getVerificationItem.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.verificationItem = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getVerificationItem.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(setUploadedVideo.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.uploading = true;
      })
      .addCase(setUploadedVideo.fulfilled, (state, video: any) => {
        state.error = false;
        state.loading = false;
        state.uploading = false;
        if (video) {
          state.uploadedVideo = video.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setUploadedVideoToStore.fulfilled, (state, video: any) => {
        state.error = false;
        state.loading = false;
        if (video.payload) {
          state.uploadedVideoToPreview = video.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setUploadedVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.uploading = false;
        if (action.error.message?.includes('413')) {
          toast.error('Uploaded file is  too large');
        }
      })
      .addCase(createBiometricVerification.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createBiometricVerification.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        if (!action.payload) {
          state.error = true;
        }
      })
      .addCase(createBiometricVerification.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setResetTimer, resetUploadedVideoToPreview, setResetTimeAndWords, resetVerificationItem } =
  biometricVerificationSlice.actions;

export default biometricVerificationSlice.reducer;
