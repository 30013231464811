import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { idProcessMasterDetails, idProcessDetails } from '../../../api/dashboard';
import { IdProcessDetails, IdProcessMasterDetails } from '../../../types/dashboardTypes';
import { SupplementalDocVerificationType } from '../../../types/idProcessTypes';
import { processDetailsById } from '../../../api/idProcesses';
import { ProfileDetails } from '../../../types/profileTypes';
import { entityProcessDetailsById } from '../../../api/entityIdprocesses';

type SupplementalDocState = {
  idProcessItems: SupplementalDocVerificationType[];
  idProcessList: IdProcessDetails[];
  idProcessMasterList: IdProcessMasterDetails[];
  selectedProfileDetails: ProfileDetails;
  selectedIdProcess: string;
  loading: boolean;
  error: boolean;
  errorObj: any;
  entityProcessDetails: any;
};

const initialState: SupplementalDocState = {
  idProcessItems: [],
  idProcessList: [],
  idProcessMasterList: [],
  selectedProfileDetails: <ProfileDetails>{},
  selectedIdProcess: '',
  loading: false,
  error: false,
  errorObj: {},
  entityProcessDetails: {},
};

export const selectSupplementalDetails = ({ supplementalVerification }: RootState) => ({
  idProcessItems: supplementalVerification.idProcessItems,
  idProcessList: supplementalVerification.idProcessList,
  idProcessMasterList: supplementalVerification.idProcessMasterList,
  selectedProfileDetails: supplementalVerification.selectedProfileDetails,
  selectedIdProcess: supplementalVerification.selectedIdProcess,
  loading: supplementalVerification.loading,
  error: supplementalVerification.error,
  entityProcessDetails: supplementalVerification.entityProcessDetails,
});

export const getProcessDetailsById = createAsyncThunk(
  'supplementalVerification/getProcessDetailsById',
  async (processId: string | number) => {
    return await processDetailsById(processId);
  },
);

export const getEntityProcessDetailsById = createAsyncThunk(
  'supplementalVerification/getEntityProcessDetailsById',
  async (data: { processId: number; entityUid: number; params?: object }) => {
    return await entityProcessDetailsById(data.processId, data.entityUid);
  },
);

export const getIdProcessDetails = createAsyncThunk('supplementalVerification/getIdProcessDetails', async () => {
  return await idProcessDetails();
});

export const getIdProcessMasterDetails = createAsyncThunk(
  'supplementalVerification/getIdProcessMasterDetails',
  async () => {
    return await idProcessMasterDetails();
  },
);

export const supplementalVerificationSlice = createSlice({
  name: 'supplementalVerification',
  initialState,
  reducers: {
    setSelectedProfileDetails: (state, action) => {
      state.selectedProfileDetails = action.payload;
    },
    setSelectedIdProcess: (state, action) => {
      state.selectedIdProcess = action.payload;
    },
    clearSupplementalVerificationStoreData: (state) => {
      state.selectedProfileDetails = <ProfileDetails>{};
      state.selectedIdProcess = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProcessDetailsById.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload && action.payload.data && Object.keys(action.payload.data).length > 0) {
          state.idProcessItems.push(action.payload);
        } else {
          state.error = true;
        }
      })
      .addCase(getProcessDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getIdProcessDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIdProcessDetails.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.idProcessList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getIdProcessDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getIdProcessMasterDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIdProcessMasterDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.idProcessMasterList = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getIdProcessMasterDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getEntityProcessDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityProcessDetailsById.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.entityProcessDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityProcessDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setSelectedProfileDetails, setSelectedIdProcess, clearSupplementalVerificationStoreData } =
  supplementalVerificationSlice.actions;
export default supplementalVerificationSlice.reducer;
