import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TButton, theme, TPaper } from '../../myde-react-components';
import AddOwnersUsersImage from '../../../common/assets/entity-add-owners-users.svg';
import { useTheme } from '../../../providers/custom-theme-provider';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { hexToRgbA } from '../../../utils/utils';
import { setEntityMembersFormData } from '../../../redux/feature/entity/entitySlice';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  addOwnersUsersImageColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  imageContainer: {
    borderRadius: '50%',
    width: '7.5rem',
    height: '7.5rem',
    backgroundColor: ({ currentThemes }) => hexToRgbA(currentThemes.palette.primary.main, 0.2),
  },
});

const AddMembers = () => {
  //Constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { routeTo } = useRouter();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //Methods
  const goToAddMembers = () => {
    routeTo(ROUTES.ENTITY_ADD_OWNERS_USERS, true, 'Add Members');
    dispatch(setEntityMembersFormData({}));
  };

  const goToEntityMyIdentity = () => {
    routeTo(ROUTES.ENTITY_MY_IDENTITY);
  };

  return (
    <TPaper>
      <Box sx={{ my: theme.spacing(7), mx: 2 }} className="flex-column-center">
        <Box sx={{ mb: 4, pt: theme.spacing(2) }} className={classes.imageContainer}>
          <img className={classes.addOwnersUsersImageColor} src={AddOwnersUsersImage} alt="Add Owners/Users" />
        </Box>
        <Box sx={{ mb: theme.spacing(3) }} className="textColor-200 text-h3 font-weight-semibold">
          {t('addMembersToEntity', { ns: 'inviteFlow' })}
        </Box>
        <Box sx={{ mb: 4 }} className="textColor-200 text-medium text-center">
          {t('addMembersLater', { ns: 'inviteFlow' })}
        </Box>
        <Box sx={{ mb: 3 }}>
          <TButton
            fullWidth
            btnWidthSize="button-w-240"
            btnText="Add"
            variant="contained"
            onClick={() => goToAddMembers()}
          ></TButton>
        </Box>
        <Box>
          <TButton
            btnText="Skip"
            btnWidthSize="button-w-240"
            variant="text"
            onClick={() => goToEntityMyIdentity()}
          ></TButton>
        </Box>
      </Box>
    </TPaper>
  );
};

export default AddMembers;
