import { apiDelete, apiGet, apiPatch, apiPost, apiPut, apiUpload } from './axios';

export const getProfile = async () => {
  const res = await apiGet('/user-profile');
  return res.data;
};

export const updateProfile = async (formData: object) => {
  const res = await apiPatch('/user-profile', formData);
  return res.data;
};

export const uploadAvatar = async (doc: any) => {
  const file = new FormData();
  file.append('file', doc);
  const res = await apiUpload('/static-assets', file);
  return res.data;
};

export const membershipFlip = async () => {
  const res = await apiPatch('/membership');
  return res.data;
};

export const verifyProfileAddress = async (profileId: string) => {
  const res = await apiPost(`/profile/${profileId}/verify`);
  return res.data;
};

export const deleteSpecificProfile = async (profileId: string) => {
  const res = await apiDelete(`/profile/${profileId}`);
  return res.data;
};

export const editSpecificProfile = async (profileId: string, payload: object) => {
  const res = await apiPut(`/profile/${profileId}`, payload);
  return res.data;
};
