import React, { useEffect, useRef } from 'react';
import { Paper as MuiPaper, PaperProps } from '@mui/material';

export const TPaper = React.forwardRef<any, PaperProps>(({ children, ...props }: PaperProps, ref: any) => {
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    executeScroll();
  }, [containerRef]);

  const executeScroll = () => {
    if (containerRef.current) {
      return containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <MuiPaper ref={ref} {...props}>
      {children}
    </MuiPaper>
  );
});
export default TPaper;
