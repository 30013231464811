import { Box, Divider, Theme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import clsx from 'clsx';

import {
  theme,
  TButton,
  TCard,
  FilePreviewType,
  TConfirmDialog,
  TLoader,
} from '../../../components/myde-react-components';
import * as ROUTES from '../../../constants/routes';
import { useRouter } from '../../../providers/custom-router-provider';
import {
  customIdProcessDetails,
  getSpecificIdProcessDetails,
  setSelectedCategory,
} from '../../../redux/feature/idProcess/customIdProcessSlice';
import {
  selectSelectedIdProcessDetails,
  setCustomIdProcess,
} from '../../../redux/feature/idProcess/mainIdProcessSlice';
import PreviewFileByType from '../../../components/common/PreviewFileByType';
import { FileType } from '../../../types/documentTypes';
import { useTheme } from '../../../providers/custom-theme-provider';
import { linkCustomIdProcessToMerchantIdRequirement } from '../../../api/idProcesses';
import { CUSTOM_ID_REQUIREMENTS_LINK_UNLINK } from '../../../constants/constants';
import { SupplementalIdProcessResults } from '../../../types/dashboardTypes';
import {
  getRequiredIdProcessList,
  selectInviteDashboardDetails,
} from '../../../redux/feature/dashboard/invitationSlice';

interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  iconColor: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
});

const CustomDocumentDescription = () => {
  // Constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation(['common', 'inviteFlow']);
  const { routeTo } = useRouter();
  const dispatch = useDispatch();

  // State Variables
  const [showDialog, setShowDialog] = useState(false);
  const [files, setFiles] = useState([] as FilePreviewType[]);
  const [processDescription, setProcessDescription] = useState('');

  // Redux Values
  const { selectedCustomIdProcess } = useSelector(selectSelectedIdProcessDetails);
  const { loading, selectedCategory } = useSelector(customIdProcessDetails);
  const { selectedApplication, customIdProcessList } = useSelector(selectInviteDashboardDetails);

  // Use Effects
  useEffect(() => {
    if (!selectedCustomIdProcess?.account_id_process_id) {
      setProcessDescription(selectedCustomIdProcess?.process_description);
    } else {
      setProcessDescription(selectedCategory?.process_data?.process_description);
    }
  }, [selectedCustomIdProcess, selectedCategory]);

  useEffect(() => {
    if (selectedCustomIdProcess?.account_id_process_id) {
      const data = {
        id: selectedCustomIdProcess?.account_id_process_id,
      };
      dispatch(getSpecificIdProcessDetails(data));
    }
  }, [selectedCustomIdProcess]);

  useEffect(() => {
    if (customIdProcessList && customIdProcessList?.length > 0) {
      const data = customIdProcessList?.find((item) => item.id === selectedCustomIdProcess.id);
      dispatch(setCustomIdProcess(data));
    }
  }, [customIdProcessList]);

  useEffect(() => {
    if (selectedCategory) {
      const fileList: any[] = [];
      selectedCategory?.process_data?.document_list?.forEach((file: FileType) => {
        const data = {
          name: file?.doc_name,
          type: file?.mime_type,
          preview: file?.signed_url,
          size: file?.size_in_kb,
        };
        fileList?.push(data);
      });
      setFiles([...fileList]);
    }
  }, [selectedCategory]);

  // Methods
  const deLinkDocument = async () => {
    const formData = {
      action: CUSTOM_ID_REQUIREMENTS_LINK_UNLINK.UNLINK,
      merchant_id_requirement_id: selectedCustomIdProcess?.id,
      account_id_process_id: selectedCustomIdProcess?.account_id_process_id,
    };

    try {
      const result = await linkCustomIdProcessToMerchantIdRequirement(formData);
      if (result?.detail) {
        dispatch(setSelectedCategory({} as SupplementalIdProcessResults));
        dispatch(getRequiredIdProcessList(selectedApplication?.invitation?.id));
        setShowDialog(false);
      }
    } catch (error) {}
  };

  const navigateTo = () => {
    routeTo(ROUTES.CUSTOM_DOCUMENTS_CATEGORY, true, 'Custom ID');
  };
  return (
    <>
      <TCard sx={{ mb: 8 }}>
        <TLoader loading={loading} />
        <Box sx={{ px: 3, pt: 3, pb: theme.spacing(5) }}>
          <Box className="flex-column-center">
            <Box
              sx={{ pt: theme.spacing(4), mb: theme.spacing(5) }}
              className={clsx(classes.iconColor, 'icon-custom-document icon-size-72')}
            />
            {selectedCustomIdProcess?.id && (
              <>
                <Box sx={{ pb: theme.spacing(3) }} className="textColor-200 text-h2 font-weight-semibold">
                  {selectedCustomIdProcess?.process_name}
                </Box>
                <Box
                  sx={{ pb: theme.spacing(5) }}
                  className="text-center textColor-200 text-medium font-weight-regular"
                >
                  {!selectedCustomIdProcess?.account_id_process_id ? (
                    <>{`Attach your ${selectedCustomIdProcess?.process_name} document(s)`}</>
                  ) : (
                    <>{selectedCustomIdProcess?.process_description}</>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Divider />
        <Box sx={{ px: 3 }}>
          {processDescription && (
            <Box sx={{ mt: theme.spacing(7), pb: theme.spacing(5) }}>
              <Box className="textColor-200 text-large font-weight-semibold" sx={{ pb: theme.spacing(3) }}>
                {t('textDescription', { ns: 'inviteFlow' })}
              </Box>
              <Box className="textColor-200 text-medium font-weight-regular">{processDescription}</Box>
            </Box>
          )}

          <Box sx={{ mb: 3 }} className={'text-center'}>
            {!selectedCustomIdProcess?.account_id_process_id ? (
              <Box sx={{ mt: 4 }}>
                <TButton
                  variant={'contained'}
                  btnWidthSize={'button-w-240'}
                  btnText={'Start'}
                  onClick={navigateTo}
                  disabled={false}
                />
              </Box>
            ) : (
              <>
                <Box sx={{ mt: theme.spacing(5) }}>
                  <Box sx={{ mb: 4 }}>
                    {files?.length > 0 && (
                      <>
                        <Box className="flex-basic-start text-medium  font-weight-semibold textColor-200">
                          {t('textDocuments', { ns: 'inviteFlow' })}
                        </Box>
                        <Box>
                          <PreviewFileByType files={files} />
                        </Box>
                      </>
                    )}
                  </Box>
                  <TButton
                    variant={'contained'}
                    onClick={() => setShowDialog(true)}
                    btnWidthSize={'button-w-240'}
                    btnText={'Detach'}
                    disabled={false}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </TCard>
      <TConfirmDialog
        showDialog={showDialog}
        title="Confirmation"
        confirmationMessage={t('areYouSureDetachIDProcesText', { ns: 'notificationMessages' })}
        onConfirmClick={deLinkDocument}
        onCancelClick={() => setShowDialog(false)}
      />
    </>
  );
};

export default CustomDocumentDescription;
