import React from 'react';
import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import DocumentListing from '../../../components/documents/DocumentListing';
import { PDF_MIME_TYPE } from '../../../constants/constants';
import { useRouter } from '../../../providers/custom-router-provider';
import { getSelectedFile, setSelectedFile } from '../../../redux/feature/documents/documentsSlice';
import { MerchantDocumentType } from '../../../types/documentTypes';
import * as ROUTES from '../../../constants/routes';
import { TButton } from '../../../components/myde-react-components';
import clsx from 'clsx';

interface SearchListingProps {
  filesList: MerchantDocumentType[];
  showCategory?: boolean;
  showYearMonth?: boolean;
  showViewAllBtn: boolean;
  viewAllBtnClickHandler: () => void;
}

// Styles
const useStyles = makeStyles({
  btnViewAllResults: {
    width: '95%',
    position: 'absolute',
    bottom: '48px',
  },
});

export const SearchListing = ({
  filesList,
  showCategory = false,
  showYearMonth = false,
  showViewAllBtn,
  viewAllBtnClickHandler,
}: SearchListingProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  const selectFile = (file: MerchantDocumentType) => {
    const data = {
      docId: file.doc_id,
    };
    dispatch(getSelectedFile(data));
    dispatch(setSelectedFile(file));
    routeTo(ROUTES.FILE_DETAILS, true, 'Document');
  };

  const documentListing = filesList?.map((file, index) => {
    return (
      <>
        <DocumentListing
          key={file?.doc_id}
          name={file?.name}
          icon={PDF_MIME_TYPE.includes(file?.details?.mime_type) ? 'PictureAsPdfOutlinedIcon' : 'ImageOutlinedIcon'}
          showDescriptionIcon={file?.merchant_communication?.message !== ''}
          modifiedDateTime={file?.modified_on}
          onClick={() => selectFile(file)}
          category={showCategory ? file?.details?.category : ''}
          year_month={showYearMonth ? file?.details?.year_month : ''}
          showUnreadIcon={!file?.is_read}
        />

        {index < filesList?.length - 1 && <Divider />}
      </>
    );
  });

  return (
    <>
      <div>{documentListing}</div>
      {showViewAllBtn && filesList?.length > 3 && (
        <div className={clsx(classes.btnViewAllResults, 'flex-basic-center')}>
          <TButton
            btnText="Verify All Results"
            variant={'contained'}
            color="primary"
            btnWidthSize={'button-w-240'}
            onClick={viewAllBtnClickHandler}
          />
        </div>
      )}
    </>
  );
};

export default SearchListing;
