import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../index';
import {
  getInvites,
  getIdRequirementsForInvitation,
  getStartedApplications,
  submitApplication,
  updateInviteStatus,
  submitEntityApplication,
} from '../../../api/invite';
import {
  IdRequirementsMerchantObject,
  MerchantApplicationType,
  MerchantInvitationType,
  ProcessType,
} from '../../../types/invitationTypes';
import { CustomIdProcessType } from '../../../types/inviteTypes';

const initialState = {
  loading: false,
  error: false,
  inviteList: <MerchantInvitationType[]>[],
  applicationList: <MerchantApplicationType[]>[],
  selectedApplication: <any>{},
  showApplicationModal: false,
  inviteDetails: {},
  basicIdProcessList: <ProcessType[]>[],
  customIdProcessList: <CustomIdProcessType[]>[],
  ownerIdProcessList: <ProcessType[]>[],
  ownerCustomIdProcessList: <CustomIdProcessType[]>[],
  selectedProfiles: <string[]>[],
  isApplicationSubmitted: false,
  isInviteSubmitAllowed: false,
  idRequirementsResult: <IdRequirementsMerchantObject>{},
  isMerchantApplicationRejected: false,
};

export const selectInviteDashboardDetails = ({ inviteDashboard }: RootState) => ({
  loading: inviteDashboard.loading,
  error: inviteDashboard.error,
  inviteList: inviteDashboard.inviteList,
  applicationList: inviteDashboard.applicationList,
  selectedApplication: inviteDashboard.selectedApplication,
  showApplicationModal: inviteDashboard.showApplicationModal,
  inviteDetails: inviteDashboard.inviteDetails,
  basicIdProcessList: inviteDashboard.basicIdProcessList,
  customIdProcessList: inviteDashboard.customIdProcessList,
  ownerIdProcessList: inviteDashboard.ownerIdProcessList,
  ownerCustomIdProcessList: inviteDashboard.ownerCustomIdProcessList,
  selectedProfiles: inviteDashboard.selectedProfiles,
  isApplicationSubmitted: inviteDashboard.isApplicationSubmitted,
  isInviteSubmitAllowed: inviteDashboard.isInviteSubmitAllowed,
  idRequirementsResult: inviteDashboard.idRequirementsResult,
  isMerchantApplicationRejected: inviteDashboard.isMerchantApplicationRejected,
});

export const getInvitationList = createAsyncThunk('inviteDashboard/getInvitationList', async (filter?: object) => {
  return await getInvites(filter);
});
export const getApplicationList = createAsyncThunk('inviteDashboard/getApplicationList', async (filters?: object) => {
  return await getStartedApplications(filters);
});
export const getRequiredIdProcessList = createAsyncThunk(
  'inviteDashboard/getRequiredIdProcessList',
  async (invitationId: string) => {
    return await getIdRequirementsForInvitation(invitationId);
  },
);
export const submitMerchantApplication = createAsyncThunk(
  'inviteDashboard/submitMerchantApplication',
  async (reqData: { applicationId: string; data: { action: string; profiles: any } }) => {
    return await submitApplication(reqData.applicationId, reqData.data);
  },
);
export const submitEntityMerchantApplication = createAsyncThunk(
  'inviteDashboard/submitMerchantApplication',
  async (reqData: { applicationId: string; data: { action: string; profiles: any } }) => {
    return await submitEntityApplication(reqData.applicationId, reqData.data);
  },
);

export const updateMerchantInvitationStatus = createAsyncThunk(
  'inviteDashboard/updateMerchantInvitationStatus',
  async (reqData: object) => {
    return await updateInviteStatus(reqData);
  },
);

export const inviteDashboardSlice = createSlice({
  name: 'inviteDashboard',
  initialState,
  reducers: {
    setSelectedApplciation: (state, action) => {
      state.selectedApplication = action.payload;
    },
    setApplicationModalFlag: (state, action) => {
      state.showApplicationModal = action.payload;
    },
    setSelectedProfiles: (state, action) => {
      state.selectedProfiles = action.payload;
    },
    setIsApplicationSubmittedFlag: (state, action) => {
      state.isApplicationSubmitted = action.payload;
    },
    resetIsMerchantApplicationRejected: (state) => {
      state.isMerchantApplicationRejected = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getInvitationList
      .addCase(getInvitationList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInvitationList.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        state.inviteList = action.payload?.results || [];
      })
      .addCase(getInvitationList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // getApplicationList
      .addCase(getApplicationList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationList.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        state.applicationList = action.payload?.results || [];
      })
      .addCase(getApplicationList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // getRequiredIdProcessList
      .addCase(getRequiredIdProcessList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getRequiredIdProcessList.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        state.idRequirementsResult = action?.payload?.merchant;
        state.inviteDetails = action?.payload?.merchant?.details || {};
        state.basicIdProcessList = action?.payload?.merchant?.id_requirements || [];
        state.customIdProcessList = action?.payload?.merchant?.custom_id_requirements || [];
        state.ownerIdProcessList = action?.payload?.merchant?.owner_id_requirements || [];
        state.ownerCustomIdProcessList = action?.payload?.merchant?.owner_custom_id_requirements || [];
        state.isInviteSubmitAllowed = action?.payload?.ready_for_submit;
      })
      .addCase(getRequiredIdProcessList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // submitMerchantApplication
      .addCase(submitMerchantApplication.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(submitMerchantApplication.fulfilled, (state) => {
        state.error = false;
        state.loading = false;
        state.isApplicationSubmitted = true;
      })
      .addCase(submitMerchantApplication.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.isMerchantApplicationRejected = true;
      })
      // updateMerchantInvitationStatus
      .addCase(updateMerchantInvitationStatus.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateMerchantInvitationStatus.fulfilled, (state) => {
        state.error = false;
        state.loading = false;
      })
      .addCase(updateMerchantInvitationStatus.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setSelectedApplciation,
  setApplicationModalFlag,
  setSelectedProfiles,
  setIsApplicationSubmittedFlag,
  resetIsMerchantApplicationRejected,
} = inviteDashboardSlice.actions;

export default inviteDashboardSlice.reducer;
