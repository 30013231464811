import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import errorImgBg from '../../../components/myde-react-components/src/common/assets/error-placeholder.svg';
import { TButton, theme, TPaper } from '../../myde-react-components';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { selectProfile } from '../../../redux/feature/profile/profileSlice';

const TaxIdFail = () => {
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();

  //redux values
  const { profileData } = useSelector(selectProfile);

  const handleContinue = () => {
    routeTo(ROUTES.TAX_ID_DETAILS, true, 'Entity');
  };

  return (
    <TPaper>
      <Box>
        <Box className="flex-column-center text-center">
          <Box className="flex-basic-center" sx={{ my: 4 }}>
            <Box className="p-relative">
              <img src={errorImgBg} alt="Failed" />
            </Box>
          </Box>
        </Box>
        <Box className="text-center font-weight-semibold text-h3 textColor-200">
          <Box>{t('entityCreated')}</Box>
        </Box>
        <Box sx={{ p: theme.spacing(3), m: 2 }} className="statusBackground">
          <Box className="flex-basic-space-between">
            <Box className="font-weight-semibold text-medium textColor-200">
              {profileData?.is_premium ? `${t('taxIdFailedPremium')}` : `${t('taxIdVerificationFailed')}`}
            </Box>
            <span className="icon-error-warning error-color-state icon-size-24"></span>
          </Box>
        </Box>
        <Box sx={{ px: 8, pt: 4, pb: 22 }}>
          <TButton btnText="Continue" variant="contained" fullWidth onClick={handleContinue} />
        </Box>
      </Box>
    </TPaper>
  );
};

export default TaxIdFail;
