import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ID_PROCESS_STATUS_TEXT, PROCESS_IDS } from '../../../../constants/constants';
import {
  selectIdProcessDetails,
  setVisibilityLevel,
  getProcessUnmaskedId,
} from '../../../../redux/feature/idProcess/ssnVerificationSlice';
import { selectProfile, getProfileDetails } from '../../../../redux/feature/profile/profileSlice';
import { formatDate } from '../../../../utils/utils';
import CommonSubscriptionScreen from '../../../common/CommonSubscriptionScreen';
import { REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS, TButton, TCard, theme, TLoader } from '../../../myde-react-components';
import {
  getProcessDetailsById,
  selectSelectedIdProcessDetails,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { membershipFlip } from '../../../../api/profile'; // need to move to slice
import { useTheme } from '../../../../providers/custom-theme-provider';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  statusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  maskIdContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  showButton: {
    top: '-18px',
  },
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
});

export const SsnVerification = () => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation(['ssn', 'common']);
  const dispatch = useDispatch();
  const BTN_LABELS = {
    SHOW: 'Show',
    HIDE: 'Hide',
  };

  // State Values
  const [status, setStatus] = useState('');
  const [btnText, setBtnText] = useState('');
  const [profileName, setProfileName] = useState('');
  const [date, setDate] = useState('');

  // Redux Values
  const { visibilityLevel, unmaskedId, loading } = useSelector(selectIdProcessDetails);
  const { selectedIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);
  const { profileData } = useSelector(selectProfile);

  //useEffect
  useEffect(() => {
    if (
      selectedIdProcessDetails?.data &&
      Object.keys(selectedIdProcessDetails?.data)?.length > 0 &&
      selectedIdProcessDetails?.status
    ) {
      setStatus(selectedIdProcessDetails?.status_label);
      setDate(selectedIdProcessDetails?.data?.dob);
      setProfileName(selectedIdProcessDetails?.data?.profile?.profile_name);
    }
  }, [selectedIdProcessDetails]);
  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);
  useEffect(() => {
    dispatch(setVisibilityLevel(false));
  }, []);
  useEffect(() => {
    if (visibilityLevel) {
      setBtnText(BTN_LABELS.HIDE);
    } else {
      setBtnText(BTN_LABELS.SHOW);
    }
  }, [visibilityLevel]);

  // Methods
  const handleShowButton = () => {
    const data = {
      processId: PROCESS_IDS.SSN_VERIFICATION,
      params: !visibilityLevel
        ? {
            visibility_level: 'source',
          }
        : {},
    };
    dispatch(getProcessUnmaskedId(data));
    dispatch(setVisibilityLevel(!visibilityLevel));
  };
  const handleVerify = async () => {
    const result = await membershipFlip(); // move this to slice later
    if (result?.id) {
      dispatch(getProcessDetailsById({ processId: PROCESS_IDS.SSN_VERIFICATION }));
      dispatch(getProfileDetails());
    }
  };

  // HTML
  return (
    <TCard>
      <TLoader loading={loading} />
      <Box sx={{ p: 3 }}>
        <Box className="flex-column-center">
          <Box sx={{ mb: 3 }} className={clsx('icon-tax-ID', classes.icon, 'icon-size-72')} />
          <Box className="textColor-200 text-h2 font-weight-semibold">{t('instructionHeading', { ns: 'ssn' })}</Box>
          <Box sx={{ mt: 3 }} className="w-100">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} pb={2}>
                <Box sx={{ pb: theme.spacing(2) }} className="textColor-300 text-small font-weight-regular">
                  {t('profile', { ns: 'common' })}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">{profileName}</Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} pb={2}>
                <Box sx={{ pb: theme.spacing(2) }} className="textColor-300 text-small font-weight-regular">
                  {t('birthDateFormationDate', { ns: 'common' })}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">{formatDate(date)}</Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box className="textColor-300 text-small font-weight-regular">
                  {t('instructionHeading', { ns: 'ssn' })}
                </Box>
                <Box className="flex-basic-space-between">
                  <Box className="textColor-200 text-medium font-weight-semibold">
                    {btnText === BTN_LABELS.SHOW ? selectedIdProcessDetails?.data?.ssn : unmaskedId}
                  </Box>
                  <Box>
                    {visibilityLevel ? (
                      <VisibilityOutlinedIcon
                        fontSize="small"
                        onClick={handleShowButton}
                        className="textColor-200 cursorPointer"
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        fontSize="small"
                        onClick={handleShowButton}
                        className="textColor-200 cursorPointer"
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} pb={2}>
                <Box className={classes.statusContainer}>
                  {selectedIdProcessDetails?.status && (
                    <Box>
                      <Box className="textColor-300 text-small font-weight-regular">
                        {t('status', { ns: 'common' })}
                      </Box>
                      <Box sx={{ pt: 1 }} className="textColor-200 text-medium font-weight-semibold">
                        {status}
                      </Box>
                    </Box>
                  )}
                  {selectedIdProcessDetails?.status_label === ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS && (
                    <Box className="flex-basic-end">
                      <Box sx={{ pt: theme.spacing(5) }} className="p-relative small-sizes custom-progress-icon">
                        <Box className="progress-spinner sm-spinner"></Box>
                      </Box>
                    </Box>
                  )}
                  {status === ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED && (
                    <Box className="flex-basic-end">
                      <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                        <span className="icon-error-warning error-color-state icon-size-24"></span>
                      </Box>
                    </Box>
                  )}
                  {status === ID_PROCESS_STATUS_TEXT.VERIFIED && (
                    <Box className="flex-basic-end">
                      <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                        <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>

              {!profileData?.is_premium &&
                !REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS.includes(selectedIdProcessDetails?.status) && (
                  <Grid item xs={12} sm={12} md={12} sx={{ mt: theme.spacing(5) }}>
                    <CommonSubscriptionScreen
                      header={t('taxIdNotVerified', { ns: 'ssn' })}
                      subtitle={t('taxIdSubscriptionText1', { ns: 'ssn' })}
                      renderAction={
                        <TButton
                          btnText="Become a Premium Member"
                          btnWidthSize="button-w-240"
                          variant="contained"
                          onClick={handleVerify}
                        />
                      }
                    />
                  </Grid>
                )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </TCard>
  );
};
export default SsnVerification;
