import React, { useEffect } from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useQuery from '../../../hooks/useQuery';
import { makeStyles } from '@mui/styles';

import ContainerCard from '../../common/ContainerCard';
import { useDispatch } from 'react-redux';
import { getEntityIdProcesses } from '../../../redux/feature/entity/entitySlice';
import { EntityIdProcessList } from './EntityIdProcessList';
import { theme } from '../../myde-react-components';
import { useTheme } from '../../../providers/custom-theme-provider';
import { setSelectedCategory } from '../../../redux/feature/idProcess/customIdProcessSlice';
import { SupplementalIdProcessResults } from '../../../types/dashboardTypes';
import * as ROUTES from '../../../constants/routes';
import { useRouter } from '../../../providers/custom-router-provider';
import { clearIdProcessMultipleData } from '../../../redux/feature/idProcess/mainIdProcessSlice';

interface EntityIdProcessIndexProps {
  tid?: number;
}

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  btnColor: {
    background: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const EntityIdProcessIndex = ({ tid }: EntityIdProcessIndexProps) => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const query = useQuery();
  const entityId = query.get('id') || tid;
  const { routeTo } = useRouter();

  useEffect(() => {
    dispatch(clearIdProcessMultipleData());
  }, []);

  useEffect(() => {
    const id = Number(entityId) || 0;
    if (id) {
      dispatch(getEntityIdProcesses(id));
    }
  }, [entityId]);

  const navigateTo = () => {
    dispatch(setSelectedCategory({} as SupplementalIdProcessResults));
    const stateObj = { isEntity: true };
    routeTo(ROUTES.INDEX, true, 'Custom ID', stateObj);
  };

  const renderHeader = () => {
    return (
      <Box>
        <Box className="textColor-300 text-large font-weight-semibold text-center" sx={{ py: theme.spacing(5) }}>
          {t('entityIdProcessTitle')}
        </Box>
        <Divider />
        <Box sx={{ px: 3, py: theme.spacing(5) }} className="textColor-200 text-medium font-weight-regular">
          {t('entityIdProcessSubtitle')}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={query.get('id') ? { mb: 9 } : { mb: 0 }}>
      <ContainerCard content={renderHeader()} />
      <Box>
        <EntityIdProcessList />
        <Box className={clsx('floating-btn flex-basic-center cursorPointer', classes.btnColor)} onClick={navigateTo}>
          <span className="icon-add"></span>
        </Box>
      </Box>
    </Box>
  );
};
export default EntityIdProcessIndex;
