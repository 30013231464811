import React from 'react';
import { Box, Chip, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette } from '../../constants/colors';

import {
  KycAmlAliasType,
  KycAmlRecordDetails,
  KycAmlRecordType,
  KycHistoryRecordType,
  KycRerunAliasType,
  KycRerunDetailsType,
  RerunRecordType,
} from '../../types/KycTypes';
import { TButton, TCard } from '../../..';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import clsx from 'clsx';
import { formatDate } from '../../utils/commonMethods';
import { useTranslation } from 'react-i18next';
import { MAX_CHAR_LIMIT } from '../../constants/constants';
import { PORTAL } from '../../constants/portal';
import { theme } from '../../../index';
interface StyleProps {
  currentThemes: Theme;
}
interface KycAmlReportsProps {
  records?: KycAmlRecordType[];
  revertBack: () => void;
  recordSelect: (record: KycAmlRecordType, event: KycAmlRecordDetails) => void;
  historyRecords?: KycHistoryRecordType;
  historyDate?: string;
  selectNewRecord?: (record: RerunRecordType, event: KycRerunDetailsType) => void;
  selectOldRecord?: (record: RerunRecordType, event: KycAmlRecordDetails) => void;
  headerText?: string;
  portalType?: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  cardWrapper: {
    borderRadius: 10,
  },
  pageHeader: {
    borderBottom: `1px solid ${colorPalette.border.base}`,
    position: 'relative',
  },
  reportSection: {
    borderBottom: `1px solid ${colorPalette.border.base}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  sectionTitle: {
    borderTop: `1px solid ${colorPalette.border.base}`,
    borderBottom: `1px solid ${colorPalette.border.base}`,
  },
  aliasWrapper: {
    '& .MuiChip-root': {
      margin: 5,
      border: '1px solid',
      borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
    },
    '& .MuiChip-label': {
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
    },
  },
});

const KycAmlRecordsComponent = ({
  records,
  revertBack,
  recordSelect,
  historyRecords,
  historyDate,
  selectNewRecord,
  selectOldRecord,
  headerText,
  portalType = PORTAL.MYDE,
}: KycAmlReportsProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');

  const renderHistoryRecordsContent = (historyRecords: KycHistoryRecordType) => {
    return (
      <>
        <Box className={classes.pageHeader}>
          <Box className="p-absolute backArrow cursorPointer" onClick={revertBack}>
            <span className="icon-arrow-lefts textColor-300"></span>
          </Box>
          {historyDate && historyRecords?.records?.new_records?.length > 0 && (
            <Box
              sx={portalType === PORTAL.MERCHANT ? { pl: 0 } : { pl: 4, pb: theme.spacing(5) }}
              className={clsx('text-large font-weight-semibold textColor-300', {
                ['flex-basic-center']: portalType === PORTAL.MYDE,
                ['flex-basic-space-between']: portalType === PORTAL.MERCHANT,
              })}
            >
              {portalType === PORTAL.MERCHANT ? (
                <>
                  <Box
                    sx={{ pl: theme.spacing(8), pb: theme.spacing(5) }}
                    className="textColor-200 text-h3 font-weight-semibold"
                  >
                    {t('newRecord')}
                  </Box>
                  <Box sx={{ pb: theme.spacing(5) }} className="textColor-200 text-medium font-weight-semibold">
                    {`${t('asOn')} ${formatDate(historyDate)}`}
                  </Box>
                </>
              ) : (
                `${t('newRecordAsOn')} ${formatDate(historyDate)}`
              )}
            </Box>
          )}
        </Box>
        {historyRecords?.records?.new_records?.map((record: RerunRecordType, index: number) => (
          <Box key={index}>
            <Box className="text-h3 font-weight-semibold" sx={{ pt: 2 }}>
              {portalType === PORTAL.MERCHANT && record?.full_name}
            </Box>
            {record?.alias?.length > 0 && (
              <Box>
                <span className="font-weight-semibold text-medium textColor-200">{t('aliasText')} </span>
                {record?.alias.map((alias: KycRerunAliasType, idx: number) => (
                  <span className={clsx('customChips', classes.aliasWrapper)} key={idx}>
                    <Chip label={alias?.alias_name} />
                  </span>
                ))}
              </Box>
            )}
            {record?.details?.length > 0 &&
              record?.details?.map((data: KycRerunDetailsType, idx: number) => (
                <Box sx={{ pt: 0 }} className={clsx('textColor-200 text-small', classes.reportSection)} key={idx}>
                  <Box sx={{ py: 1 }}>
                    {t('categoryText')} {data?.event_category}
                  </Box>
                  {data?.event_sub_category && (
                    <div>
                      {t('subCategoryText')} {data?.event_sub_category}
                    </div>
                  )}
                  <p>
                    {data?.event_description?.substring(0, MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH)}
                    {data?.event_description?.length > MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH && <span>..</span>}
                  </p>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item className="font-weight-semibold text-medium textColor-200">
                      ({data?.comments?.length}) {t('commentsText')}
                    </Grid>
                    <Grid item>
                      <TButton
                        btnText="View"
                        className={'text-medium font-weight-semibold'}
                        onClick={() => {
                          selectNewRecord && selectNewRecord(record, data);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Box>
        ))}

        {historyDate && historyRecords?.records?.deleted_records?.length > 0 && (
          <Box
            sx={portalType === PORTAL.MERCHANT ? { px: 0 } : { px: 2, py: 2 }}
            className={clsx(`text-medium font-weight-semibold textColor-200 ${classes.sectionTitle}`, {
              ['flex-basic-center']: portalType === PORTAL.MYDE,
              ['flex-basic-space-between']: portalType === PORTAL.MERCHANT,
            })}
          >
            {portalType === PORTAL.MERCHANT ? (
              <>
                <Box sx={{ py: theme.spacing(5) }} className="textColor-200 text-h3 font-weight-semibold">
                  {t('erasedRecord')}
                </Box>
                <Box sx={{ py: theme.spacing(5) }} className="textColor-200 text-medium font-weight-semibold">{`${t(
                  'asOn',
                )} ${formatDate(historyDate)}`}</Box>
              </>
            ) : (
              `${t('erasedRecordAsOn')} ${formatDate(historyDate)}`
            )}
          </Box>
        )}
        {historyRecords?.records?.deleted_records?.map((record: RerunRecordType, index: number) => (
          <Box key={index}>
            <Box className="text-h3" sx={{ pt: 2 }}>
              {portalType === PORTAL.MERCHANT && record?.full_name}
            </Box>
            {record?.alias?.length > 0 && (
              <Box>
                <span className="font-weight-semibold text-medium textColor-200">{t('aliasText')} </span>
                {record?.alias.map((alias: KycRerunAliasType, idx: number) => (
                  <span className={clsx('customChips', classes.aliasWrapper)} key={idx}>
                    <Chip label={alias?.alias_name} />
                  </span>
                ))}
              </Box>
            )}
            {record?.details?.length > 0 &&
              record?.details?.map((data: KycRerunDetailsType, idx: number) => (
                <Box sx={{ pt: 0 }} className={clsx('textColor-200 text-small', classes.reportSection)} key={idx}>
                  <Box sx={{ py: 1 }}>
                    {' '}
                    {t('categoryText')}
                    {data?.event_category}
                  </Box>
                  {data?.event_sub_category && (
                    <div>
                      {t('subCategoryText')} {data?.event_sub_category}
                    </div>
                  )}
                  <p>
                    {data?.event_description?.substring(0, MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH)}
                    {data?.event_description?.length > MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH && <span>..</span>}
                  </p>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item className="font-weight-semibold text-medium textColor-200">
                      ({data?.comments?.length}) {t('commentsText')}
                    </Grid>
                    <Grid item>
                      <TButton
                        btnText="View"
                        className={'text-medium font-weight-semibold'}
                        onClick={() => {
                          selectOldRecord && selectOldRecord(record, data);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Box>
        ))}
      </>
    );
  };

  return (
    <>
      {records && (
        <TCard sx={{ p: 3, mb: theme.spacing(25) }} className={classes.cardWrapper}>
          <Box sx={{ pb: 3 }} className={classes.pageHeader}>
            <Box className="p-absolute backArrow cursorPointer" onClick={revertBack}>
              <span className="icon-arrow-lefts textColor-300"></span>
            </Box>
            <Box sx={{ pl: 4 }} className="text-large font-weight-semibold textColor-300">
              {headerText}
            </Box>
          </Box>
          {records?.map((record, index) => (
            <Box key={index}>
              <Box
                className="text-h3 font-weight-semibold textColor-200"
                sx={{ pt: theme.spacing(5), pb: theme.spacing(3) }}
              >
                {record?.full_name}
              </Box>
              {record?.alias?.length > 0 && (
                <Box>
                  <span className="font-weight-semibold text-medium textColor-200">{t('aliasText')} </span>
                  {record?.alias?.map((alias: KycAmlAliasType, idx: number) => (
                    <span className={clsx('customChips', classes.aliasWrapper)} key={idx}>
                      <Chip label={alias?.alias_name} />
                    </span>
                  ))}
                </Box>
              )}
              {record?.details?.length > 0 &&
                record?.details?.map((data, idx) => (
                  <Box
                    sx={{ pt: theme.spacing(5) }}
                    className={clsx('text-small textColor-200 font-weight-regular', classes.reportSection)}
                    key={idx}
                  >
                    <Box sx={{ pb: 1 }}>
                      {t('categoryText')} {data?.event_category}
                    </Box>
                    {data?.event_sub_category && (
                      <Box sx={{ pb: 1 }}>
                        {t('subCategoryText')} {data?.event_sub_category}
                      </Box>
                    )}
                    <Box sx={{ pb: theme.spacing(3) }}>
                      {data?.event_description?.substring(0, MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH)}
                      {data?.event_description?.length > MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH && <span>..</span>}
                    </Box>
                    <Grid
                      sx={{ pb: theme.spacing(5) }}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className="font-weight-semibold text-medium textColor-200">
                        ({data?.comments?.length}) {t('commentsText')}
                      </Grid>
                      <Grid item>
                        <TButton
                          btnText="View"
                          className={'text-medium font-weight-semibold'}
                          onClick={() => {
                            recordSelect(record, data);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}
            </Box>
          ))}
        </TCard>
      )}

      {historyRecords &&
        (portalType === PORTAL.MYDE ? (
          <TCard sx={{ p: 3, mb: theme.spacing(25) }} className={classes.cardWrapper}>
            {renderHistoryRecordsContent(historyRecords)}
          </TCard>
        ) : (
          <Box sx={{ p: 2 }}>{renderHistoryRecordsContent(historyRecords)}</Box>
        ))}
    </>
  );
};

export default KycAmlRecordsComponent;
