import React from 'react';
import SettingsLanding from '../../pages/Settings/SettingsLanding'

export const SettingsIndex = () => {
  return (
    <>
      <SettingsLanding />
    </>
  );
};

export default SettingsIndex;
