import React, { useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ReviewInvite from '../../../pages/EntityInvite/ReviewInvite';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';
import { TTextField } from '../../myde-react-components';

const RequestsIndex = () => {
  //Constants
  const { t } = useTranslation('common');

  //Redux values
  const { selectedEntity } = useSelector(selectEntity);

  ///State variables
  const [searchRequest, setSearchRequest] = useState('');

  //Methods
  const handleRequestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchRequest(event?.target?.value);
  };

  return (
    <Box>
      <Box sx={{ mt: 3, pb: 2 }}>
        <TTextField
          variant="outlined"
          placeholder="Search"
          value={searchRequest}
          onChange={handleRequestChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="icon-search textColor-400" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ pb: 3 }} className="text-medium font-weight-semibold textColor-200">
        {t('requests')}
      </Box>
      <Box>
        <ReviewInvite trellis_uid={selectedEntity?.trellis_uid} searchRequest={searchRequest} isRequestTab={true} />
      </Box>
    </Box>
  );
};

export default RequestsIndex;
