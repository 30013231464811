import React, { useState } from 'react';
import { Box, IconButton, List, ListItem } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useTheme } from '../../../../providers/custom-theme-provider';
import { colorPalette } from '../../../myde-react-components/src/constants/colors';
import { theme } from '../../../myde-react-components/src/Theme';
import {
  ENTITY_PROCESS_IDS,
  ID_PROCESS_STATUS_COMPLETION_STATE,
  PandaDocDialog,
  PROCESS_IDS,
  TButton,
  TLoader,
} from '../../../myde-react-components';
import { getEntityEsignUrl, getEsignUrl } from '../../../../api/idProcesses';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';
import {
  getEntityProcessDetailsById,
  getProcessDetailsById,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';

interface StyleProps {
  currentThemes: Theme;
}

interface SelectAccreditationProps {
  isEntity: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  listItem: {
    border: `2px solid ${colorPalette.stateDisabled}`,
    borderRadius: '10px',
    borderStyle: 'dashed',
  },
  listItemEnabled: {
    background: colorPalette.containerBack.lighten3,
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: '10px',
    '& .MuiButtonBase-root': {
      '& .MuiSvgIcon-root': {
        fontSize: '20px!important',
      },
    },
  },
  loader: {
    color: colorPalette.backgroundFadeColors.secondary,
    zIndex: '9999 !important',
  },
}));

function SelectAccreditation({ isEntity }: SelectAccreditationProps) {
  const { currentThemes } = useTheme();
  const { t } = useTranslation('common');
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();

  //state variables
  const [esign, setEsign] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // use selectors
  const { selectedEntity } = useSelector(selectEntity);

  //methods
  const getESignUrlData = async () => {
    try {
      const formData = {
        document_type: 'Accreditation',
      };
      setLoading(true);
      const url = isEntity
        ? await getEntityEsignUrl(ENTITY_PROCESS_IDS.ACCREDITATION, selectedEntity?.trellis_uid, formData)
        : await getEsignUrl(PROCESS_IDS.ACCREDITATION, formData);
      setEsign(url?.url);
      setLoading(false);
      setOpenDialog(true);
      setIsButtonDisabled(false);
    } catch (e) {
      setLoading(false);
      toast.error(t('somethingWentWrongText', { ns: 'notificationMessages' }));
    }
  };

  const onSigningComplete = () => {
    setOpenDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    if (isEntity) {
      dispatch(
        getEntityProcessDetailsById({
          processId: ENTITY_PROCESS_IDS.ACCREDITATION,
          entityUid: selectedEntity?.trellis_uid,
        }),
      );
    } else {
      dispatch(getProcessDetailsById({ processId: PROCESS_IDS.ACCREDITATION }));
    }
  };

  const handleNext = () => {
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
  };

  const renderPreferredDocuments = () => {
    return (
      <>
        <Box>
          <List>
            <ListItem
              sx={{ mb: theme.spacing(5), px: 2, py: 3 }}
              className={clsx('text-large textColor-200 font-weight-medium', classes.listItemEnabled)}
              secondaryAction={
                <>
                  <IconButton edge="end" aria-label="delete" onClick={getESignUrlData}>
                    <ArrowForwardIosIcon color={'action'} className="icon-size-20" />
                  </IconButton>
                </>
              }
            >
              <Box sx={{ pr: 2, pt: 1 }}>
                <Box>
                  <span className="icon-accreditation icon-size-32 textColor-300"></span>
                </Box>
              </Box>
              <Box className="text-large font-weight-medium textColor-200">Accreditation Form</Box>
            </ListItem>
          </List>
          <Box sx={{ mt: theme.spacing(8) }} className="flex-basic-center">
            <TButton
              btnText="Next"
              variant={'contained'}
              btnWidthSize={'button-w-240'}
              disabled={isButtonDisabled}
              onClick={handleNext}
            />
          </Box>
          <TLoader loading={loading} />
          <PandaDocDialog
            title={`Form Accreditation`}
            open={openDialog}
            onClose={handleDialogClose}
            onComplete={onSigningComplete}
            esign={esign}
          />
        </Box>
      </>
    );
  };
  return <>{renderPreferredDocuments()}</>;
}

export default SelectAccreditation;
