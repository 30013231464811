import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import UploadDoc from '../../common/UploadDoc';
import { FileType } from '../../../../../types/documentTypes';
import { ID_PROCESS_STEP_KEYS, PROCESS_IDS } from '../../../../myde-react-components';
import { DOCUMENT_TYPE, VERIFICATION_ACTIONS } from '../../../../../constants/constants';
import {
  clearIdProcessData,
  getIdProcessChildren,
  selectSelectedIdProcessDetails,
  setUploadedDocId,
  setUploadedFrontImage,
} from '../../../../../redux/feature/idProcess/mainIdProcessSlice';
import {
  createDocumentVerification,
  selectIdeProcessDetails,
} from '../../../../../redux/feature/idProcess/documentVerificationSlice';
import { setCurrentStep, setPreviousStep } from '../../../../../redux/feature/common/commonSlice';
import passportImage from '../../../../../common/assets/passport.svg';
import licenseFrontImage from '../../../../../common/assets/front-license.svg';

const UploadFrontImage = () => {
  //constants
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'idDocumentFlow']);

  // states
  const [preview, setPreview] = useState([] as FileType[]);
  const [cardHeading, setCardHeading] = useState('');
  const [instructionImageUrl, setInstructionImageUrl] = useState('');
  const [uploadInstruction, setUploadInstruction] = useState('');
  const [imageCaption, setImageCaption] = useState('');

  // selectors
  const {
    uploadedImage,
    selectedIdProcessDetails,
    uploadedDocId,
    processId,
    uploadedImageToPreview,
    uploadedFrontImage,
  } = useSelector(selectSelectedIdProcessDetails);
  const { selectedAddress } = useSelector(selectIdeProcessDetails);

  //useEffects
  useEffect(() => {
    dispatch(setPreviousStep(ID_PROCESS_STEP_KEYS.STEP2));
  }, []);

  useEffect(() => {
    if (Object.keys(uploadedImage)?.length > 0 && Object.keys(uploadedImageToPreview)?.length > 0) {
      const previewFile = {
        ...uploadedImage,
        signed_url: uploadedImageToPreview?.preview,
      };
      dispatch(setUploadedFrontImage(previewFile));
      dispatch(clearIdProcessData());
    }
  }, [uploadedImage, uploadedImageToPreview]);

  useEffect(() => {
    if (selectedIdProcessDetails?.data?.source_document?.front) {
      setPreview([selectedIdProcessDetails?.data?.source_document?.front]);
      dispatch(setUploadedDocId(selectedIdProcessDetails?.data?.source_document?.front?.doc_id));
    }
  }, [selectedIdProcessDetails]);

  useEffect(() => {
    if (Object.keys(uploadedFrontImage)?.length > 0) {
      setPreview([uploadedFrontImage]);
      dispatch(setUploadedDocId(uploadedFrontImage?.doc_id));
    }
  }, [uploadedFrontImage]);

  useEffect(() => {
    if (processId === PROCESS_IDS.DRIVERS_LICENSE) {
      setCardHeading(t('idDocStep2License', { ns: 'idDocumentFlow' }));
      setInstructionImageUrl(licenseFrontImage);
      setImageCaption(t('frontImage'));
      setUploadInstruction(t('uploadLicenseImageInstruction', { ns: 'idDocumentFlow' }));
    } else if (processId === PROCESS_IDS.PASSPORT) {
      setCardHeading(t('idDocStep2', { ns: 'idDocumentFlow' }));
      setInstructionImageUrl(passportImage);
      setUploadInstruction(t('uploadImageInstruction', { ns: 'idDocumentFlow' }));
    }
  }, [processId]);

  //methods
  const handlePassportSubmit = async () => {
    const payload: any = {
      process_id: PROCESS_IDS.PASSPORT,
      process_data: {
        document_type: DOCUMENT_TYPE.PASSPORT,
        front: uploadedDocId,
        back: null,
        document_country: selectedAddress?.address?.country,
        profile_id: selectedAddress?.id,
      },
      action: VERIFICATION_ACTIONS.SUBMIT,
    };
    await dispatch(createDocumentVerification(payload));
    dispatch(getIdProcessChildren({ processId: PROCESS_IDS.DOCUMENT_VERIFICATION }));
    dispatch(setPreviousStep(ID_PROCESS_STEP_KEYS.STEP2));
  };

  const handleLicenseNext = async () => {
    dispatch(setCurrentStep(ID_PROCESS_STEP_KEYS.STEP4));
    dispatch(setPreviousStep(ID_PROCESS_STEP_KEYS.STEP3));
  };

  const handleNext = async () => {
    if (processId === PROCESS_IDS.PASSPORT) {
      handlePassportSubmit();
    } else if (processId === PROCESS_IDS.DRIVERS_LICENSE) {
      handleLicenseNext();
    }
  };

  const getCaptureInstructions = () => {
    return {
      instructionTitle: t('captureInstructionHeading', { ns: 'idDocumentFlow' }),
      photoCaptureInstrucationSection: true,
      captureDescriptionNote: t('imagePositionInstruction', { ns: 'idDocumentFlow' }),
      imagesUrl: {
        avatarImageUrl: instructionImageUrl,
      },
      tipsList: [t('tip1'), t('tip2'), t('tip3'), t('tip4')],
    };
  };

  const getUploadInstructions = () => {
    return {
      instructionTitle: t('uploadInstructionHeading', { ns: 'idDocumentFlow' }),
      photoCaptureInstrucationSection: true,
      captureDescriptionNote: uploadInstruction,
      imagesUrl: {
        uploadedImagesUrl: instructionImageUrl,
      },
      imageCaption: imageCaption,
    };
  };

  return (
    <Box>
      <UploadDoc
        title={cardHeading}
        showTitleText={t('uploadTitleText', {
          type: `Upload ${isMobile ? '/ Capture' : ''}`,
          option: `${processId === PROCESS_IDS.PASSPORT ? '' : 'Front'}`,
        })}
        isEntity={false}
        handleNext={handleNext}
        isMultiple={false}
        previewFiles={preview}
        buttonText={processId === PROCESS_IDS.DRIVERS_LICENSE ? 'Next' : 'Submit'}
        captureInstruction={getCaptureInstructions()}
        uploadInstruction={getUploadInstructions()}
      />
    </Box>
  );
};

export default UploadFrontImage;
