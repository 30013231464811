import React, { useEffect, useRef } from 'react';
import { colorPalette, theme } from '../myde-react-components';
import { Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';

interface ContainerCardProps {
  content?: React.ReactNode;
  children?: React.ReactNode;
  showBottomActions?: boolean;
  hasBottomShadow?: boolean;
}

const useStyles = makeStyles({
  cardContainer: {
    border: `1px solid ${colorPalette.containerBack.lighten2}`,
    borderRadius: '10px !important',
    boxShadow: 'none !important',
  },
  bottomShadow: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px !important',
  },
});

const ContainerCard = ({
  children,
  showBottomActions = true,
  hasBottomShadow = false,
  ...props
}: ContainerCardProps) => {
  const classes = useStyles();

  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    executeScroll();
  }, [containerRef]);

  const executeScroll = () => {
    if (containerRef.current) {
      return containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Paper className={classes.cardContainer} ref={containerRef}>
      <Box>
        <Box className="content">{props.content}</Box>
      </Box>
      {children && (
        <Box>
          {showBottomActions && (
            <Box className={clsx({ [classes.bottomShadow]: hasBottomShadow })}>
              {!hasBottomShadow && <Divider className="bottomBorderHide" />}
              <Box className="bottomButtonControls" sx={{ p: 2 }}>
                {children}
              </Box>
            </Box>
          )}
          {!showBottomActions && <Box sx={{ pt: theme.spacing(3), pb: 4, px: 7 }}>{children}</Box>}
        </Box>
      )}
    </Paper>
  );
};

export default ContainerCard;
