import { apiPost } from './axios';

export const sendLinkForForgotPassword = async (formData: { email: string }) => {
  const res = await apiPost('/forget-password', formData);
  return res.data;
};

export const resetPassword = async (formData: { password: string }) => {
  const res = await apiPost('/reset-password', formData, {
    custom: {
      excludeTokenIdFromHeader: true,
      APITokenIdFromHeader: false,
      resetPasswordTokenIdFromHeader: true,
    },
  });
  return res.data;
};
