import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import Webcam from 'react-webcam';
import { toast } from 'react-toastify';

import { Button } from '../../components/Button/Button';
import { makeStyles } from '@mui/styles';
import { FilePreviewType } from '../../types/commonTypes';

interface TakePhotoProps {
  setImages: (images: FilePreviewType[]) => void;
  setMultipleData?: (file: FilePreviewType[]) => void;
}

const useStyles = makeStyles({
  camWrapper: {
    borderRadius: 6,
    width: '100%',
  },
});

export const MultipleCapture = ({ setImages, setMultipleData }: TakePhotoProps) => {
  // Constants
  const videoConstraints = {
    facingMode: 'user',
  };
  const classes = useStyles();
  const webcamRef = useRef(null);

  // State Variables
  const [files, setFiles] = useState([] as FilePreviewType[]);
  const [facingMode, setFacingMode] = useState('user');

  // Use Effects
  useEffect(() => {
    if (files?.length > 0) {
      setImages(files);
    }
  }, [files]);

  useEffect(() => {
    if (files?.length > 0 && setMultipleData) {
      setMultipleData(files);
    }
    if (files?.length === 10) {
      checkMaxFiles();
    }
  }, [files]);

  // Call Back Method for React WebCam
  const capture = useCallback(() => {
    const imageSrc: any = webcamRef?.current;
    const src = imageSrc?.getScreenshot();
    fetch(src)
      .then((res) => res.blob())
      .then((blob) => {
        const newFile = new File([blob], `${1 + Math.random() * (1000 - 1)}.jpeg`, { type: 'image/jpeg' });
        const fileData = Object.assign(newFile, { preview: URL.createObjectURL(newFile) });
        const payload = {
          file: newFile,
          name: newFile?.name,
          size: newFile?.size,
          type: newFile?.type,
          preview: fileData?.preview,
        };
        setFiles((previousFiles: FilePreviewType[]) => {
          if (previousFiles?.length > 0) {
            if (previousFiles?.length === 10) {
              return [...previousFiles];
            } else {
              return [...previousFiles, payload];
            }
          } else {
            return [payload];
          }
        });
      });
  }, [webcamRef, setFiles]);

  // Methods
  const toggleCamera = (e: any) => {
    e.preventDefault();
    handleToggleCamera();
  };

  const checkMaxFiles = () => {
    return toast.error('Maximum 10 photos can be captured');
  };

  const handleToggleCamera = useCallback(() => {
    setFacingMode((prevState: string) => (prevState === 'user' ? 'environment' : 'user'));
  }, []);

  const handleButtonDisable = () => {
    let result = false;
    if (files?.length >= 10) {
      result = true;
    }
    return result;
  };

  return (
    <Box>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        className={classes.camWrapper}
        videoConstraints={{ ...videoConstraints, facingMode }}
      />
      <Box className="flex-basic-end">
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            capture();
          }}
          sx={{ mr: 12 }}
          disabled={handleButtonDisable()}
          icon={<Box className={'icon-camera'}></Box>}
        />
        <Box className="flex-basic-end">
          <Button
            variant="text"
            size="small"
            onClick={toggleCamera}
            sx={{ mr: 1 }}
            icon={<Box className={'icon-camera-swap'}></Box>}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MultipleCapture;
