import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';

import { ResetPasswordFormSchema, ResetPasswordFormSchemaPayload } from './validation';
import {
  getLoginDetails,
  selectUser,
  verifyResetPasswordToken,
} from '../../redux/feature/user/userSlice';
import AuthLayout from '../../layout/AuthLayout';
import { TButton, theme, TLoader, TPaper, TPasswordStrengthMeter, TTextField } from '../../components/myde-react-components';
import { passwordValidations } from '../../constants/constants';
import { setResetPasswordToken } from '../../utils/storage';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import ResetPasswordTokenExpired from './ResetPasswordTokenExpired';
import { useTheme } from '../../providers/custom-theme-provider';
import { LoginFormSchemaPayload } from '../Login/validation';
import { resetPasswordHandler, selectUserPasswordData } from '../../redux/feature/profile/passwordSlice';

export const ResetPassword = () => {
  // Constants
  const { t } = useTranslation(['common', 'forgotPassword']);
  const dispatch = useDispatch();
  const { currentThemes } = useTheme();
  const { routeTo } = useRouter();
  const [searchParams] = useSearchParams();
  const { handleSubmit, control, formState, getValues, setValue, setError } = useForm<ResetPasswordFormSchemaPayload>({
    resolver: zodResolver(ResetPasswordFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;
  const token = searchParams.get('token');

  // Redux Values
  const { resetTokenExpired, resetTokenAlreadyUsed, email } = useSelector(selectUser);
  const { isPasswordUpdated, loading, newPasswordValidationMsg } = useSelector(selectUserPasswordData);

  // State Values
  const [userEmail, setUserEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');

  // Use Effects
  useEffect(() => {
    if (email) {
      setUserEmail(email);
    }
  }, [email]);
  useEffect(() => {
    setResetPasswordToken(token || '');
    token && dispatch(verifyResetPasswordToken(token));
  }, [token]);
  useEffect(() => {
    if (isPasswordUpdated) {
      const resetPasswordData = getValues();
      const formData: LoginFormSchemaPayload = {
        email: userEmail,
        password: resetPasswordData?.password,
      };
      dispatch(getLoginDetails(formData));
      routeTo(ROUTES.LOGIN);
    }
  }, [isPasswordUpdated]);
  useEffect(() => {
    setNewPasswordError(newPasswordValidationMsg);
  }, [newPasswordValidationMsg]);

  // Methods
  const inputChange = (e: any, isError: boolean) => {
    setNewPassword(e?.target?.value);
    setIsPasswordError(isError);
    if (getValues('confirm_password')) {
      setValue('confirm_password', getValues('confirm_password'), { shouldValidate: true });
    }
  };
  const onSubmit = async () => {
    try {
      const resetPasswordData = getValues();
      const requestPayload = {
        password: resetPasswordData.password,
      };
      dispatch(resetPasswordHandler(requestPayload));
    } catch (err: any) {
      setError('password', { type: 'manual', message: err.detail });
    }
  };
  const backHandler = () => {
    routeTo(ROUTES.LOGIN);
  };

  // HTML
  return (
    <>
      <TLoader loading={loading} />
      {resetTokenExpired || resetTokenAlreadyUsed ? (
        <ResetPasswordTokenExpired />
      ) : (
        <AuthLayout>
          <TPaper sx={{ p: 3 }}>
            <Box className="flex-column-center text-center">
              <Box
                className="text-h2 font-weight-semibold textColor-100"
                sx={{ mt: theme.spacing(9), mb: theme.spacing(3) }}
              >
                {t('resetPassword')}
              </Box>
              <Box sx={{ mx: 3 }}>
                <span className="text-medium font-weight-regular textColor-200">{t('resetPasswordInfo')}</span>
              </Box>

              <form className="w-100">
                <Box sx={{ my: theme.spacing(15) }}>
                  <Box>
                    <Controller
                      name="password"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TTextField
                          label="New Password"
                          variant="outlined"
                          type="password"
                          fullWidth
                          onKeyUp={(event) => {inputChange(event, !!errors.password)}}
                          error={!!errors.password || !!newPasswordError}
                          helperText={errors.password?.message || newPasswordError}
                          {...field}
                        />
                      )}
                    />
                    <TPasswordStrengthMeter password={newPassword} isError={isPasswordError} />
                  </Box>
                  <Box>
                    <Controller
                      name="confirm_password"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TTextField
                          sx={{ mt: theme.spacing(5) }}
                          label="Confirm Password"
                          variant="outlined"
                          type="password"
                          fullWidth
                          error={!!errors.confirm_password}
                          helperText={errors.confirm_password?.message}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  {!isValid && (
                    <Box sx={{ textAlign: 'left' }}>
                      <Box
                        sx={{ pt: theme.spacing(5), color: currentThemes.palette.primary.main }}
                        className={'text-medium font-weight-medium'}
                      >
                        {t('newPasswordContain', { ns: 'forgotPassword' })}
                      </Box>
                      {passwordValidations.map((validation, index) => (
                        <Box sx={{ pt: theme.spacing(1) }} key={index} className={'text-small passContentList'}>
                          {validation.message}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
                <Box className="flex-basic-center">
                  <TButton
                    variant={'contained'}
                    type="submit"
                    btnWidthSize="button-w-240"
                    disabled={!isValid || isSubmitting}
                    color="primary"
                    btnText={'Update Password'}
                    onClick={handleSubmit(onSubmit)}
                  ></TButton>
                </Box>
              </form>
              <Box sx={{ mt: 3 }}>
                <TButton variant="text" btnText={'Back'} onClick={backHandler}></TButton>
              </Box>
            </Box>
          </TPaper>
        </AuthLayout>
      )}
    </>
  );
};

export default ResetPassword;
