import React, { useState, useEffect } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from '../../../../../../src/auth/auth-guard';
import { BtnType, DIALOG_WIDTH, IDLE_LOGOUT } from '../../constants/constants';
import { setLoginFlag, selectUser } from '../../../../../redux/feature/user/userSlice';
import TDialog from '../Dialog/Dialog';
import { PORTAL } from '../../constants/portal';

interface StyleProps {
  matches?: boolean;
}
interface idleLogoutProps {
  portalName?: string;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  dialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        width: ({ matches }) => (matches ? DIALOG_WIDTH.MERCHANT_PORTAL_SMALL : DIALOG_WIDTH.USER_PORTAL),
        maxWidth: ({ matches }) => (matches ? DIALOG_WIDTH.MERCHANT_PORTAL_SMALL : DIALOG_WIDTH.USER_PORTAL),
        '& .MuiBox-root': {
          '& .MuiTypography-root': {
            padding: ({ matches }) => (matches ? '' : '20px 0px 12px 20px'),
            '& .text-h2': {
              font: ({ matches }) =>
                matches
                  ? 'normal 600 24px/36px Basier Circle !important'
                  : 'normal 600 18px/28px Basier Circle !important',
            },
            '& .MuiButtonBase-root': {
              padding: '0 !important',
            },
          },
        },
        '& .MuiDialogContent-root': {
          padding: ({ matches }) => (matches ? '' : '0px 20px !important'),
        },
      },
    },
  },
}));

const IdleLogout = ({ portalName = PORTAL.MYDE }: idleLogoutProps) => {
  const matches = portalName === PORTAL.MERCHANT;
  const styleProps: StyleProps = {
    matches,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { logout } = useAuth();

  //constants
  const logoutTime = IDLE_LOGOUT.LOGOUT_TIME;
  const warningTime = IDLE_LOGOUT.WARNING_TIME;
  const [open, setOpen] = useState(false);
  const { loginSuccess } = useSelector(selectUser);
  const [messageText, setMessageText] = useState('10');

  let warnTimeout: NodeJS.Timeout;
  let logoutTimeout: NodeJS.Timeout;

  const dialogButtons: BtnType[] = [
    {
      id: 1,
      title: t('logout'),
      variant: 'outlined',
      buttonAction: () => logoutUser(),
      disabled: false,
    },
    {
      id: 1,
      title: t('staySignedIn'),
      variant: 'contained',
      buttonAction: () => staySignedIn(),
      disabled: false,
    },
  ];

  //useEffect
  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (const event in events) {
      window.addEventListener(events[event], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (const event in events) {
        window.removeEventListener(events[event], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  useEffect(() => {
    if (open) {
      setMessageText('10');
      let timeLeft = 9;
      const logoutTime = setInterval(() => {
        if (timeLeft <= 0) {
          clearInterval(logoutTime);
          setMessageText('0');
          logoutUser();
        } else {
          setMessageText(`${timeLeft}`);
        }
        timeLeft--;
      }, 1000);
    }
  }, [open]);

  //methods
  const logoutUser = () => {
    dispatch(setLoginFlag(false));
    logout();
    setOpen(false);
  };

  const staySignedIn = () => {
    setMessageText('10');
    setOpen(false);
    clearTimeouts();
  };

  const warn = () => {
    setOpen(true);
  };

  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime);
    logoutTimeout = setTimeout(logoutUser, logoutTime);
  };

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  //JSX
  return (
    <>
      {loginSuccess && (
        <Box>
          <TDialog
            portalName={PORTAL.MERCHANT}
            className={classes.dialogStyle}
            title={t('warningText')}
            open={open}
            buttons={dialogButtons}
          >
            {t('idleTimeOut', { seconds: messageText })}
          </TDialog>
        </Box>
      )}
    </>
  );
};

export default IdleLogout;
