import React, { useEffect, useState } from 'react';
import { Divider, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IdProcessDetails } from '../../../../../types/dashboardTypes';
import CardList from './CardList';
import { ID_PROCESS_STATUS_COMPLETION_STATE, ID_PROCESS_STEP_KEYS, TCard } from '../../../../myde-react-components';
import {
  setCurrentStep,
  setIsEditAllowedFlag,
  setPreviousStep,
} from '../.././../../../redux/feature/common/commonSlice';
import {
  clearIdProcessData,
  clearSelectedIdProcessData,
  selectSelectedIdProcessDetails,
  setProcessId,
} from '../../../../../redux/feature/idProcess/mainIdProcessSlice';

export const IDList = () => {
  // states
  const [idProcesses, setIdProcesses] = useState([] as IdProcessDetails[]);
  // hooks
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();

  // Redux Values
  const { selectedIdProcessChildrenList } = useSelector(selectSelectedIdProcessDetails);

  //useEffects
  useEffect(() => {
    dispatch(setIsEditAllowedFlag(false));
    dispatch(clearSelectedIdProcessData());
    dispatch(clearIdProcessData());
  }, []);

  useEffect(() => {
    if (selectedIdProcessChildrenList?.length > 0) {
      setIdProcesses(selectedIdProcessChildrenList);
    }
  }, [selectedIdProcessChildrenList]);

  //methods
  const handleClick = async (idProcessDetails: IdProcessDetails) => {
    dispatch(setProcessId(idProcessDetails?.process_id));
    if (idProcessDetails?.status_label) {
      dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    } else {
      dispatch(setCurrentStep(ID_PROCESS_STEP_KEYS.STEP2));
    }
    dispatch(setPreviousStep(ID_PROCESS_STEP_KEYS.STEP1));
  };

  return (
    <>
      <TCard>
        <Box className="text-center textColor-200 text-h3 font-weight-semibold" sx={{ px: 6, py: 3 }}>
          {t('preferredDocumentType')}
        </Box>
        <Divider />
      </TCard>
      <CardList data={idProcesses} handleClick={handleClick} />
    </>
  );
};
export default IDList;
