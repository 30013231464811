import React from 'react';
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { KYC_AML_RESULTS } from '../../../../../constants/constants';
import { selectIdeProcessDetails } from '../../../../../redux/feature/idProcess/documentVerificationSlice';
import { fontStyle } from '../../../../../styles/style';
import { removeUnderscore, titleCase } from '../../../../../utils/utils';
import { colorPalette, theme } from '../../../../myde-react-components';

const useStyles = makeStyles({
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    fontSize: fontStyle.fontSize.font14.fontSize,
  },
  detailErrorIcon: {
    ...fontStyle.fontSize.font28,
    color: colorPalette.iconColor.light,
  },
  detailSuccessIcon: {
    color: colorPalette.iconColor.light,
  },
  successIcon: {
    fontSize: `${fontStyle.fontSize.font28.fontSize} !important`,
  },
});

export const DetailedReport = () => {
  const classes = useStyles();
  const { detailsList } = useSelector(selectIdeProcessDetails);
  const { t } = useTranslation('common');

  //methods
  const getIcon = () => {
    if (detailsList?.result === KYC_AML_RESULTS.CONSIDER) {
      return (
        <Box className={classes.detailErrorIcon}>
          <span className="icon-error-warning error-color-state actionIconFont-24"></span>
        </Box>
      );
    } else {
      return (
        <Box className={classes.detailSuccessIcon}>
          <span className="icon-approved success-color-state actionIconFont-24"></span>
        </Box>
      );
    }
  };

  return (
    <>
      <Box sx={{ py: theme.spacing(5) }}>
        <Box sx={{ px: 3 }} className="flex-basic-space-between">
          <Box>
            <Box className="textColor-200 text-h3 font-weight-semibold"> ‘{detailsList?.name}’ Details</Box>
          </Box>
          {getIcon()}
        </Box>
        <Divider sx={{ pt: theme.spacing(5) }} />
        <Box>
          {detailsList?.details?.length > 0 ? (
            <Box>
              {detailsList?.details?.map((item: any, index: number) => (
                <Box key={item.id}>
                  {(!item?.result || item?.result === detailsList?.result) && item?.name && item?.description && (
                    <>
                      <Box sx={{ pt: theme.spacing(5), px: 3 }}>
                        <Box className="textColor-200 text-large font-weight-semibold">
                          {removeUnderscore(item?.name)}
                        </Box>
                        <Box className="textColor-200 text-medium font-weight-regular" sx={{ mt: 1 }}>
                          {item?.description || '-'}
                        </Box>
                      </Box>
                      {index < detailsList?.details?.length - 1 && <Divider sx={{ pt: theme.spacing(5) }} />}
                    </>
                  )}
                </Box>
              ))}
            </Box>
          ) : (
            <Box sx={{ py: 10 }} className="text-center textColor-200 text-medium font-weight-regular">
              {t('noDetailsAvailable') + '\t' + titleCase(detailsList?.name)}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
export default DetailedReport;
