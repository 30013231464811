import React from 'react';
import DocumentIdProcessVerification from '../common/DocumentIdProcessVerification';
interface DocumentFormationVerificationPorps {
  isEntity?: boolean;
}
const DocumentFormationVerification = ({ isEntity }: DocumentFormationVerificationPorps) => {
  return (
    <div>
      <DocumentIdProcessVerification isEntity={isEntity} />
    </div>
  );
};

export default DocumentFormationVerification;
