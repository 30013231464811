import * as React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TPaper, colorPalette, theme } from '../myde-react-components';

interface CommonSubscriptionScreenProps {
  header?: string;
  subtitle?: string;
  subtitle2?: string;
  renderAction?: React.ReactNode;
}

const useStyles = makeStyles({
  cardHeader: {
    display: 'flex',
  },
  cardContainer: {
    border: `1px solid ${colorPalette.containerBack.lighten2}`,
    backgroundColor: `${colorPalette.containerBack.base} !important`,
    borderRadius: '10px !important',
    borderBottom: 'none !important',
    boxShadow: 'none !important',
  },
});

const CommonSubscriptionScreen = ({ header, subtitle, subtitle2, renderAction }: CommonSubscriptionScreenProps) => {
  const classes = useStyles();

  return (
    <TPaper sx={{ px: 3, py: theme.spacing(5) }} elevation={0} className={classes.cardContainer}>
      <Box sx={{ pb: theme.spacing(3) }} className="text-center textColor-200 text-large font-weight-bold">
        {header}
      </Box>
      <Box sx={{ pb: theme.spacing(3) }} className="text-center textColor-300 text-small font-weight-regular">
        {subtitle}
      </Box>
      {subtitle2 && (
        <Box sx={{ pb: theme.spacing(3) }} className="text-center textColor-300 text-small font-weight-semibold">
          {subtitle2}
        </Box>
      )}
      <Box className="text-center">{renderAction}</Box>
    </TPaper>
  );
};

export default CommonSubscriptionScreen;
