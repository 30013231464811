import { apiGet, apiPost } from './axios';

export const getSubscriptions = async () => {
  const res = await apiGet('/subscriptions');
  return res.data;
};

export const getSubscriptionsWithFilter = async (filter: object) => {
  const res = await apiGet(`/subscriptions`, { params: filter });
  return res.data;
};

export const getInvitations = async (filters: object) => {
  const res = await apiGet(`/invitation`, { params: filters });
  return res.data;
};

export const getTAppLogin = async (tAppId: string) => {
  const res = await apiPost(`t-apps/${tAppId}/login`);
  return res.data;
};
