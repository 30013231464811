import React, { useEffect } from 'react';
import { Grid, Box, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  getTAppDetails,
  getTAppDetailsWithFilter,
  getTAppList,
  resetSearchedField,
  setSearchedField,
  setSearchToggle,
  setView,
} from '../../redux/feature/myTApps/myTAppsSlice';
import { colorPalette, TCard, TTextField } from '../../components/myde-react-components';
import { getInvitesDetails } from '../../redux/feature/invite/inviteSlice';
import { TAPP_INVITE_STATUS } from '../../constants/constants';
import { fontStyle } from '../../styles/style';

const useStyles = makeStyles({
  search: {
    margin: 'auto',
    position: 'relative',
    width: '59px',
    height: '59px',
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      backgroundColor: colorPalette.iconColor.main,
      borderRadius: '50px',
      padding: '10px 18px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0px',
      },
    },
  },
  icons: {
    position: 'relative',
    width: '59px',
    height: '59px',
    backgroundColor: colorPalette.iconColor.main,
    borderRadius: '50%',
    textAlign: 'center',
    margin: 'auto',
    lineHeight: '50px',
    padding: 4,
  },
  bgColor: {
    backgroundColor: `${colorPalette.iconColor.main} !important`,
  },
  idType: {
    border: 'none !important',
    borderRadius: '50px !important',
    textAlign: 'center',
    cursor: 'pointer',
    width: '100%',
    padding: 5,
  },
  tabsBody: {
    backgroundColor: `${colorPalette.iconColor.main} !important`,
    borderRadius: 50,
    padding: 6,
    height: '59px',
  },
  innerTabs: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 90,
  },
  expanded: {
    width: '250px',
    transition: '.5s',
    borderRadius: '50px',
    backgroundColor: colorPalette.iconColor.main,
    height: '59px',
    border: 0,
    zIndex: 1,
    padding: 4,
  },
  collapesed: {
    position: 'relative',
    transition: '.5s',
    width: '59px',
    height: '59px',
    backgroundColor: colorPalette.iconColor.main,
    borderRadius: '50%',
    textAlign: 'center',
    margin: 'auto',
    lineHeight: '50px',
    border: 0,
    padding: 4,
  },
  pointer: { cursor: 'pointer' },
  iconStyle: {
    fontSize: fontStyle.fontSize.font20.fontSize,
  },
});

const TAppHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeItem, setactiveItem] = React.useState('1');
  const [expanded, setExpanded] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const { searchToggle, filterStatus } = useSelector(getTAppList);

  useEffect(() => {
    dispatch(getTAppDetails());
  }, []);

  useEffect(() => {
    if (!searchToggle && expanded) {
      setExpanded(false);
    }
  }, [searchToggle]);

  useEffect(() => {
    if (!expanded) {
      setSearchValue('');
      dispatch(resetSearchedField());
    }
  }, [expanded]);

  useEffect(() => {
    if (searchValue === '') {
      if (filterStatus === TAPP_INVITE_STATUS.TAPP_APPROVED) {
        dispatch(getTAppDetails());
      } else {
        const filters = {
          status: filterStatus,
        };
        dispatch(getInvitesDetails(filters));
      }
    } else {
      if (filterStatus === TAPP_INVITE_STATUS.TAPP_APPROVED) {
        const params = {
          t_app_name: searchValue,
        };
        dispatch(getTAppDetailsWithFilter(params));
      } else {
        const filters = {
          t_app_name: searchValue,
          status: filterStatus,
        };
        dispatch(getInvitesDetails(filters));
      }
    }
  }, [searchValue]);

  const handleClick = (item: string) => {
    setactiveItem(item);
    dispatch(setView(item));
  };

  const toggleExpand = (value: boolean) => {
    setExpanded(value);
    if (value) {
      dispatch(setSearchToggle(value));
    }
  };

  const updateSearchValue = (event: any) => {
    setSearchValue(event.target.value);
    dispatch(setSearchedField(event.target.value));
  };

  return (
    <Grid container sx={{ p: 2, textAlign: 'center' }}>
      <Grid item sm={12} md={12} lg={12} xs={12} sx={{ mb: 1, display: 'flex' }}>
        <Box className={classes.search}>
          <TTextField
            className={clsx({
              [classes.expanded]: expanded,
              [classes.collapesed]: !expanded,
            })}
            variant="outlined"
            value={searchValue}
            onChange={updateSearchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ mr: 0 }}
                  className={classes.pointer}
                  onClick={() => toggleExpand(true)}
                >
                  <Box className={clsx('icon-search', classes.iconStyle)}></Box>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {expanded && (
                    <InputAdornment position="end" className={classes.pointer} onClick={() => toggleExpand(false)}>
                      <Box className={clsx('icon-close', classes.iconStyle)}></Box>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        </Box>
        <Box className={classes.tabsBody}>
          <Box className={classes.innerTabs}>
            <TCard
              variant={'outlined'}
              onClick={() => handleClick('1')}
              className={clsx({
                [classes.idType]: true,
                [classes.bgColor]: activeItem === '2',
                ['flex-basic-center']: true,
              })}
            >
              <Box>
                <Box className={clsx('icon-grid-view', classes.iconStyle)}></Box>
              </Box>
            </TCard>
            <TCard
              variant={'outlined'}
              onClick={() => handleClick('2')}
              className={clsx({
                [classes.idType]: true,
                [classes.bgColor]: activeItem === '1',
              })}
            >
              <Box>
                <Box className={clsx('icon-list-view', classes.iconStyle)} sx={{ m: 1 }}></Box>
              </Box>
            </TCard>
          </Box>
        </Box>
        <Box className={classes.icons}>
          <Box className={clsx('icon-add', classes.iconStyle)} sx={{ py: 2 }}></Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TAppHeader;
