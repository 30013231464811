import * as React from 'react';
import { Card as MuiCard, CardProps as MuiCardProps } from '@mui/material';

interface CardProps extends MuiCardProps {
  children: any;
}

export const Card =  React.forwardRef<any, CardProps>(({children, ...props}: CardProps, ref:any) => {
  return (
    <MuiCard
      ref={ref}
      {...props}
    >
      {children}
    </MuiCard>
  )
});
export default Card;
