import * as React from 'react';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { colorPalette } from '../../constants/colors';
import { Button } from '../Button/Button';
import { useTheme } from '../../../../../providers/custom-theme-provider';

export interface PageHeaderProps {
  title?: any;
  children?: any;
  onBack: (param?: any) => void;
}
export interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    background: colorPalette.containerBack.lighten3,
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: '10px',
    padding: '30px',
  },
});

export const PageHeader = ({ children, title, onBack }: PageHeaderProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  return (
    <>
      <Box className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box className="flex-basic-start ">
              <Box sx={{ mr: 2 }}>
                <Button
                  variant="text"
                  icon={<ArrowBackIosNewIcon className="textColor-200" />}
                  size="small"
                  onClick={() => {
                    onBack();
                  }}
                />
              </Box>
              <Box className="text-h2 font-weight-semibold textColor-200">{title}</Box>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PageHeader;
