import React, { useEffect } from 'react';
import { Box, InputAdornment, Icon, Theme } from '@mui/material';
import { TButton, TTextField } from '../../..';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useTheme } from '../../../../../providers/custom-theme-provider';

export interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  textColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
}));
export interface SearchComponentProps {
  onSearchUpdate: (event: any) => void;
  extraFilterHandler?: () => void;
  resetFilters?: () => void;
  appliedFilterCount?: number | null;
  appliedFilters?: any;
  resetSearchFilter?: boolean;
  placeholder: string;
  hasExtraFilters?: boolean;
  hideResetButton?: boolean;
}

export const SearchComponent = ({
  onSearchUpdate,
  extraFilterHandler,
  resetFilters,
  appliedFilterCount,
  resetSearchFilter = false,
  hasExtraFilters = false,
  placeholder = 'Search',
  hideResetButton,
}: SearchComponentProps) => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);
  const [searchValue, setSearchValue] = React.useState('');

  const updateSearchValue = (event: any) => {
    setSearchValue(event.target.value);
    onSearchUpdate(event.target.value);
  };
  useEffect(() => {
    if (resetSearchFilter) {
      setSearchValue('');
    }
  }, [resetSearchFilter]);

  const resetFilter = () => {
    if (appliedFilterCount || searchValue) {
      resetFilters && resetFilters();
      setSearchValue('');
    }
  };

  const getFilterCount = () => {
    let filterCount = 0;
    if (appliedFilterCount) {
      filterCount += appliedFilterCount;
      if (searchValue?.length > 0) {
        filterCount += 1;
      }
    } else if (searchValue?.length > 0) {
      filterCount += 1;
    } else {
      return null;
    }
    return `(${filterCount}) Filters Applied`;
  };

  return (
    <>
      <Box className="textFieldSearchWithIcon">
        {!hideResetButton && (appliedFilterCount || searchValue) ? (
          <Box className="text-medium flex-basic-space-between" sx={{ mb: 1 }}>
            <Box className={classes.textColor}>{getFilterCount()}</Box>
            <Box className={clsx(classes.textColor, 'cursorPointer')} onClick={resetFilter}>
              Reset Filters
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <TTextField
          sx={{ width: '100%' }}
          variant="outlined"
          placeholder={placeholder}
          value={searchValue}
          onChange={updateSearchValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="icon-search textColor-400" />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {!hasExtraFilters && (
                  <InputAdornment position="end">
                    <TButton
                      variant="outlined"
                      onClick={extraFilterHandler}
                      icon={
                        <Icon fontSize="inherit">
                          <div className="icon-filter cursorPointer"></div>
                        </Icon>
                      }
                    />
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default SearchComponent;
