import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { TBadge, TButton, theme, TLoader, TTextField } from '../../myde-react-components';
import * as ROUTES from '../../../constants/routes';
import { EntityInvitionsType } from '../../../types/entityTypes';
import { removeUnderscore } from '../../../utils/utils';
import { useRouter } from '../../../providers/custom-router-provider';
import { getAllEntityMembers, selectEntity, setSelectedMemberDetails } from '../../../redux/feature/entity/entitySlice';
import { ENTITY_INVITE_STATUS, ROLES } from '../../../constants/constants';
interface MemberOwnersListProps {
  data: EntityInvitionsType[];
  tabName: string;
}
const MemberOwnersList = ({ data, tabName }: MemberOwnersListProps) => {
  //const
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { t } = useTranslation(['common', 'entityMembers']);

  //state variables
  const [memberData, setMemberData] = useState([] as EntityInvitionsType[]);
  //Redux
  const { selectedEntity, loading } = useSelector(selectEntity);

  //useEffects
  useEffect(() => {
    if (data?.length > 0) {
      const newData = data.filter((item) => !(item.status === 'DISMISSED'));
      setMemberData(newData);
    } else {
      setMemberData([]);
    }
  }, [data]);

  //methods
  const getMemberDetails = (item: EntityInvitionsType) => {
    dispatch(setSelectedMemberDetails(item));
    const query = {
      tab: tabName,
    };
    routeTo(ROUTES.ENTITY_MEMBERS_DETAILS, true, `${item?.first_name || ''} ${item?.last_name || ''}`, query);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value.length > 0) {
      const searchResult = searchItem(event?.target?.value);
      setMemberData(searchResult);
    } else {
      setMemberData(data);
    }
  };
  const searchItem = (searchValue: string) => {
    const searchTrim = searchValue.trim();
    const filterData = data?.filter(
      (item: EntityInvitionsType) =>
        (item?.first_name && item?.first_name.toLowerCase().includes(searchTrim.toLowerCase())) ||
        (item?.last_name && item?.last_name.toLowerCase().includes(searchTrim.toLowerCase())) ||
        (item?.first_name &&
          item?.last_name &&
          (item?.first_name + ' ' + item?.last_name).toLowerCase().includes(searchTrim.toLowerCase())),
    );
    return filterData;
  };

  const navigateTo = async () => {
    await dispatch(getAllEntityMembers(selectedEntity?.trellis_uid));
    const query = {
      isPostOnboarding: true,
    };
    routeTo(
      tabName == ROLES.OWNER ? ROUTES.ENTITY_ADD_OWNERS_USERS : ROUTES.ENTITY_ADD_MEMBER_USERS,
      true,
      tabName == ROLES.OWNER ? 'Add Owner(s)' : 'Add User(s)',
      query,
    );
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box>
        <TTextField
          variant="outlined"
          placeholder="Search"
          onChange={handleSearchChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="icon-search textColor-400" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TLoader loading={loading} />
      {memberData && memberData?.length > 0 ? (
        <>
          <Box sx={{ mb: 1, mt: 4 }} className="flex-basic-space-between">
            <Box className="text-medium textColor-200 font-weight-semibold">
              {t(tabName == ROLES.OWNER ? 'ownerTitle' : 'userTitle', { ns: 'entityMembers' })}
            </Box>
            <Box className="text-medium textColor-200 font-weight-semibold">
              <TButton btnText="+ Add" onClick={navigateTo} variant="text" />
            </Box>
          </Box>
          <Box className="boxCardListScroll">
            {memberData?.map((item: EntityInvitionsType, index: number) => (
              <Box
                key={index}
                sx={{ p: theme.spacing(3), mb: theme.spacing(4) }}
                className="boxCardMember cursorPointer"
                onClick={() => getMemberDetails(item)}
              >
                <Box className="flex-basic-start">
                  <Box className="text-small font-weight-medium textColor-200" sx={{ pr: theme.spacing(2) }}>
                    {`${item?.first_name || ''} ${item?.last_name || ''}`}
                  </Box>
                  {item?.status === ENTITY_INVITE_STATUS.PENDING && (
                    <TBadge bagdeFontStyle={'text-extra-small'} showBagdeThemeColor={true} label={'Pending'} />
                  )}
                </Box>
                <Box sx={{ mt: theme.spacing(3) }} className="d-flex text-extra-small">
                  {item?.permission && (
                    <Box sx={{ mr: theme.spacing(2) }} className="flex-basic-start">
                      <Box className="icon-lock icon-size-16 textColor-300" sx={{ mr: theme.spacing(1) }} />
                      <Box>{removeUnderscore(item?.permission?.toLowerCase())}</Box>
                    </Box>
                  )}
                  {item?.ownership_percentage > 0 && tabName == ROLES.OWNER && (
                    <Box sx={{ mr: theme.spacing(2) }} className="flex-basic-start">
                      <Box className="icon-stake icon-size-16 textColor-300" sx={{ mr: theme.spacing(1) }} />
                      <Box> {item?.ownership_percentage + '% Stake'}</Box>
                    </Box>
                  )}

                  {item?.is_beneficial_owner && tabName == ROLES.OWNER && (
                    <Box className="flex-basic-start">
                      <Box className="icon-profile-users icon-size-16 textColor-300" sx={{ mr: theme.spacing(1) }} />
                      <Box>{t('beneficialOwner')}</Box>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Box className={clsx('text-center')} sx={{ p: 4 }}>
            <Box
              className={clsx('icon-profile-users textColor-400 icon-size-60')}
              sx={{ mt: 4, mb: theme.spacing(5) }}
            />
            <Box className="textColor-300 text-h3 font-weight-semibold" sx={{ mb: 8, mt: theme.spacing(5) }}>
              {t(tabName == ROLES.OWNER ? 'noMembersOwnersText' : 'noMembersUsersText', { ns: 'entityMembers' })}
            </Box>
            <TButton btnText={'Add New'} variant={'contained'} btnWidthSize="button-w-240" onClick={navigateTo} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default MemberOwnersList;
