import React from 'react';
import { Rating, RatingProps as MuiRatingProps } from '@mui/material';
import { styled, makeStyles } from '@mui/styles';
import { colorPalette } from '../../constants/colors';
import FullStarIcon from './FullStarIcon';
import EmptyStarIcon from './EmptyStarIcon';

// Interfaces
interface CustomRatingProps {
  isStyled?: boolean;
}

export type CombinedRatingProps = CustomRatingProps & MuiRatingProps;

// Styles
const useStyles = makeStyles({
  ratingStyle: {
    '&.MuiRating-root': {
      '& .MuiRating-iconEmpty': {
        color: `${colorPalette.typoText.unSelected} !important`,
      },
      color: `${colorPalette.rating} !important`,
    },
  },
});

const CustomRating = styled(Rating)(() => ({
  '&.MuiRating-root': {
    '& .MuiRating-decimal': {
      marginRight: 8,
    },
  },
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: colorPalette.typoText.unSelected,
  },
}));

export const Ratings = ({ isStyled = true, ...props }: CombinedRatingProps) => {
  // Constants
  const classes = useStyles();

  // HTML
  return (
    <>
      {isStyled ? (
        <CustomRating
          {...props}
          icon={<FullStarIcon fontSize="inherit" />}
          emptyIcon={<EmptyStarIcon fontSize="inherit" />}
        />
      ) : (
        <Rating className={classes.ratingStyle} {...props} />
      )}
    </>
  );
};

export default Ratings;
