import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { colorPalette, TTooltip } from '../myde-react-components';
import { hexToRgbA, wrapText } from '../../utils/utils';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import clsx from 'clsx';
import { useTheme } from '../../providers/custom-theme-provider';

interface StyleProps {
  currentThemes: Theme;
}

interface DocumentListingProps {
  name: string;
  icon: string;
  count?: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
  showDescriptionIcon?: boolean;
  modifiedDateTime?: string;
  category?: string;
  year_month?: string;
  showUnreadIcon: boolean;
  unreadCount?: number;
  categoryIcon?: boolean;
  isRecentDoc?: boolean;
  createdOn?: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  iconStyle: {
    marginRight: '16px',
    height: '24px',
    width: '24px',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
  },
  unreadDocCount: {
    paddingLeft: '180px',
  },
  unreadIcon: {
    color: colorPalette.border.success,
    marginLeft: '10px',
    height: '8px!important',
    width: '8px!important',
  },
  boldUnreadCount: {
    borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
  },
});

export const DocumentListing = ({
  name,
  icon,
  onClick,
  showDescriptionIcon = false,
  showUnreadIcon,
  unreadCount,
  categoryIcon,
  isRecentDoc,
  createdOn,
}: DocumentListingProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);

  const getIcon = () => {
    if (icon === 'PictureAsPdfOutlinedIcon') {
      return <PictureAsPdfOutlinedIcon className={clsx(classes.iconStyle, 'textColor-300 actionIconFont-24')} />;
    } else if (icon === 'ImageOutlinedIcon') {
      return <Box className={clsx(classes.iconStyle, 'icon-image textColor-300 actionIconFont-24')} />;
    } else if (icon === 'FolderOpenOutlinedIcon') {
      return <Box className={clsx(classes.iconStyle, 'icon-calendar-svg textColor-300 actionIconFont-24')} />;
    } else if (categoryIcon) {
      return (
        <Box className="flex-basic-center">
          <img src={icon} className={clsx(classes.iconStyle)} alt="branding logo" />
        </Box>
      );
    }
  };

  return (
    <Grid className="docListItems" container direction="row">
      <Box className="cursorPointer w-100" onClick={onClick}>
        <Box sx={!isRecentDoc ? { p: 3 } : { p: 2 }} className="flex-basic-space-between listItems">
          <Box className="w-100">
            <Box className="flex-basic-space-between">
              <Box className="flex-basic-start">
                <Box>{getIcon()}</Box>
                <Box>
                  <TTooltip title={name} sx={{ my: 1 }} arrow>
                    <Box
                      sx={isRecentDoc ? { pl: 1 } : { pl: 0 }}
                      className="text-medium font-weight-semibold textColor-200"
                    >
                      {wrapText(name, 18)}
                    </Box>
                  </TTooltip>
                  {isRecentDoc && (
                    <Box sx={{ pl: 1, pt: 0.3 }} className="font-weight-regular text-small">
                      {createdOn}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                {showUnreadIcon && !!unreadCount && unreadCount > 0 ? (
                  <Box className={clsx('unreadCountBox', classes.boldUnreadCount)}>{`${unreadCount} new`}</Box>
                ) : (
                  !isRecentDoc &&
                  showUnreadIcon && <Box className={clsx('unreadCountBox', classes.boldUnreadCount)}>{`new`}</Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ ml: 1, pt: 1 }}>
            {!!showDescriptionIcon && <span className="icon-file textColor-300 actionIconFont-24" />}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default DocumentListing;
