import { RootState } from '../..';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEntityOwnerApplicationDetails } from '../../../api/invite';
import { BeneficialOwnerDocumentType } from '../../../types/invitationTypes';

const initialState = {
  loading: false,
  error: false,
  beneficialOwnersUserList: [] as BeneficialOwnerDocumentType[],
  areAllOwnerIdRequirementsFulfilled: false,
};

export const selectBeneficialOwnerDetails = ({ beneficialOwner }: RootState) => ({
  loading: beneficialOwner.loading,
  beneficialOwnersUserList: beneficialOwner.beneficialOwnersUserList,
  areAllOwnerIdRequirementsFulfilled: beneficialOwner.areAllOwnerIdRequirementsFulfilled,
});

export const getOwnerApplicationDetails = createAsyncThunk(
  'beneficialOwner/getOwnerApplicationDetails',
  async (applicationId: string) => {
    return await getEntityOwnerApplicationDetails(applicationId);
  },
);

export const beneficialOwnerDocumentSlice = createSlice({
  name: 'beneficialOwner',
  initialState,
  reducers: {
    setAreAllOwnerIdRequirementsFulfilled: (state, action) => {
      state.areAllOwnerIdRequirementsFulfilled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOwnerApplicationDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOwnerApplicationDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.beneficialOwnersUserList = action?.payload?.users;
        } else {
          state.error = true;
        }
      })
      .addCase(getOwnerApplicationDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setAreAllOwnerIdRequirementsFulfilled } = beneficialOwnerDocumentSlice.actions;

export default beneficialOwnerDocumentSlice.reducer;
