import React, { useEffect } from 'react';
import { Box, Icon, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { fontStyle } from '../../styles/style';
import { ACCREDITATION_BADGE_TEXT, colorPalette, theme } from '../myde-react-components';
import { setCardState } from '../../redux/feature/common/commonSlice';
import { useRouter } from '../../providers/custom-router-provider';
import { selectUser, setEntitySelectionFlag } from '../../redux/feature/user/userSlice';
import { getProfileDetails } from '../../redux/feature/profile/profileSlice';
import { useTheme } from '../../providers/custom-theme-provider';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  cardDataWrapper: {
    textAlign: 'center',
    position: 'relative',
    '& .toggleIcon': {
      top: 16,
      right: 10,
      position: 'absolute',
      cursor: 'pointer',
    },
  },
  textUserName: {
    ...fontStyle.fontSize.font36,
    ...fontStyle.fontWeight.font600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 250,
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
  textLabel: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font700,
  },
  listItem: {
    borderTop: `1px solid ${colorPalette.border.card}`,
    '&:last-child': {
      borderBottom: `1px solid ${colorPalette.border.card}`,
    },
  },
  accreditation_badge_color: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

interface IdCardDetailsProps {
  userName: string;
  tId: number;
  list: ListItems[];
  accreditationStatus?: string;
}

interface ListItems {
  path: string;
  icon: string;
  label: string;
}

const IdCardDetails = ({ userName, tId, list, accreditationStatus }: IdCardDetailsProps) => {
  const { currentThemes } = useTheme();
  const styleProp = {
    currentThemes,
  };

  const classes = useStyles(styleProp);
  const { routeTo } = useRouter();
  const dispatch = useDispatch();

  const { showEntitySelectionList } = useSelector(selectUser);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);

  const listItemClickHandler = (item: ListItems) => {
    if (!item?.path) return;
    dispatch(setCardState(false));
    routeTo(item.path);
  };

  const toggleEntityView = () => {
    dispatch(setEntitySelectionFlag(!showEntitySelectionList));
  };

  return (
    <Box className={classes.cardDataWrapper} sx={{ mt: 1 }}>
      <Box className="flex-basic-center p-relative">
        <Box className={'userName text-center text-h2 font-weight-semibold textColor-200'}>{`${userName}`}</Box>
        <Box className="p-absolute switchIconPosition">
          <Icon
            sx={{ ml: theme.spacing(5) }}
            className="toggleEntityIcon icon-arrow-left-right textColor-200"
            onClick={toggleEntityView}
          >
            compare_arrows_icon
          </Icon>
        </Box>
      </Box>
      <Box sx={{ mt: 1, mb: theme.spacing(1) }} className={'propsId text-medium font-weight-bold textColor-200'}>
        {tId}
      </Box>
      {accreditationStatus && (
        <Box sx={{ mb: theme.spacing(1) }} className="flex-basic-center">
          <Box className={clsx('icon-accreditation-badge', classes.accreditation_badge_color)}></Box>
          <Box sx={{ ml: 1 }} className="textColor-200 text-medium font-weight-bold">
            {ACCREDITATION_BADGE_TEXT[accreditationStatus]}
          </Box>
        </Box>
      )}

      <Box sx={{ mt: 2, mb: 3 }} className="dashboardlistItem">
        {list?.map((element, index) => {
          return (
            <Grid
              container
              sx={{ px: 2, py: theme.spacing(5) }}
              className={clsx(classes.listItem, 'cursorPointer')}
              key={index}
              onClick={() => listItemClickHandler(element)}
            >
              <Grid item xs={12}>
                <Box className="flex-basic-start">
                  <Box className="flex-basic-start">
                    <span className={element.icon} />
                  </Box>
                  <Box sx={{ pl: 2 }}>{element.label}</Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
};

export default IdCardDetails;
