import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { TButton, TCard, theme } from '../../myde-react-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { idProcessShareDetails } from '../../../redux/feature/idProcess/idProcessShareSlice';
import { formatDate } from '../../../utils/utils';
import { ListDetailsResultType, SharedIdProcessDetailsResultType } from '../../../types/idProcessTypes';
import useQuery from '../../../hooks/useQuery';

const SharedIdProcessDetails = () => {
  const { t } = useTranslation('common');
  const query = useQuery();
  const isEntity = query.get('isEntity') === 'true';

  //redux values
  const { selectedMerchant, sharedIdProcessDetails, entitySharedIdProcessDetails } = useSelector(idProcessShareDetails);

  //state variables
  const [merchant, setSelectedMerchant] = useState({} as ListDetailsResultType);
  const [userIdProcessList, setUserIdProcessList] = useState([] as SharedIdProcessDetailsResultType[]);
  const [profiles, setProfiles] = useState([] as string[]);
  const [customIdProcess, setCustomIdProcess] = useState([] as SharedIdProcessDetailsResultType[]);

  //useEffects
  useEffect(() => {
    setSelectedMerchant(selectedMerchant);
  }, [selectedMerchant]);

  useEffect(() => {
    if (!isEntity && sharedIdProcessDetails?.length > 0) {
      getUserIdProcesses(sharedIdProcessDetails);
      getProfileDetails(sharedIdProcessDetails);
      getCustomIdProcessList();
    } else if (isEntity && entitySharedIdProcessDetails?.length > 0) {
      getUserIdProcesses(entitySharedIdProcessDetails);
      getProfileDetails(entitySharedIdProcessDetails);
      getCustomIdProcessList();
    } else {
      setUserIdProcessList([] as SharedIdProcessDetailsResultType[]);
    }
  }, [sharedIdProcessDetails]);

  //methods
  const getUserIdProcesses = (sharedIdProcesses: SharedIdProcessDetailsResultType[]) => {
    const data = sharedIdProcesses?.filter(
      (idProcess: SharedIdProcessDetailsResultType) => idProcess?.account_id_process?.id_process && idProcess,
    );
    setUserIdProcessList(data);
  };

  const getProfileDetails = (sharedIdProcesses: SharedIdProcessDetailsResultType[]) => {
    sharedIdProcesses?.map(
      (idProcess: SharedIdProcessDetailsResultType) =>
        idProcess?.details?.profiles && setProfiles(Object.keys(idProcess?.details?.profiles)),
    );
  };

  const getCustomIdProcessList = () => {
    const data = [] as SharedIdProcessDetailsResultType[];
    const sharedMerchantsList: SharedIdProcessDetailsResultType[] = isEntity
      ? entitySharedIdProcessDetails
      : sharedIdProcessDetails;
    sharedMerchantsList?.map((idProcess: SharedIdProcessDetailsResultType) => {
      if (idProcess?.account_id_process?.process_name?.length > 0) {
        data?.push(idProcess);
      }
    });
    setCustomIdProcess(data);
  };

  const getProfiles = (profiles: string[]) => {
    return Object.keys(profiles)
      .map((profile: any) => {
        return profiles[profile];
      })
      .join(', ');
  };

  return (
    <TCard>
      <Box className="shareIdProcessDetailScreen">
        <Box sx={{ pt: theme.spacing(7), pl: 3, pb: 3 }} className="flex-basic-start">
          <Box sx={{ pr: 2 }}>
            <img className="merchantLogo" src={merchant?.merchant?.branding?.logo_thumbnail_url} />
          </Box>
          <Box sx={{ pb: theme.spacing(3) }}>
            <Box className="font-weight-medium text-large">{merchant?.merchant?.name}</Box>
            <Box className="text-medium">{`${t('submittedOn')} - ${formatDate(merchant?.created_on)}`}</Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ pt: 2, px: 2, pb: 3 }}>
          <Box className="flex-basic-space-between">
            <Box className="font-weight-semibold text-h3 textColor-200">{t('identityDetails')}</Box>
            <Box>
              <TButton variant="text" btnText="Revoke Access" />
            </Box>
          </Box>
          <Box sx={{ pb: 5 }} className="text-medium">{`${t('sharedDetailsWith')} ${merchant?.merchant?.name}`}</Box>
          {userIdProcessList?.map((idProcess: SharedIdProcessDetailsResultType, index: number) => {
            return (
              <Box key={index}>
                <Box
                  sx={{ pb: isEntity && !idProcess?.details?.profiles ? theme.spacing(7) : theme.spacing(1) }}
                  className="font-weight-semibold text-medium textColor-200"
                >
                  {idProcess?.account_id_process?.id_process?.title}
                </Box>
                {idProcess?.account_id_process?.id_process?.title === 'Profile(s)' && (
                  <Box sx={{ pb: theme.spacing(1) }} className="text-medium">
                    {getProfiles(profiles)}
                  </Box>
                )}
                {!isEntity && !idProcess?.details?.profiles && (
                  <Box sx={{ pb: 2 }} className="text-medium">{`Visibility: ${idProcess?.id_access_level?.title}`}</Box>
                )}
              </Box>
            );
          })}
        </Box>
        {customIdProcess?.length > 0 && (
          <Box>
            <Divider />
            {customIdProcess?.map((idProcess: SharedIdProcessDetailsResultType, index) => {
              return (
                <Box sx={{ pt: 2, px: 2, pb: 3 }} key={index}>
                  <Box sx={{ pb: 2 }} className="text-medium">
                    {t('customIdRequirements')}
                  </Box>
                  <Box className="font-weight-semibold text-medium textColor-200">
                    {idProcess?.account_id_process?.process_name}
                  </Box>
                  {!isEntity && <Box className="text-medium">{`Visibility: ${idProcess?.id_access_level?.title}`}</Box>}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </TCard>
  );
};

export default SharedIdProcessDetails;
