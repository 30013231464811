import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { TButton, TPaper, colorPalette } from '../../components/myde-react-components';
import { useDispatch, useSelector } from 'react-redux';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import EntityListData from '../../components/entities/EntityListData';
import { getEntityList, selectEntity, resetEntityFormData } from '../../redux/feature/entity/entitySlice';
import { useRouter } from '../../providers/custom-router-provider';

const useStyles = makeStyles({
  pageContainer: {
    minHeight: '100vh',
  },
  cardContainer: {
    border: `1px solid ${colorPalette.containerBack.lighten2}`,
    borderRadius: '10px !important',
    boxShadow: 'none !important',
  },
});

const EntitiesLanding = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { t } = useTranslation('common');
  const { entityList } = useSelector(selectEntity);

  useEffect(() => {
    dispatch(getEntityList());
    dispatch(resetEntityFormData());
  }, []);

  const navigateTo = () => {
    routeTo(ROUTES.SEARCH_ENTITY, true, 'Add Entity');
  };

  const entityTitleSection = (title: string, boxclass: string) => {
    return (
      <Box className={`font-weight-semibold ${boxclass}`} sx={{ py: 2 }}>
        {t(title)}
      </Box>
    );
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TPaper
              className={classes.cardContainer}
              sx={{
                mt: 8,
              }}
            >
              <Box sx={{ p: 3 }}>
                {entityList.length > 0 ? (
                  <Box sx={{ py: 2 }}>
                    <EntityListData list={entityList} />
                  </Box>
                ) : (
                  <Box className="textColor-200 text-h3 font-weight-semibold text-center">{t('noEntityHeading')}</Box>
                )}
                <Box className="flex-basic-center" sx={{ py: 2 }}>
                  <TButton onClick={navigateTo} variant="contained" btnText="Add Entity" />
                </Box>
                <Box>
                  {entityTitleSection('entityTitle', 'text-large text-center textColor-200')}
                  {entityTitleSection('entitySubtitle1', 'text-medium textColor-300')}
                  {entityTitleSection('entitySubtitle2', 'text-medium textColor-300')}
                  {entityTitleSection('entitySubtitle3', 'text-medium textColor-300')}
                </Box>
              </Box>
            </TPaper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EntitiesLanding;
