import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, Theme } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';
import clsx from 'clsx';

import CommonSubscriptionScreen from '../../../common/CommonSubscriptionScreen';
import {
  theme,
  TButton,
  FilePreviewType,
  PROCESS_IDS,
  REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS,
  TSelect,
  TCard,
} from '../../../myde-react-components';
import { getAddress, removeUnderscore, replaceSpecialCharAndCapitalize } from '../../../../utils/utils';
import { getProfileDetails, selectProfile } from '../../../../redux/feature/profile/profileSlice';
import {
  getProcessDetailsById,
  selectSelectedIdProcessDetails,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { getIconByProcessId } from '../../../myde-react-components/src/utils/idProcess';
import { useTheme } from '../../../../providers/custom-theme-provider';
import PreviewFileByType from '../../../common/PreviewFileByType';
import { membershipFlip } from '../../../../api/profile';
import {
  resetUploadedPhoto,
  setSelectedAddressVerificationDocument,
} from '../../../../redux/feature/idProcess/addressVerificationSlice';
import { ID_PROCESS_STATUS_TEXT } from '../../../../constants/constants';
import { setIsEditAllowedFlag } from '../../../../redux/feature/common/commonSlice';
import { AddressVerificationDocDetailsType, AddressVerificationOptionsType } from '../../../../types/idProcessTypes';

interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  failedIcon: {
    color: ({ currentThemes }) => currentThemes.palette.error.main,
  },
}));

// Class
const AddressDetails = () => {
  // Constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const idProcessStatusCheck = [
    ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED,
    ID_PROCESS_STATUS_TEXT.VERIFICATION_NEEDS_MANUAL_REVIEW,
  ];

  // Redux Values.
  const { profileData } = useSelector(selectProfile);
  const { selectedIdProcessDetails, processId, addressDataIndex } = useSelector(selectSelectedIdProcessDetails);

  // State Values
  const [docType, setDocType] = useState('');
  const [document, setDocument] = useState([] as FilePreviewType[]);
  const [profile, setProfile] = useState('');
  const [idProcessStatus, setIdProcessStatus] = useState('');
  const [addressType, setAddressType] = useState('');
  const [profileAddress, setProfileAddress] = useState('');
  const [addressDocDetails, setAddressDocDetails] = useState([] as AddressVerificationDocDetailsType[]);
  const [selectedDocument, setSelectedDocument] = useState({} as AddressVerificationDocDetailsType);
  const [addressVerificationOptions, setAddressVerificationOptions] = useState([] as AddressVerificationOptionsType[]);
  // const [merchantSharedCount, setMerchantSharedCount] = useState(0);

  // Use Effects
  useEffect(() => {
    dispatch(getProfileDetails());
    dispatch(setIsEditAllowedFlag(true));
  }, []);

  useEffect(() => {
    if (selectedIdProcessDetails?.data?.length > 0 && addressDataIndex !== null) {
      const addressDocData = selectedIdProcessDetails?.data[addressDataIndex];
      setAddressDocDetails(addressDocData?.address_document);
      const address = getAddress(addressDocData?.profile?.address);
      setProfile(addressDocData?.profile?.profile_name);
      setAddressType(removeUnderscore(addressDocData?.profile?.address_type));
      setProfileAddress(address);
    }
  }, [selectedIdProcessDetails, addressDataIndex]);

  useEffect(() => {
    if (addressDocDetails?.length) {
      setDocType(removeUnderscore(addressDocDetails[0]?.address_document_type));
      setSelectedDocument(addressDocDetails[0]);
      dispatch(setSelectedAddressVerificationDocument(addressDocDetails[0]));
      const options: AddressVerificationOptionsType[] = [];
      addressDocDetails?.forEach((doc: AddressVerificationDocDetailsType) => {
        options.push({
          label: replaceSpecialCharAndCapitalize(doc?.address_document_type, '_', ' ', true),
          id: doc?.address_document_type,
        });
      });
      setAddressVerificationOptions(options);
    }
  }, [addressDocDetails]);

  useEffect(() => {
    if (selectedDocument?.document?.doc_id) {
      const docPreviewData: FilePreviewType[] = [
        {
          name: selectedDocument?.document?.doc_name,
          type: selectedDocument?.document?.mime_type,
          preview: selectedDocument?.document?.signed_url,
          size: selectedDocument?.document?.size_in_kb,
        },
      ];
      setDocument(docPreviewData);
      setIdProcessStatus(selectedDocument?.document_verification_status_label);
    }
  }, [selectedDocument]);

  // Methods
  const handleVerify = async () => {
    const result = await membershipFlip();
    if (result?.id) {
      dispatch(getProcessDetailsById({ processId: PROCESS_IDS.ADDRESS_VERIFICATION }));
      dispatch(getProfileDetails());
      dispatch(resetUploadedPhoto());
    }
  };

  const handleChange = (event: any) => {
    const selectedDoc = addressDocDetails?.find(
      (doc: AddressVerificationDocDetailsType) => doc?.address_document_type === event?.target?.value,
    );
    setSelectedDocument(selectedDoc || ({} as AddressVerificationDocDetailsType));
    dispatch(setSelectedAddressVerificationDocument(selectedDoc));
  };

  // HTML
  return (
    <Box className="addressVerificationDetailScreen">
      <TCard>
        <Box sx={{ p: 3 }}>
          <Box sx={{ mb: 4 }} className="flex-column-center">
            <Box
              sx={{ pt: theme.spacing(4), mb: theme.spacing(5) }}
              className={clsx(getIconByProcessId(processId), classes.icon, 'icon-size-72')}
            />
            <Box className="textColor-200 text-h2 font-weight-semibold">
              {t('instructionHeading', { ns: 'addressVerification' })}
            </Box>
          </Box>
          <Box>
            <Box sx={{ pb: theme.spacing(5) }}>
              <Box className={'text-small textColor-300'}>{`${t('profile', { ns: 'common' })} (${addressType})`}</Box>
              <Box sx={{ pt: theme.spacing(1) }} className={'text-medium text font-weight-semibold textColor-200'}>
                {profile}
              </Box>
            </Box>
            <Box sx={{ pb: theme.spacing(5) }}>
              <Box className={'text-small textColor-300'}>{`${t('address', { ns: 'common' })}`}</Box>
              <Box sx={{ pt: theme.spacing(1) }} className={'text-medium text font-weight-semibold textColor-200'}>
                {profileAddress}
              </Box>
            </Box>
            <Box sx={{ pb: theme.spacing(5) }}>
              {addressDocDetails?.length === 1 && (
                <Box className={'text-small textColor-300'}>
                  {t('documentTypeLabel', { ns: 'addressVerification' })}
                </Box>
              )}
              {addressDocDetails?.length > 1 ? (
                <Box sx={{ pt: theme.spacing(3) }}>
                  <FormControl className="w-100">
                    <TSelect
                      name={'documentType'}
                      id="type"
                      fullWidth
                      value={selectedDocument?.address_document_type || ''}
                      options={addressVerificationOptions}
                      label="Document Type"
                      itemId="id"
                      itemValue="label"
                      onChange={handleChange}
                    />
                  </FormControl>
                </Box>
              ) : (
                <Box sx={{ pt: theme.spacing(1) }} className={'text-medium text font-weight-semibold textColor-200'}>
                  {docType}
                </Box>
              )}
            </Box>

            {!profileData?.is_premium &&
              !REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS.includes(selectedIdProcessDetails?.status) && (
                <Grid item xs={12} sm={12} md={12} sx={{ my: theme.spacing(5) }}>
                  <CommonSubscriptionScreen
                    header={t('addressDocumentNotVerified', { ns: 'addressVerification' })}
                    subtitle={t('addressDocumentVerificationText', { ns: 'addressVerification' })}
                    renderAction={
                      <TButton
                        btnText="Become a Premium Member"
                        btnWidthSize="button-w-240"
                        variant="contained"
                        onClick={handleVerify}
                      />
                    }
                  />
                </Grid>
              )}
            <Box sx={{ pb: theme.spacing(5) }}>
              <Box className={'text-small textColor-300'}>{t('uploadedDocuments', { ns: 'common' })}</Box>
              <Box sx={{ pt: theme.spacing(1) }} className="flex-basic-start">
                <PreviewFileByType files={document} />
              </Box>
            </Box>
            <Box sx={{ pb: theme.spacing(5) }}>
              {profileData?.is_premium && (
                <>
                  <Box className="flex-basic-space-between w-100">
                    <Box>
                      <Box className="textColor-300 text-small font-weight-regular">
                        {t('status', { ns: 'common' })}
                      </Box>
                      <Box sx={{ pt: 1 }} className="textColor-200 text-medium font-weight-semibold">
                        {idProcessStatus}
                      </Box>
                    </Box>
                    <Box>
                      {idProcessStatus === ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS && (
                        <Box className="flex-basic-end">
                          <Box sx={{ pt: theme.spacing(5) }} className="p-relative small-sizes custom-progress-icon">
                            <Box className="progress-spinner sm-spinner"></Box>
                          </Box>
                        </Box>
                      )}
                      {idProcessStatus === ID_PROCESS_STATUS_TEXT.VERIFIED && (
                        <Box>
                          <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                            <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
                          </Box>
                        </Box>
                      )}
                      {idProcessStatusCheck?.includes(idProcessStatus) && (
                        <Box>
                          <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                            <span className="icon-error-warning error-color-state icon-size-24"></span>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </TCard>
    </Box>
  );
};

export default AddressDetails;
