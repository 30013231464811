import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NewInvitation from '../../components/dashboard/invitations/NewInvitation';
import PendingApplication from '../../components/dashboard/invitations/PendingApplication';
import ApplicationModal from '../../components/dashboard/invitations/ApplicationModal';
import { theme, TLoader, TPaper } from '../../components/myde-react-components';
import { APPLICATION_STATUS, ENTITY_INVITE_STATUS, MERCHANT_INVITE_STATUS } from '../../constants/constants';
import {
  getApplicationList,
  getInvitationList,
  selectInviteDashboardDetails,
} from '../../redux/feature/dashboard/invitationSlice';
import { MerchantApplicationType, MerchantInvitationType } from '../../types/invitationTypes';
import { getProfileDetailsList } from '../../redux/feature/profile/profileSlice';
import { t } from 'i18next';
import { getEntityInvitationList, selectEntity } from '../../redux/feature/entity/entitySlice';
import NewEntityInvitation from '../../components/dashboard/invitations/NewEntityInvitation';
import ViewEntityInviteDetails from '../../components/entities/invitations/ViewEntityInviteDetails';
import ViewAcceptedEntityInviteDetails from '../../components/entities/invitations/ViewAcceptedEntityInviteDetails';
import { EntityInvitionsType } from '../../types/entityTypes';

const Dashboard = () => {
  const dispatch = useDispatch();

  // Redux Values
  const { inviteList, applicationList, loading } = useSelector(selectInviteDashboardDetails);
  const { allEntityInvitations } = useSelector(selectEntity);

  // State Values
  const [invitationList, setInvitationList] = useState([] as MerchantInvitationType[]);
  const [allApplicationList, setApplicationList] = useState([] as MerchantApplicationType[]);
  const [entityInvitationList, setEntityInvitationList] = useState([] as EntityInvitionsType[]);

  // Use Effects
  useEffect(() => {
    dispatch(getEntityInvitationList());
    dispatch(getInvitationList({ status: APPLICATION_STATUS.INVITED }));
    dispatch(
      getApplicationList({
        status_in: `{${MERCHANT_INVITE_STATUS.STARTED},${MERCHANT_INVITE_STATUS.UNDER_REVIEW},${MERCHANT_INVITE_STATUS.PENDING_REVIEW}}`,
      }),
    );
    dispatch(getProfileDetailsList({}));
  }, []);

  useEffect(() => {
    if (inviteList?.length > 0) {
      setInvitationList(inviteList);
    } else {
      setInvitationList([]);
    }
  }, [inviteList]);

  useEffect(() => {
    if (applicationList?.length > 0) {
      setApplicationList(applicationList);
    }
  }, [applicationList]);

  useEffect(() => {
    if (allEntityInvitations?.length > 0) {
      const invites = allEntityInvitations?.filter(
        (invite: EntityInvitionsType) => invite?.status === ENTITY_INVITE_STATUS.PENDING,
      );
      setEntityInvitationList(invites);
    }
  }, [allEntityInvitations]);

  // HTML
  return (
    <Box sx={{ pt: 10 }}>
      <TLoader loading={loading} />
      {allApplicationList?.length > 0 && <PendingApplication />}
      {invitationList?.length > 0 && <NewInvitation />}
      {allEntityInvitations?.length > 0 && <NewEntityInvitation />}
      {allApplicationList?.length === 0 && invitationList?.length === 0 && entityInvitationList?.length === 0 && (
        <>
          <Box sx={{ mb: 1 }} className="text-h3 textColor-200 font-weight-semibold">
            {t('dashboard', { ns: 'common' })}
          </Box>
          <Divider sx={{ mt: 1, mb: theme.spacing(5) }} />
          <TPaper>
            <Box sx={{ pt: 6 }} className="emptyCardHeight flex-column-start">
              <Box sx={{ mb: 3 }}>
                <span className="icon-empty-inbox icon-size-72 textColor-400"></span>
              </Box>
              <Box sx={{ px: 3 }} className="text-center textColor-300 text-h3 font-weight-semibold">
                {t('noInvitations', { ns: 'common' })}
              </Box>
            </Box>
          </TPaper>
        </>
      )}
      <ApplicationModal />
      <ViewEntityInviteDetails />
      <ViewAcceptedEntityInviteDetails />
    </Box>
  );
};

export default Dashboard;
