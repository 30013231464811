import { apiGet, apiUpload, apiPost, apiPatch, apiDelete } from './axios';

// Get processes by id
export const processDetailsById = async (processId: any, params?: object) => {
  const res = await apiGet(`/id-process/${processId}`, { params: params || {} });
  return res.data;
};

export const getProcessChildrenById = async (processId: any, params?: object) => {
  const res = await apiGet(`/id-process/${processId}/children`, { params: params || {} });
  return res.data;
};

export const getAddressIdProcessData = async () => {
  const res = await apiGet('/address-verification');
  return res.data;
};

//Get Verification Items for ID process
export const verificationItemById = async (processId: string | number) => {
  const res = await apiGet(`/id-process/${processId}/verification-item`);
  return res.data;
};

//Upload Documents
export const postUploadedDoc = async (doc: File) => {
  const file = new FormData();
  if (doc?.type?.includes('video')) {
    file.append('file', new File([doc], doc?.name, { type: 'video/mp4' }));
  } else {
    file.append('file', doc);
  }
  const res = await apiUpload('/document', file);
  return res.data;
};

// Draft or submit id processes
export const draftOrCreateIdProcess = async (formdata: object) => {
  const res = await apiPost('/id-process', formdata);
  return res.data;
};

export const draftOrCreateAddressIdProcess = async (formdata: object) => {
  const res = await apiPost('/address-verification', formdata);
  return res.data;
};

// Retrieve address
export const retriveAddress = async () => {
  const res = await apiGet(`/address`);
  return res.data;
};

// Add new address
export const addNewAddress = async (formData: object) => {
  const res = await apiPost('/address', formData);
  return res.data;
};

export const updateExistingAddress = async (formData: object, addressId: string) => {
  const res = await apiPatch(`/address/${addressId}`, formData);
  return res.data;
};

export const getCountries = async () => {
  const res = await apiGet('/data-entity/countries');
  return res.data;
};

export const getAllStates = async () => {
  const res = await apiGet('/data-entity/states');
  return res.data;
};

export const getStatesForSpecificCountry = async (filters: object) => {
  const res = await apiGet(`/data-entity/states`, { params: filters });
  return res.data;
};
export const deleteAddress = async (address_id: string) => {
  const res = await apiDelete(`/address/${address_id}`);
  return res.data;
};

export const getEsignUrl = async (processId: number, formData: object) => {
  const res = await apiPost(`/id-process/${processId}/esign`, formData);
  return res.data;
};

export const getEntityEsignUrl = async (processId: number, entityUid: number, formData: object) => {
  const res = await apiPost(`/entity/${entityUid}/id-process/${processId}/esign`, formData);
  return res.data;
};

export const getCity = async (filters?: object) => {
  const res = await apiGet(`/city`, { params: filters });
  return res.data;
};
export const getCities = async () => {
  const res = await apiGet(`/city`);
  return res.data;
};

//profile
export const retriveProfile = async (params: object) => {
  const res = await apiGet(`/profile`, { params: params });
  return res.data;
};

export const addNewProfile = async (formData: object) => {
  const res = await apiPost('/profile', formData);
  return res.data;
};

export const retriveProfileById = async (id: string) => {
  const res = await apiGet(`/profile/${id}`);
  return res.data;
};

export const linkCustomIdProcessToMerchantIdRequirement = async (formData: object) => {
  const response = await apiPatch('/id-process/custom/link', formData);
  return response.data;
};

//get id process share list
export const getIdProcessShareList = async (params: object) => {
  const res = await apiGet(`/id-share`, { params: params });
  return res.data;
};

//get id process share list for entity
export const getIdProcessShareListForEntity = async (entity_uid: number, params: object) => {
  const res = await apiGet(`/entity/${entity_uid}/id-share`, { params: params });
  return res.data;
};

export const getSharedIdProcessListForEntity = async (entity_uid: number, params: object) => {
  const res = await apiGet(`/entity/${entity_uid}/id-share`, { params: params });
  return res.data;
};
