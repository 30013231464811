import React, { useEffect } from 'react';
import { Box, Grid, Tab, Tabs, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import clsx from 'clsx';

import TabPanel from './TabPanel';
import { ClickHandlerType } from '../../../../../types/commonTypes';
import { colorPalette } from '../../constants/colors';
import { fontStyle } from '../../../../../styles/style';
import { TCard } from '../../..';

import { useTheme } from '../../../../../providers/custom-theme-provider';

export interface CustomTabProps {
  tabList: TabListType[];
  isVertical?: boolean;
  borderRequired: boolean;
  selectedTab?: any;
  tabSelected?: ClickHandlerType;
  showBottomBorder: boolean;
  removePadding?: boolean;
  fourTabs?: boolean;
  customClass?: boolean;
}

export interface TabListType {
  label: string;
  component: JSX.Element;
  id?: any;
  tabChild?: any;
  icon?: JSX.Element;
}

interface StyleProps {
  borderRequired: boolean;
  isVertical: boolean;
  currentThemes: Theme;
  showBottomBorder: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  cards: {
    minHeight: 500,
    marginTop: 20,
    borderRadius: ({ borderRequired }) => (borderRequired ? 10 : 0),
    border: ({ borderRequired }) => (borderRequired ? `1px solid ${colorPalette.border.card}` : 'none !important'),
  },
  tabListWrapper: {
    borderRight: ({ isVertical }) => (isVertical ? `1px solid ${colorPalette.border.card}` : 'none'),
    borderBottom: ({ isVertical, showBottomBorder }) =>
      !isVertical && showBottomBorder ? `1px solid ${colorPalette.border.card}` : 'none',
    height: ({ isVertical }) => (isVertical ? '100%' : 'auto'),
    '& .MuiTabs-indicator': {
      display: ({ isVertical }) => (isVertical ? 'none' : 'inherit'),
      backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
  },
  tabItemStyle: {
    borderTop: ({ borderRequired }) =>
      borderRequired ? `1px solid ${colorPalette.border.card} !important` : 'none !important',
    justifyContent: 'flex-start !important',
    paddingLeft: '15px !important',
    alignItems: 'start !important',
    textAlign: 'left !important' as any,
    maxWidth: '100% !important',
    color: `${colorPalette.typoText.darken2}`,
    textTransform: 'unset !important' as any,
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font500,
    '&:first-child': {
      borderTop: 'none !important',
      '&.Mui-selected': {
        borderRight: 'none',
        color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
      },
    },
    '&:nth-child(2)': {
      borderTop: 'none !important',
    },
    '& .MuiTab-root': {
      padding: '10px 16px',
    },
    '&.Mui-selected': {
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
      fontWeight: ({ isVertical }) => (isVertical ? `${fontStyle.fontWeight.font700.fontWeight}` : 'inherit'),
    },
  },
  tabLabelWrapper: {
    position: 'relative',
    textAlign: ({ isVertical }) => (!isVertical ? 'center' : 'left'),
  },
  tabItemLabel: {
    verticalAlign: 'super',
    marginLeft: 5,
  },
  rightIcon: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
  },
  tabContainer: {
    '& .Mui-disabled': {
      display: 'none',
    },
    '& .Mui-selected': {
      padding: 12,
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
    paddingLeft: 12,
    paddingRight: 12,
  },
});

const basicTabProps = (index: number) => {
  return {
    id: `custom-tab-${index}`,
    'aria-controls': `custom-tabpanel-${index}`,
  };
};

const CustomTabs = ({
  tabList,
  isVertical = false,
  borderRequired = false,
  selectedTab,
  showBottomBorder,
  tabSelected,
  removePadding,
  customClass,
  fourTabs,
}: CustomTabProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    borderRequired: borderRequired,
    isVertical: isVertical,
    currentThemes: currentThemes,
    showBottomBorder,
  };
  const classes = useStyles(styleProps);
  const [value, setValue] = React.useState(selectedTab || 0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const currentTab = tabList[newValue];
    setValue(currentTab?.id || newValue);
  };

  useEffect(() => {
    if (!selectedTab && tabList && tabList.length > 0) {
      setValue(tabList[0]?.id || 0);
    }
  }, [tabList]);

  useEffect(() => {
    if (tabSelected) {
      tabSelected(value);
    }
  }, [value]);

  return (
    <>
      <TCard
        className={clsx({ ['user-view-document']: customClass }, classes.cards)}
        elevation={borderRequired ? 1 : 0}
      >
        <Grid container direction="row" sx={{ m: 0 }}>
          <Grid item xs={12} sm={12} md={isVertical ? 3 : 12} lg={isVertical ? 4 : 12}>
            <Box sx={{ p: isVertical ? 2 : 0 }} className={clsx('tab-wrapper', classes.tabListWrapper)}>
              <Tabs
                value={value}
                scrollButtons={fourTabs ? true : false}
                className={removePadding ? classes.tabContainer : ''}
                sx={{ bgcolor: 'background.paper' }}
                onChange={handleChange}
                orientation={isVertical ? 'vertical' : 'horizontal'}
                variant="scrollable"
              >
                {tabList.map((tabItem, index) => (
                  <Tab
                    className={classes.tabItemStyle}
                    key={index}
                    label={
                      !tabItem.tabChild ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className={classes.tabLabelWrapper}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {tabItem.icon && <span>{tabItem.icon}</span>}
                            <Box className="flex-basic-center">
                              <Box
                                className={clsx({
                                  [classes.tabItemLabel]: isVertical,
                                })}
                              >
                                {tabItem.label}
                              </Box>
                            </Box>
                          </Grid>
                          {isVertical && (
                            <Grid item className={classes.rightIcon}>
                              <KeyboardArrowRightIcon fontSize="small" sx={{ float: 'right', mt: 2 }} />
                            </Grid>
                          )}
                        </Grid>
                      ) : (
                        tabItem.tabChild
                      )
                    }
                    value={tabItem?.id || index}
                    {...basicTabProps(tabItem?.id || index)}
                  />
                ))}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={isVertical ? 9 : 12} lg={isVertical ? 8 : 12}>
            <Box className="messageSectionScroll" sx={{ pb: 1 }}>
              {tabList.map((tabItem, idx) => (
                <TabPanel key={idx} value={value} index={tabItem?.id || idx}>
                  <Box>{tabItem.component}</Box>
                </TabPanel>
              ))}
            </Box>
          </Grid>
        </Grid>
      </TCard>
    </>
  );
};

export default CustomTabs;
