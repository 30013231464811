import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import {
  resetAddressFormData,
  resetEntityFormData,
  selectEntity,
  setIsEntity,
  setSelectedEntity,
} from '../../redux/feature/entity/entitySlice';
import { EntityTypes } from '../../types/entityTypes';
import CommonList from '../common/CommonList';
import {
  setEntitySelectionFlag,
  setEntityDetailFlag,
  selectUser,
  setBrandingData,
} from '../../redux/feature/user/userSlice';
import { getProfileDetails, selectProfile } from '../../redux/feature/profile/profileSlice';

const EntityList = () => {
  const dispatch = useDispatch();

  //Redux values
  const { entityList } = useSelector(selectEntity);
  const { userBranding } = useSelector(selectUser);
  const { profileData, profileUrl } = useSelector(selectProfile);
  const currentUserName = `${profileData?.first_name || ''} ${profileData?.last_name || ''}`;
  const isEntityListAvailable = entityList && entityList.length > 0;

  //Use Effects
  useEffect(() => {
    dispatch(resetEntityFormData());
    dispatch(resetAddressFormData());
    dispatch(getProfileDetails());
  }, []);

  //Methods
  const updateIdCard = (entity: EntityTypes) => {
    dispatch(setSelectedEntity(entity));
    dispatch(setIsEntity(true));
    dispatch(setEntitySelectionFlag(false));
    dispatch(setEntityDetailFlag(true));
  };
  const goToUserIdCard = () => {
    dispatch(setBrandingData(userBranding));
    dispatch(setSelectedEntity({} as EntityTypes));
    dispatch(setEntitySelectionFlag(false));
    dispatch(setEntityDetailFlag(false));
    dispatch(setIsEntity(false));
  };

  return (
    <>
      <Box sx={{ mt: 1, ml: 1, pb: 2 }} className="textColor-200 text-large font-weight-semibold text-start">
        Select an Identity
      </Box>
      <Box sx={{ mb: 5 }} className={clsx('listWrapperMax', 'entityList', 'cursorPointer')}>
        <CommonList imageUrl={profileUrl || ''} listText={currentUserName} onClickHandler={goToUserIdCard} />
        {isEntityListAvailable &&
          entityList.map((entity, index) => {
            return (
              <div key={index}>
                <CommonList
                  imageUrl={''}
                  listText={entity.name}
                  role={entity?.member_details?.user_role && entity?.member_details?.user_role}
                  permission={entity?.member_details?.permission && entity?.member_details?.permission}
                  callBackData={entity}
                  onClickHandler={updateIdCard}
                />
              </div>
            );
          })}
      </Box>
    </>
  );
};

export default EntityList;
