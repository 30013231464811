import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export interface AccordionProps {
  accordinHeaderText?: string;
  accordinBodyText1?: string;
  accordinBodyText2?: string;
  accordinBodyText3?: string;
  isExpanded?: boolean;
}
export const AccordionExpand = ({
  accordinHeaderText,
  accordinBodyText1,
  accordinBodyText2,
  accordinBodyText3,
  isExpanded,
}: AccordionProps) => {
  return (
    <Accordion defaultExpanded={isExpanded}>
      <AccordionSummary
        sx={{ py: 1 }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span className="textColor-200 text-large font-weight-semibold">{accordinHeaderText}</span>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <Box sx={{ mb: 2 }} className="textColor-200 text-medium font-weight-regular">
          {accordinBodyText1}
        </Box>
        <Box sx={{ mb: 2 }} className="textColor-200 text-medium font-weight-regular">
          {accordinBodyText2}
        </Box>
        <Box sx={{ mb: 0 }} className="textColor-200 text-medium font-weight-regular">
          {accordinBodyText3}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionExpand;
