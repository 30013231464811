import React, { createContext, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const useToastProvider = () => {
  // pass
};
const defaultThemeContext = {};

const CustomToastContext = createContext(defaultThemeContext as any);

export const useToast = () => {
  return useContext(CustomToastContext);
};

export const CustomToastProvider = () => {
  const customToast = useToastProvider();
  return (
    <CustomToastContext.Provider value={customToast as any}>
      <ToastContainer
        className="toastContainer"
        autoClose={4000}
        position={'bottom-center'}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        icon={false}
        theme={'colored'}
      />
    </CustomToastContext.Provider>
  );
};
