import React, { useEffect, useState } from 'react';
import { TextField as MuiTextField, TextFieldProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { colorPalette } from '../../../../myde-react-components';
interface StyleProps {
  currentThemes: Theme;
}
interface CustomProps {
  isOptional?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  textArea: {
    '& .MuiInputLabel-formControl': {
      '&.Mui-focused': {
        color: ({ currentThemes }) => `${currentThemes.palette.primary.main}`,
      },
      '&.Mui-error': {
        color: `${colorPalette.error} !important`,
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused': {
        '& > fieldset': { borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important` },
      },
      '&.Mui-error': {
        '& > fieldset': {
          borderColor: `${colorPalette.error} !important`,
        },
        '&.Mui-focused': {
          '& > fieldset': {
            borderColor: `${colorPalette.error} !important`,
          },
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          display: 'none !important',
        },
      },
    },
    '& .MuiOutlinedInput-input.MuiInputBase-input': {
      padding: '16px 16px 16px 3px',
    },
  },
});

export type CombinedProps = CustomProps & TextFieldProps;

export const TextArea = React.forwardRef<any, CombinedProps>(({ isOptional, ...props }: CombinedProps, ref: any) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const [label, setLabel] = useState('');
  const classes = useStyles(styleProps);

  useEffect(() => {
    if (props.label) {
      setLabel(isOptional ? `${props.label} (optional)` : `${props.label}`);
    }
  }, []);

  return <MuiTextField {...props} ref={ref} label={label} className={classes.textArea} multiline />;
});
TextArea.defaultProps = {
  minRows: 8,
};
export default TextArea;
