import React from 'react';
import { Box } from '@mui/material';

import { ENTITY_ID_PROCESS_TEXTS, ID_PROCESS_TEXTS } from '../../../constants/constants';
import { TButton, TCard, theme } from '../../myde-react-components';

interface ViewShareDetailsCardProps {
  processId: number | string;
  isEntity?: boolean;
  merchantSharedCount: number;
  handleViewClick: () => void;
  isCustom?: boolean;
}

const ViewShareDetailsCard = ({
  processId,
  isEntity = false,
  merchantSharedCount,
  handleViewClick,
  isCustom = false,
}: ViewShareDetailsCardProps) => {
  //Methods
  const getIdProcessText = () => {
    let result = ``;
    if (!isCustom) {
      result = `${
        isEntity ? ENTITY_ID_PROCESS_TEXTS[processId] : ID_PROCESS_TEXTS[processId]
      } is shared with ${merchantSharedCount} merchant(s)`;
    } else {
      result = `Custom ID is shared with ${merchantSharedCount} merchant(s)`;
    }
    return result;
  };

  return (
    <TCard sx={{ mt: 1, px: 1, py: 1 }}>
      <Box className="flex-basic-center">
        <Box sx={{ pl: theme.spacing(2) }} className="text-medium font-weight-semibold">
          {getIdProcessText()}
        </Box>
        <TButton variant="text" btnText="View" onClick={handleViewClick} />
      </Box>
    </TCard>
  );
};

export default ViewShareDetailsCard;
