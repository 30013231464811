import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { t } from 'i18next';

import { selectInviteDashboardDetails } from '../../../redux/feature/dashboard/invitationSlice';
import StatusCard from '../../common/StatusCard';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { ID_CARD_NAV_INDEX } from '../../../constants/constants';
import { setActiveCardNav, setCardState } from '../../../redux/feature/common/commonSlice';
import { TCard, TLoader } from '../../myde-react-components';

const ApplicationStatus = () => {
  // Constants
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  // Redux Values
  const { selectedApplication, loading } = useSelector(selectInviteDashboardDetails);

  // State Values
  const [applicationDetails, setApplicationDetails] = useState({} as any);

  // Use Effects
  useEffect(() => {
    if (selectedApplication?.id) {
      setApplicationDetails(selectedApplication);
    }
  }, [selectedApplication]);

  // Methods
  const navigateToDetails = (isTapp: boolean, isAutoApproved: boolean) => {
    if (!isTapp) {
      routeTo(ROUTES.USER_MESSAGES);
    } else if (isAutoApproved) {
      dispatch(setActiveCardNav(ID_CARD_NAV_INDEX.TAPP));
      dispatch(setCardState(true));
    } else {
      routeTo(ROUTES.DASHBOARD);
    }
  };

  // HTML
  return (
    <Box sx={{ pt: 10 }}>
      <TLoader loading={loading} />
      <TCard sx={{ mt: 2 }}>
        {applicationDetails?.details?.invite_attributes?.is_auto_approved && (
          <StatusCard
            logoUrl={applicationDetails?.merchant?.branding?.logo_url}
            title={t('approveTitle', { ns: 'inviteFlow' })}
            body={
              <>
                <Box sx={{ pb: 3 }}>
                  {`${
                    applicationDetails?.t_app?.name
                      ? applicationDetails?.t_app?.name
                      : applicationDetails.merchant?.name
                  }`}{' '}
                  {t('approveText1', { ns: 'inviteFlow' })}
                </Box>
                {applicationDetails?.t_app && Object.keys(applicationDetails?.t_app)?.length > 0 ? (
                  <Box sx={{ pb: 3 }}> {t('approveText2', { ns: 'inviteFlow' })}</Box>
                ) : (
                  <Box sx={{ pb: 3 }}> {t('approveText3', { ns: 'inviteFlow' })}</Box>
                )}
              </>
            }
            btnLable={
              applicationDetails?.t_app && Object.keys(applicationDetails?.t_app)?.length > 0
                ? 'Go To T-Apps'
                : 'Merchant and Privacy'
            }
            onClick={() => {
              navigateToDetails(Object.keys(applicationDetails?.t_app || {})?.length > 0, true);
            }}
          />
        )}
        {!applicationDetails?.details?.invite_attributes?.is_auto_approved && (
          <StatusCard
            logoUrl={applicationDetails?.merchant?.branding?.logo_url}
            title={t('underReviewTitle', { ns: 'inviteFlow' })}
            body={
              <>
                <Box sx={{ pb: 3 }}> {t('underReviewText1', { ns: 'inviteFlow' })} </Box>
              </>
            }
            btnLable={
              applicationDetails?.t_app && Object.keys(applicationDetails?.t_app)?.length > 0
                ? t('okGotIt', { ns: 'inviteFlow' })
                : 'Merchant and Privacy'
            }
            onClick={() => {
              navigateToDetails(Object.keys(applicationDetails?.t_app || {})?.length > 0, false);
            }}
          />
        )}
      </TCard>
    </Box>
  );
};

export default ApplicationStatus;
