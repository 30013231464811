import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TCard, theme, TLoader } from '../../../components/myde-react-components';
import { useRouter } from '../../../providers/custom-router-provider';
import { useTheme } from '../../../providers/custom-theme-provider';
import { getApplicationList, selectInviteDashboardDetails } from '../../../redux/feature/dashboard/invitationSlice';
import { MerchantApplicationType } from '../../../types/invitationTypes';
import { hexToRgbA } from '../../../utils/utils';
import * as ROUTES from '../../../constants/routes';
import useQuery from '../../../hooks/useQuery';
import { useTranslation } from 'react-i18next';

interface StyleProps {
  primaryColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  boldUnreadCount: {
    borderColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: ({ primaryColor }) => `${primaryColor} !important`,
    backgroundColor: ({ primaryColor }) => hexToRgbA(`${primaryColor}`, 0.08),
  },
});

const ApplicationList = () => {
  //Constants
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes?.palette?.primary?.main;
  const styleProps: StyleProps = {
    primaryColor,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const query = useQuery();
  const invite_type = query.get('invite_type') || '';
  const merchant_id = query.get('merchant_id') || '';
  const entity_uid = query.get('entity_uid') || '';
  const is_entity = query.get('is_entity') || '';

  //Redux
  const { applicationList, loading } = useSelector(selectInviteDashboardDetails);

  //State

  //useEffect
  useEffect(() => {
    const filters = {
      invite_type,
      merchant_id,
    };
    dispatch(getApplicationList(is_entity ? { ...filters, entity_uid } : filters));
  }, []);

  //Methods
  const goToApplicationMessages = (application: MerchantApplicationType) => {
    const query = {
      applicationId: application?.id,
    };
    routeTo(ROUTES.APPLICATION_MESSAGES, true, application?.invitation?.invite_title, query);
  };

  //HTML
  return (
    <TCard sx={{ p: 3 }}>
      <TLoader loading={loading} />
      {applicationList?.length > 0 ? (
        applicationList?.map((item: MerchantApplicationType, index: number) => (
          <>
            <Box key={item?.id} className="flex-basic-space-between align-items-start">
              <Box
                sx={{ mb: applicationList?.length !== index + 1 ? theme.spacing(5) : 0 }}
                className="flex-basic-start cursorPointer"
                onClick={() => goToApplicationMessages(item)}
              >
                <Box sx={{ mr: 2 }} className="icon-chat icon-size-20"></Box>
                <Box className="textColor-200 text-medium font-weight-semibold">{item?.invitation?.invite_title}</Box>
              </Box>
              {item?.total_unread_response_count > 0 && (
                <Box
                  className={clsx('unreadCountBox', classes.boldUnreadCount)}
                >{`${item?.total_unread_response_count} new`}</Box>
              )}
            </Box>
            {applicationList?.length !== index + 1 && <Divider sx={{ mb: theme.spacing(5) }} />}
          </>
        ))
      ) : (
        <Box sx={{ pt: 5 }} className="text-center">
          <Box sx={{ mb: 3 }} className="icon-chat icon-size-72 textColor-400"></Box>
          <Box className="text-h3 font-weight-semibold textColor-300">
            {t('noApplication', { ns: 'userViewMessage' })}
          </Box>
        </Box>
      )}
    </TCard>
  );
};

export default ApplicationList;
