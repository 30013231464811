import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import MerchantListing from './MerchantListing';
import { MerchantEntitiesSelectionType, MerchantUsersSelectionType } from '../../types/documentTypes';

// Interfaces
interface MerchantListingIndexProps {
  merchantUserList: MerchantUsersSelectionType[];
  merchantEntityList: MerchantEntitiesSelectionType[];
  showHeader: boolean;
  handleClick: (data: MerchantUsersSelectionType, entity_uid?: number) => void;
}

const MerchantListingIndex = ({
  merchantUserList,
  merchantEntityList,
  showHeader,
  handleClick,
}: MerchantListingIndexProps) => {
  // Constants
  const { t } = useTranslation('userViewMessage');

  // Methods
  const renderMerchantListForEntities = (entityList: MerchantEntitiesSelectionType[], header: string) => {
    return (
      <Box>
        {!showHeader && <Box className="text-medium font-weight-semibold textColor-200">{header}</Box>}
        {entityList?.map((item: MerchantEntitiesSelectionType) => (
          <Box key={item?.entity_trellis_uid}>
            {!showHeader && (
              <Box className="text-medium textColor-200" sx={{ mt: 3 }}>
                {item?.entity_name}
              </Box>
            )}
            <MerchantListing
              list={item?.merchants}
              entity_uid={item?.entity_trellis_uid}
              onListItemClick={handleClick}
            />
          </Box>
        ))}
      </Box>
    );
  };

  // HTML
  return (
    <Box>
      {merchantUserList?.length > 0 && (
        <Box sx={{ py: 3 }}>
          <MerchantListing
            list={merchantUserList}
            header={t('userMerchantMessage', { ns: 'userViewMessage' })}
            onListItemClick={handleClick}
          />
        </Box>
      )}
      {merchantEntityList?.length > 0 && (
        <Box sx={{ py: 3 }}>
          {renderMerchantListForEntities(merchantEntityList, t('entityMerchantMessage', { ns: 'userViewMessage' }))}
        </Box>
      )}
    </Box>
  );
};

export default MerchantListingIndex;
