import React from 'react';
import { Tooltip as MuiTooltip, TooltipProps, Theme } from '@mui/material';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { makeStyles } from '@mui/styles';
export interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  customTooltip: {
    '&.MuiTooltip-tooltip': {
      borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
      '& .MuiTooltip-arrow': {
        '&::before': {
          borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
        },
      },
    },
  },
});

export const Tooltip = ({ ...props }: TooltipProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  return (
    <MuiTooltip
      arrow
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.arrow,
      }}
      {...props}
    />
  );
};

export default Tooltip;
