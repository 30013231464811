import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { idProcessMasterDetails, idProcessDetails } from '../../../api/dashboard';
import { IdProcessDetails, IdProcessMasterDetails } from '../../../types/dashboardTypes';

type DashboardState = {
  idProcessList: IdProcessDetails[];
  idProcessMasterList: IdProcessMasterDetails[];
  profileDetails: any;
  loading: boolean;
  error: boolean;
  errorObj: any;
};

const initialState: DashboardState = {
  idProcessList: [],
  idProcessMasterList: [],
  profileDetails: [],
  loading: false,
  error: false,
  errorObj: {},
};

export const selectDashboardDetails = ({ dashboard }: RootState) => ({
  idProcessList: dashboard.idProcessList,
  idProcessMasterList: dashboard.idProcessMasterList,
  profileDetails: dashboard.profileDetails,
  loading: dashboard.loading,
});

export const getIdProcessDetails = createAsyncThunk('dashboard/getIdProcessDetails', async () => {
  return await idProcessDetails();
});

export const getIdProcessMasterDetails = createAsyncThunk('dashboard/getIdProcessMasterDetails', async () => {
  return await idProcessMasterDetails();
});

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIdProcessDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIdProcessDetails.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.idProcessList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getIdProcessDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getIdProcessMasterDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIdProcessMasterDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.idProcessMasterList = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getIdProcessMasterDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default dashboardSlice.reducer;
