import React, { useEffect, useState } from 'react';
import { Box, Divider, FormControl, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  colorPalette,
  TButton,
  TTextField,
  TAutocomplete,
  TGPlaces,
  TSelect,
  PhoneNumber,
  theme,
  TLoader,
  ID_PROCESS_STATUS_COMPLETION_STATE,
  GPlacesAddressDataType,
  GPlacesAddressType,
} from '../../../myde-react-components';
import { selectProfile, setIsExistingAddressSelected } from '../../../../redux/feature/profile/profileSlice';
import { titleCase } from '../../../../utils/utils';
import { PhoneType, ProfileDetails, ProfileFormDataType } from '../../../../types/profileTypes';
import {
  PhoneCategory,
  PHONE_NUMBER_TYPE,
  PHONE_TYPES,
  ADDRESS_TYPES,
  ADDRESS_TYPES_KEYS,
} from '../../../../constants/constants';
import EmailVerification from './EmailVerification';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { selectEntityProfile } from '../../../../redux/feature/profile/entityProfileSlice';
import { getAddressData } from '../../../myde-react-components/src/utils/commonMethods';

interface ProfileFormProps {
  isEntity: boolean;
  children?: React.ReactNode;
  hasStepButton?: boolean;
  saveProfileData: (formData: ProfileFormDataType, isEditMode?: boolean) => void;
  goToPreviousStep?: (step: number) => void;
  entityOnBoarding?: boolean;
  hasAddressType?: boolean;
}

const useStyles = makeStyles({
  formCard: {
    border: `1px solid ${colorPalette.containerBack.lighten2}`,
    borderRadius: '10px',
  },
  errorContainer: {
    width: '-webkit-fill-available',
  },
  hideAddressType: {
    display: 'none !important',
  },
});

const ProfileForm = ({
  isEntity,
  children,
  saveProfileData,
  goToPreviousStep,
  entityOnBoarding,
  hasStepButton = false,
  hasAddressType = true,
}: ProfileFormProps) => {
  // Constants
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const classes = useStyles();

  // Use Selectors
  const { profileList, isExistingAddressSelected, emailNotVerified, addedProfile, profileHolder, loading } =
    useSelector(selectProfile);
  const { entityProfileList, entityAddedProfile, entityEmailNotVerified } = useSelector(selectEntityProfile);

  // State Values
  const [showVerification, setShowVerification] = useState(false);
  const [isPrimaryPhoneType, setIsPrimaryPhoneType] = useState(true);
  const [isSecondaryPhoneType, setIsSecondaryPhoneType] = useState(true);
  const [primaryEntered, setPrimaryEntered] = useState(false);
  const [primaryPhone, setPrimaryPhone] = useState('');
  const [primaryPhoneType, setPrimaryPhoneType] = useState('');
  const [primaryPhoneTypeSelected, setPrimaryPhoneTypeSelected] = useState(false);
  const [selectedAddressType, setSelectedAddressType] = useState('');
  const [secondaryEntered, setSecondaryEntered] = useState(false);
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [secondaryPhoneType, setSecondaryPhoneType] = useState('');
  const [secondaryPhoneTypeSeleceted, setSecondaryPhoneTypeSelected] = useState(false);
  const [existingAddress, setExistingAddress] = useState({} as ProfileDetails);
  const [countryCode, setCountryCode] = useState('');
  const [profileDetailsList, setProfileDetailsList] = useState([] as ProfileDetails[]);
  const [gPlacesAddressData, setGPlacesAddressData] = useState({} as GPlacesAddressDataType);
  const [profileExists, setProfileExists] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  // Form Setup
  const ProfileFormSchema = z.object({
    type: isEditMode
      ? z
          .string()
          .max(25, { message: 'Profile name should be of maximum 25 characters' })
          .min(1, { message: 'Profile name is required' })
      : z
          .string()
          .max(25, { message: 'Profile name should be of maximum 25 characters' })
          .min(1, { message: 'Profile name is required' })
          .refine(
            (value) =>
              !profileDetailsList.some(
                (el: ProfileDetails) => titleCase(el?.profile_name).toUpperCase() === titleCase(value).toUpperCase(),
              ),
            {
              message: 'Profile name should be unique',
            },
          ),
    address1: z.string().min(1, { message: 'Address line 1 is required' }),
    address2: z.string(),
    address3: z.string(),
    city: z.string(),
    state: z.string(),
    country: z.string(),
    zip: z.string().min(1, { message: 'Zip Code is required' }),
    email: z.string().min(1, { message: 'Email is required' }).email(),
    primaryPhone: z.string(),
    secondaryPhone: z.string(),
    primaryPhoneType: z.string(),
    secondaryPhoneType: z.string(),
    address_type: z.string(),
  });

  type ProfileFormSchemaPayload = z.infer<typeof ProfileFormSchema>;
  const { handleSubmit, control, formState, setError, setValue } = useForm<ProfileFormSchemaPayload>({
    resolver: zodResolver(ProfileFormSchema),
    mode: 'onChange',
  });

  const { errors, isValid } = formState;

  // Use Effects
  useEffect(() => {
    dispatch(setIsExistingAddressSelected(false));
  }, []);

  useEffect(() => {
    if (profileHolder?.id) {
      setIsEditMode(true);
      setFormValues(profileHolder);
      const gPlacesAddress = {
        name: profileHolder?.address?.address1,
        country: profileHolder?.address?.country,
        country_name: profileHolder?.address?.country_name,
        state: profileHolder?.address?.state,
        state_name: profileHolder?.address?.state_name,
        city: profileHolder?.address?.city,
        zip: profileHolder?.address?.zip,
      };
      setGPlacesAddressData(gPlacesAddress);
    }
  }, [profileHolder]);

  useEffect(() => {
    if (isEntity) {
      setProfileDetailsList(entityProfileList);
    } else {
      setProfileDetailsList(profileList);
    }
  }, [isEntity, entityProfileList, profileList]);

  useEffect(() => {
    if (isEntity && entityProfileList?.length === 0) {
      setProfileExists(true);
    }
  }, [isEntity, entityProfileList]);

  useEffect(() => {
    if (profileExists) {
      setValue('type', 'Primary', { shouldValidate: true });
    }
  }, [profileExists]);

  useEffect(() => {
    if (entityProfileList?.length > 0 || profileList?.length > 0) {
      dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    } else {
      dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[0].percent));
    }
  }, [entityProfileList, profileList]);

  useEffect(() => {
    if (emailNotVerified || entityEmailNotVerified) {
      setShowVerification(true);
    }
    const currentProfile = !isEntity ? addedProfile : entityAddedProfile;
    if (currentProfile && Object.keys(currentProfile)?.length > 0) {
      dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
      dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
    }
  }, [addedProfile, entityAddedProfile, emailNotVerified, entityEmailNotVerified, isEntity]);

  useEffect(() => {
    if (primaryEntered) {
      setIsPrimaryPhoneType(checkPrimaryConditions());
    } else {
      setIsPrimaryPhoneType(true);
    }
  }, [primaryEntered, primaryPhoneTypeSelected]);

  useEffect(() => {
    if (secondaryEntered) {
      setIsSecondaryPhoneType(checkSecondaryConditions());
    } else {
      setIsSecondaryPhoneType(true);
    }
  }, [secondaryEntered, secondaryPhoneTypeSeleceted]);

  useEffect(() => {
    const type = profileHolder?.id ? profileHolder?.address_type : ADDRESS_TYPES_KEYS.Residential;
    setAddressType(type);
  }, [hasAddressType, profileHolder]);

  // Methods
  const setAddressType = (addressType: string) => {
    setValue('address_type', addressType, { shouldValidate: true });
    setSelectedAddressType(addressType);
  };

  const setFormValues = (data: ProfileDetails) => {
    setValue('type', data?.profile_name, { shouldValidate: true });
    setValue('city', data?.address?.city);
    setValue('country', data?.address?.country_name);
    setValue('state', data?.address?.state_name);
    setValue('address1', data?.address?.address1, { shouldValidate: true });
    setValue('address2', data?.address?.address2 || '');
    setValue('address3', data?.address?.address3 || '');
    setValue('zip', data?.address?.zip, { shouldValidate: true });
    setValue('email', data?.profile_email?.email, { shouldValidate: true });
    setPhonesData(data?.phones);
  };

  const setPhonesData = (phoneDetails: PhoneType[]) => {
    if (phoneDetails?.length > 0) {
      const primaryPhoneDetails =
        phoneDetails?.find((item) => item?.category === PhoneCategory.PRIMARY) || ({} as PhoneType);
      const secondaryPhoneDetails =
        phoneDetails?.find((item) => item?.category === PhoneCategory.SECONDARY) || ({} as PhoneType);

      if (primaryPhoneDetails?.id) {
        setIsPrimaryPhoneType(true);
        setPrimaryPhoneTypeSelected(true);
        primaryPhoneHandler(primaryPhoneDetails?.phone);
        setPrimaryPhoneType(primaryPhoneDetails?.phone_type);
        setValue('primaryPhone', primaryPhoneDetails?.phone, { shouldValidate: true });
      }
      if (secondaryPhoneDetails?.id) {
        setIsSecondaryPhoneType(true);
        setSecondaryPhoneTypeSelected(true);
        secondaryPhoneHandler(secondaryPhoneDetails?.phone);
        setSecondaryPhoneType(secondaryPhoneDetails?.phone_type);
        setValue('secondaryPhone', secondaryPhoneDetails?.phone, { shouldValidate: true });
      }
    }
  };

  const onSubmit = (formData: ProfileFormSchemaPayload) => {
    const phones = [];
    if (primaryPhone !== '' && primaryPhone !== countryCode) {
      phones.push({
        phone_type: primaryPhoneType,
        phone: primaryPhone,
        category: PhoneCategory.PRIMARY,
      });
    }
    if (secondaryPhone !== '') {
      phones.push({
        phone_type: secondaryPhoneType,
        phone: secondaryPhone,
        category: PhoneCategory.SECONDARY,
      });
    }
    const addressData = isExistingAddressSelected
      ? { address_id: existingAddress?.address?.id }
      : {
          address: {
            type: formData.type,
            address1: formData.address1,
            address2: formData.address2,
            address3: formData.address3,
            city: gPlacesAddressData.city,
            state: gPlacesAddressData.state,
            state_name: gPlacesAddressData.state_name,
            country: gPlacesAddressData.country,
            country_name: gPlacesAddressData.country_name,
            zip: formData.zip,
          },
        };
    const requestPayload = {
      profile_name: formData.type,
      profile_email: formData.email,
      address_type: formData.address_type,
      ...addressData,
      phones,
    };
    saveProfileData(requestPayload, isEditMode);
  };

  const handleExistingAddressChange = (_event: React.SyntheticEvent, value: ProfileDetails) => {
    if (value && value?.address) {
      setExistingAddress(value);
      setValue('city', value?.address?.city);
      setValue('country', value?.address?.country_name);
      setValue('state', value?.address?.state_name);
      setValue('address1', value?.address?.address1, { shouldValidate: true });
      setValue('address2', value?.address?.address2 || '');
      setValue('address3', value?.address?.address3 || '');
      setValue('zip', value?.address?.zip, { shouldValidate: true });
      dispatch(setIsExistingAddressSelected(true));
    } else {
      setExistingAddress({} as ProfileDetails);
      setValue('country', '');
      setValue('state', '');
      setValue('city', '');
      setValue('address1', '', { shouldValidate: true });
      setValue('address2', '');
      setValue('address3', '');
      setValue('zip', '', { shouldValidate: true });
      setError('address1', { type: 'custom', message: '' });
      setError('zip', { type: 'custom', message: '' });
      dispatch(setIsExistingAddressSelected(false));
    }
  };

  const setAddressLine = (data: GPlacesAddressType) => {
    setPlacesDetails(data, 'line1');
  };

  const setZip = (data: GPlacesAddressType) => {
    setPlacesDetails(data, 'zip');
  };

  const setPlacesDetails = (data: GPlacesAddressType, fieldName: string) => {
    const address = getAddressData(data) || ({} as GPlacesAddressType);
    setGPlacesAddressData(address);
    if (fieldName === 'line1') {
      setValue('address1', data?.name, { shouldValidate: true });
    }
    setValue('country', address?.country_name, { shouldValidate: false });
    setValue('state', address?.state_name, { shouldValidate: false });
    setValue('city', address?.city, { shouldValidate: false });
    setValue('zip', address?.zip, { shouldValidate: true });
  };

  const checkPrimaryConditions = () => {
    return primaryEntered && !primaryPhoneTypeSelected ? false : true;
  };

  const checkSecondaryConditions = () => {
    return secondaryEntered && !secondaryPhoneTypeSeleceted ? false : true;
  };

  const handleChange = (event: any) => {
    if (event.target.name === PHONE_NUMBER_TYPE.PRIMARY_PHONE) {
      setPrimaryPhoneType(event.target.value);
      setPrimaryPhoneTypeSelected(true);
    } else {
      setSecondaryPhoneType(event.target.value);
      setSecondaryPhoneTypeSelected(true);
    }
  };

  const handleAddressTypeChange = (event: any) => {
    setSelectedAddressType(event?.target?.value);
    setValue('address_type', event.target.value, { shouldValidate: true });
  };

  const getCountryCode = (value: string) => {
    const extractedCode = value?.split(' ');
    return extractedCode[0];
  };

  const primaryPhoneHandler = (value: any) => {
    setCountryCode(getCountryCode(value));
    setPrimaryEntered(true);
    setPrimaryPhone(value);
  };

  const secondaryPhoneHandler = (value: any) => {
    setCountryCode(getCountryCode(value));
    setSecondaryEntered(true);
    setSecondaryPhone(value);
  };

  const goPreviousStep = () => {
    if (goToPreviousStep) {
      goToPreviousStep(0);
    }
  };

  const getHeaderTitle = () => {
    if (entityOnBoarding) {
      return t('entityStep2');
    } else if (isEditMode) {
      return t('editProfile');
    } else {
      return t('addProfile');
    }
  };

  // HTML
  return (
    <>
      {showVerification && !isEditMode ? (
        <EmailVerification />
      ) : (
        <>
          {loading && isExistingAddressSelected ? (
            <TLoader loading={loading} />
          ) : (
            <Box>
              <Box sx={{ p: 2 }} className="text-center text-large font-weight-semibold textColor-300">
                {getHeaderTitle()}
              </Box>
              <Divider />
              <Box>{children}</Box>
              <Box sx={{ p: 3 }}>
                <form>
                  <Controller
                    name="type"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TTextField
                        label="Profile Name"
                        variant="outlined"
                        fullWidth
                        disabled={profileExists || isEditMode}
                        sx={{ my: 1 }}
                        error={Boolean(errors.type)}
                        helperText={errors.type?.message}
                        {...field}
                      />
                    )}
                  />

                  <div className={hasAddressType && isEntity ? classes.hideAddressType : ''}>
                    <Controller
                      name="address_type"
                      defaultValue=""
                      control={control}
                      render={() => (
                        <FormControl sx={{ my: theme.spacing(3) }} className={classes.errorContainer}>
                          <TSelect
                            name="address_type"
                            id="address_type"
                            fullWidth
                            value={selectedAddressType}
                            options={ADDRESS_TYPES}
                            label="Address Type"
                            itemId="id"
                            itemValue="label"
                            onChange={handleAddressTypeChange}
                          />
                        </FormControl>
                      )}
                    />
                  </div>

                  <>
                    {profileDetailsList?.length > 0 && (
                      <TAutocomplete
                        value={existingAddress}
                        options={profileDetailsList}
                        disablePortal={true}
                        sx={{ my: 1 }}
                        onChange={handleExistingAddressChange}
                        getOptionLabel={(option) => option.profile_name || ''}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TTextField label="Saved Addresses (optional)" variant="outlined" {...params} />
                        )}
                      />
                    )}
                  </>
                  <Controller
                    name="address1"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TGPlaces
                        fieldId={'line1'}
                        placeholder="Address Line 1"
                        fieldType={[]}
                        label="Address Line 1"
                        variant="outlined"
                        fullWidth
                        sx={{ my: 1 }}
                        error={Boolean(errors.address1)}
                        helperText={errors.address1?.message}
                        disabled={isExistingAddressSelected}
                        onPlaceSelection={setAddressLine}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="address2"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TTextField
                        isOptional
                        label="Address Line 2"
                        variant="outlined"
                        fullWidth
                        sx={{ my: 1 }}
                        disabled={isExistingAddressSelected}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="address3"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TTextField
                        isOptional
                        label="Address Line 3"
                        variant="outlined"
                        fullWidth
                        sx={{ my: 1 }}
                        disabled={isExistingAddressSelected}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="zip"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TGPlaces
                        fieldId={'zip'}
                        fieldType={['postal_code']}
                        label="Zip"
                        variant="outlined"
                        placeholder="Zip"
                        fullWidth
                        sx={{ my: 1 }}
                        error={Boolean(errors.zip)}
                        helperText={errors.zip?.message}
                        disabled={isExistingAddressSelected}
                        onPlaceSelection={setZip}
                        {...field}
                      />
                    )}
                  />
                  {/* Address Country/State/City Fields in pre-filled and disabled mode */}
                  <Controller
                    name="country"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TTextField label="Country" variant="outlined" fullWidth sx={{ my: 1 }} disabled {...field} />
                    )}
                  />
                  <Controller
                    name="state"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TTextField label="State" variant="outlined" fullWidth sx={{ my: 1 }} disabled {...field} />
                    )}
                  />
                  <Controller
                    name="city"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TTextField label="City" variant="outlined" fullWidth sx={{ my: 1 }} disabled {...field} />
                    )}
                  />
                  <Controller
                    name="email"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TTextField
                        label="Email"
                        variant="outlined"
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                        fullWidth
                        sx={{ my: 1 }}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="primaryPhone"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <PhoneNumber
                        sx={{ my: theme.spacing(3) }}
                        isOptional
                        label="Primary Phone"
                        variant="outlined"
                        fullWidth
                        className="textFieldPhoneNumber"
                        {...field}
                        onChange={primaryPhoneHandler}
                      />
                    )}
                  />
                  <Controller
                    name="primaryPhoneType"
                    defaultValue=""
                    control={control}
                    render={() => (
                      <FormControl
                        sx={{ my: theme.spacing(3) }}
                        className={classes.errorContainer}
                        error={!checkPrimaryConditions()}
                      >
                        <TSelect
                          name={PHONE_NUMBER_TYPE.PRIMARY_PHONE}
                          id="primary_phone_type"
                          fullWidth
                          value={primaryPhoneType}
                          options={PHONE_TYPES}
                          label="Type"
                          itemId="id"
                          itemValue="label"
                          onChange={handleChange}
                        />
                        {primaryEntered
                          ? !primaryPhoneTypeSelected && <FormHelperText>Phone type is required</FormHelperText>
                          : ''}
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="secondaryPhone"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <PhoneNumber
                        sx={{ my: theme.spacing(3) }}
                        isOptional
                        label="Secondary Phone"
                        variant="outlined"
                        fullWidth
                        className="textFieldPhoneNumber"
                        {...field}
                        onChange={secondaryPhoneHandler}
                      />
                    )}
                  />
                  <Controller
                    name="secondaryPhoneType"
                    defaultValue=""
                    control={control}
                    render={() => (
                      <FormControl
                        sx={{ my: theme.spacing(3) }}
                        className={classes.errorContainer}
                        error={!checkSecondaryConditions()}
                      >
                        <TSelect
                          id="secondary_phone_type"
                          name={PHONE_NUMBER_TYPE.SECONDARY_PHONE}
                          value={secondaryPhoneType}
                          options={PHONE_TYPES}
                          fullWidth
                          label="Type"
                          itemId="id"
                          itemValue="label"
                          onChange={handleChange}
                        />
                        {secondaryEntered
                          ? !secondaryPhoneTypeSeleceted && <FormHelperText>Phone type is required</FormHelperText>
                          : ''}
                      </FormControl>
                    )}
                  />
                  <Box className="flex-column-center" sx={{ mt: 4 }}>
                    <TButton
                      sx={{ mb: 2 }}
                      btnText={t('save')}
                      variant="contained"
                      btnWidthSize="button-w-240"
                      disabled={
                        !isValid || Object?.keys(errors)?.length !== 0 || !isPrimaryPhoneType || !isSecondaryPhoneType
                      }
                      onClick={handleSubmit(onSubmit)}
                    />
                    {hasStepButton && (
                      <>
                        <TButton btnText={'Go Back'} variant="text" color="primary" onClick={goPreviousStep} />
                      </>
                    )}
                  </Box>
                </form>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ProfileForm;
