import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { FilePreviewType, TButton, TMultipleCapture } from '../myde-react-components';
import PreviewFileByType from './PreviewFileByType';
import { ClickHandlerType } from '../../types/commonTypes';

interface CaptureMultipleProps {
  fileName?: string;
  setShowMultipleCapture: ClickHandlerType;
  setImages?: (images: FilePreviewType[]) => void;
  setMultipleFileData?: (file: FilePreviewType[]) => void;
}

const CaptureMultiple = ({ setMultipleFileData, setShowMultipleCapture }: CaptureMultipleProps) => {
  //state
  const [files, setFiles] = useState([] as FilePreviewType[]);
  const [buttonDisable, setButtonDisable] = useState(true);

  //useEffects
  useEffect(() => {
    if (files && files?.length > 0) {
      setButtonDisable(false);
    }
  }, [files]);

  const setCapturedImages = (images: FilePreviewType[]) => {
    setFiles(images);
  };

  const handleAddButton = async () => {
    setShowMultipleCapture(false);
    setMultipleFileData && setMultipleFileData(files);
  };

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Box>
          <TMultipleCapture setImages={setCapturedImages} />
        </Box>
        <Box>
          <PreviewFileByType files={files} />
        </Box>
        <Box sx={{ pt: 4, mx: 5, pb: 6 }}>
          <TButton btnText="Add" fullWidth variant="contained" onClick={handleAddButton} disabled={buttonDisable} />
        </Box>
      </Box>
    </>
  );
};

export default CaptureMultiple;
