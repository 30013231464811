import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TButton, TPaper } from '../../components/myde-react-components';
import * as ROUTES from '../../constants/routes';
import {
  resendVerifyLinkWithTokenHandler,
  selectDashboardDetails,
} from '../../redux/feature/verifyEmail/verifyEmailSlice';
import AuthLayout from '../../layout/AuthLayout';
import clsx from 'clsx';
import { useRouter } from '../../providers/custom-router-provider';
import { useTheme } from '../../providers/custom-theme-provider';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import resendEmailBgImage from '../../../src/common/assets/check-email-bg-image.svg';
import ooppsImage from '../../../src/common/assets/oopps-img.svg';
export interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  mailIconColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const VerifyEmailExpired = () => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { emailAlreadyVerified } = useSelector(selectDashboardDetails);

  const navigateTo = (value: boolean) => {
    if (value) {
      routeTo(ROUTES.LOGIN);
    } else {
      reVerifyEmail();
    }
  };

  const reVerifyEmail = () => {
    dispatch(resendVerifyLinkWithTokenHandler());
  };

  const descriptionData = !emailAlreadyVerified
    ? {
        description1: t('expiredEmail1'),
        description11: t('expiredEmail11'),
        description2: t('expiredEmail2'),
      }
    : {
        description1: t('verifiedEmail1'),
        description2: t('verifiedEmail2'),
      };

  return (
    <>
      <AuthLayout>
        <TPaper sx={{ p: 3 }}>
          <>
            <Box className="flex-column-center">
              <Box sx={{ mb: 2 }}>
                {!emailAlreadyVerified ? (
                  <Box sx={{ mt: 2, mb: 4 }} className="flex-basic-center">
                    <img className="almostThereSizes" src={ooppsImage} alt="ooppsImage" />
                  </Box>
                ) : (
                  <Box sx={{ mt: 2 }} className="flex-basic-center">
                    <Box className={clsx(classes.imgContainer, 'p-relative')} sx={{ mb: 2 }}>
                      <img src={resendEmailBgImage} alt="resendEmailBgImage" className="w-100" />
                      <MarkEmailReadOutlinedIcon className={clsx(classes.mailIconColor, 'mailIcon')} />
                    </Box>
                  </Box>
                )}
              </Box>
              {!emailAlreadyVerified ? (
                <Box sx={{ mb: 2 }} className="text-h2 font-weight-semibold textColor-200">
                  Oops
                </Box>
              ) : (
                <Box sx={{ mb: 2 }} className="text-h2 font-weight-semibold textColor-200">
                  Email already verified
                </Box>
              )}
              <Box className="text-medium textColor-200 text-center">{descriptionData.description1}</Box>
              <Box className="text-medium textColor-200 text-center" sx={{ mb: 2 }}>
                {descriptionData.description11}
              </Box>
              <Box className="text-medium textColor-200 text-center" sx={{ mb: 4 }}>
                {descriptionData.description2}
              </Box>
              <Box sx={{ mb: 1 }} className="flex-basic-center">
                <TButton
                  btnWidthSize="button-w-240"
                  onClick={() => navigateTo(emailAlreadyVerified)}
                  variant="contained"
                  btnText={!emailAlreadyVerified ? 'Resend Email' : 'Sign In'}
                />
              </Box>
            </Box>
          </>
        </TPaper>
      </AuthLayout>
    </>
  );
};

export default VerifyEmailExpired;
