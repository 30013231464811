import * as React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { colorPalette } from '../../..';
import { getInitials, hexToRgbA } from '../../utils/commonMethods';
import { useTheme } from '../../../../../providers/custom-theme-provider';

interface CommonAvatarProps {
  imageUrl: string;
  avatarText?: string;
  iconSize?: string;
  textSize?: string;
  icon?: string;
}
export interface StyleProps {
  iconSize?: string;
  textSize?: string;
  currentThemes: Theme;
  icon?: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  iconContainer: {
    position: 'relative',
    width: ({ iconSize }) => (iconSize ? iconSize : '2.4vw'),
    height: ({ iconSize }) => (iconSize ? iconSize : '5.12vh'),
    lineHeight: ({ iconSize }) => (iconSize ? iconSize : '5.12vh'),
    borderRadius: '50%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed',
    borderColor: ({ currentThemes, icon }) =>
      icon ? hexToRgbA(`${currentThemes.palette.primary.main}`, 0.5) : colorPalette.iconColor.main,
    backgroundColor: ({ currentThemes, icon }) =>
      icon ? hexToRgbA(`${currentThemes.palette.primary.main}`, 0.03) : colorPalette.iconColor.main,
  },
}));

export const CommonAvatar = ({
  imageUrl,
  avatarText = '',
  icon = '',
  iconSize = '2.4vw',
  textSize = '0.75rem',
}: CommonAvatarProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
    iconSize,
    textSize,
    icon,
  };
  const classes = useStyles(styleProps);

  return (
    <>
      <Box>
        {!!imageUrl ? (
          <Box>
            <img
              src={`${imageUrl}`}
              loading="lazy"
              className={clsx(classes.iconContainer, 'text-small font-weight-medium avatarBox')}
              alt={avatarText}
            />
          </Box>
        ) : (
          <>
            {!!icon ? (
              <Box className={clsx(classes.iconContainer, `${icon}`)}></Box>
            ) : (
              <Box className={clsx(classes.iconContainer, 'text-medium font-weight-medium avatarBox')}>
                <span className={classes.avatarText} color="primary">
                  {getInitials(avatarText)}
                </span>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default CommonAvatar;
