import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { retriveProfile, addNewProfile, retriveProfileById } from '../../../api/idProcesses';
import {
  deleteSpecificProfile,
  editSpecificProfile,
  getProfile,
  updateProfile,
  uploadAvatar,
} from '../../../api/profile';
import { AddedProfile, ProfileData, ProfileDetails, ProfileFormDataType } from '../../../types/profileTypes';
import { VERIFICATION_STATUS } from '../../../constants/constants';

type ProfileState = {
  profileData: ProfileData;
  loading: boolean;
  error: boolean;
  errorObj: any;
  profileUrl: string;
  profileList: ProfileDetails[];
  emailNotVerified: boolean;
  addedProfile: AddedProfile;
  profileHolder: ProfileDetails;
  isSkip: boolean;
  isPasswordUpdated: boolean;
  isExistingAddressSelected: boolean;
};

const initialState: ProfileState = {
  profileData: <ProfileData>{},
  loading: false,
  error: false,
  errorObj: <any>{},
  profileUrl: '',
  profileList: [],
  emailNotVerified: false,
  addedProfile: <AddedProfile>{},
  profileHolder: <ProfileDetails>{},
  isSkip: false,
  isPasswordUpdated: false,
  isExistingAddressSelected: false,
};

export const selectProfile = ({ profile }: RootState) => ({
  profileData: profile.profileData,
  profileUrl: profile.profileUrl,
  loading: profile.loading,
  profileList: profile.profileList,
  emailNotVerified: profile.emailNotVerified,
  addedProfile: profile.addedProfile,
  profileHolder: profile.profileHolder,
  isSkip: profile.isSkip,
  isPasswordUpdated: profile.isPasswordUpdated,
  isExistingAddressSelected: profile.isExistingAddressSelected,
});

export const getProfileDetails = createAsyncThunk('profile/getProfileDetails', async () => {
  return await getProfile();
});

export const updateProfileDetails = createAsyncThunk('profile/updateProfileDetails', async (formData: object) => {
  return await updateProfile(formData);
});

export const getProfileDetailsList = createAsyncThunk('profile/getProfileDetailsList', async (params: object) => {
  return await retriveProfile(params);
});

export const getProfileDetailsById = createAsyncThunk('profile/getProfileDetailsById', async (id: string) => {
  return await retriveProfileById(id);
});

export const addProfileDetails = createAsyncThunk('profile/addProfileDetails', async (formData: object) => {
  return await addNewProfile(formData);
});

export const deleteProfileById = createAsyncThunk('profile/deleteProfileById', async (profileId: string) => {
  return await deleteSpecificProfile(profileId);
});

export const editProfileById = createAsyncThunk(
  'profile/editProfileById',
  async (formData: { id: string; requestPayload: ProfileFormDataType }) => {
    return await editSpecificProfile(formData?.id, formData?.requestPayload);
  },
);

export const uploadAvatarFile = createAsyncThunk('profile/uploadAvatarFile', async (file: any) => {
  return await uploadAvatar(file);
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.profileData = action.payload;
    },
    resetData: (state) => {
      state.profileData = <ProfileData>{};
    },
    resetAddedProfile: (state) => {
      state.addedProfile = <AddedProfile>{};
    },
    resetEmailNotVerified: (state) => {
      state.emailNotVerified = false;
    },
    setIsSkip: (state) => {
      state.isSkip = true;
    },
    resetIsSkip: (state) => {
      state.isSkip = false;
    },
    resetIsPasswordUpdated: (state) => {
      state.isPasswordUpdated = false;
    },
    setIsExistingAddressSelected: (state, action) => {
      state.isExistingAddressSelected = action.payload;
    },
    setProfileHolder: (state, action) => {
      state.profileHolder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProfileDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.profileData = action.payload;
          state.profileUrl = action.payload.avatar_url;
        } else {
          state.error = true;
        }
      })
      .addCase(getProfileDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateProfileDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateProfileDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.profileData = action.payload;
          state.profileUrl = action.payload.avatar_url;
        } else {
          state.error = true;
        }
      })
      .addCase(updateProfileDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getProfileDetailsList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProfileDetailsList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.profileList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getProfileDetailsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(addProfileDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addProfileDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.addedProfile = action.payload;
          if (action.payload?.profile_email?.verification_status !== VERIFICATION_STATUS.VERIFIED) {
            state.emailNotVerified = true;
          }
        } else {
          state.error = true;
        }
      })
      .addCase(addProfileDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(uploadAvatarFile.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(uploadAvatarFile.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.profileUrl = action.payload.url;
        } else {
          state.error = true;
        }
      })
      .addCase(uploadAvatarFile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getProfileDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProfileDetailsById.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.profileHolder = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getProfileDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setData,
  resetData,
  resetEmailNotVerified,
  resetAddedProfile,
  setIsSkip,
  resetIsSkip,
  resetIsPasswordUpdated,
  setIsExistingAddressSelected,
  setProfileHolder,
} = profileSlice.actions;

export default profileSlice.reducer;
