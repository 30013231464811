import React, { useEffect, useState } from 'react';
import { Grid, Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import UploadOrCapturePhoto from '../../../common/UploadOrCapturePhoto';
import {
  TPaper,
  ACCEPTED_IMAGE_MIME_TYPES,
  SUPPORTED_IMAGE_TYPES,
  FILE_SIZE,
  TButton,
  FilePreviewType,
  TLoader,
} from '../../../myde-react-components';
import {
  clearIdProcessData,
  clearIdProcessMultipleData,
  selectSelectedIdProcessDetails,
  setMultipleUploadedImage,
  setMultipleUploadedPreviewImage,
  setUploadedDocId,
  setUploadedDocIdMultiple,
  setUploadedImage,
  setUploadedImageToStore,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { FileType } from '../../../../types/documentTypes';
import { postUploadedDoc } from '../../../../api/idProcesses';
import CaptureMultiple from '../../../common/CaptureMultiple';
import { PhotoCaptureInstructionProps } from '../../../../types/commonTypes';

interface UploadDocProps {
  title?: string;
  showTitleText?: string;
  isMultiple: boolean;
  handleNext: () => void;
  isEntity?: boolean;
  previewFiles: FileType[];
  buttonText?: string;
  isAllowClearData?: boolean;
  captureInstruction?: PhotoCaptureInstructionProps;
  uploadInstruction?: PhotoCaptureInstructionProps;
}

const UploadDoc = ({
  title,
  showTitleText = '',
  isMultiple,
  handleNext,
  previewFiles,
  buttonText = 'Save',
  isAllowClearData = true,
  captureInstruction,
  uploadInstruction,
}: UploadDocProps) => {
  // Constants
  const dispatch = useDispatch();
  const { t } = useTranslation(['incomeVerification', 'common']);

  // Redux Values
  const { uploadedDocId, selectedEntityIdProcessDetails, uploadedImage, uploadedDocIdMultiple, uploadedImageMultiple } =
    useSelector(selectSelectedIdProcessDetails);

  // State Values
  const [preview, setPreview] = useState([] as FilePreviewType[]);
  const [showMultipleCapture, setMultipleCaptureFlag] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Use Effects
  useEffect(() => {
    if (selectedEntityIdProcessDetails?.data?.document_id_list?.length > 0) {
      dispatch(setUploadedDocId(selectedEntityIdProcessDetails?.data?.document_id_list));
    }
  }, [selectedEntityIdProcessDetails]);

  useEffect(() => {
    if (isAllowClearData) {
      dispatch(clearIdProcessData());
      dispatch(clearIdProcessMultipleData());
    }
  }, []);

  useEffect(() => {
    if (previewFiles?.length > 0) {
      const data: FilePreviewType[] = [];
      previewFiles?.forEach((item: FileType) => {
        data?.push({
          id: item?.doc_id || '',
          name: item?.doc_name || '',
          type: item?.mime_type || '',
          preview: item?.signed_url || '',
          size: parseFloat(item?.size_in_kb),
        });
      });
      setPreview(data);
    }
  }, [previewFiles]);

  useEffect(() => {
    if (Object.keys(uploadedImage)?.length > 0) {
      const docIds = [];
      docIds.push(uploadedImage?.doc_id);
      dispatch(setUploadedDocId(docIds));
    }
  }, [uploadedImage]);
  useEffect(() => {
    if (uploadedImageMultiple?.length > 0) {
      setPreviewFiles(uploadedImageMultiple);
    }
  }, [uploadedImageMultiple]);

  // Methods
  const getDisabledState = () => {
    let result = true;
    if (uploadedDocId?.length > 0 || uploadedDocIdMultiple?.length > 0) {
      result = false;
    }
    return result;
  };

  const setImage = (image: any) => {
    dispatch(setUploadedImage(image));
    dispatch(setUploadedImageToStore(image));
    setPreviewFiles([image]);
  };

  const uploadFiles = async (files: any[], oldFiles: any[]) => {
    let documentIdList: string[] = oldFiles?.map((file) => file?.id || file?.doc_id) || [];
    let documentList: FilePreviewType[] = oldFiles || [];
    if (files && files?.length > 0) {
      setIsUploading(true);
      await Promise.all(
        files?.map(async (file) => {
          const result = await postUploadedDoc(file);
          result.preview = file?.preview;
          documentIdList = Object.assign([], documentIdList);
          documentList = Object.assign([], documentList);
          documentIdList.push(result?.doc_id);
          documentList.push(result);
        }),
      );
      dispatch(setMultipleUploadedImage(documentList));
      dispatch(setUploadedDocIdMultiple(documentIdList));
    } else {
      dispatch(setMultipleUploadedImage(documentList));
      dispatch(setUploadedDocIdMultiple(documentIdList));
    }
  };

  const setPreviewFiles = (files: any[]) => {
    const result: FilePreviewType[] = files?.map((file: any) => {
      return {
        name: file?.name || file?.doc_name,
        type: file?.type || file?.mime_type,
        preview: file?.preview || file?.signed_url,
        size: file?.size || file?.size_in_kb,
        id: file?.id || file?.doc_id,
      };
    });
    setPreview(result);
    dispatch(setMultipleUploadedPreviewImage(result));
    setIsUploading(false);
  };
  const toggleMultipleCapture = () => {
    setMultipleCaptureFlag(!showMultipleCapture);
  };
  const setMultipleCaptureData = (files: FilePreviewType[]) => {
    const capturedFiles = files?.map((file: any) => {
      return file?.file;
    });

    uploadFiles(capturedFiles, preview);
  };

  // HTML
  return (
    <TPaper>
      <TLoader loading={isUploading} />
      <Grid container>
        <Grid item xs={12}>
          <Box className="text-center textColor-300 text-large font-weight-semibold" sx={{ p: 2 }}>
            {title || t('step1Header')}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 1 }} />
        </Grid>
        {!showMultipleCapture ? (
          <>
            <Grid item xs={12} sx={{ p: 3 }}>
              <UploadOrCapturePhoto
                acceptedFileFormat={ACCEPTED_IMAGE_MIME_TYPES}
                supportedFileFormat={SUPPORTED_IMAGE_TYPES}
                maxFileSize={FILE_SIZE.IMAGE_FILE_SIZE}
                isMultiple={isMultiple}
                setData={setImage}
                setMultipleFileData={uploadFiles}
                uploadedFile={preview}
                showTitleText={showTitleText}
                setShowMultipleCapture={toggleMultipleCapture}
                captureInstruction={captureInstruction}
                uploadInstruction={uploadInstruction}
              />
            </Grid>
            <Grid item xs={12} sx={{ p: 3 }}>
              <Box className="flex-basic-center">
                <TButton
                  btnText={buttonText}
                  variant={'contained'}
                  btnWidthSize={'button-w-240'}
                  disabled={getDisabledState()}
                  onClick={handleNext}
                />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sx={{ p: 3 }}>
            <CaptureMultiple
              setMultipleFileData={setMultipleCaptureData}
              setShowMultipleCapture={toggleMultipleCapture}
            />
          </Grid>
        )}
      </Grid>
    </TPaper>
  );
};

export default UploadDoc;
