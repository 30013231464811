import { CommunicationResponseType, FilterType } from './../types/messageTypes';
import { apiGet, apiPatch } from './axios';

export const getApplications = async (filters?: object) => {
  const res = await apiGet(`/my-merchant`, { params: filters });
  return res.data;
};

export const getApplicationCommunication = async (applicationId: string, filters?: FilterType) => {
  const res = await apiGet(`/application/${applicationId}/communication`, { params: filters });
  return res.data;
};

export const getApplicationCommunicationById = async (formData: { applicationId: string; communicationId: string }) => {
  const res = await apiGet(`/application/${formData.applicationId}/communication/${formData.communicationId}`);
  return res.data;
};

export const respondApplicationCommunication = async (
  applicationId: string,
  communicationId: string,
  data: CommunicationResponseType,
) => {
  const res = await apiPatch(`/application/${applicationId}/communication/${communicationId}`, data);
  return res.data;
};

export const getInboxCommunications = async (filters: any) => {
  const res = await apiGet(`/inbox`, { params: filters });
  return res.data;
};

export const getInboxCommunicationsbyId = async (communicationId: string, payload: object) => {
  const res = await apiGet(`/inbox/${communicationId}`, { params: payload });
  return res.data;
};
