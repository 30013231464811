import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, InputAdornment, List, ListItem, ListItemText, Theme } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import ContainerCard from '../../../components/common/ContainerCard';
import { TLoader, theme, TButton, TTextField, TAlertDialog } from '../../../components/myde-react-components';
import {
  customIdProcessDetails,
  getEntityCustomIdProcessDetail,
  getEntityCustomIdProcessList,
  getIdProcessCategoriesList,
  getSpecificIdProcessDetails,
  getSupplementalIdProcessDetails,
  setSelectedCategory,
} from '../../../redux/feature/idProcess/customIdProcessSlice';
import { useTheme } from '../../../providers/custom-theme-provider';
import * as ROUTES from '../../../constants/routes';
import { DocumentListingType, IdProcessResultSet, SupplementalIdProcessResults } from '../../../types/dashboardTypes';
import { useRouter } from '../../../providers/custom-router-provider';
import DocumentListing from './DocumentListing';
import useQuery from '../../../hooks/useQuery';
import { formatDate, hexToRgbA } from '../../../utils/utils';
import { editCustomIdProcess } from '../../../api/dashboard';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  btnStyle: {
    background: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  listContainer: {
    border: ({ currentThemes }) => `1px solid ${currentThemes.palette.primary.main}`,
  },
  listItem: {
    '&.MuiListItem-root': {
      padding: '0px !important',
      '& .MuiTypography-root': {
        padding: '0px!important',
      },
    },
  },
  selectedItem: {
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
}));

const CategoryFolder = () => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { routeTo, goBack } = useRouter();
  const query = useQuery();
  const folderName = query.get('folder') || '';
  const isEntity = query.get('isEntity') === 'true';
  const entityUid = useQuery().get('entityUid');

  // Redux Values
  const { loading, supplementalIdProcessList, idProcessCategoriesList } = useSelector(customIdProcessDetails);
  const { selectedEntity } = useSelector(selectEntity);

  //state values
  const [categoryDocs, setCategoryDocs] = useState([] as DocumentListingType[]);
  const [categoryDocsToSave, setCategoryDocsToSave] = useState([] as DocumentListingType[]);
  const [categoryList, setCategoryList] = useState([] as IdProcessResultSet[]);
  const [searchValue, setSearchValue] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('');
  const [selectedProcesses, setSelectedProcesses] = useState([] as string[]);
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const [showMoceDocsDialog, setShowMoveDocsDialog] = useState(false);
  const [selectedFormCategory, setSelectedFormCategory] = useState({} as IdProcessResultSet);
  //useEffects
  useEffect(() => {
    if (supplementalIdProcessList?.length > 0) {
      const data = supplementalIdProcessList?.map((item) => {
        return {
          id: item.id,
          title: item?.process_name,
          subtitle: `${formatDate(item?.created_on)}`,
          text: item?.process_data?.process_description,
          documentList: item?.process_data.document_id_list || [],
          icon: 'icon-document',
          show_action: false,
          is_checked: false,
        };
      });
      setCategoryDocs(data);
    } else {
      setCategoryDocs([] as DocumentListingType[]);
    }
  }, [supplementalIdProcessList]);

  useEffect(() => {
    if (entityUid && folderName) {
      const data = getIdProcessPayload();
      dispatch(getEntityCustomIdProcessList(data));
    } else {
      const data = getIdProcessPayload();
      dispatch(getSupplementalIdProcessDetails(data));
    }
  }, []);

  useEffect(() => {
    setCategoryList(idProcessCategoriesList);
  }, [idProcessCategoriesList]);

  useEffect(() => {
    setSelectedFolder(folderName);
  }, [folderName]);

  //Methods

  const getIdProcessPayload = () => {
    let data: any = {};
    if (isEntity) {
      data = {
        entityUid: entityUid,
        filters: {
          ordering: '-created_on',
          process_category: folderName,
        },
      };
    } else {
      data = {
        ordering: '-created_on',
        process_category: folderName,
      };
    }
    return data;
  };

  const handleAddClick = () => {
    dispatch(setSelectedCategory({} as SupplementalIdProcessResults));
    if (isEntity) {
      const stateObj = { isEntity: true };
      routeTo(ROUTES.INDEX, true, 'Custom ID', stateObj);
    } else {
      routeTo(ROUTES.INDEX, true, 'Custom ID');
    }
  };

  const handleDocumentClick = async (idProcess: DocumentListingType) => {
    if (isEntity) {
      const data = {
        entityUid: selectedEntity?.trellis_uid,
        uuid: idProcess?.id,
      };
      await dispatch(getEntityCustomIdProcessDetail(data));
      const stateObj = { isEntity: isEntity };
      routeTo(ROUTES.CUSTOM_CATEGORY_DOCUMENTS, true, idProcess?.title, stateObj);
    } else {
      const data = {
        id: idProcess?.id,
      };
      await dispatch(getSpecificIdProcessDetails(data));
      routeTo(ROUTES.CUSTOM_CATEGORY_DOCUMENTS, true, idProcess?.title);
    }
  };

  const getData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      ordering: '-created_on',
      process_category: selectedFolder,
      process_name: event?.target?.value,
    };
    return data;
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event?.target?.value);
    const data = getData(event);
    if (isEntity) {
      const payload = {
        entityUid: selectedEntity?.trellis_uid,
        filters: data,
      };
      dispatch(getEntityCustomIdProcessList(payload));
    } else {
      dispatch(getSupplementalIdProcessDetails(data));
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCategory(event?.target?.value);
    dispatch(
      getIdProcessCategoriesList({
        category_name: event?.target?.value,
      }),
    );
  };

  const setSelectedData = (data: string[]) => {
    const result = categoryDocs?.filter((item) => data?.includes(item?.id));
    setCategoryDocsToSave(result);
    setSelectedProcesses(data);
  };

  const selectClickHandler = () => {
    setIsButtonSelected(!isButtonSelected);
    const data = categoryDocs?.map((item) => {
      return {
        ...item,
        show_action: !item?.show_action,
      };
    });
    setCategoryDocs(data);
  };

  const updateCustomIdProcess = () => {
    categoryDocsToSave?.forEach(async (item) => {
      const payload = {
        process_name: item?.title,
        process_data: {
          process_category: selectedFormCategory?.category_name,
          process_description: item?.text,
          document_id_list: item?.documentList,
        },
      };
      await editCustomIdProcess(item?.id, payload);
    });
    if (isEntity) {
      dispatch(
        getEntityCustomIdProcessList({
          entityUid: selectedEntity?.trellis_uid,
          filters: { ordering: '-created_on' },
        }),
      );
    } else {
      const params = {
        ordering: '-created_on',
      };
      dispatch(getSupplementalIdProcessDetails(params));
    }
    goBack();
  };

  const onListClickHandler = (item: IdProcessResultSet) => {
    setSelectedFormCategory(item);
  };

  const closeDialog = () => {
    setShowMoveDocsDialog(false);
    setIsButtonSelected(false);
  };

  const renderActions = () => {
    return (
      <Box className="flex-basic-space-around">
        <TButton
          icon={<Box className="icon-arrow-left-right" sx={{ pr: 1 }} />}
          btnText={'Move'}
          variant={'text'}
          disabled={selectedProcesses?.length === 0}
          onClick={() => setShowMoveDocsDialog(true)}
        />
      </Box>
    );
  };

  const renderRequirements = () => {
    return (
      <>
        <Box sx={{ py: theme.spacing(5), px: 3 }}>
          {categoryDocs?.length > 0 && (
            <Box sx={{ mb: 1 }} className="flex-basic-space-between">
              <Box sx={{ mr: 1 }} className="flex-basic-start text-large font-weight-semibold textColor-300">
                {t('filesText')}
              </Box>
              <TButton btnText={isButtonSelected ? t('deSelect') : t('select')} onClick={selectClickHandler} />
            </Box>
          )}
          <Box>
            <TTextField
              variant="outlined"
              placeholder="Search Title"
              value={searchValue}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="icon-search textColor-400" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {categoryDocs?.length > 0 ? (
            <DocumentListing data={categoryDocs} handleClick={handleDocumentClick} setSelectedData={setSelectedData} />
          ) : (
            <>
              <Box sx={{ pt: 3, pb: 4 }} className="icon-size-72 text-center textColor-400 icon-letter-evidence"></Box>
              <Box className="text-center textColor-300 font-weight-semibold text-h3">{t('noCustomDocument')}</Box>
              {categoryDocs?.length <= 0 && (
                <Box sx={{ my: 4 }} className="flex-basic-center">
                  <TButton btnText={'Add'} variant="contained" btnWidthSize="button-w-240" onClick={handleAddClick} />
                </Box>
              )}
            </>
          )}
        </Box>
        {categoryDocs?.length >= 1 && (
          <Box sx={{ p: 3 }} className="floating-wrapper">
            <Box className="fixed-wrapper">
              <Box
                className={clsx('floating-btn flex-basic-center cursorPointer', classes.btnStyle)}
                onClick={handleAddClick}
              >
                <span className="icon-add"></span>
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <TLoader loading={loading} />
      {categoryDocs?.length > 0 ? (
        <ContainerCard content={renderRequirements()} hasBottomShadow={true}>
          {renderActions()}
        </ContainerCard>
      ) : (
        <ContainerCard content={renderRequirements()} />
      )}
      <TAlertDialog
        showDialog={showMoceDocsDialog}
        title={'Move'}
        content={'Please select the category you want to move the selected document(s) to'}
        yesBtnTitle={'Move'}
        noBtnTitle={'Cancel'}
        isButtonDisabled={!selectedFormCategory?.id}
        extraContent={
          <Box>
            <TTextField
              variant="outlined"
              placeholder="Search Category"
              value={searchCategory}
              onChange={handleCategoryChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="icon-search textColor-400" />
                  </InputAdornment>
                ),
              }}
            />
            <Box className={clsx(classes.listContainer, 'categoryListContainer')} sx={{ mt: theme.spacing(4) }}>
              {categoryList?.length > 0 ? (
                <List>
                  {categoryList?.map((item) => (
                    <ListItem
                      className={clsx(classes.listItem, 'cursorPointer')}
                      key={item.id}
                      onClick={() => onListClickHandler(item)}
                    >
                      <ListItemText
                        sx={{ p: 1 }}
                        className={clsx({ [classes.selectedItem]: item?.id === selectedFormCategory?.id })}
                      >
                        <Box
                          className={clsx({
                            ['text-small']: item?.id !== selectedFormCategory?.id,
                            ['text-medium font-weight-bold']: item?.id === selectedFormCategory?.id,
                            ['text-break-word']: true,
                          })}
                          sx={{ pl: 1 }}
                        >
                          {item?.category_name}
                        </Box>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box className="text-center text-small" sx={{ my: 2 }}>
                  {t('noCategoriesLabel')}
                </Box>
              )}
            </Box>
          </Box>
        }
        onCancel={closeDialog}
        onConfirm={updateCustomIdProcess}
      />
    </>
  );
};

export default CategoryFolder;
