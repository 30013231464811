import React, { useEffect, useState } from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationsResultType } from './notificationsType';
import { TBadge, TButton, theme } from '../../..';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { hexToRgbA, dateFrom, getDateInShort } from '../../utils/commonMethods';
import { BADGE_NAME } from '../../../../myde-react-components/src/constants/constants';

interface NotificationsListingProps {
  data: NotificationsResultType[];
  handleNotificationClick: (notification: NotificationsResultType, tab: string) => void;
  handleMarkAsReadClick: (data: string[], tab: string) => void;
  tab: string;
  fetchMoreData?: (tab: string) => void;
  hasMore?: boolean;
  children?: any;
  heightClass: string;
  unreadCount: number;
}

interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  primaryColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  iconBorder: {
    borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    borderRadius: '50%',
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.2),
  },
  entityIcon: {
    borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    borderRadius: '20px',
    width: '40px',
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.2),
    '&.MuiBox-root': {
      padding: '0px 5px !important',
    },
  },
  unreadNotification: {
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.1),
  },
  notificationList: {
    '&.MuiPaper-root': {
      padding: '0px 0px !important',
    },
    position: 'absolute',
    margin: '0px 0px 0px -1rem',
  },
  merchantLogo: {
    maxWidth: '37px',
  },
});

const NotificationsListing = ({
  data,
  handleNotificationClick,
  handleMarkAsReadClick,
  tab,
  fetchMoreData,
  hasMore = false,
  children = <></>,
  heightClass,
  unreadCount,
}: NotificationsListingProps) => {
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);

  //state variables
  const [listData, setListData] = useState([] as NotificationsResultType[]);
  const [markAsReadId, setMarkAsReadId] = useState([] as string[]);
  const [disableMarkAsRead, setDisableMarkAsRead] = useState(true);

  useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);

  useEffect(() => {
    if (listData?.length > 0) {
      const data = [] as string[];
      listData?.map((item: NotificationsResultType) => {
        data?.push(item?.id);
      });
      setMarkAsReadId(data);
    }
  }, [listData]);

  useEffect(() => {
    if (unreadCount > 0) {
      setDisableMarkAsRead(false);
    } else {
      setDisableMarkAsRead(true);
    }
  }, [unreadCount]);

  //methods
  const getDate = (dateTime: any) => {
    const date = dateFrom(dateTime);
    const result = getDateInShort(date);
    return result;
  };

  const fetchData = () => {
    if (fetchMoreData) {
      fetchMoreData(tab);
    }
  };

  return (
    <>
      {children}
      <Box className={clsx(classes.notificationList, 'w-100')}>
        <Box className="flex-basic-end">
          {listData?.length > 0 && (
            <Box className="flex-basic-start">
              <Box>
                <TButton
                  btnText="Mark all as read"
                  variant="text"
                  onClick={() => handleMarkAsReadClick(markAsReadId, tab)}
                  disabled={disableMarkAsRead}
                />
              </Box>
            </Box>
          )}
        </Box>
        {listData?.length > 0 ? (
          <Box id="scrollableDiv">
            <InfiniteScroll
              height={heightClass}
              dataLength={listData?.length}
              next={fetchData}
              hasMore={hasMore}
              loader={<></>}
              endMessage={<></>}
              className={clsx('commonNotificationScroll', heightClass)}
              scrollableTarget="scrollableDiv"
            >
              <Box>
                {listData?.map((item: NotificationsResultType, index) => {
                  return (
                    <Box key={index} className="cursorPointer">
                      <Box
                        sx={{ py: 3, pr: 2, pl: theme.spacing(2) }}
                        className={!item?.is_read ? classes.unreadNotification : ''}
                      >
                        <Box sx={{ pb: theme.spacing(1) }} className={'flex-basic-start '}>
                          <Box className="flex-basic-center" onClick={() => handleNotificationClick(item, tab)}>
                            <Box sx={{ p: 1, ml: 1 }} className={clsx('flex-basic-center', classes.iconBorder)}>
                              {item?.merchant?.branding?.logo_thumbnail_url ? (
                                <Box className="notifiacationMerchantLogo">
                                  <img
                                    className={classes.merchantLogo}
                                    src={item?.merchant?.branding?.logo_thumbnail_url}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  className={clsx(
                                    'icon-merchant-notification actionIconFont-24 ',
                                    classes.primaryColor,
                                  )}
                                ></Box>
                              )}
                            </Box>
                            <Box sx={{ px: 2 }} className="text-small">
                              {item?.message}
                            </Box>
                            <Box sx={{ pr: 1 }} className="text-small">
                              {getDate(item?.raised_on)}
                            </Box>
                          </Box>
                        </Box>
                        <Box className="flex-basic-start">
                          {item?.notification_for && BADGE_NAME[item?.notification_for] && (
                            <Box sx={{ ml: 7 }}>
                              <TBadge
                                bagdeFontStyle={'text-small font-weight-medium'}
                                showBagdeThemeColor={true}
                                label={BADGE_NAME[item?.notification_for]}
                              />
                            </Box>
                          )}
                          {item?.count && item?.count > 0 && (
                            <Box sx={{ ml: item?.notification_for && BADGE_NAME[item?.notification_for] ? 1 : 7 }}>
                              <TBadge
                                bagdeFontStyle={'text-small font-weight-medium'}
                                showBagdeThemeColor={true}
                                label={`+ ${item?.count} Notifications`}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>

                      <Divider />
                    </Box>
                  );
                })}
              </Box>
            </InfiniteScroll>
          </Box>
        ) : (
          <Box sx={{ pt: 5 }} className="font-weight-semibold text-h3 text-center textColor-300">
            {t('noNewNotifications')}
          </Box>
        )}
      </Box>
    </>
  );
};

export default NotificationsListing;
