import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormControlLabel, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  FormBuilder,
  AbstractControl,
  Validators,
  FormArray,
  FieldGroup,
  FieldControl,
  FieldArray,
} from 'react-reactive-form';

import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { resetEntityInviteSuccessMessage, selectEntity } from '../../../redux/feature/entity/entitySlice';
import {
  ENTITY_MEMBER_PERMISSION,
  ENTITY_USER_ROLES,
  MAX_CHAR_LIMIT,
  TButton,
  TCheckbox,
  theme,
  TLoader,
  TSelect,
  TSuccessDialog,
  TTextField,
} from '../../myde-react-components';
import {
  EntityInvitionsType,
  EntityMembersFormData,
  MembersFormData,
  MembersUserListType,
} from '../../../types/entityTypes';
import { PORTAL } from '../../myde-react-components/src/constants/portal';
import { ROLES, USER_TYPES } from '../../../constants/constants';
import { useTheme } from '../../../providers/custom-theme-provider';

interface AddMembersFormProps {
  userType: USER_TYPES.OWNER | USER_TYPES.MEMBER;
  btnText: string;
  memberList?: EntityMembersFormData[];
  isPostOnboardingFlow?: boolean;
  allEntityMembersData: EntityInvitionsType[];
  onBtnClick: (entityMemberList: EntityMembersFormData[]) => void;
}

interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  positionAddMoreBtn: {
    right: 0,
  },
  formGroup: {
    '& .MuiFormHelperText-root': {
      color: ({ currentThemes }) => `${currentThemes.palette.error.main}!important`,
      fontSize: '0.75rem',
      margin: '5px 1px',
      textAlign: 'left',
      fontWeight: 'normal',
    },
  },
  permissionFieldError: {
    color: ({ currentThemes }) => `${currentThemes.palette.error.main}!important`,
  },
});

const AddMembersForm = ({
  userType,
  btnText,
  memberList,
  isPostOnboardingFlow,
  allEntityMembersData,
  onBtnClick,
}: AddMembersFormProps) => {
  //Constants
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { t } = useTranslation('common');

  //Form
  const membersForm = FormBuilder.group({
    userList: FormBuilder.array([]),
  });

  //Redux Values
  const { entityInviteSuccessMessage, loading } = useSelector(selectEntity);

  //State Values
  let keyCount = 0;
  let existingMember: EntityInvitionsType = {} as EntityInvitionsType;
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  //Use Effects
  useEffect(() => {
    if (entityInviteSuccessMessage) {
      dispatch(resetEntityInviteSuccessMessage());
      setOpenSuccessDialog(true);
    }
  }, [entityInviteSuccessMessage]);

  useEffect(() => {
    if (membersForm && memberList && memberList.length > 0) {
      memberList?.forEach((member) => {
        addItem(member);
      });
    } else if (membersForm) {
      addItem();
    }
  }, [memberList, membersForm]);

  //Methods
  const getKey = () => {
    return userType === USER_TYPES.MEMBER ? `User ${++keyCount}` : `Owner ${++keyCount}`;
  };

  const addItem = (memberData?: EntityMembersFormData) => {
    const itemsControl = membersForm.get('userList') as FormArray;
    itemsControl.push(createItem(memberData));
  };

  const createItem = (memberData?: EntityMembersFormData) => {
    const control = FormBuilder.group({
      first_name: [
        memberData?.first_name || '',
        [Validators.required, Validators.maxLength(MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY)],
      ],
      last_name: [
        memberData?.last_name || '',
        [Validators.required, Validators.maxLength(MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY)],
      ],
      email: [memberData?.email || '', [Validators.required, Validators.email]],
      ownership_percentage: [memberData?.ownership_percentage || '', Validators.pattern(/^[0-9]*$/)],
      permission: [memberData?.permission || '', Validators.required],
      is_beneficial_owner: [memberData?.is_beneficial_owner || false],
    });
    if (isPostOnboardingFlow) {
      control.get('email').statusChanges.subscribe((status: string) => {
        if (status === 'VALID') {
          const enteredEmail = control.get('email').value;
          if (allEntityMembersData?.length) {
            existingMember =
              allEntityMembersData?.find((member: EntityInvitionsType) => member?.email === enteredEmail) ||
              ({} as EntityInvitionsType);
            control.meta.memberAlreadyExist = !!existingMember?.id;
          }
        }
      });
    }
    control.meta = {
      key: getKey(),
      memberAlreadyExist: false,
    };
    return control;
  };

  const saveFormData = () => {
    const formData: MembersUserListType = membersForm?.value;
    const membersDetails: EntityMembersFormData[] = formData?.userList?.map((data: MembersFormData) => {
      const membersData: EntityMembersFormData = {
        first_name: data?.first_name || '',
        middle_name: data?.middle_name || '',
        last_name: data?.last_name || '',
        email: data?.email || '',
        ownership_percentage: Number(data?.ownership_percentage) || null,
        user_role: userType === USER_TYPES.MEMBER ? ENTITY_USER_ROLES.USERS : ENTITY_USER_ROLES.OWNERS,
        permission: data?.permission || '',
      };
      if (userType === USER_TYPES.OWNER) {
        return { ...membersData, is_beneficial_owner: data.is_beneficial_owner || false };
      }
      return membersData;
    });
    return membersDetails;
  };

  const handleSubmit = () => {
    const membersDetails = saveFormData();
    onBtnClick(membersDetails);
  };

  const goToEntityListing = () => {
    if (isPostOnboardingFlow) {
      const query = {
        selected_tab: userType === USER_TYPES.OWNER ? ROLES.OWNER : ROLES.USER,
      };
      routeTo(ROUTES.ENTITY_MEMBERS, true, 'Members', query);
    } else {
      routeTo(ROUTES.ENTITY_MY_IDENTITY);
    }
  };

  const getMembersForm = (control: any, index: number) => {
    return (
      <Box sx={{ mt: isPostOnboardingFlow ? 0 : 3 }} key={`${control?.meta?.key}-${String(index)}`}>
        <Box className="textColor-200 text-h3 font-weight-semibold">{control?.meta?.key}</Box>
        <FieldGroup
          control={control}
          render={() => (
            <Box sx={{ mt: theme.spacing(5) }} className={classes.formGroup}>
              <Box sx={{ mb: theme.spacing(5) }}>
                <FieldControl
                  name="first_name"
                  render={({ handler, touched, hasError }) => (
                    <TTextField
                      {...handler()}
                      fullWidth
                      label={'First Name'}
                      variant="outlined"
                      error={touched && (hasError('required') || hasError('maxLength'))}
                      helperText={
                        (touched && hasError('required') && 'First name is required') ||
                        (hasError('maxLength') &&
                          `First name should be of maximun ${MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY} chars`)
                      }
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: theme.spacing(5) }}>
                <FieldControl
                  name="last_name"
                  render={({ handler, touched, hasError }) => (
                    <TTextField
                      {...handler()}
                      fullWidth
                      label={'Last Name'}
                      variant="outlined"
                      error={touched && (hasError('required') || hasError('maxLength'))}
                      helperText={
                        (touched && hasError('required') && 'Last name is required') ||
                        (hasError('maxLength') &&
                          `Last name should be of maximun ${MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY} chars`)
                      }
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: theme.spacing(5) }}>
                <FieldControl
                  name="email"
                  render={({ handler, touched, hasError }) => (
                    <TTextField
                      {...handler()}
                      fullWidth
                      label={'Email'}
                      variant="outlined"
                      error={touched && (hasError('required') || hasError('email'))}
                      helperText={
                        touched &&
                        ((hasError('required') && 'Email is required') ||
                          (hasError('email') && 'Invalid email address'))
                      }
                    />
                  )}
                />
              </Box>
              {userType === USER_TYPES.OWNER && (
                <Box sx={{ mb: theme.spacing(5) }}>
                  <FieldControl
                    name="ownership_percentage"
                    render={({ handler, touched, hasError }) => (
                      <TTextField
                        {...handler()}
                        fullWidth
                        label={'Ownership Entity Stake % (optional)'}
                        variant="outlined"
                        error={touched && hasError('pattern')}
                        helperText={touched && hasError('pattern') && 'Please enter a number'}
                      />
                    )}
                  />
                </Box>
              )}
              <Box sx={{ mb: theme.spacing(5) }}>
                <FieldControl
                  name="permission"
                  render={({ handler, touched, hasError }) => (
                    <>
                      <FormControl className="w-100">
                        <TSelect
                          {...handler()}
                          name="permission"
                          id="type"
                          fullWidth
                          options={ENTITY_MEMBER_PERMISSION}
                          label="Permission"
                          itemValue={'label'}
                          itemId={'value'}
                          error={touched && hasError('required')}
                        />
                      </FormControl>
                      <Box sx={{ my: theme.spacing(1) }} className={clsx(classes.permissionFieldError, 'text-small')}>
                        {touched && hasError('required') && 'Permission is required'}
                      </Box>
                    </>
                  )}
                />
              </Box>
              {userType === USER_TYPES.OWNER && (
                <Box sx={{ mb: theme.spacing(5) }}>
                  <FieldControl
                    name="is_beneficial_owner"
                    render={({ handler }) => (
                      <>
                        <FormControlLabel
                          control={<TCheckbox {...handler('checkbox')} />}
                          label={t('beneficialOwner')}
                        />
                      </>
                    )}
                  />
                  <Box sx={{ mt: 1 }} className="textColor-200 text-extra-small">
                    {t('beneficialOwnerNote')}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        />
        {isPostOnboardingFlow && control?.meta?.memberAlreadyExist && (
          <Box sx={{ p: theme.spacing(5), mb: 3 }} className="memberAllReadyExist">
            <Box sx={{ mb: theme.spacing(3) }} className="flex-basic-start">
              <Box className="memberAllreadyExistErrorIcon flex-basic-center">
                <Box className="icon-error-warning icon-size-24 error-color-state"></Box>
              </Box>
              <Box sx={{ ml: theme.spacing(3) }} className="textColor-100 text-large font-weight-bold">
                {t('alredyExist')}
              </Box>
            </Box>
            <Box className="textColor-200 text-small">
              {t('existinguserErrorMessage', {
                memberType: existingMember?.user_role?.toLowerCase(),
                ns: 'entityMembers',
              })}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  //HTML
  return (
    <>
      <TLoader loading={loading} />
      <FieldGroup
        control={membersForm}
        render={({ invalid }) => (
          <Box className="p-relative">
            <form>
              <Box className="p-absolute positionAddMoreBtn">
                <TButton
                  btnText="+ Add More"
                  variant="text"
                  btnWidthSize="button-w-140"
                  disabled={invalid || !!existingMember?.id}
                  onClick={() => addItem()}
                />
              </Box>
              <FieldArray
                name="userList"
                render={({ controls }: any) => (
                  <Box>
                    {[...controls]
                      ?.reverse()
                      ?.map((memberControl: AbstractControl, index: number) => getMembersForm(memberControl, index))}
                  </Box>
                )}
              />
              <Box className="flex-basic-center">
                <TButton
                  btnText={btnText}
                  btnWidthSize="button-w-240"
                  variant="contained"
                  disabled={invalid || !!existingMember?.id}
                  onClick={handleSubmit}
                />
              </Box>
            </form>
          </Box>
        )}
      />
      <TSuccessDialog
        open={openSuccessDialog}
        title={t('successfullySentLabel')}
        message={t('inviteSuccessMsg')}
        btnText="OK, Great!"
        onButtonClick={goToEntityListing}
        portalType={PORTAL.MYDE}
      />
    </>
  );
};

export default AddMembersForm;
