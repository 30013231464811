import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SettingsItemListing from './SettingsItemListing';
import * as ROUTES from '../../constants/routes';
import { selectEntity } from '../../redux/feature/entity/entitySlice';

const useStyles = makeStyles({
  pageContainer: {
    minHeight: '100vh',
  },
});

const SettingsLanding = () => {
  const classes = useStyles();
  const { checkIsEntity } = useSelector(selectEntity);
  const listItems = [
    {
      id: 0,
      label: 'Profile Info',
      path: `${ROUTES.USER_PROFILE}`,
      icon: 'icon-profile-info actionIconFont-24 textColor-200',
      hideNav: false,
      title: 'Profile Info',
    },
    {
      id: 1,
      label: 'My Merchants & Privacy',
      path: `${ROUTES.USER_MESSAGES}`,
      icon: 'icon-lock actionIconFont-24 textColor-200',
      hideNav: false,
      title: 'My Merchants & Privacy',
    },
    {
      id: 2,
      label: 'Members',
      path: `${ROUTES.ENTITY_MEMBERS}`,
      icon: 'icon-members actionIconFont-24 textColor-200',
      hideNav: true,
      title: 'Members',
      classNameSet: checkIsEntity ? '' : 'hideMemberMenu',
    },
    {
      id: 3,
      label: 'Open Request(s)',
      path: `${ROUTES.ENTITY_INVITE}`,
      icon: 'icon-profile-users actionIconFont-24 textColor-200',
      hideNav: false,
      title: 'Open Request(s)',
    },
    {
      id: 4,
      label: 'Share',
      path: '',
      icon: 'icon-share shareTabMobile textColor-200',
      hideNav: false,
      title: 'Share',
    },
    {
      id: 5,
      label: 'About',
      path: `${ROUTES.MY_T_APPS_ABOUT}`,
      icon: 'icon-error-warning icon-error-rotate actionIconFont-24 textColor-200',
      hideNav: false,
      title: 'About',
    },
  ];
  return (
    <Box className={classes.pageContainer}>
      <SettingsItemListing list={listItems} />
    </Box>
  );
};
export default SettingsLanding;
