import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { theme, TPaper } from '../../components/myde-react-components';
import CustomTabs, { TabListType } from '../../components/myde-react-components/src/components/Tabs/Tabs';
import EntityInviteList from './EntityInviteList';
import {
  getReceivedAssociationRequests,
  getSentAssociationRequests,
  selectEntity,
  setSelectedRequest,
} from '../../redux/feature/entity/entitySlice';
import { CreatedRequestType } from '../../types/entityTypes';
import { setCardState } from '../../redux/feature/common/commonSlice';
import { REQUEST_REVIEW_STATUS } from '../../constants/constants';

const EntityInviteIndex = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //redux valus
  const { sentRequests, receivedRequests } = useSelector(selectEntity);

  //state variables
  const [tabs, setTabs] = useState<Array<TabListType>>([]);
  const [sentAssociationRequests, setSentAssociationRequests] = useState([] as CreatedRequestType[]);
  const [receivedAssociationRequests, setReceivedAssociationRequests] = useState([] as CreatedRequestType[]);

  //useEffects
  useEffect(() => {
    if (sentRequests?.length > 0) {
      setSentAssociationRequests(sentRequests);
    }
  }, [sentRequests]);

  useEffect(() => {
    if (receivedRequests?.length > 0) {
      setReceivedAssociationRequests(receivedRequests);
    }
  }, [receivedRequests]);

  useEffect(() => {
    dispatch(setCardState(false));
  }, []);

  const tabList: TabListType[] = [
    {
      label: sentAssociationRequests?.length > 0 ? `Sent(${sentAssociationRequests?.length})` : `Sent`,
      component: <EntityInviteList data={sentAssociationRequests} tabId={1} />,
    },
    {
      label: receivedAssociationRequests?.length > 0 ? `Received(${receivedAssociationRequests?.length})` : `Received`,
      component: <EntityInviteList data={receivedAssociationRequests} tabId={2} />,
    },
  ];

  useEffect(() => {
    const data = {
      ordering: 'review_status',
      status_in: `{${REQUEST_REVIEW_STATUS.PENDING},${REQUEST_REVIEW_STATUS.REJECTED}}`,
    };
    dispatch(getSentAssociationRequests(data));
    const receiveData = {
      status: `${REQUEST_REVIEW_STATUS.PENDING}`,
    };
    dispatch(getReceivedAssociationRequests(receiveData));
    dispatch(setSelectedRequest({}));
  }, []);

  useEffect(() => {
    setTabs(tabList);
  }, [sentAssociationRequests, receivedAssociationRequests]);

  return (
    <Box sx={{ pt: 10 }}>
      <Box sx={{ mb: 1 }} className="text-h3 textColor-200 font-weight-semibold">
        {t('openRequests')}
      </Box>
      <Divider sx={{ mt: 1, mb: theme.spacing(5) }} />
      <TPaper>
        <Box sx={{ pt: theme.spacing(5), pl: theme.spacing(5) }} className="text-large ">
          {t('entityRequests')}
        </Box>
        <Box sx={{ px: 3 }}>
          <CustomTabs tabList={tabs} borderRequired={false} showBottomBorder={false} />
        </Box>
      </TPaper>
    </Box>
  );
};

export default EntityInviteIndex;
