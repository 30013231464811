import moment from 'moment';
import { colorPalette, ENTITY_PROCESS_IDS, ID_PROCESS_STATUS, PROCESS_IDS } from '../components/myde-react-components';
import { ID_PROCESS_EDITABLE_STATUS_LIST } from '../constants/constants';
import { IconStatusType } from '../types/idProcessTypes';
import { AddressData } from '../types/profileTypes';

export const titleCase = (value: string) => {
  if (!value) {
    return '';
  }
  return value.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()));
};

export const formatDate = (date: string) => {
  return moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : '';
};

export const formatDateToUtc = (date: string) => {
  return moment(date).isValid() ? moment(date).utc().format('MM/DD/YYYY') : '';
};

export const findCountry = (value: string, data: any) => {
  const object = data && data.value && data.value.find((item: any) => item.id === value);
  return object.name;
};

export const findState = (value: string, country: string, data: any) => {
  const object = data && data.value && data.value.find((item: any) => item.country === country && item.id === value);
  return object.name;
};

//TODO get from myde and remove from here
export const getInitials = (text: string) => {
  return text
    ? text
        .split(' ')
        .map((v) => v.slice(0, 1))
        .join('')
        .toUpperCase()
    : '';
};

export const removeUnderscore = (text: string) => {
  return text
    ? text.replace(/(?:_| |\b)(\w)/g, function ($1) {
        return $1.toUpperCase().replace('_', ' ');
      })
    : '';
};

export const insertUnderscore = (text: string) => {
  return text ? text.split(' ').join('_').toLocaleLowerCase() : '';
};

export const getTimeStamp = (date: string) => {
  return moment(date).isValid() ? moment(date).format('hh:mm a') : '';
};

export const getCommaSeperatedValues = (data: string[]) => {
  return data?.length > 0 ? data?.toString().replaceAll(',', ', ') : '';
};

export const replaceSpecialCharAndCapitalize = (
  text: string,
  replaceSpecialChar: string,
  replaceWith: string,
  isCapitalized?: boolean,
) => {
  const regex = new RegExp(replaceSpecialChar, 'g');
  if (isCapitalized) {
    return text
      ? text.replace(regex, replaceWith).replace(/(?: |\b)(\w)/g, (key) => {
          return key.toUpperCase();
        })
      : '';
  }
  return text ? text.replace(regex, replaceWith) : '';
};

export const capitalize = (value: string) => {
  if (!value) {
    return '';
  }
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const formatDateTime = (dateTime: string) => {
  return moment(dateTime).isValid() ? moment(dateTime).format('MM/DD/YYYY | HH:MM A') : '';
};

export const wrapText = (title: string, limit: number): string => {
  if (title && title.length > limit) {
    return title.substring(0, limit) + '...';
  }
  return title;
};

export const hexToRgbA = (hex: string, opacity: number) => {
  let colorData: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    colorData = hex.substring(1).split('');
    if (colorData.length == 3) {
      colorData = [colorData[0], colorData[0], colorData[1], colorData[1], colorData[2], colorData[2]];
    }
    colorData = '0x' + colorData.join('');
    return 'rgba(' + [(colorData >> 16) & 255, (colorData >> 8) & 255, colorData & 255].join(',') + `,${opacity})`;
  }
  throw new Error('Bad Hex');
};

export const setColorIdProcess = (status: string) => {
  switch (status) {
    case ID_PROCESS_STATUS.PASSED:
    case ID_PROCESS_STATUS.VERIFIED:
    case ID_PROCESS_STATUS.SYSTEM_APPROVED:
      return colorPalette.approved;
    case ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW:
      return colorPalette.approved;
    case ID_PROCESS_STATUS.FAILED:
    case ID_PROCESS_STATUS.VERIFICATION_FAILED:
      return colorPalette.stateError;
    case ID_PROCESS_STATUS.IN_PROGRESS:
    case ID_PROCESS_STATUS.PENDING_VERIFICATION:
    case ID_PROCESS_STATUS.VERIFICATION_PENDING:
    case ID_PROCESS_STATUS.IN_DRAFT:
    case ID_PROCESS_STATUS.SUBMITTED:
      return colorPalette.typoText.darken3;
    default:
      return colorPalette.typoText.darken3;
  }
};

export const getIconByStatus = (status: string, type: IconStatusType) => {
  if (
    status === ID_PROCESS_STATUS.PASSED ||
    status === ID_PROCESS_STATUS.VERIFIED ||
    status === ID_PROCESS_STATUS.SYSTEM_APPROVED ||
    status === ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW
  ) {
    return type.SUCCESS;
  } else if (status === ID_PROCESS_STATUS.FAILED || status === ID_PROCESS_STATUS.VERIFICATION_FAILED) {
    return type.ERROR;
  } else {
    return type.NORMAL;
  }
};

export const getIconByProcessId = (processId: number) => {
  switch (processId) {
    case PROCESS_IDS.PROFILE_VERIFICATION:
    case ENTITY_PROCESS_IDS.PROFILE_VERIFICATION:
      return 'icon-profile-users';
    case PROCESS_IDS.SSN_VERIFICATION:
      return 'icon-tax-ID';
    case PROCESS_IDS.W8_W9_DOCUMENT:
    case ENTITY_PROCESS_IDS.W8_W9_DOCUMENT:
      return 'icon-w8';
    case PROCESS_IDS.INCOME_VERIFICATION:
      return 'icon-income-verification';
    case PROCESS_IDS.BIOMETRIC_VERIFICATION:
      return 'icon-fing-biometrics';
    case PROCESS_IDS.ACCREDITATION:
      return 'icon-accreditation';
    case PROCESS_IDS.SIGN_VERIFICATION:
      return 'icon-wet-signature';
    default:
      break;
  }
};

export const checkIsIdProcessEditAllowed = (id: number, status: string) => {
  switch (id) {
    case PROCESS_IDS.PROFILE_VERIFICATION:
    case PROCESS_IDS.ADDRESS_VERIFICATION:
    case PROCESS_IDS.KYC_AML:
    case ENTITY_PROCESS_IDS.PROFILE_VERIFICATION:
      return false;
    case PROCESS_IDS.SSN_VERIFICATION:
      if ([ID_PROCESS_STATUS.PASSED, ID_PROCESS_STATUS.SYSTEM_APPROVED, ID_PROCESS_STATUS.SUBMITTED].includes(status)) {
        return false;
      } else {
        return true;
      }
    default:
      return ID_PROCESS_EDITABLE_STATUS_LIST.includes(status);
  }
};

export const getAddress = (addressData: AddressData) => {
  const addressString = [
    addressData?.address1,
    addressData?.address2,
    addressData?.address3,
    addressData?.city,
    addressData?.state_name,
    addressData?.country_name,
    addressData?.zip,
  ]
    .filter((item) => item)
    .join(', ');
  return addressString;
};

export const getTruncatedHeader = (folderName: string, maxLength: number) => {
  let heading = '';
  if (folderName?.length > maxLength) {
    heading = `${folderName?.slice(0, maxLength)}...`;
  } else {
    heading = folderName;
  }
  return heading;
};
