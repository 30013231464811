import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TCard } from '../../../components/myde-react-components';

const ComingSoon = () => {
  // Constants
  const { t } = useTranslation('common');

  // HTML
  return (
    <TCard sx={{ p: 3 }} className="emptyCardHeight flex-basic-center">
      <Box className="text-large text-center textColor-200">{t('comingSoonText')}</Box>
    </TCard>
  );
};

export default ComingSoon;
