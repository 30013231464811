import React from 'react';
import { Box, Icon, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import successImgBg from '../../components/myde-react-components/src/common/assets/success-placeholder.svg';
import { TButton, theme, TPaper } from '../../components/myde-react-components';
import AuthLayout from '../../layout/AuthLayout';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import { useTheme } from '../../providers/custom-theme-provider';
import clsx from 'clsx';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  imgContainer: {
    width: '156px',
  },
  img: {
    width: '100%',
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  iconContainer: {
    backgroundColor: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
}));

const ResetPasswordSuccess = () => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation(['common', 'forgotPassword']);
  const { routeTo } = useRouter();

  const navigateTo = () => {
    routeTo(ROUTES.LOGIN);
  };

  return (
    <AuthLayout>
      <TPaper sx={{ p: 3 }}>
        <Box className="flex-column-center text-center">
          <Box className="flex-basic-center" sx={{ my: 4 }}>
            <Box className="p-relative">
              <img src={successImgBg} alt="Success" />
              <div className={clsx('circularIcon flex-basic-center', classes.iconContainer)}>
                <Icon className={classes.icon}>check_icon</Icon>
              </div>
            </Box>
          </Box>
          <Box sx={{ pb: theme.spacing(3) }} className="text-center textColor-200 text-h2 font-weight-semibold">
            <Box>{t('congratulationsF', { ns: 'forgotPassword' })}</Box>
            <Box>{t('passwordVerified', { ns: 'forgotPassword' })}</Box>
          </Box>
          <Box className="text-center textColor-200 text-medium">
            {t('passwordVerifiedRecommendation', { ns: 'forgotPassword' })}
          </Box>
          <Box sx={{ mt: 4, mb: 3 }}>
            <TButton btnWidthSize="button-w-240" onClick={navigateTo} variant="contained" btnText={'Sign In'} />
          </Box>
        </Box>
      </TPaper>
    </AuthLayout>
  );
};

export default ResetPasswordSuccess;
