import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectDocuments,
  getFolderFiles,
  getSelectedFile,
  setSelectedFile,
} from '../../redux/feature/documents/documentsSlice';
import * as ROUTES from '../../constants/routes';
import { MerchantDocumentType } from '../../types/documentTypes';
import DocumentListing from '../../components/documents/DocumentListing';
import { PDF_MIME_TYPE } from '../../constants/constants';
import { useRouter } from '../../providers/custom-router-provider';
import { TLoader } from '../../components/myde-react-components';
import { formatDateTime } from '../../utils/utils';

const RecentDocuments = () => {
  const dispatch = useDispatch();
  const { fileList, loading } = useSelector(selectDocuments);
  const { routeTo } = useRouter();

  //state variables
  const [files, setFiles] = useState([] as MerchantDocumentType[]);
  const [recentDocs, setRecentDocs] = useState([] as MerchantDocumentType[]);

  useEffect(() => {
    const filters = {
      order_by: `-created_on`,
    };
    dispatch(getFolderFiles(filters));
  }, []);

  useEffect(() => {
    if (fileList) {
      const recentFiles = fileList.slice(0, 5);
      setFiles(recentFiles);
    }
  }, [fileList]);

  useEffect(() => {
    if (files) {
      const filesReceived = getDocumentGroupedByMerchant(files);
      setRecentDocs(filesReceived);
    }
  }, [files]);

  // Methods
  const selectFile = (file: MerchantDocumentType) => {
    const data = {
      docId: file.doc_id,
    };
    dispatch(getSelectedFile(data));
    dispatch(setSelectedFile(file));
    routeTo(ROUTES.FILE_DETAILS, true, 'Document');
  };

  const getDocumentGroupedByMerchant = (docs: MerchantDocumentType[]) => {
    const unique = [...new Set(docs?.map((item) => item?.merchant_communication?.merchant?.id))];
    const recentDocList: any = [];
    unique?.forEach((merchantId: string) => {
      const object = {
        merchantId: merchantId,
        docs: docs?.filter((doc) => doc?.merchant_communication?.merchant?.id === merchantId),
      };
      recentDocList.push(object);
    });
    return recentDocList;
  };

  const getIcon = (file: MerchantDocumentType) => {
    return PDF_MIME_TYPE?.includes(file?.details?.mime_type) ? 'PictureAsPdfOutlinedIcon' : 'ImageOutlinedIcon';
  };

  return (
    <>
      <TLoader loading={loading} />
      <Box>
        {recentDocs?.map((item: any, index: number) => (
          <>
            <Box sx={{ pt: 2 }} key={index}>
              <Box sx={{ pl: 3 }} className="flex-basic-start">
                <img
                  className="imgBox"
                  src={item?.docs[0]?.merchant_communication?.merchant?.branding?.logo_thumbnail_url}
                />
                <Box sx={{ pl: 2 }} className="text-large font-weight-semibold textColor-200">
                  {item?.docs[0]?.merchant_communication?.merchant?.name}
                </Box>
              </Box>
              <Box sx={{}}>
                {item?.docs?.map((item: MerchantDocumentType, index: number) => {
                  return (
                    <>
                      <Box key={index}>
                        <DocumentListing
                          isRecentDoc={true}
                          name={item?.name}
                          icon={getIcon(item)}
                          showDescriptionIcon={item?.merchant_communication?.message !== ''}
                          modifiedDateTime={item?.modified_on}
                          onClick={() => selectFile(item)}
                          showUnreadIcon={!item?.is_read}
                          createdOn={formatDateTime(item?.created_on)}
                        />
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Box>
            <Box sx={{ pl: 3, pr: 3 }}>
              <Divider />
            </Box>
          </>
        ))}
      </Box>
    </>
  );
};

export default RecentDocuments;
