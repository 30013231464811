import { apiPost } from "./axios";
import { ChangePasswordType } from "../types/commonTypes";

export const resetExpiredPassword = async (formData: ChangePasswordType) => {
  const resp = await apiPost('/reset-expired-password', formData);
  return resp.data;
};

export const changePassword = async (formData: ChangePasswordType) => {
  const res = await apiPost('/change-password', formData);
  return res.data;
};

export const validatePassword = async (formData: ChangePasswordType) => {
  const res = await apiPost('/validate-password', formData);
  return res.data;
};