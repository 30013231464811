import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TextArea from '../Textarea/Textarea';
import { Button } from '../Button/Button';

const useStyles = makeStyles({
  btnWrapper: {
    marginTop: 10,
  },
});

export interface CommentProps {
  maxLength?: number;
  btnText?: string;
  showCancel?: boolean;
  placeHolder?: string;
  minRows?: number;
  onSubmit?: ClickHandlerType;
  handleCancel?: ClickHandlerType;
}

interface ClickHandlerType {
  (param: any): void;
}

export const CommentComponent = ({
  maxLength = 500,
  btnText = 'Add Comment',
  showCancel = false,
  placeHolder = 'Add comment',
  minRows = 5,
  onSubmit,
  handleCancel,
}: CommentProps) => {
  const classes = useStyles();
  const [newMsg, setNewMsg] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || '';
    if (value && value.length > maxLength) {
      return;
    }
    setNewMsg(value);
  };

  const submitComment = () => {
    onSubmit && onSubmit(newMsg);
    setNewMsg('');
  };

  const cancel = () => {
    if (handleCancel) {
      handleCancel(null);
    } else {
      return;
    }
  };

  return (
    <Box>
      <TextArea fullWidth={true} label={placeHolder} value={newMsg} onChange={handleChange} minRows={minRows} />
      <Grid sx={{ mt: 2 }} container direction="row" alignItems="center" className={classes.btnWrapper}>
        <Grid item xs={6}>
          {showCancel && <Button size="large" fullWidth variant="text" btnText={'Cancel'} onClick={cancel} />}
        </Grid>
        <Grid item xs={6}>
          <Button size="large" fullWidth variant="contained" btnText={btnText} onClick={submitComment} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommentComponent;
