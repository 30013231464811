import React from 'react';
import TAppsIndex from '../../pages/MyTApps/Index';

export const TAppIndex = () => {
  return (
    <>
      <TAppsIndex />
    </>
  );
};

export default TAppIndex;
