import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl } from '@mui/material';

import NotificationsIndex from '../../components/myde-react-components/src/components/Notifications/NotificationsIndex';
import {
  MerchantListResultType,
  MerchantOptionsType,
  NotificationsResultType,
  NotificationTabType,
} from '../../components/myde-react-components/src/components/Notifications/notificationsType';
import {
  getMerchantsList,
  getUserMerchantNotifications,
  getUserSystemNotifications,
  markAsRead,
  selectNotification,
  setIsNotificationRead,
  clearMerchantNotifications,
  clearSystemNotifications,
  setSpecificNotification,
} from '../../redux/feature/notification/notificationSlice';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import { INVITE_TYPE } from '../../constants/constants';
import { getEntityById } from '../../redux/feature/entity/entitySlice';
import { NOTIFICATION_TAB, theme, TLoader, TSelect } from '../../components/myde-react-components';

const NotificationsLanding = () => {
  //constants
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  //redux values
  const { notificationSet, merchantNotificationSet, merchantsList, loading } = useSelector(selectNotification);

  //state variables
  const [notificationData, setNotificationData] = useState([] as NotificationTabType[]);
  const [page, setPage] = useState(1);
  const [merchantPage, setMerchantPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [systemNotifications, setSystemNotifications] = useState([] as NotificationsResultType[]);
  const [prevSystemNotifications, setPrevSystemNotifications] = useState([] as NotificationsResultType[]);
  const [merchantNotificationData, setMerchantNotificationData] = useState([] as NotificationsResultType[]);
  const [prevMerchantNotificationData, setPrevMerchantNotificationData] = useState([] as NotificationsResultType[]);
  const [merchantList, setMerchantList] = useState([] as MerchantOptionsType[]);
  const [merchantId, setMerchantId] = useState('all');

  //useEffects
  useEffect(() => {
    getSystemData();
    dispatch(getMerchantsList());
    dispatch(setIsNotificationRead(false));
  }, []);

  useEffect(() => {
    const data = merchantsList?.map((item: MerchantListResultType) => {
      return {
        id: item?.id,
        name: item?.name,
      };
    });
    data?.push({ id: 'all', name: 'All' });
    setMerchantList(data);
  }, [merchantsList]);

  useEffect(() => {
    if (merchantId !== 'all') {
      getUpdatedMerchantList();
    } else {
      getMerchantData();
    }
  }, [merchantId]);

  useEffect(() => {
    setMerchantId('all');
  }, [merchantList]);

  useEffect(() => {
    if (page > 1) {
      setPrevSystemNotifications(systemNotifications);
      getSystemData();
    }
  }, [page]);

  useEffect(() => {
    if (merchantPage > 1) {
      setPrevMerchantNotificationData(merchantNotificationData);
      getMerchantData();
    }
  }, [merchantPage]);

  useEffect(() => {
    if (notificationSet?.next) {
      setHasNext(true);
    }
    const notifications = notificationSet?.results || [];
    let combinedNotifications = [] as NotificationsResultType[];
    if (prevSystemNotifications?.length > 0) {
      combinedNotifications = [...prevSystemNotifications, ...notifications];
    } else {
      combinedNotifications = [...notifications];
    }
    setSystemNotifications(combinedNotifications);
  }, [notificationSet, prevSystemNotifications]);

  useEffect(() => {
    if (merchantNotificationSet?.next) {
      setHasNext(true);
    }
    const notifications = merchantNotificationSet?.results || [];
    let combinedNotifications = [] as NotificationsResultType[];
    if (prevMerchantNotificationData?.length > 0) {
      combinedNotifications = [...prevMerchantNotificationData, ...notifications];
    } else {
      combinedNotifications = [...notifications];
    }
    setMerchantNotificationData(combinedNotifications);
  }, [merchantNotificationSet, prevMerchantNotificationData]);

  useEffect(() => {
    createNotificationData();
  }, [notificationSet, systemNotifications, merchantNotificationData, merchantNotificationSet, merchantId]);

  //methods
  const getSystemData = async () => {
    await dispatch(
      getUserSystemNotifications({
        page: page,
        limit: 10,
      }),
    );
  };

  const createNotificationData = () => {
    if (Object?.keys(notificationSet)?.length > 0) {
      const notificationsList = [];
      const notification1: NotificationTabType = {
        id: NOTIFICATION_TAB.MERCHANT,
        label: 'Merchant',
        data: merchantNotificationData,
        count: merchantNotificationSet?.unread_count,
        children: renderSelect(),
        heightClass: 'merchantTabNotifications',
      };
      notificationsList.push(notification1);
      const notification2: NotificationTabType = {
        id: NOTIFICATION_TAB.SYSTEM,
        label: 'System',
        data: systemNotifications,
        count: notificationSet?.unread_count,
        heightClass: 'systemTabNotifications',
      };
      notificationsList.push(notification2);
      setNotificationData(notificationsList);
    }
  };

  const handleMerchantChange = (event: any) => {
    setMerchantId(event?.target?.value);
  };

  const getMerchantData = async () => {
    await dispatch(
      getUserMerchantNotifications({
        page: merchantPage,
        limit: 10,
      }),
    );
  };

  const getUpdatedMerchantList = async () => {
    await dispatch(
      getUserMerchantNotifications({
        page: merchantPage,
        limit: 10,
        merchant_id: merchantId,
      }),
    );
  };

  const renderSelect = () => {
    return (
      <Box sx={{ my: theme.spacing(3) }} className="w-100 customInputField">
        <FormControl>
          <TSelect
            name="merchants"
            id="type"
            value={merchantId}
            options={merchantList}
            label="Merchant"
            itemId="id"
            itemValue="name"
            onChange={handleMerchantChange}
          />
        </FormControl>
      </Box>
    );
  };

  const resetDataStateValues = () => {
    setSystemNotifications([]);
    setPrevSystemNotifications([]);
    setMerchantNotificationData([]);
    setPrevMerchantNotificationData([]);
    setPage(1);
    setMerchantPage(1);
  };

  const handleNotificationClick = async (notification: NotificationsResultType, tab: string) => {
    dispatch(markAsRead({ tab: tab, notification_id_list: [notification?.id] }));
    dispatch(setSpecificNotification(notification));
    if (notification?.notification_for === INVITE_TYPE.ENTITY) {
      const params = {
        trellis_uid: notification?.details?.entity_uid,
      };
      await dispatch(getEntityById(params));
    }
    routeTo(ROUTES.NOTIFICATION_ROUTING, true, '');
  };

  const getUpdatedMerchantNotifications = async () => {
    await dispatch(clearMerchantNotifications());
    setMerchantPage(1);
    await dispatch(
      getUserMerchantNotifications({
        page: 1,
        limit: 10,
      }),
    );
  };

  const getUpdatedSystemNotifications = async () => {
    await dispatch(clearSystemNotifications());
    setPage(1);
    await dispatch(
      getUserSystemNotifications({
        page: 1,
        limit: 10,
      }),
    );
  };

  const getUpdatedData = async (tab: string) => {
    if (tab === NOTIFICATION_TAB.MERCHANT) {
      getUpdatedMerchantNotifications();
    }
    if (tab === NOTIFICATION_TAB.SYSTEM) {
      getUpdatedSystemNotifications();
    }
  };

  const handleMarkAsReadClick = async (data: string[], tab: string) => {
    await dispatch(markAsRead({ tab: tab }));
    tab === NOTIFICATION_TAB.MERCHANT ? setMerchantNotificationData([]) : setSystemNotifications([]);
    getUpdatedData(tab);
    resetDataStateValues();
  };

  const fetchMoreData = async (tab: string) => {
    if (tab === NOTIFICATION_TAB.SYSTEM && notificationSet?.next) {
      setPage(page + 1);
    }
    if (tab === NOTIFICATION_TAB.MERCHANT && merchantNotificationSet?.next) {
      setMerchantPage(merchantPage + 1);
    }
  };

  //TSX
  return (
    <Box>
      <TLoader loading={loading} />
      <NotificationsIndex
        handleMarkAsReadClick={handleMarkAsReadClick}
        handleNotificationClick={handleNotificationClick}
        notificationData={notificationData}
        fetchMoreData={fetchMoreData}
        hasMore={hasNext}
        defaultTab={NOTIFICATION_TAB.MERCHANT}
      ></NotificationsIndex>
    </Box>
  );
};

export default NotificationsLanding;
