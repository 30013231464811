import React from 'react';
import DocumentIdProcessVerification from '../common/DocumentIdProcessVerification';
interface LetterOfGoodStandingVerificationPorps {
  isEntity?: boolean;
}
const LetterOfGoodStandingVerification = ({ isEntity }: LetterOfGoodStandingVerificationPorps) => {
  return (
    <div>
      <DocumentIdProcessVerification isEntity={isEntity} />
    </div>
  );
};

export default LetterOfGoodStandingVerification;
