import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';

import { ID_PROCESS_KEYS, TLoader } from '../../myde-react-components';
import { selectSelectedIdProcessDetails } from '../../../redux/feature/idProcess/mainIdProcessSlice';
import { setIsEditAllowedFlag, setPreviousStep } from '../../../redux/feature/common/commonSlice';
import ProfileDetails from '../components/profile/ProfileDetails';
import AddressDetails from '../components/address-verification/AddressDetails';
import IDList from '../components/document-verification/components/IDList';
import {
  getIdProcessSharedCount,
  getSharedMerchantsList,
  idProcessShareDetails,
} from '../../../redux/feature/idProcess/idProcessShareSlice';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../../src/constants/routes';
import {
  AUTHORIZATION_STATUS,
  ID_PROCESS_TEXTS,
  SHARED_SUMMARY_CARD,
  SHARED_SUMMARY_TEXT,
} from '../../../constants/constants';
import ViewShareDetailsCard from './ViewShareDetailsCard';

interface IdProcessDetailsProps {
  isEntity: boolean;
}

const IdProcessDetail = ({ isEntity }: IdProcessDetailsProps) => {
  // Constants
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const ID_PROCESS_COMPONENTS_DETAILS = [
    {
      processKey: ID_PROCESS_KEYS.PROFILE,
      component: <ProfileDetails isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.ADDRESS,
      component: <AddressDetails />,
    },
    {
      processKey: ID_PROCESS_KEYS.DOCUMENT_VERIFICATION,
      component: <IDList />,
    },
  ];

  // Redux Values
  const { selectedProcessKey, loading } = useSelector(selectSelectedIdProcessDetails);
  const { merchantCount, merchantCountForEntity, showIdProcessSharedSection } = useSelector(idProcessShareDetails);

  // State Values
  const [idProcessKey, setIdProcessKey] = useState('');
  const [merchantSharedCount, setMerchantSharedCount] = useState(0);
  const [showSharedIdProcessSection, setShowSharedIdProcessSection] = useState(true);

  // Use Effects
  useEffect(() => {
    dispatch(setPreviousStep(null));
  }, []);
  useEffect(() => {
    if (selectedProcessKey) {
      setIdProcessKey(selectedProcessKey);
    }
  }, [selectedProcessKey]);

  useEffect(() => {
    const data = getData(['id'], SHARED_SUMMARY_CARD[selectedProcessKey], 1);
    dispatch(getIdProcessSharedCount(data));
  }, []);

  useEffect(() => {
    setMerchantSharedCount(isEntity ? merchantCountForEntity : merchantCount);
  }, [merchantCount, merchantCountForEntity]);

  useEffect(() => {
    setShowSharedIdProcessSection(showIdProcessSharedSection);
  }, [showIdProcessSharedSection]);

  // Methods
  const updateIdProcessComponent = () => {
    const currentComponentItem = ID_PROCESS_COMPONENTS_DETAILS.find((item) => item?.processKey === idProcessKey);
    return currentComponentItem?.component || <></>;
  };

  const handleViewClick = async () => {
    const query = {
      isEntity: true,
    };
    dispatch(setIsEditAllowedFlag(false));
    if (!isEntity) {
      const data = getData(['id', 'merchant, created_on'], SHARED_SUMMARY_CARD[selectedProcessKey], 1000);
      await dispatch(getSharedMerchantsList(data));
    }
    routeTo(ROUTES.SHARED_ID, true, `${ID_PROCESS_TEXTS[SHARED_SUMMARY_CARD[selectedProcessKey]]}`, isEntity && query);
  };

  const getData = (query: string[], process_Id: number, limit: number) => {
    const data = {
      authorization_status: AUTHORIZATION_STATUS.SHARED,
      page: 1,
      process_id: process_Id,
      query: `{ ${query.toString()} }`,
      limit: limit,
    };
    return data;
  };

  // HTML
  return (
    <Box>
      <TLoader loading={loading} />
      <Box sx={{ mb: 8 }}>
        {updateIdProcessComponent()}
        {merchantSharedCount > 0 && SHARED_SUMMARY_TEXT?.includes(selectedProcessKey) && showSharedIdProcessSection && (
          <ViewShareDetailsCard
            handleViewClick={handleViewClick}
            merchantSharedCount={merchantSharedCount}
            processId={SHARED_SUMMARY_CARD[selectedProcessKey]}
          />
        )}
      </Box>
    </Box>
  );
};

export default IdProcessDetail;
