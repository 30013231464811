import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { ENTITY_INVITE_STATUS } from '../../../constants/constants';
import { setCardState } from '../../../redux/feature/common/commonSlice';
import {
  getEntityInvitationList,
  getEntityList,
  resetAcceptedRejectedEntityInviteDetails,
  selectEntity,
  setSelectedEntity,
  setShowAccptedEntityInviteDetailsFlag,
} from '../../../redux/feature/entity/entitySlice';
import { setEntitySelectionFlag } from '../../../redux/feature/user/userSlice';
import { fontStyle } from '../../../styles/style';
import { EntityTypes } from '../../../types/entityTypes';
import { TButton, TCommonAvatar, TFullScreenDialog, theme, TLoader } from '../../myde-react-components';

const ViewAcceptedEntityInviteDetails = () => {
  //Constants
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //Redux
  const { acceptedRejectedEntityInviteDetails, entityList, loading } = useSelector(selectEntity);

  //State
  const [showAcceptedEntityInviteDetails, setShowAcceptedEntityInviteDetails] = useState(false);
  const [redirectToViewEntity, setRedirectToViewEntity] = useState(false);

  //useEffects
  useEffect(() => {
    if (
      acceptedRejectedEntityInviteDetails &&
      acceptedRejectedEntityInviteDetails?.status === ENTITY_INVITE_STATUS.ACCEPTED
    ) {
      setShowAcceptedEntityInviteDetails(true);
    }
  }, [acceptedRejectedEntityInviteDetails]);

  useEffect(() => {
    if (entityList?.length > 0 && redirectToViewEntity) {
      const entityData = entityList?.find((entity: EntityTypes) => {
        if (entity?.id === acceptedRejectedEntityInviteDetails?.entity?.id) {
          return entity;
        }
      });
      dispatch(setSelectedEntity(entityData || ({} as EntityTypes)));
      dispatch(setEntitySelectionFlag(true));
      dispatch(setCardState(true));
    }
  }, [entityList]);

  //Methods
  const closeModal = () => {
    dispatch(setShowAccptedEntityInviteDetailsFlag(false));
    setShowAcceptedEntityInviteDetails(false);
  };

  const handleViewEntity = () => {
    dispatch(getEntityInvitationList());
    dispatch(getEntityList());
    setRedirectToViewEntity(true);
    closeModal();
    dispatch(resetAcceptedRejectedEntityInviteDetails());
  };

  //HTML
  return (
    <>
      <TLoader loading={loading} />
      <TFullScreenDialog
        open={showAcceptedEntityInviteDetails}
        closeModal={closeModal}
        title={'Entity Association Request'}
        dialogContent={
          <>
            <Box className="text-center">
              <Box sx={{ mb: 5 }} className="flex-basic-center">
                <TCommonAvatar
                  imageUrl={acceptedRejectedEntityInviteDetails?.entity?.avatar_url || ''}
                  avatarText={acceptedRejectedEntityInviteDetails?.entity?.name || ''}
                  iconSize="100px"
                  textSize={fontStyle.fontSize.font36.fontSize}
                />
              </Box>
              <Box sx={{ mb: 4 }} className="textColor-200 text-h3 font-weight-semibold">
                {t('inviteAcceptTitle', { ns: 'entityInvite' })}
              </Box>
            </Box>
            <Box sx={{ pl: 5 }} className="entityInviteDetailsPosition">
              <Box sx={{ mb: theme.spacing(5) }}>
                <Box sx={{ mb: theme.spacing(1) }} className="textColor-300 text-small">
                  {t('entityTid', { ns: 'entityInvite' })}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">
                  {acceptedRejectedEntityInviteDetails?.entity?.trellis_uid}
                </Box>
              </Box>
              <Box sx={{ mb: theme.spacing(5) }}>
                <Box sx={{ mb: theme.spacing(1) }} className="textColor-300 text-small">
                  {t('entityName', { ns: 'entityInvite' })}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">
                  {acceptedRejectedEntityInviteDetails?.entity?.name}
                </Box>
              </Box>
            </Box>

            <Box sx={{ mt: 5 }} className="flex-column-center">
              <Box>
                <TButton
                  btnText="View Entity"
                  btnWidthSize="button-w-240"
                  variant="contained"
                  onClick={handleViewEntity}
                />
              </Box>
            </Box>
          </>
        }
      />
    </>
  );
};

export default ViewAcceptedEntityInviteDetails;
