import React, { useState } from 'react';
import { Box, Divider } from '@mui/material';
import clsx from 'clsx';

import { DocumentListingType } from '../../../types/dashboardTypes';
import { ClickHandlerType } from '../../../types/commonTypes';
import { theme, TCheckbox } from '../../../components/myde-react-components';

interface DocumentListingProps {
  data: DocumentListingType[];
  handleClick: ClickHandlerType;
  setSelectedData?: (data: string[]) => void;
}

const DocumentListing = ({ data, handleClick, setSelectedData }: DocumentListingProps) => {
  // State Variables
  const [uniqueIdList, setUniqueIdList] = useState([] as string[]);

  // Methods
  const handleCheckboxChange = (item: DocumentListingType) => {
    const data = [...uniqueIdList];
    if (data.includes(item?.id)) {
      const index = data.indexOf(item?.id);
      data.splice(index, 1);
    } else {
      data.push(item?.id);
    }
    setUniqueIdList(data);
    setSelectedData && setSelectedData(data);
  };

  // HTML
  return (
    <Box className="custom-document-list">
      {data?.map((item: DocumentListingType, index: number) => {
        return (
          <Box className="overflow-hidden" key={index}>
            <Box sx={{ py: theme.spacing(5) }}>
              <Box className="flex-basic-space-between">
                <Box className="flex-basic-start">
                  <Box sx={{ pr: 3 }} className={clsx('icon-size-24 textColor-100', item?.icon)} />
                  <Box>
                    <Box
                      sx={{ pb: theme.spacing(1) }}
                      className={'font-weight-semibold textColor-200 cursorPointer text-ellipsis textHiddenWidth'}
                      onClick={() => handleClick(item)}
                    >
                      {item?.title}
                    </Box>
                    <Box className={'text-small text-ellipsis textHiddenWidth'}>{item?.subtitle}</Box>
                  </Box>
                </Box>
                <Box>
                  {item?.show_action && (
                    <TCheckbox
                      value={item.id}
                      checked={uniqueIdList?.includes(item?.id)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            {index < data?.length - 1 && <Divider sx={{ mt: 1, mb: 1 }} />}
          </Box>
        );
      })}
    </Box>
  );
};

export default DocumentListing;
