import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPlayer from 'react-player';
import { makeStyles } from '@mui/styles';
import { Theme, useMediaQuery, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TButton, theme, colorPalette, PdfViewer } from '../myde-react-components';
import {
  ACCEPTED_VIDEO_MIME_TYPES,
  MEGABYTE_CONVERTER,
  FILE_SIZE,
  DOCUMENT_VERIFICATION_MIME_TYPES,
  PDF_MIME_TYPE,
  DOCUMENT_TYPE,
} from '../../constants/constants';
import TakePhoto from './TakePhoto';
import { selectSession, setCameraMode } from '../../redux/feature/common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '../../providers/custom-theme-provider';
import { selectIdeProcessDetails } from '../../redux/feature/idProcess/documentVerificationSlice';

interface FileUploadProps {
  setFile: (file: File) => void;
  acceptedFileFormat: string[];
  uploadedFile?: any;
  supportedFileFormat: string[];
  maxFileSize: number;
  capturePhoto?: boolean;
  isVideo?: boolean;
  isDoc?: boolean;
  side?: string;
  showTitleText?: boolean;
}

export interface StyleProps {
  matches: boolean;
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  container: {
    padding: 0,
    marginTop: '1rem',
    width: '100%',
    height: 'auto',
  },
  image: {
    maxWidth: '100%',
    height: '10rem',
  },
  placeholder: {
    width: '100%',
    height: '17.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  card: {
    border: `1px dashed ${colorPalette.containerBack.lighten2}`,
    borderRadius: '10px !important',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  icon: {
    fontSize: '5rem !important',
  },
  browseText: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
}));

const FileUpload = ({
  uploadedFile,
  acceptedFileFormat,
  supportedFileFormat,
  maxFileSize,
  capturePhoto,
  setFile,
  isVideo,
  isDoc = true,
  side,
  showTitleText = true,
}: FileUploadProps) => {
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const styleProp = {
    matches,
    currentThemes,
  };
  const classes = useStyles(styleProp);
  const dispatch = useDispatch();
  const [file, setFiles] = useState([] as any);
  const { cameraModeActive } = useSelector(selectSession);
  const { documentType } = useSelector(selectIdeProcessDetails);

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: maxFileSize,
    accept: acceptedFileFormat.toString(),
    onDropRejected: (fileRejections) => {
      //for video
      if (isVideo && !isDoc && ACCEPTED_VIDEO_MIME_TYPES.includes(fileRejections[0].file.type)) {
        if (
          fileRejections[0].file.size > FILE_SIZE.VIDEO_FILE_SIZE &&
          ACCEPTED_VIDEO_MIME_TYPES.includes(fileRejections[0].file.type)
        )
          toast.error(
            `File size too large. Please select a file less than ${FILE_SIZE.VIDEO_FILE_SIZE / MEGABYTE_CONVERTER} MB`,
          );
      }
      //for image/pdf
      else if (
        (!isVideo && isDoc && DOCUMENT_VERIFICATION_MIME_TYPES.includes(fileRejections[0].file.type)) ||
        (!isVideo && isDoc && PDF_MIME_TYPE.includes(fileRejections[0].file.type))
      ) {
        if (
          fileRejections[0].file.size > FILE_SIZE.IMAGE_FILE_SIZE &&
          DOCUMENT_VERIFICATION_MIME_TYPES.includes(fileRejections[0].file.type)
        )
          toast.error(
            `File size too large. Please select a file less than ${FILE_SIZE.IMAGE_FILE_SIZE / MEGABYTE_CONVERTER} MB`,
          );
        if (
          fileRejections[0].file.size > FILE_SIZE.DOCUMENT_FILE_SIZE &&
          PDF_MIME_TYPE.includes(fileRejections[0].file.type)
        )
          toast.error(
            `File size too large. Please select a file less than ${
              FILE_SIZE.DOCUMENT_FILE_SIZE / MEGABYTE_CONVERTER
            } MB`,
          );
      }
      //invalid
      else toast.error('Please upload a file with a valid format');
    },
    onDropAccepted: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFiles(
          Object.assign(
            new File([acceptedFiles[0]], acceptedFiles[0].name, {
              type: acceptedFiles[0]?.type?.includes('video') ? 'video/mp4' : acceptedFiles[0]?.type,
            }),
            {
              preview: URL.createObjectURL(
                new File([acceptedFiles[0]], acceptedFiles[0].name, {
                  type: acceptedFiles[0]?.type?.includes('video') ? 'video/mp4' : acceptedFiles[0]?.type,
                }),
              ),
            },
          ),
        );
      } else {
        toast.error('Please upload a file with a valid format');
      }
    },
  });

  useEffect(() => {
    if (!cameraModeActive) {
      setClickPhoto(false);
    }
  }, [cameraModeActive]);

  useEffect(() => {
    if (file && Object.keys(file).length > 0) {
      setFile(file);
    }
    URL.revokeObjectURL(file);
  }, [file]);

  const videoFileType =
    ACCEPTED_VIDEO_MIME_TYPES.includes(uploadedFile?.mime_type) ||
    ACCEPTED_VIDEO_MIME_TYPES.includes(uploadedFile?.type);

  const pdfFileType =
    (uploadedFile && uploadedFile.mime_type && uploadedFile.mime_type.includes('pdf')) ||
    (uploadedFile && uploadedFile.type && uploadedFile.type.includes('pdf'));

  const [clickPhoto, setClickPhoto] = useState(false);

  const handleCapturePhoto = (e: any) => {
    e.preventDefault();
    setClickPhoto(true);
    dispatch(setCameraMode(true));
  };

  const setCameraFlag = (value: boolean) => {
    setClickPhoto(value);
  };

  return (
    <Box className="text-center">
      {showTitleText && (
        <Box sx={{ mb: 3 }} className="textColor-200 text-h3 font-weight-semibold">
          {clickPhoto ? 'Capture' : 'Upload'} {documentType?.key === DOCUMENT_TYPE.DRIVERS_LICENSE && side} Image
        </Box>
      )}

      {clickPhoto ? (
        <TakePhoto setFile={setFile} toggleCam={setCameraFlag} hideUpload={false} />
      ) : (
        <Box>
          <Box {...getRootProps({ className: 'dropzone' })} className={classes.container}>
            <Box>
              <input {...getInputProps()} />
              {Object.keys(file).length > 0 || (uploadedFile && Object.keys(uploadedFile).length > 0) ? (
                <Box className="flex-basic-center">
                  {(file && file.type && file.type.includes('video')) || videoFileType ? (
                    <ReactPlayer
                      width={'300px'}
                      height={'144px'}
                      className={classes.image}
                      url={file.preview || uploadedFile.signed_url || uploadedFile.preview}
                      controls={true}
                    />
                  ) : (file && file.type && file.type.includes('pdf')) || pdfFileType ? (
                    <PdfViewer domain={'myde'} uri={file.preview || uploadedFile.signed_url || uploadedFile} />
                  ) : file.preview || uploadedFile.signed_url || uploadedFile ? (
                    <img
                      src={file.preview || uploadedFile.signed_url || uploadedFile}
                      className={classes.image}
                      alt="Uploaded Image"
                    />
                  ) : (
                    <Box className="text-center textColor-300 text-medium font-weight-regular">NA</Box>
                  )}
                </Box>
              ) : (
                <Box>
                  <Box className="icon-file-copy textColor-300 icon-size-72" />
                  <Box>
                    <Box>
                      <Box className="text-center" sx={{ mt: 1 }}>
                        <Box className="text-extra-small" sx={{ pb: 1 }}>
                          <Box className={classes.browseText}>{t('supportedFileTypes')}</Box>
                          <Box className={classes.browseText}>
                            {supportedFileFormat.toString().replaceAll(',', ', ')}
                          </Box>
                        </Box>
                        <Box>
                          <Box className="text-center textColor-300 text-medium">{t('fileDragAndDrop')}</Box>
                          or
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}

      <Box className={classes.btnContainer}>
        {!clickPhoto && capturePhoto && (
          <TButton
            icon={<Box sx={{ pr: 1 }} className="icon-camera" />}
            btnText="Capture"
            variant="outlined"
            btnWidthSize="button-w-140"
            onClick={handleCapturePhoto}
            sx={{ mt: theme.spacing(5) }}
          />
        )}
        {!clickPhoto && (
          <TButton
            icon={<Box className="icon-upload" sx={{ mr: 1 }} />}
            {...getRootProps({ className: 'dropzone button-w-140' })}
            btnText={Object.keys(file).length > 0 || uploadedFile ? 'Re-Upload' : 'Upload'}
            variant={'outlined'}
            sx={{ mt: theme.spacing(2) }}
          />
        )}
      </Box>
    </Box>
  );
};
export default FileUpload;
