import React from 'react';
import { Box, Theme } from '@mui/material';
import ContainerCard from '../../../../components/common/ContainerCard';
import { makeStyles } from '@mui/styles';
import { TCard, colorPalette, theme } from '../../../../components/myde-react-components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTheme } from '../../../../providers/custom-theme-provider';
import userAvatarPlaceholder from '../../../../common/assets/user-avatar.svg';

interface DocumentSideProps {
  renderActions: React.ReactNode;
  showRecordInstrucation: boolean;
}
interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  bgColor: {
    backgroundColor: `${colorPalette.backgroundFadeColors.secondary} !important`,
  },
  idType: {
    border: `2px solid ${colorPalette.border.card}`,
    borderRadius: '10px !important',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '10px 0px !important',
  },
  blankIdType: {
    border: `1px dashed ${colorPalette.typoText.lighten1} !important`,
    borderRadius: '10px !important',
    backgroundColor: 'transparent !important',
    margin: '10px 0px !important',
    cursor: 'pointer',
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: 2,
  },
  iconNormal: {
    fontSize: '1.8rem',
  },
  iconSuccess: {
    fontSize: '2.2rem',
  },
  primaryColor: {
    '& span': {
      color: ({ currentThemes }) => currentThemes.palette.primary.main,
    },
  },
}));
const VideoCaptureInstruction = ({ renderActions, showRecordInstrucation = false }: DocumentSideProps) => {
  const { currentThemes } = useTheme();
  const styleProp = {
    currentThemes,
  };
  const classes = useStyles(styleProp);
  const { t } = useTranslation('biometricVerification');
  //how to record
  const recordVideoContent = () => {
    return (
      <>
        <Box>
          <Box sx={{ p: 3 }}>
            <Box className="text-center textColor-200 text-h3 font-weight-semibold" sx={{ pb: theme.spacing(3) }}>
              {t('videoCaptureTitle', { ns: 'biometricVerification' })}
            </Box>
            <Box sx={{ pb: theme.spacing(5) }} className="text-center textColor-200 text-medium">
              {t('videoCaptureSubTitle', { ns: 'biometricVerification' })}
            </Box>
            <TCard sx={{ p: theme.spacing(5) }}>
              <Box>
                <Box sx={{ mx: theme.spacing(7) }} className={clsx('text-center smapleImageBox', classes.primaryColor)}>
                  <span className="icon-curve-placeholder- left-corner"></span>
                  <span className="icon-curve-placeholder- right-corner"></span>
                  <span className="icon-curve-placeholder- bottom-corner"></span>
                  <span className="icon-curve-placeholder- top-corner"></span>
                  <img className="captureImagePlaceholder" src={userAvatarPlaceholder} alt="Logo" />
                  <Box className="redMarkBox"></Box>
                </Box>
              </Box>
              <Box sx={{ mb: theme.spacing(3), mt: 4 }} className=" textColor-200 text-medium">
                {t('viedo5words', { ns: 'biometricVerification' })}
              </Box>
              <Box sx={{ mb: 4 }} className=" textColor-200 text-medium">
                {t('viedo5words2', { ns: 'biometricVerification' })}
              </Box>
              <Box className="tipsBox">
                <Box sx={{ mb: 1 }} className="textColor-200 text-h3 font-weight-semibold">
                  {t('tips')}
                </Box>
                <Box sx={{ mb: 3 }} className="tipsList">
                  {t('tips1', { ns: 'biometricVerification' })}
                </Box>
                <Box sx={{ mb: 3 }} className="tipsList">
                  {t('tips2', { ns: 'biometricVerification' })}
                </Box>
                <Box sx={{ mb: 3 }} className="tipsList">
                  {t('tips3', { ns: 'biometricVerification' })}
                </Box>
                <Box className="tipsList">{t('tips4', { ns: 'biometricVerification' })}</Box>
              </Box>
            </TCard>
          </Box>
        </Box>
      </>
    );
  };
  //how to upload record
  const recordUploadContent = () => {
    return (
      <>
        <Box>
          <Box sx={{ p: 3 }}>
            <Box className="text-center textColor-200 text-h3 font-weight-semibold" sx={{ pb: theme.spacing(3) }}>
              {t('videoUploadCaptureTitle', { ns: 'biometricVerification' })}
            </Box>
            <Box sx={{ pb: theme.spacing(5) }} className="text-center textColor-200 text-medium">
              {t('videoUploadCaptureSubTitle', { ns: 'biometricVerification' })}
            </Box>
            <TCard sx={{ p: theme.spacing(5) }}>
              <Box className="p-relative videoPlaceholder">
                <Box>
                  <img className="captureImagePlaceholder" src={userAvatarPlaceholder} alt="Logo" />
                </Box>
                <Box
                  sx={{ mx: theme.spacing(7) }}
                  className={clsx('text-center smapleImageBox uploadBox', classes.primaryColor)}
                >
                  <span className="icon-curve-placeholder- left-corner"></span>
                  <span className="icon-curve-placeholder- right-corner"></span>
                  <span className="icon-curve-placeholder- bottom-corner"></span>
                  <span className="icon-curve-placeholder- top-corner"></span>

                  <Box className="icon-play icon-size-20"> </Box>
                </Box>
              </Box>
              <Box sx={{ mb: theme.spacing(5), mt: 4 }} className=" textColor-200 text-medium">
                {t('upload5Words', { ns: 'biometricVerification' })}
              </Box>

              <Box className="tipsBox">
                <Box sx={{ mb: 1 }} className="textColor-200 text-h3 font-weight-semibold">
                  {t('tips')}
                </Box>
                <Box sx={{ mb: 3 }} className="tipsList">
                  {t('tips1', { ns: 'biometricVerification' })}
                </Box>
                <Box sx={{ mb: 3 }} className="tipsList">
                  {t('tips2', { ns: 'biometricVerification' })}
                </Box>
                <Box sx={{ mb: 3 }} className="tipsList">
                  {t('videoMOVFormat', { ns: 'biometricVerification' })}
                </Box>
                <Box className="tipsList">{t('videoSize', { ns: 'biometricVerification' })}</Box>
              </Box>
            </TCard>
          </Box>
        </Box>
      </>
    );
  };
  const renderRequirements = () => {
    return <>{showRecordInstrucation ? <Box>{recordVideoContent()}</Box> : <Box>{recordUploadContent()}</Box>}</>;
  };
  return <ContainerCard content={renderRequirements()}>{renderActions}</ContainerCard>;
};
export default VideoCaptureInstruction;
