import React, { useLayoutEffect } from 'react';
import { Outlet, Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Login from '../pages/Login/Login';

import * as ROUTES from '../constants/routes';
import CreateAccount from '../pages/CreateAccount/CreateAccount';
import AppLayout from '../layout/AppLayout';
import { GuardedRoute } from './GuardedRoute';
import { AuthProvider } from '../auth/auth-guard';
import TAppsIndex from '../pages/MyTApps/Index';
import MyIdentity from '../pages/MyIdentity/MyIdentity';
import VerifyEmail from '../pages/VerifyEmail/VerifyEmail';
import ProfileInfoIndex from '../pages/Settings/ProfileInfo/ProfileInfoIndex';
import About from '../pages/Settings/About/About';

import { useDispatch } from 'react-redux';
import { updateRouteHistory } from '../redux/feature/common/commonSlice';
import EntitiesLanding from '../pages/Entities/EntitiesLanding';
import EntityIdProcessIndex from '../components/entities/id-process/EntityIdProcessIndex';
import EntityFormIndex from '../components/entities/Form/FormIndex';
import EntitiesIndex from '../components/entities/onboarding/EntitiesIndex';
import CustomDocumentForm from '../pages/MyIdentity/supplemental-id-process/CustomDocumentForm';
import KycAmlReport from '../components/id-process/components/kyc-aml/Index';
import { CustomRouterProvider } from '../providers/custom-router-provider';
import MerchantPrivacyIndex from '../pages/Settings/MerchantPrivacy/MerchantPrivacyIndex';
import DocumentCategory from '../pages/Documents/document-category/DocumentCategory';
import { CategoryFolders } from '../pages/Documents/category-folders/CategoryFolders';
import Files from '../pages/Documents/files/DocumentFiles';
import FileDetails from '../pages/Documents/files/FileDetails';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ResetPasswordSuccess from '../pages/ResetPassword/ResetPasswordSuccess';
import MerchantDocumentsIndex from '../pages/Documents/MerchantDocumentsIndex';
import IdleLogout from '../components/myde-react-components/src/components/IdleTimeLogout/IdleLogout';
import Dashboard from '../pages/Dashboard/Dashboard';
import ViewInvitationDetails from '../components/dashboard/invitations/ViewInviteDetails';
import ApproveInviteSubmit from '../components/dashboard/invitations/ApproveInviteSubmit';
import ApplicationStatus from '../components/dashboard/invitations/ApplicationStatus';
import EntityMyIdentity from '../components/entities/id-process/EntityMyIdentity';

import IdProcessIndex from '../pages/IdProcess/Index';
import CustomDocumentList from '../pages/MyIdentity/supplemental-id-process/CustomDocumentList';
import CustomDocumentDescription from '../pages/MyIdentity/supplemental-id-process/CustomDocumentDescription';
import CategoryFolder from '../pages/MyIdentity/supplemental-id-process/CategoryFolder';
import CategoryDocument from '../pages/MyIdentity/supplemental-id-process/CategoryDocument';
import CustomDocumentCategoryList from '../pages/MyIdentity/supplemental-id-process/CustomDocumentCategoryList';
import TaxIdFail from '../components/entities/onboarding/TaxIdFail';
import SuccessPage from '../components/entities/onboarding/SuccessPage';
import TaxIdDetailView from '../components/entities/onboarding/TaxIdDetailView';
import EntityInviteIndex from '../pages/EntityInvite/EntityInviteIndex';
import AddOwnerUser from '../components/entities/onboarding/AddOwnerUser';
import AddMembers from '../components/entities/onboarding/AddMembers';
import EntityInviteDetails from '../pages/EntityInvite/EntityInviteDetails';
import EntityInviteDecline from '../pages/EntityInvite/EntityInviteDecline';
import BeneficialOwnerDocuments from '../components/id-process/components/beneficial-owner-documents/BeneficialOwnerDocuments';
import SharedIdProcess from '../components/id-process/flows/SharedIdProcess';
import SharedIdProcessDetails from '../components/id-process/flows/SharedIdProcessDetails';
import EntityProfileDetails from '../pages/Settings/ProfileInfo/EntityInfo/EntityProfileDetails';
import ProfilePicture from '../components/common/ProfilePicture';
import MerchantNavItems from '../pages/Settings/MerchantPrivacy/MerchantNavItems';
import AddMemberUser from '../components/entities/onboarding/AddMemberUser';
import NotificationRouting from '../pages/NotificationsLanding/NotificationRouting';
import Messages from '../pages/Settings/MerchantPrivacy/Messages';
import MessageDetails from '../pages/Settings/MerchantPrivacy/MessageDetails';
import ComingSoon from '../pages/Settings/MerchantPrivacy/ComingSoon';
import MyInboxMessageList from '../pages/Settings/MyInbox/MyInboxMessageList';
import MyInboxMessageDetails from '../pages/Settings/MyInbox/MyInboxMessageDetails';
import MemberIndex from '../components/entities/member/MemberIndex';
import MemberDetails from '../components/entities/member/MemberDetails';
import ApplicationList from '../pages/Settings/UserMessages/ApplicationList';
import ChangePassword from '../pages/Settings/ProfileInfo/ChangePassword';

export default () => {
  const history = createBrowserHistory({ window });
  const dispatch = useDispatch();

  const AuthWrappedAppLayout = () => {
    return (
      <AppLayout>
        <Outlet />
      </AppLayout>
    );
  };

  // Store route location as history
  useLayoutEffect(() => {
    history.listen(({ location }) => {
      dispatch(updateRouteHistory(location));
    });
  }, [history]);

  return (
    <HistoryRouter history={history} basename={process.env.PUBLIC_URL}>
      <CustomRouterProvider>
        <AuthProvider>
          <IdleLogout />
          <Routes>
            <Route path={'/'} element={<Login />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />

            {/* Onboarding Routes */}
            {/* API changes required for onboarding path */}
            {/* <Route path={ROUTES.ONBOARDING}> */}
            <Route path={ROUTES.CREATE_ACCOUNT} element={<CreateAccount />} />
            <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route path={ROUTES.VERIFY_PROFILE_EMAIL} element={<VerifyEmail />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={ROUTES.PASSWORD_RESET_SUCCESS} element={<ResetPasswordSuccess />} />
            <Route path={ROUTES.RESET_EXPIRED_PASSWORD} element={<ChangePassword />} />
            {/* </Route> */}
            <Route path="/" element={<AuthWrappedAppLayout />}>
              <Route path={ROUTES.DASHBOARD} element={<GuardedRoute component={Dashboard} />} />

              {/* ID Process Routes */}
              <Route path={ROUTES.ID_PROCESS} element={<GuardedRoute component={IdProcessIndex} />} />
              <Route path={ROUTES.KYC_AML} element={<GuardedRoute component={KycAmlReport} />} />
              <Route
                path={ROUTES.BENEFICIAL_OWNER_DOCUMENT}
                element={<GuardedRoute component={BeneficialOwnerDocuments} />}
              />
              <Route path={ROUTES.SHARED_ID} element={<GuardedRoute component={SharedIdProcess} />} />
              <Route path={ROUTES.SHARED_ID_DETAILS} element={<GuardedRoute component={SharedIdProcessDetails} />} />

              <Route path={ROUTES.VIEW_INVITE} element={<GuardedRoute component={ViewInvitationDetails} />} />
              <Route path={ROUTES.APPROVE_SUBMIT_INVITE} element={<GuardedRoute component={ApproveInviteSubmit} />} />
              <Route path={ROUTES.INVITATION_STATUS} element={<GuardedRoute component={ApplicationStatus} />} />

              <Route path={ROUTES.MY_T_APPS} element={<GuardedRoute component={TAppsIndex} />} />
              <Route path={ROUTES.MY_IDENTITY} element={<GuardedRoute component={MyIdentity} />} />
              <Route path={ROUTES.CUSTOM_DOCUMENTS} element={<GuardedRoute component={CustomDocumentList} />} />
              <Route
                path={ROUTES.CUSTOM_DOCUMENTS_DESCRIPTION}
                element={<GuardedRoute component={CustomDocumentDescription} />}
              />
              <Route
                path={ROUTES.CUSTOM_DOCUMENTS_CATEGORY}
                element={<GuardedRoute component={CustomDocumentCategoryList} />}
              />
              <Route path={ROUTES.CUSTOM_CATEGORY_FOLDER} element={<GuardedRoute component={CategoryFolder} />} />
              <Route path={ROUTES.CUSTOM_CATEGORY_DOCUMENTS} element={<GuardedRoute component={CategoryDocument} />} />
              <Route path={ROUTES.USER_PROFILE} element={<GuardedRoute component={ProfileInfoIndex} />} />
              <Route path={ROUTES.EDIT_USER_PROFILE_PICTURE} element={<GuardedRoute component={ProfilePicture} />} />
              <Route path={ROUTES.MY_T_APPS_ABOUT} element={<GuardedRoute component={About} />} />
              <Route path={ROUTES.ENTITY_INVITE} element={<GuardedRoute component={EntityInviteIndex} />} />
              <Route path={ROUTES.ENTITY_INVITE_DETAILS} element={<GuardedRoute component={EntityInviteDetails} />} />
              <Route path={ROUTES.ENTITY_INVITE_DECLINE} element={<GuardedRoute component={EntityInviteDecline} />} />

              {/*My Merchant and Privacy Routes */}
              <Route path={ROUTES.USER_MESSAGES} element={<GuardedRoute component={MerchantPrivacyIndex} />} />
              <Route path={ROUTES.USER_MESSAGES_INDEX} element={<GuardedRoute component={MerchantNavItems} />} />
              <Route path={ROUTES.NOTIFICATION_ROUTING} element={<GuardedRoute component={NotificationRouting} />} />
              <Route path={ROUTES.APPLICATION_LIST} element={<GuardedRoute component={ApplicationList} />} />
              <Route path={ROUTES.APPLICATION_MESSAGES} element={<GuardedRoute component={Messages} />} />
              <Route path={ROUTES.APPLICATION_MESSAGE_DETAILS} element={<GuardedRoute component={MessageDetails} />} />
              <Route path={ROUTES.MY_DOCUMENTS} element={<GuardedRoute component={DocumentCategory} />} />
              <Route path={ROUTES.MY_INBOX} element={<GuardedRoute component={MyInboxMessageList} />} />
              <Route path={ROUTES.ENTITY_MEMBERS} element={<GuardedRoute component={MemberIndex} />} />
              <Route path={ROUTES.ENTITY_MEMBERS_DETAILS} element={<GuardedRoute component={MemberDetails} />} />
              <Route
                path={ROUTES.MY_INBOX_MESSAGE_DETAILS}
                element={<GuardedRoute component={MyInboxMessageDetails} />}
              />

              {/* Entity Routes */}
              <Route path={ROUTES.ENTITIES}>
                <Route path={ROUTES.ENTITIES} element={<GuardedRoute component={EntitiesLanding} />} />
                <Route path={ROUTES.ENTITY_ID_PROCESS} element={<GuardedRoute component={EntityIdProcessIndex} />} />
                <Route path={ROUTES.ENTITY_MY_IDENTITY} element={<GuardedRoute component={EntityMyIdentity} />} />
                <Route path={ROUTES.ENTITY_ADD_OWNERS_USERS} element={<GuardedRoute component={AddOwnerUser} />} />
                <Route path={ROUTES.ENTITY_ADD_MEMBER_USERS} element={<GuardedRoute component={AddMemberUser} />} />
                <Route path={ROUTES.ENTITY_ADD_MEMBERS} element={<GuardedRoute component={AddMembers} />} />
              </Route>
              <Route path={ROUTES.SEARCH_ENTITY} element={<GuardedRoute component={EntitiesIndex} />} />
              <Route path={ROUTES.ADD_NEW_ENTITY} element={<GuardedRoute component={EntityFormIndex} />} />
              <Route path={ROUTES.TAX_ID_FAILED} element={<GuardedRoute component={TaxIdFail} />} />
              <Route path={ROUTES.TAX_ID_SUCCESS} element={<GuardedRoute component={SuccessPage} />} />
              <Route path={ROUTES.TAX_ID_DETAILS} element={<GuardedRoute component={TaxIdDetailView} />} />
              <Route path={ROUTES.ENTITY_PROFILE} element={<GuardedRoute component={EntityProfileDetails} />} />
              <Route path={ROUTES.EDIT_ENTITY_PROFILE_PICTURE} element={<GuardedRoute component={ProfilePicture} />} />

              {/* Supplemental ID Process Routes */}
              <Route path={ROUTES.INDEX} element={<GuardedRoute component={CustomDocumentForm} />} />

              {/* ID Process Routes */}
              <Route path={ROUTES.MERCHANT_DOCUMENTS} element={<GuardedRoute component={MerchantDocumentsIndex} />} />
              <Route path={ROUTES.DOCUMENTS_CATEGORY} element={<GuardedRoute component={DocumentCategory} />} />
              <Route path={ROUTES.CATEGORY_FOLDER} element={<GuardedRoute component={CategoryFolders} />} />
              <Route path={ROUTES.DOCUMENTS_FILES} element={<GuardedRoute component={Files} />} />
              <Route path={ROUTES.FILE_DETAILS} element={<GuardedRoute component={FileDetails} />} />

              <Route path={ROUTES.COMING_SOON} element={<GuardedRoute component={ComingSoon} />} />
            </Route>
          </Routes>
        </AuthProvider>
      </CustomRouterProvider>
    </HistoryRouter>
  );
};
