import * as React from 'react';
import ContainerCard from '../../../components/common/ContainerCard';
import { Box, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TTextField, TButton } from '../../myde-react-components';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  resetAddEntityResultData,
  searchAnEntity,
  setEntitySearchData,
  setIsEntity,
  setTaxId,
} from '../../../redux/feature/entity/entitySlice';
import * as z from 'zod';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useEffect, useState } from 'react';
import { setPreviousStep } from '../../../redux/feature/common/commonSlice';
import { ID_CARD_STATE, TAX_ID_REGEX } from '../../../constants/constants';
import { setEntityProfileList } from '../../../redux/feature/profile/entityProfileSlice';
import { showResendBtnHandler } from '../../../redux/feature/verifyEmail/verifyEmailSlice';

interface SearchEntityProps {
  onSearch: (step: number) => void;
}

export const SearchEntity = ({ onSearch }: SearchEntityProps) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //state variables
  const [taxIdValue, setTaxIdValue] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  //useEffects
  useEffect(() => {
    dispatch(setIsEntity(false));
    dispatch(setTaxId(''));
    dispatch(resetAddEntityResultData());
    dispatch(setEntityProfileList([]));
    dispatch(setPreviousStep(ID_CARD_STATE.ENTITY_SELECTION));
    dispatch(showResendBtnHandler());
  }, []);

  const EntitySearchSchema = z.object({
    tax_id: z.string().min(1, { message: 'Tax ID is required' }),
  });

  type EntitySearchSchemaPayload = z.infer<typeof EntitySearchSchema>;
  const { handleSubmit, control, formState, getValues, setValue } = useForm<EntitySearchSchemaPayload>({
    resolver: zodResolver(EntitySearchSchema),
    mode: 'onChange',
  });
  const { errors, isValid } = formState;

  const onSubmit = async () => {
    try {
      const SearchEntityData = getValues();

      dispatch(setIsEntity(true));
      dispatch(setTaxId(getValues('tax_id')));
      await dispatch(setEntitySearchData(SearchEntityData));
      await dispatch(searchAnEntity(SearchEntityData));
      onSearch(1);
    } catch (err: any) {}
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value && !TAX_ID_REGEX.test(event?.target?.value)) {
      return;
    } else {
      setValue('tax_id', event?.target?.value, { shouldValidate: true });
      setTaxIdValue(event?.target?.value);
    }
  };

  const renderRequirements = () => {
    return (
      <Box className="taxIdSearchCard">
        <Box sx={{ py: 2, px: 3 }}>
          <Box className="text-h3 font-weight-semibold textColor-200" sx={{ mt: 1 }}>
            {t('enterTaxId')}
          </Box>
        </Box>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ py: 1, px: 3 }}>
              <Controller
                name="tax_id"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    {...field}
                    value={taxIdValue}
                    label="Enter Tax ID number"
                    variant="outlined"
                    fullWidth
                    type={passwordShown ? 'text' : 'password'}
                    error={Boolean(errors.tax_id)}
                    InputProps={{
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <TButton
                              variant="text"
                              onClick={togglePassword}
                              onMouseDown={handleMouseDownPassword}
                              icon={
                                <>
                                  <Box className="infoIcon">
                                    {passwordShown ? (
                                      <Box
                                        sx={{ py: 1, px: 2 }}
                                        className="icon-eye text-h3 textColor-200 cursorPointer"
                                      />
                                    ) : (
                                      <VisibilityOffOutlinedIcon
                                        fontSize="small"
                                        className="textColor-200 icon-size-20"
                                      />
                                    )}
                                  </Box>
                                </>
                              }
                            />
                          </InputAdornment>
                        </>
                      ),
                    }}
                    helperText={errors.tax_id?.message}
                    onChange={handleChange}
                  />
                )}
              />
            </Box>
            <Box sx={{ py: 3 }} className="text-center">
              <TButton
                btnWidthSize={'button-w-240'}
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                btnText="Continue"
              />
            </Box>
          </form>
        </Box>
      </Box>
    );
  };

  return <ContainerCard content={renderRequirements()} />;
};
export default SearchEntity;
