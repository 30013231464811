import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import {
  resendProfileVerifyLink,
  verifyProfileEmail,
  resendVerifyLinkWithToken,
  resendVerifyLinkWithoutToken,
  resendEntityEmailVerifyLink,
} from '../../../api/verifyEmail';
import { toast } from 'react-toastify';
import { apiPost } from '../../../api/axios';
import { EMAIL_VERIFICATION_TEXTS } from '../../../constants/constants';

type VerifyEmailState = {
  loading: boolean;
  verifyError: boolean;
  tokenExpired: boolean;
  emailAlreadyVerified: boolean;
  errorObj: any;
  showResendBtn: boolean;
  emailAlreadyExists: boolean;
};

const initialState: VerifyEmailState = {
  loading: false,
  verifyError: false,
  tokenExpired: false,
  emailAlreadyVerified: false,
  errorObj: {},
  showResendBtn: true,
  emailAlreadyExists: false,
};

export const selectDashboardDetails = ({ verifyEmail }: RootState) => ({
  loading: verifyEmail.loading,
  verifyError: verifyEmail.verifyError,
  showResendBtn: verifyEmail.showResendBtn,
  tokenExpired: verifyEmail.tokenExpired,
  emailAlreadyVerified: verifyEmail.emailAlreadyVerified,
});

export const verifyEmailHandler = createAsyncThunk(
  'verifyEmail/verifyEmailHandler',
  async (_flag, { rejectWithValue }) => {
    try {
      const res = await apiPost('/verify-email', null, {
        custom: {
          excludeTokenIdFromHeader: true,
          APITokenIdFromHeader: true,
        },
      });
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const verifyProfileEmailHandler = createAsyncThunk('verifyEmail/verifyProfileEmailHandler', async () => {
  return await verifyProfileEmail();
});

export const resendProfileVerifyLinkHandler = createAsyncThunk(
  'verifyEmail/resendProfileVerifyLinkHandler',
  async (id: string) => {
    return await resendProfileVerifyLink(id);
  },
);

export const resendEntityEmailVerification = createAsyncThunk(
  'verifyEmail/resendEntityEmailVerification',
  async (data: {entityUid: number, profile_id: string}) => {
  return await resendEntityEmailVerifyLink(data)  
  } 
)

export const resendVerifyLinkWithTokenHandler = createAsyncThunk('verifyEmail/resendVerifyLinkWithToken', async () => {
  return await resendVerifyLinkWithToken();
});

export const resendVerifyEmailWithoutToken = createAsyncThunk('verifyEmail/resendVerifyLinkWithoutToken', async () => {
  return await resendVerifyLinkWithoutToken();
});

export const dashboardSlice = createSlice({
  name: 'verifyEmail',
  initialState,
  reducers: {
    showResendBtnHandler: (state) => {
      state.showResendBtn = true;
    },
    hideResendBtnHandler: (state) => {
      state.showResendBtn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmailHandler.pending, (state) => {
        state.loading = true;
        state.verifyError = false;
      })
      .addCase(verifyEmailHandler.fulfilled, (state, action: any) => {
        state.verifyError = false;
        state.loading = false;
        if (!action?.payload) {
          state.verifyError = true;
        }
      })
      .addCase(verifyEmailHandler.rejected, (state, action: any) => {
        state.loading = false;
        if (action?.payload?.detail === EMAIL_VERIFICATION_TEXTS.TOKEN_EXPIRED) {
          state.tokenExpired = true;
        } else if (action?.payload?.detail === EMAIL_VERIFICATION_TEXTS.EMAIL_VERIFIED) {
          state.emailAlreadyVerified = true;
        } else {
          state.verifyError = true;
        }
      })
      .addCase(resendVerifyLinkWithTokenHandler.pending, (state) => {
        state.loading = true;
        state.verifyError = false;
      })
      .addCase(resendVerifyLinkWithTokenHandler.fulfilled, (state, action: any) => {
        state.loading = false;
        state.verifyError = false;
        if (action.payload) {
          toast.success(action.payload?.detail);
        } else {
          state.verifyError = true;
        }
      })
      .addCase(resendVerifyLinkWithTokenHandler.rejected, (state) => {
        state.loading = false;
        state.verifyError = true;
      })
      .addCase(verifyProfileEmailHandler.pending, (state) => {
        state.loading = true;
        state.verifyError = false;
      })
      .addCase(verifyProfileEmailHandler.fulfilled, (state, action: any) => {
        state.loading = false;
        state.verifyError = false;
        if (!action?.payload) {
          state.verifyError = true;
        }
      })
      .addCase(verifyProfileEmailHandler.rejected, (state) => {
        state.loading = false;
        state.verifyError = true;
      })
      .addCase(resendProfileVerifyLinkHandler.pending, (state) => {
        state.loading = true;
        state.verifyError = false;
      })
      .addCase(resendProfileVerifyLinkHandler.fulfilled, (state, action: any) => {
        state.loading = false;
        state.verifyError = false;
        if (action.payload) {
          state.showResendBtn = false;
        } else {
          state.verifyError = true;
        }
      })
      .addCase(resendProfileVerifyLinkHandler.rejected, (state) => {
        state.loading = false;
        state.verifyError = true;
      })
      .addCase(resendVerifyEmailWithoutToken.pending, (state) => {
        state.loading = true;
        state.verifyError = false;
      })
      .addCase(resendVerifyEmailWithoutToken.fulfilled, (state, action: any) => {
        state.loading = false;
        state.verifyError = false;
        if (action?.payload) {
          toast.success(action?.payload?.detail);
        } else {
          state.verifyError = true;
        }
      })
      .addCase(resendVerifyEmailWithoutToken.rejected, (state) => {
        state.loading = false;
        state.verifyError = true;
      })
      .addCase(resendEntityEmailVerification.pending,(state)=>{
        state.loading = true;
        state.verifyError = false;
      })
      .addCase(resendEntityEmailVerification.fulfilled,(state,action)=>{
        state.loading = false;
        state.verifyError = false;
        if (action.payload) {
          state.showResendBtn = false;
        } else {
          state.verifyError = true;
        }
      })
      .addCase(resendEntityEmailVerification.rejected,(state)=>{
        state.loading = false;
        state.verifyError = true;
      });
  },
});

export const { showResendBtnHandler, hideResendBtnHandler } = dashboardSlice.actions;

export default dashboardSlice.reducer;
