import React from 'react';
import { Autocomplete as MuiAutocomplete, AutocompleteProps, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTheme } from '../../../../../providers/custom-theme-provider';

export type CombinedProps = AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>;
export interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  paper: {
    '& .MuiAutocomplete-listbox': {
      '& .MuiAutocomplete-option': {
        backgroundColor: 'transparent !important',
        '&.Mui-focused': {
          fontWeight: '400 !important',
        },
        '&[aria-selected="true"]': {
          color: ({ currentThemes }) => `${currentThemes.palette.primary.main}`,
          backgroundColor: 'transparent !important',
          fontWeight: '700 !important',
        },
      },
    },
  },
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          display: 'none !important',
        },
      },
    },
    '& .MuiOutlinedInput-input.MuiInputBase-input': {
      padding: '22px 0px 0px 7px!important',
    },
  },
});

export const Autocomplete = React.forwardRef<any, CombinedProps>(({ ...props }: CombinedProps, ref: any) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);

  return (
    <MuiAutocomplete
      classes={{ paper: classes.paper }}
      popupIcon={<Box className="icon-arrow-down-line text-h2 textColor-200 autoTextWithIcon" />}
      className={clsx({
        [classes.autocomplete]: true,
        ['customIcon']: true,
      })}
      ref={ref}
      {...props}
    />
  );
});
export default Autocomplete;
