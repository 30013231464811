import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  colorPalette,
  theme,
  ID_PROCESS_STATUS,
  PROCESS_KEYS_WITH_PROCESS_ID,
  TPasswordExpiryModal,
} from '../components/myde-react-components';
import MobileDrawer from '../components/common/MobileDrawer';
import { selectUser } from '../redux/feature/user/userSlice';
import {
  GET_PARENT_PROCESS_IDS,
  PROCESS_IDS,
  WEB_SOCKET_ROUTES,
  WEB_SOCKET_NAVIGATE_TO,
  WEB_SOCKET_FOCUS_ITEMS,
} from '../constants/constants';
import { wssConfig } from '../api/config';
import { selectSession, setCardState, setRefreshPage } from '../redux/feature/common/commonSlice';
import useQuery from '../hooks/useQuery';
import { useRouter } from '../providers/custom-router-provider';
import { RouteToType } from '../types/commonTypes';
import { setIdProcessKey } from '../redux/feature/idProcess/mainIdProcessSlice';
import { setIsNotificationRead } from '../redux/feature/notification/notificationSlice';
import * as ROUTES from '../constants/routes';

export interface StyleProps {
  matches: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  container: {
    flexGrow: 1,
    marginTop: '0px',
    background: colorPalette.containerBack.base,
    padding: 12,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '1px',
    },

    transition: 'top 600ms ease-in-out 0s !important',
    '-webkitTransition': 'top 600ms ease-in-out 0s !important',
  },
  appContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  moveUp: {
    top: '0',
    position: 'absolute',
  },
  moveDown: {
    top: '100%',
    position: 'absolute',
  },
  topSpace: {
    paddingTop: 24,
  },
  moreTopSpace: {
    marginTop: 64,
  },
}));

export interface WsToastProps {
  message?: string;
  id: string;
  navigate: string;
}

const App: FC<any> = (props: any) => {
  // Constants
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { routeTo } = useRouter();
  const queryParams = useQuery();
  const { t } = useTranslation('common');

  const { children } = props;
  const styleProps = {
    matches,
  };

  const dispatch = useDispatch();
  const classes = useStyles(styleProps);
  const { showCard } = useSelector(selectSession);

  const isChildPath = !!queryParams.get('isChild');

  const { authToken, userData } = useSelector(selectUser);

  const getRouteDetails = (navigate: string) => {
    if (navigate === WEB_SOCKET_NAVIGATE_TO.PROFILE_LISTING) {
      return {
        isChild: true,
        stateName: 'Profiles',
        query: { id: PROCESS_IDS.PROFILE_VERIFICATION },
      };
    }
  };

  const WsToast = ({ message, id, navigate }: WsToastProps) => {
    dispatch(setRefreshPage(true));
    const currentRouteUrl = ROUTES.ID_PROCESS;
    const isSameRoute = window.location.pathname.indexOf(currentRouteUrl) !== -1;

    const navigateTo = () => {
      dispatch(setCardState(false));
      const processId = GET_PARENT_PROCESS_IDS[parseInt(id)];
      const query = { id: processId };
      if (navigate === WEB_SOCKET_NAVIGATE_TO.ID_PROCESS_LISTING) {
        const idProcessData: any = PROCESS_KEYS_WITH_PROCESS_ID.find((item) => item?.id_processes?.includes(processId));
        const processKey = idProcessData.process_key || '';
        const title = idProcessData?.process_title || '';
        dispatch(setIdProcessKey(processKey));
        routeTo(ROUTES.ID_PROCESS, true, title, query);
      } else {
        if (window.location.pathname.indexOf(WEB_SOCKET_ROUTES[navigate]) !== -1) {
          dispatch(setRefreshPage(true));
        } else {
          const data: RouteToType = getRouteDetails(navigate) || ({} as RouteToType);
          routeTo(WEB_SOCKET_ROUTES[navigate], data.isChild, data.stateName, data.query, data.stateObj);
        }
      }
    };

    return (
      <>
        <Box>
          <Box>{message}</Box>
          {!isSameRoute && (
            <Box className="text-medium font-weight-semibold cursorPointer" onClick={navigateTo}>
              View
            </Box>
          )}
        </Box>
      </>
    );
  };

  const wsToastHandler = (event: any) => {
    const json = JSON.parse(event.data);
    try {
      switch (json?.payload?.focus_item) {
        case WEB_SOCKET_FOCUS_ITEMS.ID_PROCESS_COMPLETED:
        case WEB_SOCKET_FOCUS_ITEMS.PROFILE_ADDRESS_VERIFICATION_COMPLETED:
        case WEB_SOCKET_FOCUS_ITEMS.PROFILE_VERIFICATION_COMPLETED:
          if (json?.payload?.details?.status === ID_PROCESS_STATUS.FAILED && json?.payload?.message) {
            toast.error(
              <WsToast
                navigate={json?.payload?.navigate_to}
                message={json?.payload?.message}
                id={json?.payload?.resource_id}
              />,
              {
                toastId: 'error1',
              },
            );
          } else if (json?.payload?.details?.status === ID_PROCESS_STATUS.PASSED && json?.payload?.message) {
            toast.success(
              <WsToast
                navigate={json?.payload?.navigate_to}
                message={json?.payload?.message}
                id={json?.payload?.resource_id}
              />,
              {
                toastId: 'success1',
              },
            );
          } else if (
            json?.payload?.details?.status === ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW &&
            json?.payload?.message
          ) {
            toast.info(
              <WsToast
                navigate={json?.payload?.navigate_to}
                message={json?.payload?.message}
                id={json?.payload?.resource_id}
              />,
              {
                toastId: 'info1',
              },
            );
          }
          break;
        case WEB_SOCKET_FOCUS_ITEMS.ALERT_RECEIVED:
          dispatch(setIsNotificationRead(true));
          break;
        default:
          break;
      }
    } catch (err) {}
  };

  // Use Effects
  useEffect(() => {
    if (userData?.account?.id) {
      const wsUrl = `${wssConfig.baseUrl}/${userData?.account?.trellis_uid}/websocket?token=${authToken}`;
      const socket = new WebSocket(wsUrl);
      socket.onopen = function () {
        socket.send(
          JSON.stringify({
            connection: 'Connect to Web Socket',
          }),
        );
      };
      socket.onmessage = function (event) {
        wsToastHandler(event);
      };
      return () => {
        socket.close();
      };
    }
  }, [authToken, userData]);

  // Methods
  const onResetPassword = () => {
    routeTo(ROUTES.RESET_EXPIRED_PASSWORD);
  };

  // HTML
  return (
    <>
      <Box className={classes.appContainer}>
        <MobileDrawer>
          <Box
            className={clsx({
              [classes.container]: true,
              [classes.topSpace]: !showCard && !isChildPath,
              [classes.moveUp]: !showCard,
              [classes.moveDown]: showCard,
              [classes.moreTopSpace]: isChildPath,
            })}
          >
            {children || <Outlet />}
          </Box>
        </MobileDrawer>
      </Box>
      <TPasswordExpiryModal
        onReset={onResetPassword}
        daysToPasswordExpiry={userData?.days_to_password_expiry}
        title={t('passwordExpiryModalTitle')}
        message={t('passwordExpiryMessage', { daysToExpire: userData?.days_to_password_expiry })}
      />
    </>
  );
};

export default App;
