import React from 'react';
import DocumentIdProcessVerification from '../common/DocumentIdProcessVerification';
interface IrsLetterEvidencingVerificationPorps {
  isEntity?: boolean;
}
const IrsLetterEvidencingVerification = ({ isEntity }: IrsLetterEvidencingVerificationPorps) => {
  return (
    <div>
      <DocumentIdProcessVerification isEntity={isEntity} />
    </div>
  );
};

export default IrsLetterEvidencingVerification;
