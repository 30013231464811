import * as React from 'react';
import { Button } from '../Button/Button';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogActions,
  DialogProps as MuiDialogProp,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import { PORTAL } from '../../constants/portal';
import { TButton } from '../../..';

export interface DialogProps extends MuiDialogProp {
  open: boolean;
  title?: string;
  buttons?: BtnType[];
  children: any;
  hasStartIcon?: boolean;
  startIcon?: React.ReactElement;
  onClose?: (event: any) => void;
  transparentTitle?: boolean;
  portalName?: string;
  showDownloadIcon?: boolean;
  onDownload?: ClickHandlerType;
  onResetFilter?: ClickHandlerType;
}

interface BtnType {
  id: number;
  title: string;
  variant?: 'contained' | 'outlined' | 'text';
  buttonAction: ClickHandlerType;
  disabled?: boolean;
}
interface ClickHandlerType {
  (param?: any): void;
}

export interface StyleProps {
  transparentTitle?: boolean;
  matches?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
}));

export interface DialogTitleProps {
  children?: React.ReactNode;
  transparentTitle?: boolean;
  portalName?: string;
  showDownloadIcon?: boolean;
  onClose: ((event: any) => void) | undefined;
  onDownload?: ClickHandlerType;
  onResetFilter?: ClickHandlerType;
}

const DialogTitle = ({
  portalName = PORTAL.MYDE,
  children,
  showDownloadIcon = false,
  onClose,
  onDownload,
  onResetFilter,
}: DialogTitleProps) => {
  const matches = portalName === PORTAL.MERCHANT;
  return (
    <MuiDialogTitle>
      <div className="flex-basic-space-between">
        <div className={clsx(matches ? 'text-h2' : 'text-h3', 'textColor-200 font-weight-semibold')}>{children}</div>
        <Box className="flex-basic-center">
          {onResetFilter ? (
            <Box sx={{ mr: 4 }}>
              <TButton
                icon={
                  <>
                    <Box sx={{ mr: 1 }} className="icon-reset icon-size-14"></Box>
                  </>
                }
                btnText="Reset"
                variant="text"
                btnVariantClass="buttonWithTextIcon button-small"
                onClick={onResetFilter}
              />
            </Box>
          ) : null}
          {onClose ? (
            <Box className="flex-basic-center">
              {showDownloadIcon && (
                <Box
                  sx={{ mr: 2 }}
                  className="icon-download-files textColor-200 cursorPointer text-font-24"
                  onClick={onDownload}
                ></Box>
              )}
              <Box className="icon-close icon-size-24 textColor-200 cursorPointer" onClick={onClose}></Box>
            </Box>
          ) : null}
        </Box>
      </div>
    </MuiDialogTitle>
  );
};

export const TDialog = ({
  children,
  open,
  title,
  onClose,
  buttons,
  hasStartIcon,
  startIcon,
  transparentTitle,
  portalName = PORTAL.MYDE,
  showDownloadIcon = false,
  onDownload,
  onResetFilter,
  ...props
}: DialogProps) => {
  const matches = portalName === PORTAL.MERCHANT;
  const styledProps = {
    transparentTitle,
    matches,
  };

  const classes = useStyles(styledProps);
  return (
    <>
      <MuiDialog fullWidth={true} {...props} open={open}>
        <Box className={clsx(classes.titleContainer, 'modalHeader')}>
          {hasStartIcon && startIcon && <Box className="iconWithTitle">{startIcon}</Box>}
          {title && (
            <DialogTitle
              transparentTitle
              onClose={onClose}
              portalName={portalName}
              showDownloadIcon={showDownloadIcon}
              onDownload={onDownload}
              onResetFilter={onResetFilter}
            >
              <Box sx={{ pl: hasStartIcon ? 3 : '0px!important' }}> {title}</Box>
            </DialogTitle>
          )}
        </Box>

        <DialogContent id="scrollableDiv">
          <Box sx={{ pb: 4, pl: matches ? '' : '0px!important' }}>{children}</Box>
        </DialogContent>
        <DialogActions>
          {buttons &&
            buttons.map((buttonElement) => (
              <Button
                disabled={buttonElement.disabled}
                key={buttonElement.id}
                btnText={buttonElement.title}
                variant={buttonElement.variant}
                color="primary"
                onClick={buttonElement.buttonAction}
              />
            ))}
        </DialogActions>
      </MuiDialog>
    </>
  );
};

export default TDialog;
