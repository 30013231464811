import React from 'react';
import { Backdrop, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { colorPalette } from '../../constants/colors';
import { useTheme } from '../../../../../providers/custom-theme-provider';

interface LoaderProps {
  loading: boolean;
}

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  backdrop: {
    opacity: '1 !important',
    transition: 'none !important',
    color: colorPalette.backgroundFadeColors.secondary,
    zIndex: '9999 !important',
  },
  loader: {
    '&:after': {
      borderBottomColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
  },
});

export const Loader = ({ loading, ...props }: LoaderProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  return (
    <Backdrop className={classes.backdrop} open={loading} {...props}>
      <Box className="customLoader">
        <span className={clsx('innerLoader', classes.loader)}> </span>
      </Box>
    </Backdrop>
  );
};

export default Loader;
