import React, { useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import zxcvbn from 'zxcvbn';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { hexToRgbA } from '../../utils/commonMethods';
import { PASSWORD_STRENGTH_LABEL_BY_SCORE } from '../../constants/constants';
import { colorPalette } from '../../constants/colors';

const useStyles = makeStyles({
  strengthWeak: {
    backgroundColor: `${hexToRgbA(colorPalette.error, 0.5)} !important`,
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: `${colorPalette.error} !important`,
    },
  },
  strengthFair: {
    backgroundColor: `${hexToRgbA(colorPalette.warning, 0.5)} !important`,
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: `${colorPalette.warning} !important`,
    },
  },
  strengthGood: {
    backgroundColor: `${hexToRgbA(colorPalette.rating, 0.5)} !important`,
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: `${colorPalette.rating} !important`,
    },
  },
  strengthStrong: {
    backgroundColor: `${hexToRgbA(colorPalette.success, 0.5)} !important`,
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: `${colorPalette.success} !important`,
    },
  },
});

interface PasswordStrengthMeterProps {
  password: string;
  isError: boolean;
}

export const PasswordStrengthMeter = ({ password, isError }: PasswordStrengthMeterProps) => {
  // Constants
  const classes = useStyles();
  const MAX_PASSWORD_SCORE = 4;
  const MIN_PASSWORD_SCORE = 0;

  // State Values
  const [score, setScore] = useState(MIN_PASSWORD_SCORE);
  const [scorePercent, setScorePercent] = useState(MIN_PASSWORD_SCORE);
  const [strengthLabel, setStrengthLabel] = useState('');

  // Use Effects
  useEffect(() => {
    if (password) {
      const testedResult = zxcvbn(password);
      const calculatedScore =
        testedResult?.score > MIN_PASSWORD_SCORE && !isError
          ? (testedResult?.score / MAX_PASSWORD_SCORE) * 100
          : MIN_PASSWORD_SCORE;
      const currentScore =
        testedResult?.score > MIN_PASSWORD_SCORE && !isError ? testedResult?.score : MIN_PASSWORD_SCORE;
      setScorePercent(calculatedScore);
      setScore(currentScore);
    }
  }, [password]);
  useEffect(() => {
    setStrengthLabel(PASSWORD_STRENGTH_LABEL_BY_SCORE[score]);
  }, [score]);

  // HTML
  return (
    <>
      {!!password && (
        <Box sx={{ mt: 1 }}>
          <Box sx={{ mb: 1 }} className="text-small textColor-300 flex-basic-start textCapitalize">
            <Box>Password strength:</Box>
            <Box sx={{ ml: 1 }}>{strengthLabel}</Box>
          </Box>
          <LinearProgress
            variant="determinate"
            value={scorePercent}
            className={clsx({
              [classes.strengthWeak]: strengthLabel === PASSWORD_STRENGTH_LABEL_BY_SCORE[0],
              [classes.strengthFair]: strengthLabel === PASSWORD_STRENGTH_LABEL_BY_SCORE[2],
              [classes.strengthGood]: strengthLabel === PASSWORD_STRENGTH_LABEL_BY_SCORE[3],
              [classes.strengthStrong]: strengthLabel === PASSWORD_STRENGTH_LABEL_BY_SCORE[4],
            })}
          />
        </Box>
      )}
    </>
  );
};

export default PasswordStrengthMeter;
