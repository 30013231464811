import { BottomNavigation, BottomNavigationAction, Theme } from '@mui/material';
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectUser, setEntitySelectionFlag } from '../../redux/feature/user/userSlice';
import { selectProfile } from '../../redux/feature/profile/profileSlice';
import { ClickHandlerType } from '../../types/commonTypes';
import { selectSession, setActiveCardNav } from '../../redux/feature/common/commonSlice';
import { hexToRgbA } from '../../utils/utils';
import { useTheme } from '../../providers/custom-theme-provider';
import { ID_CARD_NAV_INDEX } from '../../constants/constants';
import { TCommonAvatar } from '../myde-react-components';

interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  navBar: {
    position: 'relative',
    '& .userIcon': {
      marginTop: '-5px',
    },
    '& .MuiBottomNavigationAction-label': {
      position: 'absolute',
      top: 12,
      opacity: 1,
      '&.Mui-selected': {
        backgroundColor: 'transparent!important',
      },
    },
    '& .navIcon': {
      marginTop: 18,
    },
    '& .Mui-selected': {
      backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
      borderRadius: 10,
      '& .fontIconSize': {
        color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
      },
    },
  },
});

interface NavBarProps {
  onUpdateNavItem: ClickHandlerType;
  navItems: Array<NavItemType>;
}
interface NavItemType {
  icon: string;
  label: string;
  component?: JSX.Element;
  class?: string;
}

const NavBar = ({ navItems, onUpdateNavItem }: NavBarProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);

  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);

  const { userData } = useSelector(selectUser);
  const { profileUrl } = useSelector(selectProfile);
  const { selectedActiveCardNav } = useSelector(selectSession);

  // Use Effects
  useEffect(() => {
    if (selectedActiveCardNav && selectedActiveCardNav < ID_CARD_NAV_INDEX.ENTITY_SELECTION) {
      setValue(selectedActiveCardNav);
      onUpdateNavItem(selectedActiveCardNav);
    } else if (selectedActiveCardNav === ID_CARD_NAV_INDEX.ENTITY_SELECTION) {
      setValue(0);
      onUpdateNavItem(0);
      dispatch(setEntitySelectionFlag(true));
    }
  }, [selectedActiveCardNav]);

  // Methods
  const setActiveTab = (idx: number) => {
    dispatch(setActiveCardNav(idx));
  };

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        onUpdateNavItem(newValue);
      }}
      className={clsx({
        [classes.navBar]: true,
        ['navBarStyle']: true,
      })}
    >
      {navItems?.map((element, index) => {
        return (
          <BottomNavigationAction
            key={index}
            label={`${element?.label}`}
            className={clsx(element?.class, { ['notificationIndicator']: index === 2 })}
            icon={
              element?.icon ? (
                <span className={element?.icon} />
              ) : (
                <span className="userIcon">
                  <TCommonAvatar
                    imageUrl={profileUrl}
                    avatarText={`${userData?.account?.first_name || ''} ${userData?.account?.last_name || ''}`}
                    iconSize={'29px'}
                  />
                </span>
              )
            }
            onClick={() => {
              setActiveTab(index);
            }}
          />
        );
      })}
    </BottomNavigation>
  );
};

export default NavBar;
