import React, { useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import SearchEntity from './SearchEntity';
import EntityResult from './EntityResult';
import FormIndex from '../Form/FormIndex';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  firstStep: {
    display: 'flex',
    justifyContent: 'center',
  },
  pageContainer: {
    marginBottom: 100,
  },
});

const EntitiesIndex = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const setStep = (step: number) => {
    setActiveStep(step);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <SearchEntity onSearch={setStep} />;
      case 1:
        return <EntityResult />;
      case 2:
        return <FormIndex />;
    }
  };

  return (
    <Box className={classes.pageContainer}>
      <Box>{getStepContent(activeStep)}</Box>
    </Box>
  );
};

export default EntitiesIndex;
