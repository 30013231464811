import React, { useEffect } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectEntity, resetAddEntityResultData } from '../../../redux/feature/entity/entitySlice';
import { theme } from '../../myde-react-components';
import EntityIdProcessIndex from './EntityIdProcessIndex';
import { getEntityProfileDetailsList } from '../../../redux/feature/profile/entityProfileSlice';

const useStyles = makeStyles({
  pageContainer: {
    minHeight: '100vh',
  },
});

const EntityMyIdentity = () => {
  const classes = useStyles();
  const { selectedEntity } = useSelector(selectEntity);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAddEntityResultData());
  }, []);

  useEffect(() => {
    if (selectedEntity?.id) {
      dispatch(getEntityProfileDetailsList({ entityUId: selectedEntity?.trellis_uid, params: {} }));
    }
  }, [selectedEntity]);

  return (
    <Box sx={{ pt: 8 }} className={classes.pageContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ mt: theme.spacing(10), mb: 1 }} className="text-h2 textColor-200 text-center font-weight-semibold">
            {t('idVerification', { ns: 'common' })}
          </Box>
          <Divider sx={{ mb: 2 }} />
          <EntityIdProcessIndex tid={selectedEntity?.trellis_uid || 0} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EntityMyIdentity;
