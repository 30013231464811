import React from 'react';
import { Box, Theme } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FilePreviewType, TFullScreenDialog } from '../myde-react-components';
import { ClickHandlerType, PhotoCaptureInstructionProps, UploadCaptureButtons } from '../../types/commonTypes';
import CapturePhoto from './CapturePhoto';
import SingleAndMultipleUpload from './SingleAndMultipleUpload';
import PhotoCaptureInstruction from './PhotoCaptureInstruction';

interface UploadOrCapturePhotoInstructionProps {
  acceptedFileFormat: string[];
  maxFileSize: number;
  isMultiple: boolean;
  uploadedFile: FilePreviewType[];
  captureInstruction?: PhotoCaptureInstructionProps;
  uploadInstruction?: PhotoCaptureInstructionProps;
  clickedButton: UploadCaptureButtons | null;
  setData?: (file: File) => void;
  setMultipleFileData?: (file: FilePreviewType[], oldFiles: FilePreviewType[]) => void;
  setShowMultipleCapture?: ClickHandlerType;
  multipleFilesHandler: (newFiles: FilePreviewType[], oldFiles: FilePreviewType[]) => void;
  setCapturedImages: (data: FilePreviewType[]) => void;
  setClickedButton: React.Dispatch<React.SetStateAction<UploadCaptureButtons | null>>;
}

export interface StyleProps {
  currentThemes: Theme;
  matches: boolean;
  clickedButton: UploadCaptureButtons | null;
}

const UploadOrCaptureInstructionDialog = ({
  acceptedFileFormat,
  maxFileSize,
  isMultiple,
  uploadedFile,
  captureInstruction,
  uploadInstruction,
  clickedButton,
  setData,
  setMultipleFileData,
  setShowMultipleCapture,
  multipleFilesHandler,
  setCapturedImages,
  setClickedButton,
}: UploadOrCapturePhotoInstructionProps) => {
  // HTML
  return (
    <TFullScreenDialog
      title={''}
      closeModal={() => setClickedButton(null)}
      open={clickedButton !== null}
      topMargin={clickedButton === UploadCaptureButtons.UPLOAD ? '38%' : '8%'}
      dialogContent={
        <>
          <Box className="flex-basic-space-between textColor-200 text-h3 font-weight-semibold" sx={{ py: 2, px: 2 }}>
            <Box className="w-100 text-left">
              <Box sx={{ ml: 1 }}>
                {clickedButton === UploadCaptureButtons.CAPTURE && captureInstruction?.instructionTitle}
                {clickedButton === UploadCaptureButtons.UPLOAD && uploadInstruction?.instructionTitle}
              </Box>
            </Box>
            <Box>
              <CloseOutlinedIcon onClick={() => setClickedButton(null)} className={'cursorPointer'} />
            </Box>
          </Box>
          <Box sx={{ px: 3 }}>
            {clickedButton === UploadCaptureButtons.CAPTURE && (
              <>
                <PhotoCaptureInstruction {...(captureInstruction as PhotoCaptureInstructionProps)} />
                <Box sx={{ mt: 2 }} className="w-100 flex-basic-center">
                  <CapturePhoto
                    canCaptureMultiple={isMultiple}
                    btnText="Capture"
                    btnVariant="contained"
                    btnWidthSize="button-w-240"
                    setFiles={setCapturedImages}
                    setMultipleData={setMultipleFileData}
                    setShowMultipleCapture={setShowMultipleCapture}
                  />
                </Box>
              </>
            )}

            {clickedButton === UploadCaptureButtons.UPLOAD && (
              <>
                <PhotoCaptureInstruction {...(uploadInstruction as PhotoCaptureInstructionProps)} />
                <Box sx={{ mt: 3 }} className="w-100 flex-basic-center">
                  <SingleAndMultipleUpload
                    isMultiple={isMultiple}
                    acceptedFileFormat={acceptedFileFormat}
                    maxFileSize={maxFileSize}
                    setData={setData}
                    uploadedFiles={uploadedFile}
                    setMultipleData={multipleFilesHandler}
                    btnText="Upload from gallery"
                    btnVariant="contained"
                    btnWidthSize="button-w-240"
                    showIcon={false}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      }
    ></TFullScreenDialog>
  );
};

export default UploadOrCaptureInstructionDialog;
