import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Box } from '@mui/material';

interface SkeletonLoaderProps {
  loading: boolean;
  skeletonLineCount?: number;
  skeletonBaseColor?: string;
  skeletonhighlightColor?: string;
  skeletonWidth?: string;
  skeletonHeight?: number;
}

export const SkeletonLoader = ({
  loading,
  skeletonLineCount = 1,
  //colors need to be add in style guide
  skeletonBaseColor = '#ebebeb',
  skeletonhighlightColor = '#dcdcdc',
  skeletonWidth = '100%',
  skeletonHeight,
}: SkeletonLoaderProps) => {
  return (
    <>
      {loading && (
        <>
          <SkeletonTheme baseColor={skeletonBaseColor} highlightColor={skeletonhighlightColor}>
            <Box className="w-100">
              <Skeleton height={skeletonHeight} width={skeletonWidth} count={skeletonLineCount} />
            </Box>
          </SkeletonTheme>
        </>
      )}
    </>
  );
};
export default SkeletonLoader;
