import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import * as ROUTES from '../../../constants/routes';
import { TCard, TLoader } from '../../../components/myde-react-components';
import { useTheme } from '../../../providers/custom-theme-provider';
import { hexToRgbA } from '../../../utils/utils';
import { MessagesLandingType } from '../../../types/messageTypes';
import { useRouter } from '../../../providers/custom-router-provider';
import { setParentRoute, setPreviousStep } from '../../../redux/feature/common/commonSlice';
import { useTranslation } from 'react-i18next';
import { getApplicationsListById, messageDetails } from '../../../redux/feature/message/messageSlice';
import { MESSAGES_UNREAD_COUNT_KEYS } from '../../../constants/constants';
import useQuery from '../../../hooks/useQuery';

// Interfaces
interface StyleProps {
  primaryColor: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  boldUnreadCount: {
    borderColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: ({ primaryColor }) => `${primaryColor} !important`,
    backgroundColor: ({ primaryColor }) => hexToRgbA(`${primaryColor}`, 0.08),
  },
});

// Component
const MerchantNavItems = () => {
  // Constants
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes?.palette?.primary?.main;
  const styleProps: StyleProps = {
    primaryColor,
  };
  const classes = useStyles(styleProps);
  const { routeTo } = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const query = useQuery();
  const applicationId = query.get('id') || '';
  const isEntityMerchant = query.get('is_entity') === 'true';
  const merchantName = query.get('merchant') || '';
  const invite_type = query.get('invite_type') || '';
  const entity_uid = query.get('entity_uid') || '';
  const menuItems: MessagesLandingType[] = [
    {
      label: t('applicationMessages'),
      icon: 'icon-chat',
      routePath: ROUTES.APPLICATION_LIST,
      stateName: t('applicationMessages'),
      count: 0,
      countKey: MESSAGES_UNREAD_COUNT_KEYS.APPLICATION_MESSAGES,
      query: { merchant_id: applicationId, invite_type, entity_uid, is_entity: isEntityMerchant },
    },
    {
      label: t('myInbox'),
      icon: 'icon-messages',
      routePath: ROUTES.MY_INBOX,
      stateName: t('myInbox'),
      count: 0,
      countKey: MESSAGES_UNREAD_COUNT_KEYS.MY_INBOX,
      query: {
        merchant_id: applicationId,
        entity_uid,
        is_entity: isEntityMerchant,
        invite_type,
        merchant: merchantName,
      },
    },
    {
      label: t('textDocuments'),
      icon: 'icon-document',
      routePath: ROUTES.MY_DOCUMENTS,
      stateName: t('textDocuments'),
      count: 0,
      countKey: MESSAGES_UNREAD_COUNT_KEYS.MY_DOCUMENTS,
      query: { section: 'Messages', applicationId, entity_uid, invite_type, isEntity: isEntityMerchant },
    },
    {
      label: t('tAppText'),
      icon: 'icon-statistics',
      routePath: ROUTES.COMING_SOON,
      stateName: t('tAppText'),
      count: 0,
      countKey: '',
    },
    {
      label: t('sharingText'),
      icon: 'icon-upload',
      routePath: ROUTES.COMING_SOON,
      stateName: t('sharingText'),
      count: 0,
      countKey: '',
    },
  ];

  // State Variables
  const [optionsList, setOptionsList] = useState(menuItems);

  //Redux Values
  const { loading, specificApplicationDetail } = useSelector(messageDetails);

  // Use Effects
  useEffect(() => {
    const route = {
      path: ROUTES.USER_MESSAGES,
    };
    dispatch(setParentRoute(route));
    dispatch(setPreviousStep(null));
    const filters = { id: applicationId, merchant_name: merchantName };
    dispatch(getApplicationsListById(filters));
  }, []);

  useEffect(() => {
    if (Object.keys(specificApplicationDetail)?.length > 0) {
      const application = isEntityMerchant
        ? specificApplicationDetail?.entities[0]?.merchants[0]
        : specificApplicationDetail?.user[0];
      const updatedMenuList = menuItems?.map((item: MessagesLandingType) => {
        return {
          ...item,
          count: getSpecificItemCount(application, item?.countKey),
        };
      });
      setOptionsList(updatedMenuList);
    }
  }, [specificApplicationDetail]);

  // Methods
  const getSpecificItemCount = (applicationDetail: any, key: string) => {
    switch (key) {
      case MESSAGES_UNREAD_COUNT_KEYS.APPLICATION_MESSAGES:
        return applicationDetail?.total_unread_response_count;
      case MESSAGES_UNREAD_COUNT_KEYS.MY_INBOX:
        return applicationDetail?.inbox_unread_count;
      case MESSAGES_UNREAD_COUNT_KEYS.MY_DOCUMENTS:
        return applicationDetail?.document_unread_count;
      default:
        return 0;
    }
  };

  const navigateToSpecificSection = (data: MessagesLandingType) => {
    routeTo(data?.routePath, true, data?.stateName, data?.query);
  };

  // HTML
  return (
    <>
      <TLoader loading={loading} />
      <TCard sx={{ px: 1, pt: 1, pb: 3 }}>
        {optionsList?.map((item, index: number) => (
          <Box key={index}>
            <Box
              className="flex-basic-space-between cursorPointer"
              sx={{ p: 1, my: 1 }}
              onClick={() => navigateToSpecificSection(item)}
            >
              <Box className="flex-basic-center">
                <Box className={`${item?.icon} textColor-200 text-h3`} sx={{ pr: 3 }} />
                <Box className="text-medium font-weight-semibold textColor-200">{item?.label}</Box>
              </Box>
              <Box sx={{ pl: 5 }}>
                {item?.count > 0 ? (
                  <Box className={clsx('unreadCountBox', classes.boldUnreadCount)}>{`${item?.count} new`}</Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            <Divider />
          </Box>
        ))}
      </TCard>
    </>
  );
};

export default MerchantNavItems;
