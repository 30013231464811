import React from 'react';
import { Box } from '@mui/material';

import SelectAccreditation from './SelectAccreditation';
import { theme } from '../../../myde-react-components';
import { useTranslation } from 'react-i18next';
interface AccreditationConfirmationProps {
  isEntity: boolean;
}

const AccreditationConfirmation = ({ isEntity }: AccreditationConfirmationProps) => {
  const { t } = useTranslation('common');
  return (
    <Box sx={{ mt: 1 }}>
      <Box sx={{ pb: theme.spacing(5) }} className="text-h3 textColor-200 text-center font-weight-semibold">
        {t('accreditationHeading', { ns: 'accreditation' })}
      </Box>
      <SelectAccreditation isEntity={isEntity} />
    </Box>
  );
};

export default AccreditationConfirmation;
