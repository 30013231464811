import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { ID_PROCESS_KEYS } from '../../myde-react-components/src/constants/constants';
import SsnVerification from '../components/tax-id/SsnVerification';
import {
  getEntityProcessDetailsById,
  getProcessDetailsById,
  selectSelectedIdProcessDetails,
} from '../../../redux/feature/idProcess/mainIdProcessSlice';
import { setIsEditAllowedFlag, setPreviousStep } from '../../../redux/feature/common/commonSlice';
import { TLoader } from '../../myde-react-components';
import IncomeVerification from '../components/income-verification/IncomeVerification';
import {
  AUTHORIZATION_STATUS,
  ENTITY_ID_PROCESS_TEXTS,
  ID_PROCESS_TEXTS,
  SHOW_SHARED_SUMMARY,
} from '../../../constants/constants';
import W9W8BenVerification from '../components/w9-w8-ben/W9W8BenVerification';
import ProfileVerification from '../components/profile/ProfileVerification';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';
import BiometricVerification from '../components/biometric/BiometricVerification';
import AccreditationVerification from '../components/accreditation/AccreditationVerification';
import AddressVerification from '../components/address-verification/AddressVerification';
import WetSignatureVerification from '../components/wet-signature/WetSignatureVerification';
import DocumentFormationVerification from '../components/document-formation/DocumentFormationVerification';
import LetterOfGoodStandingVerification from '../components/letter-of-good-standing/LetterOfGoodStandingVerification';
import IrsLetterEvidencingVerification from '../components/irs-letter-evidencing/IrsLetterEvidencingVerification';
import OwnershipDocVerification from '../components/ownership-documents/OwnershipDocVerification';
import DocumentSummary from '../components/document-verification/components/DocumentSummary';
import {
  getIdProcessSharedCount,
  getIdProcessSharedCountForEntity,
  getSharedMerchantsList,
  idProcessShareDetails,
} from '../../../redux/feature/idProcess/idProcessShareSlice';
import * as ROUTES from '../../../constants/routes';
import { useRouter } from '../../../providers/custom-router-provider';
import ViewShareDetailsCard from './ViewShareDetailsCard';
import { checkIsIdProcessEditAllowed } from '../../../utils/utils';
import Index from '../components/kyc-aml/Index';

interface VerificationProps {
  isEntity: boolean;
}

const IdProcessVerification = ({ isEntity }: VerificationProps) => {
  // Constants
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const ID_PROCESS_COMPONENTS_COMPLETED = [
    {
      processKey: ID_PROCESS_KEYS.PROFILE,
      component: <ProfileVerification isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.SSN,
      component: <SsnVerification />,
    },
    {
      processKey: ID_PROCESS_KEYS.W9_W8_BEN,
      component: <W9W8BenVerification isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.INCOME,
      component: <IncomeVerification />,
    },
    {
      processKey: ID_PROCESS_KEYS.BIOMETRIC,
      component: <BiometricVerification />,
    },
    {
      processKey: ID_PROCESS_KEYS.ACCREDITATION,
      component: <AccreditationVerification isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.ADDRESS,
      component: <AddressVerification />,
    },
    {
      processKey: ID_PROCESS_KEYS.SIGNATURE,
      component: <WetSignatureVerification />,
    },
    {
      processKey: ID_PROCESS_KEYS.FORMATION_DOCUMENT,
      component: <DocumentFormationVerification isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.LETTER_OF_GOOD_STANDING,
      component: <LetterOfGoodStandingVerification isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.IRS_LETTER_EVIDENCING_TAX_ID,
      component: <IrsLetterEvidencingVerification isEntity={isEntity} />,
    },
    {
      processKey: ID_PROCESS_KEYS.OWNERSHIP_DOCUMENTS,
      component: <OwnershipDocVerification />,
    },
    {
      processKey: ID_PROCESS_KEYS.DOCUMENT_VERIFICATION,
      component: <DocumentSummary />,
    },
    {
      processKey: ID_PROCESS_KEYS.KYC,
      component: <Index />,
    },
  ];

  // Redux Values
  const { selectedProcessKey, processId, selectedIdProcessDetails, selectedEntityIdProcessDetails } =
    useSelector(selectSelectedIdProcessDetails);
  const { selectedEntity } = useSelector(selectEntity);
  const { merchantCount, merchantCountForEntity, loading, hideIdShareTab } = useSelector(idProcessShareDetails);

  // State Values
  const [idProcessKey, setIdProcessKey] = useState('');
  const [idProcess, setIdProcess] = useState({} as any);
  const [merchantSharedCount, setMerchantSharedCount] = useState(0);

  // Use Effects
  useEffect(() => {
    dispatch(setPreviousStep(null));
    if (isEntity) {
      dispatch(
        getEntityProcessDetailsById({
          processId: processId,
          entityUid: selectedEntity?.trellis_uid,
        }),
      );
    } else {
      dispatch(getProcessDetailsById({ processId: processId }));
    }
  }, []);

  useEffect(() => {
    if (selectedProcessKey) {
      setIdProcessKey(selectedProcessKey);
    }
  }, [selectedProcessKey]);

  useEffect(() => {
    const result = isEntity ? selectedEntityIdProcessDetails : selectedIdProcessDetails;
    setIdProcess(result);
  }, [isEntity, selectedIdProcessDetails, selectedEntityIdProcessDetails]);

  useEffect(() => {
    if (idProcess?.status && checkIsIdProcessEditAllowed(processId, idProcess?.status)) {
      dispatch(setIsEditAllowedFlag(true));
    } else {
      dispatch(setIsEditAllowedFlag(false));
    }
  }, [idProcess, processId]);

  useEffect(() => {
    if (!isEntity) {
      const data = getSharedProfileData(processId, 1);
      dispatch(getIdProcessSharedCount(data));
    } else {
      const data = {
        entity_uid: selectedEntity?.trellis_uid,
        params: {
          process_id: processId,
        },
      };
      dispatch(getIdProcessSharedCountForEntity(data));
    }
  }, [isEntity]);

  useEffect(() => {
    setMerchantSharedCount(isEntity ? merchantCountForEntity : merchantCount);
  }, [merchantCount, merchantCountForEntity]);

  // Methods
  const updateIdProcessComponent = () => {
    const currentComponentItem = ID_PROCESS_COMPONENTS_COMPLETED.find((item) => item?.processKey === idProcessKey);
    return currentComponentItem?.component || <></>;
  };

  const handleViewClick = async () => {
    const query = {
      isEntity: true,
    };
    dispatch(setIsEditAllowedFlag(false));
    if (!isEntity) {
      const data = getData(['id', 'merchant, created_on'], processId, 1000);
      await dispatch(getSharedMerchantsList(data));
    }
    routeTo(
      ROUTES.SHARED_ID,
      true,
      `${isEntity ? ENTITY_ID_PROCESS_TEXTS[processId] : ID_PROCESS_TEXTS[processId]}`,
      isEntity && query,
    );
  };

  const getData = (query: string[], process_Id: number, limit: number) => {
    const data = {
      authorization_status: AUTHORIZATION_STATUS.SHARED,
      page: 1,
      process_id: process_Id,
      query: `{ ${query.toString()} }`,
      limit: limit,
    };
    return data;
  };

  const getSharedProfileData = (process_Id: number, limit: number) => {
    const data = {
      authorization_status: AUTHORIZATION_STATUS.SHARED,
      page: 1,
      process_id: process_Id,
      limit: limit,
    };
    return data;
  };

  // HTML
  return (
    <Box>
      <TLoader loading={loading} />
      <>{updateIdProcessComponent()}</>
      {merchantSharedCount > 0 && !hideIdShareTab && !SHOW_SHARED_SUMMARY?.includes(processId) && (
        <ViewShareDetailsCard
          handleViewClick={handleViewClick}
          merchantSharedCount={merchantSharedCount}
          processId={processId}
          isEntity={isEntity}
        />
      )}
    </Box>
  );
};

export default IdProcessVerification;
