import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { colorPalette, TButton, TCard, TCommonAvatar, theme } from '../../components/myde-react-components';
import { selectEntity } from '../../redux/feature/entity/entitySlice';
import { CreatedRequestType } from '../../types/entityTypes';
import { fontStyle } from '../../styles/style';
import { Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { hexToRgbA } from '../../utils/utils';

const useStyles = makeStyles<Theme>({
  errorBackground: {
    borderColor: () => `${colorPalette.error} !important`,
    backgroundColor: () => `${hexToRgbA(`${colorPalette.error}`, 0.08)} !important`,
  },
});

const EntityInviteDecline = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { selectedRequest } = useSelector(selectEntity);

  //state variables
  const [data, setData] = useState({} as CreatedRequestType);

  //useEffects
  useEffect(() => {
    if (Object.keys(selectedRequest)?.length > 0) {
      setData(selectedRequest);
    }
  }, [selectedRequest]);

  return (
    <Box sx={{ mb: 8 }}>
      <TCard>
        <Box sx={{ pt: theme.spacing(5), pb: 4 }} className="font-weight-semibold text-large text-center">
          {t('entityAssociationRequest')}
        </Box>
        <Box>
          <TCommonAvatar
            imageUrl={data?.requested_account?.avatar_url || ''}
            avatarText={data?.requested_account?.name || ''}
            iconSize="6.563rem"
            textSize={fontStyle.fontSize.font36.fontSize}
          />
        </Box>
        <Box sx={{ pt: 6 }}>
          <Box sx={{ px: 3 }}>
            <TCard className={classes.errorBackground}>
              <Box
                sx={{ pt: theme.spacing(5), pl: theme.spacing(5), pr: theme.spacing(3) }}
                className="flex-basic-start"
              >
                <Box className="wrapperError flex-basic-center">
                  <Box className="icon-error-warning error-color-state icon-size-24"></Box>
                </Box>
                <Box sx={{ pl: theme.spacing(3) }} className="font-weight-bold text-large">
                  {t('requestDeclined')}
                </Box>
              </Box>
              <Box sx={{ pt: theme.spacing(3), px: theme.spacing(5), pb: theme.spacing(5) }} className="text-small">
                {data?.details?.note}
              </Box>
            </TCard>
          </Box>
          <Box sx={{ pt: theme.spacing(5), pl: 3 }} className="text-small textColor-300">
            {t('entityTid')}
          </Box>
          <Box sx={{ pl: 3 }} className="text-medium font-weight-semibold">
            {data?.requested_account?.trellis_uid}
          </Box>
          <Box sx={{ pt: theme.spacing(5), pl: 3 }} className="text-small textColor-300">
            {t('entityName')}
          </Box>
          <Box sx={{ pl: 3 }} className="text-medium font-weight-semibold">
            {data?.requested_account?.name}
          </Box>
          <Divider sx={{ pt: theme.spacing(7) }} />
          <Box sx={{ pt: 3, px: theme.spacing(3) }} className="text-center text-medium">
            {t('declineResendText')}
          </Box>
          <Box sx={{ pt: theme.spacing(7), pb: 3 }} className="text-center">
            <TButton btnText="Resend" btnWidthSize="button-w-240" variant="contained" />
          </Box>
        </Box>
      </TCard>
    </Box>
  );
};

export default EntityInviteDecline;
