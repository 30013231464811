import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { colorPalette, theme } from '../../../components/myde-react-components';
import { ClickHandlerType } from '../../../types/commonTypes';

export interface MessageBoxProps {
  messageType?: string | '';
  messageDate: string;
  messageTime: string;
  responder: string;
  message: string;
  requestorType?: string;
  openDialog?: boolean;
  onClose?: ClickHandlerType;
  isOnMessageScreen?: boolean;
}

const useStyles = makeStyles(() => ({
  msgBox: {
    border: `1px solid ${colorPalette.typoText.darken3}`,
    borderRadius: 4,
  },
}));

const MessageBox = ({ messageDate, messageTime, message, responder }: MessageBoxProps) => {
  const classes = useStyles();

  return (
    <>
      <Box
        sx={{ mb: 2, px: theme.spacing(4), py: theme.spacing(3) }}
        className={clsx(classes.msgBox, 'msgBoxWithUnreadText')}
      >
        <Box>
          <Box className="text-extra-small textColor-300">
            <span>{`Asked on ${messageDate} at ${messageTime} by `}</span>
            <span className="textCapitalize">{`${responder.toLowerCase()}`}</span>
          </Box>
          <Box className="text-medium textColor-200 text-break-word">{message}</Box>
        </Box>
      </Box>
    </>
  );
};

export default MessageBox;
