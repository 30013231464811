import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Pagination, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TPaper, theme, TButton, colorPalette, TReadMore, MAX_CHAR_LIMIT } from '../../myde-react-components';
import { MerchantInvitationType } from '../../../types/invitationTypes';
import {
  getApplicationList,
  getInvitationList,
  getRequiredIdProcessList,
  selectInviteDashboardDetails,
  setApplicationModalFlag,
  setSelectedApplciation,
  updateMerchantInvitationStatus,
} from '../../../redux/feature/dashboard/invitationSlice';
import { APPLICATION_STATUS, INVITE_TYPE, MERCHANT_INVITE_STATUS } from '../../../constants/constants';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import {
  getEntityList,
  setIsEntity,
  setSelectedEntityInviteDetails,
  updateEntityApplicationStatus,
} from '../../../redux/feature/entity/entitySlice';
import clsx from 'clsx';
import { useTheme } from '../../../providers/custom-theme-provider';
import { hexToRgbA } from '../../../utils/utils';

interface StyleProps {
  matches: boolean;
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    '& .MuiButtonBase-root.MuiPaginationItem-root': {
      height: '15px',
      minWidth: '15px',
      border: `2px solid ${colorPalette.typoText.lighten1}`,
      borderRadius: '50%',
      padding: '5px',
      fontSize: 0,
      cursor: 'initial',
    },
    '& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
      backgroundColor: colorPalette.typoText.lighten1,
    },
  },
  entityInviteLabel: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
    border: ({ currentThemes }) => `1px solid ${currentThemes.palette.primary.main}`,
  },
}));

const NewInvitation = () => {
  // Constants
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { currentThemes } = useTheme();
  const styleProps = {
    matches,
    currentThemes,
  };
  const PER_PAGE = 1;

  const classes = useStyles(styleProps);
  const dispatch = useDispatch();

  const { t } = useTranslation(['common', 'inviteFlow']);
  const { routeTo } = useRouter();

  // Redux Values
  const { inviteList, selectedApplication } = useSelector(selectInviteDashboardDetails);

  // State Values
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [invitationList, setInvitationList] = useState([] as MerchantInvitationType[]);
  const [applicationDetails, setApplicationDetails] = useState({} as MerchantInvitationType);
  const [isView, setIsView] = useState(false);

  // Use Effects
  useEffect(() => {
    if (inviteList?.length > 0) {
      const invites = inviteList?.filter((invite: MerchantInvitationType) => {
        if ([MERCHANT_INVITE_STATUS.CREATED, MERCHANT_INVITE_STATUS.ACCOUNT_SIGNED_UP].includes(invite?.status))
          return invite;
      });
      setInvitationList(invites);
      setCount(Math.ceil(invites?.length / PER_PAGE));
    } else {
      setInvitationList([]);
    }
  }, [inviteList]);
  useEffect(() => {
    if (applicationDetails?.id && isView) {
      if (
        applicationDetails?.details?.id_requirements?.length > 0 ||
        applicationDetails?.details?.owner_id_requirements?.length > 0
      ) {
        dispatch(setApplicationModalFlag(true));
      } else if (applicationDetails?.invite_type === INVITE_TYPE.ENTITY) {
        dispatch(setApplicationModalFlag(true));
      } else {
        routeTo(ROUTES.VIEW_INVITE);
      }
      setIsView(false);
    }
  }, [applicationDetails, isView]);
  useEffect(() => {
    if (selectedApplication?.id) {
      setApplicationDetails(selectedApplication);
    }
  }, [selectedApplication]);

  // Methods
  const handleStepChange = (step: number) => {
    setPage(step + 1);
  };
  // Methods
  const viewInvitation = async (application: MerchantInvitationType) => {
    if (application?.id) {
      await dispatch(setSelectedApplciation(application));
      await dispatch(getRequiredIdProcessList(application.id));
      if (application?.invite_type === INVITE_TYPE.ENTITY) {
        await dispatch(getEntityList());
        dispatch(setSelectedEntityInviteDetails(application));
        dispatch(setIsEntity(true));
      } else {
        dispatch(setSelectedEntityInviteDetails({} as MerchantInvitationType));
        dispatch(setIsEntity(false));
      }
      setIsView(true);
    }
  };
  const dismissApplication = async (application: MerchantInvitationType) => {
    const reqData = {
      action: APPLICATION_STATUS.DISMISS,
      invitation_id: application?.id,
      merchant_uid: application?.merchant?.merchant_uid,
    };
    if (application?.invite_type === INVITE_TYPE.ENTITY) {
      await dispatch(updateEntityApplicationStatus(reqData));
    } else {
      await dispatch(updateMerchantInvitationStatus(reqData));
    }
    getUpdatedInvitationsAndApplications();
  };
  const getUpdatedInvitationsAndApplications = () => {
    dispatch(getInvitationList({ status: APPLICATION_STATUS.INVITED }));
    dispatch(
      getApplicationList({
        status_in: `{${MERCHANT_INVITE_STATUS.STARTED},${MERCHANT_INVITE_STATUS.UNDER_REVIEW},${MERCHANT_INVITE_STATUS.PENDING_REVIEW}}`,
      }),
    );
  };

  // HTML
  return (
    <Box>
      {invitationList?.length > 0 ? (
        <Box sx={{ pt: 2 }} className="marginTopLess">
          <Box sx={{ py: 2 }} className="textColor-200 text-h3 font-weight-semibold">
            You have ({invitationList?.length}) new invite
            {invitationList?.length > 1 && 's'}
          </Box>
          <Box className={classes.root}>
            <SwipeableViews enableMouseEvents onChangeIndex={handleStepChange}>
              {invitationList?.length > 0 &&
                invitationList?.map((invite: MerchantInvitationType) => (
                  <TPaper key={invite?.id}>
                    <Box className="text-center">
                      {invite?.invite_type === INVITE_TYPE.ENTITY && (
                        <>
                          <Box sx={{ p: 3 }}>
                            <Box sx={{ mb: 1 }} className="textColor-200 text-large font-weight-semibold">
                              {invite?.name}
                            </Box>
                            <Box className="flex-basic-center">
                              <Box
                                sx={{ px: theme.spacing(3), py: theme.spacing(1) }}
                                className={clsx(
                                  'text-small font-weight-semibold entityInviteLabelRedius w-50',
                                  classes.entityInviteLabel,
                                )}
                              >
                                {t('entityInvitation', { ns: 'inviteFlow' })}
                              </Box>
                            </Box>
                          </Box>
                          <Divider />
                        </>
                      )}
                      <Box sx={{ p: 3 }}>
                        <Box sx={{ px: 2 }} className="textColor-200 text-h3 font-weight-semibold">
                          {`${t('inviteTitle', { ns: 'inviteFlow' })} ${invite?.merchant?.name}!`}
                        </Box>
                        <Box sx={{ mt: 1 }} className="flex-basic-center">
                          <Box className="img-radius-box large-box">
                            <img src={invite?.merchant?.branding?.logo_url} loading="lazy" alt="Branding Logo" />
                          </Box>
                        </Box>
                        <Box sx={{ mb: 2, px: 2 }} className="textColor-200 text-h2 font-weight-semibold">
                          {invite?.details?.invite_attributes?.invite_title || ''}
                        </Box>
                        <Box sx={{ mb: 4, px: 2 }}>
                          <TReadMore
                            maxCharacters={MAX_CHAR_LIMIT.INVITE_MSG}
                            text={invite?.details?.invite_attributes?.invite_message || ''}
                          />
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <TButton
                            onClick={() => viewInvitation(invite)}
                            variant="contained"
                            btnText="View Details"
                            btnWidthSize="button-w-240"
                          />
                        </Box>
                        <Box sx={{ mt: 1 }}>
                          <TButton
                            onClick={() => dismissApplication(invite)}
                            btnWidthSize="button-w-240"
                            variant="text"
                            btnText="Dismiss"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </TPaper>
                ))}
            </SwipeableViews>
            {invitationList?.length === 1 || (
              <Box className="flex-basic-center">
                <Pagination
                  sx={{ mt: 2 }}
                  siblingCount={0}
                  boundaryCount={1}
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  hideNextButton
                  hidePrevButton
                />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default NewInvitation;
