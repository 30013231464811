import React, { useState, useEffect } from 'react';
import { Divider, Box, List, ListItem, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ID_DOC_TYPES, KYC_AML_RESULTS, PROCESS_IDS } from '../../../../../constants/constants';
import { DetailResultType, IdProcessDocItemResultType } from '../../../../../types/idProcessTypes';
import { theme } from '../../../../myde-react-components';
import { formatDate } from '../../../../../utils/utils';
import { selectSelectedIdProcessDetails } from '../../../../../redux/feature/idProcess/mainIdProcessSlice';
import { setDetailsList } from '../../../../../redux/feature/idProcess/documentVerificationSlice';
import DetailedReport from './DetailedReport';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface ReportProps {
  closeModal: () => void;
}

export const Report = ({ closeModal }: ReportProps) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const [result, setResult] = useState<Array<IdProcessDocItemResultType>>([]);
  const [detailedReport, setDetailedReport] = useState<any>(null);

  // Redux Values
  const { selectedIdProcessDetails, processId } = useSelector(selectSelectedIdProcessDetails);

  useEffect(() => {
    if (selectedIdProcessDetails?.result?.length > 0) {
      setResult(selectedIdProcessDetails?.result);
    }
  }, [selectedIdProcessDetails]);

  const expirationDate = selectedIdProcessDetails?.data?.document_expiry;
  const expirationData = {
    title: t('expirationDate'),
  };

  const checkReport = (item: DetailResultType) => {
    dispatch(setDetailsList(item));
    setDetailedReport(item);
  };

  const getSubTitle = () => {
    switch (processId) {
      case PROCESS_IDS.PASSPORT: {
        return t(ID_DOC_TYPES.PASSPORT, { ns: 'idDocumentFlow' });
      }
      case PROCESS_IDS.DRIVERS_LICENSE: {
        return t(ID_DOC_TYPES.LICENSE, { ns: 'idDocumentFlow' });
      }
      default:
        return '';
    }
  };

  const getAction = (status: string, item: DetailResultType) => {
    switch (status) {
      case KYC_AML_RESULTS.CLEAR:
        return (
          <IconButton edge="end" aria-label="delete">
            <Box className="flex-basic-center" onClick={() => checkReport(item)}>
              <span className="icon-approved success-color-state actionIconFont-24"></span>
            </Box>
          </IconButton>
        );
      case KYC_AML_RESULTS.CONSIDER:
        return (
          <IconButton edge="end" aria-label="delete">
            <Box className="flex-basic-center" onClick={() => checkReport(item)}>
              <span className="icon-error-warning error-color-state actionIconFont-24"></span>
            </Box>
          </IconButton>
        );
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <Box className="flex-basic-space-between  textColor-300 text-large font-weight-semibold" sx={{ py: 3, px: 2 }}>
        <Box className="flex-basic-start">
          <Box>
            {detailedReport && (
              <span
                onClick={() => {
                  setDetailedReport(null);
                }}
                className="cursorPointer flex-column-center  icon-arrow-lefts textColor-200 icon-size-24"
              ></span>
            )}
          </Box>
          <Box sx={{ ml: 1 }}>{t('idDocStep6')}</Box>
        </Box>
        <Box className="flex-basic-space-between">
          <CloseOutlinedIcon onClick={() => closeModal()} className={'cursorPointer'} />
        </Box>
      </Box>
      <Divider />

      {!detailedReport ? (
        <>
          <Box sx={{ px: 3, py: theme.spacing(5) }}>
            <Box className="flex-basic-space-between">
              <Box>
                <Box className="textColor-200 text-small font-weight-regular" sx={{ mb: 1 }}>
                  {t('idType')}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">{getSubTitle()}</Box>
              </Box>
              <Box>
                <Box className="textColor-200 text-small font-weight-regular" sx={{ mb: 1 }}>
                  {expirationData?.title}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">{formatDate(expirationDate)}</Box>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ px: 3, py: 2 }}>
            <List>
              {result.map((element: any) => (
                <ListItem
                  sx={{ py: 1, px: 0 }}
                  key={element?.id}
                  secondaryAction={<Box>{getAction(element?.result, element)}</Box>}
                >
                  <Box className="textColor-200 text-medium font-weight-semibold cursor-pointer">{element?.name}</Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      ) : (
        <DetailedReport />
      )}
    </Box>
  );
};
export default Report;
