import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Box, Theme, FormControl, Chip } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';

import { CustomIdProcessType } from '../../../types/inviteTypes';
import { theme, TButton, TPaper, TSelect, colorPalette, TFullScreenDialog } from '../../myde-react-components';
import {
  getApplicationList,
  getInvitationList,
  selectInviteDashboardDetails,
  setApplicationModalFlag,
  setSelectedApplciation,
  setSelectedProfiles,
  updateMerchantInvitationStatus,
} from '../../../redux/feature/dashboard/invitationSlice';
import { useTranslation } from 'react-i18next';
import {
  ACCESS_LEVEL,
  APPLICATION_STATUS,
  BENEFICIAL_OWNER_DOCUMENT,
  BENEFICIAL_OWNER_DOCUMENT_VALUES,
  MERCHANT_INVITE_STATUS,
  PROFILE_REQUIREMENT_TEXT,
} from '../../../constants/constants';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { useTheme } from '../../../providers/custom-theme-provider';
import {
  getEntityList,
  selectEntity,
  setInviteCardSelectedEntity,
  updateEntityApplicationStatus,
} from '../../../redux/feature/entity/entitySlice';
import { EntityTypes } from '../../../types/entityTypes';
import { ProcessType, ProfileRequirementsType } from '../../../types/invitationTypes';
import { setEntitySelectionFlag } from '../../../redux/feature/user/userSlice';
import { setCardState } from '../../../redux/feature/common/commonSlice';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  emailIcon: {
    paddingLeft: '3px',
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  nameCountText: {
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  merchantTitle: {
    background: colorPalette.containerBack.base,
  },
  chipWrapper: {
    '& .MuiChip-root': {
      margin: 5,
      border: '1px solid',
      borderColor: `${colorPalette.typoText.unSelected}!important`,
      background: `${colorPalette.typoText.lighten3}!important`,
    },
    '& .MuiChip-label': {
      color: `${colorPalette.typoText.darken3}!important`,
      font: 'normal normal 0.75rem/18px Montserrat !important',
    },
  },
  addEntityMsg: {
    backgroundColor: colorPalette.containerBack.base,
  },
  addEntityCta: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
  },
}));

const ApplicationModal = () => {
  // constants
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { t } = useTranslation(['inviteFlow']);
  const { routeTo } = useRouter();

  // Redux Values
  const {
    showApplicationModal,
    selectedApplication,
    basicIdProcessList,
    ownerIdProcessList,
    applicationList,
    customIdProcessList,
  } = useSelector(selectInviteDashboardDetails);
  const { entityList, selectedEntityInviteDetails, checkIsEntity } = useSelector(selectEntity);

  // State Values
  const [showModal, setShowModalFlag] = useState(false);
  const [selectedItem, setSelectedItem] = useState({} as any); // keeping any as application or invitation both can be used
  const [idProcessList, setIdProcessList] = useState([] as ProcessType[]);
  const [customProcesses, setCustomProcesses] = useState([] as CustomIdProcessType[]);
  const [selectedEntity, setSelectedEntity] = useState({} as EntityTypes);
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(false);

  // Use Effects
  useEffect(() => {
    if (!entityList?.length) {
      dispatch(getEntityList());
    }
    dispatch(setInviteCardSelectedEntity({} as EntityTypes));
  }, []);

  useEffect(() => {
    setShowModalFlag(showApplicationModal);
  }, [showApplicationModal]);

  useEffect(() => {
    if (selectedApplication?.id) {
      setSelectedItem(selectedApplication);
    }
    if (selectedApplication?.account?.trellis_uid) {
      const entity =
        entityList?.find((entity: EntityTypes) => entity?.trellis_uid === selectedApplication?.account?.trellis_uid) ||
        ({} as EntityTypes);
      setSelectedEntity(entity);
      dispatch(setInviteCardSelectedEntity(entity));
    }
  }, [selectedApplication]);

  useEffect(() => {
    let idProcesses = basicIdProcessList;
    let beneficialOwnerProcess = {} as ProcessType;
    if (ownerIdProcessList?.length > 0) {
      beneficialOwnerProcess = BENEFICIAL_OWNER_DOCUMENT;
      idProcesses = [...idProcesses, beneficialOwnerProcess];
    }
    setIdProcessList(idProcesses);
  }, [basicIdProcessList, ownerIdProcessList]);

  useEffect(() => {
    if (customIdProcessList?.length > 0) {
      const data = customIdProcessList?.map((item: CustomIdProcessType) => {
        return {
          ...item,
          access_level: 'Source',
        };
      });
      setCustomProcesses(data);
    }
  }, [customIdProcessList]);

  useEffect(() => {
    if (applicationList?.length > 0) {
      const currentApplication = applicationList?.find(
        (application) => application?.invitation?.id === selectedItem?.id,
      );
      dispatch(setSelectedApplciation(currentApplication));
    }
  }, [applicationList]);

  useEffect(() => {
    if (entityList?.length === 1) {
      setSelectedEntity(entityList[0]);
      dispatch(setInviteCardSelectedEntity(entityList[0]));
    } else {
      setSelectedEntity({} as EntityTypes);
      dispatch(setInviteCardSelectedEntity({} as EntityTypes));
    }
  }, [entityList]);

  useEffect(() => {
    if (
      selectedItem?.details?.id_requirements?.length > 0 ||
      selectedItem?.details?.owner_id_requirements?.length > 0
    ) {
      setIsSubmitAllowed(false);
    } else {
      setIsSubmitAllowed(true);
    }
  }, [selectedItem]);

  // Methods
  const getStartApplicationPayload = (isEntity: boolean) => {
    let entityStartApplicationPayload: object = {};
    const startApplicationPayload: object = {
      action: APPLICATION_STATUS.START,
      invitation_id: isEntity ? selectedEntityInviteDetails?.id : selectedItem?.id,
      merchant_uid: isEntity
        ? selectedEntityInviteDetails?.merchant?.merchant_uid
        : selectedItem?.merchant?.merchant_uid,
    };
    if (isEntity) {
      entityStartApplicationPayload = {
        ...startApplicationPayload,
        entity_uid: selectedEntity?.trellis_uid || 0,
      };
      return entityStartApplicationPayload;
    } else {
      return startApplicationPayload;
    }
  };

  const getDismissApplicationPayload = (isEntity: boolean) => {
    return {
      action: APPLICATION_STATUS.DISMISS,
      invitation_id: isEntity ? selectedEntityInviteDetails?.id : selectedItem?.id,
      merchant_uid: isEntity
        ? selectedEntityInviteDetails?.merchant?.merchant_uid
        : selectedItem?.merchant?.merchant_uid,
    };
  };

  const getPayload = (isStartApplication: boolean) => {
    let payload: object = {};
    if (isStartApplication) {
      payload = getStartApplicationPayload(checkIsEntity);
    } else {
      payload = getDismissApplicationPayload(checkIsEntity);
    }
    return payload;
  };

  const closeModal = () => {
    dispatch(setApplicationModalFlag(false));
  };

  const startApplication = async () => {
    const reqData = getPayload(true);
    if (checkIsEntity) {
      await dispatch(updateEntityApplicationStatus(reqData));
    } else {
      await dispatch(updateMerchantInvitationStatus(reqData));
    }
    closeModal();
    await getUpdatedInvitationsAndApplications();
    routeTo(ROUTES.VIEW_INVITE);
  };

  const handleClick = () => {
    if (isSubmitAllowed) {
      submitApplication();
    } else {
      startApplication();
    }
  };

  const submitApplication = () => {
    dispatch(setSelectedProfiles([]));
    routeTo(ROUTES.APPROVE_SUBMIT_INVITE);
  };

  const getUpdatedInvitationsAndApplications = async () => {
    await dispatch(getInvitationList({ status: APPLICATION_STATUS.INVITED }));
    await dispatch(
      getApplicationList({
        status_in: `{${MERCHANT_INVITE_STATUS.STARTED},${MERCHANT_INVITE_STATUS.UNDER_REVIEW},${MERCHANT_INVITE_STATUS.PENDING_REVIEW}}`,
      }),
    );
  };
  const dismissApplication = async () => {
    closeModal();
    const reqData = getPayload(false);
    if (checkIsEntity) {
      await dispatch(updateEntityApplicationStatus(reqData));
    } else {
      await dispatch(updateMerchantInvitationStatus(reqData));
    }
    await getUpdatedInvitationsAndApplications();
  };
  const showMessages = () => {
    const messageData = {
      applicationId: selectedItem?.id,
      merchantId: selectedItem?.merchant?.id,
    };
    routeTo(ROUTES.APPLICATION_MESSAGES, true, 'Application Messages', messageData);
  };

  const handleChange = (event: any) => {
    const entity = entityList?.find((entity) => entity?.id === event?.target?.value) || ({} as EntityTypes);
    setSelectedEntity(entity);
    dispatch(setInviteCardSelectedEntity(entity));
  };

  const getProfileRequirementText = (addressType: string, supportingDocument: boolean, index: number) => {
    let template = <></>;
    const filterAddressType = PROFILE_REQUIREMENT_TEXT.find(
      (item) => item.type === addressType && item.isSupportingDocument === supportingDocument,
    );
    if (filterAddressType?.text) {
      template = <Box key={index}>{t(filterAddressType?.text)}</Box>;
    }
    return template;
  };

  const handleAddEntity = () => {
    closeModal();
    dispatch(setEntitySelectionFlag(true));
    dispatch(setCardState(true));
  };
  // HTML
  return (
    <>
      <TFullScreenDialog
        open={showModal}
        closeModal={closeModal}
        title={'Invitation'}
        dialogContent={
          <Box>
            <Divider />
            <Box className="text-center">
              <Box sx={{ px: 3 }} className="flex-basic-center">
                <Box className="img-radius-box large-box">
                  <img src={selectedItem?.merchant?.branding?.logo_url} loading="lazy" alt="Branding Logo" />
                </Box>
              </Box>
              <Box className={clsx(classes.merchantTitle, 'textColor-200 text-large')} sx={{ px: 3, py: 3 }}>
                <span> {t('welcome')} </span>
                <span className="font-weight-semibold"> {selectedItem?.merchant?.name} </span>
                <span> {t('merchantMessage')}</span>
              </Box>
              <Box sx={{ px: 3, pt: 3 }}>
                <Box className="text-center">
                  <Box sx={{ mb: theme.spacing(3) }} className="textColor-200 text-medium font-weight-regular">{`${t(
                    'inviteSubtitle',
                    {
                      ns: 'inviteFlow',
                    },
                  )} ${selectedItem?.merchant?.name}.`}</Box>
                  <Box className="textColor-200 text-medium font-weight-regular">
                    <span>{t('inviteSubtitle2')} </span>
                    <span className="font-weight-semibold">{selectedItem?.merchant?.name}</span>
                    <span> {t('inviteSubtitle3')}</span>
                  </Box>
                </Box>
                {checkIsEntity && (
                  <>
                    <Box sx={{ mt: theme.spacing(7), mb: entityList?.length ? 5 : 0 }} className="entityTextAlign">
                      <Box className="textColor-200 text-h3 font-weight-semibold flex-basic-start">
                        Select an entity
                      </Box>
                      <Box sx={{ mt: theme.spacing(5) }}>
                        <FormControl className="w-100">
                          <TSelect
                            name={'entity'}
                            id="type"
                            fullWidth
                            value={selectedEntity?.id || ''}
                            options={entityList}
                            label="Entity"
                            itemId="id"
                            itemValue="name"
                            disabled={
                              !entityList?.length ||
                              selectedApplication?.status === MERCHANT_INVITE_STATUS.PENDING_REVIEW
                            }
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    {!entityList?.length && (
                      <Box
                        sx={{ px: theme.spacing(5), py: theme.spacing(3), mt: theme.spacing(5), mb: 5 }}
                        className={clsx('textColor-200 text-small addEntityMsgRedius', classes.addEntityMsg)}
                      >
                        {t('addEntityMsg')}
                        <span className={clsx(classes.addEntityCta, 'cursorPointer')} onClick={handleAddEntity}>
                          {t('clickHereTitleCase')}
                        </span>
                      </Box>
                    )}
                  </>
                )}
                {/* ID Process List */}
                <Box sx={{ my: 3 }}>
                  {idProcessList?.map((item: ProcessType, index: number) => (
                    <Box key={index} sx={{ mb: 5 }}>
                      <Box sx={{ pb: theme.spacing(1) }} className="textColor-200 text-medium font-weight-semibold">
                        {item?.title}
                      </Box>
                      {!checkIsEntity && (
                        <>
                          {item?.access_level === ACCESS_LEVEL.SAFE_DOC ? (
                            <Box
                              sx={{ pb: theme.spacing(1) }}
                              className="textColor-200 text-small font-weight-regular"
                            >{`Visibility: ${item?.access_level}`}</Box>
                          ) : (
                            <Box className="text-small textColor-300">Visibility: {ACCESS_LEVEL.SOURCE}</Box>
                          )}
                        </>
                      )}
                      {item?.details?.profile_address_requirement_list && (
                        <Box sx={{ pb: theme.spacing(1) }} className="text-extra-small textColor-300">
                          {item?.details?.profile_address_requirement_list?.map(
                            (item: ProfileRequirementsType, index: number) =>
                              getProfileRequirementText(
                                item?.address_type,
                                item?.is_supporting_document_required,
                                index,
                              ),
                          )}
                        </Box>
                      )}
                      {item?.key === BENEFICIAL_OWNER_DOCUMENT_VALUES.KEY && (
                        <Box>
                          {ownerIdProcessList?.map((item: ProcessType, index: number) => (
                            <Box key={index} sx={{ pb: 1 }} className="text-medium textColor-200 text-center">
                              {item?.title}
                            </Box>
                          ))}
                        </Box>
                      )}
                      {item?.details?.note && (
                        <Box sx={{ px: theme.spacing(5), pb: theme.spacing(1) }} className="text-small textColor-200">
                          {item?.details?.note}
                        </Box>
                      )}
                      {!item?.details?.is_mandatory && (
                        <Box>
                          <span className={clsx('customChips', classes.chipWrapper)}>
                            <Chip label={t('optionalLabel')} />
                          </span>
                        </Box>
                      )}
                    </Box>
                  ))}
                  {customProcesses?.map((item: CustomIdProcessType, index: number) => (
                    <Box key={index} sx={{ mb: 5 }}>
                      <Box sx={{ pb: theme.spacing(1) }} className="textColor-200 text-medium font-weight-semibold">
                        {item?.process_name}
                      </Box>
                      {!checkIsEntity && (
                        <Box className="textColor-200 text-small font-weight-regular">{`Visibility: ${item?.access_level}`}</Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
              {/* Buttons section */}
              {(selectedItem?.status === APPLICATION_STATUS.INVITED ||
                selectedItem?.status === MERCHANT_INVITE_STATUS.ACCOUNT_SIGNED_UP ||
                selectedItem?.status === MERCHANT_INVITE_STATUS.CREATED) && (
                <Box>
                  <TButton
                    onClick={handleClick}
                    variant="contained"
                    btnText={isSubmitAllowed ? 'Submit Application' : 'Start Application'}
                    btnWidthSize="button-w-240"
                    disabled={checkIsEntity && (!entityList?.length || !selectedEntity?.id)}
                  />
                  <Box sx={{ mt: theme.spacing(5) }}>
                    <TButton
                      onClick={dismissApplication}
                      variant="text"
                      btnWidthSize="button-w-240"
                      btnText="Not Interested"
                    />
                  </Box>
                </Box>
              )}
              {/* Message Section */}
              {selectedItem?.total_unread_response_count > 0 ? (
                <TPaper sx={{ px: 3, mb: theme.spacing(5), mx: theme.spacing(9), py: 2 }}>
                  <Box className="flex-basic-space-between">
                    <Box className="textColor-300 text-h3 font-weight-semibold">Messages</Box>
                    <Box className="flex-basic-center">
                      {selectedItem?.total_unread_response_count > 0 && (
                        <Box sx={{ mr: 3, pb: theme.spacing(1) }} className="iconWithCount">
                          <Box className={clsx('countText count-invite flex-basic-center', classes.nameCountText)}>
                            <Box className="text-extra-small">{selectedItem?.total_unread_response_count}</Box>
                          </Box>
                          <Box>
                            <MailOutlineOutlinedIcon className={classes.emailIcon} />
                          </Box>
                        </Box>
                      )}
                      <Box onClick={showMessages} className="cursorPointer flex-basic-end">
                        <ChevronRightIcon className="icon-size-24 textColor-200" />
                      </Box>
                    </Box>
                  </Box>
                </TPaper>
              ) : (
                selectedItem?.status !== APPLICATION_STATUS.INVITED &&
                selectedItem?.status !== MERCHANT_INVITE_STATUS.ACCOUNT_SIGNED_UP &&
                selectedItem?.status !== MERCHANT_INVITE_STATUS.CREATED && (
                  <TPaper sx={{ px: 3, mb: theme.spacing(5), mx: theme.spacing(9), py: 2 }}>
                    <Box className=" textColor-300 text-h3 font-weight-semibold">{t('noNewMessages')}</Box>
                  </TPaper>
                )
              )}
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default ApplicationModal;
