import React from 'react';

const EmptyStarIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M19.926 7.848a1.44 1.44 0 0 0-1.153-.944L13.57 6.13l-2.262-4.696A1.449 1.449 0 0 0 10 .625c-.56 0-1.07.316-1.307.809L6.43 6.13l-5.205.774c-.534.08-.978.445-1.15.944A1.38 1.38 0 0 0 .41 9.276l3.814 3.808-.885 5.282a1.39 1.39 0 0 0 .593 1.377 1.47 1.47 0 0 0 1.53.081L10 17.382l4.538 2.442a1.48 1.48 0 0 0 1.53-.082 1.39 1.39 0 0 0 .592-1.376l-.885-5.282 3.815-3.808c.377-.377.506-.928.336-1.428Zm-5.183 4.254c-.32.319-.465.768-.392 1.209l.885 5.282-4.538-2.442a1.467 1.467 0 0 0-1.397 0l-4.537 2.442.885-5.282c.074-.441-.071-.89-.391-1.21L1.444 8.295l5.204-.775a1.44 1.44 0 0 0 1.09-.792L10 2.032l2.263 4.696c.205.424.613.72 1.088.792l5.205.775-3.813 3.807Z"
        fill="#BDBDBD"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyStarIcon;
