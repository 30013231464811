import { apiGet, apiPost } from './axios';

export const getCountOfNotifications = async () => {
  const res = await apiGet(`/notification/count`);
  return res.data;
};

export const getSystemNotifications = async (params: object) => {
  const res = await apiGet(`/notification/system`, { params: params });
  return res.data;
};

export const getMerchantNotifications = async (params: object) => {
  const res = await apiGet(`/notification/external`, { params: params });
  return res.data;
};

export const getMerchantList = async () => {
  const res = await apiGet(`/notification/merchant`);
  return res.data;
};

export const markNotificationsAsRead = async (data: object) => {
  const res = await apiPost('/notification/mark-as-read', data);
  return res.data;
};
