import { Box, Grid, Theme } from '@mui/material';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import Webcam from 'react-webcam';
import { TButton } from '../myde-react-components';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTheme } from '../../providers/custom-theme-provider';

interface TakePhotoProps {
  setFile: any;
  uploadedFile?: any;
  toggleCam: (value: boolean) => void;
  hideUpload?: any;
  fileName?: string;
}

export interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  container: {
    display: 'flex',
  },
  camWrapper: {
    borderRadius: 6,
    width: '100%',
  },
  cameraButtonContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  orContainer: {
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  toggleIcon: {
    fontSize: '25px',
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
}));

const videoConstraints = {
  facingMode: 'user',
};
const TakePhoto = ({ fileName = 'capturedImage', setFile, toggleCam, hideUpload }: TakePhotoProps) => {
  const { currentThemes } = useTheme();

  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const [file, setFiles] = useState({} as any);
  const [facingMode, setFacingMode] = useState('user');

  useEffect(() => {
    if (file && Object.keys(file).length > 0) {
      setFile(file);
    }
    URL.revokeObjectURL(file);
  }, [file]);

  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc: any = webcamRef?.current;
    const src = imageSrc?.getScreenshot();
    fetch(src)
      .then((res) => res.blob())
      .then((blob) => {
        const newFile = new File([blob], `${fileName}.jpeg`, { type: 'image/jpeg' });
        setFiles(Object.assign(newFile, { preview: URL.createObjectURL(newFile) }));
      });
  }, [webcamRef]);

  const toggleCamera = (e: any) => {
    e.preventDefault();
    handleToggleCamera();
  };

  const handleToggleCamera = useCallback(() => {
    setFacingMode((prevState: any) => (prevState === 'user' ? 'environment' : 'user'));
  }, []);

  return (
    <Box sx={{ textAlign: 'center' }} className={classes.root}>
      <Box sx={{ m: '0 auto' }} className={classes.container}>
        {Object.keys(file).length === 0 ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className={classes.camWrapper}
            videoConstraints={{ ...videoConstraints, facingMode }}
          />
        ) : (
          <img src={file.preview} />
        )}
      </Box>
      <Box>
        {Object.keys(file).length > 0 ? (
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center" sx={{ mt: 2 }}>
            <Grid item>
              <TButton
                variant="outlined"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  setFiles([]);
                }}
                icon={<Box className={'icon-camera'}></Box>}
              />
            </Grid>
            {hideUpload && (
              <>
                <Grid item>
                  <Box>OR</Box>
                </Grid>
                <Grid item>
                  <TButton
                    variant="contained"
                    size="small"
                    onClick={() => {
                      toggleCam(false);
                    }}
                    btnText="Upload"
                  />
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <Grid direction="row" justifyContent="space-evenly" alignItems="center" sx={{ mt: 2 }}>
            <Box className={classes.cameraButtonContainer}>
              <TButton
                variant="outlined"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  capture();
                }}
                sx={{ mr: 8 }}
                icon={<Box className={'icon-camera'}></Box>}
              />
              <Box
                color="primary"
                sx={{ my: 1, mx: 2 }}
                className={clsx(['icon-camera-swap cursorPointer'], [classes.toggleIcon])}
                onClick={toggleCamera}
              ></Box>
            </Box>

            {hideUpload && (
              <>
                <Grid item>
                  <Box className={classes.orContainer}>OR</Box>
                </Grid>

                <Grid item>
                  <Box>
                    <TButton
                      variant="contained"
                      size="small"
                      onClick={() => {
                        toggleCam(false);
                      }}
                      btnText="Upload"
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};
export default TakePhoto;
