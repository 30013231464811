import * as z from 'zod';

const symbol = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
const number = /\d/;
export const ChangePasswordFormSchema = z
  .object({
    current_password: z.string().nonempty('Old password is required'),
    new_password: z.string().nonempty('Password is required').min(8, { message: 'Password is invalid' }),
    confirm_password: z.string().nonempty('Confirm password is required'),
  })
  .refine((data) => number.test(data.new_password), {
    message: 'Password is invalid',
    path: ['new_password'],
  })
  .refine((data) => symbol.test(data.new_password), {
    message: 'Password is invalid',
    path: ['new_password'],
  })
  .refine((data) => data.new_password.toLowerCase() !== data.new_password, {
    message: 'Password is invalid',
    path: ['new_password'],
  })
  .refine((data) => data.new_password.toUpperCase() !== data.new_password, {
    message: 'Password is invalid',
    path: ['new_password'],
  })
  .refine((data) => data.new_password !== data.current_password, {
    message: 'New password can not be same as old password',
    path: ['new_password'],
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: 'Confirm password does not match',
    path: ['confirm_password'],
  });

export type ChangePasswordFormSchemaPayload = z.infer<typeof ChangePasswordFormSchema>;
