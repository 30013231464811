import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { FilePreviewType, TAlertDialog, TButton, theme } from '../myde-react-components';
import PreviewFileByType from './PreviewFileByType';
import CapturePhoto from './CapturePhoto';
import { useTheme } from '../../providers/custom-theme-provider';
import { getCommaSeperatedValues } from '../../utils/utils';
import SingleAndMultipleUpload from './SingleAndMultipleUpload';
import { ClickHandlerType, PhotoCaptureInstructionProps, UploadCaptureButtons } from '../../types/commonTypes';
import { useTranslation } from 'react-i18next';
import UploadOrCaptureInstructionDialog from './UploadOrCaptureInstructionDialog';

interface UploadOrCapturePhotoProps {
  acceptedFileFormat: string[];
  supportedFileFormat: string[];
  maxFileSize: number;
  isMultiple: boolean;
  setData?: (file: File) => void;
  uploadedFile: FilePreviewType[];
  showTitleText?: string;
  setMultipleFileData?: (file: FilePreviewType[], oldFiles: FilePreviewType[]) => void;
  setShowMultipleCapture?: ClickHandlerType;
  captureInstruction?: PhotoCaptureInstructionProps;
  uploadInstruction?: PhotoCaptureInstructionProps;
}

export interface StyleProps {
  currentThemes: Theme;
  matches: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  primaryText: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
}));

const UploadOrCapturePhoto = ({
  acceptedFileFormat,
  supportedFileFormat,
  maxFileSize,
  isMultiple,
  uploadedFile,
  showTitleText,
  setData,
  setMultipleFileData,
  setShowMultipleCapture,
  captureInstruction,
  uploadInstruction,
}: UploadOrCapturePhotoProps) => {
  // Constants
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { currentThemes } = useTheme();
  const styleProp = {
    matches,
    currentThemes,
  };
  const classes = useStyles(styleProp);
  const { t } = useTranslation(['common']);

  // State Variables
  const [files, setFiles] = useState([] as FilePreviewType[]);
  const [newFilesList, setNewFilesList] = useState([] as FilePreviewType[]);
  const [oldFilesList, setOldFilesList] = useState([] as FilePreviewType[]);
  const [duplicateFileList, setDuplicateFileList] = useState([] as FilePreviewType[]);
  const [showDuplicateDialog, setShowDuplicateDialogFlag] = useState(false);
  const [clickedButton, setClickedButton] = useState<UploadCaptureButtons | null>(null);
  const [duplicateDialogContent, setDuplicateDialogContent] = useState('');

  // Use Effects
  useEffect(() => {
    if (files?.length > 0) {
      setClickedButton(null);
      if (files[0]?.file) {
        setData && setData(files[0]?.file);
      }
    }
  }, [files]);
  useEffect(() => {
    if (uploadedFile?.length > 0 && uploadedFile[0]?.name) {
      setFiles(uploadedFile);
    } else {
      setFiles([] as FilePreviewType[]);
    }
  }, [uploadedFile]);

  // Methods
  const setCapturedImages = (data: FilePreviewType[]) => {
    setClickedButton(null);
    setFiles(data);
  };
  const updateFiles = (files: FilePreviewType[]) => {
    setFiles(files);
    setMultipleFileData && setMultipleFileData([], files);
  };
  const multipleFilesHandler = (newFiles: FilePreviewType[], oldFiles: FilePreviewType[]) => {
    if (setMultipleFileData) {
      if (oldFiles?.length === 0) {
        setMultipleFileData(newFiles, oldFiles);
      } else {
        checkDuplicateFiles(newFiles, oldFiles);
      }
    }
  };
  const checkDuplicateFiles = (newFiles: FilePreviewType[], oldFiles: FilePreviewType[]) => {
    const newFileNameList = [...new Set(newFiles?.map(({ name }) => name))] || [];
    const duplicateFilesList = oldFiles?.filter((file) => newFileNameList.includes(file?.name));
    const duplicateFileNameList = [...new Set(duplicateFilesList?.map(({ name }) => name))] || [];
    if (duplicateFilesList?.length === 0) {
      setMultipleFileData && setMultipleFileData(newFiles, oldFiles);
    } else {
      const dialogContent =
        duplicateFileNameList?.length > 0
          ? duplicateFileNameList?.length === 1
            ? `File name ${duplicateFileNameList.toString()} already exists.`
            : `File(s) ${duplicateFileNameList.join(', ').toString()} already exist.`
          : '';
      setNewFilesList(newFiles);
      setOldFilesList(oldFiles);
      setDuplicateFileList(duplicateFilesList);
      setShowDuplicateDialogFlag(duplicateFilesList?.length > 0);
      setDuplicateDialogContent(dialogContent);
    }
  };
  const overrideDuplicateFiles = () => {
    const newFilesNameList = [...new Set(newFilesList?.map(({ name }) => name))] || [];
    const nonDuplicateFiles = oldFilesList?.filter((file) => !newFilesNameList.includes(file?.name)) || [];
    setMultipleFileData && setMultipleFileData(newFilesList, nonDuplicateFiles);
    setShowDuplicateDialogFlag(false);
  };
  const cancelUpload = () => {
    setShowDuplicateDialogFlag(false);
  };
  const keepAllDuplicateFiles = () => {
    const updatedFilesList: any[] = newFilesList?.map((file: any) => {
      const dotIndex = file?.name?.lastIndexOf('.');
      if (dotIndex !== -1) {
        const newFile = new File(
          [file],
          `${file?.name?.substring(0, dotIndex) + new Date().toLocaleTimeString() + file?.name?.substring(dotIndex)}`,
          {
            type: file?.type,
          },
        );
        const fileData = Object.assign(newFile, {
          preview: file?.preview,
        });
        return fileData;
      }
    });
    setMultipleFileData && setMultipleFileData(updatedFilesList, oldFilesList);
    setShowDuplicateDialogFlag(false);
  };

  // HTML
  return (
    <Box>
      <Box
        className={
          isMobile
            ? 'text-center textColor-200 text-large font-weight-semibold'
            : 'text-center textColor-200 text-h3 font-weight-semibold'
        }
        sx={{ mb: 3 }}
      >
        {showTitleText
          ? showTitleText
          : isMobile
          ? t('uploadAndCaptureImageText', { ns: 'common' })
          : t('uploadDocument')}
      </Box>
      <Box
        sx={{ my: 3 }}
        className={clsx('fileUpload text-center textColor-300 text-medium font-weight-regular', classes.primaryText)}
      >
        <Box className="icon-file-copy textColor-300 icon-size-72" />
        <Box className="text-extra-small">
          <Box>{`Supported file types:\t`}</Box>
          <Box>{getCommaSeperatedValues(supportedFileFormat)}</Box>
        </Box>
      </Box>

      <Box className="flex-basic-center">
        {uploadInstruction ? (
          <TButton
            sx={{ mr: 1 }}
            icon={<Box className="icon-upload" sx={{ pr: 1 }} />}
            btnWidthSize={'button-w-140'}
            btnText="Upload"
            variant="outlined"
            onClick={() => setClickedButton(UploadCaptureButtons.UPLOAD)}
          />
        ) : (
          <SingleAndMultipleUpload
            isMultiple={isMultiple}
            acceptedFileFormat={acceptedFileFormat}
            maxFileSize={maxFileSize}
            setData={setData}
            uploadedFiles={uploadedFile}
            setMultipleData={multipleFilesHandler}
          />
        )}

        {isMobile && (
          <>
            {captureInstruction ? (
              <TButton
                btnText="Capture"
                icon={<Box sx={{ pr: 1 }} className="icon-camera" />}
                variant={'outlined'}
                btnWidthSize={'button-w-140'}
                onClick={() => setClickedButton(UploadCaptureButtons.CAPTURE)}
              />
            ) : (
              <CapturePhoto
                canCaptureMultiple={isMultiple}
                icon={<Box sx={{ pr: 1 }} className="icon-camera" />}
                btnText="Capture"
                setFiles={setCapturedImages}
                setMultipleData={setMultipleFileData}
                setShowMultipleCapture={setShowMultipleCapture}
              />
            )}
          </>
        )}
      </Box>
      <Box>
        <PreviewFileByType updateFiles={updateFiles} isEdit={true} files={files} isMultiple={isMultiple} />
      </Box>
      <TAlertDialog
        title={duplicateFileList?.length === 1 ? 'File already exits' : 'File(s) already exists'}
        showDialog={showDuplicateDialog}
        noBtnTitle={duplicateFileList?.length === 1 ? 'Replace' : 'Replace All'}
        yesBtnTitle={duplicateFileList?.length === 1 ? 'Keep Both' : 'Keep All'}
        content={duplicateDialogContent}
        extraContent="Do you want to?"
        onCancel={overrideDuplicateFiles}
        onConfirm={keepAllDuplicateFiles}
        onClose={cancelUpload}
      />

      <UploadOrCaptureInstructionDialog
        acceptedFileFormat={acceptedFileFormat}
        maxFileSize={maxFileSize}
        isMultiple={isMultiple}
        uploadedFile={uploadedFile}
        captureInstruction={captureInstruction}
        uploadInstruction={uploadInstruction}
        clickedButton={clickedButton}
        setData={setData}
        setMultipleFileData={setMultipleFileData}
        setShowMultipleCapture={setShowMultipleCapture}
        multipleFilesHandler={multipleFilesHandler}
        setCapturedImages={setCapturedImages}
        setClickedButton={setClickedButton}
      />
    </Box>
  );
};

export default UploadOrCapturePhoto;
