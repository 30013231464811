import z from 'zod';
import { REGEX } from '../../../../components/myde-react-components';

export const EditEntityFormSchema = z.object({
  dba_name: z.string().optional(),
  website: z
    .string()
    .min(1, { message: 'Website is required' })
    .refine((value) => REGEX.WEBSITE.test(value), { message: 'Please enter valid url' }),
});
export type EditEntityFormSchemaPayload = z.infer<typeof EditEntityFormSchema>;
