import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { APPLICATION_STATUS } from '../../../../constants/constants';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';
import {
  selectSelectedIdProcessDetails,
  clearIdProcessMultipleData,
  submitEntityIdProcess,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { ENTITY_PROCESS_IDS, ID_PROCESS_STATUS_COMPLETION_STATE } from '../../../myde-react-components';
import UploadDoc from '../common/UploadDoc';
import {} from '../../../../redux/feature/idProcess/incomeVerificationSlice';
import { FileType } from '../../../../types/documentTypes';

interface DocumentFormationConfirmationProps {
  isMultiple: boolean;
  isEntity?: boolean;
}

const DocumentFormationConfirmation = ({ isEntity }: DocumentFormationConfirmationProps) => {
  //constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { selectedEntity } = useSelector(selectEntity);
  const { uploadedDocIdMultiple, selectedEntityIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);
  const [preview, setPreview] = useState([] as FileType[]);
  const [documents, setDocuments] = useState([] as string[]);

  //useEffects
  useEffect(() => {
    if (uploadedDocIdMultiple?.length > 0) {
      setDocuments(uploadedDocIdMultiple);
    }
  }, [uploadedDocIdMultiple]);

  useEffect(() => {
    if (
      Object.keys(selectedEntityIdProcessDetails?.data)?.length > 0 &&
      selectedEntityIdProcessDetails?.data?.document_id_list?.length > 0
    ) {
      setPreview(selectedEntityIdProcessDetails?.data?.document_id_list);
    }
  }, [selectedEntityIdProcessDetails]);

  //methods
  const handleNext = () => {
    const payload: any = {
      process_id: ENTITY_PROCESS_IDS.FORMATION_DOCUMENT,
      process_data: {
        document_id_list: documents,
      },
      action: APPLICATION_STATUS.SUBMIT,
    };
    if (isEntity) {
      dispatch(
        submitEntityIdProcess({
          entityUid: selectedEntity?.trellis_uid,
          formData: payload,
        }),
      );
      dispatch(clearIdProcessMultipleData());
      dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
      dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
    } else {
      toast.error(t('somethingWentWrongText', { ns: 'notificationMessages' }));
    }
  };

  return (
    <Box>
      <UploadDoc
        buttonText={'Submit'}
        isEntity={isEntity}
        handleNext={handleNext}
        isMultiple={true}
        previewFiles={preview}
      />
    </Box>
  );
};

export default DocumentFormationConfirmation;
