import React from 'react';
import { Box, SvgIconProps } from '@mui/material';

import { FileButton, DOCUMENT_VERIFICATION_MIME_TYPES, TButton, FilePreviewType } from '../myde-react-components';
import { ClickHandlerType } from '../../types/commonTypes';

interface CapturePhotoProps {
  canCaptureMultiple: boolean;
  icon?: React.ReactElement<SvgIconProps>;
  btnText: string;
  setFiles: (files: FilePreviewType[]) => void;
  setMultipleData?: (files: FilePreviewType[], oldFiles: FilePreviewType[]) => void;
  setShowMultipleCapture?: ClickHandlerType;
  btnVariant?: 'text' | 'outlined' | 'contained';
  btnWidthSize?: 'button-w-240' | 'button-w-140' | 'button-w-80';
}

const CapturePhoto = ({
  canCaptureMultiple,
  icon,
  btnText,
  setFiles,
  setShowMultipleCapture,
  btnVariant = 'outlined',
  btnWidthSize = 'button-w-140',
}: CapturePhotoProps) => {
  // Methods
  const handleOnFileChange = (image: any) => {
    const newFile = new File([image[0]], image[0]?.name, {
      type: image[0]?.type,
    });
    const fileData = Object.assign(newFile, { preview: URL.createObjectURL(newFile) });
    const payload = {
      file: newFile,
      ...fileData,
    };
    setFiles([payload]);
  };

  const handleCaptureClick = () => {
    setShowMultipleCapture && setShowMultipleCapture(true);
  };

  return (
    <Box>
      {canCaptureMultiple ? (
        <>
          <TButton btnText={btnText} variant={btnVariant} btnWidthSize={btnWidthSize} onClick={handleCaptureClick} />
        </>
      ) : (
        <FileButton
          icon={icon}
          label={btnText}
          variant={btnVariant}
          btnWidthSize={btnWidthSize}
          isMultipleFile={false}
          acceptedFileTypes={DOCUMENT_VERIFICATION_MIME_TYPES}
          fileType={DOCUMENT_VERIFICATION_MIME_TYPES}
          onChange={handleOnFileChange}
        />
      )}
    </Box>
  );
};

export default CapturePhoto;
