import * as React from 'react';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette } from '../../constants/colors';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import clsx from 'clsx';
import { ACCREDITATION_BADGE_TEXT, TTooltip } from '../../..';
import { useTheme } from '../../../../../providers/custom-theme-provider';

export interface UserInfoProps {
  label?: string;
  imageUrl?: string;
  id?: string;
  rounded?: boolean;
  status?: string;
  children?: React.ReactNode;
}

export interface StyleProps {
  rounded: boolean;
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  title: {
    textOverflow: 'ellipsis',
    margin: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'capitalize',
  },
  id: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: '17px',
    color: colorPalette.info,
  },
  container: {
    textAlign: 'center',
    width: '100%',
  },
  iconBlock: {
    width: ({ rounded }) => (rounded ? '100%' : '100% !important'),
    height: ({ rounded }) => (rounded ? 'auto' : 'auto !important'),
    borderRadius: ({ rounded }) => (rounded ? '50%' : '10px'),
  },
  defaultIcon: {
    padding: ({ rounded }) => (rounded ? '0px 10px 16px' : '0px'),
    backgroundColor: colorPalette.iconColor.main,
    color: `${colorPalette.iconColor.base} !important`,
  },
  accreditationBadgeColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
}));

export const UserInfo = ({ children, imageUrl, label = '', status = '', id, rounded = false }: UserInfoProps) => {
  const { currentThemes } = useTheme();
  const styleProps = {
    rounded,
    currentThemes,
  };
  const classes = useStyles(styleProps);
  return (
    <>
      <Box className={classes.container}>
        <Box>
          {imageUrl ? (
            <img src={`${imageUrl}`} loading="lazy" className={classes.iconBlock} />
          ) : (
            <PersonOutlineIcon
              className={clsx({
                [classes.iconBlock]: true,
                [classes.defaultIcon]: true,
              })}
            />
          )}
        </Box>

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <TTooltip title={label} arrow>
            <Grid
              item
              className={clsx({
                [classes.title]: true,
                ['text-h1 font-weight-semibold textColor-200']: true,
              })}
              xs={10}
              sm={10}
              md={11}
              lg={11}
            >
              {label}
            </Grid>
          </TTooltip>
          <Grid item xs={2} sm={2} md={1} lg={1}>
            <Box sx={{ mt: 1 }} className="text-h2 textColor-200 font-weight-semibold icon-arrow-right"></Box>
          </Grid>
        </Grid>
        <Box sx={{ pb: 1 }} className="text-medium font-weight-bold textColor-200">
          {id}
        </Box>
        {status && (
          <Box sx={{ pb: 2 }} className="flex-basic-center">
            <Box className={clsx('icon-accreditation-badge', classes.accreditationBadgeColor)}></Box>
            <Box sx={{ ml: 1 }} className="textColor-200 text-medium font-weight-bold">
              {ACCREDITATION_BADGE_TEXT[status]}
            </Box>
          </Box>
        )}
        {children}
      </Box>
    </>
  );
};

export default UserInfo;
