import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import EntityFormIndex from './EntityFormIndex';
import AddressFormIndex from './AddressFormIndex';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';
import { TLoader } from '../../myde-react-components';

const FormIndex = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { loading } = useSelector(selectEntity);

  const changeStep = (step: number) => {
    setActiveStep(step);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <EntityFormIndex goToNextStep={changeStep} />;
      case 1:
        return <AddressFormIndex goToPreviousStep={changeStep} />;
    }
  };

  return (
    <Box>
      <TLoader loading={loading} />
      <Box>{getStepContent(activeStep)}</Box>
    </Box>
  );
};

export default FormIndex;
