import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, FormGroup, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ProfileDetails, ProfileDocumentsListType } from '../../../types/profileTypes';
import { theme, TCheckbox, PROCESS_IDS, ID_PROCESS_KEYS } from '../../myde-react-components';
import { selectInviteDashboardDetails } from '../../../redux/feature/dashboard/invitationSlice';
import { ProfileRequirementsType } from '../../../types/invitationTypes';
import { ADDRESS_TYPE_LABEL, GET_PARENT_PROCESS_IDS, PROFILE_ADDRESS_TYPES } from '../../../constants/constants';
import { removeUnderscore } from '../../../utils/utils';
import { useTheme } from '../../../providers/custom-theme-provider';
import { selectDashboardDetails } from '../../../redux/feature/dashboard/dashboardSlice';
import { setIdProcessKey, setParentIdProcess } from '../../../redux/feature/idProcess/mainIdProcessSlice';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';

interface ProfileSelectionListProps {
  profiles: ProfileDetails[];
  profileIdRequirementsList: ProfileRequirementsType[];
  enableOrDisableForm: (data: boolean) => void;
  addSelectedProfiles: (data: any) => void;
}

interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  primaryColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const ProfileSelectionList = ({
  profiles,
  profileIdRequirementsList,
  enableOrDisableForm,
  addSelectedProfiles,
}: ProfileSelectionListProps) => {
  // Constants
  const { t } = useTranslation(['inviteFlow']);
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  // State Variables
  const [profileMessage, setProfileMessage] = useState('');
  const [profileDetailList, setProfileDetailList] = useState([] as ProfileDetails[]);
  const [residentialProfiles, setResidentialProfiles] = useState([] as ProfileDetails[]);
  const [nonResidentialProfiles, setNonResidentialProfiles] = useState([] as ProfileDetails[]);
  const [hasResidentialSupportingDocument, setHasResidentialSupportingDocument] = useState(false);
  const [hasNonResidentialSupportingDocument, setHasNonResidentialSupportingDocument] = useState(false);
  const [isResidentialRequired, setIsResidentialRequired] = useState(false);
  const [isNonResidentialRequired, setIsNonResidentialRequired] = useState(false);
  const [selectedProfileData, setSelectedProfileData] = useState({} as any);

  // Redux Values
  const { idRequirementsResult } = useSelector(selectInviteDashboardDetails);
  const { idProcessList } = useSelector(selectDashboardDetails);

  // Use Effects
  useEffect(() => {
    setProfileDetailList(profiles);
  }, [profiles]);

  useEffect(() => {
    const residentialList = checkAddressTypes(profileDetailList, PROFILE_ADDRESS_TYPES.RESIDENTIAL);
    const nonResidentialList = checkAddressTypes(profileDetailList, PROFILE_ADDRESS_TYPES.NON_RESIDENTIAL);

    setResidentialProfiles(residentialList);
    setNonResidentialProfiles(nonResidentialList);
    if (profileDetailList?.length === 1) {
      const profileRequirement =
        profileIdRequirementsList?.find((item) => item?.address_type === profileDetailList[0]?.address_type) ||
        ({} as ProfileRequirementsType);
      setAddressTypeRequirement(profileRequirement);
      addSelectedProfiles([profileDetailList[0]?.profile_name]);
      selectUserProfile(profileDetailList[0]?.profile_name);
    }
  }, [profileDetailList, profileIdRequirementsList]);

  useEffect(() => {
    if (profileIdRequirementsList?.length === 1) {
      setAddressTypeRequirement(profileIdRequirementsList[0]);
    } else {
      profileIdRequirementsList?.forEach((item) => setAddressTypeRequirement(item));
    }
  }, [profileIdRequirementsList]);

  useEffect(() => {
    let message = '';
    if (isResidentialRequired && isNonResidentialRequired) {
      message = `${idRequirementsResult?.name} requires Residential and Non Residential Profiles`;
    } else if (isNonResidentialRequired) {
      message = `${idRequirementsResult?.name} requires ${ADDRESS_TYPE_LABEL.NON_RESIDENTIAL} Profile`;
    } else if (isResidentialRequired) {
      message = `${idRequirementsResult?.name} requires ${ADDRESS_TYPE_LABEL.RESIDENTIAL} Profile`;
    }
    setProfileMessage(message);
  }, [isResidentialRequired, isNonResidentialRequired, idRequirementsResult]);

  useEffect(() => {
    let isRequirementFulfilled: any = false;
    const residentialProfilesWithoutDocuments = residentialProfiles?.filter(
      (item) => Object.keys(item?.address?.details)?.length === 0,
    );
    const nonResidentialProfilesWithoutDocuments = nonResidentialProfiles?.filter(
      (item) => Object.keys(item?.address?.details)?.length === 0,
    );
    isRequirementFulfilled = checkIfSubmitAllowed(
      residentialProfilesWithoutDocuments,
      nonResidentialProfilesWithoutDocuments,
    );
    enableOrDisableForm(isRequirementFulfilled);
  }, [
    residentialProfiles,
    nonResidentialProfiles,
    isResidentialRequired,
    isNonResidentialRequired,
    hasResidentialSupportingDocument,
    hasNonResidentialSupportingDocument,
  ]);

  // Methods
  const checkAddressTypes = (profileDataList: ProfileDetails[], requestedAddressType: string) => {
    return profileDataList?.filter((item) => item?.address_type === requestedAddressType);
  };

  const checkIfRequirementsFulfilled = (details: ProfileDetails[], message?: string) => {
    if (message) {
      return details?.every((item) => Object.keys(item?.address?.details)?.length > 0) && !message;
    } else {
      return details?.every((item) => Object.keys(item?.address?.details)?.length > 0);
    }
  };

  const checkRequirementForBothAddressTypes = (
    hasResidentialDoc: boolean,
    hasNonResidentialDoc: boolean,
    residentialDetails: ProfileDetails[],
    nonResidentialDetails: ProfileDetails[],
  ) => {
    const profileDetails = [...residentialDetails, ...nonResidentialDetails];
    if (hasResidentialDoc && hasNonResidentialDoc) {
      return checkIfRequirementsFulfilled(profileDetails, getProfileAddMessage());
    } else if (hasResidentialDoc) {
      return checkIfRequirementsFulfilled(residentialDetails, getProfileAddMessage());
    } else if (hasNonResidentialDoc) {
      return checkIfRequirementsFulfilled(nonResidentialDetails, getProfileAddMessage());
    } else {
      return !getProfileAddMessage();
    }
  };

  const checkRequirementForSingleAddressType = (isDocumentRequired: boolean, profileDetailsList: ProfileDetails[]) => {
    if (isDocumentRequired) {
      return checkIfRequirementsFulfilled(profileDetailsList);
    } else {
      return true;
    }
  };

  const checkIfSubmitAllowed = (residential: ProfileDetails[], nonResidential: ProfileDetails[]) => {
    switch (true) {
      case isResidentialRequired:
      case isNonResidentialRequired:
        return checkRequirementForBothAddressTypes(
          hasResidentialSupportingDocument,
          hasNonResidentialSupportingDocument,
          residential,
          nonResidential,
        );
      case isResidentialRequired:
      case !isNonResidentialRequired:
        return checkRequirementForSingleAddressType(hasResidentialSupportingDocument, residential);
      case !isResidentialRequired:
      case isNonResidentialRequired:
        return checkRequirementForSingleAddressType(hasNonResidentialSupportingDocument, nonResidential);
    }
  };

  const setAddressTypeRequirement = (requirement: ProfileRequirementsType) => {
    if (requirement?.address_type === PROFILE_ADDRESS_TYPES.RESIDENTIAL) {
      setIsResidentialRequired(true);
      setHasResidentialSupportingDocument(requirement?.is_supporting_document_required);
    } else {
      setIsNonResidentialRequired(true);
      setHasNonResidentialSupportingDocument(requirement?.is_supporting_document_required);
    }
  };

  const selectUserProfile = (profileName: string) => {
    const allKeys = Object.keys(selectedProfileData);
    const isSelectedProfileName = allKeys.includes(profileName);

    if (!isSelectedProfileName) {
      const profileData: any = Object.assign({}, selectedProfileData);
      profileData[profileName] = [];
      setSelectedProfileData(profileData);
      addSelectedProfiles(profileData);
    } else {
      const profileIndex = allKeys?.findIndex((key) => key === profileName);
      allKeys.splice(profileIndex, 1);
      const newProfileData = allKeys.map((key) => {
        const updatedProfileData: any = {};
        updatedProfileData[key] = selectedProfileData[key];
        return updatedProfileData;
      });

      setSelectedProfileData(newProfileData[0] || {});
      addSelectedProfiles(newProfileData[0] || {});
    }
  };

  const selectUserProfileAddressDoc = (docuementName: string, profileName: string) => {
    const isSelectedProfileName = selectedProfileData[profileName]?.includes(docuementName);
    const newDocuemntCheck: any = Object.assign({}, selectedProfileData);
    if (!isSelectedProfileName) {
      newDocuemntCheck[profileName].push(docuementName);
    } else {
      const allKeys = Object.keys(selectedProfileData);
      const docNameIndex = allKeys?.findIndex((key: any) => key === docuementName);
      newDocuemntCheck[profileName].splice(docNameIndex, 1);
    }
    setSelectedProfileData(newDocuemntCheck);
    addSelectedProfiles(newDocuemntCheck);
  };

  const getProfileAddMessage = () => {
    if (profileIdRequirementsList?.length > 1) {
      if (residentialProfiles?.length === 0) {
        return t('residentialAddMessage');
      } else if (nonResidentialProfiles?.length === 0) {
        return t('nonResidentialAddMessage');
      }
    }
  };

  const goToSpecificProcess = (processId: number, route: string, key: string) => {
    if (idProcessList?.length > 0) {
      const parentProcessData = idProcessList?.find((item) => {
        if (item?.process_id === GET_PARENT_PROCESS_IDS[processId]) {
          return item;
        }
      });
      dispatch(setParentIdProcess(parentProcessData));
      dispatch(setIdProcessKey(key));
      const query = { id: processId };
      routeTo(route, true, '', query);
    }
  };

  const profileSelectionTemplate = (profileList: ProfileDetails[], isDocumentRequired: boolean) => {
    return (
      <Box>
        {profileList?.map((profile: ProfileDetails) => (
          <Box key={profile?.id}>
            <Box className="flex-basic-space-between">
              <FormControlLabel
                control={
                  <TCheckbox
                    checked={!!selectedProfileData[profile?.profile_name]}
                    onChange={() => selectUserProfile(profile?.profile_name)}
                    name={profile?.profile_name}
                  />
                }
                label={profile?.profile_name}
              />
              {profileIdRequirementsList?.length > 1 && (
                <Box className="addressTypeContainer flex-basic-center">
                  <Box className="text-small" sx={{ p: theme.spacing(1) }}>
                    {removeUnderscore(profile?.address_type)}
                  </Box>
                </Box>
              )}
            </Box>
            {!!selectedProfileData[profile?.profile_name] && (
              <Box>
                {profile?.address?.details?.documents?.map((documentsList: ProfileDocumentsListType, index) => {
                  return (
                    <Box sx={{ ml: 4 }} key={index}>
                      <FormControlLabel
                        control={
                          <TCheckbox
                            onChange={() =>
                              selectUserProfileAddressDoc(documentsList.document_type, profile?.profile_name)
                            }
                            name={documentsList?.document_type}
                          />
                        }
                        label={removeUnderscore(documentsList?.document_type)}
                      />
                    </Box>
                  );
                })}
              </Box>
            )}
            {isDocumentRequired &&
              !!selectedProfileData[profile?.profile_name] &&
              Object.keys(profile?.address?.details)?.length === 0 && (
                <Box sx={{ py: 1, my: 2 }} className="profileRequirementContainer text-small text-center">
                  <Box className="textColor-200">
                    {t('supportingDocumentMessage')}
                    <span
                      className={clsx(classes.primaryColor, 'cursorPointer')}
                      onClick={() =>
                        goToSpecificProcess(
                          PROCESS_IDS.ADDRESS_VERIFICATION,
                          ROUTES.ID_PROCESS,
                          ID_PROCESS_KEYS.ADDRESS,
                        )
                      }
                    >
                      {t('clickHereTitleCase')}
                    </span>
                  </Box>
                </Box>
              )}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ px: 3, my: theme.spacing(5) }}>
      <Box sx={{ pb: 1 }} className="text-medium font-weight-semibold textColor-200">
        {t('selectProfileLabel')}
      </Box>
      <Box sx={{ pb: 1 }} className="text-small textColor-300">
        {profileMessage}
      </Box>
      <FormGroup>
        {profileSelectionTemplate(residentialProfiles, hasResidentialSupportingDocument)}
        {profileSelectionTemplate(nonResidentialProfiles, hasNonResidentialSupportingDocument)}
      </FormGroup>
      {profileIdRequirementsList?.length > 1 && (
        <Box>
          {getProfileAddMessage() ? (
            <Box sx={{ py: 1, my: 2 }} className="profileRequirementContainer text-small text-center">
              <Box className="textColor-200" sx={{ p: theme.spacing(1) }}>
                {getProfileAddMessage()}
                <span
                  className={clsx(classes.primaryColor, 'cursorPointer')}
                  onClick={() =>
                    goToSpecificProcess(PROCESS_IDS.PROFILE_VERIFICATION, ROUTES.ID_PROCESS, ID_PROCESS_KEYS.PROFILE)
                  }
                >
                  {t('clickHereTitleCase')}
                </span>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProfileSelectionList;
