import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { colorPalette, TConfirmDialog, theme } from '../../components/myde-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../auth/auth-guard';
import { setCardState } from '../../redux/feature/common/commonSlice';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../providers/custom-router-provider';
import { deleteUserData, selectUser, resetIsDataDeleted } from '../../redux/feature/user/userSlice';

const useStyles = makeStyles({
  cardDataWrapper: {
    position: 'relative',
    '& .toggleIcon': {
      top: 16,
      right: 10,
      position: 'absolute',
      cursor: 'pointer',
    },
  },
  listItem: {
    borderTop: `1px solid ${colorPalette.border.card}`,
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: `1px solid ${colorPalette.border.card}`,
    },
    '&:first-child': {
      borderTop: `0px solid ${colorPalette.border.card}`,
    },
  },
});

interface SettingsItemListingProps {
  list: ListItems[];
}

interface ListItems {
  path: string;
  id: number;
  label: string;
  icon: string;
  hideNav: boolean;
  title: string;
  classNameSet?: string;
}

const SettingsItemListing = ({ list }: SettingsItemListingProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { t } = useTranslation('common');
  const { logout } = useAuth();
  const [deleteClicked, setDeleteClicked] = useState(false);
  const { isAllDataDeleted } = useSelector(selectUser);

  const listItemClickHandler = (item: ListItems) => {
    dispatch(setCardState(false));
    routeTo(item?.path, item?.hideNav, item?.title);
  };

  const handleDelete = () => {
    dispatch(deleteUserData());
  };

  //useEffect
  useEffect(() => {
    if (isAllDataDeleted) {
      dispatch(resetIsDataDeleted());
      setDeleteClicked(false);
      logout();
    }
  }, [isAllDataDeleted]);

  return (
    <Box className={classes.cardDataWrapper} sx={{ mt: 1 }}>
      <Box sx={{ p: 2 }} className={'text-h2 font-weight-semibold textColor-200'}>
        Settings
      </Box>
      <Box sx={{ mb: 4 }}>
        {list.map((element, index) => {
          return (
            <Grid
              sx={{ py: theme.spacing(5) }}
              container
              className={clsx(
                classes.listItem,
                `${element.classNameSet}`,
                'text-large font-weight-medium textColor-200',
              )}
              key={index}
              onClick={() => listItemClickHandler(element)}
            >
              <Grid item xs={12}>
                <Box className="d-flex textColor-200">
                  <Box sx={{ px: 2 }} className={element.icon} />
                  <Box>{element.label}</Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
        <Divider />
        <Box sx={{ py: theme.spacing(5) }}>
          <Grid item xs={12} onClick={() => setDeleteClicked(true)}>
            <Box className="d-flex cursorPointer">
              <Box sx={{ px: 2 }} className="icon-delete actionIconFont-24 textColor-200" />
              <Box className="textColor-200 font-weight-medium">{t('clearData')}</Box>
            </Box>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ py: theme.spacing(5) }}>
          <Grid item xs={12} onClick={logout}>
            <Box className="d-flex cursorPointer">
              <Box sx={{ px: 2 }} className="icon-logout actionIconFont-24 textColor-200" />
              <Box className="textColor-200 font-weight-medium">Log Out</Box>
            </Box>
          </Grid>
        </Box>
        <Divider />
      </Box>
      {deleteClicked && (
        <TConfirmDialog
          title={t('confirmationText')}
          onCancelClick={() => setDeleteClicked(false)}
          onConfirmClick={handleDelete}
          showDialog={deleteClicked}
          confirmationMessage={t('confirmClearData')}
        />
      )}
    </Box>
  );
};

export default SettingsItemListing;
