import React from 'react';
import { Box, IconButton, List, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { colorPalette } from '../myde-react-components';
import { fontStyle } from '../../styles/style';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import * as ROUTES from '../../constants/routes';
import { EntityTypes } from '../../types/entityTypes';
import { useTheme } from '../../providers/custom-theme-provider';
import { useRouter } from '../../providers/custom-router-provider';

const useStyles = makeStyles({
  listItem: {
    borderTop: `1px solid ${colorPalette.border.card}`,
    borderBottom: `1px solid ${colorPalette.border.card}`,
    cursor: 'pointer',
  },
  icon: {
    color: 'black',
    fontSize: '0.875rem',
  },
  entityTitle: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font600,
    fontFamily: 'Montserrat',
    cursor: 'pointer',
  },
});

interface EntityProps {
  list: EntityTypes[];
}

const EntityListData = ({ list }: EntityProps) => {
  const classes = useStyles();
  const { currentThemes } = useTheme();
  const { routeTo } = useRouter();

  const gotToSpecificProcess = (element: EntityTypes) => {
    const query = { id: element.trellis_uid };
    routeTo(ROUTES.ENTITY_ID_PROCESS, true, element.name, query);
  };

  return (
    <>
      <List>
        {list.map((element: EntityTypes) => (
          <ListItem
            className={classes.listItem}
            sx={{ py: 2 }}
            key={element.id}
            secondaryAction={
              <IconButton edge="end">
                <ArrowForwardIosIcon className={classes.icon} />
              </IconButton>
            }
            onClick={() => gotToSpecificProcess(element)}
          >
            <Box className={classes.entityTitle} sx={{ color: currentThemes.palette.primary.main }}>
              {element.name}
            </Box>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default EntityListData;
