import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getTAppDetails, setView } from '../../redux/feature/myTApps/myTAppsSlice';
import { makeStyles } from '@mui/styles';
import TAppHeader from './TAppHeader';
import TAppTabs from './TAppTabs';
const useStyles = makeStyles({
  pageContainer: {
    minHeight: '100vh',
  },
});

const TAppsIndex = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTAppDetails());
    dispatch(setView('1'));
  }, []);

  return (
    <Box className={classes.pageContainer}>
      <TAppHeader />
      <TAppTabs />
    </Box>
  );
};

export default TAppsIndex;
