import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDocCategoryList, getFile, getSelectedCategoryFolders, getSelectedFolderFiles } from '../../../api/document';
import { getApplications } from '../../../api/messages';
import {
  CategoryFolder,
  DocumentCategoryType,
  MerchantDocumentType,
  FileType,
  DocFiltersType,
  MerchantUserEntityResultType,
} from '../../../types/documentTypes';
import { RootState } from '../../index';

type DocumentType = {
  loading: boolean;
  error: boolean;
  categoryList: DocumentCategoryType[];
  folderList: CategoryFolder[];
  fileList: MerchantDocumentType[];
  selectedCategory: string;
  selectedFolder: string;
  selectedFile: MerchantDocumentType;
  file: FileType;
  isSearching: boolean;
  merchantList: MerchantUserEntityResultType;
  merchantId: string;
  merchantTitle: string;
  screenToBeShown: string;
};
const initialState: DocumentType = {
  loading: false,
  error: false,
  categoryList: [],
  folderList: [],
  fileList: [],
  selectedCategory: '',
  selectedFolder: '',
  selectedFile: <MerchantDocumentType>{},
  file: <FileType>{},
  isSearching: false,
  merchantList: <MerchantUserEntityResultType>{},
  merchantId: '',
  merchantTitle: '',
  screenToBeShown: '',
};

// Selectors
export const selectDocuments = ({ documents }: RootState) => ({
  loading: documents.loading,
  categoryList: documents.categoryList,
  folderList: documents.folderList,
  fileList: documents.fileList,
  file: documents.file,
  selectedCategory: documents.selectedCategory,
  selectedFolder: documents.selectedFolder,
  selectedFile: documents.selectedFile,
  isSearching: documents.isSearching,
  merchantList: documents.merchantList,
  merchantId: documents.merchantId,
  merchantTitle: documents.merchantTitle,
  screenToBeShown: documents.screenToBeShown,
});

// Actions
export const getDocCategories = createAsyncThunk('documents/getDocCategories', async (filters?: DocFiltersType) => {
  return getDocCategoryList(filters);
});
export const getCategoryFolders = createAsyncThunk(
  'documents/getCategoryFolders',
  async (data: { category: string; filters: object }) => {
    return getSelectedCategoryFolders(data);
  },
);
export const getFolderFiles = createAsyncThunk('documents/getFolderFiles', async (fileParams: object) => {
  return getSelectedFolderFiles(fileParams);
});

export const getSelectedFile = createAsyncThunk(
  'documents/getSelectedFile',
  async (data: { docId: string; filters?: object }) => {
    return getFile(data.docId, data.filters);
  },
);

export const getApplicationMerchants = createAsyncThunk(
  'documents/getApplicationMerchants',
  async (filters?: object) => {
    return await getApplications(filters);
  },
);

// Reducers
export const documentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedFolder: (state, action) => {
      state.selectedFolder = action.payload;
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    setIsSearching: (state, action) => {
      state.isSearching = action.payload;
    },
    clearFileList: (state) => {
      state.fileList = [];
    },
    setMerchantId: (state, action) => {
      state.merchantId = action.payload;
    },
    resetMerchantId: (state) => {
      state.merchantId = '';
    },
    setMerchantTitle: (state, action) => {
      state.merchantTitle = action.payload;
    },
    setScreenToBeShown: (state, action) => {
      state.screenToBeShown = action.payload;
    },
    resetScreenToBeShown: (state) => {
      state.screenToBeShown = '';
    },
  },
  extraReducers: (builder) => {
    builder
      // getDocCategories
      .addCase(getDocCategories.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getDocCategories.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.categoryList = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getDocCategories.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.categoryList = [];
      })
      // getCategoryFolders
      .addCase(getCategoryFolders.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCategoryFolders.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.folderList = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getCategoryFolders.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.folderList = [];
      })
      // getFolderFiles
      .addCase(getFolderFiles.pending, (state) => {
        if (!state.isSearching) {
          state.loading = true;
        }
        state.error = false;
      })
      .addCase(getFolderFiles.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.fileList = action?.payload?.results || [];
        } else {
          state.error = true;
        }
      })
      .addCase(getFolderFiles.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.fileList = [];
      })
      // getFile
      .addCase(getSelectedFile.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSelectedFile.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.file = action?.payload || {};
        } else {
          state.error = true;
        }
      })
      .addCase(getSelectedFile.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.file = {} as FileType;
      })
      //getApplicationMerchants
      .addCase(getApplicationMerchants.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationMerchants.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.merchantList = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getApplicationMerchants.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.merchantList = <MerchantUserEntityResultType>{};
      });
  },
});

export const {
  setSelectedCategory,
  setSelectedFolder,
  setSelectedFile,
  setIsSearching,
  clearFileList,
  setMerchantId,
  resetMerchantId,
  setMerchantTitle,
  setScreenToBeShown,
  resetScreenToBeShown,
} = documentSlice.actions;

export default documentSlice.reducer;
