import React, { useEffect, useState } from 'react';

import { MAX_PASSWORD_EXPIRY_DAYS, MIN_PASSWORD_EXPIRY_DAYS, PASSWORD_EXPIRED_DAY, TConfirmDialog } from '../../..';
import { PORTAL } from '../../constants/portal';

// Interface
interface PasswordExpiryModalProps {
  daysToPasswordExpiry: number;
  title: string;
  message: string;
  onReset: () => void;
}

export const PasswordExpiryModal = ({ daysToPasswordExpiry, title, message, onReset }: PasswordExpiryModalProps) => {
  // State Values
  const [showDialog, setShowDialog] = useState(false);
  const [daysToExpire, setDaysToExpire] = useState(MAX_PASSWORD_EXPIRY_DAYS);

  // Use Effects
  useEffect(() => {
    setDaysToExpire(daysToPasswordExpiry);
  }, [daysToPasswordExpiry]);
  useEffect(() => {
    if (daysToExpire <= MIN_PASSWORD_EXPIRY_DAYS && daysToExpire > PASSWORD_EXPIRED_DAY) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }
  }, [daysToExpire]);

  // HTML
  return (
    <TConfirmDialog
      showDialog={showDialog}
      title={title}
      portalName={PORTAL.MYDE}
      confirmationMessage={message}
      noBtnTitle={'Later'}
      yesBtnTitle={'Reset'}
      onConfirmClick={onReset}
      onCancelClick={() => {
        setShowDialog(false);
      }}
    />
  );
};

export default PasswordExpiryModal;
