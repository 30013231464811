import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { processDetailsById } from '../../../api/idProcesses';
import { KycAmlRecordDetails, KycAmlResultType, KycHistoryRecordType } from '../../../types/idProcessTypes';
import { addNewComment, deleteComment, getEventById, updateComment, getHistoryById } from '../../../api/kycAml';

type SignatureState = {
  kycData: KycAmlResultType;
  loading: boolean;
  error: boolean;
  commentUpdated: boolean;
  updatedEvent: KycAmlRecordDetails;
  historyRecord: KycHistoryRecordType;
  kycRunId: string;
  isHistoryEvent: boolean;
};

const initialState: SignatureState = {
  kycData: <KycAmlResultType>{},
  commentUpdated: false,
  loading: false,
  error: false,
  updatedEvent: <KycAmlRecordDetails>{},
  historyRecord: <KycHistoryRecordType>{},
  kycRunId: '',
  isHistoryEvent: false,
};

const successMethod = (state: any, action: any) => {
  state.error = false;
  state.loading = false;
  if (action.payload) {
    state.commentUpdated = true;
  } else {
    state.error = true;
  }
};

export const kycAmlIdProcessDetails = ({ kycaml }: RootState) => ({
  // here we will get data for id process item
  kycData: kycaml.kycData,
  loading: kycaml.loading,
  error: kycaml.error,
  commentUpdated: kycaml.commentUpdated,
  updatedEvent: kycaml.updatedEvent,
  historyRecord: kycaml.historyRecord,
  kycRunId: kycaml.kycRunId,
  isHistoryEvent: kycaml.isHistoryEvent,
});

// Actions
export const getProcessDetailsById = createAsyncThunk(
  'kycaml/getProcessDetailsById',
  async (processId: string | number) => {
    return await processDetailsById(processId);
  },
);
export const addComment = createAsyncThunk('kycaml/addComment', async (data: { eventId: string; comment: string }) => {
  return await addNewComment(data);
});
export const updateExistingComment = createAsyncThunk(
  'kycaml/updateExistingComment',
  async (data: { eventId: string; commentId: string; comment: string }) => {
    return await updateComment(data);
  },
);
export const deleteSelectedComment = createAsyncThunk(
  'kycaml/deleteSelectedComment',
  async (data: { eventId: string; commentId: string }) => {
    return await deleteComment(data);
  },
);
export const getUpdatedEvent = createAsyncThunk(
  'kycaml/getUpdatedEvent',
  async (data: { recordId: string; eventId: string; runId: string }) => {
    return await getEventById(data);
  },
);
export const getKycHistoryById = createAsyncThunk('kycaml/getKycHistoryById', async (data: { runId: string }) => {
  return await getHistoryById(data);
});

export const kycamlSlice = createSlice({
  name: 'kycaml',
  initialState,
  reducers: {
    setcommentUpdatedFlag: (state, action) => {
      state.commentUpdated = action.payload;
    },
    //resetUpdatedEvent
    resetUpdatedEvent: (state) => {
      state.updatedEvent = <KycAmlRecordDetails>{};
    },
    setKycRunId: (state, action) => {
      state.kycRunId = action.payload;
    },
    resetKycRunId: (state) => {
      state.kycRunId = '';
    },
  },
  extraReducers: (builder) => {
    builder
      // getProcessDetailsById
      .addCase(getProcessDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProcessDetailsById.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.kycData = action.payload?.result;
        } else {
          state.error = true;
        }
      })
      .addCase(getProcessDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // addComment
      .addCase(addComment.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addComment.fulfilled, (state, action: any) => {
        successMethod(state, action);
      })
      .addCase(addComment.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // updateExistingComment
      .addCase(updateExistingComment.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateExistingComment.fulfilled, (state, action: any) => {
        successMethod(state, action);
      })
      .addCase(updateExistingComment.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // deleteSelectedComment
      .addCase(deleteSelectedComment.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteSelectedComment.fulfilled, (state, action) => {
        successMethod(state, action);
      })
      .addCase(deleteSelectedComment.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // getUpdatedEvent
      .addCase(getUpdatedEvent.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUpdatedEvent.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.updatedEvent = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getUpdatedEvent.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // getKycHistoryById
      .addCase(getKycHistoryById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getKycHistoryById.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.historyRecord = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getKycHistoryById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setcommentUpdatedFlag, resetUpdatedEvent, setKycRunId, resetKycRunId } = kycamlSlice.actions;

export default kycamlSlice.reducer;
