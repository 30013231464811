import React, { useEffect, useState } from 'react';
import { Box, Theme, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TransitionProps } from '@mui/material/transitions';
import ReactPlayer from 'react-player';
import clsx from 'clsx';

import { FilePreviewType } from '../../types/commonTypes';
import TDialog from '../Dialog/Dialog';
import { PdfViewer } from '../../common/PdfViewer';
import { PORTAL } from '../../constants/portal';
import { DIALOG_WIDTH } from '../../constants/constants';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';

interface DocumentViewModalProps {
  open: boolean;
  file: FilePreviewType;
  portalName?: string;
  title?: string;
  showDownloadIcon?: boolean;
  closeModal: () => void;
  onDownload?: () => void;
}

interface StyleProps {
  matches: boolean;
}

//as this is a functional component, it will render once in the component lifecycle.
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  dialogStyle: {
    marginTop: '60px',
    marginBottom: '60px',
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        width: ({ matches }) => (matches ? DIALOG_WIDTH.MERCHANT_PORTAL_SMALL : DIALOG_WIDTH.USER_PORTAL),
        height: 'auto',
        maxHeight: '100%',
        '& .MuiBox-root': {
          '& .MuiTypography-root': {
            padding: ({ matches }) => (matches ? '' : '20px 20px 12px 20px'),
            '& .text-h2': {
              font: ({ matches }) =>
                matches
                  ? 'normal 600 24px/36px Basier Circle !important'
                  : 'normal 600 18px/28px Basier Circle !important',
            },
            '& .MuiButtonBase-root': {
              padding: '0 !important',
            },
          },
        },
        '& #scrollableDiv': {
          '& .MuiBox-root': {
            paddingBottom: '0px!important',
          },
        },
        '& .MuiDialogContent-root': {
          padding: ({ matches }) => (matches ? '' : '0px 20px !important'),
        },
      },
    },
  },
  imageContainer: {
    width: '100%',
    height: 'auto',
  },
  image: {
    width: '100%',
    maxHeight: '100%',
  },
}));

export const DocumentViewModal = ({
  open,
  file,
  portalName = PORTAL.MYDE,
  title = 'Document',
  showDownloadIcon = false,
  closeModal,
  onDownload,
}: DocumentViewModalProps) => {
  // Constants
  const matches = portalName === PORTAL.MERCHANT;
  const styleProps: StyleProps = {
    matches,
  };
  const classes = useStyles(styleProps);
  const [showImageLoader, setShowImageLoader] = useState(false);
  const [showPDF, setShowPDF] = useState(true);

  // useEffect
  useEffect(() => {
    setShowPDF(true);
    setShowImageLoader(true);
    //setTimeout method use for showing the PDF after 1sec
    setTimeout(() => {
      setShowPDF(false);
    }, 1200);
  }, [open]);

  return (
    <TDialog
      className={classes.dialogStyle}
      fullScreen={true}
      title={title}
      open={open}
      onClose={closeModal}
      transparentTitle={true}
      TransitionComponent={Transition}
      portalName={portalName}
      showDownloadIcon={showDownloadIcon}
      onDownload={onDownload}
    >
      <Box>
        <Box sx={{ mb: 1 }} className="text-center text-extra-small textColor-200">
          <Box>{file?.name}</Box>
        </Box>
        {file?.type?.includes('video') ? (
          <ReactPlayer width={'300px'} height={'200px'} className={classes.image} url={file.preview} controls={true} />
        ) : file?.type?.includes('pdf') ? (
          <Box className="flex-basic-center">
            {showPDF ? (
              <>
                <SkeletonLoader skeletonHeight={400} loading={true} />
              </>
            ) : (
              <>
                <PdfViewer maxArea={true} domain={portalName} uri={file?.preview} />
              </>
            )}
          </Box>
        ) : file?.type?.includes('image') ? (
          <Box className={clsx(classes.imageContainer, 'd-flex')}>
            <Box>{showImageLoader && <SkeletonLoader skeletonHeight={400} loading={true} />}</Box>
            <Box className="flex-basic-center w-100">
              <img
                onLoad={() => setShowImageLoader(false)}
                src={file?.preview}
                className={classes.image}
                alt="Uploaded Image"
              />
            </Box>
          </Box>
        ) : (
          <Box className="text-center textColor-300 text-medium font-weight-regular">NA</Box>
        )}
      </Box>
    </TDialog>
  );
};

export default DocumentViewModal;
