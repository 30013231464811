import moment from 'moment';
import { DATE_TIME, GPLACES_ADDRESS_CONSTRAINTS } from '../constants/constants';
import { GPlacesAddressType, GPlacesAddressComponentsType, GPlacesAddressDataType } from '../types/commonTypes';

export const hexToRgbA = (hex: string, opacity: number) => {
  let colorData: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    colorData = hex.substring(1).split('');
    if (colorData.length == 3) {
      colorData = [colorData[0], colorData[0], colorData[1], colorData[1], colorData[2], colorData[2]];
    }
    colorData = '0x' + colorData.join('');
    return 'rgba(' + [(colorData >> 16) & 255, (colorData >> 8) & 255, colorData & 255].join(',') + `,${opacity})`;
  }
  throw new Error('Bad Hex');
};

export const formatDate = (date: string) => {
  return moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : '';
};

export const getAddressData = (data: GPlacesAddressType) => {
  const addressData = {} as GPlacesAddressDataType;
  addressData.name = data?.name;
  if (data?.address_components?.length > 0) {
    data?.address_components?.forEach((item: GPlacesAddressComponentsType) => {
      const addressConstraint = item?.types[0];
      switch (addressConstraint) {
        case GPLACES_ADDRESS_CONSTRAINTS.ZIP:
          addressData.zip = item?.long_name;
          break;
        case GPLACES_ADDRESS_CONSTRAINTS.CITY:
          addressData.city = item?.long_name;
          break;
        case GPLACES_ADDRESS_CONSTRAINTS.STATE:
          addressData.state = item?.short_name;
          addressData.state_name = item?.long_name;
          break;
        case GPLACES_ADDRESS_CONSTRAINTS.COUNTRY:
          addressData.country = item?.short_name;
          addressData.country_name = item?.long_name;
          break;
        default:
          break;
      }
    });
  }
  return addressData;
};

export const getPortal = () => {
  return window.location.hostname.split('.')[0];
};

export const dateFrom = (date: any) => {
  const from = moment();
  return moment(date).isValid() ? moment(date).from(from) : '';
};

export const getDateInShort = (dateTime: string) => {
  let result = '';
  const number = dateTime?.match(/\d+/) || 1;
  if (dateTime?.includes('year')) {
    result = `${number}${DATE_TIME.YEAR}`;
  } else if (dateTime?.includes('month')) {
    result = `${number}${DATE_TIME.MONTH}`;
  } else if (dateTime?.includes('day')) {
    result = `${number}${DATE_TIME.DAY}`;
  } else if (dateTime?.includes('hour')) {
    result = `${number}${DATE_TIME.HOUR}`;
  } else if (dateTime?.includes('minute')) {
    result = `${number}${DATE_TIME.MINUTE}`;
  } else if (dateTime?.includes('second')) {
    result = `${DATE_TIME.SECOND}`;
  }
  return result;
};

export const getInitials = (text: string) => {
  return text
    ? text
        .split(' ')
        .map((v) => v.slice(0, 1))
        .join('')
        .toUpperCase()
    : '';
};

export const getCommaSeperatedValues = (data: string[]) => {
  return data?.length > 0 ? data?.toString()?.replaceAll(',', ', ') : '';
};

export const formatDateToUtc = (date: string) => {
  return moment(date).isValid() ? moment(date).utc().format('MM/DD/YYYY') : '';
};
