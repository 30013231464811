import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postUploadedDoc, processDetailsById } from '../../../api/idProcesses';
import { postIncomeVerificationDocument } from '../../../api/incomeVerification';
import { FileType } from '../../../types/documentTypes';
import { IncomeVerificationDocument } from '../../../types/incomeVerificationType';
import { RootState } from '../../index';

type IncomeDocumentState = {
  loading: boolean;
  error: boolean;
  errorObj: any;
  documentSubmitSuccessMsg: string;
  uploadedDocumentIdList: string[];
  uploadedPhotoToPreview: any;
  uploadedDoc: FileType;
  incomeVerificationProcessDetails: any;
};

const initialState: IncomeDocumentState = {
  loading: false,
  error: false,
  errorObj: {},
  documentSubmitSuccessMsg: '',
  uploadedDocumentIdList: [] as string[],
  uploadedPhotoToPreview: {},
  uploadedDoc: <FileType>{},
  incomeVerificationProcessDetails: {},
};

export const selectIncomeVerificationDetails = ({ incomeVerification }: RootState) => ({
  loading: incomeVerification.loading,
  error: incomeVerification.error,
  errorObj: incomeVerification.errorObj,
  documentSubmitSuccessMsg: incomeVerification.documentSubmitSuccessMsg,
  uploadedDocumentIdList: incomeVerification.uploadedDocumentIdList,
  uploadedPhotoToPreview: incomeVerification.uploadedPhotoToPreview,
  uploadedDoc: incomeVerification.uploadedDoc,
  incomeVerificationProcessDetails: incomeVerification.incomeVerificationProcessDetails,
});

export const submitIncomeVerification = createAsyncThunk(
  'incomeVerification/submitIncomeVerification',
  async (formData: IncomeVerificationDocument) => {
    return await postIncomeVerificationDocument(formData);
  },
);

export const setUploadedPhotoToStore = createAsyncThunk(
  'incomeVerification/setUploadedPhotoToStore',
  async (image: any) => {
    return image;
  },
);

export const setUploadedDoc = createAsyncThunk('documentVerification/setFUploadedImage', async (image: any) => {
  return await postUploadedDoc(image);
});

export const getIncomeVerificationProcessDetails = createAsyncThunk(
  'incomeVerification/getIncomeVerificationProcessDetails',
  async (processId: number) => {
    return await processDetailsById(processId);
  },
);

export const incomeVerification = createSlice({
  name: 'incomeVerification',
  initialState,
  reducers: {
    setUploadedDocumentIdList: (state, action) => {
      if (action.payload) {
        state.uploadedDocumentIdList.push(action.payload);
      }
    },
    clearStoreData: (state) => {
      state.uploadedDocumentIdList = [];
      state.uploadedDoc = <FileType>{};
      state.uploadedPhotoToPreview = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitIncomeVerification.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(submitIncomeVerification.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.documentSubmitSuccessMsg = action.payload?.detail;
        } else {
          state.error = true;
        }
      })
      .addCase(submitIncomeVerification.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(setUploadedPhotoToStore.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(setUploadedPhotoToStore.fulfilled, (state, image: any) => {
        state.error = false;
        state.loading = false;
        if (image.payload) {
          state.uploadedPhotoToPreview = image.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setUploadedDoc.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setUploadedDoc.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.uploadedDoc = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setUploadedDoc.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getIncomeVerificationProcessDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIncomeVerificationProcessDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.incomeVerificationProcessDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getIncomeVerificationProcessDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setUploadedDocumentIdList, clearStoreData } = incomeVerification.actions;

export default incomeVerification.reducer;
