import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import * as entityApi from '../../../api/entity';

import {
  EntityTypes,
  EntityIdProcess,
  EntityForm,
  AddressForm,
  SearchEntityType,
  EntitySearchPayload,
  CreatedRequestType,
  EntityInvitionsType,
  AcceptInviteType,
  SpecificEntityDataType,
  EditEntityInfoPayloadType,
  EntityUserDataListType,
} from '../../../types/entityTypes';
import { MerchantInvitationType } from '../../../types/invitationTypes';
import {
  createNewRequest,
  getSentRequests,
  getReceivedRequests,
  revokeEntityRequest,
  getEntityProfileDetails,
  updateEntityProfileDetails,
} from '../../../api/entity';
import { GPlacesAddressDataType } from '../../../components/myde-react-components';
import { uploadAvatar } from '../../../api/profile';

type EntityInitialState = {
  entityList: EntityTypes[];
  loading: boolean;
  error: boolean;
  selectedEntity: EntityTypes;
  entityIdProcessList: EntityIdProcess[];
  entityFormData: EntityForm;
  addressFormData: AddressForm;
  searchEntityType: SearchEntityType;
  entitySearchData: EntitySearchPayload;
  addEntityResult: EntityTypes;
  currentEntityTaxId: string;
  checkIsEntity: boolean;
  checkEntityUiId: number;
  createdRequest: CreatedRequestType;
  entityInviteSuccessMessage: string;
  entityMembersFormData: EntityUserDataListType;
  allEntityInvitations: EntityInvitionsType[];
  showEntityDetailsFlag: boolean;
  selectedEntityDetails: EntityInvitionsType;
  showAccptedEntityInviteDetailsFlag: boolean;
  acceptedRejectedEntityInviteDetails: EntityInvitionsType;
  selectedEntityInviteDetails: MerchantInvitationType;
  sentRequests: CreatedRequestType[];
  receivedRequests: CreatedRequestType[];
  selectedRequest: CreatedRequestType;
  showOwnerField: any;
  specificEntityDetails: SpecificEntityDataType;
  updatedEntityDetails: SpecificEntityDataType;
  googlePlacesAddress: GPlacesAddressDataType;
  entityProfileURL: string;
  allEntityInviteesList: EntityInvitionsType[];
  selectedMemberDetails: EntityInvitionsType;
  inviteCardSelectedEntity: EntityTypes;
  updatedEntityMemberDetails: EntityInvitionsType;
  selectedTabName: string;
  allEntityMembersList: EntityInvitionsType[];
};

const initialState: EntityInitialState = {
  loading: false,
  error: false,
  entityList: [],
  selectedEntity: <EntityTypes>{},
  entityIdProcessList: [],
  entityFormData: <EntityForm>{},
  addressFormData: <AddressForm>{},
  searchEntityType: <SearchEntityType>{},
  entitySearchData: <EntitySearchPayload>{},
  addEntityResult: <EntityTypes>{},
  currentEntityTaxId: '',
  checkIsEntity: false,
  checkEntityUiId: 0,
  createdRequest: <CreatedRequestType>{},
  entityInviteSuccessMessage: '',
  entityMembersFormData: {} as EntityUserDataListType,
  allEntityInvitations: [],
  showEntityDetailsFlag: false,
  selectedEntityDetails: <EntityInvitionsType>{},
  showAccptedEntityInviteDetailsFlag: false,
  acceptedRejectedEntityInviteDetails: <EntityInvitionsType>{},
  selectedEntityInviteDetails: <MerchantInvitationType>{},
  sentRequests: [] as CreatedRequestType[],
  receivedRequests: [] as CreatedRequestType[],
  selectedRequest: <CreatedRequestType>{},
  showOwnerField: null,
  specificEntityDetails: <SpecificEntityDataType>{},
  updatedEntityDetails: <SpecificEntityDataType>{},
  googlePlacesAddress: <GPlacesAddressDataType>{},
  entityProfileURL: '',
  allEntityInviteesList: [],
  selectedMemberDetails: <EntityInvitionsType>{},
  inviteCardSelectedEntity: <EntityTypes>{},
  updatedEntityMemberDetails: <EntityInvitionsType>{},
  selectedTabName: '',
  allEntityMembersList: [],
};

// Selectors
export const selectEntity = ({ entity }: RootState) => ({
  loading: entity.loading,
  entityList: entity.entityList,
  selectedEntity: entity.selectedEntity,
  entityIdProcessList: entity.entityIdProcessList,
  entityFormData: entity.entityFormData,
  addressFormData: entity.addressFormData,
  searchEntityType: entity.searchEntityType,
  entitySearchData: entity.entitySearchData,
  addEntityResult: entity.addEntityResult,
  currentEntityTaxId: entity.currentEntityTaxId,
  checkIsEntity: entity.checkIsEntity,
  checkEntityUiId: entity.checkEntityUiId,
  createdRequest: entity.createdRequest,
  entityInviteSuccessMessage: entity.entityInviteSuccessMessage,
  entityMembersFormData: entity.entityMembersFormData,
  allEntityInvitations: entity.allEntityInvitations,
  showEntityDetailsFlag: entity.showEntityDetailsFlag,
  selectedEntityDetails: entity.selectedEntityDetails,
  showAccptedEntityInviteDetailsFlag: entity.showAccptedEntityInviteDetailsFlag,
  acceptedRejectedEntityInviteDetails: entity.acceptedRejectedEntityInviteDetails,
  selectedEntityInviteDetails: entity.selectedEntityInviteDetails,
  sentRequests: entity.sentRequests,
  receivedRequests: entity.receivedRequests,
  selectedRequest: entity.selectedRequest,
  showOwnerField: entity.showOwnerField,
  specificEntityDetails: entity.specificEntityDetails,
  updatedEntityDetails: entity.updatedEntityDetails,
  googlePlacesAddress: entity.googlePlacesAddress,
  entityProfileURL: entity.entityProfileURL,
  allEntityInviteesList: entity.allEntityInviteesList,
  selectedMemberDetails: entity.selectedMemberDetails,
  inviteCardSelectedEntity: entity.inviteCardSelectedEntity,
  updatedEntityMemberDetails: entity.updatedEntityMemberDetails,
  selectedTabName: entity.selectedTabName,
  allEntityMembersList: entity.allEntityMembersList,
});

// Actions
export const getEntityList = createAsyncThunk('entity/getEntityList', async () => {
  return entityApi.getEntityList();
});

export const getEntityById = createAsyncThunk('entity/getEntityById', async (params: object) => {
  return entityApi.getFilteredEntityList(params);
});

export const setSelectedEntity = createAsyncThunk('entity/setSelectedEntity', async (selectedEntity: EntityTypes) => {
  return selectedEntity;
});

export const getEntityIdProcesses = createAsyncThunk('entities/getEntityIdProcesses', async (entityId: number) => {
  return await entityApi.getIdProcessesOfAnEntity(entityId);
});

export const searchAnEntity = createAsyncThunk('entities/searchAnEntity', async (formData: object) => {
  return await entityApi.searchEntity(formData);
});

export const setEntitySearchData = createAsyncThunk(
  'entities/setEntitySearchData',
  async (data: EntitySearchPayload) => {
    return data;
  },
);

export const createEntityForAnAccount = createAsyncThunk(
  'entities/createEntityForAccount',
  async (formData: object) => {
    return await entityApi.createNewEntity(formData);
  },
);

export const createNewAssociationRequest = createAsyncThunk(
  'entities/createNewAssociationRequest',
  async (data: object) => {
    return await createNewRequest(data);
  },
);

export const sendInviteToMembers = createAsyncThunk('entities/sendInviteToMembers', async (formData: any) => {
  return await entityApi.inviteEntityMembers(formData?.membersData, formData?.entity_uid);
});

export const getEntityInvitationList = createAsyncThunk('entities/getEntityInvitationList', async () => {
  return await entityApi.getEntityInvite();
});

export const entityInviteAction = createAsyncThunk(
  'entities/acceptEntityInvite',
  async (formData: AcceptInviteType) => {
    return await entityApi.inviteAction(formData?.inviteId, formData?.payload);
  },
);

export const getEntityInviteDetails = createAsyncThunk('entities/getEntityInviteDetails', async (token: string) => {
  return await entityApi.getEntityInviteDetailsByToken(token);
});

export const updateEntityApplicationStatus = createAsyncThunk(
  'entities/updateEntityApplicationStatus',
  async (formData: object) => {
    return await entityApi.startEntityApplication(formData);
  },
);

export const getSentAssociationRequests = createAsyncThunk(
  'entities/getSentAssociationRequests',
  async (params: object) => {
    return await getSentRequests(params);
  },
);

export const getReceivedAssociationRequests = createAsyncThunk(
  'entities/getReceivedAssociationRequests',
  async (params: object) => {
    return await getReceivedRequests(params);
  },
);

export const revokeEntityAssociationRequest = createAsyncThunk(
  'entity/revokeEntityAssociationRequest',
  async (requestId: string) => {
    return await revokeEntityRequest(requestId);
  },
);

export const getSpecificEntityDetails = createAsyncThunk(
  'entity/getSpecificEntityDetails',
  async (payload: { entityUid: number; filters?: object }) => {
    return await getEntityProfileDetails(payload?.entityUid, payload?.filters || {});
  },
);

export const updateEntityDetails = createAsyncThunk(
  'entity/updateEntityDetails',
  async (payload: { entityUid: number; formData: EditEntityInfoPayloadType }) => {
    return await updateEntityProfileDetails(payload?.entityUid, payload?.formData);
  },
);

export const uploadEntityAvatarFile = createAsyncThunk('entity/uploadEntityAvatarFile', async (file: any) => {
  return await uploadAvatar(file);
});

export const entityInvitessList = createAsyncThunk(
  'entities/entityInvitessList',
  async (payload: { entityUid: number; userData: object }) => {
    return await entityApi.getEntityInvitessList(payload?.entityUid, payload?.userData || {});
  },
);

export const getAllEntityMembers = createAsyncThunk('entities/getAllEntityMembers', async (entityUid: number) => {
  return await entityApi.getEntityInvitessList(entityUid);
});

export const updateEntityMemberDetails = createAsyncThunk(
  'entities/updateEntityMemberDetails',
  async (payload: { entity_uid: number; trellis_uid: number; userData: object }) => {
    return await entityApi.updateEntityMemberDetails(
      payload?.entity_uid,
      payload?.trellis_uid,
      payload?.userData || {},
    );
  },
);

export const deleteMember = createAsyncThunk(
  'entities/deleteMember',
  async (data: { entity_uid: number; trellis_uid: number }) => {
    return await entityApi.deleteEntityMember(data?.entity_uid, data?.trellis_uid);
  },
);

// Reducers
export const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntityFormData: (state, action) => {
      state.entityFormData = action.payload;
    },
    resetEntityFormData: (state) => {
      state.entityFormData = <EntityForm>{};
    },
    setAddressFormData: (state, action) => {
      state.addressFormData = action.payload;
    },
    resetAddressFormData: (state) => {
      state.addressFormData = <AddressForm>{};
    },
    resetAddEntityResultData: (state) => {
      state.addEntityResult = <EntityTypes>{};
    },
    setTaxId: (state, action) => {
      state.currentEntityTaxId = action.payload;
    },
    setIsEntity: (state, action) => {
      state.checkIsEntity = action.payload;
    },
    setEntityUiId: (state, action) => {
      state.checkEntityUiId = action.payload;
    },
    setEntityMembersFormData: (state, action) => {
      state.entityMembersFormData = action.payload;
    },
    resetEntityInviteSuccessMessage: (state) => {
      state.entityInviteSuccessMessage = '';
    },
    setShowEntityDetailsFlag: (state, action) => {
      state.showEntityDetailsFlag = action?.payload;
    },
    setShowAccptedEntityInviteDetailsFlag: (state, action) => {
      state.showAccptedEntityInviteDetailsFlag = action?.payload;
    },
    resetAcceptedRejectedEntityInviteDetails: (state) => {
      state.acceptedRejectedEntityInviteDetails = {} as EntityInvitionsType;
    },
    setSelectedEntityInviteDetails: (state, action) => {
      state.selectedEntityInviteDetails = action.payload;
    },
    setSelectedRequest: (state, action) => {
      state.selectedRequest = action?.payload;
    },
    setShowOwnerField: (state, action) => {
      state.showOwnerField = action?.payload;
    },
    setGPlacesAddress: (state, action) => {
      state.googlePlacesAddress = action.payload;
    },
    setEntityProfileImgUrl: (state, action) => {
      state.entityProfileURL = action.payload;
    },
    setSelectedMemberDetails: (state, action) => {
      state.selectedMemberDetails = action.payload;
    },
    setInviteCardSelectedEntity: (state, action) => {
      state.inviteCardSelectedEntity = action.payload;
    },
    setSelectedTabName: (state, action) => {
      state.selectedTabName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntityList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.entityList = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityList.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.entityList = [];
      })
      .addCase(setSelectedEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.selectedEntity = action.payload;
      })
      .addCase(getEntityIdProcesses.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityIdProcesses.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.entityIdProcessList = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityIdProcesses.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.entityIdProcessList = [];
      })
      .addCase(searchAnEntity.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(searchAnEntity.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.searchEntityType = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(searchAnEntity.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.searchEntityType = <SearchEntityType>{};
      })
      .addCase(setEntitySearchData.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.entitySearchData = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(createEntityForAnAccount.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createEntityForAnAccount.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.addEntityResult = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(createEntityForAnAccount.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(sendInviteToMembers.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(sendInviteToMembers.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.entityInviteSuccessMessage = action?.payload?.detail;
        } else {
          state.error = true;
        }
      })
      .addCase(sendInviteToMembers.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getEntityInvitationList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityInvitationList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload?.results?.length) {
          state.allEntityInvitations = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityInvitationList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getEntityInviteDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityInviteDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.selectedEntityDetails = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityInviteDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(entityInviteAction.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(entityInviteAction.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.acceptedRejectedEntityInviteDetails = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(entityInviteAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(createNewAssociationRequest.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createNewAssociationRequest.fulfilled, (state, action) => {
        if (action?.payload) {
          state.createdRequest = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(createNewAssociationRequest.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSentAssociationRequests.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSentAssociationRequests.fulfilled, (state, action) => {
        if (action.payload) {
          state.sentRequests = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getSentAssociationRequests.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getReceivedAssociationRequests.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getReceivedAssociationRequests.fulfilled, (state, action) => {
        if (action.payload) {
          state.receivedRequests = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getReceivedAssociationRequests.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(revokeEntityAssociationRequest.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(revokeEntityAssociationRequest.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.error = false;
        } else {
          state.error = true;
        }
      })
      .addCase(revokeEntityAssociationRequest.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSpecificEntityDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSpecificEntityDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.specificEntityDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificEntityDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateEntityDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateEntityDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.updatedEntityDetails = action.payload;
          state.entityProfileURL = action.payload?.avatar_url;
        } else {
          state.error = true;
        }
      })
      .addCase(updateEntityDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(uploadEntityAvatarFile.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(uploadEntityAvatarFile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.entityProfileURL = action.payload?.url;
        } else {
          state.error = true;
        }
      })
      .addCase(uploadEntityAvatarFile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(entityInvitessList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(entityInvitessList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        state.allEntityInviteesList = action?.payload;
      })
      .addCase(entityInvitessList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateEntityMemberDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateEntityMemberDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.updatedEntityMemberDetails = action.payload;
      })
      .addCase(updateEntityMemberDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteMember.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteMember.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.error = false;
        } else {
          state.error = true;
        }
      })
      .addCase(deleteMember.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getEntityById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityById.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.selectedEntity = action?.payload?.results[0];
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAllEntityMembers.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllEntityMembers.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.allEntityMembersList = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getAllEntityMembers.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setEntityFormData,
  resetEntityFormData,
  setAddressFormData,
  resetAddressFormData,
  resetAddEntityResultData,
  setTaxId,
  setIsEntity,
  setEntityUiId,
  resetEntityInviteSuccessMessage,
  setEntityMembersFormData,
  setShowEntityDetailsFlag,
  setShowAccptedEntityInviteDetailsFlag,
  resetAcceptedRejectedEntityInviteDetails,
  setSelectedEntityInviteDetails,
  setSelectedRequest,
  setShowOwnerField,
  setGPlacesAddress,
  setEntityProfileImgUrl,
  setSelectedMemberDetails,
  setInviteCardSelectedEntity,
  setSelectedTabName,
} = entitySlice.actions;

export default entitySlice.reducer;
