import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  MAX_CHAR_LIMIT,
  TAlertDialog,
  TButton,
  TCard,
  TCheckbox,
  theme,
  TRadio,
  TSelect,
  TTextArea,
  TTextField,
} from '../../components/myde-react-components';
import {
  ACCESS_TEXT,
  ACCESS_TYPES,
  ENTITY_INVITE_STATUS,
  REGEX,
  REQUEST_STATUS,
  ROLES,
} from '../../constants/constants';
import {
  selectEntity,
  getReceivedAssociationRequests,
  setShowOwnerField,
} from '../../redux/feature/entity/entitySlice';
import { CreatedRequestType } from '../../types/entityTypes';
import { reviewRequest } from '../../api/entity';
import { selectUser, setEntitySelectionFlag } from '../../redux/feature/user/userSlice';
import clsx from 'clsx';

interface ReviewInviteProps {
  trellis_uid: number;
  searchRequest?: string;
  isRequestTab?: boolean;
}

const useStyles = makeStyles({
  reviewInvite: {
    '& .MuiTypography-root': {
      padding: '0px !important',
    },
  },
  selectField: {
    '& .MuiFormControl-root': {
      width: '100% !important',
    },
    '& .MuiOutlinedInput-root': {
      width: '100% !important',
    },
  },
  radioButtons: {
    '& .MuiTypography-root': {
      padding: '0px !important',
    },
  },
});

const ReviewInvite = ({ trellis_uid, searchRequest, isRequestTab = false }: ReviewInviteProps) => {
  //Constants
  const classes = useStyles();
  const { t } = useTranslation(['common', 'userViewMessage']);
  const dispatch = useDispatch();
  const ReviewInviteSchema = z.object({
    ownership_percentage: z
      .string()
      .nullish()
      .optional()
      .refine((value) => REGEX.NUMBER_DECIMAL.test(value || ''), {
        message: 'Please enter a number',
      })
      .or(z.literal('')),
    access_type: z.string(),
  });

  type ReviewInviteSchemaPayload = z.infer<typeof ReviewInviteSchema>;
  const { control, formState, setValue, reset } = useForm<ReviewInviteSchemaPayload>({
    resolver: zodResolver(ReviewInviteSchema),
    mode: 'onChange',
  });
  const { errors } = formState;

  //state variables
  const [data, setData] = useState([] as CreatedRequestType[]);
  const [showAcceptDialog, setShowAcceptDialog] = useState(false);
  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState({} as CreatedRequestType);
  const [ownerRadio, setOwnerRadio] = useState(false);
  const [userRadio, setUserRadio] = useState(false);
  const [stake, setStake] = useState<string | null>('');
  const [access, setAccess] = useState('');
  const [note, setNote] = useState('');
  const [isBeneficialOwner, setIsBeneficialOwner] = useState(false);

  //redux values
  const { receivedRequests, showOwnerField } = useSelector(selectEntity);
  const { showEntitySelectionList } = useSelector(selectUser);

  //useEffects
  useEffect(() => {
    if (trellis_uid && searchRequest) {
      getSearchedData();
    } else {
      getData();
    }
  }, [trellis_uid, searchRequest]);

  useEffect(() => {
    if (receivedRequests) {
      setData(receivedRequests);
    }
  }, [receivedRequests]);

  useEffect(() => {
    reset();
  }, [showAcceptDialog]);

  useEffect(() => {
    if (stake?.length === 0) {
      setStake(null);
    }
  }, [stake]);

  //methods
  const getName = (item: CreatedRequestType) => {
    let nameValue = ``;
    if (item?.requestor_account?.first_name?.length > 0 && item?.requestor_account?.first_name) {
      nameValue += item?.requestor_account?.first_name;
    }
    if (item?.requestor_account?.middle_name?.length > 0 && item?.requestor_account?.middle_name) {
      nameValue += ` ${item?.requestor_account?.middle_name}`;
    }
    if (item?.requestor_account?.last_name?.length > 0 && item?.requestor_account?.last_name) {
      nameValue += ` ${item?.requestor_account?.last_name}`;
    }
    return `${nameValue} ${t('receiveRequestText')}`;
  };

  const getData = () => {
    const data = {
      entity_trellis_uid: trellis_uid,
      status: ENTITY_INVITE_STATUS.PENDING,
    };
    dispatch(getReceivedAssociationRequests(data));
  };

  const getSearchedData = () => {
    const data = {
      entity_trellis_uid: trellis_uid,
      status: ENTITY_INVITE_STATUS.PENDING,
      requestor_name: searchRequest,
    };
    dispatch(getReceivedAssociationRequests(data));
  };

  const getAcceptPayload = () => {
    let user_role = '';
    let permission = '';
    let ownership_percentage = null;
    if (ownerRadio) {
      user_role = ROLES.OWNER;
    } else if (userRadio) {
      user_role = ROLES.USER;
    }
    if (access === 'Full Access') {
      permission = ACCESS_TEXT.FULL_ACCESS;
    } else if (access === 'View Only') {
      permission = ACCESS_TEXT.VIEW_ONLY_ACCESS;
    }
    ownership_percentage = stake;
    return {
      review_status: REQUEST_STATUS.APPROVED,
      note: '',
      user_role: user_role,
      permission: permission,
      ownership_percentage: ownership_percentage,
      is_beneficial_owner: isBeneficialOwner,
    };
  };

  const handleAcceptConfirm = async () => {
    const data = getAcceptPayload();
    await reviewRequest(currentRequest?.id, data);
    resetStateValues();
    dispatch(setEntitySelectionFlag(!showEntitySelectionList));
    getData();
  };

  const handleAcceptCancel = () => {
    resetStateValues();
  };

  const handleDeclineConfirm = async () => {
    let data: any;
    if (note?.length === 0) {
      data = {
        review_status: REQUEST_STATUS.REJECTED,
        user_role: '',
        permission: '',
        ownership_percentage: null,
      };
    } else if (note?.length > 0) {
      data = {
        note: note,
        review_status: REQUEST_STATUS.REJECTED,
        user_role: '',
        permission: '',
        ownership_percentage: null,
      };
    }
    await reviewRequest(currentRequest?.id, data);
    dispatch(setEntitySelectionFlag(!showEntitySelectionList));
    setShowDeclineDialog(false);
    getData();
    resetStateValues();
  };

  const handleDeclineCancel = () => {
    setShowDeclineDialog(false);
  };

  const handelChangeTextBox = (event: any) => {
    setValue('ownership_percentage', event?.target?.value, { shouldValidate: true });
    setStake(event?.target?.value);
  };

  const handleAccessChange = (event: any) => {
    setAccess(event?.target?.value);
    setValue('access_type', event.target.value, { shouldValidate: true });
  };

  const handleNoteChange = (event: any) => {
    setNote(event?.target?.value);
  };

  const handleAcceptDisable = () => {
    let result = false;
    if ((ownerRadio || userRadio) && access?.length > 0 && !errors?.ownership_percentage) {
      result = true;
    }
    return result;
  };

  const resetStateValues = () => {
    setShowAcceptDialog(false);
    setOwnerRadio(false);
    setUserRadio(false);
    setAccess('');
    setStake(null);
    setIsBeneficialOwner(false);
  };

  const handleCheckboxChange = () => {
    setIsBeneficialOwner(!isBeneficialOwner);
  };

  const handleOwnerChange = () => {
    setOwnerRadio(true);
    dispatch(setShowOwnerField(true));
    setAccess('');
    setUserRadio(false);
    setIsBeneficialOwner(false);
    reset();
  };

  const handleUserChange = () => {
    setOwnerRadio(false);
    dispatch(setShowOwnerField(false));
    setAccess('');
    setUserRadio(true);
    setIsBeneficialOwner(false);
    setStake(null);
  };

  return (
    <>
      {data?.length === 0 && (
        <Box>
          <Box sx={{ pt: 5 }} className="text-h3 font-weight-semibold textColor-300 text-center">
            {t('nodataAvailable', { ns: 'userViewMessage' })}
          </Box>
        </Box>
      )}
      <Box
        className={clsx({
          ['acceptRequestsScroll']: isRequestTab,
          ['request-scroll']: !isRequestTab,
        })}
      >
        {data?.map((item: CreatedRequestType) => {
          return (
            <TCard key={item?.requestor_account?.id} sx={{ mb: 3 }} className="requestBackground">
              <Box sx={{ pt: 1, pl: theme.spacing(3), pr: theme.spacing(2) }}>{getName(item)}</Box>
              <Box className="flex-basic-start">
                <Box>
                  <TButton
                    btnText="Accept"
                    variant="text"
                    onClick={() => {
                      setShowAcceptDialog(true);
                      dispatch(setShowOwnerField(true));
                      setCurrentRequest(item);
                    }}
                  />
                </Box>
                <Box>
                  <TButton
                    btnText="Decline"
                    variant="text"
                    onClick={() => {
                      setShowDeclineDialog(true);
                      setCurrentRequest(item);
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <TAlertDialog
                  key={item?.requestor_account?.id}
                  showDialog={showAcceptDialog}
                  title={'Accept Request'}
                  content={getName(currentRequest)}
                  yesBtnTitle={'Confirm'}
                  noBtnTitle={'Cancel'}
                  onCancel={handleAcceptCancel}
                  onClose={handleAcceptCancel}
                  onConfirm={handleAcceptConfirm}
                  isButtonDisabled={!handleAcceptDisable()}
                  extraContent={
                    <Box>
                      <form>
                        <FormControl className={classes.radioButtons}>
                          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                            <Box sx={{ pb: 2 }} className="flex-basic-start">
                              <FormControlLabel
                                value="owner"
                                control={<TRadio onChange={handleOwnerChange} />}
                                label="Owner"
                              />
                              <FormControlLabel
                                value="user"
                                control={<TRadio onChange={handleUserChange} />}
                                label="User"
                              />
                            </Box>
                          </RadioGroup>
                        </FormControl>
                        {showOwnerField && (
                          <Controller
                            name={'ownership_percentage'}
                            defaultValue={null}
                            control={control}
                            render={({ field }) => (
                              <TTextField
                                {...field}
                                label="Ownership Entity Stake % (Optional)"
                                variant="outlined"
                                fullWidth
                                onChange={handelChangeTextBox}
                                error={Boolean(errors.ownership_percentage)}
                                helperText={errors.ownership_percentage?.message}
                              />
                            )}
                          />
                        )}

                        <Box sx={{ my: theme.spacing(3) }} className={classes.selectField}>
                          <FormControl>
                            <TSelect
                              name="access_type"
                              id="type"
                              value={access}
                              options={ACCESS_TYPES}
                              label="Permissions"
                              itemId="id"
                              itemValue="label"
                              onChange={handleAccessChange}
                            />
                          </FormControl>
                        </Box>
                        {showOwnerField && (
                          <Box className={classes.reviewInvite}>
                            <FormControlLabel
                              value="benificialOwner"
                              control={<TCheckbox value={isBeneficialOwner} onChange={handleCheckboxChange} />}
                              label={t('beneficialOwner')}
                            />
                            <Box className="text-extra-small">{t('benificaialOwnerNote')}</Box>
                          </Box>
                        )}
                      </form>
                    </Box>
                  }
                />
              </Box>
              <Box>
                <TAlertDialog
                  key={item?.requestor_account?.id}
                  showDialog={showDeclineDialog}
                  title={'Decline Request'}
                  content={getName(currentRequest)}
                  yesBtnTitle={'Confirm'}
                  noBtnTitle={'Cancel'}
                  onCancel={handleDeclineCancel}
                  onConfirm={handleDeclineConfirm}
                  onClose={handleDeclineCancel}
                  extraContent={
                    <Box>
                      <TTextArea
                        variant="outlined"
                        onChange={(event) => handleNoteChange(event)}
                        label={'Add Note'}
                        fullWidth
                        inputProps={{ maxLength: MAX_CHAR_LIMIT.CHAR_LENGTH }}
                        minRows={4}
                      />
                    </Box>
                  }
                />
              </Box>
            </TCard>
          );
        })}
      </Box>
    </>
  );
};

export default ReviewInvite;
