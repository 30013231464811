import React, { useState } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useTheme } from '../../../../../providers/custom-theme-provider';
import { MAX_CHAR_LIMIT } from '../../constants/constants';

interface ReadMoreProps {
  text: string;
  maxCharacters?: number;
  textStyle?: string;
  charsToShow?: number;
}

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  primaryColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
  },
});

export const ReadMore = ({
  text,
  maxCharacters = MAX_CHAR_LIMIT.CHAR_LENGTH,
  charsToShow = 200,
  textStyle = 'textColor-200 text-large',
}: ReadMoreProps) => {
  // constants
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);

  // state variables
  const [isReadMore, setIsReadMore] = useState(true);

  // methods
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {text?.length < maxCharacters ? (
        <p className={clsx('readMoreTextContainer', textStyle)}>{text}</p>
      ) : (
        <p className={clsx('readMoreTextContainer', textStyle)}>
          {isReadMore ? text.slice(0, charsToShow) : text}
          <span onClick={toggleReadMore} className={clsx('cursorPointer', classes.primaryColor)}>
            {isReadMore ? '...show more' : ' show less'}
          </span>
        </p>
      )}
    </>
  );
};

export default ReadMore;
