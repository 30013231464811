import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, ListItem, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { typography } from '../../styles/style';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { getTAppLogin } from '../../api/tApp';
import { getTAppDetails, getTAppList, setTabStatus } from '../../redux/feature/myTApps/myTAppsSlice';
import { colorPalette, TCommonAvatar, TLoader } from '../../components/myde-react-components';
import { getInvitation } from '../../redux/feature/myTApps/myTAppsSlice';
import { inviteDetails } from '../../redux/feature/invite/inviteSlice';

interface InvitationProps {
  status: string;
  isApproved: boolean;
  approvedTapps?: any;
}
const useStyles = makeStyles({
  noDataBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: ' translate(-50%, -50%)',
  },
  tApp: {
    cursor: 'pointer',
  },
  paperBox: {
    borderRadius: '10px !important',
    boxShadow: `0px 0px 11px ${colorPalette.typoText.lighten2}  !important`,
    padding: 24,
    display: 'flex',
    maxWidth: '100%',
  },
  signInIcon: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});
const TAppInvitations = ({ status, isApproved }: InvitationProps) => {
  const [value, setValue] = useState('1');
  const dispatch = useDispatch();
  const ssoSubscription = async (id: string) => {
    if (isApproved) {
      try {
        const resp: any = await getTAppLogin(id);
        if (resp && resp.redirect_url) {
          const openWindow = window.open(resp && resp.redirect_url, '_blank');
          if (openWindow) {
            openWindow.focus();
          }
        }
      } catch (err) {
        // pass
      }
    }
  };
  const [tAppListing, setTAppListing] = useState([] as any);
  const { view, tAppInvitations, loading, tAppList } = useSelector(getTAppList);
  const { inviteList } = useSelector(inviteDetails);

  useEffect(() => {
    setValue(view);
  }, [view]);

  useEffect(() => {
    setTAppListing(inviteList);
  }, [inviteList]);

  useEffect(() => {
    dispatch(setTabStatus(status));
    if (isApproved) {
      dispatch(getTAppDetails());
      setTAppListing(tAppList);
    } else {
      const inviteOption = {
        isTapp: true,
        status: status,
      };
      dispatch(getInvitation(inviteOption));
      setTAppListing(tAppInvitations);
    }
  }, []);

  useEffect(() => {
    setTAppListing(tAppInvitations);
  }, [tAppInvitations]);

  useEffect(() => {
    setTAppListing(tAppList);
  }, [tAppList]);

  const classes = useStyles();
  return (
    <>
      {/* Grid view */}
      {value === '1' ? (
        <Grid container>
          {tAppListing.length > 0 ? (
            <>
              {tAppListing.map((element: any) => (
                <Grid item xs={6} md={6} lg={6} sm={6} key={element.id}>
                  <Box className={classes.tApp} onClick={() => ssoSubscription(element.t_app.id)}>
                    <TCommonAvatar
                      iconSize="80px"
                      imageUrl={
                        element.t_app && Object.keys(element.t_app.branding).length > 0
                          ? element.t_app.branding.logo_url
                          : ''
                      }
                      avatarText={element?.t_app?.name}
                    />
                    <Typography sx={{ p: 2, textAlign: 'center' }} style={typography.captionSm}>
                      {element?.t_app?.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </>
          ) : (
            <Box className={classes.noDataBox}>
              <Typography variant="caption">No Data</Typography>
            </Box>
          )}
        </Grid>
      ) : (
        <Box>
          {tAppListing.length > 0 ? (
            <List sx={{ py: 0 }}>
              {tAppListing.map((element: any) => (
                <ListItem
                  disablePadding
                  key={element.id}
                  sx={{ borderBottom: `1px solid ${colorPalette.border.card}` }}
                  onClick={() => ssoSubscription(element.t_app.id)}
                >
                  <ListItemButton sx={{ my: 1, px: 0 }}>
                    <ListItemIcon>
                      <TCommonAvatar
                        iconSize="35px"
                        imageUrl={
                          element.t_app && Object.keys(element.t_app.branding).length > 0
                            ? element.t_app.branding.logo_url
                            : ''
                        }
                        avatarText={element.t_app.name}
                      />
                    </ListItemIcon>
                    <ListItemText primary={element.t_app.name} className={clsx('text-small font-weight-semibold')} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box className={classes.noDataBox}>
              {loading ? <TLoader loading={loading} /> : <Typography variant="caption">No Data</Typography>}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default TAppInvitations;
