import { RootState } from '../..';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSubscriptions, getInvitations, getSubscriptionsWithFilter } from '../../../api/tApp';
import { TAppType, TAppInvitationsType } from '../../../types/tAppTypes';

type myTAppsState = {
  tAppList: TAppType[];
  tAppInvitations: TAppInvitationsType[];
  loading: boolean;
  error: boolean;
  view: string;
  searchedField: string;
  searchToggle: boolean;
  filterStatus: string;
};

const initialState: myTAppsState = {
  loading: false,
  error: false,
  tAppList: [],
  tAppInvitations: [],
  view: '',
  searchedField: '',
  searchToggle: false,
  filterStatus: '',
};

export const getTAppList = ({ myTApps }: RootState) => ({
  tAppList: myTApps.tAppList,
  loading: myTApps.loading,
  view: myTApps.view,
  tAppInvitations: myTApps.tAppInvitations,
  searchedField: myTApps.searchedField,
  searchToggle: myTApps.searchToggle,
  filterStatus: myTApps.filterStatus,
});

export const getTAppDetails = createAsyncThunk('myTApps/getTAppDetails', async () => {
  return await getSubscriptions();
});

export const getTAppDetailsWithFilter = createAsyncThunk('myTApps/getTAppDetailsWithFilter', async (filter: object) => {
  return await getSubscriptionsWithFilter(filter);
});

export const getInvitation = createAsyncThunk('myTApps/getInvitation', async (filters: object) => {
  return await getInvitations(filters);
});

export const setView = createAsyncThunk('myTApps/setView', async (item: string) => {
  return item;
});

export const myTAppsSlice = createSlice({
  name: 'myTApps',
  initialState,
  reducers: {
    setSearchedField: (state, action) => {
      state.searchedField = action.payload;
    },
    resetSearchedField: (state) => {
      state.searchedField = '';
    },
    setSearchToggle: (state, action) => {
      state.searchToggle = action.payload;
    },
    resetSearchToggle: (state) => {
      state.searchToggle = false;
    },
    setTabStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTAppDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getTAppDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.tAppList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getTAppDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(setView.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.view = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getInvitation.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInvitation.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.tAppInvitations = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getInvitation.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getTAppDetailsWithFilter.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getTAppDetailsWithFilter.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.tAppList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getTAppDetailsWithFilter.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setSearchedField, resetSearchedField, setSearchToggle, resetSearchToggle, setTabStatus } =
  myTAppsSlice.actions;

export default myTAppsSlice.reducer;
