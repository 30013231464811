import React, { useEffect, useState } from 'react';
import { Divider, Box, List, ListItem, ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboardDetails } from '../../../redux/feature/dashboard/dashboardSlice';
import * as ROUTES from '../../../constants/routes';
import clsx from 'clsx';
import { IdProcessDetails } from '../../../types/dashboardTypes';
import {
  TAccordion,
  colorPalette,
  ID_PROCESS_STATUS,
  PROCESS_KEYS_WITH_PROCESS_ID,
  TPaper,
  TRating,
  theme,
  PROCESS_IDS,
} from '../../../components/myde-react-components';

import {
  ID_PROCESS_CATEGORY,
  PROFILE_ICONS,
  TAX_ID_ICONS,
  KYC_AML_ICONS,
  ADDRESS_ICONS,
  BIOMETRIC_ICONS,
  IDENTITY_DOC_ICONS,
  SIGNATURE_VERIFICATION_ICONS,
  SUPPLEMENTAL_DOCUMENTS_ICONS,
  INCOME_VERIFICATION_ICONS,
  ID_PROCESS_STATUS_SHOW_TEXT,
  ACCREDITATION,
  ID_PROCESS_STATUS_TEXT,
  ID_PROCESS_VERIFIED_STATUSES,
} from '../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { fontStyle } from '../../../styles/style';
import { useRouter } from '../../../providers/custom-router-provider';
import { IconStatusType } from '../../../types/idProcessTypes';
import { setColorIdProcess } from '../../../utils/utils';
import {
  clearSelectedIdProcessData,
  resetSelectedIdProcessDetails,
  setIdProcessKey,
  setParentIdProcess,
} from '../../../redux/feature/idProcess/mainIdProcessSlice';
import { setHideIdShareTab } from '../../../redux/feature/idProcess/idProcessShareSlice';
import { RouteType } from '../../../types/commonTypes';
import { setParentRoute } from '../../../redux/feature/common/commonSlice';

interface IdProcessListProps {
  category: string;
  hideAccordion?: boolean;
}

const useStyles = makeStyles({
  listItem: {
    border: `1px solid ${colorPalette.typoText.lighten1}`,
    borderRadius: '10px',
    borderStyle: 'dashed',
    cursor: 'pointer',
  },
  checked: {
    borderStyle: 'none',
    border: 'none',
    borderRadius: '10px',
    background: colorPalette.containerBack.lighten3,
    cursor: 'pointer',
    boxShadow: `0px 0px 11px ${colorPalette.typoText.lighten2}`,
  },
  iconContainer: {
    width: '34px',
    height: '34px',
    border: `2px solid ${colorPalette.iconColor.main}`,
    borderRadius: '50%',
    textAlign: 'center',
  },
  icon: {
    ...fontStyle.fontSize.font36,
  },
  retry: {
    color: colorPalette.border.warning,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
  },
  badgeIcon: {
    '& .MuiBadge-badge': {
      top: '4px',
      right: '6px',
      minWidth: '10px',
      padding: 0,
      height: '0.6rem',
    },
  },
  userDetailBox: {},
  check: {
    pointerEvents: 'none',
  },
  caution: {
    '& .MuiSvgIcon-root': {
      width: '0.5em',
      height: 'auto',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    float: 'right',
  },
  identityCard: {
    border: `1px solid ${colorPalette.border.card} !important`,
    borderRadius: '10px !important',
    boxShadow: 'none !important',
  },
});

export const IdProcessList = ({ category, hideAccordion }: IdProcessListProps) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const classes = useStyles();

  //Redux values
  const { idProcessList } = useSelector(selectDashboardDetails);

  //State values
  const [idProcesses, setIdProcesses] = useState([] as IdProcessDetails[]);
  const [verifiedIdProcesses, setVerifiedIdProcesses] = useState([] as IdProcessDetails[]);
  const [idProcessPercent, setIdProcessPercent] = useState(0);

  const { routeTo } = useRouter();
  const idProcessStatusCheck = [
    ID_PROCESS_STATUS.IN_PROGRESS,
    ID_PROCESS_STATUS.SUBMITTED,
    ID_PROCESS_STATUS.PENDING_VERIFICATION,
    ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
  ];

  //useEffects
  useEffect(() => {
    dispatch(setHideIdShareTab(false));
    dispatch(clearSelectedIdProcessData());
    dispatch(setParentRoute({} as RouteType));
  }, []);
  useEffect(() => {
    const list = idProcessList.filter(
      (element: IdProcessDetails) => !element?.parent_process_id && element?.category === category,
    );
    const filteredList = idProcessList?.map((item) => {
      if (ID_PROCESS_VERIFIED_STATUSES.includes(item?.status) && !item?.parent_process_id) {
        return {
          ...item,
          rating: 0.5,
        };
      }
      return {
        ...item,
      };
    });
    setVerifiedIdProcesses(filteredList);
    if (list.length) {
      setIdProcesses(list);
    }
  }, [idProcessList]);

  useEffect(() => {
    const filteredIdProcesses = verifiedIdProcesses?.filter(
      (item) => !item?.parent_process_id && ID_PROCESS_VERIFIED_STATUSES?.includes(item?.status),
    );
    let sum = 0;
    filteredIdProcesses?.forEach((item) => {
      if (item?.rating) {
        sum += item?.rating;
      }
    });
    setIdProcessPercent(sum);
  }, [verifiedIdProcesses]);

  //Methods
  const getIconByStatus = (status: string, type: IconStatusType) => {
    if (
      status === ID_PROCESS_STATUS.PASSED ||
      status === ID_PROCESS_STATUS.VERIFIED ||
      status === ID_PROCESS_STATUS.SYSTEM_APPROVED ||
      status === ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW
    ) {
      return type.SUCCESS;
    } else if (status === ID_PROCESS_STATUS.FAILED || status === ID_PROCESS_STATUS.VERIFICATION_FAILED) {
      return type.ERROR;
    } else {
      return type.NORMAL;
    }
  };

  const gotToSpecificProcess = (element: any) => {
    dispatch(resetSelectedIdProcessDetails());
    dispatch(setParentIdProcess(element));
    const selectedProcessId =
      element?.process_id === PROCESS_IDS.DOCUMENT_VERIFICATION ? element?.process_id : element?.process_id + 1;
    const query = { id: selectedProcessId };
    const idProcessData: any = PROCESS_KEYS_WITH_PROCESS_ID.find((item) =>
      item?.id_processes?.includes(element?.process_id + 1),
    );
    const processKey = idProcessData.process_key || '';
    const title = idProcessData?.process_title || '';
    dispatch(setIdProcessKey(processKey));
    routeTo(ROUTES.ID_PROCESS, true, title, query);
  };

  const getIconName = (id: number, status: string) => {
    switch (id) {
      case 50:
        return getIconByStatus(status, PROFILE_ICONS);
      case 100:
        return getIconByStatus(status, TAX_ID_ICONS);
      case 200:
        return getIconByStatus(status, IDENTITY_DOC_ICONS);
      case 300:
        return getIconByStatus(status, BIOMETRIC_ICONS);
      case 600:
        return getIconByStatus(status, ADDRESS_ICONS);
      case 400:
        return getIconByStatus(status, SIGNATURE_VERIFICATION_ICONS);
      case 500:
        return getIconByStatus(status, SUPPLEMENTAL_DOCUMENTS_ICONS);
      case 250:
        return getIconByStatus(status, KYC_AML_ICONS);
      case 700:
        return getIconByStatus(status, INCOME_VERIFICATION_ICONS);
      case 800:
        return getIconByStatus(status, ACCREDITATION);
      default:
    }
  };

  const getIconTemplate = (status: string, processId: number, status_label: string) => {
    return (
      <>
        <Box style={{ color: setColorIdProcess(status) }} className="flex-basic-center">
          {idProcessStatusCheck?.includes(status) &&
          status !== ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW &&
          status_label !== ID_PROCESS_STATUS_TEXT?.PENDING_VERIFICATION ? (
            <Box className="p-relative custom-progress-icon">
              <Box className="progress-spinner"></Box>
              <span className={clsx(`${getIconName(processId, status)}`)} />
            </Box>
          ) : (
            <Box>
              <span className={clsx(`${getIconName(processId, status)}`, classes.icon)} />
            </Box>
          )}
        </Box>
      </>
    );
  };

  //HTML
  return (
    <Box>
      <Box>
        {!hideAccordion && (
          <>
            <TAccordion
              isExpanded={true}
              accordinHeaderText={t('enhanceDigitalIdentity')}
              accordinBodyText1={t('idVerificationBody1')}
              accordinBodyText2={t('idVerificationBody2')}
              accordinBodyText3={t('idVerificationBody3')}
            ></TAccordion>
            <Divider sx={{ mt: 2 }} />
          </>
        )}
        {category === ID_PROCESS_CATEGORY.BASIC && (
          <TPaper sx={{ mt: 2, py: 1, px: 2 }}>
            <Box className="flex-basic-start">
              <Box className="text-medium font-weight-semibold textColor-200" sx={{ mb: theme.spacing(1) }}>
                {t('idVerification')}
              </Box>
              <Box sx={{ mx: 2 }}>
                <TRating value={idProcessPercent} readOnly size="large" precision={0.5} />
              </Box>
            </Box>
            <Box className="text-extra-small textColor-200">
              {idProcessPercent < 5 ? t('moreIdProcesses') : t('completedIdProcesses')}
            </Box>
          </TPaper>
        )}
        <List>
          {idProcesses?.map((element: any) => (
            <ListItem
              className={clsx({
                [classes.listItem]: true,
                [classes.checked]:
                  element?.status === ID_PROCESS_STATUS.SUBMITTED ||
                  element?.status === ID_PROCESS_STATUS.PASSED ||
                  element?.status === ID_PROCESS_STATUS.SYSTEM_APPROVED ||
                  element?.status === ID_PROCESS_STATUS.FAILED ||
                  element?.status === ID_PROCESS_STATUS.IN_PROGRESS ||
                  element?.status === ID_PROCESS_STATUS.IN_DRAFT ||
                  element?.status === ID_PROCESS_STATUS.PENDING_VERIFICATION ||
                  element?.status === ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
              })}
              sx={{ py: 3, my: 1.5 }}
              key={element?.process_id}
              onClick={() => gotToSpecificProcess(element)}
            >
              <ListItemIcon>{getIconTemplate(element?.status, element?.process_id, element.status_label)}</ListItemIcon>
              <Box className="flex-basic-space-between w-100">
                <Box
                  className={clsx({
                    ['textColor-200 text-large font-weight-semibold']: element?.status,
                    ['textColor-300 text-large font-weight-semibold']: !element?.status,
                  })}
                >
                  {element?.title}
                </Box>
                {idProcessStatusCheck.includes(element?.status) && (
                  <Box className="text-extra-small font-weight-medium textColor-300">
                    {ID_PROCESS_STATUS_SHOW_TEXT[element?.status]}
                  </Box>
                )}
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default IdProcessList;
