import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PROCESS_IDS } from '../../../../constants/constants';
import { selectSession, setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { selectIdeProcessDetails } from '../../../../redux/feature/idProcess/documentVerificationSlice';
import { selectSelectedIdProcessDetails } from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { ID_PROCESS_STATUS_COMPLETION_STATE, ID_PROCESS_STEP_KEYS, TLoader } from '../../../myde-react-components';
import IDList from './components/IDList';
import ProfileSelection from './components/ProfileSelection';
import UploadBackImage from './components/UploadBackImage';
import UploadFrontImage from './components/UploadFrontImage';

const DocumentConfirmation = () => {
  const dispatch = useDispatch();

  // Redux Values
  const { currentStep } = useSelector(selectSession);
  const { processId } = useSelector(selectSelectedIdProcessDetails);
  const { loading } = useSelector(selectIdeProcessDetails);

  // State Values
  const [stepKey, setStepKey] = useState(1);
  const ID_PROCESS_COMPONENTS_IN_PROGRESS = [
    {
      stepKey: ID_PROCESS_STEP_KEYS.STEP1,
      component: <IDList />,
    },
    {
      stepKey: ID_PROCESS_STEP_KEYS.STEP2,
      component: <ProfileSelection />,
    },
    {
      stepKey: ID_PROCESS_STEP_KEYS.STEP3,
      component: <UploadFrontImage />,
    },
    {
      stepKey: ID_PROCESS_STEP_KEYS.STEP4,
      component: <UploadBackImage />,
    },
  ];

  // Use Effects
  useEffect(() => {
    // To handle edit operation
    if (processId > PROCESS_IDS.DOCUMENT_VERIFICATION) {
      dispatch(setCurrentStep(ID_PROCESS_STEP_KEYS.STEP2));
      dispatch(setPreviousStep(ID_PROCESS_STEP_KEYS.STEP1));
    } else {
      dispatch(setCurrentStep(ID_PROCESS_STEP_KEYS.STEP1));
      dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[0].percent));
    }
  }, []);

  useEffect(() => {
    if (currentStep) {
      setStepKey(currentStep);
    }
  }, [currentStep]);

  // Methods
  const getSelectedStepComponent = () => {
    const currentComponentItem = ID_PROCESS_COMPONENTS_IN_PROGRESS.find((item) => item?.stepKey === stepKey);
    return currentComponentItem?.component || <></>;
  };

  // HTML
  return (
    <>
      <TLoader loading={loading} />
      {getSelectedStepComponent()}
    </>
  );
};
export default DocumentConfirmation;
