import React from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { theme } from '../myde-react-components';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../redux/feature/profile/profileSlice';
import clsx from 'clsx';
import { useTheme } from '../../providers/custom-theme-provider';
import { hexToRgbA } from '../../utils/utils';

export interface StyleProps {
  iconSize?: string;
  matches: boolean;
  icon: string;
  imgBorderRadius?: string;
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  userIconContainer: {
    position: 'relative',
    width: ({ iconSize }) => (iconSize ? iconSize : '100%'),
    height: ({ iconSize }) => (iconSize && iconSize !== '100%' ? iconSize : '36.6vh'),
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
    border: '1px solid',
    borderColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.5),
    textAlign: 'center',
    borderRadius: ({ imgBorderRadius }) => (imgBorderRadius ? imgBorderRadius : '50%'),
  },

  profileImage: {
    borderRadius: ({ imgBorderRadius }) => (imgBorderRadius ? imgBorderRadius : '50%'),
    width: ({ iconSize }) => (iconSize ? iconSize : '100%'),
    height: ({ iconSize }) => (iconSize ? iconSize : '36.6vh'),
  },
  iconColors: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
}));

interface UserIconProps {
  iconSize?: string;
  imgBorderRadius?: string;
}

const UserIcon = ({ iconSize = '100%', imgBorderRadius = '50%' }: UserIconProps) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const icon = iconSize && iconSize !== '100%' ? parseInt(iconSize, 10) / 2 + 'px' : '50%';
  const { currentThemes } = useTheme();
  const StyleProps = {
    iconSize,
    matches,
    icon,
    imgBorderRadius,
    currentThemes,
  };

  const classes = useStyles(StyleProps);
  const { profileUrl } = useSelector(selectProfile);

  return (
    <>
      {profileUrl && profileUrl !== '' ? (
        <Box className={classes.userIconContainer}>
          <img src={`${profileUrl}`} loading="lazy" className={classes.profileImage} />
        </Box>
      ) : (
        <Box className={clsx(classes.userIconContainer, 'flex-basic-center')}>
          <div className={classes.userIcon}>
            <Box className={clsx('icon-profile-users icon-messages-font', classes.iconColors)}></Box>
          </div>
        </Box>
      )}
    </>
  );
};

export default UserIcon;
