import { ID_PROCESS_STATUS } from '../components/myde-react-components';
import * as ROUTES from './routes';

export const URL_REGEX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const TAX_ID_REGEX = /^[A-Za-z0-9]*$/;

export const PHONE_TYPES = [
  {
    label: 'Mobile',
    id: 'Mobile',
  },
  {
    label: 'Home',
    id: 'Home',
  },
];

export const ADDRESS_TYPE_TEXT = {
  Residential: 'Residential',
  Non_residential: 'Non-Residential',
};

export const ADDRESS_TYPES_KEYS = {
  Residential: 'residential',
  Non_residential: 'non_residential',
};

export const ADDRESS_TYPES = [
  {
    label: ADDRESS_TYPE_TEXT.Residential,
    id: ADDRESS_TYPES_KEYS.Residential,
  },
  {
    label: ADDRESS_TYPE_TEXT.Non_residential,
    id: ADDRESS_TYPES_KEYS.Non_residential,
  },
];

export const MESSAGES_UNREAD_COUNT_KEYS = {
  APPLICATION_MESSAGES: 'total_unread_response_count',
  MY_INBOX: 'inbox_unread_count',
  MY_DOCUMENTS: 'unread_document_count',
};

export const CUSTOM_ID_REQUIREMENTS_LINK_UNLINK = {
  LINK: 'LINK',
  UNLINK: 'UNLINK',
};

export const ACCESS_TYPES = [
  {
    label: 'Full Access',
    id: 'Full Access',
  },
  {
    label: 'View Only',
    id: 'View Only',
  },
];

export const ACCESS_TEXT = {
  VIEW_ONLY_ACCESS: 'VIEW_ONLY_ACCESS',
  FULL_ACCESS: 'FULL_ACCESS',
};

export const ACCESS_TYPE_LABEL: any = {
  FULL_ACCESS: 'Full Access',
  VIEW_ONLY_ACCESS: 'View Only',
};

export const ROLE_TEXT: any = {
  USER: 'User',
  OWNER: 'Owner',
};

export const REQUEST_REVIEW_STATUS = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};

export const ROLES = {
  OWNER: 'OWNER',
  USER: 'USER',
  REQUEST: 'REQUEST',
};

export const REQUEST_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const Icons = {
  Profile_Verification: 'icon-user',
  SSN_Verification: 'icon-SSN-Verification',
  Document_Verification: 'icon-Document-Verification',
  Biometric_Verification: 'icon-Biometric-Verification',
  Address_Verification: 'icon-Address-Verification',
  Signature_Verification: 'icon-Signature-Verification',
  Supplemental_Documents: 'icon-Supplemental-Documents',
  Kyc_Aml: 'icon-user-profile',
};

export const CUSTOM_DOCUMENTS = {
  NORMAL: 'icon-custom-document',
  ERROR: 'icon-letter-evidence',
  SUCCESS: 'icon-letter-evidence-success',
};

export const PROFILE_ICONS = {
  NORMAL: 'icon-profile-users',
  ERROR: 'icon-profile-decline',
  SUCCESS: 'icon-profile',
};

export const TAX_ID_ICONS = {
  NORMAL: 'icon-tax-ID',
  ERROR: 'icon-tax-id-decline',
  SUCCESS: 'icon-tax-Id',
};

export const IDENTITY_DOC_ICONS = {
  NORMAL: 'icon-Identity-document',
  ERROR: 'icon-identity-document-decline-2',
  SUCCESS: 'icon-id',
};

export const BIOMETRIC_ICONS = {
  NORMAL: 'icon-fing-biometrics',
  ERROR: 'icon-biometric-verification-decline',
  SUCCESS: 'icon-biometrics',
};

export const ADDRESS_ICONS = {
  NORMAL: 'icon-location',
  ERROR: 'icon-address-verification-decline',
  SUCCESS: 'icon-address',
};

export const KYC_AML_ICONS = {
  NORMAL: 'icon-user-kyc',
  ERROR: 'icon-kyc-aml-decline',
  SUCCESS: 'icon-kyc',
};

export const SIGNATURE_VERIFICATION_ICONS = {
  NORMAL: 'icon-wet-signature',
  ERROR: 'icon-w8-sign-verification-decline',
  SUCCESS: 'icon-w8-sign-verification',
};

export const SUPPLEMENTAL_DOCUMENTS_ICONS = {
  NORMAL: 'icon-w8',
  ERROR: 'icon-w8-w9-decline',
  SUCCESS: 'icon-w8-w9',
};

export const INCOME_VERIFICATION_ICONS = {
  NORMAL: 'icon-income-verification',
  ERROR: 'icon-income-verification-fail',
  SUCCESS: 'icon-income-verification-success',
};

export const ACCREDITATION = {
  NORMAL: 'icon-accreditation',
  ERROR: 'icon-accreditation-fail',
  SUCCESS: 'icon-accreditation-success',
};

export const IRS_LETTER_EVIDENCING_TAX_ID = {
  NORMAL: 'icon-letter-evidence',
  ERROR: 'icon-irs-letter-evidencing-decline-4',
  SUCCESS: 'icon-union',
};

export const W9_W8BEN = {
  NORMAL: 'icon-w8',
  ERROR: 'icon-w8-w9-decline',
  SUCCESS: 'icon-w8-w9',
};

export const LETTER_OF_GOOD_STANDING_ICONS = {
  NORMAL: 'icon-letter-of-good-standing',
  ERROR: 'icon-letter-of-good-failed',
  SUCCESS: 'icon-letter-of-good-succes',
};

export const OWNERSHIP_DOCUMENT = {
  NORMAL: 'icon-ownership',
  ERROR: 'icon-owner-doc-failed',
  SUCCESS: 'icon-owner-doc-succes',
};

export const FORMATION_DOCUMENTS = {
  NORMAL: 'icon-formation-document',
  ERROR: 'icon-formation-doc-failed',
  SUCCESS: 'icon-formation-doc-succes',
};

export const ID_PROCESS_CATEGORY = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
  SUPPLEMENTAL: 'SUPPLEMENTAL',
};

export const ID_PROCESS_VERIFIED_STATUSES = [
  ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
  ID_PROCESS_STATUS.VERIFICATION_NEEDS_MANUAL_REVIEW,
  ID_PROCESS_STATUS.VERIFIED,
  ID_PROCESS_STATUS.PASSED,
  ID_PROCESS_STATUS.SYSTEM_APPROVED,
];

export const ID_PROCESS_TEXTS: Record<string, string> = {
  51: 'Profile(s)',
  101: 'Tax ID',
  201: 'License',
  202: 'Passport',
  301: 'Biometric Verification',
  401: 'Wet Signature Verification',
  501: 'W-9/W-8BEN',
  601: 'Address Verification',
  701: 'Income Verification',
  801: 'Accreditation',
  901: 'Identity Document',
  251: 'KYC/AML Report',
};

export const ENTITY_ID_PROCESS_TEXTS: Record<string, string> = {
  5001: 'Profile(s)',
  10001: 'IRS Letter Evidencing Tax ID',
  20001: 'W-9/W-8BEN',
  30001: 'Formation Document(s)',
  40001: 'Ownership Document(s)',
  50001: 'Letter Of Good Standing',
  70001: 'Accreditation',
};

export const PROFILE_ADDRESS_TYPES = {
  RESIDENTIAL: 'residential',
  NON_RESIDENTIAL: 'non_residential',
};

export const ID_PROCESS_STATUS_TEXT: Record<string, string> = {
  PASSED: 'Passed',
  FAILED: 'Failed',
  IN_PROGRESS: 'In Progress',
  SUBMITTED: 'Submitted',
  SYSTEM_APPROVED: 'System Approved',
  PENDING_VERIFICATION: 'Not Verified',
  VERIFICATION_PENDING: 'Verification Pending',
  VERIFICATION_FAILED: 'Verification Failed',
  VERIFIED: 'Verified',
  VERIFICATION_NEEDS_MANUAL_REVIEW: 'Needs Review',
  VERIFICATION_IN_PROGRESS: 'Verification In Progress',
};

export const ID_PROCESS_STATUS_CHECK = [
  ID_PROCESS_STATUS_TEXT.IN_PROGRESS,
  ID_PROCESS_STATUS_TEXT.VERIFICATION_PENDING,
];

export const ID_PROCESS_STATUS_SHOW_TEXT: Record<string, string> = {
  IN_PROGRESS: 'In Progress',
  SUBMITTED: 'In Progress',
  NEEDS_MANUAL_REVIEW: 'Needs Review',
};

//delete after common is merged
export const ENTITY_TYPE = [
  { id: 'corporation', name: 'Corporation' },
  { id: 'llc', name: 'LLC' },
  { id: 'partnership', name: 'Partnership' },
  { id: 'individual', name: 'Individual' },
  { id: 'non_profit', name: 'NonProfit' },
  { id: 'foreign_corp', name: 'ForeignCorp' },
  { id: 'trust', name: 'Trust' },
];

export const ID_DOC_TYPES = {
  LICENSE: 'License',
  PASSPORT: 'Passport',
};

export const PROCESS_IDS = {
  PROFILE_VERIFICATION: 51,
  SSN_VERIFICATION: 101,
  DOCUMENT_VERIFICATION: 200,
  DRIVERS_LICENSE: 201,
  PASSPORT: 202,
  BIOMETRIC_VERIFICATION: 301,
  SIGN_VERIFICATION: 401,
  ADDRESS_VERIFICATION: 601,
  KYC_AML: 251,
  W8_W9_DOCUMENT: 500,
  W8_DOCUMENT: 501,
  W9_DOCUMENT: 502,
  INCOME_VERIFICATION: 701,
  ACCREDITATION: 801,
};

export const ID_DOC_VERIFICATION_IDS = [
  PROCESS_IDS.DRIVERS_LICENSE,
  PROCESS_IDS.PASSPORT,
  PROCESS_IDS.DOCUMENT_VERIFICATION,
];

export const VERIFICATION_ACTIONS = {
  DRAFT: 'DRAFT',
  SUBMIT: 'SUBMIT',
};

export const GET_PARENT_PROCESS_IDS: Record<number, number> = {
  51: 50,
  101: 100,
  201: 200,
  202: 200,
  251: 250,
  301: 300,
  401: 400,
  501: 500,
  601: 600,
  701: 700,
  801: 800,
  5001: 5000,
  10001: 10000,
  20001: 20000,
  30001: 30000,
  40001: 40000,
  50001: 50000,
  60001: 60000,
  70001: 70000,
};

export const BENEFICIAL_OWNER_DOCUMENT_VALUES = {
  KEY: 'beneficial_owner_documentation',
  LABEL: 'Beneficial Owner Documentation',
};

export const BENEFICIAL_OWNER_DOCUMENT = {
  process_id: 0,
  parent_process_id: 0,
  pre_process_verification: null,
  title: BENEFICIAL_OWNER_DOCUMENT_VALUES.LABEL,
  key: BENEFICIAL_OWNER_DOCUMENT_VALUES.KEY,
  status: '',
  access_level: 'source',
  details: {
    is_mandatory: true,
    note: '',
  },
};

export const BENEFICIAL_OWNER_ID_PROCESS_STATUS_TO_CHECK = [
  ID_PROCESS_STATUS.SYSTEM_APPROVED,
  ID_PROCESS_STATUS.PASSED,
  ID_PROCESS_STATUS.VERIFIED,
  ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
  ID_PROCESS_STATUS.VERIFICATION_NEEDS_MANUAL_REVIEW,
  ID_PROCESS_STATUS.PENDING_VERIFICATION,
];

export const GET_PROCESS_IDS: Record<string, number> = {
  PROFILE: 51,
  ENTITY_PROFILE: 5001,
};
export const ACCESS_TOKEN = `ACCESS_TOKEN`;
export const VERIFICATION_TOKEN = `VERIFICATION_TOKEN`;
export const ACCESS_BRANDING = 'ACCESS_BRANDING';
export const RESET_PASSWORD_TOKEN = `RESET_PASSWORD_TOKEN`;

export const defaultBranding = {
  colors: {
    primary: '#009F46',
    secondary: '#0075A1',
    success: '#009F46',
    warning: '#F6841B',
    error: '#E55957',
    info: '#f4f4f4',
    failure: '#F6841B',
  },
};

export const ACCOUNT_ACCEPTED = 'ACCOUNT_ACCEPTED';
export const ACCOUNT_DECLINED = 'ACCOUNT_DECLINED';

export const MERCHANT_INVITE_STATUS = {
  ACCOUNT_SIGNED_UP: 'ACCOUNT_SIGNED_UP',
  MERCHANT_APPROVED: 'MERCHANT_APPROVED',
  MERCHANT_REJECTED: 'MERCHANT_REJECTED',
  UNDER_REVIEW: 'UNDER_REVIEW',
  CREATED: 'CREATED',
  ACCOUNT_ACCEPTED: 'ACCOUNT_ACCEPTED',
  REQUESTED_DATA: 'REQUESTED_DATA',
  STARTED: 'STARTED',
  PENDING_REVIEW: 'PENDING_REVIEW',
};

export const APPLICATION_STATUS = {
  START: 'START',
  DISMISS: 'DISMISS',
  INVITED: 'INVITED',
  SUBMIT: 'SUBMIT',
};

export const TAPP_INVITE_STATUS = {
  TAPP_APPROVED: 'TAPP_APPROVED',
  CREATED: 'CREATED',
  ACCOUNT_ACCEPTED: 'ACCOUNT_ACCEPTED',
};

export const DOCUMENT_TYPE = {
  DRIVERS_LICENSE: 'driving_license',
  PASSPORT: 'passport',
};

export const ACCESS_LEVEL = {
  SAFE_DOC: 'SafeDoc',
  SOURCE: 'Source',
};

export const WEB_SOCKET_ROUTES: Record<string, string> = {
  PROFILE_LISTING: ROUTES.ID_PROCESS,
};

export const WEB_SOCKET_NAVIGATE_TO = {
  ID_PROCESS_LISTING: 'ID_PROCESS_LISTING',
  PROFILE_LISTING: 'PROFILE_LISTING',
  DASHBOARD: 'DASHBOARD',
  ALERTS: 'ALERTS',
  TAPPS: 'TAPPS',
  SETTINGS: 'SETTINGS',
  MY_IDENTITY_CARD: 'MY_IDENTITY_CARD',
};

export const WEB_SOCKET_FOCUS_ITEMS = {
  ID_PROCESS_COMPLETED: 'ID_PROCESS_COMPLETED',
  PROFILE_ADDRESS_VERIFICATION_COMPLETED: 'PROFILE_ADDRESS_VERIFICATION_COMPLETED',
  PROFILE_VERIFICATION_COMPLETED: 'PROFILE_VERIFICATION_COMPLETED',
  ALERT_RECEIVED: 'ALERT_RECEIVED',
};

export const ID_CARD_NAV_INDEX: Record<string, number> = {
  ID: 0,
  TAPP: 1,
  ALERTS: 2,
  SETTINGS: 3,
  ENTITY_SELECTION: 4,
};

export const ID_CARD_STATE = {
  ID: 'ID',
  TAPP: 'TAPP',
  ALERTS: 'ALERTS',
  SETTINGS: 'SETTINGS',
  ENTITY_SELECTION: 'ENTITY_SELECTION',
};

export const NAVIGATION_ROUTES = [
  {
    WS_KEY: WEB_SOCKET_NAVIGATE_TO.ID_PROCESS_LISTING,
    ROUTE_KEY: ROUTES.MY_IDENTITY,
  },
  {
    WS_KEY: WEB_SOCKET_NAVIGATE_TO.DASHBOARD,
    ROUTE_KEY: ROUTES.DASHBOARD,
  },
];

export const PROFILE_REQUIREMENT_TEXT = [
  {
    type: PROFILE_ADDRESS_TYPES.RESIDENTIAL,
    isSupportingDocument: true,
    text: 'residentialText',
  },
  {
    type: PROFILE_ADDRESS_TYPES.RESIDENTIAL,
    isSupportingDocument: false,
    text: 'residentialTextWithoutDoc',
  },
  {
    type: PROFILE_ADDRESS_TYPES.NON_RESIDENTIAL,
    isSupportingDocument: true,
    text: 'nonResidentialText',
  },
  {
    type: PROFILE_ADDRESS_TYPES.NON_RESIDENTIAL,
    isSupportingDocument: false,
    text: 'nonResidentialTextWithoutDoc',
  },
];

export const WEB_SOCKET_ID_CARD_MAP = [
  {
    WS_KEY: WEB_SOCKET_NAVIGATE_TO.MY_IDENTITY_CARD,
    TAB_KEY: ID_CARD_NAV_INDEX.ID,
  },
  {
    WS_KEY: WEB_SOCKET_NAVIGATE_TO.TAPPS,
    TAB_KEY: ID_CARD_NAV_INDEX.TAPP,
  },
  {
    WS_KEY: WEB_SOCKET_NAVIGATE_TO.ALERTS,
    TAB_KEY: ID_CARD_NAV_INDEX.ALERTS,
  },
  {
    WS_KEY: WEB_SOCKET_NAVIGATE_TO.SETTINGS,
    TAB_KEY: ID_CARD_NAV_INDEX.SETTINGS,
  },
];

export const ADDRESS_TYPE_LABEL = {
  RESIDENTIAL: 'Residential',
  NON_RESIDENTIAL: 'Non Residential',
};

export const EMAIL_VERIFICATION_TEXTS = {
  TOKEN_EXPIRED: 'Token has expired',
  EMAIL_VERIFIED: 'Email has been already verified',
};

export const RESET_PASSWORD_TEXTS = {
  TOKEN_EXPIRED: 'Token has expired',
  TOKEN_ALREADY_USED: 'Token has already been used.',
  TOKEN_INVALID: 'Invalid token',
};

export const ACCEPTED_VIDEO_MIME_TYPES = ['video/mp4', 'video/quicktime', 'application/x-mpegURL'];

export const ACCEPTED_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

export const SUPPORTED_IMAGE_TYPES = ['JPEG', 'PNG', 'JPG', 'PDF'];

export const SUPPORTED_VIDEO_TYPES = ['MP4', 'MOV'];

export const DOCUMENT_VERIFICATION_IMAGE_TYPES = ['JPEG', 'PNG', 'JPG'];

export const DOCUMENT_VERIFICATION_MIME_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export const PDF_MIME_TYPE = ['application/pdf'];

export const passwordValidations = [
  {
    message: 'At least 8 characters',
  },
  {
    message: 'Numeric character',
  },
  {
    message: 'Special character (@ ! # %  *)',
  },
  {
    message: 'Upper case letter',
  },
  {
    message: 'Lower case letter',
  },
];

export const PHONE_NUMBER_TYPE = {
  PRIMARY_PHONE: 'primaryPhone',
  SECONDARY_PHONE: 'secondaryPhone',
};

export const VERIFIED = 'VERIFIED';

export const VERIFICATION_STATUS = {
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  VERIFIED,
};

export const EMAIL_STATUS = {
  EMAIL_VERIFIED: 'Email Verified',
  EMAIL_NOT_VERIFIED: 'Email Not Verified',
};

export const ADDRESS_STATUS = {
  ADDRESS_VERIFIED: 'Address Verified',
  ADDRESS_NOT_VERIFIED: 'Address Not Verified',
};

export const KYC_AML_RESULTS = {
  CLEAR: 'clear',
  CONSIDER: 'consider',
};

export const FILE_SIZE = {
  IMAGE_FILE_SIZE: 10000000, //10mb
  VIDEO_FILE_SIZE: 50000000, //50mb
  DOCUMENT_FILE_SIZE: 10000000, //10mb
};

export const MEGABYTE_CONVERTER = 1000000;
export const SHORT_DESCRIPTION_LENGTH = 50;
export const MAX_FILES_TO_UPLOAD = 10;

export enum PhoneCategory {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export const BTN_TEXT = {
  START: 'Start',
  NEXT: 'Next',
  CLOSE: 'Close',
  SUBMIT: 'Submit',
  CONFIRM: 'Confirm',
};

export const FACE_SEARCH_STATUS_TEXT: Record<string, string> = {
  NEEDS_MANUAL_REVIEW: 'Face search inconclusive, you may try re-recording the video',
  PASSED: 'Face search passed',
  FAILED: 'Face search failed',
};
export const DOCUMENTS_SELECTION = {
  CATEGORY: 'category',
  FOLDER: 'folder',
  FILES: 'files',
  FILEDETAILS: 'fileDetails',
};

export const ID_PROCESS_EDITABLE_STATUS_LIST = [
  ID_PROCESS_STATUS.FAILED,
  ID_PROCESS_STATUS.PENDING_VERIFICATION,
  ID_PROCESS_STATUS.VERIFICATION_FAILED,
  ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
  ID_PROCESS_STATUS.VERIFICATION_NEEDS_MANUAL_REVIEW,
  ID_PROCESS_STATUS.PASSED,
  ID_PROCESS_STATUS.SYSTEM_APPROVED,
];

export const ENTITY_INVITE_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  DISMISSED: 'DISMISSED',
};

export const ENTITY_INVITATION_ACTION = {
  ACCEPT: 'ACCEPT',
  DISMISS: 'DISMISS',
};

export const INVITE_TYPE = {
  ENTITY: 'ENTITY',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const ENTITY_INVITE_REQUEST_TYPE = {
  SENT: 'SENT',
  RECEIVED: 'RECEIVED',
};

export const ENTITY_MEMBER = 'ENTITY_MEMBER';

export const ID_PROCESS_WITH_SAFE_DOC = [101, 201, 202];
export const FOLDER_NAME_LENGTH = 15;

export const AUTHORIZATION_STATUS = {
  SHARED: 'SHARED',
};

export const PROFILE_PICTURE_ACTIONS = {
  UPLOAD: 'upload',
  CAPTURE: 'capture',
};

export const SHOW_SHARED_SUMMARY = [51, 601, 5001];

export const SHARED_SUMMARY_CARD: Record<string, number> = {
  address_verification: 601,
  profile_verification: 51,
};

export const SHARED_SUMMARY_TEXT = ['address_verification', 'profile_verification'];

export const ENTITY_TAX_ID_VERIFICATION_LABEL = {
  PASSED: 'Verified',
  FAILED: 'Not Verified',
};

export const NOTIFICATION_TAB = {
  SYSTEM: 'SYSTEM',
  MERCHANT: 'MERCHANT',
};

export enum USER_TYPES {
  OWNER = 'owner',
  MEMBER = 'member',
}

export const REGEX = {
  NUMBER_DECIMAL: /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?|0(?:\.\d{1,2})?)$/,
};
