import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Theme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { FilePreviewType, TCard, theme, DocumentViewModal, colorPalette } from '../../../myde-react-components';
import { selectSelectedIdProcessDetails } from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { SupplementalDocVerificationType } from '../../../../types/idProcessTypes';
import { useTheme } from '../../../../providers/custom-theme-provider';
import { getIconByStatus, removeUnderscore } from '../../../../utils/utils';
import { ACCREDITATION } from '../../../../constants/constants';
import {
  getAccredationIcon,
  getAccredationStatusText,
  getAccredationMsg,
} from '../../../myde-react-components/src/utils/idProcess';
import { selectEntity, setSelectedEntity } from '../../../../redux/feature/entity/entitySlice';

interface StyleProps {
  currentThemes: Theme;
}

interface AccreditationVerificationProps {
  isEntity: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  iconContainer: {
    backgroundColor: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  accreditationIconColor: {
    color: colorPalette.typoText.lighten3,
  },
});

const AccreditationVerification = ({ isEntity }: AccreditationVerificationProps) => {
  //constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };

  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');

  //state
  const [accreditationData, setAccreditationData] = useState({} as SupplementalDocVerificationType);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState({} as FilePreviewType);
  const { selectedEntity } = useSelector(selectEntity);
  const dispatch = useDispatch();
  // use selectors
  const { selectedIdProcessDetails, selectedEntityIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);

  //use effects
  useEffect(() => {
    let idProcessData = {} as SupplementalDocVerificationType;
    if (isEntity && Object.keys(selectedEntityIdProcessDetails?.data)?.length > 0) {
      idProcessData = selectedEntityIdProcessDetails;
    } else if (!isEntity && Object.keys(selectedIdProcessDetails?.data)?.length > 0) {
      idProcessData = selectedIdProcessDetails;
    }
    setAccreditationData(idProcessData);
  }, [selectedIdProcessDetails, selectedEntityIdProcessDetails, isEntity]);

  useEffect(() => {
    if (accreditationData?.data?.accreditation_status !== selectedEntity?.details?.accreditation_status) {
      const updatedSelectedEntity = { ...selectedEntity };
      updatedSelectedEntity.details = {
        ...selectedEntity?.details,
        accreditation_status: accreditationData?.data?.accreditation_status,
      };
      dispatch(setSelectedEntity(updatedSelectedEntity));
    }
  }, [accreditationData]);

  useEffect(() => {
    if (accreditationData?.data?.signed_document) {
      const data = {
        name: accreditationData?.data?.signed_document?.doc_name || '',
        type: accreditationData?.data?.signed_document?.mime_type || '',
        preview: accreditationData?.data?.signed_document?.signed_url || '',
        size: accreditationData?.data?.signed_document?.size_in_kb,
      };
      setFiles(data);
    }
  }, [accreditationData]);

  //methods
  const handleEyeIconClick = () => {
    if (accreditationData?.data?.signed_document?.signed_url) {
      setOpen(true);
    } else {
      toast.error(t('invalidURLText', { ns: 'notificationMessages' }));
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TCard>
        <Box className="flex-column-center">
          <Box
            sx={{ mb: 3, mt: theme.spacing(8) }}
            className={clsx('text-center accreditation-icon-container-size', classes.iconContainer)}
          >
            <Box
              sx={{ my: theme.spacing(3) }}
              className={clsx(
                `icon-size-60 ${getAccredationIcon(accreditationData?.data?.accreditation_status)}`,
                classes.accreditationIconColor,
              )}
            />
          </Box>
          <Box sx={{ px: 3 }}>
            <Box className="textColor-200 text-h2 font-weight-semibold text-center">
              {removeUnderscore(accreditationData?.data?.accreditation_status) ||
                t('instructionHeading', { ns: 'accreditation' })}
            </Box>
            {isEntity ? (
              <Box className="textColor-200 text-medium  text-center" sx={{ mt: 1 }}>
                {t(getAccredationMsg(accreditationData?.data?.accreditation_status), { ns: 'accreditation' })}
              </Box>
            ) : (
              <Box sx={{ pt: theme.spacing(3) }} className="text-medium textColor-200 text-center">
                {t(`${getAccredationStatusText(accreditationData?.data?.accreditation_status)}`, {
                  ns: 'accreditation',
                })}
              </Box>
            )}
          </Box>
          <Box sx={{ mt: theme.spacing(5) }} className="w-100">
            <Divider />
          </Box>
          <Box sx={{ px: 1, py: 3 }} className="textColor-200 text-medium font-weight-regular w-100">
            <Grid container>
              <Grid item xs={10}>
                <Box className="flex-basic-start">
                  <Box
                    sx={{ ml: theme.spacing(5), mr: theme.spacing(5) }}
                    className={clsx(
                      `${getIconByStatus(accreditationData?.status, ACCREDITATION)} icon-size-32`,
                      classes.icon,
                    )}
                  />
                  <Box>
                    <Box className="textColor-300 text-small">
                      {t('accreditationFormType', { ns: 'accreditation' })}
                    </Box>
                    <Box className="textColor-200 text-medium font-weight-semibold">
                      {t('accreditationForm', { ns: 'accreditation' })}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={2} className="flex-column-center">
                {!accreditationData?.data?.signed_document ? (
                  <Box className="progress-spinner sm-spinner"></Box>
                ) : (
                  <Box
                    className="icon-eye icon-size-20 textColor-200 cursorPointer"
                    onClick={() => handleEyeIconClick()}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </TCard>
      <DocumentViewModal file={files} open={open} closeModal={handleDialogClose} />
    </>
  );
};

export default AccreditationVerification;
