import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { session } from '../../../api/login';
import { UserSession, Branding } from '../../../types/userTypes';
import { LocationType, RouteType } from '../../../types/commonTypes';

const initialState = {
  session: <UserSession>{},
  branding: <Branding>{},
  loading: false,
  error: false,
  errorObj: <any>{},
  routeHistory: <any>[],
  showCard: false,
  refreshPage: false,
  cameraModeActive: false,
  parentRoute: <RouteType>{},
  childRoute: <RouteType>{},
  selectedActiveCardNav: 0,
  isNewSession: false,
  previousStep: <number | string>0,
  currentStep: 0,
  isEditAllowed: false,
  isDeleteAllowed: false,
  showDeleteConfirmation: false,
};

// Selectors
export const selectSession = ({ common }: RootState) => ({
  session: common.session,
  routeHistory: common.routeHistory,
  showCard: common.showCard,
  refreshPage: common.refreshPage,
  cameraModeActive: common.cameraModeActive,
  parentRoute: common.parentRoute,
  selectedActiveCardNav: common.selectedActiveCardNav,
  isNewSession: common.isNewSession,
  previousStep: common.previousStep,
  currentStep: common.currentStep,
  isEditAllowed: common.isEditAllowed,
  childRoute: common.childRoute,
  isDeleteAllowed: common.isDeleteAllowed,
  showDeleteConfirmation: common.showDeleteConfirmation,
});

// Actions
export const getSessionDetails = createAsyncThunk('common/getSessionDetails', async () => {
  return await session();
});
export const updateRouteHistory = createAsyncThunk('common/updateRouteHistory', async (locationData: LocationType) => {
  return locationData;
});

// Reducers
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearResults() {},
    setCardState: (state, action) => {
      state.showCard = action.payload;
    },
    setRefreshPage: (state, action) => {
      state.refreshPage = action.payload;
    },
    setCameraMode: (state, action) => {
      state.cameraModeActive = action.payload;
    },
    setParentRoute: (state, action) => {
      state.parentRoute = action.payload;
    },
    setChildRoute: (state, action) => {
      state.childRoute = action.payload;
    },
    setActiveCardNav: (state, action) => {
      state.selectedActiveCardNav = action.payload;
    },
    setPreviousStep: (state, action) => {
      state.previousStep = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setIsEditAllowedFlag: (state, action) => {
      state.isEditAllowed = action.payload;
    },
    setIsDeleteAllowed: (state, action) => {
      state.isDeleteAllowed = action.payload;
    },
    setShowDeleteConfirmation: (state, action) => {
      state.showDeleteConfirmation = action.payload;
    },
    setIsNewSessionFlag: (state, action) => {
      state.isNewSession = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSessionDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.isNewSession = false;
      })
      .addCase(getSessionDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.isNewSession = true;
          state.session = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSessionDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.isNewSession = false;
      })
      .addCase(updateRouteHistory.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (!state.routeHistory) {
          state.routeHistory = [];
        }
        state.routeHistory.unshift(action.payload);
      });
  },
});

export const {
  clearResults,
  setCardState,
  setRefreshPage,
  setCameraMode,
  setParentRoute,
  setChildRoute,
  setActiveCardNav,
  setPreviousStep,
  setCurrentStep,
  setIsEditAllowedFlag,
  setIsNewSessionFlag,
  setIsDeleteAllowed,
  setShowDeleteConfirmation,
} = commonSlice.actions;

export default commonSlice.reducer;
