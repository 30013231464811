import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import {
  KycAmlRecordDetails,
  KycAmlRecordType,
  KycHistoryRecordType,
  KycRerunDetailsType,
  RerunRecordType,
} from '../../../../types/idProcessTypes';
import { colorPalette } from '../../../myde-react-components';
import { ClickHandlerType } from '../../../../types/commonTypes';
import { useTheme } from '../../../../providers/custom-theme-provider';
import {
  addComment,
  deleteSelectedComment,
  getUpdatedEvent,
  kycAmlIdProcessDetails,
  setcommentUpdatedFlag,
  updateExistingComment,
  getKycHistoryById,
} from '../../../../redux/feature/idProcess/kycamlSlice';
import KycAmlRecordsComponent from '../../../myde-react-components/src/components/KycAmlRecords/KycAmlRecordsComponent';
import KycAmlRecordEventComponent from '../../../myde-react-components/src/components/KycAmlRecords/KycAmlRecordEventComponent';
interface StyleProps {
  currentThemes: Theme;
}
interface KycAmlReportsProps {
  kycRecord?: KycAmlRecordType[];
  onBack: ClickHandlerType;
  fetchData: ClickHandlerType;
  secondaryRecords?: KycAmlRecordType[];
  kycHistory?: KycHistoryRecordType;
  historyDate?: string;
  historyRunId?: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  pageHeader: {
    borderBottom: `1px solid ${colorPalette.border.base}`,
    position: 'relative',
  },
});

const KycAmlRecords = ({
  kycRecord,
  onBack,
  fetchData,
  secondaryRecords,
  kycHistory,
  historyDate,
  historyRunId,
}: KycAmlReportsProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const { t } = useTranslation(['common', 'kycAmlReport']);

  const classes = useStyles(styleProps);
  const dispatch = useDispatch();

  const { commentUpdated, updatedEvent, kycData, kycRunId } = useSelector(kycAmlIdProcessDetails);

  // State Values
  const [selectedRecord, setselectedRecord] = useState({} as KycAmlRecordType | RerunRecordType);
  const [selectedEvent, setselectedEvent] = useState({} as KycAmlRecordDetails | KycRerunDetailsType);
  const [showSingleData, setSingleDataFlag] = useState(false);
  const [showComments, setCommentSectionFlag] = useState(false);
  const [showAddCommentSection, setAddCommentSectionFlag] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(false);

  // Use Effects
  useEffect(() => {
    if (commentUpdated && selectedRecord && selectedEvent) {
      getSelectedEvent();
      dispatch(setcommentUpdatedFlag(false));
      const data = {
        runId: kycRunId,
      };
      dispatch(getKycHistoryById(data));
      setCommentSectionFlag(true);
    }
  }, [commentUpdated]);

  useEffect(() => {
    if (showSingleData && selectedRecord?.record_id && selectedEvent?.event_id) {
      getSelectedEvent();
    }
  }, [showSingleData]);

  useEffect(() => {
    if (updatedEvent && Object.keys(updatedEvent)?.length > 0) {
      setselectedEvent(updatedEvent);
    }
  }, [updatedEvent]);

  // Methods
  const selectRecord = (
    record: KycAmlRecordType | RerunRecordType,
    event: KycAmlRecordDetails | KycRerunDetailsType,
  ) => {
    setselectedRecord(record);
    setselectedEvent(event);
    setSingleDataFlag(true);
  };
  const getSelectedEvent = () => {
    const data = {
      recordId: selectedRecord.record_id,
      eventId: selectedEvent.event_id,
      runId: historyRunId ? historyRunId : kycData?.kyc_run_id,
    };
    dispatch(getUpdatedEvent(data));
  };
  const goPrevious = () => {
    setselectedRecord({} as KycAmlRecordType);
    setselectedEvent({} as KycAmlRecordDetails);
    setSingleDataFlag(false);
    fetchData();
  };
  const toggleCommentSection = () => {
    setCommentSectionFlag(!showComments);
  };
  const closeNewCommentSection = () => {
    setAddCommentSectionFlag(false);
  };
  const addNewComment = (comment: string) => {
    const data = {
      eventId: selectedEvent.event_id,
      comment: comment,
    };
    dispatch(addComment(data));
    setAddCommentSectionFlag(false);
  };
  const updateComment = (selectedComment: any) => {
    const data = {
      eventId: selectedEvent.event_id,
      commentId: selectedComment.id,
      comment: selectedComment.comment,
    };
    dispatch(updateExistingComment(data));
  };
  const deleteComment = (selectedComment: any) => {
    const data = {
      eventId: selectedEvent.event_id,
      commentId: selectedComment.id,
    };
    dispatch(deleteSelectedComment(data));
  };
  const kyclHeaderSection = (title: string) => {
    return (
      <Box sx={{ pb: 3 }} className={classes.pageHeader}>
        <Box className="p-absolute backArrow cursorPointer" onClick={goPrevious}>
          <span className="icon-arrow-lefts textColor-300"></span>
        </Box>
        <Box sx={{ pl: 4 }} className="text-large font-weight-semibold textColor-300 flex-basic-start">
          {title}
        </Box>
      </Box>
    );
  };
  const selectNewRecord = (record: RerunRecordType, event: KycRerunDetailsType) => {
    setIsNewRecord(true);
    selectRecord(record, event);
  };

  const selectOldRecord = (record: RerunRecordType, event: KycAmlRecordDetails) => {
    setIsNewRecord(false);
    selectRecord(record, event);
  };

  // HTML
  return (
    <>
      {kycRecord &&
        (!showSingleData ? (
          <KycAmlRecordsComponent
            records={kycRecord}
            revertBack={onBack}
            recordSelect={selectRecord}
            headerText={t('recordFound', { ns: 'kycAmlReport' })}
          />
        ) : (
          <KycAmlRecordEventComponent
            headerSection={kyclHeaderSection}
            eventSelected={selectedEvent}
            commentSectionToggle={toggleCommentSection}
            commentVisible={showComments}
            commentSectionVisible={showAddCommentSection}
            setCommentSectionVisible={setAddCommentSectionFlag}
            newCommentAdd={addNewComment}
            newCommentSectionClose={closeNewCommentSection}
            commentUpdate={updateComment}
            commentDelete={deleteComment}
            headerText={t('singleRecordFound', { ns: 'kycAmlReport' })}
          />
        ))}

      {secondaryRecords &&
        (!showSingleData ? (
          <KycAmlRecordsComponent
            headerText={t('secondaryChecks', { ns: 'kycAmlReport' })}
            records={secondaryRecords}
            revertBack={onBack}
            recordSelect={selectRecord}
          />
        ) : (
          <KycAmlRecordEventComponent
            headerSection={kyclHeaderSection}
            eventSelected={selectedEvent}
            commentSectionToggle={toggleCommentSection}
            commentVisible={showComments}
            commentSectionVisible={showAddCommentSection}
            setCommentSectionVisible={setAddCommentSectionFlag}
            newCommentAdd={addNewComment}
            newCommentSectionClose={closeNewCommentSection}
            commentUpdate={updateComment}
            commentDelete={deleteComment}
            headerText={t('secondaryRecord', { ns: 'kycAmlReport' })}
          />
        ))}

      {kycHistory &&
        (!showSingleData ? (
          <KycAmlRecordsComponent
            historyRecords={kycHistory}
            revertBack={onBack}
            recordSelect={selectRecord}
            historyDate={historyDate}
            selectNewRecord={selectNewRecord}
            selectOldRecord={selectOldRecord}
          />
        ) : (
          <KycAmlRecordEventComponent
            isNewRecord={isNewRecord}
            headerSection={kyclHeaderSection}
            eventSelected={selectedEvent}
            commentSectionToggle={toggleCommentSection}
            commentVisible={showComments}
            commentSectionVisible={showAddCommentSection}
            setCommentSectionVisible={setAddCommentSectionFlag}
            newCommentAdd={addNewComment}
            newCommentSectionClose={closeNewCommentSection}
            commentUpdate={updateComment}
            commentDelete={deleteComment}
            hideComment={true}
            headerText={isNewRecord ? t('newRecord') : t('erasedRecord')}
          />
        ))}
    </>
  );
};

export default KycAmlRecords;
