import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';

import { toast } from 'react-toastify';
import { changePassword, resetExpiredPassword, validatePassword } from '../../../api/password';
import { ChangePasswordType } from '../../../types/commonTypes';
import { resetPassword } from '../../../api/forgot-password';

const initialState = {
  loading: false,
  error: false,
  errorObj: <any>{},
  isPasswordUpdated: false,
  newPasswordValidationMsg: '',
};

export const selectUserPasswordData = ({ userPasswordData }: RootState) => ({
  loading: userPasswordData.loading,
  isPasswordUpdated: userPasswordData.isPasswordUpdated,
  newPasswordValidationMsg: userPasswordData.newPasswordValidationMsg,
});

// Actions
export const changePasswordHandler = createAsyncThunk(
  'userPasswordData/changePassword',
  async (formData?: ChangePasswordType) => {
    return await changePassword(formData || {} as ChangePasswordType);
  },
);
export const resetExpiredUserPassword = createAsyncThunk(
  'userPasswordData/resetExpiredUserPassword',
  async (formData: ChangePasswordType) => {
    return await resetExpiredPassword(formData);
  },
);
export const validateNewPassword = createAsyncThunk(
  'userPasswordData/validateNewPassword',
  async (formData: ChangePasswordType) => {
    return await validatePassword(formData);
  },
);
export const resetPasswordHandler = createAsyncThunk('user/resetPassword', async (formData: { password: string }) => {
  return await resetPassword(formData);
});

// Reducers
export const userPasswordSlice = createSlice({
  name: 'userPasswordData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordHandler.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(changePasswordHandler.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.isPasswordUpdated = true;
          toast.success(action.payload.detail);
        } else {
          state.error = true;
        }
      })
      .addCase(changePasswordHandler.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        //Implementing this wayaround until idial way is discovered
        if (action.error.message?.includes('400')) {
          toast.error('Current password is incorrect');
        }
      })
      // Reset Expired User Password
      .addCase(resetExpiredUserPassword.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(resetExpiredUserPassword.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.isPasswordUpdated = true;
          toast.success(action.payload.detail);
        } else {
          state.error = true;
        }
      })
      .addCase(resetExpiredUserPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        //Implementing this wayaround until idial way is discovered
        if (action.error.message?.includes('400')) {
          toast.error('Current password is incorrect');
        }
      })
      // Validate New Password
      .addCase(validateNewPassword.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(validateNewPassword.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
        state.newPasswordValidationMsg = '';
      })
      .addCase(validateNewPassword.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.newPasswordValidationMsg = 'Either the current password is incorrect, or the new password matches the last 10 password(s).';
      })
      // Reset Password
      .addCase(resetPasswordHandler.pending, (state) => {
        state.loading = true;
        state.error = true;
        state.isPasswordUpdated = false;
      })
      .addCase(resetPasswordHandler.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
        state.isPasswordUpdated = true;
        state.newPasswordValidationMsg = '';
      })
      .addCase(resetPasswordHandler.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.isPasswordUpdated = false;
        state.newPasswordValidationMsg = 'New password cannot be same as the last 10 password(s).';
      })
  },
});

export const {} = userPasswordSlice.actions;

export default userPasswordSlice.reducer;

