import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { AddedProfile, ProfileDetails } from '../../../types/profileTypes';
import { VERIFICATION_STATUS } from '../../../constants/constants';
import { addProfileForEntity, entityProfileDetailsById, entityProfileList } from '../../../api/entityIdprocesses';

type ProfileState = {
  entityLoading: boolean;
  error: boolean;
  errorObj: any;
  entityProfileList: ProfileDetails[];
  entityEmailNotVerified: boolean;
  entityAddedProfile: AddedProfile;
  entityProfileHolder: ProfileDetails;
  isPasswordUpdated: boolean;
};

const initialState: ProfileState = {
  entityLoading: false,
  error: false,
  errorObj: <any>{},
  entityProfileList: [],
  entityEmailNotVerified: false,
  entityAddedProfile: <AddedProfile>{},
  entityProfileHolder: <ProfileDetails>{},
  isPasswordUpdated: false,
};

export const selectEntityProfile = ({ entityProfile }: RootState) => ({
  entityLoading: entityProfile.entityLoading,
  entityProfileList: entityProfile.entityProfileList,
  entityEmailNotVerified: entityProfile.entityEmailNotVerified,
  entityAddedProfile: entityProfile.entityAddedProfile,
  entityProfileHolder: entityProfile.entityProfileHolder,
  isPasswordUpdated: entityProfile.isPasswordUpdated,
});

export const getEntityProfileDetailsList = createAsyncThunk(
  'entityProfile/getEntityProfileDetailsList',
  async (data: { entityUId: number; params: object }) => {
    return await entityProfileList(data.entityUId, data.params);
  },
);

export const getEntityProfileDetailsById = createAsyncThunk(
  'entityProfile/getEntityProfileDetailsById',
  async (data: { id: string; entityId: number }) => {
    return await entityProfileDetailsById(data.id, data.entityId);
  },
);

export const addEntityProfileDetails = createAsyncThunk(
  'entityProfile/addEntityProfileDetails',
  async (data: { entityUid: number; formData: object }) => {
    return await addProfileForEntity(data.entityUid, data.formData);
  },
);

export const entityProfileSlice = createSlice({
  name: 'entityProfile',
  initialState,
  reducers: {
    resetEntityAddedProfile: (state) => {
      state.entityAddedProfile = <AddedProfile>{};
    },
    setEmailNotVerified: (state, action) => {
      state.entityEmailNotVerified = action.payload;
    },
    setIsPasswordUpdated: (state, action) => {
      state.isPasswordUpdated = action.payload;
    },
    setEntityProfileList: (state, action) => {
      state.entityProfileList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntityProfileDetailsList.pending, (state) => {
        state.entityLoading = true;
        state.error = false;
      })
      .addCase(getEntityProfileDetailsList.fulfilled, (state, action: any) => {
        state.entityLoading = false;
        state.error = false;
        if (action.payload) {
          state.entityProfileList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityProfileDetailsList.rejected, (state) => {
        state.entityLoading = false;
        state.error = true;
      })
      .addCase(addEntityProfileDetails.pending, (state) => {
        state.entityLoading = true;
        state.error = false;
      })
      .addCase(addEntityProfileDetails.fulfilled, (state, action: any) => {
        state.entityLoading = false;
        state.error = false;
        if (action.payload) {
          state.entityAddedProfile = action.payload;
          if (action.payload?.profile_email?.verification_status !== VERIFICATION_STATUS.VERIFIED) {
            state.entityEmailNotVerified = true;
          }
        } else {
          state.error = true;
        }
      })
      .addCase(addEntityProfileDetails.rejected, (state) => {
        state.entityLoading = false;
        state.error = true;
      })
      .addCase(getEntityProfileDetailsById.pending, (state) => {
        state.entityLoading = true;
        state.error = false;
      })
      .addCase(getEntityProfileDetailsById.fulfilled, (state, action: any) => {
        state.entityLoading = false;
        state.error = false;
        if (action.payload) {
          state.entityProfileHolder = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityProfileDetailsById.rejected, (state) => {
        state.entityLoading = false;
        state.error = true;
      });
  },
});

export const { setEmailNotVerified, resetEntityAddedProfile, setEntityProfileList } = entityProfileSlice.actions;

export default entityProfileSlice.reducer;
