import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import clsx from 'clsx';

import useQuery from '../../hooks/useQuery';
import {
  FORM_STEPS,
  ID_PROCESS_STATUS_COMPLETION_STATE,
  NO_STEP_ID_PROCESSES,
} from '../../components/myde-react-components/src/constants/constants';
import IdProcessInstructions from '../../components/id-process/flows/IdProcessInstructions';
import { TLoader, TPaper } from '../../components/myde-react-components';
import IdProcessConfirmation from '../../components/id-process/flows/IdProcessConfirmation';
import IdProcessVerification from '../../components/id-process/flows/IdProcessVerification';
import {
  getEntityProcessDetailsById,
  getIdProcessChildren,
  getProcessDetailsById,
  selectSelectedIdProcessDetails,
  setProcessId,
} from '../../redux/feature/idProcess/mainIdProcessSlice';
import { getIdProcessTranslationName } from '../../components/myde-react-components/src/utils/idProcess';
import { selectSession, setCurrentStep, setParentRoute, setRefreshPage } from '../../redux/feature/common/commonSlice';
import { PROCESS_IDS } from '../../constants/constants';
import { selectEntity } from '../../redux/feature/entity/entitySlice';
import IdProcessDetail from '../../components/id-process/flows/IdProcessDetail';
import * as ROUTES from '../../constants/routes';
import { RouteType } from '../../types/commonTypes';
import { selectNotification, setSpecificNotification } from '../../redux/feature/notification/notificationSlice';
import { NotificationsResultType } from '../../components/myde-react-components/src/components/Notifications/notificationsType';

const IdProcessIndex = () => {
  // Constants
  const dispatch = useDispatch();
  const queryProcessId = useQuery().get('id') || '';
  const hasEntityValue = useQuery().get('isEntity') === 'true';
  const isEntity = hasEntityValue || false;

  // Redux Values
  const {
    loading,
    processId,
    selectedIdProcessDetails,
    selectedEntityIdProcessDetails,
    selectedIdProcessChildrenList,
  } = useSelector(selectSelectedIdProcessDetails);
  const { currentStep, refreshPage } = useSelector(selectSession);
  const { selectedEntity } = useSelector(selectEntity);
  const { specificNotification } = useSelector(selectNotification);

  // State Values
  const [currentIdProcessStatus, setIdProcessStatus] = useState(FORM_STEPS.START);
  const [idProcessTransaltion, setIdProcessTranslation] = useState('common');
  // Use Effects
  useEffect(() => {
    if (queryProcessId) {
      dispatch(setProcessId(parseInt(queryProcessId)));
      if (isEntity) {
        dispatch(
          getEntityProcessDetailsById({
            processId: parseInt(queryProcessId),
            entityUid: selectedEntity?.trellis_uid,
          }),
        );
      } else {
        getIdProcessDetailsById();
      }
    }
  }, []);
  useEffect(() => {
    if (specificNotification?.id) {
      const route: RouteType = {
        path: isEntity ? ROUTES.ENTITY_MY_IDENTITY : ROUTES.MY_IDENTITY,
        isChild: false,
        stateName: '',
        query: null,
        stateObj: null,
      };
      dispatch(setParentRoute(route));
      dispatch(setSpecificNotification({} as NotificationsResultType));
    }
  }, [isEntity, specificNotification]);
  useEffect(() => {
    if (currentStep > -1) {
      setIdProcessStatus(currentStep);
    }
  }, [currentStep]);
  useEffect(() => {
    if (processId > 0) {
      setIdProcessTranslation(getIdProcessTranslationName(processId));
    }
  }, [processId]);
  useEffect(() => {
    if (isEntity) {
      if (selectedEntityIdProcessDetails?.status) {
        const currentStatus = ID_PROCESS_STATUS_COMPLETION_STATE.find((state) =>
          state.statuses.includes(selectedEntityIdProcessDetails?.status),
        );
        setIdProcessStatus(currentStatus?.percent || FORM_STEPS.START);
        dispatch(setCurrentStep(currentStatus?.percent || FORM_STEPS.START));
      }
    } else {
      if (NO_STEP_ID_PROCESSES.includes(processId)) {
        setIdProcessStatus(FORM_STEPS.DONE);
        dispatch(setCurrentStep(FORM_STEPS.DONE));
      } else {
        if (selectedIdProcessDetails?.status && processId.toString() === queryProcessId) {
          const currentStatus = ID_PROCESS_STATUS_COMPLETION_STATE.find((state) =>
            state.statuses.includes(selectedIdProcessDetails?.status),
          );
          setIdProcessStatus(currentStatus?.percent || FORM_STEPS.START);
          dispatch(setCurrentStep(currentStatus?.percent || FORM_STEPS.START));
        }
      }
    }
  }, [selectedIdProcessDetails, selectedEntityIdProcessDetails]);

  useEffect(() => {
    if (selectedIdProcessChildrenList?.length > 0) {
      const percents: number[] = [];
      selectedIdProcessChildrenList.forEach((item: any) => {
        const status = ID_PROCESS_STATUS_COMPLETION_STATE.find((state) => state.statuses.includes(item?.status));
        const percent = status?.percent ? FORM_STEPS.DETAILS : FORM_STEPS.START;
        percents.push(percent);
      });

      const currentPercent = percents.reduce((preStatus, currentStatus) =>
        preStatus > currentStatus ? preStatus : currentStatus,
      );
      setIdProcessStatus(currentPercent || FORM_STEPS.START);
      dispatch(setCurrentStep(currentPercent || FORM_STEPS.START));
    }
  }, [selectedIdProcessChildrenList]);

  useEffect(() => {
    if (refreshPage && FORM_STEPS.DONE === currentStep) {
      isEntity
        ? dispatch(
            getEntityProcessDetailsById({
              processId: parseInt(queryProcessId),
              entityUid: selectedEntity?.trellis_uid,
            }),
          )
        : getIdProcessDetailsById();
      dispatch(setRefreshPage(false));
    }
  }, [refreshPage, currentStep]);

  //Methods
  const getIdProcessDetailsById = () => {
    dispatch(getProcessDetailsById({ processId: queryProcessId }));
    dispatch(getIdProcessChildren({ processId: queryProcessId }));
  };

  // HTML
  return (
    <>
      <TLoader loading={loading} />
      {parseInt(queryProcessId) !== PROCESS_IDS.KYC_AML &&
        currentIdProcessStatus === ID_PROCESS_STATUS_COMPLETION_STATE[0].percent && (
          <TPaper
            sx={{ mb: 10 }}
            className={clsx({
              ['flex-basic-center']:
                processId === PROCESS_IDS.W8_DOCUMENT &&
                currentIdProcessStatus !== ID_PROCESS_STATUS_COMPLETION_STATE[2].percent,
            })}
          >
            <IdProcessInstructions lang={idProcessTransaltion} />
          </TPaper>
        )}
      {currentIdProcessStatus > ID_PROCESS_STATUS_COMPLETION_STATE[0].percent &&
        currentIdProcessStatus < ID_PROCESS_STATUS_COMPLETION_STATE[3].percent && (
          <IdProcessConfirmation lang={idProcessTransaltion} isEntity={isEntity} />
        )}
      {currentIdProcessStatus >= ID_PROCESS_STATUS_COMPLETION_STATE[2].percent && (
        <Box
          sx={{ mb: 10 }}
          className={clsx({
            ['flex-basic-center']:
              processId === PROCESS_IDS.W8_DOCUMENT &&
              currentIdProcessStatus !== ID_PROCESS_STATUS_COMPLETION_STATE[2].percent,
          })}
        >
          <IdProcessVerification isEntity={isEntity} />
        </Box>
      )}
      {currentIdProcessStatus === ID_PROCESS_STATUS_COMPLETION_STATE[3].percent && (
        <IdProcessDetail isEntity={isEntity} />
      )}
    </>
  );
};

export default IdProcessIndex;
