import React, { useEffect, useState } from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { TCard } from '../../../components/myde-react-components';
import { BUILD_NUMBER, SUPPORT_EMAILS } from '../../../components/myde-react-components/src/constants/constants';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/feature/user/userSlice';
import { makeStyles } from '@mui/styles';
import { useTheme } from '../../../providers/custom-theme-provider';
import clsx from 'clsx';
import { hexToRgbA } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';
interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  brandingImg: {
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
  },
});

const About = () => {
  //const
  const { t } = useTranslation(['common']);
  const { userData, showEntityDetails } = useSelector(selectUser);
  const { selectedEntity } = useSelector(selectEntity);

  // state variables
  const [brandingUrl, setBrandingUrl] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);

  // use effects
  useEffect(() => {
    if (showEntityDetails) {
      setBrandingUrl(selectedEntity?.merchant?.branding?.logo_url);
      setMerchantName(selectedEntity?.merchant?.branding?.show_name);
    } else {
      setBrandingUrl(userData?.merchant?.branding?.logo_url);
      setMerchantName(userData?.merchant?.name);
    }
  }, [userData, showEntityDetails]);

  return (
    <>
      <Box sx={{ mt: 8, mb: 2 }} className="text-h2 textColor-200 text-center font-weight-semibold">
        {t('about')}
      </Box>
      <Divider sx={{ mt: 1, mb: 2 }} />

      <TCard sx={{ pt: 1 }} className="card-height-100 userMessageList">
        <Box sx={{ px: 3 }} className="flex-basic-space-between flex-direction-column height-100">
          <Box sx={{ mt: 10 }}>
            <Box className={clsx('brandingLogo flex-column-center', classes.brandingImg)}>
              <img src={brandingUrl} loading="lazy" alt="Branding Logo" className="branding-logo" />
              <Box sx={{ mt: 2 }} className="text-large textColor-200 font-weight-semibold">
                {merchantName}
              </Box>
            </Box>
          </Box>
          <Box sx={{ mb: 4 }}>
            <Box sx={{ mb: 4 }} className="text-medium font-weight-semibold textColor-200 text-center">
              <a className="anchorTagStyle" href={`mailto: ${SUPPORT_EMAILS.CONTACT_US}`}>
                {t('contactUs')}
              </a>
            </Box>

            <Box className="text-medium textColor-300">
              {t('poweredByText')} {BUILD_NUMBER}
            </Box>
          </Box>
        </Box>
      </TCard>
    </>
  );
};

export default About;
