import React from 'react';
import { Box, Grid, Icon, Theme } from '@mui/material';
import clsx from 'clsx';
import { CommentComponent, CommentList, TButton, TCard, theme } from '../../..';
import { makeStyles } from '@mui/styles';
import { formatDate } from '../../utils/commonMethods';
import { colorPalette } from '../../constants/colors';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { KycAmlRecordDetails } from '../../types/KycTypes';
import { MAX_CHAR_LIMIT } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import { PORTAL } from '../../constants/portal';

interface StyleProps {
  currentThemes: Theme;
}

interface EventViewProps {
  headerSection: (title: string) => void;
  eventSelected: KycAmlRecordDetails;
  commentSectionToggle: () => void;
  commentVisible: boolean;
  commentSectionVisible: boolean;
  setCommentSectionVisible?: (condition: boolean) => void;
  newCommentAdd?: (comment: string) => void;
  newCommentSectionClose?: () => void;
  commentUpdate?: (selectedComment: any) => void;
  commentDelete?: (selectedComment: any) => void;
  isNewRecord?: boolean;
  headerText: string;
  portalType?: string;
  hideComment?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  cardWrapper: {
    marginBottom: '100px !important',
    borderRadius: 10,
  },
  reportSection: {
    borderBottom: `1px solid ${colorPalette.border.base}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  noBorder: {
    borderBottom: 'none',
  },
  aliasWrapper: {
    '& .MuiChip-root': {
      margin: 5,
      border: '1px solid',
      borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
    },
    '& .MuiChip-label': {
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
    },
  },
});

const KycAmlRecordEventComponent = ({
  headerSection,
  eventSelected,
  commentSectionToggle,
  commentVisible,
  commentSectionVisible,
  setCommentSectionVisible,
  newCommentAdd,
  newCommentSectionClose,
  commentUpdate,
  commentDelete,
  isNewRecord,
  headerText,
  portalType = PORTAL.MYDE,
  hideComment,
}: EventViewProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');

  const renderContent = () => {
    return (
      <>
        {isNewRecord ? headerSection(headerText) : headerSection(headerText)}

        <Box
          sx={{ pt: theme.spacing(5), pb: 2 }}
          className={clsx('textColor-200 text-small font-weight-regular', classes.reportSection, classes.noBorder)}
        >
          <Box>
            {t('categoryText')} {eventSelected?.event_category}
          </Box>
          {eventSelected?.event_sub_category && (
            <Box sx={{ mt: 1 }}>
              {t('subCategoryText')} {eventSelected?.event_sub_category}
            </Box>
          )}
          <Box sx={{ mt: 1 }}>
            {eventSelected?.event_name}
            <div>
              {t('felonyRecordText')} {formatDate(eventSelected?.event_date) || 'NA'}
            </div>
          </Box>
          {eventSelected?.source_name && (
            <Box sx={{ mt: 1 }}>
              {t('sourceText')} {eventSelected?.source_name}
            </Box>
          )}
          <Box sx={{ mt: 1 }}>{eventSelected?.event_description}</Box>
        </Box>
        <Box>
          <Grid sx={{ mb: 1 }} container direction="row" justifyContent="space-between" alignItems="center">
            <Grid
              item
              className={'flex-basic-center font-weight-semibold text-medium textColor-200 cursorPointer'}
              onClick={commentSectionToggle}
            >
              {eventSelected?.comments?.length > 0 && (
                <Box className="flex-basic-center">
                  {!commentVisible && <Icon>add_icon</Icon>}
                  {commentVisible && <Icon>remove_icon</Icon>}
                </Box>
              )}
              ({eventSelected?.comments?.length}) {t('commentsText')}
            </Grid>
            {!commentSectionVisible && portalType === PORTAL.MYDE && (
              <Grid item>
                {!hideComment && (
                  <TButton
                    btnText="Add Comment"
                    onClick={() => {
                      setCommentSectionVisible && setCommentSectionVisible(true);
                    }}
                  />
                )}
              </Grid>
            )}
          </Grid>
          {commentSectionVisible && (
            <Box sx={{ my: 3 }}>
              <Box sx={{ pb: 2 }} className={'font-weight-semibold text-medium textColor-200'}>
                {t('newCommentText')}
              </Box>
              <CommentComponent
                showCancel={true}
                onSubmit={newCommentAdd}
                handleCancel={newCommentSectionClose}
                maxLength={MAX_CHAR_LIMIT.CHAR_LENGTH}
              />
            </Box>
          )}

          {commentVisible && (
            <Box>
              {eventSelected?.comments?.length > 0 && (
                <CommentList
                  list={eventSelected?.comments}
                  onSave={!hideComment ? commentUpdate : undefined}
                  onDelete={!hideComment ? commentDelete : undefined}
                  portalType={portalType}
                  deleteMsg={t('commentDeleteMsg')}
                />
              )}
            </Box>
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      {portalType === PORTAL.MYDE ? (
        <TCard sx={{ p: 3 }} className={classes.cardWrapper}>
          {renderContent()}
        </TCard>
      ) : (
        <Box sx={{ p: 2 }}>{renderContent()}</Box>
      )}
    </>
  );
};

export default KycAmlRecordEventComponent;
