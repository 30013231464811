import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { getLoginDetails, loginUsingBankVault, selectUser } from '../../redux/feature/user/userSlice';
import { LoginFormSchema, LoginFormSchemaPayload } from './validation';
import * as ROUTES from '../../constants/routes';
import AuthLayout from '../../layout/AuthLayout';
import PublicFooter from '../../components/common/PublicFooter';
import {
  TButton,
  TTextField,
  colorPalette,
  BANKVAULT_HOST_URL,
  theme,
  TLoader,
} from '../../components/myde-react-components';
import { useAuth } from '../../auth/auth-guard';
import { useTranslation } from 'react-i18next';
import { clearStorage } from '../../utils/storage';
import { getProfileDetails } from '../../redux/feature/profile/profileSlice';
import {
  clearResults,
  getSessionDetails,
  selectSession,
  setActiveCardNav,
  setCardState,
} from '../../redux/feature/common/commonSlice';
import { useRouter } from '../../providers/custom-router-provider';
import { UserSession } from '../../types/userTypes';
import logo from '../../../src/common/assets/Trellis-Logo.png';
import bankVault from '../../../src/common/assets/BankVault-logo.png';
import clsx from 'clsx';
import { ID_CARD_NAV_INDEX, NAVIGATION_ROUTES, WEB_SOCKET_ID_CARD_MAP } from '../../constants/constants';
import { getNotificationsCount } from '../../redux/feature/notification/notificationSlice';

const useStyles = makeStyles({
  loginContainer: {
    paddingTop: '10%',
    backgroundColor: '#FFFF',
    borderRadius: '2%',
  },
  form: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: colorPalette.containerBack.lighten3,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colorPalette.border.card}`,
      },
    },
  },
  qrCode: {
    width: '150px',
    height: '150px',
  },
  subtitle: { margin: '16px 0px' },
  divider: { padding: '32px 0px' },
  align: { textAlign: 'center' },
  forgotPasswordBtn: {
    marginTop: '4px',
    float: 'right',
  },
  createAccountButton: {
    clear: 'both',
    paddingTop: '12px',
  },
  dividerBorder: {
    borderBottom: '2px solid lightgray',
    width: '100%',
  },
  logo: {
    height: 'auto',
    width: '100%',
  },
});

const Login = () => {
  const formRef = useRef<null | HTMLFormElement>(null);
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { handleSubmit, control, formState, setError } = useForm<LoginFormSchemaPayload>({
    resolver: zodResolver(LoginFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting, isSubmitted } = formState;

  const { userData, loginSuccess, loading } = useSelector(selectUser);
  const { session, isNewSession } = useSelector(selectSession);

  const [isBankVaultCallBack, setIsBankVaultCallBack] = useState(false);
  const [bankVaultSession, setBankVaultSession] = useState({} as UserSession);
  const [bankVaultWindow, setBankVaultWindow] = useState({} as any);
  const [isLoggedInWithBankVault, setIsLoggedInWithBankVault] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const { login } = useAuth();

  useEffect(() => {
    clearStorage();
    dispatch(clearResults());
  }, []);

  useEffect(() => {
    if (isSubmitted && loginSuccess) {
      dispatch(getProfileDetails());
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (formRef?.current?.submit) {
      formRef.current.submit = () => {
        loginWithBankVault();
      };
    }
  }, [formRef]);

  useEffect(() => {
    if (userData?.access) {
      if (userData.account?.verification_status?.is_email_verified) {
        if (isLoggedInWithBankVault) {
          bankVaultWindow.close();
        }
        login(userData.access);
        navigateTo(userData?.navigate_to);
      } else if (userData) {
        routeTo(ROUTES.VERIFY_EMAIL);
      }
    } else if (userData?.days_to_password_expiry <= 0) {
      routeTo(ROUTES.RESET_EXPIRED_PASSWORD, false, 'Reset Password', {email: userEmail});
    }
  }, [userData]);

  useEffect(() => {
    if (Object.keys(session)?.length > 0) {
      setBankVaultSession(session);
    } else {
      dispatch(getSessionDetails());
    }
  }, [session]);

  useEffect(() => {
    if (Object.keys(bankVaultSession)?.length > 0 && bankVaultSession?.session === session?.session) {
      loadScript(bankVaultSession?.session);
      if (isBankVaultCallBack && isNewSession) {
        loginWithBankVault();
      }
    }
  }, [bankVaultSession, session, isBankVaultCallBack, isNewSession]);

  const navigateTo = (navigation: string) => {
    dispatch(getNotificationsCount());
    const filterRoute = NAVIGATION_ROUTES.find((item) => item.WS_KEY === navigation);
    if (filterRoute?.ROUTE_KEY) {
      dispatch(setCardState(false));
      routeTo(filterRoute?.ROUTE_KEY);
    } else {
      const filterIdCardTabs = WEB_SOCKET_ID_CARD_MAP.find((item) => item.WS_KEY === navigation);
      dispatch(setCardState(true));
      routeTo(ROUTES.DASHBOARD);
      if (filterIdCardTabs?.TAB_KEY) {
        dispatch(setActiveCardNav(filterIdCardTabs?.TAB_KEY));
      } else {
        dispatch(setActiveCardNav(ID_CARD_NAV_INDEX.ID));
      }
    }
  };

  const navigationHandler = () => {
    routeTo(ROUTES.CREATE_ACCOUNT);
  };

  const forgotPasswordHandler = () => {
    routeTo(ROUTES.FORGOT_PASSWORD);
  };

  const loadScript = (sessionId: string) => {
    (window as any).BankVaultApi?.init({ session: sessionId, host: BANKVAULT_HOST_URL });
  };

  const loginWithBankVault = () => {
    if (Object.keys(bankVaultSession)?.length > 0 && isNewSession) {
      const data = {
        session: bankVaultSession?.session,
        token: bankVaultSession?.token,
        secret: bankVaultSession?.secret,
      };
      try {
        setIsLoggedInWithBankVault(true);
        dispatch(loginUsingBankVault(data));
        setIsBankVaultCallBack(false);
      } catch (err: any) {
        toast.error('Failed to login');
      }
    } else {
      setIsBankVaultCallBack(true);
      dispatch(getSessionDetails());
    }
  };

  const onSubmit = async (formData: LoginFormSchemaPayload) => {
    try {
      setUserEmail(formData?.email);
      await dispatch(getLoginDetails(formData));
    } catch (err: any) {
      setError('password', { type: 'manual', message: err.password });
    }
  };

  const openBankVault = () => {
    setBankVaultWindow(window.open(bankVaultSession?.session_url, '_blank'));
  };

  return (
    <>
      <AuthLayout>
        <TLoader loading={loading} />
        <Grid container spacing={0} className={classes.loginContainer}>
          <Grid item xs={12} sm={12} md={12}>
            <Box>
              <Box sx={{ textAlign: 'center' }}>
                <Box
                  className={(clsx(classes.logo), 'flex-basic-center')}
                  sx={{ mb: theme.spacing(5), pr: theme.spacing(3) }}
                >
                  <img className="trellis-logo" src={logo} alt="Branding Logo" />
                </Box>
                <Box className="text-h2 font-weight-semibold textColor-100">{t('signIn')}</Box>
              </Box>
              <Box sx={{ mt: theme.spacing(14), px: 3 }}>
                <form ref={formRef} className={classes.form}>
                  <input type="email" name="email" className="showNone" />
                  <input type="password" name="password" className="showNone" />
                  <Box sx={{ mb: theme.spacing(5) }}>
                    <Controller
                      name="email"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TTextField
                          label="Your Email"
                          variant="outlined"
                          fullWidth
                          error={Boolean(errors.email)}
                          helperText={errors.email?.message}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="password"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TTextField
                          label="Password"
                          variant="outlined"
                          type="password"
                          fullWidth
                          error={Boolean(errors.password)}
                          helperText={errors.password?.message}
                          {...field}
                        />
                      )}
                    />
                    <Box className="flex-basic-end" sx={{ mt: 1 }}>
                      <TButton variant="text" onClick={forgotPasswordHandler} btnText={'Forgot Password?'}></TButton>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <TButton
                      variant={'contained'}
                      type="submit"
                      fullWidth
                      disabled={!isValid || isSubmitting}
                      btnText={'Sign In'}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </Box>
                </form>
                <Box sx={{ mt: 1, px: 3 }}>
                  <Box sx={{ mt: 4 }} className="text-center">
                    <Box className="flex-basic-center  text-medium textColor-300">{t('signUpText')}</Box>
                    <Box sx={{ mt: theme.spacing(3) }} className="flex-basic-center">
                      <TButton variant="text" onClick={navigationHandler} btnText={'Sign Up'}></TButton>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ px: 3 }}>
                  <Box sx={{ my: 3 }} className="loginOrSection flex-basic-center">
                    <Box className="orCircle flex-basic-center">
                      <Box className="text-medium">Or</Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ px: 3, pb: 5 }} className="flex-basic-center">
                  <Box className="text-medium textColor-300">{t('loginWith')}</Box>
                  <Box className="cursorPointer" sx={{ ml: 1 }}>
                    <img src={bankVault} alt={'BankVault logo'} onClick={openBankVault} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 1, mb: 4, px: 4 }}>
              <PublicFooter />
            </Box>
          </Grid>
        </Grid>
      </AuthLayout>
    </>
  );
};

export default Login;
