import { colorPalette } from '../components/myde-react-components';
export const fontStyle = {
  fontSize: {
    font8: {
      fontSize: '0.5rem', // 8px
    },
    font10: {
      fontSize: '0.625rem', // 10px
    },
    font11: {
      fontSize: '0.688rem', // 11px
    },
    font12: {
      fontSize: '0.75rem', // 12px
    },
    font14: { fontSize: '0.875rem' }, // 14px
    font16: { fontSize: '1rem' }, // 16px
    font18: { fontSize: '1.125rem' }, // 18px
    font20: { fontSize: '1.25rem' }, //20px
    font22: { fontSize: '1.375rem' }, // 22px
    font24: { fontSize: '1.5rem' }, // 24px
    font28: { fontSize: '1.75rem' }, // 28px
    font32: { fontSize: '2rem' }, // 32px
    font36: { fontSize: '2.25rem' }, // 36px
    font40: { fontSize: '2.5rem' }, // 40px
  },
  fontWeight: {
    font400: {
      fontWeight: '400',
    },
    font500: {
      fontWeight: '500',
    },
    font600: { fontWeight: '600' },
    font700: { fontWeight: '700' },
    font800: { fontWeight: '800' },
  },
};

export const typography = {
  title24Bold: {
    ...fontStyle.fontSize.font24,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  title24SemiBold: {
    ...fontStyle.fontSize.font24,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken1,
  },
  title16BoldDarken: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken1,
  },
  title16SemiBold: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken2,
  },
  title16Bold: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.lighten1,
  },
  title16BoldDark2: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font800,
    color: colorPalette.typoText.darken2,
  },
  body16ThinDark: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken,
  },
  title16BoldDark1: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.darken2,
  },
  title16BoldBase: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.base,
  },
  body18Thin: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.lighten1,
  },
  body18Dark: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken1,
  },
  body18ThinDark: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken2,
  },
  body18SemiBold: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.lighten1,
  },
  body18SemiBlack: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.base,
  },
  body18Bold: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.lighten1,
  },
  body18BoldDark: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.darken2,
  },
  link: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    fontFamily: 'Montserrat',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  linkLight: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.lighten2,
    fontFamily: 'Montserrat',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  description: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.lighten1,
    fontFamily: 'Montserrat',
  },
  descriptionDark: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.base,
    fontFamily: 'Montserrat',
  },
  caption: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
    lineHeight: '20px',
  },
  captionLighten: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.lighten1,
    fontFamily: 'Montserrat',
  },
  captionSm: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  captionSmDark: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.base,
    fontFamily: 'Montserrat',
  },
  tabsText: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.base,
    fontFamily: 'Montserrat',
  },
  label: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  labelDark: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken1,
    fontFamily: 'Montserrat',
  },
  value: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken1,
    fontFamily: 'Montserrat',
  },
  valueLighten: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  stepperTitle: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.lighten1,
    fontFamily: 'Montserrat',
  },
  subTitle: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  bannerTitle: {
    ...fontStyle.fontSize.font28,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.base,
    fontFamily: 'Montserrat',
  },
  title20: {
    ...fontStyle.fontSize.font20,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  title20SemiBold: {
    ...fontStyle.fontSize.font20,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  address: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.base,
    fontFamily: 'Montserrat',
  },
  mobileList: {
    ...fontStyle.fontSize.font10,
    ...fontStyle.fontWeight.font300,
    color: colorPalette.typoText.base,
    fontFamily: 'Montserrat',
  },
  mobileLabel: {
    ...fontStyle.fontSize.font10,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.lighten1,
    fontFamily: 'Montserrat',
  },
  addressTitle: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  verifyTitle: {
    ...fontStyle.fontSize.font11,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.lighten1,
    fontFamily: 'Montserrat',
  },
};
