import React, { useEffect, useState } from 'react';
import VideoRecorder from 'react-video-recorder';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface CaptureVideoProps {
  setFile: any;
  uploadedFile?: any;
}
const useStyles = makeStyles({
  container: {
    padding: 0,
    marginTop: '1rem',
    width: '17rem',
    height: '9rem',
  },
});
const CaptureVideo = ({ setFile }: CaptureVideoProps) => {
  const classes = useStyles();
  const [file, setFiles] = useState([] as any);
  useEffect(() => {
    if (file && Object.keys(file).length > 0) {
      setFile(file);
    }
    URL.revokeObjectURL(file);
  }, [file]);
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box sx={{ m: '0 auto' }} className={classes.container}>
        <VideoRecorder
          chunkSize={250}
          constraints={{
            audio: true,
            video: true,
          }}
          countdownTime={3000}
          isFlipped={true}
          timeLimit={30000}
          dataAvailableTimeout={500}
          isOnInitially
          onOpenVideoInput={() => {
            return;
          }}
          onRecordingComplete={(videoBlob: any) => {
            videoBlob = videoBlob.slice(0, videoBlob.size, 'video/mp4');
            setFiles(Object.assign(videoBlob, { preview: URL.createObjectURL(videoBlob) }));
          }}
        />
      </Box>
    </Box>
  );
};
export default CaptureVideo;
