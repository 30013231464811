import React from 'react';
import { Button as MuiButton, SvgIconProps, ButtonProps as MuiButtonProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import clsx from 'clsx';
import { hexToRgbA } from '../../utils/commonMethods';

export interface ButtonProps extends MuiButtonProps {
  btnText?: string;
  icon?: React.ReactElement<SvgIconProps>;
  width?: string;
  btnWidthSize?: string;
  btnVariantClass?: string;
}

interface StyleProps {
  currentThemes: Theme;
  variant?: string;
  width?: string;
}
const useStyles = makeStyles<Theme, StyleProps>({
  iconOnly: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  customBtn: {
    '&.MuiButton-contained': {
      '&:hover': {
        backgroundColor: ({ currentThemes, variant }) =>
          variant === 'contained' ? `${currentThemes.palette.primary.main}` : '',
      },
      backgroundColor: ({ currentThemes, variant }) =>
        variant === 'contained' ? `${currentThemes.palette.primary.main}` : '',
      width: ({ width }) => (width ? width : ''),
      //new button with icon
      '&.buttonWithTextIcon': {
        backgroundColor: ({ currentThemes }) => `${hexToRgbA(currentThemes.palette.primary.main, 0.06)} !important`,
        color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
      },
    },

    '&.MuiButton-outlined': {
      '&.buttonWithTextIcon': {
        color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
        borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
      },
    },
  },
});

export const Button = ({
  btnText,
  variant,
  icon,
  width,
  btnVariantClass = '',
  btnWidthSize = '',
  ...props
}: ButtonProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
    variant: variant,
    width: width,
  };

  const classes = useStyles(styleProps);
  return (
    <MuiButton
      startIcon={icon}
      component="button"
      className={clsx(
        {
          [classes.iconOnly]: !btnText,
        },
        classes.customBtn,
        btnWidthSize,
        btnVariantClass,
      )}
      {...props}
      variant={variant}
    >
      {btnText}
    </MuiButton>
  );
};
