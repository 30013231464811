import * as z from 'zod';

const symbol = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
const number = /\d/;
export const RegisterFormSchema = z
  .object({
    first_name: z.string().min(1, { message: 'First Name is required' }),
    middle_name: z.string().optional(),
    last_name: z.string().min(1, { message: 'Last Name is required' }),
    email: z.string().min(1, { message: 'Email is required' }).email(),
    password: z.string().min(8, { message: 'Password is invalid' }),
    confirm_password: z.string().min(1, { message: 'Confirm password is required' }),
  })
  .refine((data) => number.test(data.password), {
    message: 'Password is invalid',
    path: ['password'],
  })
  .refine((data) => symbol.test(data.password), {
    message: 'Password is invalid',
    path: ['password'],
  })
  .refine((data) => data.password.toLowerCase() !== data.password, {
    message: 'Password is invalid',
    path: ['password'],
  })
  .refine((data) => data.password.toUpperCase() !== data.password, {
    message: 'Password is invalid',
    path: ['password'],
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Confirm password does not match',
    path: ['confirm_password'],
  });

export type RegisterFormSchemaPayload = z.infer<typeof RegisterFormSchema>;
