import React from 'react';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EntityForm from './components/EntityForm';

interface EntityFormIndexProps {
  goToNextStep: (step: number) => void;
}

const EntityFormIndex = ({ goToNextStep }: EntityFormIndexProps) => {
  const { t } = useTranslation('common');
  const renderForm = () => {
    return (
      <Box>
        <Box className="text-large font-weight-semibold textColor-300 flex-basic-center" sx={{ p: 2 }}>
          {t('entityOverview')}
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Box className="text-large font-weight-semibold textColor-200" sx={{ px: 3, pt: 2, pb: 1 }}>
          {t('entityFormTitle')}
        </Box>
        <Box sx={{ p: 3 }}>{<EntityForm goToNextStep={goToNextStep} />}</Box>
      </Box>
    );
  };

  return <Box>{renderForm()}</Box>;
};
export default EntityFormIndex;
