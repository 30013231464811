import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { PORTAL } from '../../constants/portal';
import TDialog from '../Dialog/Dialog';

interface ConfirmDialogProps {
  showDialog: boolean;
  confirmationMessage?: string;
  title?: string;
  noBtnTitle?: string;
  yesBtnTitle?: string;
  classNameConfirmDailog?: string;
  onCancelClick: ClickHandlerType;
  onConfirmClick: ClickHandlerType;
  portalName?: string;
}
interface ClickHandlerType {
  (param?: any): void;
}
interface StyleProps {
  matches: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  confirmDialog: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        width: ({ matches }) => (matches ? 460 : 303),
        maxWidth: ({ matches }) => (matches ? 460 : 303),
        '& .MuiBox-root': {
          '& .MuiTypography-root': {
            padding: ({ matches }) => (matches ? '' : '20px 0px 12px 20px'),
            '& .text-h2': {
              font: ({ matches }) =>
                matches
                  ? 'normal 600 24px/36px Basier Circle !important'
                  : 'normal 600 18px/28px Basier Circle !important',
            },
            '& .MuiButtonBase-root': {
              padding: '0 !important',
            },
          },
        },
        '& .MuiDialogContent-root': {
          padding: ({ matches }) => (matches ? '' : '0px 20px !important'),
        },
      },
    },
  },
});

export const ConfirmDialog = ({
  title,
  noBtnTitle = 'No',
  yesBtnTitle = 'Yes',
  showDialog = false,
  confirmationMessage = 'Are you sure to delete the selected comment ?',
  onCancelClick,
  classNameConfirmDailog,
  onConfirmClick,
  portalName = PORTAL.MYDE,
}: ConfirmDialogProps) => {
  const matches = portalName === PORTAL.MERCHANT;
  const styleProps = {
    matches,
  };
  const classes = useStyles(styleProps);

  const onCancel = () => {
    onCancelClick();
  };
  const onConfirm = () => {
    onConfirmClick();
  };

  const confDialogBtns: BtnType[] = [
    {
      id: 1,
      title: noBtnTitle,
      variant: 'text',
      buttonAction: onCancel,
    },
    {
      id: 2,
      title: yesBtnTitle,
      variant: 'contained',
      buttonAction: onConfirm,
    },
  ];
  interface BtnType {
    id: number;
    title: string;
    variant?: 'contained' | 'outlined' | 'text';
    buttonAction: ClickHandlerType;
  }

  return (
    <TDialog
      className={clsx(classes.confirmDialog, classNameConfirmDailog)}
      open={showDialog}
      title={title ? title : 'Confirm Delete'}
      buttons={confDialogBtns}
      portalName={portalName}
    >
      {confirmationMessage}
    </TDialog>
  );
};

export default ConfirmDialog;
