import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ENTITY_INVITATION_ACTION } from '../../../constants/constants';
import {
  entityInviteAction,
  getEntityInvitationList,
  selectEntity,
  setShowEntityDetailsFlag,
} from '../../../redux/feature/entity/entitySlice';
import { fontStyle } from '../../../styles/style';
import { AcceptInviteType } from '../../../types/entityTypes';
import { removeUnderscore, titleCase } from '../../../utils/utils';
import { TButton, TCommonAvatar, TFullScreenDialog, theme, TLoader } from '../../myde-react-components';

const ViewEntityInviteDetails = () => {
  //Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //Redux
  const { showEntityDetailsFlag, selectedEntityDetails, loading, acceptedRejectedEntityInviteDetails } =
    useSelector(selectEntity);

  //State
  const [showEntityInviteDetailsDialog, setShowEntityInviteDetailsDialog] = useState(false);

  //useEffects
  useEffect(() => {
    if (showEntityDetailsFlag && selectedEntityDetails && Object.keys(selectedEntityDetails)?.length > 0) {
      setShowEntityInviteDetailsDialog(showEntityDetailsFlag);
    }
  }, [selectedEntityDetails]);

  useEffect(() => {
    if (acceptedRejectedEntityInviteDetails && Object.keys(acceptedRejectedEntityInviteDetails)?.length > 0) {
      dispatch(getEntityInvitationList());
    }
  }, [acceptedRejectedEntityInviteDetails]);

  //Methods
  const closeModal = () => {
    dispatch(setShowEntityDetailsFlag(false));
    setShowEntityInviteDetailsDialog(false);
  };

  const handleInviteAction = async (inviteAction: string) => {
    const payload: AcceptInviteType = {
      inviteId: selectedEntityDetails?.id,
      payload: {
        action: inviteAction,
      },
    };
    dispatch(entityInviteAction(payload));
    closeModal();
  };

  //HTML
  return (
    <>
      <TLoader loading={loading} />
      <TFullScreenDialog
        open={showEntityInviteDetailsDialog}
        closeModal={closeModal}
        title={'Entity Association Request'}
        dialogContent={
          <>
            <Box className="text-center">
              <Box sx={{ mb: 5 }} className="flex-basic-center">
                <TCommonAvatar
                  imageUrl={selectedEntityDetails?.entity?.avatar_url || ''}
                  avatarText={selectedEntityDetails?.entity?.name || ''}
                  iconSize="100px"
                  textSize={fontStyle.fontSize.font36.fontSize}
                />
              </Box>
              <Box sx={{ mb: 4, px: 3 }} className="textColor-200 text-h3 font-weight-semibold">
                {`${t('inviteTitle', { ns: 'entityInvite' })} ${selectedEntityDetails?.entity?.name} from ${
                  selectedEntityDetails?.account?.first_name
                } ${selectedEntityDetails?.account?.last_name}`}
              </Box>
            </Box>
            <Box sx={{ pl: 3 }} className="entityInviteDetailsPosition">
              <Box sx={{ mb: theme.spacing(5) }}>
                <Box sx={{ mb: theme.spacing(1) }} className="textColor-300 text-small">
                  {t('entityTid', { ns: 'entityInvite' })}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">
                  {selectedEntityDetails?.entity?.trellis_uid}
                </Box>
              </Box>
              <Box sx={{ mb: theme.spacing(5) }}>
                <Box sx={{ mb: theme.spacing(1) }} className="textColor-300 text-small">
                  {t('entityName', { ns: 'entityInvite' })}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">
                  {selectedEntityDetails?.entity?.name}
                </Box>
              </Box>
              <Box sx={{ mb: theme.spacing(5) }}>
                <Box sx={{ mb: theme.spacing(1) }} className="textColor-300 text-small">
                  {t('role', { ns: 'entityInvite' })}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">
                  {titleCase(selectedEntityDetails?.user_role?.toLocaleLowerCase())}
                </Box>
              </Box>
              <Box>
                <Box sx={{ mb: theme.spacing(1) }} className="textColor-300 text-small">
                  {t('permission', { ns: 'entityInvite' })}
                </Box>
                <Box className="textColor-200 text-medium font-weight-semibold">
                  {removeUnderscore(selectedEntityDetails?.permission?.toLowerCase())}
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: theme.spacing(9), mb: theme.spacing(5) }} />
            <Box sx={{ mb: 4, px: 3 }} className="textColor-200 text-medium text-center">
              {t('inviteAcceptInstruction', { ns: 'entityInvite' })}
            </Box>
            <Box className="flex-column-center">
              <Box sx={{ mb: theme.spacing(5) }}>
                <TButton
                  btnText="Accept"
                  btnWidthSize="button-w-240"
                  variant="contained"
                  onClick={() => handleInviteAction(ENTITY_INVITATION_ACTION.ACCEPT)}
                />
              </Box>
              <Box sx={{ mb: theme.spacing(3) }}>
                <TButton
                  btnText="Decline"
                  btnWidthSize="button-w-240"
                  variant="text"
                  onClick={() => handleInviteAction(ENTITY_INVITATION_ACTION.DISMISS)}
                />
              </Box>
            </Box>
          </>
        }
      />
    </>
  );
};

export default ViewEntityInviteDetails;
