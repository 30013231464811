import React from 'react';
import { LinearProgress as MuiLinearProgress, LinearProgressProps, Theme } from '@mui/material';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { makeStyles } from '@mui/styles';
interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  colorPrimary: {
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
  },
  barColorPrimary: {
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
  },
});
export const LinearProgress = ({ ...props }: LinearProgressProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);
  return (
    <MuiLinearProgress
      classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
      {...props}
    />
  );
};
export default MuiLinearProgress;
