import React, { useEffect, useState } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AuthLayout from '../../layout/AuthLayout';
import { colorPalette, TButton, theme, TPaper, TTextField } from '../../components/myde-react-components';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { ForgotPasswordFormSchemaPayload, ForgotPasswordSchema } from './validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from '../../providers/custom-router-provider';
import { forgotPassword, selectUser, setLinkSetForgotPassword } from '../../redux/feature/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import resendEmailBgImage from '../../../src/common/assets/check-email-bg-image.svg';
import { useTheme } from '../../providers/custom-theme-provider';
import clsx from 'clsx';

interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  form: {
    margin: '18px 32px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: colorPalette.containerBack.lighten3,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colorPalette.border.card}`,
      },
    },
  },
  imgContainer: {
    width: '156px',
    marginLeft: '-9px',
  },
  img: {
    width: '100%',
  },
  mailIconColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

export const ForgotPassword = () => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const classes = useStyles(styleProps);
  const { goBack } = useRouter();
  const { handleSubmit, control, formState, setError } = useForm<ForgotPasswordFormSchemaPayload>({
    resolver: zodResolver(ForgotPasswordSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;

  const { linkSentForForgotPassword } = useSelector(selectUser);

  //state
  const [formData, setFormData] = useState({ email: '' });

  const sendEmail = async (payload: ForgotPasswordFormSchemaPayload) => {
    try {
      await dispatch(forgotPassword(payload));
    } catch (err: any) {
      setError('email', { type: 'manual', message: err.detail });
    }
  };

  const onSubmit = async (formDataDetail: ForgotPasswordFormSchemaPayload) => {
    setFormData(formDataDetail);
    sendEmail(formDataDetail);
  };

  const resendEmail = () => {
    sendEmail(formData);
  };

  useEffect(() => {
    dispatch(setLinkSetForgotPassword(false));
  }, []);

  const headingSubHeading = (headerText: string, subHeaderText: string) => {
    return (
      <>
        <Box className="text-h2 font-weight-semibold textColor-100" sx={{ mt: theme.spacing(9), pb: theme.spacing(3) }}>
          {t(headerText)}
        </Box>
        <Box sx={{ mx: 3 }}>
          <span className="text-medium font-weight-regular textColor-200">{t(subHeaderText)}</span>
        </Box>
      </>
    );
  };

  const emailScreen = (
    <>
      {headingSubHeading('forgotPassword', 'forgotPasswordText')}
      <form className="w-100">
        <Box sx={{ my: theme.spacing(15) }}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TTextField
                label="Enter Email Address"
                variant="outlined"
                fullWidth
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                {...field}
              />
            )}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TButton
            variant={'contained'}
            type="submit"
            btnWidthSize="button-w-240"
            disabled={!isValid || isSubmitting}
            btnText={'Submit'}
            onClick={handleSubmit(onSubmit)}
          ></TButton>
        </Box>
        <Box sx={{ mt: 3 }}>
          <TButton variant="text" btnText={'Back'} onClick={() => goBack()}></TButton>
        </Box>
      </form>
    </>
  );

  const resendEmailScreen = (
    <>
      <Box sx={{ pt: theme.spacing(4) }} className="flex-basic-center">
        <Box className={clsx(classes.imgContainer, 'p-relative')}>
          <img src={resendEmailBgImage} alt="resendEmailBgImage" className={classes.img} />
          <MarkEmailReadOutlinedIcon className={clsx(classes.mailIconColor, 'mailIcon')} />
        </Box>
      </Box>
      {headingSubHeading('checkYourEmail', 'checkYourEmailInfo')}
      <Box sx={{ mx: 6, mt: 4 }}>
        <span className="text-medium font-weight-regular textColor-200">{t('emailNotReceived')}</span>
      </Box>
      <Box sx={{ mb: 1, mt: theme.spacing(3), mx: 6 }}>
        <TButton
          variant={'contained'}
          btnWidthSize="button-w-240"
          btnText={'Resend Email'}
          onClick={resendEmail}
        ></TButton>
      </Box>
    </>
  );

  // HTML
  return (
    <AuthLayout>
      <TPaper>
        <Box sx={{ p: 3 }} className="flex-column-center text-center">
          {linkSentForForgotPassword ? resendEmailScreen : emailScreen}
        </Box>
      </TPaper>
    </AuthLayout>
  );
};

export default ForgotPassword;
