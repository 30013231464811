import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import DocumentListing from '../../../components/documents/DocumentListing';
import {
  colorPalette,
  PDF_MIME_TYPE,
  TButton,
  TCard,
  TLoader,
  TSearchComponent,
} from '../../../components/myde-react-components';
import { MerchantDocumentType } from '../../../types/documentTypes';
import * as ROUTES from '../../../constants/routes';
import {
  clearFileList,
  getFolderFiles,
  getSelectedFile,
  selectDocuments,
  setIsSearching,
  setSelectedFile,
} from '../../../redux/feature/documents/documentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../providers/custom-router-provider';
import useQuery from '../../../hooks/useQuery';

const useStyles = makeStyles({
  divider: {
    borderColor: colorPalette.border.base,
  },
  pageContainer: {
    minHeight: '100vh',
  },

  btnViewAllResults: {
    width: '95%',
    position: 'absolute',
    bottom: '21px',
  },
});

export const DocumentFiles = () => {
  // Constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const query = useQuery();
  const category = query.get('category') || '';
  const folder = query.get('folder') || '';
  const merchant = query.get('merchant') || '';
  const isEntityMerchant = query.get('isEntiy') === 'true';
  const section = query.get('section') || '';
  const entity_uid = query.get('entity_uid') || '';
  const applicationId = query.get('applicationId') || '';

  // Redux Values
  const { merchantId, fileList, loading, isSearching } = useSelector(selectDocuments);

  // State Value
  const [filesList, setFilesList] = useState([] as MerchantDocumentType[]);
  const [searchValue, setSearchValue] = useState('');
  const [resetSearchFilter, setResetSearchFilter] = useState(false);
  const [showViewAllBtn, setShowViewAllBtn] = useState(false);

  // Use Effects
  useEffect(() => {
    if (category && folder) {
      const data = {
        page: 1,
        limit: 1000,
        category: category,
        year_month: folder,
        merchantId: applicationId,
      };
      if (isEntityMerchant) {
        dispatch(getFolderFiles({ ...data, entity_uid: entity_uid }));
      } else {
        dispatch(getFolderFiles(data));
      }
    }
  }, [category, folder]);

  useEffect(() => {
    setFilesList(fileList);
  }, [fileList]);

  // Methods
  const selectFile = (file: MerchantDocumentType) => {
    const data = {
      docId: file.doc_id,
    };
    if (isEntityMerchant) {
      dispatch(getSelectedFile({ ...data, filters: { entity_uid: entity_uid } }));
    } else {
      dispatch(getSelectedFile(data));
    }
    dispatch(setSelectedFile(file));
    const query = {
      id: merchantId,
      category: category,
      folder: folder,
      merchant: merchant,
      section: section,
      entity_uid: entity_uid,
    };
    routeTo(ROUTES.FILE_DETAILS, true, 'Document', query);
  };

  const searchHandler = (value: string) => {
    setSearchValue(value);
    if (value) {
      dispatch(
        getFolderFiles({
          name: value,
          category: category,
          year_month: folder,
          limit: 4,
          entity_uid: isEntityMerchant ? entity_uid : '',
        }),
      );
      setShowViewAllBtn(true);
    } else {
      dispatch(clearFileList());
      setShowViewAllBtn(false);
    }
  };

  const searchBoxClickHandler = () => {
    dispatch(setIsSearching(true));
    setResetSearchFilter(false);
    setShowViewAllBtn(false);
  };

  const backSearchHandler = () => {
    dispatch(getFolderFiles({ category: category, year_month: folder }));
    setResetSearchFilter(true);
    dispatch(setIsSearching(false));
    dispatch(clearFileList());
    setShowViewAllBtn(false);
  };

  const viewAllBtnClickHandler = () => {
    dispatch(getFolderFiles({ name: searchValue, category: category, year_month: folder }));
    setShowViewAllBtn(false);
  };

  // HTML
  return (
    <Box className={classes.pageContainer}>
      <TLoader loading={loading} />
      {!loading && (
        <TCard sx={{ py: 3 }} className="card-height-200">
          <Box className="flex-basic-start" sx={{ px: 3 }}>
            {isSearching && (
              <Box sx={{ pr: 2, pt: 1 }}>
                <span
                  className="icon-arrow-lefts textColor-200 cursorPointer actionIconFont-24"
                  onClick={backSearchHandler}
                ></span>
              </Box>
            )}
            <Box className="flex-basic-start w-100" onClick={searchBoxClickHandler}>
              <Box className="w-100">
                <TSearchComponent
                  hideResetButton={true}
                  placeholder="Search"
                  onSearchUpdate={searchHandler}
                  hasExtraFilters={true}
                  resetSearchFilter={resetSearchFilter}
                />
              </Box>
            </Box>
          </Box>

          <Box className="scrollBox">
            {filesList?.map((file, index) => {
              return (
                <Box key={index}>
                  <DocumentListing
                    name={file?.name}
                    icon={
                      PDF_MIME_TYPE.includes(file?.details?.mime_type)
                        ? 'PictureAsPdfOutlinedIcon'
                        : 'ImageOutlinedIcon'
                    }
                    showDescriptionIcon={file?.merchant_communication?.message !== ''}
                    modifiedDateTime={file?.modified_on}
                    onClick={() => selectFile(file)}
                    showUnreadIcon={!file?.is_read}
                  />

                  {index < filesList?.length - 1 && <Divider sx={{ mt: 1, mb: 1 }} />}
                </Box>
              );
            })}
          </Box>
          {showViewAllBtn && filesList?.length > 3 && (
            <div className={clsx(classes.btnViewAllResults, 'flex-basic-center')}>
              <TButton
                btnText="Verify All Results"
                variant={'contained'}
                color="primary"
                btnWidthSize={'button-w-240'}
                onClick={viewAllBtnClickHandler}
              />
            </div>
          )}
        </TCard>
      )}
    </Box>
  );
};

export default DocumentFiles;
