import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  colorPalette,
  TLoader,
  TButton,
  ID_PROCESS_STATUS_COMPLETION_STATE,
  theme,
  TCard,
} from '../../../myde-react-components';
import {
  selectProfile,
  getProfileDetailsList,
  getProfileDetailsById,
  resetAddedProfile,
  resetEmailNotVerified,
  resetIsSkip,
  setProfileHolder,
} from '../../../../redux/feature/profile/profileSlice';
import {
  setEmailNotVerified,
  resetEntityAddedProfile,
  getEntityProfileDetailsList,
  getEntityProfileDetailsById,
  selectEntityProfile,
} from '../../../../redux/feature/profile/entityProfileSlice';
import { ID_PROCESS_STATUS_TEXT } from '../../../../constants/constants';
import { ProfileDetails } from '../../../../types/profileTypes';
import { useTheme } from '../../../../providers/custom-theme-provider';
import {
  selectSession,
  setCurrentStep,
  setIsDeleteAllowed,
  setIsEditAllowedFlag,
  setPreviousStep,
  setShowDeleteConfirmation,
} from '../../../../redux/feature/common/commonSlice';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';
import { showResendBtnHandler } from '../../../../redux/feature/verifyEmail/verifyEmailSlice';
import {
  idProcessShareDetails,
  setShowIdProcessSharedSection,
} from '../../../../redux/feature/idProcess/idProcessShareSlice';
import { ListDetailsResultType } from '../../../../types/idProcessTypes';

interface StyleProps {
  currentThemes: Theme;
}

interface ProfileVerificationProps {
  isEntity: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  formCard: {
    border: `1px solid ${colorPalette.containerBack.lighten2}`,
    borderRadius: '10px',
    marginBottom: 12,
  },
}));

const ProfileVerification = ({ isEntity }: ProfileVerificationProps) => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  // state variables
  const [formMode, setFormMode] = useState(false);
  const [profileDetailsList, setProfileDetailsList] = useState([] as ProfileDetails[]);

  // selectors
  const { profileList, isSkip, loading } = useSelector(selectProfile);
  const { refreshPage } = useSelector(selectSession);
  const { selectedEntity } = useSelector(selectEntity);
  const { entityProfileList } = useSelector(selectEntityProfile);
  const { entitySharedMerchantList, sharedIdProcessData } = useSelector(idProcessShareDetails);

  //useEffects
  useEffect(() => {
    if (isEntity) {
      setProfileDetailsList(entityProfileList);
    } else {
      setProfileDetailsList(profileList);
    }
  }, [isEntity, entityProfileList, profileList]);

  useEffect(() => {
    if (refreshPage) {
      if (isEntity) {
        dispatch(getEntityProfileDetailsList({ entityUId: selectedEntity?.trellis_uid, params: {} }));
      } else {
        dispatch(getProfileDetailsList({}));
      }
    }
  }, [refreshPage, isEntity, selectedEntity]);

  useEffect(() => {
    if (isEntity) {
      dispatch(getEntityProfileDetailsList({ entityUId: selectedEntity?.trellis_uid, params: {} }));
    } else {
      dispatch(getProfileDetailsList({}));
    }
  }, [formMode, isEntity]);

  useEffect(() => {
    if (isSkip) {
      formModeSwitch();
    }
  }, [isSkip]);

  useEffect(() => {
    dispatch(setIsEditAllowedFlag(false));
    dispatch(setIsDeleteAllowed(false));
    dispatch(resetAddedProfile());
    dispatch(setShowDeleteConfirmation(false));
    dispatch(setProfileHolder({} as ProfileDetails));
    dispatch(showResendBtnHandler());
  }, []);

  const formModeSwitch = () => {
    setFormMode(!formMode);
    dispatch(resetEmailNotVerified());
    dispatch(setEmailNotVerified(false));
    dispatch(resetAddedProfile());
    dispatch(resetEntityAddedProfile());
    dispatch(resetIsSkip());
  };

  const addNewProfile = () => {
    if (isEntity) {
      dispatch(getEntityProfileDetailsList({ entityUId: selectedEntity?.trellis_uid, params: {} }));
    } else {
      dispatch(getProfileDetailsList({}));
    }
    formModeSwitch();
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
  };

  const showProfileDetails = (profile: ProfileDetails) => {
    const sharedIdProcessDataWithMerchant = isEntity ? entitySharedMerchantList : sharedIdProcessData;
    const showIdProcessSharedSection = checkIsProfileShared(sharedIdProcessDataWithMerchant, profile);
    dispatch(setShowIdProcessSharedSection(showIdProcessSharedSection));
    if (isEntity) {
      dispatch(getEntityProfileDetailsById({ id: profile?.id, entityId: selectedEntity?.trellis_uid }));
    } else {
      dispatch(getProfileDetailsById(profile?.id));
    }
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[3].percent));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
  };

  const checkIsProfileShared = (sharedMerchantList: ListDetailsResultType[], selectedProfile: ProfileDetails) => {
    const sharedMerchant = sharedMerchantList?.find(
      (merchantData) =>
        merchantData?.details?.profiles &&
        Object.keys(merchantData?.details?.profiles).includes(selectedProfile?.profile_name),
    );
    return !!sharedMerchant?.id;
  };

  return (
    <>
      <Box>
        <Box>
          <TLoader loading={loading} />
          {profileDetailsList &&
            profileDetailsList?.map((profile: ProfileDetails) => (
              <Box className="p-relative" sx={{ mb: 2 }} key={profile?.id}>
                <TCard sx={{ p: 3 }} className="cursorPointer" onClick={() => showProfileDetails(profile)}>
                  <Box sx={{ mb: 1 }} className="textColor-200 text-large font-weight-semibold">
                    {profile?.profile_name}
                  </Box>
                  <Box className="p-relative">
                    <Box sx={{ pb: theme.spacing(1) }} className="textColor-300 text-small">
                      {t('email') + ':\t'}
                      {profile?.profile_email?.verification_status_label}
                    </Box>
                    <Box className="textColor-300 text-small font-weight-regular">
                      {t('address') + ':\t'}
                      {profile?.address?.address_verification_status_label}
                    </Box>
                  </Box>
                </TCard>
                <Box className="p-absolute approveStatus">
                  {profile?.address?.address_verification_status_label === ID_PROCESS_STATUS_TEXT.VERIFIED && (
                    <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                      <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
                    </Box>
                  )}
                  {profile?.address?.address_verification_status_label ===
                    ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED && (
                    <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                      <span className="icon-error-warning error-color-state icon-size-24"></span>
                    </Box>
                  )}
                  {profile?.address?.address_verification_status_label ===
                    ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS && (
                    <Box className="flex-basic-end">
                      <Box sx={{ pt: theme.spacing(5) }} className="p-relative small-sizes custom-progress-icon">
                        <Box className="progress-spinner sm-spinner"></Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
        </Box>
        <Box className="sticky-button text-center" sx={{ pb: theme.spacing(5), my: theme.spacing(9) }}>
          <TButton
            btnText={'Add New'}
            variant="contained"
            btnWidthSize="button-w-240"
            color="primary"
            onClick={addNewProfile}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProfileVerification;
