import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ID_PROCESS_STATUS_TEXT, PROCESS_IDS } from '../../../../constants/constants';
import { useTheme } from '../../../../providers/custom-theme-provider';
import {
  getProcessDetailsById,
  selectSelectedIdProcessDetails,
  getEntityProcessDetailsById,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';
import PreviewFileByType from '../../../common/PreviewFileByType';
import {
  FilePreviewType,
  TCard,
  theme,
  ENTITY_PROCESS_IDS,
  ID_PROCESS_STATUS,
  EntityPayloadType,
  colorPalette,
} from '../../../myde-react-components';
import { getIconByProcessId } from '../../../myde-react-components/src/utils/idProcess';
import { hexToRgbA } from '../../../../utils/utils';

// Interfaces
interface DocumentIdProcessVerificationPorps {
  isEntity?: boolean;
  isMultiple?: boolean;
}

interface StyleProps {
  currentThemes: Theme;
  primaryColor: string;
  errorColor: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  notification: {
    backgroundColor: ({ primaryColor }) => hexToRgbA(primaryColor, 0.08),
  },
  errorMessageBox: {
    backgroundColor: ({ errorColor }) => hexToRgbA(errorColor, 0.08),
    border: '1px solid',
    borderRadius: '10px',
    borderColor: ({ errorColor }) => hexToRgbA(errorColor, 0.07),
  },
});

// Main Class
const DocumentIdProcessVerification = ({ isEntity, isMultiple }: DocumentIdProcessVerificationPorps) => {
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes.palette.primary.main;
  const errorColor = colorPalette.error;
  const styleProps = {
    currentThemes,
    primaryColor,
    errorColor,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation([
    'incomeVerification',
    'common',
    'formationDocument',
    'letterOfGoodStanding',
    'irsLetterEvidencing',
  ]);
  const dispatch = useDispatch();
  const { selectedEntity } = useSelector(selectEntity);
  // State variables
  const [selectedIdProcessData, setSelectedIdProcessData] = useState({} as any);
  const [idProcessStatus, setIdProcessStatus] = useState('');

  // Redux Values
  const { selectedIdProcessDetails, selectedEntityIdProcessDetails, processId } =
    useSelector(selectSelectedIdProcessDetails);
  // Use Effect
  useEffect(() => {
    if (isEntity) {
      const payload = getPayload();
      if (payload?.processId > 0) {
        dispatch(getEntityProcessDetailsById(payload || ({} as EntityPayloadType)));
      }
    } else {
      dispatch(getProcessDetailsById({ processId: PROCESS_IDS.INCOME_VERIFICATION }));
    }
  }, [isEntity]);

  useEffect(() => {
    if (isEntity && Object.keys(selectedEntityIdProcessDetails?.data)?.length > 0) {
      setSelectedIdProcessData(selectedEntityIdProcessDetails);
      setIdProcessStatus(selectedEntityIdProcessDetails?.data?.verification_status);
    } else if (!isEntity && Object.keys(selectedIdProcessDetails?.data)?.length > 0) {
      setSelectedIdProcessData(selectedIdProcessDetails);
    }
  }, [isEntity, selectedIdProcessDetails, selectedEntityIdProcessDetails]);

  // Methods
  const getPreview = () => {
    if (processId !== ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID) {
      const data: FilePreviewType[] = [];
      if (Object.keys(selectedIdProcessData)?.length > 0 && selectedIdProcessData?.data?.document_id_list?.length > 0) {
        selectedIdProcessData?.data?.document_id_list?.forEach((item: any) => {
          data.push({
            id: item?.doc_id || '',
            name: item?.doc_name || '',
            type: item?.mime_type || '',
            preview: item?.signed_url || '',
            size: item?.size_in_kb,
          });
        });
      }
      return data;
    } else {
      const data: FilePreviewType[] = [];
      if (selectedIdProcessData?.data?.tax_document) {
        const payload = {
          id: selectedIdProcessData?.data?.tax_document?.doc_id || '',
          name: selectedIdProcessData?.data?.tax_document?.doc_name || '',
          type: selectedIdProcessData?.data?.tax_document?.mime_type || '',
          preview: selectedIdProcessData?.data?.tax_document?.signed_url || '',
          size: selectedIdProcessData?.data?.tax_document?.size_in_kb,
        };
        data.push(payload);
      }
      return data;
    }
  };

  const getPayload = () => {
    const payload: EntityPayloadType = {
      processId: 0,
      entityUid: selectedEntity?.trellis_uid,
    };
    switch (processId) {
      case ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING:
        payload.processId = ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING;
        break;
      case ENTITY_PROCESS_IDS.FORMATION_DOCUMENT:
        payload.processId = ENTITY_PROCESS_IDS.FORMATION_DOCUMENT;
        break;
      case ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID:
        payload.processId = ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID;
        break;
    }
    return payload;
  };

  const getHeader = () => {
    switch (processId) {
      case ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING: {
        return t('instructionHeading', { ns: 'letterOfGoodStanding' });
      }
      case ENTITY_PROCESS_IDS.FORMATION_DOCUMENT: {
        return t('instructionHeading', { ns: 'formationDocument' });
      }
      case PROCESS_IDS.INCOME_VERIFICATION: {
        return t('instructionHeading', { ns: 'incomeVerification' });
      }
      case ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT: {
        return t('instructionHeading', { ns: 'entityOwnership' });
      }
      case ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID: {
        return t('instructionHeading', { ns: 'irsLetterEvidencing' });
      }
    }
  };

  //HTML
  return (
    <TCard>
      <Box sx={{ p: 3 }}>
        <Box className="flex-column-center">
          <Box sx={{ mb: 2 }} className={clsx(getIconByProcessId(processId), 'icon-size-60', classes.icon)} />
          <Box className="textColor-200 text-center text-h2 font-weight-semibold">{getHeader()}</Box>
          {processId === PROCESS_IDS.INCOME_VERIFICATION && (
            <Box sx={{ pt: theme.spacing(3) }} className="text-medium textColor-200 text-center">
              {t('incomeVerificationText', { ns: 'incomeVerification' })}
            </Box>
          )}
        </Box>
        <Box sx={{ mt: 5 }} className={!isMultiple ? 'flex-basic-center' : 'flex-basic-start'}>
          <Box className="textColor-200 text-medium font-weight-semibold">{t('documents')}</Box>
        </Box>
        <Box sx={{ mt: theme.spacing(5) }} className={!isMultiple ? 'flex-basic-center ' : 'flex-basic-start'}>
          <PreviewFileByType files={getPreview()} />
        </Box>
        {processId === ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID && (
          <Box className="w-100" sx={{ mt: 3, mb: 1 }}>
            <Box className="flex-basic-space-between">
              {selectedIdProcessData?.data?.verification_status && (
                <Box>
                  <Box className="textColor-300 text-small font-weight-regular">
                    {t('statusText', { ns: 'irsLetterEvidencing' })}
                  </Box>
                  <Box sx={{ pt: 1 }} className="textColor-200 text-medium font-weight-semibold">
                    {ID_PROCESS_STATUS_TEXT[idProcessStatus]}
                  </Box>
                </Box>
              )}
              <Box className="flex-basic-end">
                {selectedIdProcessData?.data?.verification_status === ID_PROCESS_STATUS.VERIFIED && (
                  <Box>
                    <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                      <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
                    </Box>
                  </Box>
                )}
                {selectedIdProcessData?.data?.verification_status === ID_PROCESS_STATUS.VERIFICATION_FAILED && (
                  <Box>
                    <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                      <span className="icon-error-warning error-color-state icon-size-24"></span>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {selectedIdProcessData?.data?.verification_status === ID_PROCESS_STATUS.VERIFIED && (
              <Box
                sx={{ mt: 3, px: 2, py: theme.spacing(3) }}
                className={clsx(classes.notification, 'customInfoBox textColor-300 text-small font-weight-regular')}
              >
                {t('infoText', { ns: 'irsLetterEvidencing' })}
              </Box>
            )}

            {selectedIdProcessData?.data?.verification_status === ID_PROCESS_STATUS.VERIFICATION_FAILED && (
              <Box className={classes.errorMessageBox} sx={{ mt: 3, px: 2, py: theme.spacing(3) }}>
                <Box className={'text-small textColor-200'}>{selectedIdProcessData?.data?.verification_remark}</Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </TCard>
  );
};

export default DocumentIdProcessVerification;
