import axios, { AxiosRequestConfig } from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { clearStorage, getAccessToken, getEmailVerificationToken, getResetPasswordToken } from '../utils/storage';
import { apiConfig } from './config';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as ROUTES from '../constants/routes';

const axiosInstance = axios.create({
  baseURL: apiConfig.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const useAxios = makeUseAxios({
  axios: axiosInstance,
  defaultOptions: {
    manual: true,
    useCache: false,
    ssr: false,
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (confA: AxiosRequestConfig) => {
    const conf = confA as TAxiosRequestConfig;
    const accessToken = getAccessToken();
    const emailVerificationToken = getEmailVerificationToken();
    const resetPasswordToken = getResetPasswordToken();

    if (!conf.headers) {
      conf.headers = {};
    }
    if (!conf.custom?.excludeTokenIdFromHeader) {
      conf.headers.authorization = `Bearer ${accessToken}`;
    } else if (conf.custom?.APITokenIdFromHeader) {
      conf.headers.authorization = `${emailVerificationToken}`;
    } else if (conf.custom?.resetPasswordTokenIdFromHeader) {
      conf.headers.authorization = `${resetPasswordToken}`;
    }
    return conf;
  },
  (error) => Promise.reject(error),
);

const errorHandler = (error: any) => {
  toast.error(`${error ? error : 'Something went wrong'}`);
  return Promise.reject({ ...error });
};

axiosInstance.interceptors.response.use(
  (response) =>
    // Edit response config
    response,
  (error) => {
    if (error?.response && error.response.data) {
      if (error.response.status === 401 || error.response.status === 403) {
        clearStorage();
        if (window.location.pathname.includes(ROUTES.LOGIN)) {
          errorHandler(error.response.data?.detail);
        } else {
          window.location.href = `${process.env.PUBLIC_URL}${ROUTES.LOGIN}`;
        }
      } else if (error.response.status === 500 || error.response.status === 404 || error.response.status === 400) {
        errorHandler(error.response.data?.detail);
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;

export interface TAxiosCustomConfig {
  excludeTokenIdFromHeader?: boolean;
  APITokenIdFromHeader?: boolean;
  resetPasswordTokenIdFromHeader?: boolean;
}

export interface TAxiosRequestConfig extends AxiosRequestConfig {
  custom?: TAxiosCustomConfig;
}

export async function apiGet<T = any>(resource: string, config?: TAxiosRequestConfig) {
  return axiosInstance.get<T>(resource, config);
}

export async function apiPost<T = any>(resource: string, data?: any, config?: TAxiosRequestConfig) {
  return axiosInstance.post<T>(resource, data, config);
}

export async function apiDelete<T = any>(resource: string, config?: TAxiosRequestConfig) {
  return axiosInstance.delete<T>(resource, config);
}

export async function apiPatch<T = any>(resource: string, data?: any, config?: TAxiosRequestConfig) {
  return axiosInstance.patch<T>(resource, data, config);
}

export async function apiPut<T = any>(resource: string, data?: any, config?: TAxiosRequestConfig) {
  return axiosInstance.put<T>(resource, data, config);
}

export async function apiUpload<T = any>(
  resource: string,
  data?: any,
  config?: TAxiosRequestConfig,
  progressCallback?: (progress: number) => void,
) {
  return axiosInstance.post<T>(resource, data, {
    ...config,
    headers: {
      ...config?.headers,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100).toString());
      progressCallback && progressCallback(uploadPercentage);
    },
  });
}
