import * as React from 'react';
import ContainerCard from '../../../../components/common/ContainerCard';
import { Box, Divider, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedIdProcessDetails,
  getProcessDetailsById,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { hexToRgbA, titleCase } from '../../../../utils/utils';
import { useEffect, useState } from 'react';
import { getProfileDetails, selectProfile } from '../../../../redux/feature/profile/profileSlice';
import {
  TButton,
  theme,
  PROCESS_IDS,
  REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS,
} from '../../../../components/myde-react-components';
import CommonSubscriptionScreen from '../../../../components/common/CommonSubscriptionScreen';
import { useTheme } from '../../../../providers/custom-theme-provider';
import clsx from 'clsx';
import { FaceWordSearch } from '../../../../components/myde-react-components/src/common/FaceWordSearch';
import { PORTAL } from '../../../../components/myde-react-components/src/constants/portal';
import { SectionTitleType } from '../../../../components/myde-react-components/src/types/faceWordSearchTypes';
import { membershipFlip } from '../../../../api/profile';
import { selectIdeProcessDetails } from '../../../../redux/feature/idProcess/biometricVerificationSlice';
import { ID_PROCESS_STATUS_TEXT } from '../../../../constants/constants';

interface StyleProps {
  currentThemes: Theme;
  primaryColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  words: {
    textAlign: 'left',
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textAlignment: {
    textAlign: 'right',
  },
  notification: {
    backgroundColor: ({ primaryColor }) => hexToRgbA(primaryColor, 0.08),
    borderRadius: '10px',
    padding: '12px',
  },
}));

export const BiometricVerification = () => {
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes.palette.primary.main;
  const styleProps = {
    currentThemes,
    primaryColor,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation(['common', 'faceWordSearch']);
  const dispatch = useDispatch();
  const { selectedIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);

  const { uploadedVideoToPreview } = useSelector(selectIdeProcessDetails);

  const [status, setStatus] = useState('');
  const { profileData } = useSelector(selectProfile);

  const items = selectedIdProcessDetails?.data?.biometric_verification_words;
  const headerData = {
    title: t('biometrics'),
  };
  const idProcessStatusCheck = [
    ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED,
    ID_PROCESS_STATUS_TEXT.VERIFICATION_NEEDS_MANUAL_REVIEW,
  ];
  const sectionTitles: SectionTitleType = {
    faceSearch: t('faceSearch', { ns: 'faceWordSearch' }),
    wordSearch: t('wordSearch', { ns: 'faceWordSearch' }),
    wordRecognized: t('wordRecognized', { ns: 'faceWordSearch' }),
  };

  useEffect(() => {
    setStatus(selectedIdProcessDetails?.status_label);
  }, [selectedIdProcessDetails]);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);

  const urlToDisplay = () => {
    if (
      uploadedVideoToPreview &&
      Object.keys(uploadedVideoToPreview)?.length === 0 &&
      selectedIdProcessDetails?.data?.video
    ) {
      return selectedIdProcessDetails?.data?.video?.signed_url;
    } else {
      return uploadedVideoToPreview?.preview;
    }
  };

  const getVerificationItems = () => {
    const result = items
      ?.map((item: string) => titleCase(item))
      .toString()
      .replace(/,/g, ', ');
    return result;
  };

  const handleVerify = async () => {
    const result = await membershipFlip();
    if (result?.id) {
      dispatch(getProcessDetailsById({ processId: PROCESS_IDS.BIOMETRIC_VERIFICATION }));
      dispatch(getProfileDetails());
    }
  };

  const renderRequirements = () => {
    return (
      <Box>
        <Box sx={{ px: 3, mt: 3 }}>
          <Box className="flex-column-center">
            <Box sx={{ mb: 3 }} className={clsx('icon-fing-biometrics icon-size-72', classes.icon)} />
            <Box className="textColor-200 text-h2 font-weight-semibold">{headerData.title}</Box>
          </Box>
        </Box>
        <Box>
          <Box className="flex-basic-center" sx={{ mt: 4, mb: 2, mx: 3 }}>
            <ReactPlayer width={'300px'} height={'144px'} url={urlToDisplay()} controls={true} />
          </Box>
          {/* Generated Words Section */}
          {selectedIdProcessDetails && !selectedIdProcessDetails?.result?.similarity_percentage && (
            <Box sx={{ mx: 3 }} className={classes.words}>
              <Box sx={{ py: 2 }}>
                <Box sx={{ mb: 1 }} className="textColor-300 text-small font-weight-regular">
                  {t('words')}
                </Box>
                <Box>
                  <Box className="textColor-200 text-medium font-weight-semibold">{getVerificationItems()}</Box>
                </Box>
              </Box>
            </Box>
          )}

          {selectedIdProcessDetails &&
            (selectedIdProcessDetails?.result?.similarity_percentage ||
              selectedIdProcessDetails?.result?.word_search_results?.words_found?.length > 0) && (
              <Box sx={{ mx: 3 }}>
                <FaceWordSearch
                  portalType={PORTAL.MYDE}
                  faceWordSearchData={selectedIdProcessDetails}
                  sectionTitles={sectionTitles}
                />
              </Box>
            )}

          {(profileData?.is_premium ||
            REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS.includes(selectedIdProcessDetails?.status)) && (
            <>
              <Divider />
              <Box sx={{ mx: 3, mb: 10 }}>
                {selectedIdProcessDetails?.status && (
                  <>
                    <Box sx={{ my: 2 }} className="flex-basic-space-between">
                      <Box className="flex-basic-space-between w-100 p-relative">
                        <Box>
                          <Box className="textColor-300 text-small font-weight-regular">{t('status')}</Box>
                          <Box sx={{ pt: 1 }} className="textColor-200 text-medium font-weight-semibold">
                            {status}
                          </Box>
                        </Box>
                        <Box>
                          {selectedIdProcessDetails?.status_label ===
                            ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS && (
                            <Box className="flex-basic-end">
                              <Box
                                sx={{ pt: theme.spacing(5) }}
                                className="p-relative small-sizes custom-progress-icon"
                              >
                                <Box className="progress-spinner sm-spinner"></Box>
                              </Box>
                            </Box>
                          )}
                          {selectedIdProcessDetails?.status_label === ID_PROCESS_STATUS_TEXT.VERIFIED && (
                            <Box>
                              <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                                <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
                              </Box>
                            </Box>
                          )}
                          {idProcessStatusCheck.includes(selectedIdProcessDetails?.status_label) && (
                            <Box>
                              <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                                <span className="icon-error-warning error-color-state icon-size-24"></span>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
          {!profileData?.is_premium &&
            !REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS.includes(selectedIdProcessDetails?.status) && (
              <Box sx={{ m: 3 }}>
                <CommonSubscriptionScreen
                  header={t('biometricNotVerified', { ns: 'faceWordSearch' })}
                  subtitle={t('biometricVerificationMessage')}
                  renderAction={
                    <TButton
                      btnText="Become a Premium Member"
                      btnWidthSize="button-w-240"
                      variant="contained"
                      onClick={handleVerify}
                    />
                  }
                />
              </Box>
            )}
        </Box>
      </Box>
    );
  };

  return <ContainerCard content={renderRequirements()}></ContainerCard>;
};
export default BiometricVerification;
