import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import {
  getCountOfNotifications,
  getSystemNotifications,
  getMerchantNotifications,
  getMerchantList,
  markNotificationsAsRead,
} from '../../../api/notifications';
import {
  MerchantListResultType,
  NotificationsResultType,
  NotificationsType,
} from '../../../components/myde-react-components/src/components/Notifications/notificationsType';

type NotificationState = {
  loading: boolean;
  error: boolean;
  notificationCount: number;
  notifications: NotificationsResultType[];
  notificationSet: NotificationsType;
  merchantNotificationSet: NotificationsType;
  merchantNotifications: NotificationsResultType[];
  isNotificationRead: boolean;
  merchantsList: MerchantListResultType[];
  specificNotification: NotificationsResultType;
};

const initialState: NotificationState = {
  loading: false,
  error: false,
  notificationCount: 0,
  notifications: [],
  notificationSet: <NotificationsType>{},
  merchantNotificationSet: <NotificationsType>{},
  merchantNotifications: [],
  isNotificationRead: false,
  merchantsList: [],
  specificNotification: <NotificationsResultType>{},
};

//selector
export const selectNotification = ({ notification }: RootState) => ({
  loading: notification.loading,
  notificationCount: notification.notificationCount,
  notifications: notification.notifications,
  notificationSet: notification.notificationSet,
  merchantNotificationSet: notification.merchantNotificationSet,
  merchantNotifications: notification.merchantNotifications,
  isNotificationRead: notification.isNotificationRead,
  merchantsList: notification.merchantsList,
  specificNotification: notification.specificNotification,
});

//actions
export const getNotificationsCount = createAsyncThunk('notifications/getNotificationsCount', async () => {
  return await getCountOfNotifications();
});

export const getUserSystemNotifications = createAsyncThunk(
  'notifications/getUserSystemNotifications',
  async (params: object) => {
    return await getSystemNotifications(params);
  },
);

export const getUserMerchantNotifications = createAsyncThunk(
  'notifications/getUserMerchantNotifications',
  async (params: object) => {
    return await getMerchantNotifications(params);
  },
);

export const getMerchantsList = createAsyncThunk('notifications/getMerchantsList', async () => {
  return await getMerchantList();
});

export const markAsRead = createAsyncThunk('notifications/markAsRead', async (data: object) => {
  return await markNotificationsAsRead(data);
});

//reducer
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setIsNotificationRead: (state, action) => {
      state.isNotificationRead = action?.payload;
    },
    clearMerchantNotifications: (state) => {
      state.merchantNotificationSet = {} as NotificationsType;
    },
    clearSystemNotifications: (state) => {
      state.notificationSet = {} as NotificationsType;
    },
    setSpecificNotification: (state, action) => {
      state.specificNotification = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsCount.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getNotificationsCount.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.notificationCount = action?.payload?.count;
        } else {
          state.error = true;
        }
      })
      .addCase(getNotificationsCount.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getUserSystemNotifications.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserSystemNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.notificationSet = action.payload;
          state.notifications = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getUserSystemNotifications.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getUserMerchantNotifications.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserMerchantNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.merchantNotificationSet = action.payload;
          state.merchantNotifications = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getUserMerchantNotifications.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getMerchantsList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMerchantsList.fulfilled, (state, action) => {
        if (action.payload) {
          state.merchantsList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getMerchantsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(markAsRead.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.error = false;
        } else {
          state.error = true;
        }
      })
      .addCase(markAsRead.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setIsNotificationRead, clearMerchantNotifications, clearSystemNotifications, setSpecificNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
