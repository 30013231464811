import React from 'react';
import { makeStyles } from '@mui/styles';
import TDialog from '../Dialog/Dialog';
import { BtnType, ClickHandlerType, DIALOG_WIDTH } from '../../constants/constants';
import { Box, Theme } from '@mui/material';
import { PORTAL } from '../../constants/portal';

interface AlertDialogProps {
  showDialog: boolean;
  content?: string;
  title?: string;
  extraContent?: any;
  noBtnTitle?: string;
  yesBtnTitle?: string;
  portalName?: string;
  isButtonDisabled?: boolean;
  dialogWidth?: string;
  onCancel: ClickHandlerType;
  onConfirm: ClickHandlerType;
  onClose?: ClickHandlerType;
}

interface StyleProps {
  matches: boolean;
  dialogWidth: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  confirmDialog: {
    '& .MuiBackdrop-root': {
      opacity: '0.5 !important',
    },
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        width: ({ matches, dialogWidth }) => (dialogWidth && matches ? dialogWidth : DIALOG_WIDTH.USER_PORTAL),
        maxWidth: ({ matches, dialogWidth }) => (dialogWidth && matches ? dialogWidth : DIALOG_WIDTH.USER_PORTAL),
        '& .MuiBox-root': {
          '& .MuiTypography-root': {
            padding: ({ matches }) => (matches ? '' : '20px 20px 12px 20px'),
            '& .text-h2': {
              font: ({ matches }) =>
                matches
                  ? 'normal 600 24px/36px Basier Circle !important'
                  : 'normal 600 18px/28px Basier Circle !important',
            },
            '& .MuiButtonBase-root': {
              padding: '0 !important',
            },
          },
        },
        '& .MuiDialogContent-root': {
          padding: ({ matches }) => (matches ? '' : '0px 20px !important'),
        },
      },
      '& .MuiPaper-root': {
        boxShadow: 'none !important',
        '& .MuiDialogActions-spacing': {
          display: 'flex !important',
        },
      },
    },
  },
});

export const AlertDialog = ({
  title = 'Confirmation',
  showDialog = false,
  noBtnTitle = 'No',
  yesBtnTitle = 'Yes',
  content = 'Your recent changes might get lost. Do you want to continue?',
  extraContent,
  portalName = 'myde',
  isButtonDisabled = false,
  dialogWidth = `${DIALOG_WIDTH.MERCHANT_PORTAL_SMALL}px !important`,
  onCancel,
  onConfirm,
  onClose,
}: AlertDialogProps) => {
  const matches = portalName === PORTAL.MERCHANT;
  const styleProps = {
    matches,
    dialogWidth,
  };
  const classes = useStyles(styleProps);

  const onCancelClick = () => {
    onCancel();
  };

  const onConfirmClick = () => {
    onConfirm();
  };

  const confDialogBtns: BtnType[] = [
    {
      id: 1,
      title: noBtnTitle,
      variant: 'text',
      buttonAction: onCancelClick,
      disabled: false,
    },
    {
      id: 2,
      title: yesBtnTitle,
      variant: 'contained',
      buttonAction: onConfirmClick,
      disabled: isButtonDisabled,
    },
  ];

  return (
    <TDialog
      className={classes.confirmDialog}
      open={showDialog}
      title={title}
      buttons={confDialogBtns}
      maxWidth={'xs'}
      portalName={portalName}
      onClose={onClose}
    >
      <Box className={matches ? 'text-large' : 'text-medium text-break-word'}>{content}</Box>
      {extraContent && <Box sx={{ mt: matches ? '' : 2 }}>{extraContent}</Box>}
    </TDialog>
  );
};

export default AlertDialog;
