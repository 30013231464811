import { apiGet, apiPatch, apiPost } from './axios';

export const getInvites = async (filter?: object) => {
  const res = await apiGet(`/invitation`, { params: filter });
  return res.data;
};

export const getStartedApplications = async (filters?: object) => {
  const res = await apiGet('/application', { params: filters });
  return res.data;
};

export const getIdRequirementsForInvitation = async (invitationId: string) => {
  const res = await apiGet(`/invitation/${invitationId}/id-requirement`);
  return res.data;
};

export const getMerchantIdProcessData = async (merchantId: number) => {
  const res = await apiGet(`/merchant/${merchantId}/id-process`);
  return res.data;
};

export const submitApplication = async (applicationId: string, data: { action: string; profiles: any }) => {
  const res = await apiPatch(`/application/${applicationId}`, data);
  return res.data;
};

export const submitEntityApplication = async (applicationId: string, data: { action: string; profiles: any }) => {
  const res = await apiPatch(`entity/application/${applicationId}`, data);
  return res.data;
};

export const updateInviteStatus = async (data: object) => {
  const res = await apiPost('/application', data);
  return res.data;
};

export const getEntityOwnerApplicationDetails = async (applicationId: string) => {
  const response = await apiGet(`/application/${applicationId}/owner`);
  return response.data;
};
