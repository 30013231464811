import { apiGet, apiPost, apiPut } from './axios';

export const idProcessMasterDetails = async () => {
  const res = await apiGet('/id-process-master');
  return res.data;
};

export const idProcessDetails = async () => {
  const res = await apiGet('/id-process');
  return res.data;
};

export const listCustomIdProcessWithStatus = async (filters: object) => {
  const res = await apiGet('/id-process/custom', { params: filters });
  return res.data;
};

export const listIdProcessCategories = async (data: object) => {
  const res = await apiGet('/id-process/category', { params: data });
  return res.data;
};

export const createCustomIdProcess = async (formData: object) => {
  const res = await apiPost('/id-process/custom', formData);
  return res.data;
};

export const editCustomIdProcess = async (processId: string, formData: object) => {
  const res = await apiPut(`/id-process/custom/${processId}`, formData);
  return res.data;
};

export const getIdProcessDetails = async (data: { id: string }) => {
  const res = await apiGet(`/id-process/custom/${data.id}`);
  return res.data;
};

export const createIdProcessCategory = async (data: object) => {
  const res = await apiPost('id-process/category', data);
  return res.data;
};
