import React from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useTheme } from '../../providers/custom-theme-provider';
import { MerchantUsersSelectionType } from '../../types/documentTypes';
import { hexToRgbA } from '../../utils/utils';
import { theme } from '../myde-react-components';

// Interfaces
interface MerchantListingProps {
  list: MerchantUsersSelectionType[];
  entity_uid?: number;
  header?: string;
  onListItemClick: (item: MerchantUsersSelectionType, entity_uid?: number) => void;
}

interface StyleProps {
  primaryColor: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  boldUnreadCount: {
    color: ({ primaryColor }) => `${primaryColor} !important`,
  },
  unReadCountContainer: {
    borderColor: ({ primaryColor }) => `${primaryColor} !important`,
    backgroundColor: ({ primaryColor }) => hexToRgbA(`${primaryColor}`, 0.08),
    minWidth: 80,
    width: 'fit-content',
  },
});

const MerchantListing = ({ list, header, entity_uid = 0, onListItemClick }: MerchantListingProps) => {
  // Constants
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes?.palette?.primary?.main;
  const styleProps: StyleProps = {
    primaryColor,
  };
  const classes = useStyles(styleProps);

  // HTML
  return (
    <Box>
      {header && <Box className="text-medium font-weight-semibold textColor-200">{header}</Box>}
      {list?.map((item: MerchantUsersSelectionType) => (
        <Box key={item?.merchant_id}>
          <Box
            sx={{ pl: 2 }}
            className="flex-basic-space-between cursorPointer borderBottom"
            onClick={() => onListItemClick(item, entity_uid)}
          >
            <Box sx={{ py: theme.spacing(5), pr: 1 }} className="flex-basic-center">
              <Box className="flex-basic-center" sx={{ mr: 2 }}>
                <img src={item?.logo} height="30px" width="30px" />
              </Box>
              <Box className="text-large font-weight-semibold textColor-200">{item?.merchant_name}</Box>
            </Box>
            {item?.total_unread_count > 0 && (
              <Box className={clsx('unreadCountBox flex-basic-center', classes.unReadCountContainer)}>
                <Box className={clsx(classes.boldUnreadCount, 'text-center')}>{`${item?.total_unread_count} new`}</Box>
              </Box>
            )}
          </Box>
          {list?.length > 1 && <Divider />}
        </Box>
      ))}
    </Box>
  );
};

export default MerchantListing;
