import * as React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IdProcessDetails } from '../../../../../types/dashboardTypes';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { TCard, theme } from '../../../../myde-react-components';
import { ID_PROCESS_STATUS_TEXT } from '../../../../../constants/constants';
export interface CardDetails {
  id: string;
  title: string;
  subTitle: string;
  handleClick: () => void;
  processStatus?: string;
}
export interface CardListProps {
  data: IdProcessDetails[];
  rounded?: boolean;
  handleClick: (idProcessDetails: IdProcessDetails) => void;
}
export interface StyleProps {
  rounded: boolean;
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
}));
export const CardList = ({ data, handleClick, rounded = false }: CardListProps) => {
  const { currentThemes } = useTheme();
  const styleProps = {
    rounded,
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation(['idDocumentFlow']);

  return (
    <>
      <Box className="p-relative" sx={{ mb: 2 }}>
        {data?.map((card: IdProcessDetails) => {
          return (
            <TCard key={card?.key} sx={{ p: 3, mt: 2 }} className="cursorPointer" onClick={() => handleClick(card)}>
              <Box className="flex-basic-space-between">
                <Box>
                  <Box sx={{ mb: 1 }} className="textColor-300 text-small">
                    {t('idType')}
                  </Box>
                  <Box className="p-relative">
                    <Box
                      sx={{ pb: currentThemes.spacing(1) }}
                      className="textColor-200 text-medium font-weight-semibold"
                    >
                      {t(card?.key)}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {card?.status_label === ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS && (
                    <Box className="flex-basic-end">
                      <Box sx={{ pb: theme.spacing(5) }} className="p-relative small-sizes custom-progress-icon">
                        <Box className="progress-spinner sm-spinner"></Box>
                      </Box>
                    </Box>
                  )}
                  {[ID_PROCESS_STATUS_TEXT.VERIFIED, ID_PROCESS_STATUS_TEXT.VERIFICATION_NEEDS_MANUAL_REVIEW].includes(
                    card?.status_label || '',
                  ) && (
                    <Box sx={{ pb: theme.spacing(5) }} className="p-relative">
                      <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
                    </Box>
                  )}
                  {card?.status_label === ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED && (
                    <Box sx={{ pb: theme.spacing(5) }} className="p-relative">
                      <span className="icon-error-warning error-color-state icon-size-24"></span>
                    </Box>
                  )}
                </Box>
              </Box>
            </TCard>
          );
        })}
      </Box>
    </>
  );
};
export default CardList;
