import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { t } from 'i18next';

import { ID_PROCESS_STATUS_COMPLETION_STATE, TButton, TCard, theme, TLoader } from '../../../myde-react-components';
import {
  selectSelectedIdProcessDetails,
  setAddressDataIndex,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { setCurrentStep, setIsEditAllowedFlag, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { setSelectedAddressVerificationDocument } from '../../../../redux/feature/idProcess/addressVerificationSlice';
import { AddressVerificationDocDetailsType } from '../../../../types/idProcessTypes';

const AddressVerification = () => {
  // Constants
  const dispatch = useDispatch();

  // Redux Values
  const { selectedIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);

  // State Values
  const [addressList, setAddressList] = useState([]);

  // Use Effects
  useEffect(() => {
    dispatch(setIsEditAllowedFlag(false));
  }, []);

  useEffect(() => {
    if (selectedIdProcessDetails?.data?.length > 0) {
      setAddressList(selectedIdProcessDetails?.data);
    }
  }, [selectedIdProcessDetails]);

  // Methods
  const setShowDetails = (index: number) => {
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[3].percent));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    dispatch(setAddressDataIndex(index));
  };
  const addNew = () => {
    dispatch(setAddressDataIndex(null));
    dispatch(setSelectedAddressVerificationDocument({} as AddressVerificationDocDetailsType));
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
  };

  // HTML
  return (
    <Box>
      <TLoader loading={false} />
      <Box>
        {addressList &&
          addressList?.map((addressData: any, index: number) => (
            <Box className="p-relative" sx={{ mb: 2 }} key={index}>
              <TCard sx={{ p: 3 }} className="cursorPointer" onClick={() => setShowDetails(index)}>
                <Box sx={{ mb: 1 }} className="textColor-200 text-large font-weight-semibold">
                  {addressData?.profile?.profile_name}
                </Box>
                <Box className="p-relative">
                  <Box sx={{ pb: theme.spacing(1) }} className="textColor-200 text-small">
                    {addressData?.address_document?.length}
                    {` ${t('documentSelectTitle', { ns: 'addressVerification' })}`}
                  </Box>
                </Box>
              </TCard>
            </Box>
          ))}
      </Box>
      <Box className="sticky-button text-center" sx={{ pb: theme.spacing(5), my: theme.spacing(9) }}>
        <TButton btnText={'Add New'} variant="contained" btnWidthSize="button-w-240" color="primary" onClick={addNew} />
      </Box>
    </Box>
  );
};

export default AddressVerification;
