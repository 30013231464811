import { ListDetailsResultType, SharedIdProcessDetailsResultType } from './../../../types/idProcessTypes';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getIdProcessShareList,
  getIdProcessShareListForEntity,
  getSharedIdProcessListForEntity,
} from '../../../api/idProcesses';
import { RootState } from '../../index';

type IdProcessShareType = {
  loading: boolean;
  error: boolean;
  merchantCount: number;
  sharedMerchantList: ListDetailsResultType[];
  selectedMerchant: ListDetailsResultType;
  sharedIdProcessDetails: SharedIdProcessDetailsResultType[];
  merchantCountForEntity: number;
  entitySharedMerchantList: ListDetailsResultType[];
  entitySharedIdProcessDetails: any;
  hideIdShareTab: boolean;
  customIdProcessId: string;
  showIdProcessSharedSection: boolean;
  sharedIdProcessData: ListDetailsResultType[];
};

const initialState: IdProcessShareType = {
  loading: false,
  error: false,
  merchantCount: 0,
  sharedMerchantList: [],
  selectedMerchant: {} as ListDetailsResultType,
  sharedIdProcessDetails: [],
  merchantCountForEntity: 0,
  entitySharedMerchantList: [],
  entitySharedIdProcessDetails: {},
  hideIdShareTab: false,
  customIdProcessId: '',
  showIdProcessSharedSection: false,
  sharedIdProcessData: [],
};

export const idProcessShareDetails = ({ idProcessShare }: RootState) => ({
  loading: idProcessShare.loading,
  error: idProcessShare.error,
  merchantCount: idProcessShare.merchantCount,
  selectedMerchant: idProcessShare.selectedMerchant,
  sharedMerchantList: idProcessShare.sharedMerchantList,
  sharedIdProcessDetails: idProcessShare.sharedIdProcessDetails,
  merchantCountForEntity: idProcessShare.merchantCountForEntity,
  entitySharedMerchantList: idProcessShare.entitySharedMerchantList,
  entitySharedIdProcessDetails: idProcessShare.entitySharedIdProcessDetails,
  hideIdShareTab: idProcessShare.hideIdShareTab,
  customIdProcessId: idProcessShare.customIdProcessId,
  showIdProcessSharedSection: idProcessShare.showIdProcessSharedSection,
  sharedIdProcessData: idProcessShare.sharedIdProcessData,
});

export const getIdProcessSharedCount = createAsyncThunk(
  'idProcessShare/getIdProcessSharedCount',
  async (params: object) => {
    return await getIdProcessShareList(params);
  },
);

export const getSharedMerchantsList = createAsyncThunk(
  'idProcessShare/getSharedMerchantsList',
  async (params: object) => {
    return await getIdProcessShareList(params);
  },
);

export const getSharedIdProcessDetails = createAsyncThunk(
  'idProcessShare/getSharedIdProcessDetails',
  async (params: object) => {
    return await getIdProcessShareList(params);
  },
);

export const getIdProcessSharedCountForEntity = createAsyncThunk(
  'idProcessShare/getIdProcessSharedCountForEntity',
  async (data: { entity_uid: number; params: object }) => {
    return await getIdProcessShareListForEntity(data?.entity_uid, data?.params);
  },
);

export const getSharedIdPorcessDetailsForEntity = createAsyncThunk(
  'idProcessShare/getSharedIdPorcessDetails',
  async (data: { entity_uid: number; params: object }) => {
    return await getSharedIdProcessListForEntity(data?.entity_uid, data?.params);
  },
);

export const idProcessShare = createSlice({
  name: 'idProcessShare',
  initialState,
  reducers: {
    setSelectedMerchant: (state, action) => {
      if (action.payload) {
        state.selectedMerchant = action.payload;
      }
    },
    setHideIdShareTab: (state, action) => {
      state.hideIdShareTab = action.payload;
    },
    setCustomIdProcessId: (state, action) => {
      state.customIdProcessId = action.payload;
    },
    setShowIdProcessSharedSection: (state, action) => {
      state.showIdProcessSharedSection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIdProcessSharedCount.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIdProcessSharedCount.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.merchantCount = action.payload?.count;
          state.sharedIdProcessData = action.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getIdProcessSharedCount.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSharedMerchantsList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSharedMerchantsList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.sharedMerchantList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getSharedMerchantsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSharedIdProcessDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSharedIdProcessDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.sharedIdProcessDetails = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getSharedIdProcessDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getIdProcessSharedCountForEntity.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIdProcessSharedCountForEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.merchantCountForEntity = action.payload?.count;
          state.entitySharedMerchantList = action.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getIdProcessSharedCountForEntity.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSharedIdPorcessDetailsForEntity.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSharedIdPorcessDetailsForEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.entitySharedIdProcessDetails = action.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getSharedIdPorcessDetailsForEntity.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setSelectedMerchant, setHideIdShareTab, setCustomIdProcessId, setShowIdProcessSharedSection } =
  idProcessShare.actions;

export default idProcessShare.reducer;
