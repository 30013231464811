import React from 'react';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette, theme } from './../components/myde-react-components';
import { useTheme } from '../providers/custom-theme-provider';

type AuthLayoutProp = {
  children: React.ReactNode;
};

export interface StyleProps {
  width: string;
  matches: boolean;
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  paper: {
    border: `1px solid ${colorPalette.border.base}`,
    padding: '0px 18px',
    height: 'auto',
  },
  layout: {
    padding: '12px',
    minHeight: '100vh',
    background: colorPalette.containerBack.base,
    width: '375px',
    minWidth: 'auto',
    borderTop: '6px solid',
    borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
}));

const AuthLayout = (props: AuthLayoutProp) => {
  const { children } = props;
  const matches = !useMediaQuery(theme.breakpoints.up('md'));
  const { currentThemes } = useTheme();
  const styleProps = {
    width: matches ? '766px' : 'auto',
    matches: matches,
    currentThemes,
  };

  const classes = useStyles(styleProps);

  return (
    <>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Grid className={classes.layout}>{children}</Grid>
      </Grid>
    </>
  );
};

export default AuthLayout;
