import { apiGet, apiPost, apiPatch, apiDelete } from './axios';

// Add new comment
export const addNewComment = async (data: { eventId: string; comment: string }) => {
  const reqData = { comment: data.comment };
  const res = await apiPost(`/kyc-aml/event/${data.eventId}/comment`, reqData);
  return res.data;
};

// Update Comment
export const updateComment = async (data: { eventId: string; commentId: string; comment: string }) => {
  const reqData = { comment: data.comment };
  const res = await apiPatch(`/kyc-aml/event/${data.eventId}/comment/${data.commentId}`, reqData);
  return res.data;
};

// Delete Comment
export const deleteComment = async (data: { eventId: string; commentId: string }) => {
  const res = await apiDelete(`/kyc-aml/event/${data.eventId}/comment/${data.commentId}`);
  return res.data;
};

// Get Event By ID
export const getEventById = async (data: { recordId: string; eventId: string; runId: string }) => {
  const res = await apiGet(`/kyc-aml/run/${data.runId}/record/${data.recordId}/event/${data.eventId}`);
  return res.data;
};

// Retrieve New and Deleted KYC records by Run ID
export const getHistoryById = async (data: { runId: string }) => {
  const res = await apiGet(`/kyc-aml/run/${data.runId}/diff`);
  return res.data;
};
