import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { TButton, theme, TPaper } from '../../components/myde-react-components';
import { selectUser, setToken } from '../../redux/feature/user/userSlice';
import * as ROUTES from '../../constants/routes';
import {
  verifyEmailHandler,
  resendVerifyEmailWithoutToken,
  selectDashboardDetails,
  verifyProfileEmailHandler,
} from '../../redux/feature/verifyEmail/verifyEmailSlice';
import { setEmailVerificationToken } from '../../utils/storage';
import AuthLayout from '../../layout/AuthLayout';
import VerifyEmailExpired from './VerifyEmailExpired';
import { useRouter } from '../../providers/custom-router-provider';
import almostTheregImage from '../../../src/common/assets/alomost-img.svg';
import verifiedImages from '../../../src/common/assets/success-img.svg';

export interface StyleProps {
  maxWidth: string;
}

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { t } = useTranslation('common');
  const { routeTo } = useRouter();
  const [searchParams] = useSearchParams();

  const url = location?.pathname;
  const token = searchParams.get('token');

  const { authToken } = useSelector(selectUser);
  const { tokenExpired, emailAlreadyVerified } = useSelector(selectDashboardDetails);

  const reVerifyEmail = () => {
    dispatch(resendVerifyEmailWithoutToken());
  };

  const toSignIn = () => {
    routeTo(ROUTES.LOGIN);
  };

  useEffect(() => {
    dispatch(setToken(authToken));
    setEmailVerificationToken(token || '');
    if (token) {
      if (url === ROUTES.VERIFY_EMAIL) {
        dispatch(verifyEmailHandler());
      } else if (url === ROUTES.VERIFY_PROFILE_EMAIL) {
        dispatch(verifyProfileEmailHandler());
      }
    }
  }, [token]);

  const getButton = () => {
    if (url !== ROUTES.VERIFY_PROFILE_EMAIL) {
      return (
        <Box className="flex-basic-center">
          <TButton btnWidthSize="button-w-240" onClick={toSignIn} variant="contained" btnText="Sign In" />
        </Box>
      );
    }
  };

  return (
    <>
      {tokenExpired || emailAlreadyVerified ? (
        <VerifyEmailExpired />
      ) : (
        <AuthLayout>
          <TPaper sx={{ p: 3 }}>
            {token && (
              <>
                <Box className="flex-column-center">
                  <Box sx={{ mb: 4 }}>
                    <Box sx={{ mt: 2 }} className="flex-basic-center">
                      <img className="almostThereSizes" src={verifiedImages} alt="verifiedImages" />
                    </Box>
                  </Box>
                  <Box className="flex-column-center" sx={{ mb: 2 }}>
                    <Box sx={{ pb: theme.spacing(3) }} className="text-h2 font-weight-semibold textColor-200">
                      {t('congratulations')}
                    </Box>
                    <Box className="text-h3 font-weight-semibold textColor-200 text-center">{t('emailVerified')}</Box>
                  </Box>
                  <Box className="text-medium textColor-200 text-center" sx={{ mb: 4 }}>
                    {t('consent')}
                  </Box>
                  <Box sx={{ mb: 1 }}>{getButton()}</Box>
                </Box>
              </>
            )}
            {!token && (
              <>
                <Box className="flex-column-center">
                  <Box sx={{ mb: 4 }}>
                    <Box sx={{ mt: 2 }} className="flex-basic-center">
                      <Box>
                        <img className="almostThereSizes" src={almostTheregImage} alt="almostTheregImage" />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 2 }} className="text-h2 font-weight-semibold textColor-200">
                    {t('verifyTitle')}
                  </Box>
                  <Box className="text-medium textColor-200 text-center" sx={{ mb: 2 }}>
                    {t('verifySubtitle')}
                  </Box>
                  <Box className="text-medium textColor-200" sx={{ mb: 4 }}>
                    {t('question')}
                  </Box>
                  <Box sx={{ mb: 1 }} className="flex-basic-center">
                    <TButton
                      btnWidthSize="button-w-240"
                      onClick={reVerifyEmail}
                      variant="contained"
                      btnText="Resend Email"
                    />
                  </Box>
                </Box>
              </>
            )}
          </TPaper>
        </AuthLayout>
      )}
    </>
  );
};

export default VerifyEmail;
