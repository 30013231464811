import React from 'react';
import { Box, AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface HeaderProps {
  children?: any;
  backgroundColor?: string;
}
const useStyles = makeStyles({
  childBox: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'end',
  },
  toolBarContainer: {
    '&.MuiToolbar-root': {
      minheight: '80px !important',
      height: '80px !important',
    },
  },
});

export const Header = ({ children, backgroundColor }: HeaderProps) => {
  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: { backgroundColor } }}>
        <Toolbar className={classes.toolBarContainer}>
          <Box className={classes.childBox}>{children}</Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
