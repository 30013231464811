import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, List, ListItem, Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette, TButton, TCard, theme } from '../../../myde-react-components';
import {
  KycAmlRecordType,
  KycAmlSummaryType,
  KycHistoryRecordType,
  KycHistorySummaryTye,
} from '../../../../types/idProcessTypes';
import {
  getKycHistoryById,
  kycAmlIdProcessDetails,
  resetUpdatedEvent,
  setKycRunId,
  resetKycRunId,
} from '../../../../redux/feature/idProcess/kycamlSlice';
import { typography } from '../../../../styles/style';
import { KYC_AML_RESULTS } from '../../../../constants/constants';
import KycAmlReports from './KycAmlRecords';
import { ClickHandlerType } from '../../../../types/commonTypes';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import { formatDate } from '../../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { setHideIdShareTab } from '../../../../redux/feature/idProcess/idProcessShareSlice';

// Interface
interface ResultListProps {
  fetchData: ClickHandlerType;
}

// Styles
const useStyles = makeStyles({
  listItem: {
    paddingLeft: '0px !important',
    borderBottom: `1px solid ${colorPalette.border.base}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  icon: {
    verticalAlign: 'middle',
  },
  historyText: {
    color: colorPalette.typoText.darken2,
  },
});

// Main Component
export const ResultList = ({ fetchData }: ResultListProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { kycData, historyRecord } = useSelector(kycAmlIdProcessDetails);
  const { t } = useTranslation(['common', 'kycAmlReport']);

  // State Values
  const [kycSummary, setKycSummary] = useState<Array<KycAmlSummaryType>>([]);
  const [kycRecord, setKycRecord] = useState<Array<KycAmlRecordType>>([]);
  const [historySummary, setHistorySummary] = useState<Array<KycHistorySummaryTye>>([]);
  const [rerunHistoryRecord, setRerunHistoryRecord] = useState({} as KycHistoryRecordType);
  const [secondaryRecords, setSecondaryRecords] = useState<Array<KycAmlRecordType>>([]);
  const [showRecordList, setRecordListFlag] = useState(false);
  const [showKycHistory, setShowKycHistory] = useState(false);
  const [showSecondaryChecks, setShowSecondaryChecks] = useState(false);
  const [numOfRecords, setRecordNum] = useState(0);
  const [historyDate, setHistoryDate] = useState('');
  const [historyRunId, setHistoryRunId] = useState('');
  const [activeStep, setActiveStep] = React.useState(0);

  // Use Effect
  useEffect(() => {
    dispatch(setHideIdShareTab(false));
  }, []);

  useEffect(() => {
    if (Object.keys(kycData).length > 0) {
      setKycSummary(kycData?.summary || []);
      setKycRecord(kycData?.records || []);
      setHistorySummary(kycData?.history_summary || []);
      setSecondaryRecords(kycData?.secondary_records);
      if (kycData?.records?.length > 0) {
        let count = 0;
        kycData.records.forEach((record) => {
          count += record?.details?.length || 0;
        });
        setRecordNum(count);
      }
    }
  }, [kycData]);

  useEffect(() => {
    if (activeStep === 0) dispatch(resetUpdatedEvent());
  }, [activeStep]);

  useEffect(() => {
    if (historyRecord?.records?.new_records?.length > 0) {
      setRerunHistoryRecord(historyRecord);
    }
  }, [historyRecord]);

  // Methods
  const getAction = (status: string) => {
    switch (status) {
      case KYC_AML_RESULTS.CLEAR:
        return <span className={clsx(classes.icon, 'icon-approved success-color-state actionIconFont-24')} />;
      case KYC_AML_RESULTS.CONSIDER:
        return <span className={clsx(classes.icon, 'icon-error-warning error-color-state actionIconFont-24')} />;
      default:
        return <span className={clsx(classes.icon, 'icon-approved success-color-state actionIconFont-24')} />;
    }
  };

  const hideReportsSection = () => {
    dispatch(setHideIdShareTab(false));
    dispatch(resetKycRunId());
    setRecordListFlag(false);
    setShowSecondaryChecks(false);
    setShowKycHistory(false);
    setActiveStep(0);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 1:
        return <KycAmlReports kycRecord={kycRecord} onBack={hideReportsSection} fetchData={fetchData} />;
      case 2:
        return <KycAmlReports secondaryRecords={secondaryRecords} onBack={hideReportsSection} fetchData={fetchData} />;
      case 3:
        return (
          <KycAmlReports
            kycHistory={rerunHistoryRecord}
            historyDate={historyDate}
            onBack={hideReportsSection}
            fetchData={fetchData}
            historyRunId={historyRunId}
          />
        );
    }
  };

  const handleRecords = () => {
    dispatch(setHideIdShareTab(true));
    setShowSecondaryChecks(false);
    setShowKycHistory(false);
    setRecordListFlag(true);
    setActiveStep(1);
  };

  const handleSecondary = () => {
    dispatch(setHideIdShareTab(true));
    setRecordListFlag(false);
    setShowKycHistory(false);
    setShowSecondaryChecks(true);
    setActiveStep(2);
  };

  const handleHistory = (item: KycHistorySummaryTye) => {
    const data = {
      runId: item?.kyc_run_id,
    };
    dispatch(getKycHistoryById(data));
    setHistoryDate(item?.date);
    setHistoryRunId(item?.kyc_run_id);
    dispatch(setKycRunId(item?.kyc_run_id));
    setShowSecondaryChecks(false);
    setRecordListFlag(false);
    setShowKycHistory(true);
    setActiveStep(3);
  };

  // HTML
  return (
    <>
      {(showRecordList || !showSecondaryChecks) &&
      (!showRecordList || showSecondaryChecks) &&
      (showRecordList || !showKycHistory) &&
      (showSecondaryChecks || !showKycHistory) &&
      (showKycHistory || !showRecordList) &&
      (showKycHistory || !showSecondaryChecks) ? (
        <>
          <TCard sx={{ pr: 2, pl: 3 }}>
            {kycSummary?.length > 0 ? (
              <Box>
                <List>
                  {kycSummary?.map((data: KycAmlSummaryType) => (
                    <ListItem
                      sx={{ py: theme.spacing(5) }}
                      key={data.id}
                      className={clsx({
                        [classes.listItem]: true,
                        ['text-medium font-weight-semibold textColor-200 text-center']: true,
                      })}
                      secondaryAction={<>{getAction(data.result)}</>}
                    >
                      <Box>{data.name}</Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : (
              <Box
                style={typography.descriptionDark}
                sx={{ p: 2 }}
                className="text-medium font-weight-semibold textColor-200 text-center"
              >
                {t('noData', { ns: 'kycAmlReport' })}
              </Box>
            )}
          </TCard>
          {(kycRecord?.length > 0 || secondaryRecords?.length > 0) && (
            <>
              <TCard sx={{ py: theme.spacing(5), pl: 3, pr: 3, mt: theme.spacing(5) }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="textColor-200 text-medium font-weight-semibold"
                >
                  <Grid item>
                    {t('recordFound', { ns: 'kycAmlReport' })} ({numOfRecords})
                  </Grid>
                  <Grid item>
                    {kycData?.records[0]?.details?.length > 0 && <TButton btnText="View" onClick={handleRecords} />}
                  </Grid>
                </Grid>
              </TCard>

              {kycData?.secondary_records && kycData?.secondary_records[0]?.details?.length > 0 && (
                <TCard sx={{ py: theme.spacing(5), pl: 3, pr: 3, my: theme.spacing(5) }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="textColor-200 text-medium font-weight-semibold"
                  >
                    {/* <Grid item>Secondary Checks ({numOfSecondary})</Grid> */}
                    <Grid item>{t('secondaryChecks', { ns: 'kycAmlReport' })} </Grid>
                    <TButton btnText="View" onClick={handleSecondary} />
                  </Grid>
                </TCard>
              )}

              {historySummary?.length > 0 && (
                <TCard
                  sx={{ py: theme.spacing(5), pl: theme.spacing(6), pr: theme.spacing(8), mt: theme.spacing(5), mb: 9 }}
                >
                  <Box sx={{ pb: theme.spacing(5) }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="textColor-300 text-large font-weight-semibold"
                    >
                      <Grid item>{t('reportHistory', { ns: 'kycAmlReport' })} </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                  <Box>
                    <Box>
                      {historySummary?.map((item: any, index: number) => (
                        <>
                          <Box key={item?.kyc_run_id} className="flex-basic-space-between">
                            <Box sx={{ pt: theme.spacing(5), pb: theme.spacing(1) }}>
                              <Box className="text-medium font-weight-semibold">{formatDate(item?.date)}</Box>
                              <Box sx={index < historySummary?.length - 1 ? { pb: theme.spacing(5) } : { pb: 0 }}>
                                {item?.new > 0 &&
                                  (item?.new === 1 ? (
                                    <Box
                                      sx={{ pb: theme.spacing(1) }}
                                      className={clsx('text-medium font-weight-semibold', classes.historyText)}
                                    >
                                      {t('newRecordFoundText', { ns: 'kycAmlReport' })} {`(${item?.new})`}
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{ pb: theme.spacing(1) }}
                                      className={clsx('text-medium font-weight-semibold', classes.historyText)}
                                    >
                                      {t('multipleNewRecords', { ns: 'kycAmlReport' })} {`(${item?.new})`}
                                    </Box>
                                  ))}

                                {item?.removed > 0 &&
                                  (item?.removed === 1 ? (
                                    <Box
                                      sx={{ pb: theme.spacing(1) }}
                                      className={clsx('text-medium font-weight-semibold', classes.historyText)}
                                    >
                                      {t('recordErased', { ns: 'kycAmlReport' })} {`(${item?.removed})`}
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{ pb: theme.spacing(1) }}
                                      className={clsx('text-medium font-weight-semibold', classes.historyText)}
                                    >
                                      {t('multipleRecordsErased', { ns: 'kycAmlReport' })} {`(${item?.removed})`}
                                    </Box>
                                  ))}

                                {item?.new === 0 && item?.removed === 0 && (
                                  <Box className={clsx('text-medium font-weight-semibold', classes.historyText)}>
                                    {t('noNewRecords', { ns: 'kycAmlReport' })}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            {(item?.new > 0 || item?.removed > 0) && (
                              <Box
                                className="cursorPointer flex-basic-space-between"
                                onClick={() => handleHistory(item)}
                              >
                                <ChevronRightIcon />
                              </Box>
                            )}
                          </Box>

                          {historySummary?.length > 1 && index < historySummary?.length - 1 && <Divider />}
                        </>
                      ))}
                    </Box>
                  </Box>
                </TCard>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Box>{getStepContent(activeStep)}</Box>
        </>
      )}
    </>
  );
};

export default ResultList;
