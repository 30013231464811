import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Theme } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { TPaper } from '../../..';
import { MEGABYTE_CONVERTER } from '../../constants/constants';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { getCommaSeperatedValues } from '../../utils/commonMethods';

type FilePreviewType = {
  id?: string;
  file?: any;
  name: string;
  size: number;
  type: string;
  preview: string;
  isDeleteAllowed: boolean;
};
interface FileUploadProps {
  title: string;
  acceptedFileFormat: string[];
  uploadedFiles: any[];
  supportedFileFormat: string[];
  maxFileSize: number;
  isMultiple: boolean;
  subtitle?: string;
  setSingleData?: any;
  setMultipleData?: (file: FilePreviewType[], oldFiles: FilePreviewType[]) => void;
}
interface StyleProps {
  currentThemes: Theme;
}

//styles
const useStyles = makeStyles<Theme, StyleProps>({
  image: {
    maxWidth: '100%',
    height: '10rem',
  },
  placeholder: {
    width: '13.25rem',
    height: '9.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  browseText: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },

  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadBtn: {
    marginTop: '1rem',
  },
  icon: {
    fontSize: '5rem !important',
  },
  supportedText: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

export const MultipleFileUpload = ({
  title,
  acceptedFileFormat,
  supportedFileFormat,
  uploadedFiles,
  maxFileSize,
  isMultiple,
  subtitle = 'Browse',
  setSingleData,
  setMultipleData,
}: FileUploadProps) => {
  // constants
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);

  // state variables
  const [files, setFiles] = useState([] as any[]);
  const [newFiles, setNewFiles] = useState([] as any[]);

  // Use Effects
  useEffect(() => {
    if (files?.length > 0) {
      if (!isMultiple && files[0]?.file && setSingleData) {
        setSingleData(files[0]?.file);
      }
    }
  }, [files]);
  useEffect(() => {
    if (newFiles?.length > 0 && isMultiple && setMultipleData) {
      setMultipleData(newFiles, uploadedFiles || []);
    }
  }, [newFiles]);

  // react dropzone
  const { getRootProps, getInputProps } = useDropzone({
    multiple: isMultiple,
    maxSize: maxFileSize,
    accept: acceptedFileFormat.toString(),
    onDropRejected: (rejections: any[]) => {
      if (!acceptedFileFormat.includes(rejections[0]?.file?.type)) {
        return toast.error(`Please upload a file with a valid format`);
      } else {
        return toast.error(`${t('multiFileSizeErrorMessage')} ${maxFileSize / MEGABYTE_CONVERTER} MB`);
      }
    },
    onDropAccepted: (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        !isMultiple ? singleFileAccept(acceptedFiles[0]) : multpileFileAccept(acceptedFiles);
      } else {
        toast.error('Please upload a file with a valid format');
      }
    },
  });

  const singleFileAccept = (acceptedFile: any) => {
    const newFile = new File([acceptedFile], acceptedFile?.name, {
      type: acceptedFile?.type?.includes('video') ? 'video/mp4' : acceptedFile?.type,
    });
    const fileData = Object.assign(newFile, { preview: URL.createObjectURL(newFile) });
    const payload = {
      file: newFile,
      ...fileData,
    };
    setFiles([payload]);
  };

  const multpileFileAccept = (acceptedFiles: any[]) => {
    const filesList: any[] = [];
    const oldFiles = uploadedFiles || [];
    acceptedFiles?.forEach((file) => {
      const fileData = Object.assign(file, { preview: URL.createObjectURL(file) });
      filesList.push(fileData);
    });
    const combineFiles = [...filesList, ...oldFiles];
    setFiles(combineFiles);
    setNewFiles(filesList);
  };

  // HTML
  return (
    <Box className="multipleFileUpload cursorPointer">
      <Box {...getRootProps({ className: 'dropzone' })} sx={{ m: '0 auto' }}>
        <TPaper elevation={0}>
          <input {...getInputProps()} />
          <Box className="flex-column-center">
            <Box className={clsx('icon-file-copy textColor-300', classes.icon)} />
            <Box>
              <Box className="text-center" sx={{ my: 1 }}>
                <Box className="text-center text-small">
                  <Box className={clsx('font-weight-semibold', classes.browseText)}>{title}</Box>
                  <Box className="textColor-200">Or</Box>
                  <Box className={clsx(classes.browseText, 'font-weight-semibold')}>{subtitle}</Box>
                </Box>
              </Box>
              <Box className="flex-basic-center textColor-300 text-extra-small font-weight-medium text-center">
                <Box>{`Supported file types: ${getCommaSeperatedValues(supportedFileFormat)}`}</Box>
              </Box>
            </Box>
          </Box>
        </TPaper>
      </Box>
    </Box>
  );
};
export default MultipleFileUpload;
