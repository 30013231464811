import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fontStyle } from '../../styles/style';

import * as ROUTES from '../../constants/routes';
import { selectEntity } from '../../redux/feature/entity/entitySlice';
import IdCardDetails from '../../components/id-card/IdCardDetails';
import { setBrandingData } from '../../redux/feature/user/userSlice';
import { TCommonAvatar } from '../../components/myde-react-components';

const EntityIdentity = () => {
  //const
  const dispatch = useDispatch();

  //redux val
  const { selectedEntity } = useSelector(selectEntity);

  //use Effect
  useEffect(() => {
    dispatch(setBrandingData(selectedEntity?.merchant?.branding));
  }, [selectedEntity]);

  const listItems = [
    {
      icon: 'icon-dashboard',
      label: 'My Dashboard',
      path: '',
    },
    {
      icon: 'icon-profile-users',
      label: 'My Identity',
      path: `${ROUTES.ENTITY_MY_IDENTITY}`,
    },
    {
      icon: 'icon-documents-file',
      label: 'Documents',
      path: '',
    },
  ];

  return (
    <Box>
      <Box>
        <TCommonAvatar
          imageUrl={selectedEntity?.avatar_url || ''}
          avatarText={`${selectedEntity?.name || ''}`}
          iconSize="200px"
          textSize={fontStyle.fontSize.font36.fontSize}
        />
      </Box>
      <IdCardDetails
        userName={selectedEntity?.name || ''}
        tId={selectedEntity?.trellis_uid || 0}
        list={listItems}
        accreditationStatus={selectedEntity?.details?.accreditation_status}
      />
    </Box>
  );
};

export default EntityIdentity;
