import React, { useState, useEffect } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIdeProcessDetails,
  getProcessDetailsById,
  setUploadedImageToPreview,
} from '../../../../redux/feature/idProcess/signVerificationSlice';
import {
  TButton,
  theme,
  TCard,
  FilePreviewType,
  REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS,
} from '../../../../components/myde-react-components';
import { useTranslation } from 'react-i18next';

import { ID_PROCESS_STATUS_TEXT, PROCESS_IDS } from '../../../../constants/constants';
import CommonSubscriptionScreen from '../../../../components/common/CommonSubscriptionScreen';
import { getProfileDetails, selectProfile } from '../../../../redux/feature/profile/profileSlice';
import clsx from 'clsx';
import { useTheme } from '../../../../providers/custom-theme-provider';
import { getIconByProcessId } from '../../../../utils/utils';
import { resetUploadedPhoto } from '../../../../redux/feature/idProcess/addressVerificationSlice';
import { membershipFlip } from '../../../../api/profile';
import PreviewFileByType from '../../../common/PreviewFileByType';

interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  imgPreview: {
    width: '100%',
    height: '250px',
  },
  imgPreviewContainer: {
    maxWidth: '100%',
    width: '100%',
  },
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
});

export const WetSignatureVerification = () => {
  //constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation(['wetSignature', 'common']);
  const dispatch = useDispatch();
  const { idProcessItem } = useSelector(selectIdeProcessDetails);
  const { profileData } = useSelector(selectProfile);
  const [idProcessStatus, setIdProcessStatus] = useState(idProcessItem?.status || '');
  const [document, setDocument] = useState([] as FilePreviewType[]);
  // Use Effects
  useEffect(() => {
    dispatch(getProfileDetails());
    dispatch(getProcessDetailsById(PROCESS_IDS.SIGN_VERIFICATION));
    dispatch(setUploadedImageToPreview({}));
  }, []);

  useEffect(() => {
    setIdProcessStatus(idProcessItem?.status_label);
    if (idProcessItem?.data?.signature?.doc_id) {
      const payload = {
        name: idProcessItem?.data?.signature?.doc_name || '',
        type: idProcessItem?.data?.signature?.mime_type || '',
        preview: idProcessItem?.data?.signature?.signed_url || '',
        size: idProcessItem?.data?.signature?.size_in_kb || '',
      };
      setDocument([payload]);
    }
  }, [idProcessItem]);

  // Methods
  const handleVerify = async () => {
    const result = await membershipFlip();
    if (result?.id) {
      dispatch(getProcessDetailsById({ processId: PROCESS_IDS.SIGN_VERIFICATION }));
      dispatch(getProfileDetails());
      dispatch(resetUploadedPhoto());
    }
  };
  return (
    <TCard sx={{ p: 3 }}>
      <Box className="flex-column-center">
        <Box
          sx={{ pb: theme.spacing(5) }}
          className={clsx(getIconByProcessId(PROCESS_IDS.SIGN_VERIFICATION), classes.icon, 'icon-size-72')}
        />
        <Box className="text-center textColor-200 text-h2 font-weight-semibold">
          {t('instructionHeading', { ns: 'wetSignature' })}
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Box>
          <Box className="w-100 flex-basic-center">
            <Box
              sx={{ my: 2, py: theme.spacing(2) }}
              className="verificationItem text-medium text-center textColor-200"
            >
              {idProcessItem?.data?.verification_string}
            </Box>
          </Box>
          <Box className="text-small text-center textColor-300" sx={{ mt: 2 }}>
            {t('uploadedDocument', { ns: 'wetSignature' })}
          </Box>
          <Box sx={{ pb: theme.spacing(5) }}>
            <PreviewFileByType files={document} />
          </Box>
        </Box>
      </Box>
      {!profileData?.is_premium && !REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS.includes(idProcessItem?.status) && (
        <Box sx={{ mt: 3 }}>
          <CommonSubscriptionScreen
            header={t('signatureNotVerified', { ns: 'wetSignature' })}
            subtitle={t('signVerificationMessage', { ns: 'wetSignature' })}
            renderAction={
              <TButton
                btnText="Become a Premium Member"
                onClick={handleVerify}
                btnWidthSize="button-w-240"
                variant="contained"
              />
            }
          />
        </Box>
      )}
      <Box sx={{ pt: 3, mb: 5 }} className="flex-basic-center">
        <Box className="flex-basic-space-between w-100">
          <Box>
            <Box className="textColor-300 text-small font-weight-regular">{t('status', { ns: 'wetSignature' })} </Box>
            <Box sx={{ pt: 1 }} className="textColor-200 text-medium font-weight-semibold">
              {idProcessStatus}
            </Box>
          </Box>
          {idProcessItem?.status_label === ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS && (
            <Box className="flex-basic-end">
              <Box sx={{ pt: theme.spacing(5) }} className="p-relative small-sizes custom-progress-icon">
                <Box className="progress-spinner sm-spinner"></Box>
              </Box>
            </Box>
          )}
          {idProcessItem?.status_label === ID_PROCESS_STATUS_TEXT.VERIFIED && (
            <Box>
              <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
              </Box>
            </Box>
          )}
          {idProcessItem?.status_label === ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED && (
            <Box>
              <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                <span className="icon-error-warning error-color-state icon-size-24"></span>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </TCard>
  );
};
export default WetSignatureVerification;
