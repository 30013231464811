import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import {
  processDetailsById,
  verificationItemById,
  postUploadedDoc,
  draftOrCreateIdProcess,
} from '../../../api/idProcesses';
import {
  SignatureIdProcessType,
  VerificationItemType,
  UploadedImageToPreviewType,
} from '../../../types/idProcessTypes';
import { FileType } from '../../../types/documentTypes';

type SignatureState = {
  idProcessItem: SignatureIdProcessType;
  verificationItem: VerificationItemType;
  loading: boolean;
  error: boolean;
  errorObj: any;
  uploadedImage: FileType;
  uploadedImageToPreview: UploadedImageToPreviewType;
};

const initialState: SignatureState = {
  idProcessItem: <SignatureIdProcessType>{},
  loading: false,
  error: false,
  errorObj: {},
  uploadedImage: <FileType>{},
  uploadedImageToPreview: <UploadedImageToPreviewType>{},
  verificationItem: <VerificationItemType>{},
};

export const selectIdeProcessDetails = ({ signVerification }: RootState) => ({
  // here we will get data for id process item
  idProcessItem: signVerification.idProcessItem,
  verificationItem: signVerification.verificationItem,
  uploadedImage: signVerification.uploadedImage,
  uploadedImageToPreview: signVerification.uploadedImageToPreview,
  loading: signVerification.loading,
});

export const getProcessDetailsById = createAsyncThunk(
  'signVerification/getProcessDetailsById',
  async (processId: any) => {
    return await processDetailsById(processId);
  },
);

export const getVerificationItem = createAsyncThunk(
  'signVerification/getVerificationItem',
  async (processId: string) => {
    return await verificationItemById(processId);
  },
);

export const setUploadedImage = createAsyncThunk('signVerification/setUploadedImage', async (image: any) => {
  return await postUploadedDoc(image);
});

export const createSignVerification = createAsyncThunk(
  'signVerification/createSignVerification',
  async (formdata: object) => {
    return await draftOrCreateIdProcess(formdata);
  },
);

export const signVerificationSlice = createSlice({
  name: 'signVerification',
  initialState,
  reducers: {
    setUploadedImageToPreview: (state, action) => {
      state.uploadedImageToPreview = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProcessDetailsById.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.idProcessItem = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getProcessDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getVerificationItem.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getVerificationItem.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.verificationItem = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getVerificationItem.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(setUploadedImage.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setUploadedImage.fulfilled, (state, image: any) => {
        state.error = false;
        state.loading = false;
        if (image.payload) {
          state.uploadedImage = image.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setUploadedImage.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(createSignVerification.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createSignVerification.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          // toast.success(action.payload?.detail);
        } else {
          state.error = true;
        }
      })
      .addCase(createSignVerification.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setUploadedImageToPreview } = signVerificationSlice.actions;

export default signVerificationSlice.reducer;
