import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../..';
import { apiGet, apiPost } from '../../../api/axios';
import { sendLinkForForgotPassword } from '../../../api/forgot-password';
import { login, deleteData, bankVaultLogin } from '../../../api/login';
import { verifyEnteredEmail } from '../../../api/verifyEmail';
import { RESET_PASSWORD_TEXTS } from '../../../constants/constants';
import { ValidateEmailType } from '../../../types/commonTypes';
import { IBranding } from '../../../types/styleTypes';
import { UserData, IInviteData, ITAppInviteData, IMerchantInviteData } from '../../../types/userTypes';
import { setAccessToken, setBranding } from '../../../utils/storage';

const initialState = {
  userData: <UserData>{},
  authToken: '',
  loading: false,
  error: false,
  branding: <IBranding>{},
  userBranding: <IBranding>{},
  errorObj: <any>{},
  inviteData: <IInviteData>{},
  merchantInviteData: <IMerchantInviteData>{},
  tAppInviteData: <ITAppInviteData>{},
  signUpComplete: false,
  loginSuccess: false,
  showEntitySelectionList: false,
  showEntityDetails: false,
  customErrorMessage: '',
  linkSentForForgotPassword: false,
  isAllDataDeleted: false,
  isPasswordChangedSuccessfully: false,
  resetTokenExpired: false,
  resetTokenAlreadyUsed: false,
  email: '',
  userRoles: [] as string[],
};

export const selectUser = ({ user }: RootState) => ({
  userData: user.userData,
  authToken: user.authToken,
  branding: user.branding,
  userBranding: user.userBranding,
  loading: user.loading,
  error: user.error,
  inviteData: user.inviteData,
  merchantInviteData: user.merchantInviteData,
  tAppInviteData: user.tAppInviteData,
  signUpComplete: user.signUpComplete,
  loginSuccess: user.loginSuccess,
  showEntitySelectionList: user.showEntitySelectionList,
  showEntityDetails: user.showEntityDetails,
  customErrorMessage: user.customErrorMessage,
  errorObj: user.errorObj,
  linkSentForForgotPassword: user.linkSentForForgotPassword,
  isAllDataDeleted: user.isAllDataDeleted,
  isPasswordChangedSuccessfully: user.isPasswordChangedSuccessfully,
  resetTokenExpired: user.resetTokenExpired,
  resetTokenAlreadyUsed: user.resetTokenAlreadyUsed,
  email: user.email,
  userRoles: user.userRoles,
});

export const getLoginDetails = createAsyncThunk('user/getLoginDetails', async (formData: object) => {
  return await login(formData);
});

export const deleteUserData = createAsyncThunk('user/deleteUserData', async () => {
  return await deleteData();
});

export const loginUsingBankVault = createAsyncThunk(
  'user/loginUsingBankVault',
  async (formData: { session: string; token: string; secret: string }) => {
    return await bankVaultLogin(formData);
  },
);

export const getRegisterDetails = createAsyncThunk(
  'user/getRegisterDetails',
  async (formData: object, { rejectWithValue }) => {
    try {
      const res = await apiPost('/sign-up', formData);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const validateEmail = createAsyncThunk(
  'validateEmail/validateApplicationEmail',
  async (data: ValidateEmailType, { rejectWithValue }) => {
    try {
      const res = await verifyEnteredEmail(data);
      return res;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return error?.response?.status === 409 ? rejectWithValue(error?.response?.data) : '';
    }
  },
);

export const getInviteViaToken = createAsyncThunk(
  'user/getInviteViaToken',
  async (token: string, { rejectWithValue }) => {
    try {
      const res = await apiGet(`/merchant/invite/${token}`);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (formData: { email: string }) => {
  return sendLinkForForgotPassword(formData);
});

export const verifyResetPasswordToken = createAsyncThunk(
  'user/verifyResetPasswordToken',
  async (token: string, { rejectWithValue }) => {
    try {
      const res = await apiGet(`/reset-password/${token}`, {
        custom: {
          excludeTokenIdFromHeader: true,
          APITokenIdFromHeader: false,
          resetPasswordTokenIdFromHeader: true,
        },
      });
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.authToken = action.payload;
    },
    setData: (state, action) => {
      state.userData = action.payload;
    },
    resetData: (state) => {
      state.userData = <UserData>{};
      state.authToken = '';
      state.branding = <IBranding>{};
    },
    setBrandingData: (state, action) => {
      state.branding = action.payload;
    },
    resetSignUpComplete: (state) => {
      state.signUpComplete = false;
    },
    setEntitySelectionFlag: (state, action) => {
      state.showEntitySelectionList = action.payload;
    },
    setEntityDetailFlag: (state, action) => {
      state.showEntityDetails = action.payload;
    },
    resetCustomErrorMessage: (state, action) => {
      state.customErrorMessage = action.payload;
    },
    resetErrorFlag: (state, action) => {
      state.error = action.payload;
    },
    setLinkSetForgotPassword: (state, action) => {
      state.linkSentForForgotPassword = action.payload;
    },
    setLoginFlag: (state, action) => {
      state.loginSuccess = action.payload;
    },
    resetIsDataDeleted: (state) => {
      state.isAllDataDeleted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getLoginDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          if (action.payload.access) {
            state.loginSuccess = true;
          }
          state.userData = action.payload;
          setAccessToken(action.payload.access);
          state.authToken = action.payload.access;
          state.userBranding = action.payload.merchant?.branding;
          state.branding = action.payload.merchant?.branding || action.payload.t_app?.branding;
          setBranding(action.payload.merchant?.branding || action.payload.t_app?.branding);
        } else {
          state.error = true;
        }
      })
      .addCase(getLoginDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getRegisterDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getRegisterDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.signUpComplete = true;
        } else {
          state.error = true;
        }
      })
      .addCase(getRegisterDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getInviteViaToken.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInviteViaToken.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.inviteData = action.payload;
          if (action.payload?.merchant && Object.keys(action.payload.merchant).length !== 0) {
            state.merchantInviteData = action.payload.merchant;
          } else {
            state.tAppInviteData = action.payload.t_app;
          }
          setBranding(action.payload.merchant?.branding || action.payload.t_app?.branding);
          state.branding = action.payload.merchant?.branding || action.payload.t_app?.branding;
        } else {
          state.error = true;
        }
      })
      .addCase(loginUsingBankVault.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(loginUsingBankVault.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          if (action.payload.access) {
            state.loginSuccess = true;
          }
          state.userData = action.payload;
          setAccessToken(action.payload.access);
          state.authToken = action.payload.access;
          state.branding = action.payload.merchant?.branding || action.payload.t_app?.branding;
          setBranding(action.payload.merchant?.branding || action.payload.t_app?.branding);
        } else {
          state.error = true;
        }
      })
      .addCase(loginUsingBankVault.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getInviteViaToken.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        toast.error(action?.payload?.detail || 'User with same email is already signed up');
      })
      .addCase(validateEmail.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.customErrorMessage = action?.payload?.detail;
      })
      .addCase(validateEmail.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(validateEmail.pending, (state) => {
        state.loading = true;
        state.error = true;
      })
      .addCase(deleteUserData.pending, (state) => {
        state.loading = true;
        state.error = true;
      })
      .addCase(deleteUserData.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.isAllDataDeleted = true;
        } else {
          state.error = true;
        }
      })
      .addCase(deleteUserData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
        state.linkSentForForgotPassword = true;
      })
      .addCase(forgotPassword.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.customErrorMessage = action?.payload?.detail;
      })
      .addCase(verifyResetPasswordToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyResetPasswordToken.fulfilled, (state, action) => {
        state.resetTokenExpired = false;
        state.resetTokenAlreadyUsed = false;
        state.email = action?.payload?.email;
        state.loading = false;
      })
      .addCase(verifyResetPasswordToken.rejected, (state, action: any) => {
        state.loading = false;
        if (action?.payload?.detail === RESET_PASSWORD_TEXTS.TOKEN_EXPIRED) {
          state.resetTokenExpired = true;
        } else if (action?.payload?.detail === RESET_PASSWORD_TEXTS.TOKEN_ALREADY_USED) {
          state.resetTokenAlreadyUsed = true;
        } else {
          state.resetTokenExpired = true;
        }
      });
  },
});

const { actions, reducer } = userSlice;
export const {
  setData,
  resetData,
  setBrandingData,
  setToken,
  resetSignUpComplete,
  setEntitySelectionFlag,
  setEntityDetailFlag,
  resetCustomErrorMessage,
  resetErrorFlag,
  setLinkSetForgotPassword,
  setLoginFlag,
  resetIsDataDeleted,
} = actions;
export default reducer;
