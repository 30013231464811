import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { theme, TLoader, TSearchComponent } from '../../../components/myde-react-components';
import { useTranslation } from 'react-i18next';
import * as ROUTES from '../../../constants/routes';
import { useRouter } from '../../../providers/custom-router-provider';

import {
  clearFileList,
  getApplicationMerchants,
  getDocCategories,
  getFolderFiles,
  resetMerchantId,
  selectDocuments,
  setIsSearching,
} from '../../../redux/feature/documents/documentsSlice';
import {
  MerchantDocumentType,
  MerchantEntitiesSelectionType,
  MerchantObjectResultType,
  MerchantUsersSelectionType,
} from '../../../types/documentTypes';
import SearchListing from '../search-listing/SearchListing';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';
import MerchantListingIndex from '../../../components/common/MerchantListingIndex';

const MerchantDocuments = () => {
  // Constants
  const { routeTo } = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation('userViewMessage');

  // Redux values
  const { loading, isSearching, fileList, merchantList } = useSelector(selectDocuments);
  const { selectedEntity } = useSelector(selectEntity);

  // State Variables
  const [searchValue, setSearchValue] = useState('');
  const [resetSearchFilter, setResetSearchFilter] = useState(false);
  const [filesList, setFilesList] = useState([] as MerchantDocumentType[]);
  const [showViewAllBtn, setShowViewAllBtn] = useState(false);
  const [merchantListForUsers, setMerchantListForUsers] = useState([] as MerchantUsersSelectionType[]);
  const [merchantListForEntities, setMerchantListForEntities] = useState([] as MerchantEntitiesSelectionType[]);
  const [isEntityContext, setIsEntityContext] = useState(false);

  // Use Effects
  useEffect(() => {
    const params = selectedEntity?.id
      ? { entity_uid: selectedEntity?.trellis_uid, has_sent_document: true }
      : { has_sent_document: true };
    setIsEntityContext(!!selectedEntity?.id);
    dispatch(getApplicationMerchants(params));
    dispatch(resetMerchantId());
  }, [selectedEntity]);

  useEffect(() => {
    const users = getMerchantDetailList(merchantList?.user, false);
    setMerchantListForUsers(users);
    const entities = merchantList?.entities?.map((item) => {
      return {
        entity_name: item?.name,
        entity_trellis_uid: item?.trellis_uid,
        merchants: getMerchantDetailList(item?.merchants, true),
      };
    });
    setMerchantListForEntities(entities);
    if (merchantList?.user?.length > 0 || merchantList?.entities?.length > 0) {
      dispatch(getDocCategories());
    }
    backSearchHandler();
  }, [merchantList]);

  useEffect(() => {
    setFilesList(fileList);
  }, [fileList]);

  // Methods
  const getMerchantDetailList = (data: MerchantObjectResultType[], isEntity: boolean) => {
    return data?.map((item) => {
      return {
        merchant_id: item?.merchant?.id,
        merchant_name: item?.merchant?.name,
        logo: item?.merchant?.branding?.logo_thumbnail_url,
        total_unread_count: item?.document_unread_count,
        is_entity: isEntity,
      };
    });
  };

  const searchHandler = (value: string) => {
    setSearchValue(value);
    if (value) {
      dispatch(getFolderFiles({ name: value, limit: 4 }));
      setShowViewAllBtn(true);
    } else {
      dispatch(clearFileList());
      setShowViewAllBtn(false);
    }
  };

  const searchBoxClickHandler = () => {
    dispatch(setIsSearching(true));
    setResetSearchFilter(false);
    setShowViewAllBtn(false);
  };

  const backSearchHandler = () => {
    setResetSearchFilter(true);
    dispatch(setIsSearching(false));
    dispatch(clearFileList());
  };

  const viewAllBtnClickHandler = () => {
    dispatch(getFolderFiles({ name: searchValue }));
    setShowViewAllBtn(false);
  };

  const handleMerchantClick = (item: MerchantUsersSelectionType) => {
    const filters = {
      merchant_id: item?.merchant_id,
    };
    const query = { id: item?.merchant_id, merchant: item?.merchant_name, section: 'Documents' };
    dispatch(getDocCategories(filters));
    routeTo(ROUTES.DOCUMENTS_CATEGORY, true, item?.merchant_name, query);
  };

  // HTML
  return (
    <>
      <Box>
        <TLoader loading={loading} />
        <Box className="flex-basic-start" sx={{ px: 3, pt: 3 }}>
          {isSearching && (
            <Box sx={{ pr: 1, pt: theme.spacing(1) }}>
              <span
                className="icon-arrow-lefts textColor-200 cursorPointer actionIconFont-24"
                onClick={backSearchHandler}
              ></span>
            </Box>
          )}
          <Box className="w-100" onClick={searchBoxClickHandler}>
            <TSearchComponent
              hideResetButton={true}
              placeholder="Search"
              onSearchUpdate={searchHandler}
              hasExtraFilters={true}
              resetSearchFilter={resetSearchFilter}
            />
          </Box>
        </Box>
        {isSearching ? (
          <Box className="scrollBox">
            <SearchListing
              filesList={filesList}
              showCategory={true}
              showYearMonth={true}
              showViewAllBtn={showViewAllBtn}
              viewAllBtnClickHandler={viewAllBtnClickHandler}
            />
          </Box>
        ) : (
          <Box sx={{ px: 3 }}>
            {merchantListForUsers?.length > 0 || merchantListForEntities?.length > 0 ? (
              <MerchantListingIndex
                merchantUserList={merchantListForUsers}
                merchantEntityList={merchantListForEntities}
                showHeader={isEntityContext}
                handleClick={handleMerchantClick}
              />
            ) : (
              <Box sx={{ pt: 5 }}>
                <Box className="text-h3 font-weight-semibold textColor-300 text-center">
                  {t('nodataAvailable', { ns: 'userViewMessage' })}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default MerchantDocuments;
