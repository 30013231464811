import * as React from 'react';
import ContainerCard from '../../../common/ContainerCard';
import { Divider, Box, Grid } from '@mui/material';
import ReactPlayer from 'react-player';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { fontStyle } from '../../../../styles/style';
import { selectIdeProcessDetails } from '../../../../redux/feature/idProcess/biometricVerificationSlice';
import { useTranslation } from 'react-i18next';
import { titleCase } from '../../../../utils/utils';
import { useTheme } from '../../../../providers/custom-theme-provider';
import { TButton } from '../../../myde-react-components';
interface ConfirmationProps {
  renderActions: React.ReactNode;
  onStartOver: (step: number) => void;
  onSubmit: () => void;
}

const useStyles = makeStyles({
  preview: {
    width: 500,
    height: 320,
  },
  words: {
    textAlign: 'left',
  },
  verificationWords: {
    display: 'flex',
    flexDirection: 'row',
  },
  startOverText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  startOver: {
    ...fontStyle.fontWeight.font600,
    ...fontStyle.fontSize.font14,
    fontFamily: 'Montserrat',
    cursor: 'pointer',
  },
});

export const BiometricVerification = ({ onStartOver, onSubmit }: ConfirmationProps) => {
  const classes = useStyles();
  const { currentThemes } = useTheme();
  const { t } = useTranslation('biometricVerification');
  const { uploadedVideoToPreview, idProcessItem, verificationItem } = useSelector(selectIdeProcessDetails);

  const items = verificationItem?.verification_item;

  const urlToDisplay = () => {
    if (Object.keys(uploadedVideoToPreview)?.length === 0 && idProcessItem?.data?.video) {
      return idProcessItem?.data?.video?.signed_url;
    } else {
      return uploadedVideoToPreview?.preview;
    }
  };

  const getVerificationItems = () => {
    const result =
      items &&
      items
        .map((item: string) => titleCase(item))
        .toString()
        .replace(/,/g, ', ');
    return result;
  };

  const goBack = () => {
    onStartOver(0);
  };
  const onSubmitVerification = () => {
    onSubmit();
  };

  const renderRequirements = () => {
    return (
      <>
        <Box>
          <Box className="text-center" sx={{ p: 2 }}>
            <Box className="text-center textColor-300 text-large font-weight-semibold">{t('biometricConfirm')}</Box>
          </Box>
          <Divider />
          <Box className={classes.words}>
            <Box sx={{ py: 2 }}>
              <Box sx={{ px: 2, pb: 1 }} className="textColor-300 text-small font-weight-regular">
                {t('wordstext', { ns: 'biometricVerification' })}
              </Box>
              <Box sx={{ px: 2 }}>
                <Box className="textColor-200 text-medium font-weight-semibold">{getVerificationItems()}</Box>
              </Box>
            </Box>
            <Divider />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 2 }}>
            <Box className={classes.startOverText}>
              <Box className="textColor-300 text-small font-weight-regular" sx={{ p: 2 }}>
                {t('uploadVideo', { ns: 'biometricVerification' })}
              </Box>
              <Box
                sx={{ p: 2, color: currentThemes.palette.primary.main }}
                className="cursorPointer text-medium font-weight-semibold"
              >
                <Box onClick={goBack}>{t('startOver')}</Box>
              </Box>
            </Box>
            <Box className="flex-column-center" sx={{ mt: 3 }}>
              <ReactPlayer width={'300px'} height={'144px'} url={urlToDisplay()} controls={true} />
            </Box>
            <Box sx={{ mt: 3 }} className="text-center textColor-200 text-medium">
              {t('instructionTitle', { ns: 'biometricVerification' })}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <ContainerCard content={renderRequirements()}>
      <Box className="flex-basic-center">
        <TButton btnText="Submit" btnWidthSize="button-w-240" variant="contained" onClick={onSubmitVerification} />
      </Box>
    </ContainerCard>
  );
};
export default BiometricVerification;
