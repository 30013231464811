import React from 'react';
import { Grid, Box, Tab, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getTAppList, resetSearchToggle } from '../../redux/feature/myTApps/myTAppsSlice';
import { makeStyles } from '@mui/styles';
import TAppInvitations from './TAppInvitations';
import clsx from 'clsx';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { TAPP_INVITE_STATUS } from '../../constants/constants';
import { useTheme } from '../../providers/custom-theme-provider';

export interface StyleProps {
  value?: string;
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  tabpanelContainer: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '& .MuiTabPanel-root': {
      padding: ({ value }) => (value === '1' ? '24px' : 0),
    },
  },
  gridPanelContainer: {
    height: '85%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
  },
  tabLabel: {
    fontFamily: 'Montserrat',
    marginBottom: '8px',
  },
  tabsItem: {
    '& .MuiTabs-flexContainer': {
      '& .MuiButtonBase-root': {
        padding: 12,
        margin: 'auto',
      },
    },
    '& .Mui-selected': {
      padding: 12,
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
  },
}));

const TAppTabs = () => {
  const dispatch = useDispatch();
  const { tAppList, view } = useSelector(getTAppList);
  const [value, setValue] = React.useState('1');
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    dispatch(resetSearchToggle());
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TabContext value={value}>
          <Box sx={{ borderTop: 1, borderColor: 'divider' }}>
            <TabList sx={{ mt: 2 }} onChange={handleChange} className={classes.tabsItem}>
              <Tab
                label={
                  <span className={clsx('text-medium font-weight-semibold textCapitalize', classes.tabLabel)}>
                    My T-Apps
                  </span>
                }
                value="1"
              />
              <Tab
                label={
                  <span className={clsx('text-medium font-weight-semibold textCapitalize', classes.tabLabel)}>
                    All T-Apps
                  </span>
                }
                value="2"
              />
            </TabList>
          </Box>
          <Box
            className={clsx({
              [classes.gridPanelContainer]: view === '1',
              [classes.tabpanelContainer]: view === '2',
            })}
          >
            <TabPanel value="1">
              <TAppInvitations status={TAPP_INVITE_STATUS.TAPP_APPROVED} approvedTapps={tAppList} isApproved={true} />
            </TabPanel>
            <TabPanel value="2">
              <div>Coming Soon...</div>
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default TAppTabs;
