import React from 'react';
import { Box, Theme, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TransitionProps } from '@mui/material/transitions';
import clsx from 'clsx';

import TDialog from '../Dialog/Dialog';
import { getPortal } from '../../utils/commonMethods';
import { theme } from '../../Theme';
import { PORTAL } from '../../constants/portal';

interface FullScreenDialogProps {
  open: boolean;
  title: string;
  dialogContent: React.ReactNode;
  closeModal: () => void;
  topMargin?: string;
}

interface StyleProps {
  matches: boolean;
  topMargin: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  dialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        width: ({ matches }) => (matches ? '100%' : 375),
        maxWidth: ({ matches }) => (matches ? '100%' : 375),
        top: ({ topMargin }) => (topMargin ? topMargin : 0),
        '& .MuiBox-root': {
          justifyContent: 'center',
          '& .MuiTypography-root': {
            padding: '24px',
            '& .MuiButtonBase-root': {
              padding: '0 !important',
            },
          },
        },
        '& .MuiDialogContent-root': {
          padding: '0 !important',
          marginTop: '0 !important',
        },
        '& .MuiDialogActions-root': {
          paddingBottom: '0px !important',
        },
      },
    },
  },
}));

//as this is a functional component, it will render once in the component lifecycle.
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FullScreenDialog = ({
  open,
  title,
  dialogContent,
  closeModal,
  topMargin = '0px',
}: FullScreenDialogProps) => {
  // Constants
  const portalName = getPortal();
  const matches = portalName === PORTAL.MERCHANT;
  const styleProps = {
    matches,
    topMargin,
  };
  const classes = useStyles(styleProps);

  // HTML
  return (
    <TDialog
      className={clsx({ [classes.dialogStyle]: true, ['commonDialogStyle']: portalName === PORTAL.MYDE })}
      fullScreen={true}
      title={title}
      open={open}
      onClose={closeModal}
      transparentTitle={true}
      TransitionComponent={Transition}
      portalName={portalName}
    >
      <Box>{dialogContent}</Box>
    </TDialog>
  );
};

export default FullScreenDialog;
