import React from 'react';
import { Radio as MuiRadio, RadioProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from '../../../../../providers/custom-theme-provider';
interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  radioButton: {
    '&.Mui-checked': {
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
  },
});

export const Radio = ({ ...props }: RadioProps) => {
  // Constants
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);

  return <MuiRadio className={classes.radioButton} color="primary" {...props} />;
};
