import React from 'react';
import { Box } from '@mui/material';
import { TButton, theme } from '../../components/myde-react-components';

export interface StyleProps {
  maxWidth: string;
}

interface StatusCardProps {
  logoUrl: string;
  title: string;
  body: any;
  onClick?: () => void;
  btnLable?: string;
}

const StatusCard = ({ title, body, onClick, logoUrl, btnLable }: StatusCardProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <>
        <Box sx={{ p: 3 }}>
          <Box className="flex-basic-center">
            <Box className="img-radius-box large-box">
              <img src={logoUrl} loading="lazy" alt="Branding Logo" />
            </Box>
          </Box>
          <Box className="flex-column-center text-center" sx={{ mt: 3 }}>
            <Box sx={{ pb: theme.spacing(3) }}>
              <Box className="textColor-200 text-h2 font-weight-semibold">{title}</Box>
            </Box>
            <Box className="textColor-200 text-large font-weight-regular">{body}</Box>
          </Box>
          {btnLable && (
            <Box className="flex-basic-center" sx={{ my: 4 }}>
              <TButton btnWidthSize="button-w-240" onClick={handleClick} variant="contained" btnText={btnLable} />
            </Box>
          )}
        </Box>
      </>
    </>
  );
};

export default StatusCard;
