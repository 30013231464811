import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemIcon, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';
import clsx from 'clsx';
import { EntityIdProcess } from '../../../types/entityTypes';
import { colorPalette, ID_PROCESS_STATUS, PROCESS_KEYS_WITH_PROCESS_ID, theme } from '../../myde-react-components';
import {
  FORMATION_DOCUMENTS,
  INCOME_VERIFICATION_ICONS,
  IRS_LETTER_EVIDENCING_TAX_ID,
  LETTER_OF_GOOD_STANDING_ICONS,
  OWNERSHIP_DOCUMENT,
  PROFILE_ICONS,
  W9_W8BEN,
  ACCREDITATION,
  ID_PROCESS_STATUS_TEXT,
} from '../../../constants/constants';
import { getIconByStatus } from '../../../utils/utils';
import {
  clearSelectedIdProcessData,
  setIdProcessKey,
  setParentIdProcess,
} from '../../../redux/feature/idProcess/mainIdProcessSlice';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { useTheme } from '../../../providers/custom-theme-provider';
import {
  customIdProcessDetails,
  getEntityCustomIdProcessList,
} from '../../../redux/feature/idProcess/customIdProcessSlice';
import { SupplementalIdProcessResults } from '../../../types/dashboardTypes';
import { useTranslation } from 'react-i18next';
import { setHideIdShareTab } from '../../../redux/feature/idProcess/idProcessShareSlice';
import { RouteType } from '../../../types/commonTypes';
import { setParentRoute } from '../../../redux/feature/common/commonSlice';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  listItem: {
    border: `1px solid ${colorPalette.typoText.lighten1}`,
    borderRadius: '10px',
    borderStyle: 'dashed',
    cursor: 'pointer',
  },
  checked: {
    borderStyle: 'none',
    border: 'none',
    borderRadius: '10px',
    background: colorPalette.containerBack.lighten3,
    cursor: 'pointer',
    boxShadow: `0px 0px 11px ${colorPalette.typoText.lighten2}`,
  },
  btnColor: {
    background: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

export const EntityIdProcessList = () => {
  const dispatch = useDispatch();
  const { currentThemes } = useTheme();
  const { t } = useTranslation('common');
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { routeTo } = useRouter();

  //Redux Values
  const { entityIdProcessList, selectedEntity } = useSelector(selectEntity);
  const { supplementalIdProcessList } = useSelector(customIdProcessDetails);

  //State Values
  const [idProcesses, setIdProcesses] = useState([] as EntityIdProcess[]);
  const [customIdProcessList, setCustomIdProcessList] = useState([] as SupplementalIdProcessResults[]);

  //useEffects
  useEffect(() => {
    const list = entityIdProcessList?.filter((element: EntityIdProcess) => element?.parent_process_id === null);
    if (list?.length) {
      setIdProcesses(list);
    }
  }, [entityIdProcessList]);

  useEffect(() => {
    dispatch(clearSelectedIdProcessData());
    dispatch(setHideIdShareTab(false));
    dispatch(setParentRoute({} as RouteType));
  }, []);

  useEffect(() => {
    dispatch(getEntityCustomIdProcessList({ entityUid: selectedEntity?.trellis_uid, filters: {} }));
  }, [selectedEntity]);

  useEffect(() => {
    if (supplementalIdProcessList?.length > 0) {
      setCustomIdProcessList(supplementalIdProcessList);
    } else {
      setCustomIdProcessList([] as SupplementalIdProcessResults[]);
    }
  }, [supplementalIdProcessList]);

  //Methods
  const getIconName = (id: number, status: string) => {
    switch (id) {
      case 5000:
        return getIconByStatus(status, PROFILE_ICONS);

      case 10000:
        return getIconByStatus(status, IRS_LETTER_EVIDENCING_TAX_ID);

      case 20000:
        return getIconByStatus(status, W9_W8BEN);

      case 30000:
        return getIconByStatus(status, FORMATION_DOCUMENTS);

      case 40000:
        return getIconByStatus(status, OWNERSHIP_DOCUMENT);

      case 50000:
        return getIconByStatus(status, LETTER_OF_GOOD_STANDING_ICONS);

      case 60000:
        return getIconByStatus(status, INCOME_VERIFICATION_ICONS);

      case 70000:
        return getIconByStatus(status, ACCREDITATION);

      default:
        return 'icon-add';
    }
  };

  const goToSpecificProcess = (element: EntityIdProcess) => {
    dispatch(setParentIdProcess(element));
    const selectedProcessId = element?.process_id + 1;
    const idProcessData: any = PROCESS_KEYS_WITH_PROCESS_ID.find((item) =>
      item?.id_processes?.includes(selectedProcessId),
    );
    const processKey = idProcessData.process_key || '';
    const title = idProcessData?.process_title || '';
    const query = { id: selectedProcessId, isEntity: true };
    dispatch(setIdProcessKey(processKey));
    routeTo(ROUTES.ID_PROCESS, true, title, query);
  };

  const handleCustomDocuments = () => {
    const stateObj = { isEntity: true };
    routeTo(ROUTES.CUSTOM_DOCUMENTS, true, 'Custom ID', stateObj);
  };

  //HTML
  return (
    <>
      <Box>
        <List>
          {idProcesses.map((element: EntityIdProcess) => (
            <>
              <ListItem
                className={clsx({
                  [classes.listItem]: true,
                  [classes.checked]: element?.status_label === ID_PROCESS_STATUS_TEXT.VERIFIED,
                })}
                sx={{ py: 3, my: theme.spacing(5) }}
                key={element?.process_id}
                onClick={() => goToSpecificProcess(element)}
              >
                <ListItemIcon>
                  {element?.status === ID_PROCESS_STATUS.PASSED ||
                  element?.status === ID_PROCESS_STATUS.SYSTEM_APPROVED ? (
                    <span className="icon-approved success-color-state actionIconFont-24"></span>
                  ) : (
                    <Box>
                      <span className={clsx(`${getIconName(element?.process_id, element?.status)} icon-size-32`)} />
                    </Box>
                  )}
                </ListItemIcon>

                <Box className="font-weight-semibold">{element?.title}</Box>
              </ListItem>
            </>
          ))}
        </List>
        {customIdProcessList?.length > 0 && (
          <ListItem
            className={clsx(classes.checked, classes.listItem, 'cursorPointer')}
            sx={{ py: 3, my: 1.5 }}
            onClick={handleCustomDocuments}
          >
            <Box sx={{ pr: 3 }}>
              <span className="icon-letter-evidence icon-size-32 textColor-300"></span>
            </Box>
            <Box className="flex-basic-space-between w-100">
              <Box className="textColor-300 text-large font-weight-semibold">{t('customDocuments')}</Box>
            </Box>
          </ListItem>
        )}
      </Box>
    </>
  );
};

export default EntityIdProcessList;
