import React from 'react';
import { TFullScreenDialog } from '../../..';

interface PandaDocDialogProps {
  title: string;
  open: boolean;
  esign: string;
  onClose: () => void;
  onComplete: () => void;
}

export const PandaDocDialog = ({ title, open, esign, onClose, onComplete }: PandaDocDialogProps) => {
  // Methods
  window.addEventListener('message', (event) => {
    let result = null;
    if (event && event.data) {
      if (typeof event.data === 'string') {
        result = JSON.parse(event.data);
      } else {
        result = event.data;
      }
      const type = result;
      if (type && type.data && type.data.document && Object.keys(type.data.document).length > 0) {
        onComplete();
      }
    }
  });
  return (
    <TFullScreenDialog
      open={open}
      closeModal={onClose}
      title={title}
      topMargin={'0'}
      dialogContent={<iframe id="pandaDocIFrame" src={esign} height="100%" width="100%" />}
    />
  );
};

export default PandaDocDialog;
