import { apiPost, apiGet, apiDelete } from './axios';

export const login = async (formData: object) => {
  const res = await apiPost('/login', formData);
  return res.data;
};

export const session = async () => {
  const res = await apiGet('/vault-login/session');
  return res.data;
};

export const deleteData = async () => {
  const res = await apiDelete('/user');
  return res.data;
};

export const bankVaultLogin = async (formData: { session: string; token: string; secret: string }) => {
  const res = await apiPost('/vault-login', formData);
  return res.data;
};
