import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { APPLICATION_STATUS } from '../../../../constants/constants';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';
import {
  selectSelectedIdProcessDetails,
  clearIdProcessData,
  submitEntityIdProcess,
  setUploadedDocId,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { ENTITY_PROCESS_IDS, ID_PROCESS_STATUS_COMPLETION_STATE } from '../../../myde-react-components';
import UploadDoc from '../common/UploadDoc';
import { FileType } from '../../../../types/documentTypes';

interface LetterOfGoodStandingConfirmationProps {
  isEntity?: boolean;
  isMultiple: boolean;
}

const LetterOfGoodStandingConfirmation = ({ isEntity, isMultiple }: LetterOfGoodStandingConfirmationProps) => {
  //constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { selectedEntity } = useSelector(selectEntity);
  const { uploadedImage, uploadedDocId, selectedEntityIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);

  //state variables
  const [preview, setPreview] = useState([] as FileType[]);

  //useEffects
  useEffect(() => {
    dispatch(setUploadedDocId(uploadedImage?.doc_id));
  }, [uploadedImage]);

  useEffect(() => {
    if (
      isEntity &&
      !isMultiple &&
      selectedEntityIdProcessDetails &&
      Object.keys(selectedEntityIdProcessDetails)?.length > 0 &&
      selectedEntityIdProcessDetails?.data?.document_id_list?.length > 0
    ) {
      setPreview(selectedEntityIdProcessDetails?.data?.document_id_list);
    }
  }, [selectedEntityIdProcessDetails]);

  //methods
  const handleNext = () => {
    const payload: any = {
      process_id: ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING,
      process_data: {
        document_id_list: [uploadedDocId],
      },
      action: APPLICATION_STATUS.SUBMIT,
    };
    if (isEntity && uploadedImage?.doc_name?.length > 0) {
      dispatch(submitEntityIdProcess({ entityUid: selectedEntity?.trellis_uid, formData: payload }));
      dispatch(clearIdProcessData());
      dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
      dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
    } else {
      toast.error(t('somethingWentWrongText', { ns: 'notificationMessages' }));
    }
  };

  return (
    <Box>
      <UploadDoc
        buttonText={'Submit'}
        isEntity={isEntity}
        handleNext={handleNext}
        isMultiple={false}
        previewFiles={preview}
      />
    </Box>
  );
};

export default LetterOfGoodStandingConfirmation;
