import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { t } from 'i18next';

import { TPaper, theme, colorPalette } from '../../myde-react-components';
import AddMembersForm from './AddMembersForm';
import { selectEntity, sendInviteToMembers, setEntityMembersFormData } from '../../../redux/feature/entity/entitySlice';
import { EntityMembersFormData, EntityMembersPayload } from '../../../types/entityTypes';
import { USER_TYPES } from '../../../constants/constants';
import useQuery from '../../../hooks/useQuery';

// Styles
const useStyles = makeStyles({
  instructionContainer: {
    backgroundColor: colorPalette.containerBack.base,
    borderRadius: '10px',
  },
});

const AddMemberUser = () => {
  // Constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const isPostOnboardingFlow = query.get('isPostOnboarding') === 'true' || false;

  // Redux Values
  const { addEntityResult, entityMembersFormData, selectedEntity, allEntityMembersList } = useSelector(selectEntity);

  // State Values
  const [isBtnClicked, setIsBtnClicked] = useState(false);

  useEffect(() => {
    if (
      entityMembersFormData?.memberList &&
      Object.keys(entityMembersFormData?.memberList)?.length > 0 &&
      isBtnClicked
    ) {
      sendInvites();
      setIsBtnClicked(false);
    }
  }, [entityMembersFormData, isBtnClicked]);

  // Methods
  const updateMemberData = (memberData: EntityMembersFormData[]) => {
    const memberList = { memberList: memberData };
    dispatch(setEntityMembersFormData({ ...entityMembersFormData, ...memberList }));
    setIsBtnClicked(true);
  };
  const sendInvites = async () => {
    const membersDetails = isPostOnboardingFlow
      ? [...entityMembersFormData?.memberList]
      : [...entityMembersFormData?.ownerList, ...entityMembersFormData?.memberList];
    const payload: EntityMembersPayload = {
      entity_uid: isPostOnboardingFlow ? selectedEntity?.trellis_uid : addEntityResult?.trellis_uid || 0,
      membersData: { user_list: membersDetails },
    };
    await dispatch(sendInviteToMembers(payload));
  };

  // HTML
  return (
    <TPaper sx={{ p: 3, mb: 8 }}>
      <Box className="flex-column-center">
        {!isPostOnboardingFlow && (
          <Box
            sx={{ p: theme.spacing(5) }}
            className={clsx('textColor-200 text-medium text-center', classes.instructionContainer)}
          >
            {t('addMembersLater', { ns: 'inviteFlow' })}
          </Box>
        )}
        <Box className="w-100">
          <AddMembersForm
            userType={USER_TYPES.MEMBER}
            btnText={'Send Invite'}
            memberList={entityMembersFormData?.memberList || []}
            isPostOnboardingFlow={isPostOnboardingFlow}
            allEntityMembersData={allEntityMembersList || []}
            onBtnClick={updateMemberData}
          />
          ,
        </Box>
      </Box>
    </TPaper>
  );
};

export default AddMemberUser;
