export type ButtonToggleType = {
  label: string;
  value: string;
};

export type FilePreviewType = {
  file?: File;
  id?: string;
  name: string;
  type: string;
  preview: string;
  size: number;
};

export type GPlacesAddressComponentsType = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type GPlacesAddressType = {
  address_components: GPlacesAddressComponentsType[];
  name: string;
};

export type GPlacesAddressDataType = {
  name: string;
  country: string;
  country_name: string;
  state: string;
  state_name: string;
  city: string;
  zip: string;
};

export type GPlacesEntityAddressDataType = {
  name: string;
  country: string;
  country_name: string;
  state: string;
  state_name: string;
};

export type EntityPayloadType = {
  processId: number;
  entityUid: number;
  params?: object;
};

export const FileTypeProp = {
  SAFE_DOC: 'safe_document',
  SOURCE_DOC: 'source_document',
};

export type FormFieldRules = {
  required?: { value: boolean; message?: string };
  pattern?: { value: RegExp; message: string };
  maxLength?: { value: number; message?: string };
  minLength?: { value: number; message?: string };
  validate?: {
    [key: string]: (value: unknown) => boolean | string;
  };
};

export type FieldType = 'text' | 'number' | 'password' | 'select';

export type FormFieldType = {
  type: FieldType;
  label: string;
  defaultValue?: string;
  placeholder?: string;
  options?: string[];
  name: string;
  name_input?: string;
  rules?: FormFieldRules;
};

export const NOTIFICATION_TAB = {
  SYSTEM: 'SYSTEM',
  MERCHANT: 'MERCHANT',
  USER: 'USER',
};
