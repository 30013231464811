import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  FilePreviewType,
  TCard,
  theme,
  TSwitch,
  colorPalette,
  TButton,
  ID_PROCESS_STATUS_COMPLETION_STATE,
  REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS,
  FileTypeProp,
  TFullScreenDialog,
} from '../../../../myde-react-components';
import CommonSubscriptionScreen from '../../../../common/CommonSubscriptionScreen';
import { ID_PROCESS_STATUS_TEXT, KYC_AML_RESULTS, PROCESS_IDS } from '../../../../../constants/constants';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import Report from './Report';
import {
  getProcessDetailsById,
  selectSelectedIdProcessDetails,
} from '../../../../../redux/feature/idProcess/mainIdProcessSlice';
import PreviewFileByType from '../../../../common/PreviewFileByType';
import { getIconByProcessId } from '../../../../myde-react-components/src/utils/idProcess';
import { setIsEditAllowedFlag, setPreviousStep } from '../../../../../redux/feature/common/commonSlice';
import { getProfileDetails, selectProfile } from '../../../../../redux/feature/profile/profileSlice';
import { membershipFlip } from '../../../../../api/profile';
import { hexToRgbA } from '../../../../../utils/utils';

interface StyleProps {
  primaryColor: string;
  isSafeDoc: boolean;
  errorColor: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  icon: {
    color: ({ primaryColor }) => primaryColor,
  },
  switchLabel: {
    color: ({ isSafeDoc, primaryColor }) => (isSafeDoc ? primaryColor : colorPalette.iconColor.light),
  },
  errorMessageBox: {
    backgroundColor: ({ errorColor }) => hexToRgbA(errorColor, 0.08),
    border: '1px solid',
    borderRadius: '10px',
    borderColor: ({ errorColor }) => hexToRgbA(errorColor, 0.07),
  },
});

const DocumentSummary = () => {
  // constants
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes.palette.primary.main;
  const errorColor = colorPalette.error;

  // State variables
  const [selectedIdProcessData, setSelectedIdProcessData] = useState({} as any);
  const [idProcessStatus, setIdProcessStatus] = useState('');
  const [isSafeDoc, setIsSafeDoc] = useState<boolean>(false);
  const [previewFiles, setPreviewFiles] = useState([] as FilePreviewType[]);
  const [issues, setIssues] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // hooks
  const styleProps = {
    primaryColor,
    errorColor,
    isSafeDoc,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation(['common', 'idDocumentFlow']);
  const dispatch = useDispatch();

  // Redux Values
  const { selectedIdProcessDetails, processId } = useSelector(selectSelectedIdProcessDetails);
  const { profileData } = useSelector(selectProfile);

  // Use Effect
  useEffect(() => {
    dispatch(getProcessDetailsById({ processId: processId }));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[3].percent));
  }, []);

  useEffect(() => {
    if (Object.keys(selectedIdProcessDetails)?.length > 0) {
      setSelectedIdProcessData(selectedIdProcessDetails);
      setIdProcessStatus(selectedIdProcessDetails?.status_label);

      if (Object.keys(selectedIdProcessDetails?.result)?.length > 0) {
        const report = selectedIdProcessDetails?.result?.filter(
          (item: any) => item?.result === KYC_AML_RESULTS.CONSIDER,
        );
        setIssues(report?.length);
      }
    }
  }, [selectedIdProcessDetails]);

  useEffect(() => {
    if (idProcessStatus === ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS || idProcessStatus === '') {
      dispatch(setIsEditAllowedFlag(false));
    } else {
      dispatch(setIsEditAllowedFlag(true));
    }
  }, [idProcessStatus]);

  useEffect(() => {
    if (Object.keys(selectedIdProcessData)?.length > 0) {
      setFilePreview();
    }
  }, [selectedIdProcessData, isSafeDoc]);

  //methods
  const getHeader = () => {
    switch (processId) {
      case PROCESS_IDS.PASSPORT: {
        return t('passport', { ns: 'idDocumentFlow' });
      }
      case PROCESS_IDS.DRIVERS_LICENSE: {
        return t('driving_license', { ns: 'idDocumentFlow' });
      }
    }
  };

  const setFilePreview = () => {
    const data: FilePreviewType[] = [];
    const fileProp = isSafeDoc ? FileTypeProp.SAFE_DOC : FileTypeProp.SOURCE_DOC;
    setPreview(data, selectedIdProcessData?.data[fileProp]?.front);
    if (processId == PROCESS_IDS.DRIVERS_LICENSE) {
      setPreview(data, selectedIdProcessData?.data[fileProp]?.back);
    }
    setPreviewFiles(data);
  };

  const setPreview = (data: FilePreviewType[], fileData: any) => {
    if (fileData) {
      const payload = {
        id: fileData?.doc_id || '',
        name: fileData?.doc_name || '',
        type: fileData?.mime_type || '',
        preview: fileData?.signed_url || '',
        size: fileData?.size_in_kb,
      };
      data.push(payload);
    }
  };

  const toggleSwitch = () => {
    setIsSafeDoc(!isSafeDoc);
  };

  const setIssuesFoundText = (issue: number) => {
    if (issue > 0) {
      return `(${issue}) \t ${t('documentVerificationIssue')}`;
    } else {
      return `${t('documentVerificationNoIssuesReported')}`;
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleVerify = async () => {
    const result = await membershipFlip();
    if (result?.id) {
      dispatch(getProcessDetailsById({ processId: PROCESS_IDS.BIOMETRIC_VERIFICATION }));
      dispatch(getProfileDetails());
    }
  };

  return (
    <TCard>
      <Box sx={{ p: 3, mt: 2 }}>
        <Box sx={{ mb: 3 }} className="flex-column-center">
          <Box sx={{ mb: 2 }} className={clsx(getIconByProcessId(processId), 'icon-size-60', classes.icon)} />
          <Box className="textColor-200 text-center text-h2 font-weight-semibold">{getHeader()}</Box>
        </Box>

        <Box sx={{ pb: theme.spacing(3) }}>
          <Box className="flex-basic-space-between">
            <Box className={'text-small textColor-300 flex-column-center'}>
              {t('documents', { ns: 'idDocumentFlow' })}
            </Box>
            {selectedIdProcessData?.data?.safe_document &&
              Object.keys(selectedIdProcessData?.data?.safe_document)?.length > 0 && (
                <Box className="flex-basic-end">
                  <Box>
                    <TSwitch themed value={isSafeDoc} checked={isSafeDoc} onChange={toggleSwitch} />
                  </Box>
                  <Box className={clsx(classes.switchLabel, 'text-medium font-weight-semibold')}>Safe Doc</Box>
                </Box>
              )}
          </Box>
          <Box className="flex-basic-start">
            <PreviewFileByType files={previewFiles} />
          </Box>
        </Box>
        {(profileData?.is_premium ||
          REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS.includes(selectedIdProcessDetails?.status)) && (
          <Box className="w-100" sx={{ mt: 2, mb: 1 }}>
            <Box className="flex-basic-space-between">
              {idProcessStatus && (
                <Box>
                  <Box className="textColor-300 text-small font-weight-regular">
                    {t('statusText', { ns: 'idDocumentFlow' })}
                  </Box>
                  <Box sx={{ pt: 1 }} className="textColor-200 text-medium font-weight-semibold">
                    {idProcessStatus}
                  </Box>
                </Box>
              )}
              <Box className="flex-basic-end">
                {idProcessStatus === ID_PROCESS_STATUS_TEXT.VERIFIED && (
                  <Box>
                    <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                      <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
                    </Box>
                  </Box>
                )}
                {[
                  ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED,
                  ID_PROCESS_STATUS_TEXT.VERIFICATION_NEEDS_MANUAL_REVIEW,
                ].includes(idProcessStatus) && (
                  <Box>
                    <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                      <span className="icon-error-warning error-color-state icon-size-24"></span>
                    </Box>
                  </Box>
                )}
                {idProcessStatus === ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS && (
                  <Box>
                    <Box sx={{ pt: theme.spacing(5) }} className="p-relative small-sizes custom-progress-icon">
                      <Box className="progress-spinner sm-spinner"></Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {idProcessStatus === ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED && (
              <Box>
                <Box className={classes.errorMessageBox} sx={{ mt: 3, px: 2, py: theme.spacing(3) }}>
                  <Box className={'text-small textColor-200'}>{selectedIdProcessData?.error_message}</Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
        <Box>
          {profileData?.is_premium &&
            [ID_PROCESS_STATUS_TEXT.VERIFIED, ID_PROCESS_STATUS_TEXT.VERIFICATION_NEEDS_MANUAL_REVIEW]?.includes(
              idProcessStatus,
            ) && (
              <Grid item xs={12} sm={12} md={12} sx={{ mt: theme.spacing(5) }}>
                <CommonSubscriptionScreen
                  header={t('verificationComplete')}
                  subtitle={t('documentVerificationCompletedText')}
                  subtitle2={setIssuesFoundText(issues)}
                  renderAction={
                    <TButton
                      btnText="View Report"
                      btnWidthSize="button-w-240"
                      variant="contained"
                      onClick={() => setShowModal(true)}
                    />
                  }
                />
              </Grid>
            )}
        </Box>
        {!profileData?.is_premium && !REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS.includes(selectedIdProcessDetails?.status) && (
          <Box>
            <CommonSubscriptionScreen
              header={t('documentNotVerified')}
              subtitle={t('documentVerificationMessage')}
              renderAction={
                <TButton
                  btnText="Become a Premium Member"
                  btnWidthSize="button-w-240"
                  variant="contained"
                  onClick={handleVerify}
                />
              }
            />
          </Box>
        )}
      </Box>
      <TFullScreenDialog
        open={showModal}
        closeModal={closeModal}
        title={''}
        dialogContent={<Report closeModal={closeModal} />}
      />
    </TCard>
  );
};
export default DocumentSummary;
