export const colorPalette = {
  primary: '#009F46',
  secondary: '#0075A1',
  accent: '#f4f4f4',
  info: '#000000',
  error: '#E55957',
  success: '#009F46',
  warning: '#F6841B',
  failure: '#F6841B',
  approved: '#27ae60',
  stateError: '#eb5757',
  stateDisabled: '#D9D9D9',
  stateWarning: '#e2b93b',
  stateInProgress: '#3687D2',
  rating: '#FFC30B',

  typoText: {
    // Regular
    base: '#000000',
    // Dark
    darken1: '#000000',
    darken2: '#5D686B',
    darken3: '#828282',
    // Light
    lighten1: '#8A989B',
    lighten2: '#e0e0e0',
    lighten3: '#ffffff',
    lighten4: '#C0CBCD',
    disabled: '#B4B4B4',
    inviteAction: '#9DA514',
    unSelected: '#BDBDBD',
  },
  containerBack: {
    dark: '#9C9C9C',
    // mediumlight
    base: '#EDF2F3',
    // dark
    darken1: '#323A3C',
    // lighten1
    lighten1: '#EDF2F3',
    // lighten2
    lighten2: '#D6DDDF',
    // lighten3
    lighten3: '#FFFFFF',
  },
  menu: {
    base: '#BECEEA',
  },
  border: {
    base: '#B9C5C7',
    success: '#179D4E',
    warning: '#FE970C',
    card: '#D7E0E2',
    pending: '#2DCCFF',
    submitted: '#2DCCFF',
  },
  iconColor: {
    base: '#A1A1A1',
    main: '#e7e7e7',
    light: '#989898',
  },
  backgroundFadeColors: {
    primary: '#009f461a',
    secondary: '#E6F2F4',
  },
  boxShadowColors: {
    lighten1: '#c9c9c9',
  },
};
