import React from 'react';

import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IdProcesses from './IdProcesses';

export const IdProcessListIndex = () => {
  const { t } = useTranslation('common');

  return (
    <Box>
      <Box className="text-start text-h2 font-weight-semibold" sx={{ my: 1 }}>
        {t('idVerification')}
      </Box>
      <Divider sx={{ mb: 2 }} />
      <IdProcesses />
    </Box>
  );
};

export default IdProcessListIndex;
