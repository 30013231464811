import * as ROUTES from './routes';

export const ID_CARD_NAV_INDEX: Record<string, number> = {
  ID: 0,
  TAPPS: 1,
  ALERTS: 2,
  SETTINGS: 3,
  ENTITY_SELECTION: 4,
};

export const NAVIGATE_TO_NOTIFICATION: any = {
  ID_PROCESS_LISTING: ROUTES.MY_IDENTITY,
  ID_PROCESS_LISTING_ENTITY: ROUTES.ENTITY_MY_IDENTITY,
  PROFILE_LISTING: ROUTES.ID_PROCESS,
  ID_PROCESS_SUMMARY: ROUTES.ID_PROCESS,
  ID_PROCESS_SUMMARY_ENTITY: ROUTES.ID_PROCESS,
  DASHBOARD: ROUTES.DASHBOARD,
  TAPPS: ID_CARD_NAV_INDEX.TAPP,
  ALERTS: ID_CARD_NAV_INDEX.ALERTS,
  SETTINGS: ID_CARD_NAV_INDEX.SETTINGS,
  MY_IDENTITY_CARD: ID_CARD_NAV_INDEX.ID,
  INVITE_LISTING: ROUTES.DASHBOARD,
  INVITE_DETAILS: ROUTES.DASHBOARD,
  ENTITY_DETAILS: ID_CARD_NAV_INDEX.ENTITY_SELECTION,
  MERCHANT_PRIVACY: ROUTES.USER_MESSAGES,
  OPEN_REQUESTS: ROUTES.ENTITY_INVITE,
  INVITE_DETAILS_SCREEN: 'INVITE_DETAILS_SCREEN', // TBD
  MERCHANT_MESSAGE: ROUTES.USER_MESSAGES_INDEX,
  MERCHANT_DOCUMENTS: ROUTES.MERCHANT_DOCUMENTS,
  MERCHANT_DOCUMENT_ENTITY: ROUTES.MERCHANT_DOCUMENTS,
  MERCHANT_DOC_ENTITY: ROUTES.MERCHANT_DOCUMENTS,
  MERCHANT_TAPPS: ID_CARD_NAV_INDEX.TAPP,
  ENTITY_MEMBER_LIST: ROUTES.ENTITY_MEMBERS,
  OPEN_REQUEST_RECEIVED: ROUTES.ENTITY_INVITE,
  ENTITY_PROFILE_DETAILS: ROUTES.USER_PROFILE,
  MERCHANT_APPLICATION_DETAIL: ROUTES.USER_MESSAGES_INDEX,
  MERCHANT_APPLICATION_DETAIL_ENTITY: ROUTES.USER_MESSAGES_INDEX,
  CUSTOM_ID_PROCESS_CATEGORY: ROUTES.CUSTOM_DOCUMENTS,
  CUSTOM_ID_PROCESS_CATEGORY_ENTITY: ROUTES.CUSTOM_DOCUMENTS,
};

export const NOTIFICATION_NAVIGATE = {
  ID_PROCESS_LISTING: 'ID_PROCESS_LISTING',
  ID_PROCESS_LISTING_ENTITY: 'ID_PROCESS_LISTING_ENTITY',
  PROFILE_LISTING: 'PROFILE_LISTING',
  ID_PROCESS_SUMMARY: 'ID_PROCESS_SUMMARY',
  ID_PROCESS_SUMMARY_ENTITY: 'ID_PROCESS_SUMMARY_ENTITY',
  DASHBOARD: 'DASHBOARD',
  TAPPS: 'TAPPS',
  ALERTS: 'ALERTS',
  SETTINGS: 'SETTINGS',
  MY_IDENTITY_CARD: 'MY_IDENTITY_CARD',
  INVITE_LISTING: 'INVITE_LISTING',
  INVITE_DETAILS: 'INVITE_DETAILS',
  ENTITY_DETAILS: 'ENTITY_DETAILS',
  MERCHANT_PRIVACY: 'MERCHANT_PRIVACY',
  OPEN_REQUESTS: 'OPEN_REQUESTS',
  INVITE_DETAILS_SCREEN: 'INVITE_DETAILS_SCREEN',
  MERCHANT_MESSAGE: 'MERCHANT_MESSAGE',
  MERCHANT_DOCUMENTS: 'MERCHANT_DOCUMENTS',
  MERCHANT_DOCUMENT_ENTITY: 'MERCHANT_DOCUMENT_ENTITY',
  MERCHANT_DOC_ENTITY: 'MERCHANT_DOC_ENTITY',
  MERCHANT_TAPPS: 'MERCHANT_TAPPS',
  ENTITY_MEMBER_LIST: 'ENTITY_MEMBER_LIST',
  OPEN_REQUEST_RECEIVED: 'OPEN_REQUEST_RECEIVED',
  ENTITY_PROFILE_DETAILS: 'ENTITY_PROFILE_DETAILS',
  MERCHANT_APPLICATION_DETAIL: 'MERCHANT_APPLICATION_DETAIL',
  MERCHANT_APPLICATION_DETAIL_ENTITY: 'MERCHANT_APPLICATION_DETAIL_ENTITY',
  CUSTOM_ID_PROCESS_CATEGORY: 'CUSTOM_ID_PROCESS_CATEGORY',
  CUSTOM_ID_PROCESS_CATEGORY_ENTITY: 'CUSTOM_ID_PROCESS_CATEGORY_ENTITY',
};

export const NAVIGATION_ROUTES = {
  ID_PROCESS_LISTING: ROUTES.MY_IDENTITY,
  DASHBOARD: ROUTES.DASHBOARD,
  PROFILE_LISTING: ROUTES.ID_PROCESS,
};

export const ID_CARD_NAVIGATION_MAP = {
  MY_IDENTITY_CARD: ID_CARD_NAV_INDEX.ID,
  TAPPS: ID_CARD_NAV_INDEX.TAPP,
  ALERTS: ID_CARD_NAV_INDEX.ALERTS,
  SETTINGS: ID_CARD_NAV_INDEX.SETTINGS,
};
