import React from 'react';
import {
  Select as MuiSelect,
  SelectProps,
  MenuItem,
  InputLabel,
  InputLabelProps,
  OutlinedInput,
  OutlinedInputProps,
  Checkbox,
  ListItemText,
  Theme,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '../../../../../providers/custom-theme-provider';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
export interface StyleProps {
  currentThemes: Theme;
}
interface CustomSelectProps {
  options: string[] | any[];
  label: string;
  isMultiple?: boolean;
  defaultValue?: any;
  itemValue: string;
  itemId: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  itemSelected: {
    '&.Mui-selected': {
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main}`,
      backgroundColor: 'transparent!important',
    },
  },
  select: {
    '& .MuiSelect-select': {
      padding: '25px 0px 11px 16px',
    },
  },
  inputLabel: {
    '&.MuiInputLabel-formControl': {
      '&.Mui-focused': {
        color: ({ currentThemes }) => `${currentThemes.palette.primary.main}`,
      },
    },
  },
  inputField: {
    '&.MuiOutlinedInput-root': {
      '&.Mui-focused': {
        '& > fieldset': { borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important` },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          display: 'none !important',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      '& legend': {
        display: 'none !important',
      },
    },
  },
});

export type CombinedProps = CustomSelectProps & SelectProps & InputLabelProps & OutlinedInputProps;

export const Select = React.forwardRef<any, CombinedProps>(
  ({ isMultiple = false, options, itemValue, itemId, label, ...props }: CombinedProps, ref: any) => {
    const { currentThemes } = useTheme();
    const styleProps: StyleProps = {
      currentThemes: currentThemes,
    };

    const classes = useStyles(styleProps);
    const [data] = React.useState<string[]>([]);

    const renderCheckboxes = (option: any) => (
      <MenuItem key={option} value={option}>
        <Checkbox checked={data.indexOf(option) > -1} />
        <ListItemText primary={option} />
      </MenuItem>
    );

    const renderOption = (option: any) => (
      <MenuItem className={classes.itemSelected} key={option[itemId]} value={option[itemId]}>
        {option[itemValue]}
      </MenuItem>
    );
    return (
      <Box className="selectDropBox">
        <InputLabel className={classes.inputLabel}>{label}</InputLabel>
        <MuiSelect
          {...props}
          className={classes.select}
          ref={ref}
          multiple={isMultiple}
          MenuProps={{
            classes: { paper: 'selectDropdownList' },
            variant: 'menu',
          }}
          input={<OutlinedInput className={classes.inputField} label={label} />}
          IconComponent={KeyboardArrowDownIcon}
        >
          {options?.map((option) => (isMultiple ? renderCheckboxes(option) : renderOption(option)))}
        </MuiSelect>
      </Box>
    );
  },
);
