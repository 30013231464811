import React, { useEffect, useState } from 'react';
import { Box, Divider, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as ROUTES from '../../../constants/routes';
import {
  getApplicationsList,
  messageDetails,
  setSelectedMerchantDetails,
} from '../../../redux/feature/message/messageSlice';
import { TCard, theme, TTextField } from '../../../components/myde-react-components';
import { resetMerchantId, resetScreenToBeShown, setMerchantId } from '../../../redux/feature/documents/documentsSlice';
import {
  MerchantEntitiesSelectionType,
  MerchantObjectResultType,
  MerchantUsersSelectionType,
} from '../../../types/documentTypes';
import { useRouter } from '../../../providers/custom-router-provider';
import { setCardState } from '../../../redux/feature/common/commonSlice';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';
import MerchantListingIndex from '../../../components/common/MerchantListingIndex';
import { INVITE_TYPE } from '../../../constants/constants';

const MerchantPrivacyIndex = () => {
  const { t } = useTranslation('userViewMessage');
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  //Redux values
  const { applicationsList } = useSelector(messageDetails);
  const { selectedEntity, checkIsEntity } = useSelector(selectEntity);

  //State values
  const [merchantListForUsers, setMerchantListForUsers] = useState([] as MerchantUsersSelectionType[]);
  const [merchantListForEntities, setMerchantListForEntities] = useState([] as MerchantEntitiesSelectionType[]);
  const [searchValue, setSearchValue] = useState('');
  const [isEntityContext, setIsEntityContext] = useState(false);

  //useEffects
  useEffect(() => {
    const params = selectedEntity?.id ? { entity_uid: selectedEntity?.trellis_uid } : {};
    setIsEntityContext(!!selectedEntity?.id);
    dispatch(getApplicationsList(params));
    dispatch(setCardState(false));
  }, [selectedEntity]);

  useEffect(() => {
    const users = getMerchantDetailList(applicationsList?.user, false);
    setMerchantListForUsers(users);
    const entities = applicationsList?.entities?.map((item) => {
      return {
        entity_name: item?.name,
        entity_trellis_uid: item?.trellis_uid,
        merchants: getMerchantDetailList(item?.merchants, true),
      };
    });
    setMerchantListForEntities(entities);
  }, [applicationsList]);

  useEffect(() => {
    dispatch(resetMerchantId());
    dispatch(resetScreenToBeShown());
  }, []);

  //Methods
  const getMerchantDetailList = (data: MerchantObjectResultType[], isEntity: boolean) => {
    return data?.map((item) => {
      return {
        merchant_id: item?.merchant?.id,
        merchant_name: item?.merchant?.name,
        logo: item?.merchant?.branding?.logo_thumbnail_url,
        total_unread_count: item?.total_unread_count,
        is_entity: isEntity,
      };
    });
  };

  const handleClick = (item: MerchantUsersSelectionType, entity_uid?: number) => {
    dispatch(setMerchantId(item?.merchant_id));
    dispatch(setSelectedMerchantDetails(item));
    const inviteType = selectedEntity?.id || item?.is_entity ? INVITE_TYPE.ENTITY : INVITE_TYPE.INDIVIDUAL;

    const query = {
      id: item?.merchant_id,
      invite_type: inviteType,
      is_entity: item?.is_entity,
      merchant: item?.merchant_name,
      entity_uid: checkIsEntity ? selectedEntity?.trellis_uid : entity_uid || 0,
    };
    routeTo(ROUTES.USER_MESSAGES_INDEX, true, item?.merchant_name, query);
  };

  const handleChange = (value: string) => {
    setSearchValue(value);
    const filters = selectedEntity?.id
      ? {
          entity_uid: selectedEntity?.trellis_uid,
          merchant_name: value,
        }
      : { merchant_name: value };
    dispatch(getApplicationsList(filters));
  };

  //HTML
  return (
    <>
      <Box sx={{ mt: 8, mb: 1 }} className="text-h2 textColor-200 font-weight-semibold">
        {t('myMerchantsPrivacyTitle', { ns: 'userViewMessage' })}
      </Box>
      <Divider sx={{ mt: 1, mb: theme.spacing(5) }} />
      <TCard sx={{ p: 2 }} className="card-height-100 userMessageList">
        <Box>
          <TTextField
            value={searchValue}
            placeholder="Search Merchant"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className="icon-search textColor-400" />
                </InputAdornment>
              ),
            }}
            onChange={(event) => handleChange(event?.target?.value)}
          />
        </Box>
        {merchantListForUsers?.length > 0 || merchantListForEntities?.length > 0 ? (
          <MerchantListingIndex
            merchantUserList={merchantListForUsers}
            merchantEntityList={merchantListForEntities}
            showHeader={isEntityContext}
            handleClick={handleClick}
          />
        ) : (
          <Box sx={{ pt: 5 }}>
            <Box className="text-h3 font-weight-semibold textColor-300 text-center">
              {t('nodataAvailable', { ns: 'userViewMessage' })}
            </Box>
          </Box>
        )}
      </TCard>
    </>
  );
};

export default MerchantPrivacyIndex;
