import React, { useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

import {
  FilePreviewType,
  ACCEPTED_VIDEO_MIME_TYPES,
  FILE_SIZE,
  MEGABYTE_CONVERTER,
  DOCUMENT_VERIFICATION_MIME_TYPES,
  PDF_MIME_TYPE,
  TButton,
} from '../myde-react-components';
import { MAX_FILES_TO_UPLOAD } from '../../constants/constants';

interface SingleUploadProps {
  isMultiple: boolean;
  acceptedFileFormat: string[];
  maxFileSize: number;
  uploadedFiles?: FilePreviewType[];
  setData?: (file: File) => void;
  setMultipleData?: (file: FilePreviewType[], oldFiles: FilePreviewType[]) => void;
  showIcon?: boolean;
  btnText?: string;
  btnVariant?: 'text' | 'outlined' | 'contained';
  btnWidthSize?: 'button-w-240' | 'button-w-140' | 'button-w-80';
}

const SingleAndMultipleUpload = ({
  maxFileSize,
  acceptedFileFormat,
  isMultiple,
  uploadedFiles,
  setData,
  setMultipleData,
  showIcon = true,
  btnText = 'Upload',
  btnVariant = 'outlined',
  btnWidthSize = 'button-w-140',
}: SingleUploadProps) => {
  // Constants
  const { t } = useTranslation('common');

  // State Variables
  const [files, setFiles] = useState([] as FilePreviewType[]);
  const [newFiles, setNewFiles] = useState([] as FilePreviewType[]);

  // Use Effects
  useEffect(() => {
    if (files?.length > 0) {
      if (!isMultiple && files[0]?.file && setData) {
        setData(files[0]?.file);
      }
    }
  }, [files]);
  useEffect(() => {
    if (newFiles?.length > 0 && isMultiple && setMultipleData) {
      setMultipleData(newFiles, uploadedFiles || []);
    }
  }, [newFiles]);

  // Methods
  const { getRootProps, getInputProps, open } = useDropzone({
    maxSize: maxFileSize,
    accept: acceptedFileFormat?.toString(),
    onDropRejected: (fileRejections) => {
      !isMultiple ? singleFileReject(fileRejections[0]) : multipleFileReject(fileRejections[0]);
    },
    onDropAccepted: (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        !isMultiple ? singleFileAccept(acceptedFiles[0]) : multpileFileAccept(acceptedFiles);
      } else {
        toast.error('Please upload a file with a valid format');
      }
    },
  });

  const singleFileAccept = (acceptedFile: any) => {
    const newFile = new File([acceptedFile], acceptedFile?.name, {
      type: acceptedFile?.type?.includes('video') ? 'video/mp4' : acceptedFile?.type,
    });
    const fileData = Object.assign(newFile, { preview: URL.createObjectURL(newFile) });
    const payload = {
      file: newFile,
      ...fileData,
    };
    setFiles([payload]);
  };

  const multpileFileAccept = (acceptedFiles: any[]) => {
    const filesList: any[] = [];
    const oldFiles = uploadedFiles || [];
    acceptedFiles?.forEach((file) => {
      const fileData = Object.assign(file, { preview: URL.createObjectURL(file) });
      filesList.push(fileData);
    });
    const combineFiles = [...filesList, ...oldFiles];
    if (combineFiles?.length > MAX_FILES_TO_UPLOAD) {
      toast.error(`File upload limit reached. You can upload upto ${MAX_FILES_TO_UPLOAD} files`);
    } else {
      setFiles(combineFiles);
      setNewFiles(filesList);
    }
  };

  const singleFileReject = (fileRejection: FileRejection) => {
    if (ACCEPTED_VIDEO_MIME_TYPES.includes(fileRejection?.file?.type)) {
      if (
        fileRejection?.file?.size > FILE_SIZE.VIDEO_FILE_SIZE &&
        ACCEPTED_VIDEO_MIME_TYPES.includes(fileRejection?.file?.type)
      )
        toast.error(
          `File size too large. Please select a file less than ${FILE_SIZE.VIDEO_FILE_SIZE / MEGABYTE_CONVERTER} MB`,
        );
    } else if (
      DOCUMENT_VERIFICATION_MIME_TYPES.includes(fileRejection?.file?.type) ||
      PDF_MIME_TYPE.includes(fileRejection?.file?.type)
    ) {
      if (
        fileRejection?.file?.size > FILE_SIZE.IMAGE_FILE_SIZE &&
        DOCUMENT_VERIFICATION_MIME_TYPES.includes(fileRejection?.file?.type)
      )
        toast.error(
          `File size too large. Please select a file less than ${FILE_SIZE.IMAGE_FILE_SIZE / MEGABYTE_CONVERTER} MB`,
        );
      if (fileRejection?.file?.size > FILE_SIZE.DOCUMENT_FILE_SIZE && PDF_MIME_TYPE.includes(fileRejection?.file?.type))
        toast.error(
          `File size too large. Please select a file less than ${FILE_SIZE.DOCUMENT_FILE_SIZE / MEGABYTE_CONVERTER} MB`,
        );
    } else toast.error('Please upload a file with a valid format');
  };

  const multipleFileReject = (fileRejection: FileRejection) => {
    if (!acceptedFileFormat?.includes(fileRejection?.file?.type)) {
      return toast.error(`Please upload a file with a valid format`);
    } else {
      return toast.error(`${t('multiFileSizeErrorMessage')} ${maxFileSize / MEGABYTE_CONVERTER} MB`);
    }
  };

  // HTML
  return (
    <>
      <input {...getInputProps()} />
      <TButton
        sx={{ mr: 1 }}
        {...getRootProps({ className: `dropzone ${btnWidthSize}` })}
        icon={showIcon ? <Box className="icon-upload" sx={{ pr: 1 }} /> : <></>}
        btnText={btnText}
        btnWidthSize={btnWidthSize}
        variant={btnVariant}
        onClick={open}
      />
    </>
  );
};

export default SingleAndMultipleUpload;
