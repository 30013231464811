import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import {
  postUploadedDoc,
  draftOrCreateAddressIdProcess,
  retriveProfile,
  getAddressIdProcessData,
  verificationItemById,
} from '../../../api/idProcesses';
import {
  AddressIdProcessType,
  AddressType,
  AddressResultType,
  AddressVerificationItems,
  AddressVerificationDocDetailsType,
} from '../../../types/idProcessTypes';
import { ProfileDetails } from '../../../types/profileTypes';

type AddressState = {
  idProcessItem: AddressIdProcessType;
  loading: boolean;
  uploading: boolean;
  error: boolean;
  errorObj: any;
  uploadedPhoto: any;
  uploadedPhotoToPreview: any;
  address: AddressType;
  selectedAddressToPreview: AddressResultType;
  selectedProfileToPreview: ProfileDetails;
  selectedType: string;
  profileAddressId: string;
  profileDetailsList: ProfileDetails[];
  resetFile: boolean;
  previousProfile: string;
  verificationItems: AddressVerificationItems;
  selectedAddressVerificationDocument: AddressVerificationDocDetailsType;
};

const initialState: AddressState = {
  idProcessItem: <AddressIdProcessType>{},
  address: <AddressType>{},
  loading: false,
  uploading: false,
  error: false,
  errorObj: {},
  uploadedPhoto: {},
  uploadedPhotoToPreview: {},
  selectedAddressToPreview: <AddressResultType>{},
  selectedProfileToPreview: <ProfileDetails>{},
  selectedType: '',
  profileAddressId: '',
  profileDetailsList: [],
  resetFile: false,
  previousProfile: '',
  verificationItems: <AddressVerificationItems>{},
  selectedAddressVerificationDocument: <AddressVerificationDocDetailsType>{},
};

export const selectIdeProcessDetails = ({ addressVerification }: RootState) => ({
  // here we will get data for id process item
  loading: addressVerification.loading,
  idProcessItem: addressVerification.idProcessItem,
  uploadedPhoto: addressVerification.uploadedPhoto,
  uploadedPhotoToPreview: addressVerification.uploadedPhotoToPreview,
  address: addressVerification.address,
  selectedAddressToPreview: addressVerification.selectedAddressToPreview,
  selectedProfileToPreview: addressVerification.selectedProfileToPreview,
  selectedType: addressVerification.selectedType,
  profileAddressId: addressVerification.profileAddressId,
  uploading: addressVerification.uploading,
  profileDetailsList: addressVerification.profileDetailsList,
  resetFile: addressVerification.resetFile,
  previousProfile: addressVerification.previousProfile,
  addressVerificationItems: addressVerification.verificationItems,
  selectedAddressVerificationDocument: addressVerification.selectedAddressVerificationDocument,
});

export const getProcessDetailsById = createAsyncThunk('addressVerification/getProcessDetailsById', async () => {
  return await getAddressIdProcessData();
});

export const setUploadedPhoto = createAsyncThunk('addressVerification/setUploadedPhoto', async (image: any) => {
  return await postUploadedDoc(image);
});

export const setSelectedAddressToStore = createAsyncThunk(
  'addressVerification/setSelectedAddressToStore',
  async (address: any) => {
    return address;
  },
);

export const setSelectedProfileToStore = createAsyncThunk(
  'addressVerification/setSelectedProfileToStore',
  async (profile: any) => {
    return profile;
  },
);

export const setSelectedType = createAsyncThunk('addressVerification/setSelectedType', async (type: string) => {
  return type;
});

export const createAddressVerification = createAsyncThunk(
  'addressVerification/createAddressVerification',
  async (formdata: object) => {
    return await draftOrCreateAddressIdProcess(formdata);
  },
);

export const getProfilesList = createAsyncThunk('addressVerification/getProfilesList', async (params: object) => {
  return await retriveProfile(params);
});

export const getVerificationItemsByProcessId = createAsyncThunk(
  'addressVerification/getVerificationItemByProcessId',
  async (processId: number) => {
    return await verificationItemById(processId);
  },
);

export const addressVerificationSlice = createSlice({
  name: 'addressVerification',
  initialState,
  reducers: {
    setProfileAddressId: (state, action) => {
      state.profileAddressId = action.payload;
    },
    setPreviousProfile: (state, action) => {
      state.previousProfile = action.payload;
    },
    resetUploadedPhoto: (state) => {
      state.uploadedPhoto = {};
    },
    resetUploadedFile: (state, action) => {
      state.resetFile = action.payload;
    },
    setSelectedAddressVerificationDocument: (state, action) => {
      state.selectedAddressVerificationDocument = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessDetailsById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProcessDetailsById.fulfilled, (state, action: any) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.idProcessItem = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getProcessDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(setUploadedPhoto.pending, (state) => {
        state.loading = true;
        state.uploading = true;
        state.error = false;
      })
      .addCase(setUploadedPhoto.fulfilled, (state, image: any) => {
        state.error = false;
        state.loading = false;
        state.uploading = false;
        if (image) {
          state.uploadedPhoto = image.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setSelectedType.fulfilled, (state, type: any) => {
        state.error = false;
        state.loading = false;
        if (type) {
          state.selectedType = type.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setSelectedAddressToStore.fulfilled, (state, address: any) => {
        state.error = false;
        state.loading = false;
        if (address.payload) {
          state.selectedAddressToPreview = address.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setSelectedProfileToStore.fulfilled, (state, profile: any) => {
        state.error = false;
        state.loading = false;
        if (profile.payload) {
          state.selectedProfileToPreview = profile.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(setUploadedPhoto.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(createAddressVerification.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createAddressVerification.fulfilled, (state, action) => {
        state.error = false;
        state.loading = false;
        if (action.payload) {
          state.error = false;
        } else {
          state.error = true;
        }
      })
      .addCase(createAddressVerification.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getProfilesList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProfilesList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.profileDetailsList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getProfilesList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getVerificationItemsByProcessId.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getVerificationItemsByProcessId.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.verificationItems = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getVerificationItemsByProcessId.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setProfileAddressId,
  setPreviousProfile,
  resetUploadedPhoto,
  resetUploadedFile,
  setSelectedAddressVerificationDocument,
} = addressVerificationSlice.actions;
export default addressVerificationSlice.reducer;
