import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useQuery from '../../../hooks/useQuery';

import ContainerCard from '../../../components/common/ContainerCard';
import { FilePreviewType, theme, TLoader } from '../../../components/myde-react-components';
import { customIdProcessDetails } from '../../../redux/feature/idProcess/customIdProcessSlice';
import { SupplementalIdProcessResults } from '../../../types/dashboardTypes';
import PreviewFileByType from '../../../components/common/PreviewFileByType';
import { FileType } from '../../../types/documentTypes';
import { setIsEditAllowedFlag, setChildRoute } from '../../../redux/feature/common/commonSlice';
import * as ROUTES from '../../../constants/routes';
import { AUTHORIZATION_STATUS } from '../../../constants/constants';
import {
  getIdProcessSharedCount,
  getIdProcessSharedCountForEntity,
  getSharedMerchantsList,
  idProcessShareDetails,
  setCustomIdProcessId,
} from '../../../redux/feature/idProcess/idProcessShareSlice';
import ViewShareDetailsCard from '../../../components/id-process/flows/ViewShareDetailsCard';
import { useRouter } from '../../../providers/custom-router-provider';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';

const CategoryDocument = () => {
  //Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const isEntity = useQuery().get('isEntity') || false;

  //Redux values
  const { selectedCategory, loading } = useSelector(customIdProcessDetails);
  const { selectedEntity } = useSelector(selectEntity);
  const { merchantCount, merchantCountForEntity } = useSelector(idProcessShareDetails);
  const { routeTo } = useRouter();

  //State values
  const [category, setCategory] = useState({} as SupplementalIdProcessResults);
  const [files, setFiles] = useState([] as FilePreviewType[]);
  const [merchantSharedCount, setMerchantSharedCount] = useState(0);

  //Use Effects
  useEffect(() => {
    dispatch(setIsEditAllowedFlag(true));
  }, []);

  useEffect(() => {
    const data = getData(['id'], selectedCategory?.id, 1000);
    if (isEntity) {
      const payload = {
        entity_uid: selectedEntity?.trellis_uid,
        params: { account_id_process_id: selectedCategory?.id },
      };
      dispatch(getIdProcessSharedCountForEntity(payload));
    } else {
      dispatch(getIdProcessSharedCount(data));
    }
  }, [isEntity, selectedEntity, selectedCategory]);

  useEffect(() => {
    const countOfMerchant = isEntity ? merchantCountForEntity : merchantCount;
    setMerchantSharedCount(countOfMerchant);
  }, [merchantCount, merchantCountForEntity, isEntity]);

  useEffect(() => {
    if (selectedCategory?.id) {
      const fileList: any[] = [];
      const route = {
        path: ROUTES.INDEX,
        isChild: true,
        stateName: 'Custom ID',
        query: isEntity ? `id=${selectedCategory?.id}&isEntity=${isEntity}` : `id=${selectedCategory?.id}`,
        stateObj: null,
      };
      dispatch(setChildRoute(route));
      setCategory(selectedCategory);
      selectedCategory?.process_data?.document_list?.forEach((file: FileType) => {
        const data = {
          name: file?.doc_name,
          type: file?.mime_type,
          preview: file?.signed_url,
          size: file?.size_in_kb,
        };
        fileList?.push(data);
      });
      const combineFile = [...fileList];
      setFiles(combineFile);
    }
  }, [selectedCategory]);

  const renderRequirements = () => {
    return (
      <>
        <Box sx={{ pt: theme.spacing(7), px: 3 }}>
          {category?.process_data?.process_description && (
            <>
              <Box
                sx={{ pb: theme.spacing(5) }}
                className="flex-basic-start text-medium  font-weight-semibold textColor-200"
              >
                {t('descriptionText')}
              </Box>
              <Box sx={{ pb: theme.spacing(5) }} className="text-small font-weight-medium">
                {category?.process_data?.process_description}
              </Box>
            </>
          )}
          {category?.process_data?.document_id_list && category?.process_data?.document_id_list?.length > 0 && (
            <>
              <Box
                sx={{ pb: theme.spacing(5) }}
                className="flex-basic-start text-medium  font-weight-semibold textColor-200"
              >
                {t('textDocuments')}
              </Box>
              <Box sx={{ pb: 4 }}>
                <PreviewFileByType files={files} />
              </Box>
            </>
          )}
        </Box>
      </>
    );
  };

  const getData = (query: string[], account_id_process_id: string, limit: number) => {
    const data = {
      authorization_status: AUTHORIZATION_STATUS.SHARED,
      page: 1,
      account_id_process_id: account_id_process_id,
      query: `{ ${query.toString()} }`,
      limit: limit,
    };
    return data;
  };

  const handleViewClick = async () => {
    dispatch(setIsEditAllowedFlag(false));
    await dispatch(setCustomIdProcessId(selectedCategory?.id));
    const query = isEntity
      ? {
          isEntity: true,
        }
      : {};
    if (!isEntity) {
      const data = getData(['id', 'merchant, created_on'], selectedCategory?.id, 1000);
      await dispatch(getSharedMerchantsList(data));
    }
    routeTo(ROUTES.SHARED_ID, true, `Custom ID`, query);
  };

  return (
    <>
      <TLoader loading={loading} />
      <ContainerCard content={renderRequirements()} />
      {merchantSharedCount > 0 && (
        <ViewShareDetailsCard
          isCustom={true}
          handleViewClick={handleViewClick}
          merchantSharedCount={merchantSharedCount}
          processId={selectedCategory?.id}
        />
      )}
    </>
  );
};

export default CategoryDocument;
