import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputAdornment, Box, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { zodResolver } from '@hookform/resolvers/zod';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { TTextField, TDatePicker, TSelect, TButton, TPaper, theme } from '../../../../components/myde-react-components';
import { formatDate } from '../../../../utils/utils';
import {
  selectIdProcessDetails,
  setSelectedAddressToStore,
  getProcessUnmaskedId,
  createSsnVerification,
  setSsnCreatedFlag,
} from '../../../../redux/feature/idProcess/ssnVerificationSlice';
import { selectProfile } from '../../../../redux/feature/profile/profileSlice';
import { ProfileDetails } from '../../../../types/profileTypes';
import { SsnFormSchema, SsnFormSchemaPayload } from './validation';
import { PROCESS_IDS, TAX_ID_REGEX, VERIFICATION_ACTIONS } from '../../../../constants/constants';
import { selectSelectedIdProcessDetails } from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { ID_PROCESS_STATUS_COMPLETION_STATE } from '../../../myde-react-components/src/constants/constants';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { t } from 'i18next';

const useStyles = makeStyles({
  fieldWidth: {
    width: '100%',
  },
});

const TaxIdForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Redux Values
  const { unmaskedId, isSsnCreated } = useSelector(selectIdProcessDetails);
  const { profileList } = useSelector(selectProfile);
  const { selectedIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);

  // State Values
  const [formDetail, setFormDetail] = React.useState({
    ssn: '',
    dob: '',
  });
  const [addressValue, setAddressValue] = React.useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [unmaskedTaxId, setUnmaskedTaskId] = useState('');
  const [selectedProfileDetail, setSelectedProfile] = useState({} as ProfileDetails);

  const { handleSubmit, control, formState, reset, setValue } = useForm<SsnFormSchemaPayload>({
    resolver: zodResolver(SsnFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;

  // Use Effects
  useEffect(() => {
    if (profileList.length === 1) {
      setAddressValue(profileList[0]?.profile_name);
      setSelectedProfile(profileList[0]);
    }
  }, [profileList]);
  useEffect(() => {
    reset(formDetail);
  }, [formDetail]);
  useEffect(() => {
    const data = {
      processId: PROCESS_IDS.SSN_VERIFICATION,
      params: {
        visibility_level: 'source',
      },
    };
    dispatch(getProcessUnmaskedId(data));
    setUnmaskedTaskId(unmaskedId);
  }, [unmaskedId]);
  useEffect(() => {
    if (selectedIdProcessDetails?.data?.profile?.id) {
      setFormDetail({
        ssn: unmaskedTaxId,
        dob: formatDate(selectedIdProcessDetails?.data?.dob),
      });
      setAddressValue(selectedIdProcessDetails?.data?.profile?.profile_name);
      setSelectedProfile(selectedIdProcessDetails?.data?.profile);
    }
  }, [selectedIdProcessDetails, unmaskedTaxId]);
  useEffect(() => {
    if (isSsnCreated) {
      dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
      dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
      dispatch(setSsnCreatedFlag(false));
    }
  }, [isSsnCreated]);

  // Methods
  const handleChange = (event: any) => {
    const filterredAddress: ProfileDetails =
      profileList?.find((item: ProfileDetails) => item?.profile_name === event?.target?.value) ||
      ({} as ProfileDetails);
    setAddressValue(filterredAddress?.profile_name);
    setSelectedProfile(filterredAddress);
    dispatch(setSelectedAddressToStore(filterredAddress));
  };
  const existingAddressData = () => {
    const addObj: { label: string; id: string }[] = [];
    profileList?.forEach((element: ProfileDetails) => {
      addObj.push({
        label: element?.profile_name,
        id: element?.profile_name,
      });
    });
    return addObj;
  };
  const handleDateChange = (value: string) => {
    setValue('dob', value, { shouldValidate: true });
    setFormDetail({ dob: value, ssn: formDetail?.ssn });
  };
  const handleTaxIdChange = (_event: React.SyntheticEvent, value: string) => {
    if (value && !TAX_ID_REGEX.test(value)) {
      return;
    } else {
      setValue('ssn', value, { shouldValidate: true });
      setFormDetail({ dob: formDetail?.dob, ssn: value });
    }
    return value;
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const addTaxId = () => {
    const data = {
      process_id: PROCESS_IDS.SSN_VERIFICATION,
      process_data: {
        ssn: formDetail?.ssn,
        dob: formDetail?.dob,
        profile_id: selectedProfileDetail?.id,
      },
      action: VERIFICATION_ACTIONS.SUBMIT,
    };
    dispatch(createSsnVerification(data));
  };

  // HTML
  return (
    <>
      <TPaper className="flex-basic-center">
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              className="text-center textColor-300 text-large font-weight-semibold"
              sx={{ px: 3, py: theme.spacing(5) }}
            >
              {t('formPageHeader', { ns: 'ssn' })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider sx={{ mb: 1 }} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 3, py: theme.spacing(5) }}>
            <form>
              <Grid container spacing={theme.spacing(5)}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl className={classes.fieldWidth}>
                    <TSelect
                      id="type"
                      value={addressValue}
                      options={existingAddressData()}
                      label="Profile"
                      itemId="id"
                      itemValue="label"
                      fullWidth
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Controller
                    name="dob"
                    defaultValue={formDetail.dob}
                    control={control}
                    render={({ field }) => (
                      <TDatePicker
                        {...field}
                        label="Birthdate"
                        variant="outlined"
                        format="YYYY-MM-DD"
                        disableFuture={true}
                        clearable={true}
                        onChange={(e) => handleDateChange(e)}
                        renderInput={(params) => (
                          <TTextField
                            {...params}
                            fullWidth
                            error={Boolean(errors.dob)}
                            helperText={errors.dob?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Controller
                    name="ssn"
                    defaultValue={unmaskedTaxId}
                    control={control}
                    render={({ field }) => (
                      <TTextField
                        {...field}
                        label="Tax ID"
                        variant="outlined"
                        fullWidth
                        type={passwordShown ? 'text' : 'password'}
                        error={Boolean(errors.ssn)}
                        helperText={errors.ssn?.message}
                        onChange={(event) => {
                          const { value } = event.target;
                          handleTaxIdChange(event, value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment position="end">
                                <TButton
                                  variant="text"
                                  onClick={togglePassword}
                                  onMouseDown={handleMouseDownPassword}
                                  icon={
                                    <>
                                      <Box className="infoIcon">
                                        {passwordShown ? (
                                          <VisibilityOutlinedIcon
                                            fontSize="large"
                                            className="textColor-200 icon-size-20"
                                          />
                                        ) : (
                                          <VisibilityOffOutlinedIcon
                                            fontSize="large"
                                            className="textColor-200 icon-size-20"
                                          />
                                        )}
                                      </Box>
                                    </>
                                  }
                                />
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box sx={{ px: 2, pt: theme.spacing(4), pb: theme.spacing(3) }} className={'text-center'}>
                    <TButton
                      variant={'contained'}
                      btnWidthSize={'button-w-240'}
                      btnText={'Submit'}
                      disabled={!isValid || isSubmitting}
                      onClick={handleSubmit(addTaxId)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Divider />
        </Grid>
      </TPaper>
    </>
  );
};
export default TaxIdForm;
