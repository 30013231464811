import { apiGet, apiPost } from './axios';

export const entityProcessDetailsById = async (processId: number, entityUid: number, params?: object) => {
  const res = await apiGet(`/entity/${entityUid}/id-process/${processId}`, { params: params || {} });
  return res.data;
};

export const entityProfileList = async (entityUid: number, params: object) => {
  const res = await apiGet(`/entity/${entityUid}/profile`, { params: params });
  return res.data;
};

export const addProfileForEntity = async (entityUid: number, formData: object) => {
  const res = await apiPost(`/entity/${entityUid}/profile`, formData);
  return res.data;
};

export const entityProfileDetailsById = async (profileId: string, entityUid: number) => {
  const res = await apiGet(`/entity/${entityUid}/profile/${profileId}`);
  return res.data;
};

export const newEntityIdProcess = async (entityUid: number, formData: object) => {
  const res = await apiPost(`/entity/${entityUid}/id-process`, formData);
  return res.data;
};
