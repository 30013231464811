import { Box, Divider, Grid } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { PORTAL } from '../../constants/portal';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { makeStyles } from '@mui/styles';
import ReactPlayer from 'react-player';
import { BiometricIdProcessType } from '../../../../../types/idProcessTypes';
import {
  ID_PROCESS_STATUS,
  MERCHANT_FACE_SEARCH_STATUS_TEXT,
  MYDE_FACE_SEARCH_STATUS_TEXT,
} from '../../constants/constants';
import { SectionTitleType, WordsFoundResultType } from '../../types/faceWordSearchTypes';

interface FaceWordSearchProps {
  portalType: string;
  faceWordSearchData: BiometricIdProcessType;
  sectionTitles: SectionTitleType;
}

const useStyles = makeStyles({
  previewImageContainer: {
    height: '100%',
    objectFit: 'fill',
  },
  profileImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '10px',
  },
  uploadedVideo: {
    '& video': {
      borderRadius: '10px',
    },
  },
});

export const FaceWordSearch = ({ portalType, faceWordSearchData, sectionTitles }: FaceWordSearchProps) => {
  const classes = useStyles();
  const [wordSearchvalues, setWordSearchValues] = useState([] as WordsFoundResultType[]);

  //useEffect
  useEffect(() => {
    setWordSearchValues(faceWordSearchData?.result?.word_search_results?.words_found);
  }, [faceWordSearchData]);

  return (
    <>
      {Object.keys(faceWordSearchData?.result)?.includes('similarity_percentage') && (
        <>
          <Box className="flex-basic-space-between">
            <Box>
              <Box className="textColor-300 text-small font-weight-regular">{sectionTitles?.faceSearch}</Box>
            </Box>
            <Box className="flex-basic-end">
              <span
                className={clsx('actionIconFont-24', {
                  ['icon-review-needed success-color-state']:
                    faceWordSearchData?.result?.face_search_status === ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
                  ['icon-approved success-color-state']:
                    faceWordSearchData?.result?.face_search_status === ID_PROCESS_STATUS.PASSED,
                  ['icon-review-needed error-color-state']:
                    faceWordSearchData?.result?.face_search_status === ID_PROCESS_STATUS.FAILED,
                })}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }} className="flex-basic-space-between">
            <Box>
              <Box className="textColor-200 text-large font-weight-semibold">
                {portalType === PORTAL.MERCHANT
                  ? MERCHANT_FACE_SEARCH_STATUS_TEXT[faceWordSearchData?.result?.face_search_status]
                  : MYDE_FACE_SEARCH_STATUS_TEXT[faceWordSearchData?.result?.face_search_status]}
              </Box>
            </Box>
            <Box className='lassName="flex-basic-end"'>
              <Box className="textColor-200 font-weight-semibold">
                {`${faceWordSearchData?.result?.similarity_percentage || 0}%`}
              </Box>
            </Box>
          </Box>
          {portalType === PORTAL.MERCHANT && (
            <Grid container spacing={2} sx={{ mt: 4 }}>
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.previewImageContainer}>
                <Box className="textColor-300 text-small font-weight-regular">{sectionTitles?.imgFromIdDoc}</Box>
                <Box sx={{ mt: 1 }}>
                  {faceWordSearchData?.data?.user_image?.image ? (
                    <img
                      src={`data:image/jpeg;base64,${faceWordSearchData?.data?.user_image?.image}`}
                      loading="lazy"
                      className={classes.profileImage}
                      alt="passport"
                    />
                  ) : (
                    <PersonOutlineIcon className="icon-size-72" />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.previewImageContainer}>
                <Box className="textColor-300 text-small font-weight-regular">{sectionTitles?.uploadedVideo}</Box>
                <Box sx={{ mt: 1 }} className={classes.previewImageContainer}>
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={faceWordSearchData?.data?.video?.signed_url}
                    controls={true}
                    className={classes.uploadedVideo}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {portalType === PORTAL.MYDE && <Divider sx={{ mt: 2, mb: 2 }} />}
        </>
      )}
      {Object.keys(faceWordSearchData?.result?.word_search_results?.words_found)?.length > 0 && (
        <>
          <Box sx={{ mb: 1, mt: 2 }} className="textColor-300 text-small font-weight-regular">
            {sectionTitles?.wordSearch}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box sx={{ mb: 1 }} className="textColor-200 text-large font-weight-semibold">
                {faceWordSearchData?.result?.word_search_results?.count_of_words_found
                  ? faceWordSearchData?.result?.word_search_results?.count_of_words_found
                  : 'No'}
                {` ${sectionTitles?.wordRecognized}`}
              </Box>
            </Grid>
            <Grid item xs={4} className="flex-basic-end">
              <Box className="textColor-200 text-large font-weight-semibold">
                {`${faceWordSearchData?.result?.word_search_results?.word_search_score || 0}%`}
              </Box>
            </Grid>
          </Grid>
          {faceWordSearchData?.result?.word_search_results?.words_found?.length > 0 && (
            <Grid container spacing={2}>
              <Grid item sx={{ mt: 2 }} xs={8}>
                {faceWordSearchData?.result?.word_search_results?.words_found?.map((word, index) => (
                  <Box sx={{ mb: 2 }} key={index} className="textColor-200 text-large textCapitalize">
                    {word?.word}
                  </Box>
                ))}
              </Grid>
              <Grid item xs={4} sx={{ mt: 2 }} className="flex-column-end">
                {wordSearchvalues?.map((word, index) => (
                  <Box
                    sx={{ mb: 2 }}
                    key={index}
                    className={clsx(
                      'actionIconFont-24',
                      { 'icon-close error-color-state': !word?.is_found },
                      { 'icon-checked success-color-state': word?.is_found },
                    )}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          {portalType === PORTAL.MERCHANT && <Divider sx={{ mt: 3, mb: 4 }} />}
        </>
      )}
    </>
  );
};
