export const MEGABYTE_CONVERTER = 1000000;
export const BUILD_NUMBER = '0.0.32.0';
export const BANKVAULT_HOST_URL = 'kbapi-trellis-1.bankvault.com';

export const FILE_SIZE = {
  IMAGE_FILE_SIZE: 10000000, //10mb
  VIDEO_FILE_SIZE: 50000000, //50mb
  DOCUMENT_FILE_SIZE: 10000000, //10mb
  CUSTOM_DOCUMENT_FILE_SIZE: 500000000, // 500mb
};
export const ID_PROCESS_STATUS = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUBMITTED: 'SUBMITTED',
  SYSTEM_APPROVED: 'SYSTEM_APPROVED',
  IN_DRAFT: 'IN_DRAFT',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  VERIFIED: 'VERIFIED',
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
  NEEDS_MANUAL_REVIEW: 'NEEDS_MANUAL_REVIEW',
  VERIFICATION_NEEDS_MANUAL_REVIEW: 'VERIFICATION_NEEDS_MANUAL_REVIEW',
  VERIFICATION_IN_PROGRESS: 'VERIFICATION_IN_PROGRESS',
};

export const ACCEPTED_VIDEO_MIME_TYPES = ['video/mp4', 'video/quicktime'];

export const ACCEPTED_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

export const ACCEPTED_CSV_MIME_TYPES = ['text/csv'];

export const SUPPORTED_IMAGE_TYPES = ['JPEG', 'PNG', 'JPG', 'PDF'];

export const PDF_SUPPORTED_TYPE = ['PDF'];

export const SUPPORTED_VIDEO_TYPES = ['MP4', 'MOV'];

export const DOCUMENT_VERIFICATION_IMAGE_TYPES = ['JPEG', 'PNG', 'JPG'];

export const DOCUMENT_VERIFICATION_MIME_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export const PDF_MIME_TYPE = ['application/pdf'];

export const ITEMS_PER_PAGE_LIST = [10, 20, 50, 100];

export const W9_W8_BEN_DOCUMENT_TYPE = ['W-9', 'W-8BEN'];

export const MAX_CHAR_LIMIT = {
  BASIC_CHAR_LENGTH: 20,
  CHAR_LENGTH_THIRTY: 30,
  SUMMARY_STRING_LENGTH: 100,
  INVITE_MSG: 200,
  CHAR_LENGTH: 300,
};

// List of ID process status for which we don't want to show Premium block card.
export const REMOVE_PRIMIUM_FOR_ID_PROCESS_STATUS = [
  ID_PROCESS_STATUS.PASSED,
  ID_PROCESS_STATUS.SYSTEM_APPROVED,
  ID_PROCESS_STATUS.VERIFIED,
  ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
  ID_PROCESS_STATUS.VERIFICATION_NEEDS_MANUAL_REVIEW,
];

export const GPLACES_ADDRESS_CONSTRAINTS = {
  COUNTRY: 'country',
  STATE: 'administrative_area_level_1',
  CITY: 'locality',
  ZIP: 'postal_code',
};

export enum PhoneCategory {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export const MESSAGE_RESPONDER_TYPES = {
  MERCHANT: 'Merchant',
  USER: 'User',
};

export const IDLE_LOGOUT = {
  WARNING_TIME: 890 * 1000, //10 seconds before 15 minutes
  LOGOUT_TIME: 900 * 1000, //15 minutes
};

export const DEFAULT_TABLE_OPTIONS = {
  selectableRows: 'none',
  print: false,
  download: false,
  search: false,
  filter: false,
  viewColumns: false,
  textLabels: {
    body: {
      toolTip: '',
    },
  },
};

export interface ClickHandlerType {
  (param?: any): void;
}

export interface BtnType {
  id: number;
  title: string;
  variant?: 'contained' | 'outlined' | 'text';
  buttonAction: ClickHandlerType;
  disabled: boolean;
}

export const FORM_STEPS = {
  START: 0,
  EDIT: 1,
  DONE: 100,
  DETAILS: 99,
};

export const ID_PROCESS_STATUS_COMPLETION_STATE = [
  {
    percent: FORM_STEPS.START,
    statuses: '',
  },
  {
    percent: FORM_STEPS.EDIT,
    statuses: [ID_PROCESS_STATUS.IN_DRAFT],
  },
  {
    percent: FORM_STEPS.DONE,
    statuses: [
      ID_PROCESS_STATUS.PASSED,
      ID_PROCESS_STATUS.SYSTEM_APPROVED,
      ID_PROCESS_STATUS.VERIFIED,
      ID_PROCESS_STATUS.PENDING_VERIFICATION,
      ID_PROCESS_STATUS.FAILED,
      ID_PROCESS_STATUS.SUBMITTED,
      ID_PROCESS_STATUS.VERIFICATION_FAILED,
      ID_PROCESS_STATUS.VERIFICATION_PENDING,
      ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
      ID_PROCESS_STATUS.VERIFICATION_NEEDS_MANUAL_REVIEW,
      ID_PROCESS_STATUS.IN_PROGRESS,
    ],
  },
  {
    percent: FORM_STEPS.DETAILS,
    statuses: [
      ID_PROCESS_STATUS.PASSED,
      ID_PROCESS_STATUS.SYSTEM_APPROVED,
      ID_PROCESS_STATUS.VERIFIED,
      ID_PROCESS_STATUS.PENDING_VERIFICATION,
      ID_PROCESS_STATUS.FAILED,
      ID_PROCESS_STATUS.SUBMITTED,
      ID_PROCESS_STATUS.VERIFICATION_FAILED,
      ID_PROCESS_STATUS.VERIFICATION_PENDING,
      ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW,
      ID_PROCESS_STATUS.VERIFICATION_NEEDS_MANUAL_REVIEW,
      ID_PROCESS_STATUS.IN_PROGRESS,
    ],
  },
];

export const ID_PROCESS_KEYS = {
  PROFILE: 'profile_verification',
  SSN: 'ssn',
  LICENCE: 'driving_license',
  PASSPORT: 'passport',
  BIOMETRIC: 'biometric_verification',
  SIGNATURE: 'signature_verification',
  W9: 'w9_document',
  W8: 'w8_ben',
  ADDRESS: 'address_verification',
  KYC: 'kyc_aml',
  INCOME: 'income_verification',
  W9_W8_BEN: 'w9_w8_ben',
  ACCREDITATION: 'accreditation',
  LETTER_OF_GOOD_STANDING: 'letter_of_good_standing',
  FORMATION_DOCUMENT: 'formation_document',
  IRS_LETTER_EVIDENCING_TAX_ID: 'tax_id',
  OWNERSHIP_DOCUMENTS: 'ownership_document',
  DOCUMENT_VERIFICATION: 'document_verification',
  BENEFICIAL_OWNER_DOCUMENTATIOIN: 'beneficial_owner_documentation',
};

export const ID_PROCESS_STEP_KEYS = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
  STEP4: 4,
  STEP5: 5,
};

export const MULTIPLE_ID_PROCESS_SELECTION = [
  ID_PROCESS_KEYS.PROFILE,
  ID_PROCESS_KEYS.LICENCE,
  ID_PROCESS_KEYS.PASSPORT,
];
export const MERCHANT_FACE_SEARCH_STATUS_TEXT: Record<string, string> = {
  NEEDS_MANUAL_REVIEW: 'Face search inconclusive',
  PASSED: 'Face search passed',
  FAILED: 'Face search failed',
};

export const MYDE_FACE_SEARCH_STATUS_TEXT: Record<string, string> = {
  NEEDS_MANUAL_REVIEW: 'Face search inconclusive, you may try re-recording the video',
  PASSED: 'Face search passed',
  FAILED: 'Face search failed',
};

export const PROCESS_IDS = {
  BENEFICIAL_OWNER_DOCUMENTATIOIN: 0,
  PROFILE_VERIFICATION: 51,
  SSN_VERIFICATION: 101,
  DRIVERS_LICENSE: 201,
  PASSPORT: 202,
  BIOMETRIC_VERIFICATION: 301,
  SIGN_VERIFICATION: 401,
  ADDRESS_VERIFICATION: 601,
  KYC_AML: 251,
  W8_W9_DOCUMENT: 500,
  W9_W8_BEN_DOCUMENT: 501,
  INCOME_VERIFICATION: 701,
  ACCREDITATION: 801,
  DOCUMENT_VERIFICATION: 200,
};

export const ENTITY_PROCESS_IDS = {
  PROFILE_VERIFICATION: 5001,
  IRS_LETTER_EVIDENCING_TAX_ID: 10001,
  W8_W9_DOCUMENT: 20001,
  FORMATION_DOCUMENT: 30001,
  OWNERSHIP_DOCUMENT: 40001,
  LETTER_OF_GOOD_STANDING: 50001,
  INCOME_VERIFICATION: 60001,
  ACCREDITATION: 70001,
};

export const NO_STEP_ID_PROCESSES = [PROCESS_IDS.KYC_AML];

export const PROCESS_KEYS_WITH_PROCESS_ID = [
  {
    process_key: ID_PROCESS_KEYS.BENEFICIAL_OWNER_DOCUMENTATIOIN,
    process_title: 'Beneficial Owner Documentation',
    id_processes: [PROCESS_IDS.BENEFICIAL_OWNER_DOCUMENTATIOIN],
  },
  {
    process_key: ID_PROCESS_KEYS.PROFILE,
    process_title: 'Profile(s)',
    id_processes: [PROCESS_IDS.PROFILE_VERIFICATION, ENTITY_PROCESS_IDS.PROFILE_VERIFICATION],
  },
  {
    process_key: ID_PROCESS_KEYS.SSN,
    process_title: 'Tax ID',
    id_processes: [PROCESS_IDS.SSN_VERIFICATION],
  },
  {
    process_key: ID_PROCESS_KEYS.DOCUMENT_VERIFICATION,
    process_title: 'Identity Document',
    id_processes: [PROCESS_IDS.DRIVERS_LICENSE, PROCESS_IDS.PASSPORT, PROCESS_IDS.DOCUMENT_VERIFICATION],
  },
  {
    process_key: ID_PROCESS_KEYS.KYC,
    process_title: 'KYC/AML',
    id_processes: [PROCESS_IDS.KYC_AML],
  },
  {
    process_key: ID_PROCESS_KEYS.BIOMETRIC,
    process_title: 'Biometric Verification',
    id_processes: [PROCESS_IDS.BIOMETRIC_VERIFICATION],
  },
  {
    process_key: ID_PROCESS_KEYS.ADDRESS,
    process_title: 'Address Verification',
    id_processes: [PROCESS_IDS.ADDRESS_VERIFICATION],
  },
  {
    process_key: ID_PROCESS_KEYS.SIGNATURE,
    process_title: 'Wet Signature Verification',
    id_processes: [PROCESS_IDS.SIGN_VERIFICATION],
  },
  {
    process_key: ID_PROCESS_KEYS.W9_W8_BEN,
    process_title: 'W-9/W-8BEN',
    id_processes: [PROCESS_IDS.W9_W8_BEN_DOCUMENT, ENTITY_PROCESS_IDS.W8_W9_DOCUMENT],
  },
  {
    process_key: ID_PROCESS_KEYS.INCOME,
    process_title: 'Income Verification',
    id_processes: [PROCESS_IDS.INCOME_VERIFICATION, ENTITY_PROCESS_IDS.INCOME_VERIFICATION],
  },
  {
    process_key: ID_PROCESS_KEYS.ACCREDITATION,
    process_title: 'Accreditation',
    id_processes: [PROCESS_IDS.ACCREDITATION, ENTITY_PROCESS_IDS.ACCREDITATION],
  },
  {
    process_key: ID_PROCESS_KEYS.LETTER_OF_GOOD_STANDING,
    process_title: 'Letter Of Good Standing',
    id_processes: [ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING],
  },
  {
    process_key: ID_PROCESS_KEYS.FORMATION_DOCUMENT,
    process_title: 'Formation Document(s)',
    id_processes: [ENTITY_PROCESS_IDS.FORMATION_DOCUMENT],
  },
  {
    process_key: ID_PROCESS_KEYS.OWNERSHIP_DOCUMENTS,
    process_title: 'Ownership Document(s)',
    id_processes: [ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT],
  },
  {
    process_key: ID_PROCESS_KEYS.IRS_LETTER_EVIDENCING_TAX_ID,
    process_title: 'IRS Letter Evidencing Tax ID',
    id_processes: [ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID],
  },
];

export const ACCREDITATION_STATUS = {
  ACCREDITED: 'accredited',
  QUALIFIED_PURCHASER: 'qualified_purchaser',
  QUALIFIED_INSTITUTIONAL_BUYER: 'qualified_institution_buyer',
};

export const ACCREDITATION_BADGE_TEXT: Record<string, string> = {
  accredited: 'Accredited',
  qualified_purchaser: 'QP',
  qualified_institution_buyer: 'QIB',
};

export const ENTITY_MEMBER_PERMISSION = [
  {
    label: 'Full Access',
    value: 'FULL_ACCESS',
  },
  {
    label: 'View Only',
    value: 'VIEW_ONLY_ACCESS',
  },
];

export const ENTITY_MEMBERS = {
  OWNERS: 'Owners',
  USERS: 'Users',
};

export const ENTITY_USER_ROLES = {
  OWNERS: 'OWNER',
  USERS: 'USER',
};

export const DIALOG_WIDTH = {
  USER_PORTAL: 303,
  MERCHANT_PORTAL_SMALL: 460,
  MERCHANT_PORTAL_MEDIUM: 560,
  MERCHANT_PORTAL_LARGE: 1100,
};

export const SUPPORT_EMAILS = {
  HELP_ASSISTANCE: 'help@trellisplatform.com',
  CONTACT_US: 'info@trellisplatform.com',
};

export const BADGE_NAME: any = {
  ENTITY: 'Entity',
};

export const DATE_TIME: any = {
  YEAR: 'y',
  MONTH: 'M',
  DAY: 'd',
  HOUR: 'h',
  MINUTE: 'm',
  SECOND: 'now',
};

export const PASSWORD_STRENGTH_LABEL_BY_SCORE: Record<number, string> = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong',
};

export const MAX_PASSWORD_EXPIRY_DAYS = 1000;
export const MIN_PASSWORD_EXPIRY_DAYS = 5;
export const PASSWORD_EXPIRED_DAY = 0;

//entity types
export const ENTITY_TYPE = [
  { id: 'corporation', name: 'Corporation' },
  { id: 'llc', name: 'LLC' },
  { id: 'partnership', name: 'Partnership' },
  { id: 'individual', name: 'Individual' },
  { id: 'non_profit', name: 'NonProfit' },
  { id: 'foreign_corp', name: 'ForeignCorp' },
  { id: 'trust', name: 'Trust' },
];

export const REGEX = {
  WEBSITE:
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/,
  NUMBER_DECIMAL: /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?|0(?:\.\d{1,2})?)$/,
  TAX_ID: /^[A-Za-z0-9]*$/,
};

export const APPLICATION_STATUS = {
  START: 'START',
  DISMISS: 'DISMISS',
  INVITED: 'INVITED',
  SUBMIT: 'SUBMIT',
};

export const ENTITY_INVITE_STATUS_LABEL: Record<string, string> = {
  INPROGRESS: 'In Progress',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
};

export const ENTITY_INVITE_STATUS = {
  INPROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
};

export const ENTITY_INVITE_STATUS_FILTER_OPTIONS = [
  { id: ENTITY_INVITE_STATUS.INPROGRESS, name: 'In Progress' },
  { id: ENTITY_INVITE_STATUS.COMPLETED, name: 'Completed' },
];

export const MERCHANT_USER_ROLES = {
  ADMIN: 'admin',
  COORDINATOR: 'coordinator',
  FORM_ADMIN: 'form admin',
  TAPP_Reviewer: 'tapp reviewer',
  ID_REVIEWER: 'id reviewer',
};
