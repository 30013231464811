import { ID_PROCESS_STATUS_TEXT } from '../../../../constants/constants';
import { ACCREDITATION_STATUS, ENTITY_PROCESS_IDS, ID_PROCESS_STATUS, PROCESS_IDS } from '../constants/constants';

export const getIdProcessVerificationStatus = (status: string, isPremium: boolean) => {
  let updatedStatus = 'Not Verified';
  switch (status) {
    case ID_PROCESS_STATUS.FAILED:
    case ID_PROCESS_STATUS.VERIFICATION_FAILED:
      updatedStatus = ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED;
      break;
    case ID_PROCESS_STATUS.SYSTEM_APPROVED:
    case ID_PROCESS_STATUS.PASSED:
    case ID_PROCESS_STATUS.VERIFIED:
      updatedStatus = ID_PROCESS_STATUS_TEXT.VERIFIED;
      break;
    case ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW:
    case ID_PROCESS_STATUS.VERIFICATION_NEEDS_MANUAL_REVIEW:
      updatedStatus = ID_PROCESS_STATUS_TEXT.VERIFICATION_NEEDS_MANUAL_REVIEW;
      break;
    case ID_PROCESS_STATUS.SUBMITTED:
    case ID_PROCESS_STATUS.PENDING_VERIFICATION:
    case ID_PROCESS_STATUS.VERIFICATION_PENDING:
      if (isPremium) {
        updatedStatus = ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS;
      }
      break;
  }
  return updatedStatus;
};

export const getIdProcessTranslationName = (processId: number) => {
  switch (processId) {
    case PROCESS_IDS.PROFILE_VERIFICATION:
    case ENTITY_PROCESS_IDS.PROFILE_VERIFICATION:
      return 'profile';
    case PROCESS_IDS.SSN_VERIFICATION:
      return 'ssn';
    case PROCESS_IDS.W9_W8_BEN_DOCUMENT:
      return 'w8';
    case ENTITY_PROCESS_IDS.W8_W9_DOCUMENT:
      return 'entityW8';
    case PROCESS_IDS.INCOME_VERIFICATION:
      return 'incomeVerification';
    case PROCESS_IDS.BIOMETRIC_VERIFICATION:
      return 'biometricVerification';
    case PROCESS_IDS.ADDRESS_VERIFICATION:
      return 'addressVerification';
    case PROCESS_IDS.ACCREDITATION:
    case ENTITY_PROCESS_IDS.ACCREDITATION:
      return 'accreditation';
    case ENTITY_PROCESS_IDS.FORMATION_DOCUMENT:
      return 'formationDocument';
    case ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING:
      return 'letterOfGoodStanding';
    case ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID:
      return 'irsLetterEvidencing';
    case ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT:
      return 'entityOwnership';
    case PROCESS_IDS.SIGN_VERIFICATION:
      return 'wetSignature';
    case PROCESS_IDS.DOCUMENT_VERIFICATION:
    case PROCESS_IDS.PASSPORT:
    case PROCESS_IDS.DRIVERS_LICENSE:
      return 'idDocumentFlow';
    default:
      return 'common';
  }
};
export const getIconByProcessId = (processId: number) => {
  switch (processId) {
    case PROCESS_IDS.PROFILE_VERIFICATION:
    case ENTITY_PROCESS_IDS.PROFILE_VERIFICATION:
      return 'icon-profile-users';
    case PROCESS_IDS.SSN_VERIFICATION:
      return 'icon-tax-ID';
    case PROCESS_IDS.W9_W8_BEN_DOCUMENT:
    case PROCESS_IDS.W8_W9_DOCUMENT:
    case ENTITY_PROCESS_IDS.W8_W9_DOCUMENT:
      return 'icon-w8';
    case PROCESS_IDS.DOCUMENT_VERIFICATION:
    case PROCESS_IDS.INCOME_VERIFICATION:
      return 'icon-Identity-document';
    case PROCESS_IDS.BIOMETRIC_VERIFICATION:
      return 'icon-fing-biometrics';
    case PROCESS_IDS.ADDRESS_VERIFICATION:
      return 'icon-location';
    case PROCESS_IDS.ACCREDITATION:
    case ENTITY_PROCESS_IDS.ACCREDITATION:
      return 'icon-accreditation';
    case ENTITY_PROCESS_IDS.FORMATION_DOCUMENT:
      return 'icon-formation-document';
    case ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING:
      return 'icon-letter-of-good-standing';
    case ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID:
      return 'icon-letter-evidence';
    case ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT:
      return 'icon-Identity-document';
    case PROCESS_IDS.SIGN_VERIFICATION:
      return 'icon-wet-signature';
    case PROCESS_IDS.PASSPORT:
      return 'icon-passport';
    case PROCESS_IDS.DRIVERS_LICENSE:
      return 'icon-license';
    default:
      break;
  }
};

export const getAccredationIcon = (status: string) => {
  switch (status) {
    case ACCREDITATION_STATUS.ACCREDITED:
      return 'icon-accredited';
    case ACCREDITATION_STATUS.QUALIFIED_PURCHASER:
      return 'icon-qualified-purchaser';
    case ACCREDITATION_STATUS.QUALIFIED_INSTITUTIONAL_BUYER:
      return 'icon-qualified-institutional-buyer';
    default:
      return 'icon-accreditation';
  }
};

export const getAccredationMsg = (status: string) => {
  switch (status) {
    case ACCREDITATION_STATUS.ACCREDITED:
      return 'accreditedMsg';
    case ACCREDITATION_STATUS.QUALIFIED_PURCHASER:
      return 'qualifiedPurchaserMsg';
    case ACCREDITATION_STATUS.QUALIFIED_INSTITUTIONAL_BUYER:
      return 'qualifiedInstitutionalBuyerMsg';
    default:
      return 'entitydefaultMsg';
  }
};
//common method added to get accredation status text
//please use same key for the other portal
export const getAccredationStatusText = (status: string) => {
  switch (status) {
    case ACCREDITATION_STATUS.ACCREDITED:
      return 'individualAccreditedStatusText';
    case ACCREDITATION_STATUS.QUALIFIED_PURCHASER:
      return 'individualQualifiedPurchaserStatusText';
    default:
      return 'individualDefaultStatusText';
  }
};
