import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { t } from 'i18next';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';

import { TPaper, theme, TLinearProgress, colorPalette } from '../../myde-react-components';
import { MerchantApplicationType } from '../../../types/invitationTypes';
import {
  getRequiredIdProcessList,
  selectInviteDashboardDetails,
  setApplicationModalFlag,
  setSelectedApplciation,
} from '../../../redux/feature/dashboard/invitationSlice';
import { useTheme } from '../../../providers/custom-theme-provider';
import { INVITE_TYPE, MERCHANT_INVITE_STATUS } from '../../../constants/constants';
import * as ROUTES from '../../../constants/routes';
import { useRouter } from '../../../providers/custom-router-provider';
import { setIsEntity, setEntityUiId } from '../../../redux/feature/entity/entitySlice';
import { hexToRgbA } from '../../../utils/utils';

interface StyleProps {
  currentThemes: Theme;
  matches: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  paper: {
    position: 'relative',
    border: `1px solid ${colorPalette.border.card}`,
    boxShadow: 'none !important',
    borderRadius: '10px !important',
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItemss: 'center',
  },
  brandIcon: {
    width: '32px',
    height: '32px',
  },
  btnStyle: {
    position: 'absolute',
    top: 27,
    right: 25,
    display: 'flex',
  },
  icon: {
    color: colorPalette.typoText.darken1,
    fontSize: '1rem !important',
  },
  progressBar: {
    height: '12px !important',
    borderRadius: 10,
    backgroundColor: `${colorPalette.containerBack.lighten2} !important`,
  },
  nameCountContainer: {
    display: 'flex',
  },
  unreadCountContainer: {
    display: 'flex',
    paddingRight: '16px',
    paddingTop: '4px',
  },
  unreadCountBox: {
    display: 'flex',
    paddingLeft: '83px',
    paddingRight: '10px',
  },
  emailIcon: {
    paddingLeft: '3px',
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  nameCountText: {
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  messageContainer: {
    paddingTop: '15px',
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
  messageCard: {
    display: 'flex',
  },
  merchantTitle: {
    background: colorPalette.containerBack.base,
  },
  entityColors: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
    border: ({ currentThemes }) => `1px solid ${currentThemes.palette.primary.main}`,
  },
});

const PendingApplication = () => {
  // Constants
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
    matches,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  // Redux Values
  const { applicationList } = useSelector(selectInviteDashboardDetails);

  // State Values
  const [merchantInvites, setMerchantInvites] = useState([] as MerchantApplicationType[]);

  // Use Effects
  useEffect(() => {
    if (applicationList?.length > 0) {
      setMerchantInvites(applicationList);
    }
  }, [applicationList]);

  // Methods
  const selectApplication = (application: MerchantApplicationType) => {
    if (application?.id && application?.invitation?.id) {
      dispatch(setSelectedApplciation(application));
      dispatch(getRequiredIdProcessList(application?.invitation?.id));
      if (application?.invitation?.invite_type === INVITE_TYPE.ENTITY) {
        dispatch(setIsEntity(true));
        dispatch(setEntityUiId(application?.account?.trellis_uid));
      } else {
        dispatch(setIsEntity(false));
      }
      if (application?.status !== MERCHANT_INVITE_STATUS.STARTED) {
        dispatch(setApplicationModalFlag(true));
      } else {
        routeTo(ROUTES.VIEW_INVITE);
      }
    }
  };

  // HTML
  return (
    <Box>
      <Box className="textColor-200 text-h3 font-weight-semibold">{t('inProgress', { ns: 'common' })}</Box>
      <Box>
        {merchantInvites?.map((item: MerchantApplicationType) => (
          <TPaper
            key={item?.id}
            sx={{ my: theme.spacing(5), p: 3 }}
            className={clsx(classes.paper, 'cursorPointer')}
            onClick={() => selectApplication(item)}
          >
            <Box className={clsx('flex-basic-center', classes.boxContainer)}>
              <Box sx={{ pt: 1 }} className="imgBox flex-basic-center">
                <img
                  src={item?.merchant?.branding?.logo_thumbnail_url}
                  loading="lazy"
                  alt="Branding Logo"
                  className={classes.brandIcon}
                />
              </Box>

              <Box
                className={clsx('textColor-200 text-large font-weight-semibold', classes.nameCountContainer)}
                sx={{ mt: 1, ml: 1 }}
              >
                <Box className="text-ellipsis width-200">{item?.merchant?.name}</Box>
              </Box>
            </Box>
            <Box className={classes.btnStyle}>
              {item?.total_unread_response_count > 0 && (
                <Box className={classes.unreadCountContainer}>
                  <Box className="iconWithCount">
                    <Box className={clsx('countText flex-basic-center', classes.nameCountText)}>
                      <Box className="text-extra-small">{item?.total_unread_response_count}</Box>
                    </Box>
                    <Box>
                      <MailOutlineOutlinedIcon className={classes.emailIcon} />
                    </Box>
                  </Box>
                </Box>
              )}
              <IconButton edge="end" aria-label="delete">
                <ArrowForwardIosIcon className={classes.icon} />
              </IconButton>
            </Box>
            {item?.invitation?.invite_type === INVITE_TYPE.ENTITY && (
              <Box sx={{ ml: 5, mt: 2 }} className="flex-basic-start">
                <Box className="tex-medium textColor-200 textEllipsis">{item?.account?.name}</Box>
                <Box sx={{ ml: 1 }} className={clsx(classes.entityColors, 'entityBadge')}>
                  Entity
                </Box>
              </Box>
            )}
            <Box sx={{ pt: 2 }}>
              <TLinearProgress
                className={classes.progressBar}
                variant="determinate"
                value={item?.completion_percentage}
              />
            </Box>
          </TPaper>
        ))}
      </Box>
    </Box>
  );
};

export default PendingApplication;
