import React, { useEffect, useState } from 'react';
import { Box, Grid, Theme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { TSwitch, theme, TLoader, colorPalette } from '../../../components/myde-react-components';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import useQuery from '../../../hooks/useQuery';

import { useRouter } from '../../../providers/custom-router-provider';
import { useTheme } from '../../../providers/custom-theme-provider';
import {
  customIdProcessDetails,
  getSpecificIdProcessDetails,
  getSupplementalIdProcessDetails,
  getIdProcessCategoriesList,
  setSelectedCategory,
  getEntityCustomIdProcessList,
  getEntityCustomIdProcessDetail,
} from '../../../redux/feature/idProcess/customIdProcessSlice';
import * as ROUTES from '../../../constants/routes';
import ContainerCard from '../../../components/common/ContainerCard';
import { DocumentListingType, IdProcessResultSet, SupplementalIdProcessResults } from '../../../types/dashboardTypes';
import DocumentListing from './DocumentListing';
import { formatDate, getTruncatedHeader } from '../../../utils/utils';
import { selectEntity } from '../../../redux/feature/entity/entitySlice';
import { FOLDER_NAME_LENGTH } from '../../../constants/constants';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  btnColor: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  btnStyle: {
    background: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  disabledState: {
    color: colorPalette.typoText.disabled,
  },
  fileTitle: {
    marginTop: '4px',
  },
}));

const CustomDocumentList = () => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'userViewMessage']);
  const { routeTo } = useRouter();
  const isEntity = useQuery().get('isEntity') || false;

  //Redux values
  const { loading, supplementalIdProcessList, idProcessCategoriesList } = useSelector(customIdProcessDetails);
  const { selectedEntity } = useSelector(selectEntity);

  //State values
  const [customIdProcessList, setCustomIdProcessList] = useState([] as DocumentListingType[]);
  const [uniqueCategory, setUniqueCategory] = useState([] as string[]);
  const [categoryList, setCategoryList] = useState([] as IdProcessResultSet[]);
  const [isUnCategorized, setIsUnCategorized] = useState(false);

  //Use Effects
  useEffect(() => {
    if (isEntity) {
      dispatch(
        getEntityCustomIdProcessList({
          entityUid: selectedEntity?.trellis_uid,
          filters: { ordering: '-created_on' },
        }),
      );
    } else {
      const params = {
        ordering: '-created_on',
      };
      dispatch(getSupplementalIdProcessDetails(params));
    }
    dispatch(getIdProcessCategoriesList({ limit: 1000 }));
  }, [isEntity]);

  useEffect(() => {
    if (idProcessCategoriesList?.length > 0) {
      setCategoryList(idProcessCategoriesList);
    }
  }, [idProcessCategoriesList]);

  useEffect(() => {
    if (categoryList?.length > 0) {
      const filteredList: Array<string> = [
        ...new Set(categoryList?.map((item: IdProcessResultSet) => item?.category_name)),
      ];
      setUniqueCategory(filteredList);
    }
  }, [categoryList]);

  useEffect(() => {
    if (supplementalIdProcessList?.length > 0) {
      const data = supplementalIdProcessList?.map((item) => {
        return {
          id: item.id,
          title: item?.process_name,
          subtitle: `${formatDate(item?.created_on)} ${item?.process_data?.process_category && '|'} ${
            item?.process_data?.process_category
          }`,
          text: item?.process_data?.process_description,
          documentList: item?.process_data?.document_id_list || [],
          icon: 'icon-letter-evidence',
          show_action: false,
          is_checked: false,
        };
      });
      setCustomIdProcessList(data);
    } else {
      setCustomIdProcessList([] as DocumentListingType[]);
    }
  }, [supplementalIdProcessList]);

  //Methods
  const handleSubmit = () => {
    dispatch(setSelectedCategory({} as SupplementalIdProcessResults));
    if (isEntity) {
      const stateObj = { isEntity: true };
      routeTo(ROUTES.INDEX, true, 'Custom ID', stateObj);
    } else {
      routeTo(ROUTES.INDEX, true, 'Custom ID');
    }
  };

  const getEntityFolderData = async (folderName: string) => {
    const heading = getTruncatedHeader(folderName, FOLDER_NAME_LENGTH);
    const data = {
      entityUid: selectedEntity?.trellis_uid,
      filters: {
        process_category: folderName,
      },
    };
    const query = { folder: folderName, isEntity: isEntity, entityUid: selectedEntity?.trellis_uid };
    await dispatch(getEntityCustomIdProcessList(data));
    routeTo(ROUTES.CUSTOM_CATEGORY_FOLDER, true, heading, query);
  };

  const getUserFolderData = async (folderName: string) => {
    const heading = getTruncatedHeader(folderName, FOLDER_NAME_LENGTH);
    const data = {
      ordering: '-created_on',
      process_category: folderName,
    };
    const query = { folder: folderName };
    await dispatch(getSupplementalIdProcessDetails(data));
    routeTo(ROUTES.CUSTOM_CATEGORY_FOLDER, true, heading, query);
  };

  const handleFolderClick = async (folderName: string) => {
    if (isEntity) {
      getEntityFolderData(folderName);
    } else {
      getUserFolderData(folderName);
    }
  };

  const getEntityCategoryData = (category: SupplementalIdProcessResults) => {
    const data = {
      entityUid: selectedEntity?.trellis_uid,
      uuid: category?.id,
    };
    dispatch(getEntityCustomIdProcessDetail(data));
    const stateObj = { isEntity: isEntity };
    routeTo(ROUTES.CUSTOM_CATEGORY_DOCUMENTS, true, category?.title, stateObj);
  };

  const getUserCategoryData = (category: SupplementalIdProcessResults) => {
    const data = {
      id: category?.id,
    };
    dispatch(getSpecificIdProcessDetails(data));
    routeTo(ROUTES.CUSTOM_CATEGORY_DOCUMENTS, true, category?.title);
  };

  const handleCategoryClick = (category: SupplementalIdProcessResults) => {
    if (isEntity) {
      getEntityCategoryData(category);
    } else {
      getUserCategoryData(category);
    }
  };

  const handleEntityCategorySwitch = () => {
    const data = !isUnCategorized
      ? {
          ordering: '-created_on',
          has_process_category: isUnCategorized,
        }
      : { ordering: '-created_on' };
    setIsUnCategorized(!isUnCategorized);
    dispatch(getSupplementalIdProcessDetails(data));
  };

  const handleUserCategorySwitch = () => {
    const data = !isUnCategorized
      ? {
          entityUid: selectedEntity?.trellis_uid,
          filters: { ordering: '-created_on', has_process_category: isUnCategorized },
        }
      : {
          entityUid: selectedEntity?.trellis_uid,
          filters: { ordering: '-created_on' },
        };
    setIsUnCategorized(!isUnCategorized);
    dispatch(getEntityCustomIdProcessList(data));
  };

  const handleSwitchChange = () => {
    if (!isEntity) {
      handleEntityCategorySwitch();
    } else {
      handleUserCategorySwitch();
    }
  };

  const renderRequirements = () => {
    return (
      <>
        <Box sx={{ py: theme.spacing(5), px: 3 }}>
          <Box className="flex-basic-start text-large font-weight-semibold textColor-300">{t('categories')}</Box>
          <Box sx={{ my: 2 }} className="previewFileContainer flex-basic-start">
            {uniqueCategory?.map((item: string, index: number) => {
              return (
                <Box key={index}>
                  <Box sx={{ m: 1 }}>
                    <Box className="flex-column-center cursorPointer" onClick={() => handleFolderClick(item)}>
                      <Box sx={{ p: 3 }} className="category-background">
                        <Box className="icon-folder-open textColor-200 icon-size-20" />
                      </Box>
                      <Box
                        className={
                          'text-extra-small text-center font-weight-medium text-ellipsis folderNameHiddenWidth'
                        }
                      >
                        {item}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box className={clsx('text-large font-weight-semibold textColor-300', classes.fileTitle)}>
                {t('customDocuments')}
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box className="flex-basic-center">
                <TSwitch themed value={isUnCategorized} checked={isUnCategorized} onChange={handleSwitchChange} />
                <Box
                  className={clsx({
                    ['text-medium font-weight-semibold']: true,
                    [classes.btnColor]: isUnCategorized,
                    [classes.disabledState]: !isUnCategorized,
                  })}
                >
                  {'Uncategorized'}
                </Box>
              </Box>
            </Grid>
          </Grid>
          {customIdProcessList?.length > 0 ? (
            <DocumentListing data={customIdProcessList} handleClick={handleCategoryClick} />
          ) : (
            <Box sx={{ pt: 5 }} className="text-h3 font-weight-semibold textColor-300 text-center">
              {t('nodataAvailable', { ns: 'userViewMessage' })}
            </Box>
          )}
          <Box
            className={clsx('floating-btn document-list flex-basic-center cursorPointer', classes.btnStyle)}
            onClick={handleSubmit}
          >
            <span className="icon-add"></span>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <TLoader loading={loading} />
      <ContainerCard content={renderRequirements()} />
    </>
  );
};

export default CustomDocumentList;
