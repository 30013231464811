import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { ID_CARD_NAV_INDEX, NAVIGATE_TO_NOTIFICATION, NOTIFICATION_NAVIGATE } from '../../constants/notifications';
import { setActiveCardNav, setCardState } from '../../redux/feature/common/commonSlice';
import { useRouter } from '../../providers/custom-router-provider';
import { PROCESS_IDS, PROCESS_KEYS_WITH_PROCESS_ID, TLoader } from '../../components/myde-react-components';
import { selectNotification } from '../../redux/feature/notification/notificationSlice';
import { NotificationsResultType } from '../../components/myde-react-components/src/components/Notifications/notificationsType';
import { ID_DOC_VERIFICATION_IDS, INVITE_TYPE } from '../../constants/constants';
import { selectEntity, setIsEntity, setSelectedEntity } from '../../redux/feature/entity/entitySlice';
import { setIdProcessKey } from '../../redux/feature/idProcess/mainIdProcessSlice';
import {
  selectUser,
  setBrandingData,
  setEntityDetailFlag,
  setEntitySelectionFlag,
} from '../../redux/feature/user/userSlice';
import { EntityTypes } from '../../types/entityTypes';

const NotificationRouting = () => {
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  //state variables
  const [idCardIndex, setIdCardIndex] = useState([] as string[]);

  // Redux values
  const { specificNotification } = useSelector(selectNotification);
  const { selectedEntity } = useSelector(selectEntity);
  const { userBranding } = useSelector(selectUser);

  //useEffect
  useEffect(() => {
    setIdCardIndex(Object.keys(ID_CARD_NAV_INDEX));
  }, []);

  useEffect(() => {
    if (idCardIndex?.includes(specificNotification?.navigate_to)) {
      if (specificNotification?.navigate_to === NOTIFICATION_NAVIGATE.ENTITY_DETAILS) {
        dispatch(setActiveCardNav(ID_CARD_NAV_INDEX.ID));
        dispatch(setEntitySelectionFlag(true));
      } else {
        dispatch(setActiveCardNav(NAVIGATE_TO_NOTIFICATION[specificNotification?.navigate_to]));
      }
      dispatch(setCardState(true));
    } else {
      const query = getQuerySet(specificNotification);
      const headerTitle = getHeaderTitle(specificNotification);
      const isChild = getIsChildRoute(specificNotification);
      checkSwitchForEntityContext(specificNotification);
      routeTo(NAVIGATE_TO_NOTIFICATION[specificNotification?.navigate_to], isChild, headerTitle, query);
      dispatch(setActiveCardNav(ID_CARD_NAV_INDEX.ID));
      dispatch(setCardState(false));
    }
  }, [idCardIndex, specificNotification]);

  // Methods
  const getProcessKeyAndTitle = (id: number) => {
    return PROCESS_KEYS_WITH_PROCESS_ID.find((item) => item?.id_processes?.includes(id));
  };

  const setIdProcessKeyValue = async (id: number) => {
    const idProcessData: any = getProcessKeyAndTitle(id);
    const processKey = idProcessData.process_key || '';
    await dispatch(setIdProcessKey(processKey));
  };

  const checkSwitchForEntityContext = async (notification: NotificationsResultType) => {
    switch (notification?.notification_for) {
      case INVITE_TYPE.ENTITY:
        if (notification?.navigate_to?.indexOf(INVITE_TYPE.ENTITY) !== -1) {
          await dispatch(setSelectedEntity(selectedEntity));
          await dispatch(setIsEntity(true));
          await dispatch(setEntitySelectionFlag(false));
          await dispatch(setEntityDetailFlag(true));
        }
        break;
      default:
        await dispatch(setBrandingData(userBranding));
        await dispatch(setSelectedEntity({} as EntityTypes));
        await dispatch(setEntitySelectionFlag(false));
        await dispatch(setEntityDetailFlag(false));
        await dispatch(setIsEntity(false));
        break;
    }
  };

  const getQuerySet = (notification: NotificationsResultType) => {
    let params = {};
    switch (notification?.navigate_to) {
      case NOTIFICATION_NAVIGATE.PROFILE_LISTING:
      case NOTIFICATION_NAVIGATE.ID_PROCESS_SUMMARY:
        setIdProcessKeyValue(notification?.details?.process_id);
        params = {
          id: ID_DOC_VERIFICATION_IDS.includes(notification?.details?.process_id)
            ? PROCESS_IDS.DOCUMENT_VERIFICATION
            : notification?.details?.process_id,
        };
        break;
      case NOTIFICATION_NAVIGATE.ID_PROCESS_SUMMARY_ENTITY:
        setIdProcessKeyValue(notification?.details?.process_id);
        params = {
          id: notification?.details?.process_id,
          isEntity: true,
        };
        break;
      case NOTIFICATION_NAVIGATE.CUSTOM_ID_PROCESS_CATEGORY_ENTITY:
        params = {
          isEntity: true,
        };
        break;
      case NOTIFICATION_NAVIGATE.MERCHANT_DOCUMENTS:
      case NOTIFICATION_NAVIGATE.MERCHANT_DOCUMENT_ENTITY:
      case NOTIFICATION_NAVIGATE.MERCHANT_DOC_ENTITY:
        params = {
          id: notification?.merchant?.id,
          merchant: notification?.merchant?.name,
          section: 'Documents',
        };
        break;
      case NOTIFICATION_NAVIGATE.MERCHANT_MESSAGE:
      case NOTIFICATION_NAVIGATE.MERCHANT_APPLICATION_DETAIL:
      case NOTIFICATION_NAVIGATE.MERCHANT_APPLICATION_DETAIL_ENTITY:
        const inviteType =
          notification?.notification_for === INVITE_TYPE.ENTITY ? INVITE_TYPE.ENTITY : INVITE_TYPE.INDIVIDUAL;
        params = {
          id: notification?.merchant?.id,
          invite_type: inviteType,
          is_entity: inviteType === INVITE_TYPE.ENTITY,
          merchant: notification?.merchant?.name,
        };
        break;
      default:
        params = {};
        break;
    }
    return params;
  };

  const getHeaderTitle = (notification: NotificationsResultType) => {
    switch (notification?.navigate_to) {
      case NOTIFICATION_NAVIGATE.PROFILE_LISTING:
      case NOTIFICATION_NAVIGATE.ID_PROCESS_SUMMARY:
      case NOTIFICATION_NAVIGATE.ID_PROCESS_SUMMARY_ENTITY:
        return getProcessKeyAndTitle(notification?.details?.process_id)?.process_title || '';
      case NOTIFICATION_NAVIGATE.CUSTOM_ID_PROCESS_CATEGORY_ENTITY:
        return 'Custom ID';
      case NOTIFICATION_NAVIGATE.MERCHANT_MESSAGE:
      case NOTIFICATION_NAVIGATE.MERCHANT_APPLICATION_DETAIL:
      case NOTIFICATION_NAVIGATE.MERCHANT_APPLICATION_DETAIL_ENTITY:
        return notification?.merchant?.name || '';
      default:
        return '';
    }
  };

  const getIsChildRoute = (notification: NotificationsResultType) => {
    switch (notification?.navigate_to) {
      case NOTIFICATION_NAVIGATE.MERCHANT_DOCUMENTS:
      case NOTIFICATION_NAVIGATE.MERCHANT_DOCUMENT_ENTITY:
      case NOTIFICATION_NAVIGATE.MERCHANT_DOC_ENTITY:
      case NOTIFICATION_NAVIGATE.INVITE_LISTING:
      case NOTIFICATION_NAVIGATE.DASHBOARD:
      case NOTIFICATION_NAVIGATE.ID_PROCESS_LISTING:
      case NOTIFICATION_NAVIGATE.ID_PROCESS_LISTING_ENTITY:
      case NOTIFICATION_NAVIGATE.MERCHANT_PRIVACY:
      case NOTIFICATION_NAVIGATE.OPEN_REQUESTS:
      case NOTIFICATION_NAVIGATE.OPEN_REQUEST_RECEIVED:
      case NOTIFICATION_NAVIGATE.ENTITY_PROFILE_DETAILS:
        return false;
      default:
        return true;
    }
  };

  // HTML
  return (
    <>
      <Box>
        <TLoader loading={true} />
      </Box>
    </>
  );
};

export default NotificationRouting;
