import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentListing from '../../../components/common/DocumentListing';
import { TCard, TLoader } from '../../../components/myde-react-components';
import { setParentRoute, setPreviousStep } from '../../../redux/feature/common/commonSlice';
import { getAllInboxCommunications, messageDetails } from '../../../redux/feature/message/messageSlice';
import { ListingType, RouteType } from '../../../types/commonTypes';
import { InboxMessagesResultsType } from '../../../types/messageTypes';
import { formatDate, getTimeStamp } from '../../../utils/utils';
import { debounce } from 'lodash';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import useQuery from '../../../hooks/useQuery';

const MyInboxMessageList = () => {
  //Constants
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const query = useQuery();
  const merchant_id = query.get('merchant_id') || '';
  const entity_uid = query.get('entity_uid') || '';
  const invite_type = query.get('invite_type') || '';
  const merchant = query.get('merchant') || '';
  const is_entity = query.get('is_entity') === 'true';
  const inboxFilters = {
    ordering: '-created_on',
    page: '1',
    limit: '1000',
    query: `{*, merchant_communication{id, title, message, document_list, sent_on}}`,
    merchant_id,
  };

  //Redux
  const { inboxCommunicationList, loading, selectedMerchantDetails } = useSelector(messageDetails);

  //State
  const [inboxList, setInboxList] = useState([] as ListingType[]);
  const [selectedCommunication, setSelectedCommunication] = useState({} as ListingType);
  const [searchText, setSearchText] = useState('');

  //useEffects
  useEffect(() => {
    const route: RouteType = {
      path: ROUTES.USER_MESSAGES_INDEX,
      isChild: true,
      stateName: selectedMerchantDetails?.merchant_name,
      query: { id: merchant_id, entity_uid, is_entity, invite_type, merchant },
      stateObj: undefined,
    };
    dispatch(setParentRoute(route));
    dispatch(setPreviousStep(null));
    dispatch(getAllInboxCommunications(is_entity ? { ...inboxFilters, entity_uid } : inboxFilters));
  }, []);

  useEffect(() => {
    if (inboxCommunicationList?.count) {
      getInboxList(inboxCommunicationList?.results);
    } else {
      setInboxList([] as ListingType[]);
    }
  }, [inboxCommunicationList]);

  useEffect(() => {
    if (selectedCommunication?.id) {
      const query = {
        id: selectedCommunication?.id,
        merchant_id,
        entity_uid,
        is_entity,
        invite_type,
      };
      routeTo(ROUTES.MY_INBOX_MESSAGE_DETAILS, true, selectedCommunication?.title, query);
    }
  }, [selectedCommunication]);

  //Methods
  const getInboxList = (communicationList: InboxMessagesResultsType[]) => {
    const communicationData: ListingType[] = [];
    communicationList?.forEach((communication) => {
      const list: ListingType = {
        id: communication?.merchant_communication?.id,
        title: communication?.merchant_communication?.title,
        subtitle: `${formatDate(communication?.merchant_communication?.sent_on)} | ${getTimeStamp(
          communication?.merchant_communication?.sent_on,
        )}`,
        icon: 'icon-messages',
        hasDocuments: !!communication?.merchant_communication?.document_list?.length,
        isRead: communication?.is_read,
      };
      communicationData.push(list);
    });
    setInboxList(communicationData);
  };

  const handleSearchTextChange = debounce((searchText) => {
    const updatedInboxFilters = is_entity
      ? { ...inboxFilters, entity_uid, title: searchText }
      : { ...inboxFilters, title: searchText };
    setSearchText(searchText);
    dispatch(getAllInboxCommunications(updatedInboxFilters));
  }, 1000);

  //HTML
  return (
    <TCard>
      <TLoader loading={loading} />
      <DocumentListing
        list={inboxList}
        searchText={searchText}
        isMessagesSection={true}
        searchList={handleSearchTextChange}
        setSelectedCommunication={setSelectedCommunication}
      />
    </TCard>
  );
};

export default MyInboxMessageList;
