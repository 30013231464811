import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Grid, Box, Divider, FormControl } from '@mui/material';
import { t } from 'i18next';
import clsx from 'clsx';

import {
  ACCEPTED_IMAGE_MIME_TYPES,
  FilePreviewType,
  FILE_SIZE,
  FORM_STEPS,
  PROCESS_IDS,
  SUPPORTED_IMAGE_TYPES,
  TButton,
  theme,
  TPaper,
  TSelect,
  TLoader,
} from '../../../myde-react-components';
import { getProfileDetailsList, selectProfile } from '../../../../redux/feature/profile/profileSlice';
import {
  selectIdeProcessDetails,
  getVerificationItemsByProcessId,
  getProfilesList,
  setUploadedPhoto,
  createAddressVerification,
  resetUploadedPhoto,
  setSelectedAddressVerificationDocument,
} from '../../../../redux/feature/idProcess/addressVerificationSlice';
import { AddressVerificationDocDetailsType, AddressVerificationOptionsType } from '../../../../types/idProcessTypes';
import { ProfileDetails } from '../../../../types/profileTypes';
import {
  getProcessDetailsById,
  selectSelectedIdProcessDetails,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { replaceSpecialCharAndCapitalize } from '../../../../utils/utils';
import { typography } from '../../../../styles/style';
import UploadOrCapturePhoto from '../../../common/UploadOrCapturePhoto';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { FileType } from '../../../../types/documentTypes';

const AddressForm = () => {
  // Constants
  const dispatch = useDispatch();

  // Redux Values
  const { profileList } = useSelector(selectProfile);
  const { selectedIdProcessDetails, addressDataIndex } = useSelector(selectSelectedIdProcessDetails);
  const { uploadedPhoto, addressVerificationItems, profileDetailsList, selectedAddressVerificationDocument, loading } =
    useSelector(selectIdeProcessDetails);

  // State Values
  const [profileAddress, setProfileAddress] = useState('');
  const [existingProfile, setExistingProfile] = useState<ProfileDetails>({} as ProfileDetails);
  const [preview, setPreview] = useState([] as FilePreviewType[]);
  const [docValue, setDocValue] = useState('');
  const [addressVerificationOptions, setAddressVerificationOptions] = useState([] as AddressVerificationOptionsType[]);
  const [existingAddressList, setExistingAddressList] = useState([] as ProfileDetails[]);
  const [uploadedDoc, setUploadedDoc] = useState({} as FileType);

  // Use Effects
  useEffect(() => {
    dispatch(getVerificationItemsByProcessId(PROCESS_IDS.ADDRESS_VERIFICATION));
    dispatch(getProfileDetailsList({}));
  }, []);

  useEffect(() => {
    const options: AddressVerificationOptionsType[] = [];
    addressVerificationItems?.verification_item?.forEach((item) => {
      options.push({
        label: replaceSpecialCharAndCapitalize(item, '_', ' ', true),
        id: item,
      });
    });
    setAddressVerificationOptions(options);
  }, [addressVerificationItems]);

  useEffect(() => {
    if (existingProfile?.id) {
      const params = {
        address_id: existingProfile?.address?.id,
      };
      dispatch(getProfilesList(params));
    }
  }, [existingProfile]);

  useEffect(() => {
    if (profileDetailsList?.length > 0) {
      const index = profileDetailsList?.findIndex((item) => item?.id === existingProfile?.id);
      const list = [...profileDetailsList];
      list.splice(index, 1);
      setExistingAddressList(list);
    }
  }, [profileDetailsList]);

  useEffect(() => {
    if (uploadedPhoto && Object.keys(uploadedPhoto)?.length > 0) {
      setUploadedDoc(uploadedPhoto);
    }
  }, [uploadedPhoto]);

  useEffect(() => {
    setAlreadyUploadedDocument(selectedAddressVerificationDocument?.address_document_type);
  }, [selectedIdProcessDetails, addressDataIndex]);

  useEffect(() => {
    if (profileList?.length === 1) {
      setProfileAddress(profileList[0]?.profile_name);
      setExistingProfile(profileList[0]);
    }
  }, [profileList]);

  // Methods
  const handleChangeProfile = (event: any) => {
    const selectedProfileName = event?.target?.value;
    const selectedProfile: ProfileDetails =
      profileList?.find((item: ProfileDetails) => item.profile_name === selectedProfileName) || ({} as ProfileDetails);
    setProfileAddress(selectedProfile?.profile_name);
    setExistingProfile(selectedProfile);
  };

  const setAlreadyUploadedDocument = (docType: string) => {
    if (addressDataIndex !== null && docType) {
      const addressDocData = selectedIdProcessDetails?.data[addressDataIndex]?.address_document?.find(
        (doc: AddressVerificationDocDetailsType) => doc?.address_document_type === docType,
      );
      if (addressDocData?.document?.doc_id) {
        const docPreviewData: FilePreviewType[] = [
          {
            name: addressDocData?.document?.doc_name,
            type: addressDocData?.document?.mime_type,
            preview: addressDocData?.document?.signed_url,
            size: addressDocData?.document?.size_in_kb,
          },
        ];
        setProfileAddress(selectedIdProcessDetails?.data[addressDataIndex || 0]?.profile?.profile_name);
        setExistingProfile(selectedIdProcessDetails?.data[addressDataIndex || 0]?.profile);
        setPreview(docPreviewData);
        setDocValue(addressDocData?.address_document_type);
        setUploadedDoc(addressDocData?.document);
      } else {
        setDefaultValues(docType);
      }
    } else {
      setDefaultValues(docType);
    }
  };

  const setDefaultValues = (docType: string) => {
    setDocValue(docType);
    setPreview([]);
    setUploadedDoc({} as FileType);
  };

  const handleDocTypeChange = (event: any) => {
    setAlreadyUploadedDocument(event?.target?.value);
  };

  const getConsolidatedAddress = (item: ProfileDetails) => {
    return `(${item?.address?.address1} \t ${item?.address?.address2})`;
  };

  const setImage = (image: any) => {
    const payload = {
      name: image?.name,
      type: image?.type,
      preview: image?.preview,
      size: image?.size,
    };
    setPreview([payload]);
    dispatch(setUploadedPhoto(image));
  };

  const submitAddressDocs = async () => {
    const formData = {
      profile_id: existingProfile?.id,
      doc_id: uploadedDoc?.doc_id,
      mime_type: uploadedDoc?.mime_type,
      document_type: docValue,
    };
    await dispatch(createAddressVerification(formData));
    await dispatch(getProcessDetailsById({ processId: PROCESS_IDS.ADDRESS_VERIFICATION }));
    dispatch(resetUploadedPhoto());
    dispatch(setCurrentStep(FORM_STEPS.DONE));
    dispatch(setPreviousStep(null));
    dispatch(setSelectedAddressVerificationDocument({}));
  };

  const checkIsValid = () => {
    let isValid = true;
    if (!profileAddress || !docValue || (uploadedDoc && !Object.keys(uploadedDoc)?.length) || loading) {
      isValid = false;
    }
    return isValid;
  };

  // HTML
  return (
    <TPaper className="flex-basic-center" sx={{ mb: 10 }}>
      <TLoader loading={loading} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            className="text-center textColor-300 text-large font-weight-semibold"
            sx={{ px: 3, py: theme.spacing(5) }}
          >
            {t('instructionHeading', { ns: 'addressVerification' })}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 3, py: theme.spacing(5) }}>
          <Box sx={{ pb: theme.spacing(3) }} className={'text-h3 font-weight-semibold textColor-200'}>
            {t('documentSelectTitle', { ns: 'addressVerification' })}
          </Box>
          <Box className={'text-medium textColor-200'}>{t('documentSelectDesc', { ns: 'addressVerification' })}</Box>
          <Box sx={{ mt: 3 }}>
            <FormControl className="w-100">
              <TSelect
                value={profileAddress}
                options={profileList}
                fullWidth
                label="Which profile does this document verify?"
                itemId="profile_name"
                itemValue="profile_name"
                disabled={addressDataIndex !== null}
                onChange={handleChangeProfile}
              />
            </FormControl>
          </Box>
          {existingAddressList?.length >= 1 && (
            <Box>
              <Box className={'text-medium textColor-200'} sx={{ mt: 3, mb: 1 }}>
                {t('documentSelectInfo', { ns: 'addressVerification' })}
              </Box>
              <Box sx={{ mb: 2 }}>
                <Box>
                  {existingAddressList?.map((item) => (
                    <Box key={item.id} className="flex-basic-start">
                      <Box style={typography.addressTitle}>{item?.profile_name}</Box>
                      <Box
                        className={clsx({
                          ['text-ellipsis']: getConsolidatedAddress(item)?.length > 15,
                        })}
                        style={typography.tabsText}
                      >
                        {getConsolidatedAddress(item)}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 3 }}>
            <FormControl className="w-100">
              <TSelect
                value={docValue}
                options={addressVerificationOptions}
                fullWidth
                label="What type of document is this?"
                itemId="id"
                itemValue="label"
                onChange={handleDocTypeChange}
              />
            </FormControl>
          </Box>
          <Box sx={{ my: 4 }}>
            <UploadOrCapturePhoto
              showTitleText={isMobile ? t('uploadAndCaptureImageText', { ns: 'common' }) : ''}
              isMultiple={false}
              acceptedFileFormat={ACCEPTED_IMAGE_MIME_TYPES}
              supportedFileFormat={SUPPORTED_IMAGE_TYPES}
              maxFileSize={FILE_SIZE.IMAGE_FILE_SIZE}
              setData={setImage}
              uploadedFile={preview}
            />
          </Box>
          <Box sx={{ my: 2 }} className={'text-center'}>
            <TButton
              variant={'contained'}
              disabled={!checkIsValid()}
              btnText={'Submit'}
              btnWidthSize={'button-w-240'}
              onClick={submitAddressDocs}
            />
          </Box>
        </Grid>
      </Grid>
    </TPaper>
  );
};

export default AddressForm;
