import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { RegisterFormSchema, RegisterFormSchemaPayload } from './validation';
import { zodResolver } from '@hookform/resolvers/zod';
import AuthLayout from '../../layout/AuthLayout';
import PublicFooter from '../../components/common/PublicFooter';
import { TButton, TTextField, TPaper, TPasswordStrengthMeter } from '../../components/myde-react-components';
import {
  getInviteViaToken,
  getLoginDetails,
  getRegisterDetails,
  resetData,
  resetSignUpComplete,
  selectUser,
  resetCustomErrorMessage,
  resetErrorFlag,
  validateEmail,
} from '../../redux/feature/user/userSlice';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { TLoader } from '../../components/myde-react-components';
import { useAuth } from '../../auth/auth-guard';
import { ENTITY_MEMBER, passwordValidations } from '../../constants/constants';
import { useRouter } from '../../providers/custom-router-provider';
import { useTheme } from '../../providers/custom-theme-provider';
import clsx from 'clsx';
import { getEntityInviteDetails, selectEntity } from '../../redux/feature/entity/entitySlice';
import { IInviteData } from '../../types/userTypes';
import { EntityInvitionsType } from '../../types/entityTypes';

const CreateAccount = () => {
  const { currentThemes } = useTheme();
  const { t } = useTranslation('common');

  const { routeTo } = useRouter();
  const dispatch = useDispatch();
  const { login } = useAuth();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const type = searchParams.get('type');
  const { loading, authToken, inviteData, userData, signUpComplete, customErrorMessage } = useSelector(selectUser);
  const { selectedEntityDetails } = useSelector(selectEntity);

  const { handleSubmit, control, formState, setError, getValues, setValue } = useForm<RegisterFormSchemaPayload>({
    resolver: zodResolver(RegisterFormSchema),
    mode: 'onChange',
  });

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);

  //useEffects
  useEffect(() => {
    dispatch(resetCustomErrorMessage(''));
    if (token) {
      type === ENTITY_MEMBER ? dispatch(getEntityInviteDetails(token)) : dispatch(getInviteViaToken(token));
    }
  }, []);

  useEffect(() => {
    if (isSubmitted && signUpComplete) {
      dispatch(getLoginDetails({ email: getValues('email'), password: getValues('password') }));
    }
  }, [signUpComplete]);

  useEffect(() => {
    const invitationData = type === ENTITY_MEMBER ? selectedEntityDetails : inviteData;
    assignFormValues(invitationData);
  }, [inviteData, selectedEntityDetails, type]);

  useEffect(() => {
    if (authToken !== '') {
      dispatch(resetSignUpComplete());
      if (userData.account?.verification_status?.is_email_verified) {
        login(userData.access);
        routeTo(ROUTES.DASHBOARD);
      } else {
        dispatch(resetData());
        routeTo(ROUTES.VERIFY_EMAIL);
      }
    }
  }, [authToken]);

  const handleMessage = () => {
    dispatch(resetCustomErrorMessage(''));
    dispatch(resetErrorFlag(false));
  };

  useEffect(() => {
    if (customErrorMessage) {
      setError('email', {
        type: 'custom',
        message: customErrorMessage,
      });
    }
  }, [customErrorMessage]);

  const assignFormValues = (invitationData: IInviteData | EntityInvitionsType) => {
    setEmail(invitationData?.email || '');
    setValue('first_name', invitationData?.first_name || '');
    setValue('middle_name', invitationData?.middle_name || '');
    setValue('last_name', invitationData?.last_name || '');
  };

  const checkEmail = () => {
    const emailValue = getValues('email');
    const data = {
      email: emailValue,
    };
    dispatch(validateEmail(data));
  };

  const { errors, isValid, isSubmitting, isSubmitted } = formState;

  const onSubmit = async () => {
    try {
      const RegisterFormData = getValues();
      dispatch(getRegisterDetails(RegisterFormData));
    } catch (err: any) {
      setError('password', { type: 'manual', message: err.password });
    }
  };

  const backToLogin = () => {
    routeTo(ROUTES.LOGIN);
  };

  useEffect(() => {
    if (token && email) {
      setValue('email', email);
    } else setValue('email', '');
  }, [email]);

  //methods
  const inputChange = (e: any, isError: boolean) => {
    setNewPassword(e?.target?.value);
    setIsPasswordError(isError);
    if (getValues('confirm_password')) {
      setValue('confirm_password', getValues('confirm_password'), { shouldValidate: true });
    }
  };

  return (
    <>
      <AuthLayout>
        <TLoader loading={loading} />
        <TPaper sx={{ p: 3 }}>
          <Box>
            <>
              <Box className={'text-h2 font-weight-semibold textColor-200 text-center'}>
                {t('settingUpYourSecureIdentity')}
              </Box>
              <Box
                className={clsx({
                  ['text-medium textColor-200 text-center']: true,
                })}
              >
                <p>{t('settingUpInstructions')}</p>
              </Box>
              <Box sx={{ mt: 3 }}>
                <form>
                  <Box sx={{ mb: 2 }}>
                    <Controller
                      name="first_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TTextField
                          label="First Name"
                          variant="outlined"
                          fullWidth
                          error={!!errors.first_name}
                          helperText={errors.first_name?.message}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Controller
                      name="middle_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TTextField
                          isOptional
                          label="Middle Name"
                          variant="outlined"
                          fullWidth
                          error={!!errors.middle_name}
                          helperText={errors.middle_name?.message}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Controller
                      name="last_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TTextField
                          label="Last Name"
                          variant="outlined"
                          fullWidth
                          error={!!errors.last_name}
                          helperText={errors.last_name?.message}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <TTextField
                            label="Email Address"
                            variant="outlined"
                            fullWidth
                            disabled={!!token}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            {...field}
                            onFocus={handleMessage}
                            onBlur={checkEmail}
                          />
                        </>
                      )}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TTextField
                          label="Password"
                          variant="outlined"
                          type="password"
                          fullWidth
                          onKeyUp={(event) => {inputChange(event, !!errors.password)}}
                          error={!!errors.password}
                          helperText={errors.password?.message}
                          {...field}
                        />
                      )}
                    />
                    <TPasswordStrengthMeter password={newPassword} isError={isPasswordError} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Controller
                      name="confirm_password"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TTextField
                          label="Confirm Password"
                          variant="outlined"
                          type="password"
                          fullWidth
                          error={!!errors.confirm_password}
                          helperText={errors.confirm_password?.message}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  {!isValid && errors.password && (
                    <Box sx={{ mb: 1 }}>
                      <Box className="text-medium" sx={{ color: currentThemes.palette.primary.main }}>
                        Password should contain
                      </Box>
                      {passwordValidations.map((validation, index) => (
                        <Box key={index} className="text-small passContentList">
                          {validation.message}
                        </Box>
                      ))}
                    </Box>
                  )}
                  <Box sx={{ mt: 4 }} className="text-center">
                    <TButton
                      variant={'contained'}
                      type="submit"
                      btnWidthSize="button-w-240"
                      disabled={!isValid || isSubmitting}
                      btnText={'Create Account'}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </Box>
                  <Box className="flex-basic-center" sx={{ mt: 2, mb: 5 }}>
                    <Box className="text-medium textColor-300"> {t('alreadyHaveAccountText')}</Box>
                    <Box sx={{ pl: 1 }} onClick={backToLogin}>
                      <Box
                        className="text-medium font-weight-semibold cursorPointer"
                        sx={{ color: currentThemes.palette.primary.main }}
                      >
                        {t('loginText')}
                      </Box>
                    </Box>
                  </Box>
                </form>
                <Box sx={{ mt: 1, mb: 1, px: 2 }}>
                  <PublicFooter />
                </Box>
              </Box>
            </>
          </Box>
        </TPaper>
      </AuthLayout>
    </>
  );
};

export default CreateAccount;
