import React from 'react';
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useTheme } from '../../../../../providers/custom-theme-provider';
interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  customSwitch: {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& .MuiSwitch-thumb:before': {
          backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
        },
        '& + .MuiSwitch-track': {
          borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
        },
      },
    },
  },
});

const ThemedSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 26,
  height: 16,
  padding: 0,
  margin: 4,
  '& .MuiSwitch-switchBase': {
    margin: 4,
    padding: 2,
    transform: 'translateX(-1px)',
    '&.Mui-checked': {
      transform: 'translateX(8px)',
      '& .MuiSwitch-thumb:before': {
        backgroundColor: theme.palette.primary.main,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: 'transparent',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'transparent',
    width: 3,
    height: 3,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      backgroundPosition: 'center',
      backgroundColor: `#BDBDBD`,
      borderRadius: 8,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'transparent',
    borderRadius: 10,
    border: `3px solid #BDBDBD`,
  },
}));

export interface SwitchProps extends MuiSwitchProps {
  themed?: boolean;
}

export const Switch = ({ themed, ...props }: SwitchProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  return (
    <>
      {themed ? <ThemedSwitch {...props} className={classes.customSwitch} /> : <MuiSwitch color="primary" {...props} />}
    </>
  );
};
export default Switch;
