import React from 'react';
import { Box, Icon, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import successImg from '../../common/assets/success-placeholder.svg';
import TDialog from '../Dialog/Dialog';
import { TButton, theme } from '../../..';
import { colorPalette } from '../../constants/colors';
import clsx from 'clsx';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { PORTAL } from '../../constants/portal';

interface SuccessDialogProps {
  open: boolean;
  title?: string;
  message?: string;
  btnText?: string;
  portalType?: string;
  onButtonClick: () => void;
}

interface StyleProps {
  currentThemes: Theme;
  portalType: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  dialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: ({ portalType }) => (portalType === PORTAL.MYDE ? '20rem' : '28.75rem'),
        maxWidth: ({ portalType }) => (portalType === PORTAL.MYDE ? '20rem' : '28.75rem'),
        '& .MuiDialogActions-root': {
          padding: '0 !important',
        },
      },
    },
  },
  icon: {
    color: colorPalette.typoText.lighten3,
  },
  iconContainer: {
    backgroundColor: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
});

export const SuccessDialog = ({
  open,
  title = 'Success!',
  message = 'Your data is saved successfully',
  btnText = 'Ok, Great!',
  portalType = PORTAL.MERCHANT,
  onButtonClick,
}: SuccessDialogProps) => {
  // constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
    portalType,
  };
  const classes = useStyles(styleProps);

  return (
    <TDialog open={open} maxWidth={'xs'} className={classes.dialogStyle}>
      <Box className="flex-column-center" sx={{ pt: 5 }}>
        <Box className="p-relative">
          <img src={successImg} alt="Success" />
          <div className={clsx('circularIcon flex-basic-center', classes.iconContainer)}>
            <Icon className={classes.icon}>check_icon</Icon>
          </div>
        </Box>
        {title && (
          <Box
            className={clsx(
              'text-center textColor-200  font-weight-semibold',
              portalType === PORTAL.MYDE ? 'text-h3' : 'text-h2',
            )}
            sx={{ mt: 3 }}
          >
            {title}
          </Box>
        )}
        {message && (
          <Box
            className={clsx(
              'text-center textColor-200  font-weight-semibold',
              portalType === PORTAL.MYDE ? 'text-medium' : 'text-large',
            )}
            sx={{ mt: theme.spacing(3), mb: theme.spacing(10) }}
          >
            {message}
          </Box>
        )}
        <Box sx={!message ? { mt: 3, mb: 1 } : { mt: 0, mb: 1 }}>
          <TButton btnText={btnText} variant="contained" btnWidthSize="button-w-140" onClick={onButtonClick} />
        </Box>
      </Box>
    </TDialog>
  );
};

export default SuccessDialog;
