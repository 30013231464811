import React, { useEffect, useState } from 'react';
import { Box, Divider, Link, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {
  ACCREDITATION_BADGE_TEXT,
  colorPalette,
  FilePreviewType,
  TAlertDialog,
  TButton,
  TCard,
  TCommonAvatar,
  TDocumentViewModal,
  theme,
  TLoader,
  TTextField,
} from '../../../../components/myde-react-components';
import { useTheme } from '../../../../providers/custom-theme-provider';
import {
  getSpecificEntityDetails,
  selectEntity,
  setEntityProfileImgUrl,
  updateEntityDetails,
} from '../../../../redux/feature/entity/entitySlice';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { EditEntityFormSchema, EditEntityFormSchemaPayload } from './validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { UpdateEntityDetailsType } from '../../../../types/entityTypes';
import { useRouter } from '../../../../providers/custom-router-provider';
import * as ROUTE from '../../../../constants/routes';
import { ENTITY_TAX_ID_VERIFICATION_LABEL } from '../../../../constants/constants';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  addProfilePicBtn: {
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    color: `${colorPalette.typoText.lighten3} !important`,
  },
  checkboxColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  accreditationBadge: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const EntityProfileDetails = () => {
  //Constant
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { control, formState, handleSubmit, getValues } = useForm<EditEntityFormSchemaPayload>({
    resolver: zodResolver(EditEntityFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid } = formState;
  const { routeTo } = useRouter();
  const blankFieldContent = '--';

  //State
  const [showTaxId, setShowTaxId] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState({} as FilePreviewType);
  const [imageInFullView, setImageInFullView] = useState(false);

  //Redux
  const { selectedEntity, specificEntityDetails } = useSelector(selectEntity);

  //useEffect
  useEffect(() => {
    getEntityDetails();
  }, [selectedEntity, showTaxId]);

  useEffect(() => {
    if (specificEntityDetails?.avatar_url) {
      dispatch(setEntityProfileImgUrl(specificEntityDetails?.avatar_url));
    }
  }, [specificEntityDetails]);

  //Methods
  const getEntityDetails = () => {
    const payload = {
      entityUid: selectedEntity?.trellis_uid,
      filters: showTaxId ? { visibility_level: 'source' } : {},
    };
    dispatch(getSpecificEntityDetails(payload));
  };

  const toggleTaxIdVisibility = () => {
    setShowTaxId(!showTaxId);
  };

  const onSubmit = async () => {
    setShowEditDialog(false);
    setLoading(true);
    const payload: UpdateEntityDetailsType = {
      entityUid: specificEntityDetails?.trellis_uid,
      formData: { entity_info: getValues() },
    };
    await dispatch(updateEntityDetails(payload));
    getEntityDetails();
    setLoading(false);
  };

  const gotoProfilePicture = () => {
    const query = {
      isEntity: true,
    };
    routeTo(ROUTE.EDIT_ENTITY_PROFILE_PICTURE, true, t('entityProfileImage'), query);
  };

  const openImageInFullView = () => {
    if (!specificEntityDetails?.avatar_url) return;
    const imagePreview: FilePreviewType = {
      name: '',
      type: 'image/*',
      preview: specificEntityDetails?.avatar_url || '',
      size: 0,
    };
    setPreview(imagePreview);
    setImageInFullView(true);
  };

  const handleCloseModel = () => {
    setImageInFullView(false);
  };

  const getEntityTaxIdVerificationIcon = (label: string) => {
    if (label === ENTITY_TAX_ID_VERIFICATION_LABEL.PASSED) {
      return <Box sx={{ ml: 2 }} className={clsx('icon-approved icon-size-20', classes.checkboxColor)}></Box>;
    } else {
      return <Box sx={{ ml: 2 }} className={clsx('icon-error-warning error-color-state icon-size-20')}></Box>;
    }
  };

  const goTo = (url: string) => {
    if (url.includes('http')) {
      window.open(url, '_blank');
    } else {
      window.open('https://' + url, '_blank');
    }
  };

  //HTML
  return (
    <>
      <TLoader loading={loading} />
      <TCard sx={{ mb: 9 }}>
        <Box sx={{ px: 3, mt: 3 }} className="flex-column-center">
          <Box className="p-relative">
            <Box
              className={clsx({ ['cursorPointer']: specificEntityDetails?.avatar_url })}
              onClick={openImageInFullView}
            >
              <TCommonAvatar
                imageUrl={specificEntityDetails?.avatar_url || ''}
                avatarText={specificEntityDetails?.name || ''}
                iconSize={'133px'}
                textSize={'2rem'}
              />
            </Box>
            <TButton
              className={clsx('addProfileImgBtn p-absolute', classes.addProfilePicBtn)}
              icon={<Box className="icon-add"></Box>}
              onClick={gotoProfilePicture}
            />
          </Box>
          <Box className="textColor-200 text-h2 font-weight-semibold">{specificEntityDetails?.name || ''}</Box>
          {specificEntityDetails?.details?.accreditation_status && (
            <Box className="flex-basic-center">
              <Box className={clsx('icon-accreditation-badge', classes.accreditationBadge)}></Box>
              <Box sx={{ ml: theme.spacing(1) }} className="textColor-200 text-large font-weight-medium">
                {ACCREDITATION_BADGE_TEXT[specificEntityDetails?.details?.accreditation_status]}
              </Box>
            </Box>
          )}
        </Box>
        <Divider sx={{ my: theme.spacing(7) }} />
        <Box sx={{ px: 3, pb: 3 }} className="flex-column-start align-items-start">
          <Box sx={{ mb: theme.spacing(9) }}>
            <Box className="textColor-200 text-small textUppercase">{t('entityTid')}</Box>
            <Box className="textColor-200 text-medium font-weight-semibold textCapitalize">
              {specificEntityDetails?.trellis_uid || blankFieldContent}
            </Box>
          </Box>
          <Box sx={{ mb: theme.spacing(9) }}>
            <Box className="textColor-200 text-small textUppercase">{t('type')}</Box>
            <Box className="textColor-200 text-medium font-weight-semibold textCapitalize">
              {specificEntityDetails?.details?.entity_info?.entity_type || blankFieldContent}
            </Box>
          </Box>
          <Box sx={{ mb: theme.spacing(9) }} className="w-100">
            <Box className="textColor-200 text-small textUppercase">{t('entityTaxId')}</Box>
            <Box className="flex-basic-space-between">
              <Box className="textColor-200 text-medium font-weight-semibold textCapitalize">
                {specificEntityDetails?.details?.entity_info?.tax_id || blankFieldContent}
              </Box>
              <Box className="flex-basic-end">
                {showTaxId ? (
                  <VisibilityOutlinedIcon
                    fontSize="small"
                    onClick={toggleTaxIdVisibility}
                    className="textColor-200 cursorPointer"
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    fontSize="small"
                    className="textColor-200 cursorPointer"
                    onClick={toggleTaxIdVisibility}
                  />
                )}
                {getEntityTaxIdVerificationIcon(specificEntityDetails?.details?.entity_info?.verification_status_label)}
              </Box>
            </Box>
          </Box>
          <Box sx={{ mb: theme.spacing(9) }}>
            <Box className="textColor-200 text-small textUppercase">{t('email')}</Box>
            <Box className="textColor-200 text-medium font-weight-semibold">
              {specificEntityDetails?.email || blankFieldContent}
            </Box>
          </Box>
          <Box sx={{ mb: theme.spacing(9) }}>
            <Box className="textColor-200 text-small textUppercase">{t('legalName')}</Box>
            <Box className="textColor-200 text-medium font-weight-semibold textCapitalize">
              {specificEntityDetails?.name || blankFieldContent}
            </Box>
          </Box>
          <Box sx={{ mb: theme.spacing(9) }} className="w-100">
            <Box className="flex-basic-space-between p-relative">
              <Box>
                <Box className="textColor-200 text-small textUppercase">{t('dba-name')}</Box>
                <Box className="textColor-200 text-medium font-weight-semibold textCapitalize entityDetailsSection">
                  {specificEntityDetails?.details?.entity_info?.dba_name || blankFieldContent}
                </Box>
              </Box>
              <Box
                className="icon-edit icon-size-20 p-absolute editEntityDetailsIcon"
                onClick={() => setShowEditDialog(true)}
              ></Box>
            </Box>
          </Box>
          <Box sx={{ mb: theme.spacing(5) }} className="w-100">
            <Box className="flex-basic-space-between p-relative">
              <Box>
                <Box className="textColor-200 text-small textUppercase">{t('website')}</Box>
                <Link
                  className="textColor-200 text-medium font-weight-semibold entityDetailsSection cursorPointer"
                  onClick={() => goTo(specificEntityDetails?.details?.entity_info?.website)}
                >
                  {specificEntityDetails?.details?.entity_info?.website || blankFieldContent}
                </Link>
              </Box>
              <Box
                className="icon-edit icon-size-20 p-absolute editEntityDetailsIcon"
                onClick={() => setShowEditDialog(true)}
              ></Box>
            </Box>
          </Box>
        </Box>
      </TCard>

      <TAlertDialog
        showDialog={showEditDialog}
        title={'Edit'}
        content=""
        yesBtnTitle={'Save'}
        noBtnTitle={'Cancel'}
        isButtonDisabled={!isValid}
        onCancel={() => setShowEditDialog(false)}
        onConfirm={handleSubmit(onSubmit)}
        extraContent={
          <Box>
            <form>
              <Box sx={{ mb: theme.spacing(5) }}>
                <Controller
                  name={'dba_name'}
                  defaultValue={specificEntityDetails?.details?.entity_info?.dba_name || ''}
                  control={control}
                  render={({ field }) => <TTextField {...field} label="DBA Name" variant="outlined" fullWidth />}
                />
              </Box>
              <Box>
                <Controller
                  name={'website'}
                  defaultValue={specificEntityDetails?.details?.entity_info?.website || ''}
                  control={control}
                  render={({ field }) => (
                    <TTextField
                      {...field}
                      label="Website"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors?.website)}
                      helperText={errors?.website?.message}
                    />
                  )}
                />
              </Box>
            </form>
          </Box>
        }
      />

      <TDocumentViewModal
        open={imageInFullView}
        file={preview}
        closeModal={handleCloseModel}
        title={t('entityProfileImage')}
      />
    </>
  );
};

export default EntityProfileDetails;
