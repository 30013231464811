import { Box, Divider, Grid } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectType from './SelectType';
import { selectSelectedIdProcessDetails } from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { setSelectedProfileDetails } from '../../../../redux/feature/idProcess/supplementalVerificationSlice';
import { selectEntityProfile } from '../../../../redux/feature/profile/entityProfileSlice';
import { selectProfile } from '../../../../redux/feature/profile/profileSlice';
import { ProfileDetails } from '../../../../types/profileTypes';
import { TAutocomplete, theme, TPaper, TTextField } from '../../../myde-react-components';

interface W9W8BenConfirmationProps {
  isEntity: boolean;
}

const W9W8BenConfirmation = ({ isEntity }: W9W8BenConfirmationProps) => {
  // Constants
  const dispatch = useDispatch();
  const { profileList } = useSelector(selectProfile);
  const { selectedIdProcessDetails, selectedEntityIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);
  const { entityProfileList } = useSelector(selectEntityProfile);

  //State Variables
  const [profileDetailsList, setProfileDetailsList] = useState([] as ProfileDetails[]);
  const [profile, setProfile] = useState<ProfileDetails>({} as ProfileDetails);
  const [idProcess, setIdProcess] = useState({} as any);

  //Use Effect
  useEffect(() => {
    if (isEntity) {
      setProfileDetailsList(entityProfileList);
    } else {
      setProfileDetailsList(profileList);
    }
  }, [isEntity, entityProfileList, profileList]);

  useEffect(() => {
    if (isEntity && Object.keys(selectedEntityIdProcessDetails?.data)?.length > 0) {
      setIdProcess(selectedEntityIdProcessDetails);
    } else if (!isEntity && Object.keys(selectedIdProcessDetails?.data)?.length > 0) {
      setIdProcess(selectedIdProcessDetails);
    } else {
      setIdProcess({} as any);
    }
  }, [selectedIdProcessDetails, selectedEntityIdProcessDetails, isEntity]);

  useEffect(() => {
    if (Object.keys(idProcess)?.length > 0) {
      if (idProcess?.data?.profile?.id) {
        setProfile(idProcess?.data?.profile);
        dispatch(setSelectedProfileDetails(idProcess?.data?.profile));
      }
    }
  }, [idProcess, isEntity]);

  //methods
  const handleProfileChange = (_event: React.SyntheticEvent, profile: ProfileDetails) => {
    setProfile(profile);
    dispatch(setSelectedProfileDetails(profile));
  };

  return (
    <>
      <TPaper>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box className="text-center textColor-300 text-large font-weight-semibold" sx={{ p: 2 }}>
              {t(profile && Object.keys(profile)?.length > 0 ? 'step2Header' : 'step1Header', { ns: 'w8' })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider sx={{ mb: 1 }} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 3 }}>
            <Box className="text-center">
              <Box className="text-h3 font-weight-semibold textColor-200">
                {t('profileSelectionSubTitle', { ns: 'w8' })}
              </Box>
            </Box>
            <Box sx={{ mt: 3, mb: theme.spacing(5) }}>
              <TAutocomplete
                value={profile}
                options={profileDetailsList || []}
                getOptionLabel={(option) => option.profile_name || ''}
                onChange={handleProfileChange}
                renderInput={(params) => (
                  <TTextField label={t('ProfileSelection', { ns: 'w8' })} variant="outlined" {...params} />
                )}
              />
            </Box>
          </Grid>
          <Divider />
        </Grid>
      </TPaper>
      <Box sx={{ mt: theme.spacing(11) }}>
        <SelectType isEntity={isEntity} />
      </Box>
    </>
  );
};

export default W9W8BenConfirmation;
