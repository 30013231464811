import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DocumentListing from '../../../components/documents/DocumentListing';
import { colorPalette, TCard, TLoader, TSearchComponent } from '../../../components/myde-react-components';
import { useRouter } from '../../../providers/custom-router-provider';
import { CategoryFolder, MerchantDocumentType } from '../../../types/documentTypes';
import * as ROUTES from '../../../constants/routes';
import {
  clearFileList,
  getCategoryFolders,
  getFolderFiles,
  selectDocuments,
  setIsSearching,
} from '../../../redux/feature/documents/documentsSlice';
import SearchListing from '../search-listing/SearchListing';
import useQuery from '../../../hooks/useQuery';

const useStyles = makeStyles({
  divider: {
    borderColor: colorPalette.border.base,
  },
  pageContainer: {
    minHeight: '100vh',
  },
});

export const CategoryFolders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const query = useQuery();
  const category = query.get('category') || '';
  const merchant = query.get('merchant') || '';
  const section = query.get('section') || '';
  const isEntityMerchant = query.get('isEntity') === 'true';
  const entity_uid = query.get('entity_uid') || '';
  const invite_Type = query.get('invite_Type') || '';
  const applicationId = query.get('applicationId') || '';

  // State Values
  const [categoryFolderList, setCategoryFolderList] = useState([] as CategoryFolder[]);
  const [searchValue, setSearchValue] = useState('');
  const [filesList, setFilesList] = useState([] as MerchantDocumentType[]);
  const [resetSearchFilter, setResetSearchFilter] = useState(false);
  const [showViewAllBtn, setShowViewAllBtn] = useState(false);

  // Redux Values
  const { merchantId, folderList, loading, isSearching, fileList } = useSelector(selectDocuments);

  // Use Effects
  useEffect(() => {
    setFilesList(fileList);
  }, [fileList]);

  useEffect(() => {
    if (category) {
      const filterData = {
        category: category,
        filters: { merchant_id: merchantId, entity_uid: isEntityMerchant ? entity_uid : '' },
      };

      dispatch(getCategoryFolders(filterData));
      backSearchHandler();
    }
  }, [merchantId, category]);

  useEffect(() => {
    setCategoryFolderList(folderList);
  }, [folderList]);

  const selecteFolder = (selectedFolder: CategoryFolder) => {
    const query = {
      id: merchantId,
      folder: selectedFolder.year_month,
      category: category,
      merchant: merchant,
      section: section,
      invite_Type: invite_Type,
      applicationId: applicationId,
      entity_uid: entity_uid,
      isEntiy: isEntityMerchant,
    };
    routeTo(ROUTES.DOCUMENTS_FILES, true, selectedFolder.year_month, query);
  };

  const searchHandler = (value: string) => {
    setSearchValue(value);
    if (value) {
      dispatch(
        getFolderFiles({ name: value, category: category, limit: 4, entity_uid: isEntityMerchant ? entity_uid : '' }),
      );
      setShowViewAllBtn(true);
    } else {
      dispatch(clearFileList());
      setShowViewAllBtn(false);
    }
  };

  const searchBoxClickHandler = () => {
    dispatch(setIsSearching(true));
    setResetSearchFilter(false);
    setShowViewAllBtn(false);
  };

  const backSearchHandler = () => {
    setResetSearchFilter(true);
    dispatch(setIsSearching(false));
    dispatch(clearFileList());
  };

  const viewAllBtnClickHandler = () => {
    dispatch(getFolderFiles({ name: searchValue, category: category }));
    setShowViewAllBtn(false);
  };

  return (
    <Box className={classes.pageContainer}>
      <TLoader loading={loading} />
      <Box>
        {!loading && (
          <TCard sx={{ py: 3 }} className="card-height-200">
            <Box className="flex-basic-start" sx={{ px: 3 }}>
              {isSearching && (
                <Box sx={{ pr: 2, pt: 1 }}>
                  <span
                    className="icon-arrow-lefts textColor-200 cursorPointer actionIconFont-24"
                    onClick={backSearchHandler}
                  ></span>
                </Box>
              )}
              <Box className="flex-basic-start w-100" onClick={searchBoxClickHandler}>
                <Box className="w-100">
                  <TSearchComponent
                    hideResetButton={true}
                    placeholder="Search"
                    onSearchUpdate={searchHandler}
                    hasExtraFilters={true}
                    resetSearchFilter={resetSearchFilter}
                  />
                </Box>
              </Box>
            </Box>

            {isSearching ? (
              <Box className="scrollBox">
                <SearchListing
                  filesList={filesList}
                  showYearMonth={true}
                  showViewAllBtn={showViewAllBtn}
                  viewAllBtnClickHandler={viewAllBtnClickHandler}
                />
              </Box>
            ) : (
              <Box className="scrollBox">
                {categoryFolderList?.map((folder, index) => {
                  return (
                    <>
                      <DocumentListing
                        name={folder?.year_month}
                        icon="FolderOpenOutlinedIcon"
                        count={folder?.no_of_files}
                        onClick={() => selecteFolder(folder)}
                        showUnreadIcon={folder?.unread_count > 0}
                        unreadCount={folder?.unread_count}
                      />
                      {index < categoryFolderList?.length - 1 && <Divider />}
                    </>
                  );
                })}
              </Box>
            )}
          </TCard>
        )}
      </Box>
    </Box>
  );
};

export default CategoryFolders;
