import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, FormControlLabel } from '@mui/material';

import { ProfileDetails } from '../../../types/profileTypes';
import { TCheckbox, theme } from '../../myde-react-components';

interface EntityProfileSelectionListProps {
  profiles: ProfileDetails[];
  addSelectedProfiles: (data: string[]) => void;
}
const EntityProfileSelectionList = ({ profiles, addSelectedProfiles }: EntityProfileSelectionListProps) => {
  // Constants
  const { t } = useTranslation(['inviteFlow']);

  // State Variables
  const [selectedProfileData, setSelectedProfileData] = useState({} as any);

  // Methods
  const selectUserProfile = (profileName: string) => {
    const allKeys = Object.keys(selectedProfileData);
    const isSelectedProfileName = allKeys.includes(profileName);
    if (!isSelectedProfileName) {
      const profileData: any = Object.assign({}, selectedProfileData);
      profileData[profileName] = [];
      setSelectedProfileData(profileData);
      addSelectedProfiles(profileData);
    } else {
      const profileIndex = allKeys?.findIndex((key) => key === profileName);
      allKeys.splice(profileIndex, 1);
      const newProfileData = allKeys.map((key) => {
        const updatedProfileData: any = {};
        updatedProfileData[key] = selectedProfileData[key];
        return updatedProfileData;
      });

      setSelectedProfileData(newProfileData[0] || {});
      addSelectedProfiles(newProfileData);
    }
  };
  return (
    <Box sx={{ px: 3, my: theme.spacing(5) }}>
      <Box sx={{ pb: 1 }} className="text-medium font-weight-semibold textColor-200">
        {t('selectProfileLabel')}
      </Box>
      <FormGroup>
        {profiles?.map((profile: ProfileDetails) => (
          <Box key={profile?.id}>
            <Box className="flex-basic-space-between">
              <FormControlLabel
                control={
                  <TCheckbox
                    checked={!!selectedProfileData[profile?.profile_name]}
                    onChange={() => selectUserProfile(profile?.profile_name)}
                    name={profile?.profile_name}
                    disabled={profiles?.length === 0}
                  />
                }
                label={profile?.profile_name}
              />
            </Box>
          </Box>
        ))}
      </FormGroup>
    </Box>
  );
};

export default EntityProfileSelectionList;
