import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { hexToRgbA } from '../../utils/commonMethods';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { theme } from '../../Theme';

interface BadgeProps {
  label: string;
  showBagdeThemeColor?: boolean;
  bagdeFontStyle?: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  badgeThemeColor: {
    border: '1px solid',
    borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.2),
  },
});

interface StyleProps {
  currentThemes: Theme;
}

export const Badge = ({ label, showBagdeThemeColor, bagdeFontStyle }: BadgeProps) => {
  //constants
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);

  //TSX
  return (
    <Box
      sx={{ px: theme.spacing(3), py: theme.spacing(1) }}
      className={clsx(
        { [classes.badgeThemeColor]: showBagdeThemeColor },
        `${bagdeFontStyle}`,
        'customBadge text-center',
      )}
    >
      {label}
    </Box>
  );
};

export default Badge;
