import { RootState } from '../..';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CommunicationResponseType,
  FilterType,
  InboxCommunicationType,
  InboxMessagesType,
  MessageResultType,
} from '../../../types/messageTypes';
import {
  getApplicationCommunication,
  getApplicationCommunicationById,
  respondApplicationCommunication,
  getApplications,
  getInboxCommunications,
  getInboxCommunicationsbyId,
} from '../../../api/messages';
import { MerchantUsersSelectionType, MerchantUserEntityResultType } from '../../../types/documentTypes';

type MessageState = {
  applicationsList: MerchantUserEntityResultType;
  applicationCommunicationList: MessageResultType[];
  applicationCommunicationListById: ResponseType[];
  specificApplicationDetail: any;
  communicationResponse: ResponseType[];
  loading: boolean;
  error: boolean;
  applicationId: string;
  selectedMessageDetail: MessageResultType;
  communicationDialogFlag: boolean;
  communicationId: string;
  inboxCommunicationList: InboxMessagesType;
  inboxCommunicationDetails: InboxCommunicationType;
  selectedMerchantDetails: MerchantUsersSelectionType;
};

const initialState: MessageState = {
  applicationsList: <MerchantUserEntityResultType>{},
  applicationCommunicationList: [],
  applicationCommunicationListById: [],
  specificApplicationDetail: <any>{},
  communicationResponse: [],
  loading: false,
  error: false,
  applicationId: '',
  selectedMessageDetail: <MessageResultType>{},
  communicationDialogFlag: false,
  communicationId: '',
  inboxCommunicationList: <InboxMessagesType>{},
  inboxCommunicationDetails: <InboxCommunicationType>{},
  selectedMerchantDetails: <MerchantUsersSelectionType>{},
};

export const messageDetails = ({ message }: RootState) => ({
  applicationsList: message.applicationsList,
  applicationCommunicationList: message.applicationCommunicationList,
  applicationCommunicationListById: message.applicationCommunicationListById,
  specificApplicationDetail: message.specificApplicationDetail,
  communicationResponse: message.communicationResponse,
  loading: message.loading,
  error: message.error,
  applicationId: message.applicationId,
  selectedMessageDetail: message.selectedMessageDetail,
  communicationDialogFlag: message.communicationDialogFlag,
  communicationId: message.communicationId,
  inboxCommunicationList: message.inboxCommunicationList,
  inboxCommunicationDetails: message.inboxCommunicationDetails,
  selectedMerchantDetails: message.selectedMerchantDetails,
});

export const getApplicationsList = createAsyncThunk('message/getApplicationsList', async (filters?: object) => {
  return await getApplications(filters);
});

export const getApplicationsListById = createAsyncThunk('message/getApplicationsListById', async (filters?: object) => {
  return await getApplications(filters);
});

export const getApplicationCommunicationDetails = createAsyncThunk(
  'message/getApplicationCommunicationDetails',
  async (data: { applicationId: string; filters: FilterType }) => {
    const applicationId = data.applicationId;
    const filters = data.filters;
    return await getApplicationCommunication(applicationId, filters);
  },
);

export const getApplicationCommunicationByIdDetails = createAsyncThunk(
  'message/getApplicationCommunicationByIdDetails',
  async (data: { applicationId: string; communicationId: string }) => {
    return await getApplicationCommunicationById(data);
  },
);

export const respondApplicationCommunicationDetails = createAsyncThunk(
  'message/respondApplicationCommunicationDetails',
  async (data: { applicationId: string; communicationId: string; reply: CommunicationResponseType }) => {
    return await respondApplicationCommunication(data.applicationId, data.communicationId, data.reply);
  },
);

export const getAllInboxCommunications = createAsyncThunk(
  'message/getAllInboxCommunications',
  async (filters: object) => {
    return await getInboxCommunications(filters);
  },
);

export const getSpecificInboxCommunications = createAsyncThunk(
  'message/getSpecificInboxCommunications',
  async (data: { communicationId: string; payload: object }) => {
    return await getInboxCommunicationsbyId(data?.communicationId, data?.payload);
  },
);

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setApplictionId: (state, action) => {
      state.applicationId = action.payload;
    },
    setCommunicationDialog: (state, action) => {
      state.communicationDialogFlag = action.payload;
    },
    setCommunicationId: (state, action) => {
      state.communicationId = action.payload;
    },
    setSelectedMerchantDetails: (state, action) => {
      state.selectedMerchantDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationsList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationsList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.applicationsList = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getApplicationsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getApplicationsListById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationsListById.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.specificApplicationDetail = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getApplicationsListById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getApplicationCommunicationDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationCommunicationDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.applicationCommunicationList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getApplicationCommunicationDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getApplicationCommunicationByIdDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationCommunicationByIdDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.selectedMessageDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getApplicationCommunicationByIdDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(respondApplicationCommunicationDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(respondApplicationCommunicationDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.selectedMessageDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(respondApplicationCommunicationDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAllInboxCommunications.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllInboxCommunications.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.inboxCommunicationList = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getAllInboxCommunications.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSpecificInboxCommunications.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSpecificInboxCommunications.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.inboxCommunicationDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificInboxCommunications.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setApplictionId, setCommunicationDialog, setCommunicationId, setSelectedMerchantDetails } =
  messageSlice.actions;

export default messageSlice.reducer;
