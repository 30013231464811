import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Theme, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

import { colorPalette, theme, FORM_STEPS } from '../myde-react-components';
import { useTheme } from '../../providers/custom-theme-provider';
import useQuery from '../../hooks/useQuery';
import IDCardIndex from '../../pages/Dashboard/IdCardIndex';
import {
  selectSession,
  setActiveCardNav,
  setCardState,
  setCurrentStep,
  setIsDeleteAllowed,
  setIsEditAllowedFlag,
  setParentRoute,
  setPreviousStep,
  setShowDeleteConfirmation,
} from '../../redux/feature/common/commonSlice';
import { useRouter } from '../../providers/custom-router-provider';
import clsx from 'clsx';
import { replaceSpecialCharAndCapitalize } from '../../utils/utils';
import { RouteType } from '../../types/commonTypes';
import { ID_CARD_NAV_INDEX, ID_CARD_STATE } from '../../constants/constants';
import * as ROUTES from '../../constants/routes';

export interface StyleProps {
  matches: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  appBar: {
    position: 'fixed',
    zIndex: 10,
    height: '100%',
    '& .MuiPaper-root.MuiAppBar-root': {
      position: 'relative !important',
      backgroundColor: colorPalette.containerBack.base,
      boxShadow: 'none',
    },
    '& .MuiToolbar-root': {
      width: 375,
    },
  },
  pageContainer: {
    maxWidth: 375,
    width: 375,
    height: '100%',
  },
  toolbarAlign: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textAlignment: {
    lineHeight: '26px',
    maxWidth: '15.5rem',
  },
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(() => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
interface MobileDrawerProps {
  children?: any;
}

const MobileDrawer = ({ children }: MobileDrawerProps) => {
  const { currentThemes } = useTheme();
  const matches = useMediaQuery(currentThemes.breakpoints.down('md'));
  const styleProps = {
    matches,
  };
  const location = useLocation();
  const classes = useStyles(styleProps);
  const { routeTo, goBack } = useRouter();

  // Redux Values
  const { showCard, parentRoute, childRoute, currentStep, previousStep, isEditAllowed, isDeleteAllowed } =
    useSelector(selectSession);

  const query = useQuery();
  const dispatch = useDispatch();

  const isChildPath = !!query.get('isChild');
  const params: any = useLocation();
  const isIDCardOpen = showCard;

  // Use States
  const [showEdit, setShowEditFlag] = useState(false);
  const [showDelete, setShowDeleteFlag] = useState(false);

  // Use Effects
  useEffect(() => {
    setShowEditFlag(isEditAllowed);
  }, [isEditAllowed]);

  useEffect(() => {
    setShowDeleteFlag(isDeleteAllowed);
  }, [isDeleteAllowed]);

  // Methods
  const navigateTo = () => {
    if (parentRoute?.path) {
      routeTo(
        parentRoute?.path,
        parentRoute?.isChild,
        parentRoute?.stateName,
        parentRoute?.query,
        parentRoute?.stateObj,
      );
    } else {
      goBack();
    }
    dispatch(setParentRoute({} as RouteType));
    dispatch(setCurrentStep(null));
    dispatch(setPreviousStep(null));
    dispatch(setIsEditAllowedFlag(false));
  };
  const getPageTitle = (path: string) => {
    const title = replaceSpecialCharAndCapitalize(path, '-', ' ', true).split('/').pop();
    return title;
  };
  const goPrevious = () => {
    if (previousStep !== null) {
      if (Object.values(ID_CARD_STATE).includes(previousStep?.toString())) {
        dispatch(setActiveCardNav(ID_CARD_NAV_INDEX[previousStep]) || ID_CARD_NAV_INDEX.ID);
        dispatch(setCardState(true));
        routeTo(ROUTES.DASHBOARD);
      } else {
        dispatch(setCurrentStep(previousStep));
      }
    } else {
      navigateTo();
    }
  };
  const gotToEditStep = () => {
    if (currentStep) {
      dispatch(setCurrentStep(FORM_STEPS.EDIT));
    } else {
      routeTo(childRoute?.path, childRoute?.isChild, childRoute?.stateName, childRoute?.query, childRoute?.stateObj);
    }
    dispatch(setIsEditAllowedFlag(false));
    dispatch(setIsDeleteAllowed(false));
  };

  const confirmAndDelete = () => {
    dispatch(setShowDeleteConfirmation(true));
  };

  return (
    <Box className={classes.appBar}>
      <CssBaseline />
      {!isChildPath && <IDCardIndex cardDirection={!isIDCardOpen ? 'up' : 'down'} hideCard={isChildPath} />}
      {isChildPath && (
        <Box>
          <AppBar>
            <Toolbar sx={{ px: 2 }} className={classes.toolbarAlign}>
              <Box className="flex-basic-space-between w-100">
                <Box className="flex-basic-space-between">
                  <Box className="flex-basic-center" sx={{ mr: 2 }}>
                    <span
                      onClick={() => goPrevious()}
                      className="cursorPointer icon-arrow-lefts textColor-200 icon-size-24"
                    ></span>
                  </Box>
                  {params?.state?.name ? (
                    <Box
                      className={clsx(
                        'text-medium font-weight-semibold textColor-200 text-ellipsis',
                        classes.textAlignment,
                      )}
                    >
                      {params?.state?.name}
                    </Box>
                  ) : (
                    <Box
                      className={clsx({
                        [classes.textAlignment]: true,
                        ['text-medium font-weight-semibold textColor-200']: true,
                      })}
                    >
                      {getPageTitle(location?.pathname)}
                    </Box>
                  )}
                </Box>
                <Box className="flex-basic-space-between">
                  {showEdit && (
                    <Box sx={{ mr: 2 }}>
                      <IconButton className={'textColor-200'} onClick={gotToEditStep} edge="end">
                        <span className="icon-edit icon-size-24" />
                      </IconButton>
                    </Box>
                  )}
                  {showDelete && (
                    <Box sx={{ mr: 2 }}>
                      <IconButton className={'textColor-200'} onClick={confirmAndDelete} edge="end">
                        <span className="icon-delete icon-size-24" />
                      </IconButton>
                    </Box>
                  )}
                  <Box>
                    <IconButton className={'textColor-200'} onClick={navigateTo} edge="end" aria-label={'Close'}>
                      <span className="icon-close icon-size-24" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      )}
      <Main className={classes.pageContainer}>{children}</Main>
    </Box>
  );
};

export default MobileDrawer;
