import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import {
  setUploadedVideo,
  setUploadedVideoToStore,
  selectIdeProcessDetails,
  setResetTimer,
  setResetTimeAndWords,
} from '../../../../redux/feature/idProcess/biometricVerificationSlice';
import { ACCEPTED_VIDEO_MIME_TYPES, SUPPORTED_VIDEO_TYPES, FILE_SIZE } from '../../../../constants/constants';
import FileUpload from '../../../../components/common/FileUpload';
import { TButton, TCard, theme } from '../../../../components/myde-react-components';
import ContainerCard from '../../../../components/common/ContainerCard';
import ReactPlayer from 'react-player';
import GenerateRandomNumber from './GenerateRandomNumber';
import CaptureVideo from './CaptureVideo';

interface RecordVideoProps {
  renderActions: React.ReactNode;
  disableConfirm: (value: boolean) => void;
  showCancelButton: (value: boolean) => void;
  showTimerPage: boolean;
  onVideoRecord: (step: number) => void;
  showRecord: boolean;
  showUpload: boolean;
  onVideoUpload: (step: number) => void;
}

const useStyles = makeStyles({
  recordUploadBtns: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '20px',
    marginTop: '20px',
  },
});

export const RecordVideo = ({
  onVideoRecord,
  renderActions,
  disableConfirm,
  showCancelButton,
  showTimerPage,
  showRecord,
  onVideoUpload,
  showUpload,
}: RecordVideoProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('biometricVerification');
  const [captureVideo, setCaptureVideo] = useState(false);
  const [uploadVideo, setUploadVideo] = useState(false);
  const [videoSavedUsing, setvideoSavedUsing] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [title, setTitle] = useState('Timer');
  const { idProcessItem, uploadedVideoToPreview } = useSelector(selectIdeProcessDetails);

  useEffect(() => {
    if (showTimerPage) {
      setUploadVideo(false);
      setCaptureVideo(false);
      setTitle('Timer');
      showCancelButton(false);
      dispatch(setResetTimer(true));
    }
  }, [showTimerPage]);
  useEffect(() => {
    if (showRecord) {
      disableConfirm(true);
      setShowPreview(false);
      showCancelButton(true);
      setUploadVideo(false);
      setCaptureVideo(true);
      setTitle('Timer');
    }
    if (showUpload) {
      disableConfirm(true);
      showCancelButton(true);
      setCaptureVideo(false);
      setUploadVideo(true);
      setShowPreview(false);
      setTitle('Timer');
    }
  }, [showRecord, showUpload]);

  const setVideo = (video: File, videoType: string) => {
    disableConfirm(false);
    dispatch(setUploadedVideo(video));
    dispatch(setUploadedVideoToStore(video));
    recordingOrUploadComplete(videoType);
  };

  const recordVideo = () => {
    onVideoRecord(2);
  };

  const recordingOrUploadComplete = (videoType: string) => {
    setUploadVideo(false);
    setCaptureVideo(false);
    setTitle('Preview');
    setvideoSavedUsing(videoType);
    videoType === 'record' && setShowPreview(true);
  };

  const videoUpload = () => {
    onVideoUpload(2);
  };

  const urlToDisplay = () => {
    if (Object.keys(uploadedVideoToPreview).length === 0 && idProcessItem?.data?.video) {
      return idProcessItem?.data?.video?.signed_url;
    } else {
      return uploadedVideoToPreview?.preview;
    }
  };

  const reRecordHandler = () => {
    dispatch(setResetTimeAndWords(true));
    setShowPreview(false);
    recordVideo();
  };

  const renderRequirements = () => {
    return (
      <Box>
        <Box sx={{ p: 2 }}>
          <Box className="text-center textColor-300 text-large font-weight-semibold">{t('biometricRecordVideo')}</Box>
        </Box>
        <Divider />
        <TCard sx={{ mx: 3, my: theme.spacing(5) }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box>{<GenerateRandomNumber title={title} startTimer={showRecord || showUpload} />}</Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {captureVideo && !uploadVideo && (
                <Box sx={{ mb: 21 }}>
                  <CaptureVideo
                    setFile={(video: File) => setVideo(video, 'record')}
                    uploadedFile={
                      Object.keys(uploadedVideoToPreview).length > 0
                        ? uploadedVideoToPreview
                        : idProcessItem?.data?.video
                    }
                  />
                </Box>
              )}
              {!captureVideo && (uploadVideo || videoSavedUsing === 'upload') && !showTimerPage && (
                <Box sx={{ mx: 3, mb: 3 }}>
                  <FileUpload
                    maxFileSize={FILE_SIZE.VIDEO_FILE_SIZE}
                    setFile={(video: File) => setVideo(video, 'upload')}
                    uploadedFile={Object.keys(uploadedVideoToPreview).length > 0 ? uploadedVideoToPreview : ''}
                    acceptedFileFormat={ACCEPTED_VIDEO_MIME_TYPES}
                    supportedFileFormat={SUPPORTED_VIDEO_TYPES}
                    isVideo={true}
                    isDoc={false}
                    showTitleText={false}
                  />
                </Box>
              )}

              {showPreview && videoSavedUsing === 'record' && !showTimerPage && (
                <>
                  <Box className="flex-basic-center" sx={{ mx: 2, mt: 4, mb: 2 }}>
                    <ReactPlayer width={'300px'} height={'144px'} url={urlToDisplay()} controls={true} />
                  </Box>
                  <Box sx={{ mb: 2 }} className={classes.recordUploadBtns}>
                    <TButton
                      icon={<Box className="icon-upload cust-icon-size" sx={{ pr: 1 }} />}
                      variant={'outlined'}
                      btnText={'Re-Record'}
                      btnWidthSize="button-w-140"
                      onClick={reRecordHandler}
                    />
                  </Box>
                </>
              )}

              {showTimerPage && (
                <>
                  <Box className={classes.recordUploadBtns}>
                    <TButton
                      icon={<Box className="icon-record-video cust-icon-size" sx={{ pr: 1 }} />}
                      variant={'outlined'}
                      btnText={'Record'}
                      btnWidthSize="button-w-140"
                      onClick={recordVideo}
                    />
                    <TButton
                      icon={<Box className="icon-upload cust-icon-size" sx={{ mr: 1 }} />}
                      btnText={'Upload'}
                      variant={'outlined'}
                      btnWidthSize="button-w-140"
                      onClick={videoUpload}
                    />
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </TCard>
      </Box>
    );
  };
  return <ContainerCard content={renderRequirements()}>{renderActions}</ContainerCard>;
};
