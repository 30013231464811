import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  selectProfile,
  getProfileDetails,
  getProfileDetailsById,
  deleteProfileById,
} from '../../../../redux/feature/profile/profileSlice';
import {
  colorPalette,
  ID_PROCESS_STATUS,
  ID_PROCESS_STATUS_COMPLETION_STATE,
  TButton,
  TCard,
  TConfirmDialog,
  theme,
  TLoader,
} from '../../../../components/myde-react-components';
import { VERIFICATION_STATUS, ID_PROCESS_STATUS_TEXT } from '../../../../constants/constants';
import {
  resendEntityEmailVerification,
  resendProfileVerifyLinkHandler,
  selectDashboardDetails,
} from '../../../../redux/feature/verifyEmail/verifyEmailSlice';
import { useTheme } from '../../../../providers/custom-theme-provider';
import CommonSubscriptionScreen from '../../../../components/common/CommonSubscriptionScreen';
import { membershipFlip } from '../../../../api/profile';
import { capitalize, removeUnderscore } from '../../../../utils/utils';
import { selectEntityProfile } from '../../../../redux/feature/profile/entityProfileSlice';
import { PhoneType, ProfileDetails } from '../../../../types/profileTypes';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';
import {
  selectSession,
  setCurrentStep,
  setIsDeleteAllowed,
  setIsEditAllowedFlag,
  setPreviousStep,
  setShowDeleteConfirmation,
} from '../../../../redux/feature/common/commonSlice';

interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  verificationCard: {
    background: colorPalette.containerBack.base,
    borderRadius: '10px',
  },
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
});

interface ProfileDetailsViewProps {
  isEntity: boolean;
}

const ProfileDetailsView = ({ isEntity }: ProfileDetailsViewProps) => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //redux values
  const { profileHolder, profileData } = useSelector(selectProfile);
  const { loading, showResendBtn } = useSelector(selectDashboardDetails);
  const { entityProfileHolder, entityLoading } = useSelector(selectEntityProfile);
  const { selectedEntity } = useSelector(selectEntity);
  const { showDeleteConfirmation } = useSelector(selectSession);

  //state varibales
  const [selectedProfileHolder, setSelectedProfileHolder] = useState({} as ProfileDetails);
  const [addressStatus, setAddressStatus] = useState('');
  const [emailStatus, setEmailStatus] = useState('');
  const [phones, setPhones] = useState([] as PhoneType[]);
  const [isPremium, setIsPremium] = useState(false);
  const [individualProfileHolder, setIndividualProfileHolder] = useState({} as ProfileDetails);

  //useEffects
  useEffect(() => {
    dispatch(getProfileDetails());
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
  }, []);

  useEffect(() => {
    const phoneList: PhoneType[] = isEntity ? entityProfileHolder?.phones : profileHolder?.phones;
    let filteredPhones: PhoneType[] = [];
    if (phoneList?.length > 0) {
      filteredPhones = [...phoneList]?.reverse();
    }
    setPhones(filteredPhones);
    const specificProfileDetail = isEntity ? entityProfileHolder : profileHolder;
    setIndividualProfileHolder(profileHolder);
    setSelectedProfileHolder(specificProfileDetail);
    setEmailStatus(specificProfileDetail?.profile_email?.verification_status);
    setAddressStatus(specificProfileDetail?.address?.address_verification_status || '');
  }, [isEntity, entityProfileHolder, profileHolder]);

  // TODO: Remove this use effect when edit / delete is implemented for entity profiles
  useEffect(() => {
    // Checking here for a false value because if we use a not condition
    // then the flag for edit and delete will get true due to negation
    if (
      individualProfileHolder?.is_profile_in_use === false &&
      ![ID_PROCESS_STATUS.VERIFICATION_IN_PROGRESS].includes(
        individualProfileHolder?.address?.address_verification_status,
      )
    ) {
      dispatch(setIsEditAllowedFlag(true));
      dispatch(setIsDeleteAllowed(true));
    }
  }, [individualProfileHolder]);

  useEffect(() => {
    if (profileData?.is_premium) {
      setIsPremium(profileData.is_premium);
    }
  }, [profileData]);

  const fieldComponent = (label: string, value: string) => {
    return (
      <>
        <Box sx={{ mb: 1 }} className="textColor-300 text-small font-weight-regular">
          {label}
        </Box>
        <Box className="textColor-200 text-medium font-weight-semibold">{value}</Box>
      </>
    );
  };

  const resentProfileVerificationLink = () => {
    if (isEntity) {
      const data = {
        entityUid: selectedEntity?.trellis_uid,
        profile_id: entityProfileHolder?.id,
      };
      dispatch(resendEntityEmailVerification(data));
    } else {
      dispatch(resendProfileVerifyLinkHandler(profileHolder?.id));
    }
  };

  const handleVerify = async () => {
    const result = await membershipFlip();
    if (result?.id) {
      dispatch(getProfileDetails());
      if (isEntity) {
        dispatch(getProfileDetailsById(entityProfileHolder?.id));
      } else {
        dispatch(getProfileDetailsById(profileHolder?.id));
      }
    }
  };

  const closeConfirmationDialog = () => {
    dispatch(setShowDeleteConfirmation(false));
  };

  const deleteSpecificProfile = async (data: ProfileDetails) => {
    await dispatch(deleteProfileById(data?.id));
    dispatch(setShowDeleteConfirmation(false));
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    dispatch(setPreviousStep(null));
  };

  return (
    <>
      {selectedProfileHolder && Object.keys(selectedProfileHolder)?.length > 0 ? (
        <Box>
          <TCard sx={{ mb: 1 }}>
            <Box>
              {!isPremium ? (
                <>
                  <Box sx={{ mx: 3, my: 2 }}>{fieldComponent('Profile Name', selectedProfileHolder?.profile_name)}</Box>
                  <Divider />
                </>
              ) : (
                <>
                  <Box sx={{ p: 3 }} className="flex-column-center w-100">
                    <Box sx={{ mb: 3 }} className={clsx('icon-profile-users', classes.icon, 'icon-size-72')} />
                    <Box className="textColor-200 text-h2 font-weight-semibold">
                      {selectedProfileHolder?.profile_name}
                    </Box>
                  </Box>
                </>
              )}
              <Box className="p-relative" sx={{ mx: 3, my: 2 }}>
                <Box className="add-address-section">
                  {selectedProfileHolder?.address?.address_verification_status_label ===
                    ID_PROCESS_STATUS_TEXT.VERIFICATION_IN_PROGRESS && (
                    <Box className="flex-basic-end">
                      <Box sx={{ pt: theme.spacing(5) }} className="p-relative small-sizes custom-progress-icon">
                        <Box className="progress-spinner sm-spinner"></Box>
                      </Box>
                    </Box>
                  )}
                  {selectedProfileHolder?.address?.address_verification_status_label ===
                    ID_PROCESS_STATUS_TEXT.VERIFIED && (
                    <Box>
                      <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                        <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
                      </Box>
                    </Box>
                  )}
                  {selectedProfileHolder?.address?.address_verification_status_label ===
                    ID_PROCESS_STATUS_TEXT.VERIFICATION_FAILED && (
                    <Box>
                      <Box sx={{ pt: theme.spacing(5) }} className="p-relative">
                        <span className="icon-error-warning error-color-state icon-size-24"></span>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box className="textColor-200 text-h2 font-weight-semibold">Address</Box>
                {selectedProfileHolder?.address_type && (
                  <Box sx={{ my: 2 }}>
                    {fieldComponent('Address Type', removeUnderscore(selectedProfileHolder?.address_type))}{' '}
                  </Box>
                )}
                <Box sx={{ my: 2 }}>{fieldComponent('Address Line 1', selectedProfileHolder?.address?.address1)} </Box>
                <Box sx={{ my: 2 }}>
                  {fieldComponent('Address Line 2', selectedProfileHolder?.address?.address2 || '-')}{' '}
                </Box>
                <Box sx={{ my: 2 }}>
                  {fieldComponent('Address Line 3', selectedProfileHolder?.address?.address3 || '-')}{' '}
                </Box>
                <Box sx={{ my: 2 }}>{fieldComponent('City', selectedProfileHolder?.address?.city)}</Box>

                <Box>
                  <Box>
                    <Box>{fieldComponent('State', selectedProfileHolder?.address?.state_name)}</Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Box>{fieldComponent('Zip', selectedProfileHolder?.address?.zip)}</Box>
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Box>{fieldComponent('Country', selectedProfileHolder?.address?.country_name)}</Box>
                  </Box>
                </Box>
                <Box sx={{ my: 3 }}>
                  {(isPremium || selectedProfileHolder.status == VERIFICATION_STATUS.VERIFIED) && !isEntity && (
                    <>
                      <Box className="flex-basic-space-between w-100">
                        <Box>
                          <Box className="textColor-300 text-small font-weight-regular">{t('status')}</Box>
                          <Box sx={{ pt: 1 }} className="textColor-200 text-medium font-weight-semibold">
                            {ID_PROCESS_STATUS_TEXT[addressStatus]}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                  {!isPremium && !isEntity && selectedProfileHolder.status != VERIFICATION_STATUS.VERIFIED && (
                    <CommonSubscriptionScreen
                      header={t('addressNotVerified')}
                      subtitle={t('addressNotVerifiedSubtitle')}
                      renderAction={
                        <TButton
                          btnWidthSize="button-w-240"
                          btnText="Become a Premium Member"
                          variant="contained"
                          onClick={handleVerify}
                        />
                      }
                    />
                  )}
                </Box>
              </Box>
              <Divider />
              <Box className="p-relative" sx={{ mx: 3, my: 2 }}>
                <Box className="textColor-200 text-h2 font-weight-semibold">Contact</Box>
                <Box sx={{ my: 2 }}>{fieldComponent('Email', selectedProfileHolder?.profile_email?.email)}</Box>

                {phones?.map((element, index) => (
                  <>
                    {element?.phone !== '' && (
                      <Box sx={{ my: 2 }} key={index}>
                        {fieldComponent(`${element?.phone_type} (${capitalize(element?.category)})`, element?.phone)}
                      </Box>
                    )}
                  </>
                ))}
                {emailStatus === VERIFICATION_STATUS.VERIFIED && (
                  <Box sx={{ my: 2 }}>{fieldComponent('Status', 'Verified')}</Box>
                )}
                {emailStatus !== VERIFICATION_STATUS.VERIFIED && (
                  <>
                    <Box sx={{ p: 2 }} className={classes.verificationCard}>
                      <Box sx={{ my: 1 }} className="text-center textColor-200 text-large font-weight-bold">
                        {t('verificationTitle')}
                      </Box>
                      <Box sx={{ my: 1 }} className="text-center textColor-300 text-small font-weight-regular">
                        {t('verificationBody')}
                      </Box>
                      {showResendBtn && (
                        <Box className="flex-basic-center" sx={{ mt: 2, mb: 1 }}>
                          <TButton
                            btnText="Resend Verification Email"
                            variant="contained"
                            btnWidthSize="button-w-240"
                            disabled={loading}
                            onClick={resentProfileVerificationLink}
                          />
                        </Box>
                      )}
                      {!showResendBtn && (
                        <Box sx={{ my: 2, color: currentThemes.palette.primary.main }}>
                          {t('emailVerificationLinkSent')}
                        </Box>
                      )}
                    </Box>
                  </>
                )}
                <Box>
                  {emailStatus === VERIFICATION_STATUS.VERIFIED && (
                    <Box className="add-email-section">
                      <Box className="p-relative">
                        <span className={clsx(classes.icon, 'icon-approved icon-size-24')}></span>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </TCard>
          <TConfirmDialog
            title={t('delete')}
            onCancelClick={closeConfirmationDialog}
            onConfirmClick={() => deleteSpecificProfile(selectedProfileHolder)}
            showDialog={showDeleteConfirmation}
            confirmationMessage={t('deleteProfileMessage')}
          />
        </Box>
      ) : (
        <>
          <TLoader loading={loading || entityLoading} />
        </>
      )}
    </>
  );
};

export default ProfileDetailsView;
