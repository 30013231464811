import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TLoader } from '../../../myde-react-components';
import ResultList from './ResultList';
import {
  getProcessDetailsById,
  kycAmlIdProcessDetails,
  resetKycRunId,
} from '../../../../redux/feature/idProcess/kycamlSlice';
import { selectSession, setRefreshPage } from '../../../../redux/feature/common/commonSlice';
import useQuery from '../../../../hooks/useQuery';

// Main Component
export const KycAmlReport = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const processId = query.get('id');

  //redux values
  const { loading } = useSelector(kycAmlIdProcessDetails);
  const { refreshPage } = useSelector(selectSession);

  // Use Effect
  useEffect(() => {
    fetchData();
    dispatch(resetKycRunId());
  }, []);

  useEffect(() => {
    if (refreshPage) {
      fetchData();
      dispatch(setRefreshPage(false));
    }
  }, [refreshPage]);

  //Methods
  const fetchData = () => {
    const id = processId !== null ? processId : '';
    dispatch(getProcessDetailsById(id));
  };

  // HTML
  return (
    <>
      <TLoader loading={loading} />
      <ResultList fetchData={fetchData} />
    </>
  );
};

export default KycAmlReport;
