import React from 'react';

import { Navigate } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { useAuth } from '../auth/auth-guard';

interface IGuardedRouteProps {
  component: any;
  path?: string;
}

export const GuardedRoute = ({ component: RouteComponent }: IGuardedRouteProps) => {
  const { accessToken } = useAuth();

  if (accessToken) {
    return <RouteComponent />;
  }

  return <Navigate to={ROUTES.LOGIN} />;
};

GuardedRoute.defaultProps = {
  path: '',
};
