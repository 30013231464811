import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { t } from 'i18next';

import { useTheme } from '../../../../providers/custom-theme-provider';
import { PdfViewer, TCard, theme, ID_PROCESS_STATUS, TFullScreenDialog } from '../../../myde-react-components';
import { selectSelectedIdProcessDetails } from '../../../../redux/feature/idProcess/mainIdProcessSlice';

interface W9W8BenVerificationProps {
  isEntity: boolean;
}

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  iconStyle: {
    marginTop: '14px',
  },
});

const W9W8BenVerification = ({ isEntity }: W9W8BenVerificationProps) => {
  // Constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  // Redux Values
  const { selectedIdProcessDetails, selectedEntityIdProcessDetails } = useSelector(selectSelectedIdProcessDetails);

  // State Variables
  const [open, setOpen] = useState(false);
  const [idProcess, setIdProcess] = useState({} as any);

  useEffect(() => {
    const idProcessDetails = isEntity ? selectedEntityIdProcessDetails : selectedIdProcessDetails;
    setIdProcess(idProcessDetails);
  }, [isEntity, selectedEntityIdProcessDetails, selectedIdProcessDetails]);

  const handleEyeIconClick = () => {
    if (idProcess?.data?.signed_document?.signed_url) {
      setOpen(true);
    } else {
      toast.error('Invalid URL, please try again!');
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TCard sx={{ p: 2 }}>
        <Box className="flex-column-center">
          <Box sx={{ mb: 2 }} className={clsx('icon-w8 icon-size-60', classes.icon)} />
          <Box className="textColor-200 text-h2 font-weight-semibold">{idProcess?.data?.document_type}</Box>
          <Box className="textColor-200 text-medium font-weight-regular w-100">
            <Grid container sx={{ mt: theme.spacing(14) }}>
              <Grid item xs={12}>
                <Box className="flex-basic-start">
                  <Box
                    sx={{ ml: theme.spacing(5), mr: theme.spacing(5) }}
                    className={clsx('icon-profile icon-size-32', classes.icon)}
                  />
                  <Box>
                    <Box className="text-small">{t('profile', { ns: 'common' })}</Box>
                    <Box className="text-medium font-weight-semibold">{idProcess?.data?.profile?.profile_name}</Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container sx={{ my: theme.spacing(8) }}>
              <Grid item xs={10}>
                <Box className="flex-basic-start">
                  <Box
                    sx={{ ml: theme.spacing(5), mr: theme.spacing(5) }}
                    className={clsx('icon-w8-w9 icon-size-32', classes.icon)}
                  />
                  <Box>
                    <Box className="text-small">{t('formType', { ns: 'w8' })}</Box>
                    <Box className="text-medium font-weight-semibold">{`Form ${idProcess?.data?.document_type}`}</Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2} className="flex-column-center">
                {idProcess?.status === ID_PROCESS_STATUS.SUBMITTED ? (
                  <Box className="progress-spinner sm-spinner" />
                ) : (
                  <Box
                    className={clsx('icon-eye text-h3 textColor-200 cursorPointer', classes.iconStyle)}
                    onClick={() => handleEyeIconClick()}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </TCard>
      <TFullScreenDialog
        open={open}
        closeModal={handleDialogClose}
        title={'Signed Document'}
        dialogContent={
          <Box className={classes.imgPreviewContainer}>
            <PdfViewer maxArea domain={'myde'} uri={idProcess?.data?.signed_document?.signed_url} />
          </Box>
        }
      />
    </>
  );
};

export default W9W8BenVerification;
