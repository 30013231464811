import React from 'react';
import {
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonProps,
  ToggleButtonGroupProps,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ButtonToggleType } from '../../types/commonTypes';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { colorPalette } from '../../constants/colors';
import { hexToRgbA } from '../../utils/commonMethods';

interface ButtonToggleProps {
  buttons: ButtonToggleType[];
}

interface StyleProps {
  currentTheme: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  toggleButtonStyle: {
    '& .MuiToggleButton-root': {
      padding: '6px 12px',
      border: ({ currentTheme }) => `1px solid ${currentTheme.palette.primary.main}`,
      borderRadius: '4px',
      textTransform: 'capitalize',
      color: `${colorPalette.typoText.unSelected} !important`,
    },
    '& .Mui-selected': {
      color: ({ currentTheme }) => `${currentTheme.palette.primary.main} !important`,
      backgroundColor: ({ currentTheme }) => `${hexToRgbA(`${currentTheme.palette.primary.main}`, 0.08)} !important`,
    },
  },
});

export type CombinedProps = ButtonToggleProps & ToggleButtonProps & ToggleButtonGroupProps;

export const ButtonToggle = ({ buttons, ...props }: CombinedProps) => {
  // Constants
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentTheme: currentThemes,
  };
  const classes = useStyles(styleProps);

  // HTML
  return (
    <MuiToggleButtonGroup
      {...props}
      className={classes.toggleButtonStyle}
      color="primary"
      exclusive
      aria-label="Platform"
    >
      {buttons?.map((item, index) => (
        <ToggleButton {...props} key={index} value={item?.value}>
          {item?.label}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
};

export default ButtonToggle;
