import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setUploadedImage,
  setUploadedImageToPreview,
  selectIdeProcessDetails,
  createSignVerification,
} from '../../../../redux/feature/idProcess/signVerificationSlice';
import GenerateRandomNumber from './GenerateRandomNumber';
import {
  ACCEPTED_IMAGE_MIME_TYPES,
  FILE_SIZE,
  SUPPORTED_IMAGE_TYPES,
  VERIFICATION_ACTIONS,
} from '../../../../constants/constants';
import {
  FilePreviewType,
  ID_PROCESS_STATUS_COMPLETION_STATE,
  PROCESS_IDS,
  TButton,
  TCard,
  TLoader,
  theme,
} from '../../../../components/myde-react-components';
import UploadOrCapturePhoto from '../../../../components/common/UploadOrCapturePhoto';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';

const WetSignatureConfirmation = () => {
  //constants
  const dispatch = useDispatch();
  const { t } = useTranslation(['wetSignature', 'common']);
  const [preview, setPreview] = useState([] as FilePreviewType[]);

  // Redux Values
  const { uploadedImage, verificationItem, idProcessItem, uploadedImageToPreview, loading } =
    useSelector(selectIdeProcessDetails);

  // Use Effects
  useEffect(() => {
    let payload: FilePreviewType = {} as FilePreviewType;
    if (
      (uploadedImageToPreview && uploadedImageToPreview?.name) ||
      (idProcessItem && idProcessItem?.data?.signature?.doc_id)
    ) {
      payload = {
        name: uploadedImageToPreview?.name || idProcessItem?.data?.signature?.doc_name || '',
        type: uploadedImageToPreview?.type || idProcessItem?.data?.signature?.mime_type || '',
        preview: uploadedImageToPreview?.preview || idProcessItem?.data?.signature?.signed_url || '',
        size: uploadedImageToPreview?.size || idProcessItem?.data?.signature?.size_in_kb || '',
      };
    }
    setPreview([payload]);
  }, [uploadedImageToPreview, idProcessItem]);

  // Methods
  const setImage = (image: File) => {
    dispatch(setUploadedImage(image));
    dispatch(setUploadedImageToPreview(image));
  };
  const validateBtn = () => {
    let result = false;
    if (
      (uploadedImage && Object.keys(uploadedImage)?.length > 0) ||
      (idProcessItem?.data?.signature && Object.keys(idProcessItem?.data)?.length > 0)
    ) {
      result = true;
    }
    return result;
  };

  const getFormData = () => {
    const formData = {
      process_id: PROCESS_IDS.SIGN_VERIFICATION,
      process_data: {
        signature_id:
          Object.keys(uploadedImage)?.length > 0 ? uploadedImage?.doc_id : idProcessItem?.data?.signature?.doc_id,
        verification_string:
          Object.keys(verificationItem)?.length > 0
            ? verificationItem?.verification_item
            : idProcessItem.data?.verification_string,
      },
      action: VERIFICATION_ACTIONS.SUBMIT,
    };
    return formData;
  };

  const submitWetSignatureDocs = async () => {
    const formData = getFormData();
    await dispatch(createSignVerification(formData));
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[0].percent));
  };
  return (
    <>
      <TCard sx={{ mb: 10 }}>
        <TLoader loading={loading} />
        <Box sx={{ p: theme.spacing(5) }}>
          <Box className="text-center text-large font-weight-semibold textColor-300">
            {t('uploadPhoto', { ns: 'wetSignature' })}
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 3 }}>
          <Box>
            <Box className="text-h3 font-weight-semibold textColor-200">{t('instructionTitle')}</Box>
            <Box className="text-medium textColor-200" sx={{ mt: 1 }}>
              {t('signInstructionSubtitle', { ns: 'wetSignature' })}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box sx={{ px: 3, pt: 2 }}>
            <GenerateRandomNumber />
          </Box>
          <Box sx={{ px: 3, my: 3 }}>
            <UploadOrCapturePhoto
              showTitleText={isMobile ? t('uploadAndCaptureImageText', { ns: 'common' }) : ''}
              isMultiple={false}
              acceptedFileFormat={ACCEPTED_IMAGE_MIME_TYPES}
              supportedFileFormat={SUPPORTED_IMAGE_TYPES}
              maxFileSize={FILE_SIZE.IMAGE_FILE_SIZE}
              setData={setImage}
              uploadedFile={preview}
            />
          </Box>
          <Box sx={{ my: 4 }} className={'text-center'}>
            <TButton
              variant={'contained'}
              disabled={!validateBtn()}
              btnText={'Submit'}
              btnWidthSize={'button-w-240'}
              onClick={submitWetSignatureDocs}
            />
          </Box>
        </Box>
      </TCard>
    </>
  );
};

export default WetSignatureConfirmation;
