import * as React from 'react';
import { Stepper as MuiStepper, Step, StepLabel, StepperProps, StepProps, StepLabelProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette } from '../../constants/colors';

export interface CustomProps {
  stepsList: {
    value: string;
    key?: any;
  }[];
}

export type CombinedProps = CustomProps & StepperProps & StepProps & StepLabelProps;

const useStyles = makeStyles({
  stepper: {
    '& .MuiStepLabel-label': {
      fontWeight: 600,
      fontSize: '0.875rem',
      color: colorPalette.typoText.lighten1,
    },
    '& .MuiStepLabel-label.Mui-completed': {
      fontWeight: 600,
      fontSize: '0.875rem',
      color: colorPalette.typoText.lighten1,
    },
    '& .MuiStepLabel-label.Mui-active':{
      fontWeight: 600,
      fontSize: '0.875rem',
      color: colorPalette.success,
    },
    '& .MuiSvgIcon-root': {
      '& .MuiStepIcon-text': {
        visibility: 'hidden',
      },
    },
  },
});

export const Stepper = ({ stepsList, ...props }: CombinedProps) => {
  const classes = useStyles();
  return (
    <MuiStepper {...props}>
      {stepsList.map((step) => (
        <Step key={step.key} {...props}>
          <StepLabel className={classes.stepper} {...props}>
            {step.value}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};
export default Stepper;
