import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { selectEntity } from '../../../../redux/feature/entity/entitySlice';
import {
  addEntityProfileDetails,
  getEntityProfileDetailsList,
} from '../../../../redux/feature/profile/entityProfileSlice';
import {
  addProfileDetails,
  editProfileById,
  getProfileDetailsList,
  selectProfile,
} from '../../../../redux/feature/profile/profileSlice';
import { ProfileFormDataType } from '../../../../types/profileTypes';
import { ID_PROCESS_STATUS_COMPLETION_STATE, TCard } from '../../../myde-react-components';
import ProfileForm from './ProfileForm';

interface ProfileFormIndexProps {
  isEntity: boolean;
}
const ProfileFormIndex = ({ isEntity }: ProfileFormIndexProps) => {
  // Constants
  const dispatch = useDispatch();

  // Use Selectors
  const { selectedEntity } = useSelector(selectEntity);
  const { profileHolder } = useSelector(selectProfile);

  // Methods
  const saveProfileData = (requestPayload: ProfileFormDataType, isEditMode?: boolean) => {
    if (isEntity) {
      const formPayload = {
        entityUid: selectedEntity?.trellis_uid,
        formData: requestPayload,
      };
      dispatch(addEntityProfileDetails(formPayload));
      dispatch(getEntityProfileDetailsList({ entityUId: selectedEntity?.trellis_uid, params: {} }));
    } else {
      if (isEditMode) {
        const data = {
          id: profileHolder?.id,
          requestPayload: requestPayload,
        };
        dispatch(editProfileById(data));
      } else {
        dispatch(addProfileDetails(requestPayload));
      }
      dispatch(getProfileDetailsList({}));
    }
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
    dispatch(setPreviousStep(null));
  };

  // HTML
  return (
    <>
      <TCard sx={{ mb: 8 }}>
        <ProfileForm isEntity={isEntity} saveProfileData={saveProfileData} />
      </TCard>
    </>
  );
};

export default ProfileFormIndex;
