import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ID_PROCESS_STATUS_COMPLETION_STATE, TPaper } from '../../../myde-react-components';
import { setCurrentStep, setPreviousStep } from '../../../../redux/feature/common/commonSlice';
import { APPLICATION_STATUS, PROCESS_IDS } from '../../../../constants/constants';
import { submitIncomeVerification } from '../../../../redux/feature/idProcess/incomeVerificationSlice';
import { IncomeVerificationDocument } from '../../../../types/incomeVerificationType';
import {
  clearIdProcessMultipleData,
  selectSelectedIdProcessDetails,
} from '../../../../redux/feature/idProcess/mainIdProcessSlice';
import { FileType } from '../../../../types/documentTypes';
import UploadDoc from '../common/UploadDoc';

const IncomeConfirmation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  //State Values
  const [preview, setPreview] = useState([] as FileType[]);

  //Redux Values
  const { selectedIdProcessDetails, uploadedDocIdMultiple } = useSelector(selectSelectedIdProcessDetails);

  //Use Effects

  useEffect(() => {
    if (
      Object.keys(selectedIdProcessDetails)?.length > 0 &&
      selectedIdProcessDetails?.data?.document_id_list?.length > 0
    ) {
      setPreview(selectedIdProcessDetails?.data?.document_id_list);
    }
  }, [selectedIdProcessDetails]);

  //Methods
  const handleNext = () => {
    if (uploadedDocIdMultiple?.length > 0) {
      const payload: IncomeVerificationDocument = {
        process_id: PROCESS_IDS.INCOME_VERIFICATION,
        process_data: {
          document_id_list: uploadedDocIdMultiple,
        },
        action: APPLICATION_STATUS.SUBMIT,
      };
      dispatch(submitIncomeVerification(payload));
      dispatch(clearIdProcessMultipleData());
      dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[2].percent));
      dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
    } else {
      toast.error(t('somethingWentWrongText', { ns: 'notificationMessages' }));
    }
  };

  //HTML
  return (
    <TPaper>
      <UploadDoc buttonText={'Submit'} handleNext={handleNext} isMultiple={true} previewFiles={preview} />
    </TPaper>
  );
};

export default IncomeConfirmation;
