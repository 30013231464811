import userReducer from './feature/user/userSlice';
import commonReducer from './feature/common/commonSlice';
import dashboardReducer from './feature/dashboard/dashboardSlice';
import signVerificationReducer from './feature/idProcess/signVerificationSlice';
import biometricVerificationReducer from './feature/idProcess/biometricVerificationSlice';
import profileReducer from './feature/profile/profileSlice';
import kycamlReducer from './feature/idProcess/kycamlSlice';
import addressVerificationReducer from './feature/idProcess/addressVerificationSlice';
import ssnVerificationReducer from './feature/idProcess/ssnVerificationSlice';
import documentVerificationReducer from './feature/idProcess/documentVerificationSlice';
import supplementalVerificationReducer from './feature/idProcess/supplementalVerificationSlice';
import inviteReducer from './feature/invite/inviteSlice';
import messageReducer from './feature/message/messageSlice';
import myTAppsReducer from './feature/myTApps/myTAppsSlice';
import verifyEmailReducer from './feature/verifyEmail/verifyEmailSlice';
import customIdProcessReducer from './feature/idProcess/customIdProcessSlice';
import entityReducer from './feature/entity/entitySlice';
import documentsReducer from './feature/documents/documentsSlice';
import idProcessMainReducer from './feature/idProcess/mainIdProcessSlice';
import inviteDashboardReducer from './feature/dashboard/invitationSlice';
import incomeVerificationReducer from './feature/idProcess/incomeVerificationSlice';
import entityProfileReducer from './feature/profile/entityProfileSlice';
import beneficialOwnerDocumentReducer from './feature/idProcess/beneficialOwnerDocumentSlice';
import idProcessShareReducer from './feature/idProcess/idProcessShareSlice';
import notificationReducer from './feature/notification/notificationSlice';
import userPasswordReducer from './feature/profile/passwordSlice';
import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';

const reducer = combineReducers({
  common: commonReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  signVerification: signVerificationReducer,
  biometricVerification: biometricVerificationReducer,
  profile: profileReducer,
  addressVerification: addressVerificationReducer,
  ssnVerification: ssnVerificationReducer,
  documentVerification: documentVerificationReducer,
  supplementalVerification: supplementalVerificationReducer,
  invite: inviteReducer,
  message: messageReducer,
  myTApps: myTAppsReducer,
  verifyEmail: verifyEmailReducer,
  entity: entityReducer,
  customIdProcess: customIdProcessReducer,
  kycaml: kycamlReducer,
  documents: documentsReducer,
  idProcessMain: idProcessMainReducer,
  inviteDashboard: inviteDashboardReducer,
  incomeVerification: incomeVerificationReducer,
  entityProfile: entityProfileReducer,
  beneficialOwner: beneficialOwnerDocumentReducer,
  idProcessShare: idProcessShareReducer,
  notification: notificationReducer,
  userPasswordData: userPasswordReducer,
});

const rootReducer: any = (state: RootState, action: AnyAction) => {
  if (action.type.indexOf('/clearResults') > -1) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');

    state = {} as RootState;
  }
  return reducer(state, action);
};

export type RootState = ReturnType<typeof reducer>;

export default rootReducer;
