import React, { useEffect, useState } from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from '../../hooks/useQuery';
import { isMobile } from 'react-device-detect';

import { PROFILE_PICTURE_ACTIONS } from '../../constants/constants';
import { useRouter } from '../../providers/custom-router-provider';
import { useTheme } from '../../providers/custom-theme-provider';
import {
  getSpecificEntityDetails,
  selectEntity,
  updateEntityDetails,
  uploadEntityAvatarFile,
} from '../../redux/feature/entity/entitySlice';
import { selectProfile, updateProfileDetails, uploadAvatarFile } from '../../redux/feature/profile/profileSlice';
import { SpecificEntityDataType, UpdateEntityDetailsType } from '../../types/entityTypes';
import {
  DOCUMENT_VERIFICATION_MIME_TYPES,
  TButton,
  TCard,
  TCommonAvatar,
  TConfirmDialog,
  theme,
  TLoader,
} from '../myde-react-components';
import * as ROUTES from '../../constants/routes';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  labelColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const ProfilePicture = () => {
  //Constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  //State
  const isEntity = useQuery().get('isEntity') === 'true';
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [entityDetails, setEntityDetails] = useState({} as SpecificEntityDataType);
  const [uploadingProfilePic, setUploadingProfilePic] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  //Redux
  const { specificEntityDetails, updatedEntityDetails, entityProfileURL } = useSelector(selectEntity);
  const { profileUrl, profileData } = useSelector(selectProfile);

  //useEffects
  useEffect(() => {
    if (specificEntityDetails?.id || updatedEntityDetails?.id) {
      setEntityDetails(
        updatedEntityDetails?.id
          ? updatedEntityDetails
          : specificEntityDetails?.id
          ? specificEntityDetails
          : ({} as SpecificEntityDataType),
      );
    }
  }, [specificEntityDetails, updatedEntityDetails]);

  useEffect(() => {
    isEntity ? setAvatarUrl(entityProfileURL) : setAvatarUrl(profileUrl);
    setUploadingProfilePic(false);
  }, [profileUrl, entityProfileURL]);

  //Methods
  const updateEntityProfileImage = async () => {
    const payload: UpdateEntityDetailsType = {
      entityUid: entityDetails?.trellis_uid,
      formData: {
        avatar_url: profileUrl === '' ? null : profileUrl,
      },
    };

    await dispatch(updateEntityDetails(payload));
    dispatch(getSpecificEntityDetails({ entityUid: entityDetails?.trellis_uid }));
    routeTo(ROUTES.ENTITY_PROFILE, true, specificEntityDetails?.name);
  };

  const updateUserProfileImage = () => {
    const data = {
      avatar_url: profileUrl === '' ? null : profileUrl,
    };

    dispatch(updateProfileDetails(data));
    routeTo(ROUTES.USER_PROFILE);
  };

  const checkImage = (event: any) => {
    const [file] = event.target.files;
    const img: HTMLImageElement = document.createElement('img');
    img.onload = function () {
      setUploadingProfilePic(true);
      dispatch(isEntity ? uploadEntityAvatarFile(file) : uploadAvatarFile(file));
    };
    img.src = URL.createObjectURL(file);
  };

  const handleConfirmation = () => {
    handleShowConfirmationDialog(false);
    const payload = {
      avatar_url: null,
    };

    if (isEntity) {
      const entityPayload = {
        entityUid: entityDetails?.trellis_uid,
        formData: payload,
      };
      dispatch(updateEntityDetails(entityPayload));
    } else {
      dispatch(updateProfileDetails(payload));
    }
  };

  const handleShowConfirmationDialog = (show: boolean) => {
    if (!avatarUrl) {
      return;
    }
    setShowConfirmationDialog(show);
  };

  const handleSave = () => {
    isEntity ? updateEntityProfileImage() : updateUserProfileImage();
  };

  const getUploadCaptureComponent = (action: string, handle: string) => {
    return (
      <label>
        {action === PROFILE_PICTURE_ACTIONS.UPLOAD ? (
          <input
            accept={DOCUMENT_VERIFICATION_MIME_TYPES.toString()}
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={checkImage}
          />
        ) : (
          <input
            accept={DOCUMENT_VERIFICATION_MIME_TYPES.toString()}
            style={{ display: 'none' }}
            id="contained-button-file"
            capture="environment"
            type="file"
            onChange={checkImage}
          />
        )}
        <Box className="flex-basic-center cursorPointer">
          <Box
            sx={{ mr: theme.spacing(5) }}
            className={clsx('icon-size-20', {
              ['icon-camera']: action === PROFILE_PICTURE_ACTIONS.CAPTURE,
              ['icon-image']: action === PROFILE_PICTURE_ACTIONS.UPLOAD,
            })}
          ></Box>
          <Box className={clsx('text-medium font-weight-semibold', classes.labelColor)}>{t(`${handle}`)}</Box>
        </Box>
      </label>
    );
  };

  //HTML
  return (
    <>
      <TLoader loading={uploadingProfilePic} />
      <TCard sx={{ mb: 9 }}>
        <Box sx={{ px: 3, mt: 3 }}>
          <TCommonAvatar
            imageUrl={avatarUrl || ''}
            avatarText={(isEntity ? entityDetails?.name : profileData?.name) || ''}
            iconSize={'277px'}
            textSize={'2rem'}
          />
          <Box className="flex-basic-center">
            <Box sx={{ mt: 7 }} className="flex-column-start align-items-start">
              {isMobile && (
                <>
                  <Box className="flex-basic-center cursorPointer">
                    {getUploadCaptureComponent(PROFILE_PICTURE_ACTIONS.CAPTURE, 'takePhoto')}
                  </Box>
                  <Divider sx={{ my: 2 }} className="w-100" />
                </>
              )}
              <Box className="flex-basic-center cursorPointer">
                {getUploadCaptureComponent(PROFILE_PICTURE_ACTIONS.UPLOAD, 'addFromGallery')}
              </Box>
              <Divider sx={{ my: 2 }} className="w-100" />
              <Box
                className={clsx('flex-basic-center', { ['cursorPointer']: avatarUrl })}
                onClick={() => handleShowConfirmationDialog(true)}
              >
                <Box sx={{ mr: theme.spacing(5) }} className="icon-delete icon-size-20"></Box>
                <Box
                  className={clsx('text-medium font-weight-semibold', {
                    [classes.labelColor]: avatarUrl,
                    ['textColor-400']: !avatarUrl,
                  })}
                >
                  {t('remove')}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ my: 10 }} className="flex-basic-center">
            <TButton
              btnText="Save"
              btnWidthSize="button-w-240"
              variant="contained"
              disabled={!avatarUrl}
              onClick={handleSave}
            />
          </Box>
        </Box>
      </TCard>

      <TConfirmDialog
        showDialog={showConfirmationDialog}
        onCancelClick={() => handleShowConfirmationDialog(false)}
        onConfirmClick={handleConfirmation}
        confirmationMessage={t('removeProfileImgMsg')}
        title={t('remove')}
      />
    </>
  );
};

export default ProfilePicture;
