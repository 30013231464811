import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import clsx from 'clsx';

import { colorPalette, TCard, theme, TLoader } from '../../../myde-react-components';
import { selectInviteDashboardDetails } from '../../../../redux/feature/dashboard/invitationSlice';
import {
  getOwnerApplicationDetails,
  selectBeneficialOwnerDetails,
} from '../../../../redux/feature/idProcess/beneficialOwnerDocumentSlice';
import { BeneficialOwnerDocumentType, ProcessType } from '../../../../types/invitationTypes';
import { useTheme } from '../../../../providers/custom-theme-provider';
import { getInitials } from '../../../../utils/utils';
import { BENEFICIAL_OWNER_ID_PROCESS_STATUS_TO_CHECK, MERCHANT_INVITE_STATUS } from '../../../../constants/constants';
import { setPreviousStep } from '../../../../redux/feature/common/commonSlice';

// Interfaces
interface StyleProps {
  primaryColor: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  userBoxContainer: {
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: 4,
  },
  colorPrimary: {
    color: ({ primaryColor }) => `${primaryColor} !important`,
  },
  idRequirementsContainer: {
    border: ({ primaryColor }) => `1px solid ${primaryColor}`,
    borderRadius: 4,
  },
});

const BeneficialOwnerDocuments = () => {
  // Constants
  const { t } = useTranslation('beneficialOwnerDocument');
  const dispatch = useDispatch();
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes.palette.primary.main;
  const styleProps = {
    primaryColor,
  };
  const classes = useStyles(styleProps);

  // Redux Values
  const { selectedApplication } = useSelector(selectInviteDashboardDetails);
  const { beneficialOwnersUserList, loading } = useSelector(selectBeneficialOwnerDetails);

  // State Variables
  const [ownersList, setOwnersList] = useState([] as BeneficialOwnerDocumentType[]);

  // Use Effects
  useEffect(() => {
    dispatch(setPreviousStep(null));
  }, []);

  useEffect(() => {
    if (selectedApplication?.id) {
      dispatch(getOwnerApplicationDetails(selectedApplication?.id));
    }
  }, [selectedApplication]);

  useEffect(() => {
    const result = beneficialOwnersUserList?.map((item) => {
      return {
        ...item,
        is_checked: false,
      };
    });
    setOwnersList(result);
  }, [beneficialOwnersUserList]);

  // Methods
  const getUserName = (data: BeneficialOwnerDocumentType) => {
    const name = `${data?.first_name} \t ${getInitials(data?.last_name)}`;
    return name;
  };

  const getUserStatus = (applicationStatus: string) => {
    if (applicationStatus === MERCHANT_INVITE_STATUS.PENDING_REVIEW) {
      return (
        <Box className="flex-basic-center">
          <Box className={clsx('icon-approved', classes.colorPrimary)} />
          <Box sx={{ pl: theme.spacing(2) }} className="text-extra-small textColor-300">
            {t('done')}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box className="flex-basic-center">
          <Box className="icon-ideal-state" />
          <Box sx={{ pl: theme.spacing(2) }} className="text-extra-small textColor-300">
            {t('pending')}
          </Box>
        </Box>
      );
    }
  };

  const showMoreDetails = (owner: BeneficialOwnerDocumentType) => {
    const users = ownersList?.map((item) => {
      if (item?.id === owner?.id) {
        return {
          ...item,
          is_checked: !item?.is_checked,
        };
      }
      return {
        ...item,
      };
    });
    setOwnersList(users);
  };

  const getIconByStatus = (status: string) => {
    const isVerified = BENEFICIAL_OWNER_ID_PROCESS_STATUS_TO_CHECK.includes(status);
    if (isVerified) {
      return <Box className={clsx('icon-approved', classes.colorPrimary)} />;
    } else {
      return <Box className="icon-ideal-state" />;
    }
  };

  // HTML
  return (
    <TCard className="beneficialUsersCardHeight">
      <TLoader loading={loading} />
      <Box sx={{ p: 2 }}>
        <Box className="text-h3 font-weight-semibold">{t('ownerLabel')}</Box>
        <Box sx={{ my: 3, pr: theme.spacing(1) }} className="beneficialUsersList">
          {ownersList?.map((item: BeneficialOwnerDocumentType, index: number) => (
            <Box key={index}>
              <Box sx={{ px: 1, py: 2, mt: 2 }} className={clsx(classes.userBoxContainer, 'flex-basic-space-between')}>
                <Box className="text-medium textColor-200">{getUserName(item)}</Box>
                <Box>
                  <Box className="flex-basic-center" onClick={() => showMoreDetails(item)}>
                    <Box>{getUserStatus(item?.application_status)}</Box>
                    <Box sx={{ pl: 2, mt: theme.spacing(1) }}>
                      {!item?.is_checked ? (
                        <KeyboardArrowDownIcon className="textColor-200" />
                      ) : (
                        <KeyboardArrowUpIcon className="textColor-200" />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {item?.is_checked && item?.id_requirements?.length > 0 && (
                <Box className={classes.idRequirementsContainer}>
                  {item?.id_requirements?.map((item: ProcessType, idx: number) => (
                    <Box key={idx}>
                      <Box className={clsx('flex-basic-space-between')} sx={{ p: 2 }}>
                        <Box className="text-small textColor-300">{item?.title}</Box>
                        <Box>{getIconByStatus(item?.status)}</Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </TCard>
  );
};

export default BeneficialOwnerDocuments;
