import React, { useEffect, useState } from 'react';
import { Box, Grid, Icon, Paper, SlideProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import NavBar from '../../components/common/NavBar';
import { colorPalette, TCard } from '../../components/myde-react-components';
import UserIdentity from '../../views/identity/UserIdentity';
import AllTAppsIndex from '../../views/tApps/TAppsIndex';
import SettingsIndex from '../../views/settings/SettingsIndex';
import {
  selectUser,
  setBrandingData,
  setEntityDetailFlag,
  setEntitySelectionFlag,
} from '../../redux/feature/user/userSlice';
import { useTheme } from '../../providers/custom-theme-provider';
import { setCardState, setIsDeleteAllowed, setIsEditAllowedFlag } from '../../redux/feature/common/commonSlice';
import NotificationsLanding from '../NotificationsLanding/NotificationsLanding';
import { selectNotification, setIsNotificationRead } from '../../redux/feature/notification/notificationSlice';
import { setProfileHolder } from '../../redux/feature/profile/profileSlice';
import { ProfileDetails } from '../../types/profileTypes';

interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  square: {
    boxSizing: 'border-box',
    width: '72px',
    height: '10px',
    background: colorPalette.containerBack.base,
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: '10px',
    margin: 'auto',
    top: -7,
    right: -20,
  },
  idCardWrapper: {
    borderRadius: '10px !important',
    border: `1px solid ${colorPalette.border.card}`,
    height: '100%',
    maxHeight: '100%',
    zIndex: 9,
    width: 351, // '94%',
    transition: 'top 600ms ease-in-out 0s !important',
    '-webkitTransition': 'top 600ms ease-in-out 0s !important',
  },
  bottomNavBar: {
    width: '100%',
    position: 'absolute',
    margin: '0 auto',
    marginBottom: 1,
    bottom: '-1px',
    left: 0,
    right: 0,
    background: 'transparent',
    borderRadius: '10px !important',
    border: 'none!important',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  scrollCard: {
    overflow: 'auto !important',
  },
  moveUp: {
    top: '-88%',
    left: '11px',
    position: 'absolute',
  },
  moveDown: {
    top: '15px',
    left: '12px',
    position: 'absolute',
  },
  hideUp: {
    top: '-101%',
    zIndex: 1101,
  },
  container: {
    background: colorPalette.containerBack.base,
    height: '100%',
    padding: '12px',
    borderTop: '6px solid',
    borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
  },
  upSlideIcon: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
  },
  notificationIndicatorBgColor: {
    '& .icon-with-dot-notification': {
      '&:after': {
        backgroundColor: ({ currentThemes }) => `${currentThemes.palette.error.main}!important`,
      },
    },
  },
});

interface IdCardProps {
  cardDirection: SlideProps['direction'];
  hideCard: boolean;
}

export const IDCardIndex = ({ cardDirection = 'down', hideCard = false }: IdCardProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);
  const dispatch = useDispatch();

  //redux val
  const { showEntitySelectionList, showEntityDetails } = useSelector(selectUser);
  const { userBranding } = useSelector(selectUser);
  const { notificationCount, isNotificationRead } = useSelector(selectNotification);

  //Use Effects
  useEffect(() => {
    dispatch(setIsEditAllowedFlag(false));
    dispatch(setIsDeleteAllowed(false));
    dispatch(setProfileHolder({} as ProfileDetails));
  }, []);

  useEffect(() => {
    setCardDirection(cardDirection);
  }, [cardDirection]);

  useEffect(() => {
    if (notificationCount > 0) {
      dispatch(setIsNotificationRead(true));
    }
  }, [notificationCount]);

  const navItems = [
    {
      icon: '',
      label: 'Identity',
      component: <UserIdentity />,
    },
    {
      icon: 'icon-phone-link fontIconSize',
      label: 'T-Apps',
      component: <AllTAppsIndex />,
    },
    {
      icon: 'icon-with-dot-notification fontIconSize',
      label: 'Notifications',
      class: isNotificationRead ? classes.notificationIndicatorBgColor : '',
      component: <NotificationsLanding />,
    },
    {
      icon: 'icon-more-dots fontIconSize',
      label: 'More',
      component: <SettingsIndex />,
    },
  ];

  const [currentTab, setCurrentTab] = useState(navItems[0]);
  const [direction, setCardDirection] = useState('down' as SlideProps['direction']);

  const toggleCard = () => {
    dispatch(setCardState(false));
  };
  const setSelectedNavItem = (val: number) => {
    setCurrentTab(navItems[val]);
    dispatch(setCardState(true));
  };
  const closeSelectionPage = () => {
    dispatch(setBrandingData(userBranding));
    dispatch(setEntitySelectionFlag(false));
    dispatch(setEntityDetailFlag(false));
  };

  return (
    <Box className={classes.container}>
      <TCard
        sx={{ px: 2, py: 3 }}
        className={clsx({
          [classes.idCardWrapper]: true,
          [classes.moveUp]: direction === 'up',
          [classes.moveDown]: direction === 'down',
          [classes.hideUp]: hideCard,
        })}
        elevation={3}
      >
        <Grid container sx={{ p: 0 }} direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box className="cursorPointer" onClick={() => toggleCard()}>
              <span
                className={clsx({
                  [classes.upSlideIcon]: true,
                  ['upSildeArrow icon-expand-circle-down']: true,
                })}
              />
            </Box>
          </Grid>
          <Grid item className="p-relative">
            <Box className={clsx(classes.square, 'p-absolute')} />
          </Grid>
          <Grid item>
            {(showEntitySelectionList || showEntityDetails) && (
              <Icon className={classes.cursorPointer} onClick={closeSelectionPage}>
                close_icon
              </Icon>
            )}
          </Grid>
        </Grid>
        <Box sx={{ my: 1 }}>{currentTab.component}</Box>
        <Paper className={classes.bottomNavBar} elevation={0}>
          <NavBar onUpdateNavItem={setSelectedNavItem} navItems={navItems} />
        </Paper>
      </TCard>
    </Box>
  );
};

export default IDCardIndex;
