import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { getIdProcessDetails, listCustomIdProcessWithStatus, listIdProcessCategories } from '../../../api/dashboard';
import {
  SupplementalIdProcessResults,
  IdProcessResultSet,
  ProcessDataTypes,
  ProcessTextTypes,
} from '../../../types/dashboardTypes';
import { getEntityCustomIdProcessDetails, getEntityCustomIdProcesses } from '../../../api/entity';

type CustomIdProcessState = {
  loading: boolean;
  error: boolean;
  supplementalIdProcessList: SupplementalIdProcessResults[];
  idProcessCategoriesList: IdProcessResultSet[];
  processText: ProcessTextTypes;
  processData: ProcessDataTypes;
  documentList: string[];
  filesList: any[];
  isSearching: boolean;
  selectedCategory: SupplementalIdProcessResults;
  noCategory: boolean;
};

const initialState: CustomIdProcessState = {
  loading: false,
  error: false,
  supplementalIdProcessList: [],
  idProcessCategoriesList: [],
  processText: <ProcessTextTypes>{},
  processData: <ProcessDataTypes>{},
  documentList: [],
  filesList: [],
  isSearching: false,
  selectedCategory: <SupplementalIdProcessResults>{},
  noCategory: false,
};

export const customIdProcessDetails = ({ customIdProcess }: RootState) => ({
  loading: customIdProcess.loading,
  supplementalIdProcessList: customIdProcess.supplementalIdProcessList,
  idProcessCategoriesList: customIdProcess.idProcessCategoriesList,
  processText: customIdProcess.processText,
  processData: customIdProcess.processData,
  documentList: customIdProcess.documentList,
  filesList: customIdProcess.filesList,
  isSearching: customIdProcess.isSearching,
  selectedCategory: customIdProcess.selectedCategory,
  noCategory: customIdProcess.noCategory,
});

export const getSupplementalIdProcessDetails = createAsyncThunk(
  'customIdProcess/getSupplementalIdProcessDetails',
  async (filters: object) => {
    return await listCustomIdProcessWithStatus(filters);
  },
);

export const getIdProcessCategoriesList = createAsyncThunk(
  'customIdProcess/getIdProcessCategoriesList',
  async (data: object) => {
    return await listIdProcessCategories(data);
  },
);

export const getSpecificIdProcessDetails = createAsyncThunk(
  'customIdProcess/getSpecificIdProcessDetails',
  async (data: { id: string }) => {
    return await getIdProcessDetails(data);
  },
);

export const getEntityCustomIdProcessList = createAsyncThunk(
  'entityCustomIdProcess/getEntityCustomIdProcessList',
  async(data:{entityUid: number | string, filters: object}) => {
    return await getEntityCustomIdProcesses(data)
  }
)

export const getEntityCustomIdProcessDetail = createAsyncThunk(
  'entityCustomIdProcessDetails/getEntityCustomIdProcessDetail',
  async(data:{entityUid: number, uuid:string}) => {
    return await getEntityCustomIdProcessDetails(data)
  }
)

export const customIdProcessSlice = createSlice({
  name: 'customIdProcess',
  initialState,
  reducers: {
    setProcessTextData: (state, action) => {
      state.processText = action.payload;
    },
    resetProcessTextData: (state) => {
      state.processText = <ProcessTextTypes>{};
    },
    setCommonFormData: (state, action) => {
      state.processData = action.payload;
    },
    resetCommonFormData: (state) => {
      state.processData = <ProcessDataTypes>{};
    },
    setDocumentList: (state, action) => {
      state.documentList = action.payload;
    },
    resetDocumentList: (state) => {
      state.documentList = [];
    },
    setFilesList: (state, action) => {
      state.filesList = action.payload;
    },
    resetFilesList: (state) => {
      state.filesList = [];
    },
    clearSupplementalIdProcessList: (state) => {
      state.supplementalIdProcessList = [];
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setIsSearching: (state, action) => {
      state.isSearching = action.payload;
    },
    setNoCategory: (state, action) => {
      state.noCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupplementalIdProcessDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSupplementalIdProcessDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload?.results) {
          state.supplementalIdProcessList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getSupplementalIdProcessDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getIdProcessCategoriesList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIdProcessCategoriesList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload?.results) {
          state.idProcessCategoriesList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getIdProcessCategoriesList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSpecificIdProcessDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.selectedCategory = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getEntityCustomIdProcessList.pending,(state)=>{
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityCustomIdProcessList.fulfilled,(state,action)=>{
        state.loading = false;
        state.error = false;
        if (action?.payload?.results) {
          state.supplementalIdProcessList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityCustomIdProcessList.rejected,(state)=>{
        state.loading = false;
        state.error = true;
      })
      .addCase(getEntityCustomIdProcessDetail.pending,(state)=>{
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityCustomIdProcessDetail.fulfilled,(state,action) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.selectedCategory = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityCustomIdProcessDetail.rejected,(state)=>{
        state.loading = false;
        state.error = true;
      })
  },
});

export const {
  setProcessTextData,
  resetProcessTextData,
  setCommonFormData,
  resetCommonFormData,
  setDocumentList,
  resetDocumentList,
  setFilesList,
  resetFilesList,
  clearSupplementalIdProcessList,
  setIsSearching,
  setNoCategory,
  setSelectedCategory,
} = customIdProcessSlice.actions;

export default customIdProcessSlice.reducer;
