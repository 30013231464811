import * as React from 'react';
import { TextFieldProps, Theme } from '@mui/material';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import DateRangeIcon from '@mui/icons-material/DateRange';
interface CustomProps {
  format: string;
  onChange: null | ((e: string) => void);
}
interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    '& .PrivatePickersYear-yearButton.Mui-selected': {
      backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
    '& .PrivatePickersMonth-root.Mui-selected': {
      backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
    '&.MuiCalendarPicker-root': {
      '& .MuiIconButton-root': {
        color: ({ currentThemes }) => `${currentThemes.palette.primary.main}`,
      },
    },
  },
  datePicker: {
    '& .MuiInputLabel-formControl': {
      transform: 'translate(11px, 5px) scale(0.75) !important',
    },

    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          display: 'none !important',
        },
      },
    },
    '& .MuiOutlinedInput-input.MuiInputBase-input': {
      padding: '25px 20px 15px 16px',
    },
  },
});

export type CombinedProps = CustomProps & TextFieldProps & DatePickerProps<any>;

export const DatePicker = React.forwardRef<any, CombinedProps>(({ ...props }: CombinedProps, ref: any) => {
  const { format, onChange, ...rest } = props;
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const handleDateChange = (e: any) => {
    if (e?.isValid()) {
      onChange(e.format(format));
    } else {
      onChange('');
    }
  };

  return (
    <MuiDatePicker
      classes={{
        root: classes.root,
      }}
      className={classes.datePicker}
      ref={ref}
      onChange={handleDateChange}
      {...rest}
      components={{
        OpenPickerIcon: DateRangeIcon,
      }}
    />
  );
});
