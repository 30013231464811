import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useTheme } from '../../../providers/custom-theme-provider';
import { TButton, theme, TLoader } from '../../myde-react-components';
import { ID_PROCESS_STATUS_COMPLETION_STATE } from '../../myde-react-components/src/constants/constants';
import { setCurrentStep, setPreviousStep } from '../../../redux/feature/common/commonSlice';
import { selectSelectedIdProcessDetails } from '../../../redux/feature/idProcess/mainIdProcessSlice';
import { clearSupplementalVerificationStoreData } from '../../../redux/feature/idProcess/supplementalVerificationSlice';
import i18next from 'i18next';
import { getIconByProcessId } from '../../myde-react-components/src/utils/idProcess';

interface StyleProps {
  currentThemes: Theme;
}

interface InstructionsProps {
  lang: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  icon: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
}));

const IdProcessInstructions = ({ lang }: InstructionsProps) => {
  // Constants
  const { t } = useTranslation(['common', 'ssn']);
  const { currentThemes } = useTheme();

  const styleProps = {
    currentThemes,
  };

  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { loading, processId, selectedParentIdProcess } = useSelector(selectSelectedIdProcessDetails);

  // State Values
  const [idProcessTransaltion, setIdProcessTranslation] = useState('common');

  // Use Effects
  useEffect(() => {
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[0].percent));
    dispatch(setPreviousStep(null));
    dispatch(clearSupplementalVerificationStoreData());
  }, []);

  useEffect(() => {
    setIdProcessTranslation(lang);
  }, [lang]);

  // Methods
  const startIdProcess = () => {
    dispatch(setCurrentStep(ID_PROCESS_STATUS_COMPLETION_STATE[1].percent));
    dispatch(setPreviousStep(ID_PROCESS_STATUS_COMPLETION_STATE[0].percent));
  };

  // HTML
  return (
    <Box>
      <TLoader loading={loading} />
      <Box sx={{ px: 3, pt: 3, pb: theme.spacing(5) }}>
        <Box className="flex-column-center">
          <Box sx={{ mb: 2 }} className={clsx(getIconByProcessId(processId), classes.icon, 'icon-size-72')} />
          <Box sx={{ mb: theme.spacing(3) }} className="textColor-200 text-center text-h2 font-weight-semibold">
            {t('instructionHeading', { ns: idProcessTransaltion })}
          </Box>
          <Box className="text-center textColor-200 text-medium font-weight-regular">
            {t('instructionSubHeading', { ns: idProcessTransaltion })}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ px: 3, py: theme.spacing(5) }}>
        <Box className="textColor-200 text-large font-weight-semibold" sx={{ pb: theme.spacing(3) }}>
          {t('whatWeAsk', { ns: 'common' })}
        </Box>
        <Box className="textColor-200 text-medium font-weight-regular">
          {t('instructionQues', { ns: idProcessTransaltion })}
        </Box>
      </Box>
      <Divider />
      <Box sx={{ px: 3, py: theme.spacing(5) }}>
        <Box className="textColor-200 text-large font-weight-semibold" sx={{ pb: theme.spacing(3) }}>
          {t('instructionWhyDataHeading', { ns: idProcessTransaltion })}
        </Box>
        <Box className="textColor-200 text-medium font-weight-regular">
          {t('instructionWhyDataDescription', { ns: idProcessTransaltion })}
        </Box>
      </Box>
      {i18next.exists('instructionVerifyHeading', { ns: idProcessTransaltion }) && (
        <>
          <Divider />
          <Box sx={{ px: 3, py: theme.spacing(5) }}>
            <Box className="textColor-200 text-large font-weight-semibold" sx={{ pb: theme.spacing(3) }}>
              {t('instructionVerifyHeading', { ns: idProcessTransaltion })}
            </Box>
            <Box className="textColor-200 text-medium font-weight-regular">
              {t('instructionVerifyDescription', { ns: idProcessTransaltion })}
            </Box>
          </Box>
        </>
      )}
      {i18next.exists('instructionPrerequisiteSubtitle', { ns: idProcessTransaltion }) && (
        <>
          <Divider />
          <Box sx={{ px: 3, py: theme.spacing(5) }}>
            <Box className="textColor-200 text-large font-weight-semibold" sx={{ pb: theme.spacing(3) }}>
              {t('prerequisiteTitle', { ns: 'common' })}
            </Box>
            <Box className="textColor-200 text-medium font-weight-regular">
              {t('instructionPrerequisiteSubtitle', { ns: idProcessTransaltion })}
            </Box>
          </Box>
        </>
      )}
      {i18next.exists('instructionInfoVerifiedText', { ns: idProcessTransaltion }) && (
        <>
          <Divider />
          <Box sx={{ px: 3, py: theme.spacing(5) }}>
            <Box className="textColor-200 text-large font-weight-semibold" sx={{ pb: theme.spacing(3) }}>
              {t('canInformationVerified', { ns: 'common' })}
            </Box>
            <Box className="textColor-200 text-medium font-weight-regular">
              {t('instructionInfoVerifiedText', { ns: idProcessTransaltion })}
            </Box>
          </Box>
        </>
      )}

      <Box sx={{ px: 3, pt: theme.spacing(3), pb: theme.spacing(8) }} className={'text-center'}>
        <TButton
          variant={'contained'}
          btnWidthSize={'button-w-240'}
          btnText={'Start'}
          onClick={startIdProcess}
          disabled={!selectedParentIdProcess?.is_enabled}
        />
      </Box>
    </Box>
  );
};

export default IdProcessInstructions;
